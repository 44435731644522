import type { ProTableProps } from '@ant-design/pro-table';
import styles from './index.less';
import _ from 'lodash';
import TableFooter from '@/components/Common/TableFooter';
import classNames from 'classnames';
import { getStoredPageSize } from '@/utils/utils';
import type { Props as PaginationProps } from '@/components/DmPagination';

/**
 * 滚动ProTable属性生成fn
 * @param props
 */
export function scrollProTableOptionFn(
  props?: ProTableProps<any, any> & {
    pageId?: any;
    alwaysShowFooter?: boolean;
    pagination?: false | PaginationProps;
  },
) {
  const {
    className,
    tableClassName,
    footer,
    scroll,
    search,
    rowSelection,
    pagination,
    pageId,
    alwaysShowFooter,
    ...otherProps
  } = props || {};

  const defaultOptions = {
    tableLayout: 'fixed',
    rowKey: 'id',
    options: false,
    className: classNames(styles.dmScrollProTable, className),
    tableClassName: classNames(styles.dmScrollTable, tableClassName),
    search: false,
    tableRender: (_, dom, domList) => {
      const { table } = domList;
      const { props: tableProps } = table;
      const empty = tableProps?.pagination?.total === 0;
      return (
        <div
          className={classNames(styles.scrollTableWrap, {
            [styles.tableEmptyFooter]: empty,
          })}
        >
          {dom}
          {alwaysShowFooter ? (
            <TableFooter pageId={pageId} tableProps={tableProps}>
              {footer?.(tableProps?.dataSource)}
            </TableFooter>
          ) : (
            !empty &&
            pagination !== false && (
              <TableFooter pageId={pageId} tableProps={tableProps}>
                {footer?.(tableProps?.dataSource)}
              </TableFooter>
            )
          )}
        </div>
      );
    },

    footer: false,
    tableAlertRender: false,
  };
  if (rowSelection) {
    defaultOptions.rowSelection = {
      fixed: true,
      columnWidth: '42px',
      ...rowSelection,
    };
  }
  if (pagination !== false) {
    defaultOptions.pagination = {
      ...pagination,
      pageSize: getStoredPageSize(pageId),
    };
  } else {
    defaultOptions.pagination = false;
  }
  if (!props?.toolbar && !props?.toolBarRender) {
    defaultOptions.toolBarRender = false;
  }
  if (search && search !== false) {
    defaultOptions.search = {
      collapsed: false,
      ...search,
    };
  }
  if (scroll) {
    defaultOptions.scroll = {
      y: '100%',
      ...scroll,
    };
  } else {
    defaultOptions.scroll = {
      y: '100%',
    };
  }
  return _.merge(defaultOptions, otherProps) as unknown as ProTableProps<any, any>;
}
