import React, { useMemo } from 'react';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import styles from './index.less';

type Props = {
  text?: string;
  style?: React.CSSProperties;
};

/**
 * 以 Markdown 格式渲染文本
 * @param props
 * @constructor
 */
const MarkdownView: React.FC<Props> = (props) => {
  const { text = '', ...otherProps } = props;

  const renderer = useMemo(() => {
    const _renderer = new marked.Renderer();
    const linkRenderer = _renderer.link;
    _renderer.link = (href, title, _text) => {
      if (
        !href?.startsWith('mailto:') &&
        !href?.startsWith('http://') &&
        !href?.startsWith('https://')
      ) {
        return _text;
      }
      const html = linkRenderer.call(renderer, href, title, _text);
      return html.replace(/^<a /, `<a target="_blank" rel="noreferrer noopener nofollow" `);
    };
    return _renderer;
  }, []);

  const html = useMemo(() => {
    return DOMPurify.sanitize(
      marked.parse(text, {
        renderer,
        mangle: false,
        smartypants: true,
        gfm: true,
        breaks: true,
        sanitize: true,
      }),
      { ADD_ATTR: ['target'] },
    );
  }, [text, renderer]);

  if (!html) return null;
  return <div className={styles.wrap} {...otherProps} dangerouslySetInnerHTML={{ __html: html }} />;
};

export default MarkdownView;
