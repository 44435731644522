import I18N from '@/i18n';
import type { FC, ReactNode } from 'react';
import React, { useMemo } from 'react';
import { message, Tooltip } from 'antd';
import classNames from 'classnames';
import copy from 'copy-to-clipboard';

import color from '@/style/color.less';
import styles from './copyableText.less';
import IconFontIcon from '@/components/Common/IconFontIcon';

type ActionNode = {
  tooltip?: ReactNode;
  node: JSX.Element;
  key: string;
};

type Props = React.HTMLAttributes<HTMLSpanElement> & {
  text?: string;
  type?: string;
  noTitle?: boolean;
  showIcon?: boolean;
  tooltip?: ReactNode;
  suffixActions?: ActionNode[];
  prefixActions?: ActionNode[];
  onCopy?: (text?: string) => void;
};

/**
 * 可以复制的文本
 * @param props
 * @constructor
 */
const CopyableText: FC<Props> = (props) => {
  const {
    text = '',
    type = '',
    className,
    children,
    noTitle,
    showIcon = false,
    tooltip = '',
    suffixActions = [],
    prefixActions = [],
    onCopy,
    ...restProps
  } = props;

  let _text = text;
  if (!text && (typeof children === 'string' || typeof children === 'number')) {
    _text = String(children);
  }
  const title = useMemo(() => {
    if (!noTitle) {
      return _text;
    }
    return '';
  }, [_text, noTitle]);
  return (
    <span className={classNames(styles.wrap, className)} {...restProps}>
      <span title={title}>{children ?? text}</span>
      {prefixActions?.map((action) => {
        const { tooltip: _tooltip, node, key } = action;
        return (
          <Tooltip title={_tooltip} key={key}>
            {React.cloneElement(node, {
              style: { opacity: showIcon ? 1 : undefined },
            })}
          </Tooltip>
        );
      })}
      <Tooltip title={tooltip}>
        <a
          style={{
            opacity: showIcon ? 1 : undefined,
            display: showIcon ? 'inline-block' : undefined,
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            copy(_text);
            message.success(`${type}${I18N.t('已复制到剪切板')}`);
            onCopy?.(_text);
            return false;
          }}
        >
          <IconFontIcon iconName="fuzhi_24" color={color.primaryColor} />
        </a>
      </Tooltip>
      {suffixActions?.map((action) => {
        const { tooltip: _tooltip, node, key } = action;
        return (
          <Tooltip title={_tooltip} key={key}>
            {React.cloneElement(node, {
              style: { opacity: showIcon ? 1 : undefined },
            })}
          </Tooltip>
        );
      })}
    </span>
  );
};

export default CopyableText;
