import keyMirror from 'keymirror';

// 权限项
const Functions = keyMirror({
  //   /*费用中心，2个*/
  EXPENSE_MANAGE: null, //  充值、支付与发票管理权", "可对团队的账户余额进行充值，可对购买IP、购买流量、IP服务续费等操作进行支付，拥有此权限同时意味着可以查看订单管理与收支明细"),
  EXPENSE_VIEW: null, // "查看费用中心", "可查看团队的费用中心页面，是费用中心管理其它权限的基础权限，拥有此权限意味着可以查看费用中心总览页面与代金券管理"),
  //   /**
  //    * IP管理，3个
  //    */
  IP_CONFIG: null, // "对已有IP的管理权", "可对已有IP的基本信息、续费策略、标签等属性进行设置，可对IP所属的独享主机进行管理，可对IP视图进行管理，可查看团队所有成员对IP的操作轨迹"),
  IP_LIST: null, // "IP列表查看权", "可查看当前团队内的所有IP列表，是IP管理其它权限的基础权限"),
  IP_MANAGE: null, // "IP新增、购买与删除权", "可添加自有IP，可删除已有IP，可购买通过团队云账户创建的IP或者平台提供的IP（但并不意味着拥有支付权限，需要开启费用中心的充值、支付与发票管理权）"),
  //   /*操作日志，5个*/
  OPERATE_LOG_GET_IP: null, // "查看IP日志", "可查看团队所有成员对IP进行各项管理操作的全部日志"),
  OPERATE_LOG_GET_LOGIN: null, // 查"查看登录日志", "可查看团队所有成员的全部登录日志"),
  OPERATE_LOG_GET_SHOP: null, // "查看分身访问日志", "可查看团队所有成员对分身进行访问的全部日志"),
  OPERATE_LOG_GET_TEAM_MANAGE: null, // "查看团队管理日志", "可查看对团队进行各项操作管理的全部日志"),
  OPERATE_LOG_MANAGE_SHOP: null, // "查看分身管理日志", "可查看团队所有成员对分身进行各项管理操作的全部日志"),
  // /**
  //  * 分身管理，5个
  //  */
  SHOP_AUTHORIZE: null, // "对已有分身的授权与监管权", "可将分身授权给某个团队成员或者团队内的某个组织单元，可实时监管或者强制结束其它人员对分身的访问，可查看团队所有成员对分身的操作轨迹"),
  SHOP_CONFIG: null, // "对已有分身的属性设置权", "可对分身的基本信息、访问策略、标签等属性进行设置，可对分身视图进行管理"),
  SHOP_IMPORT_DELETE: null, // "分身新增、删除与转让权", "可导入新的分身或删除已有的分身，可将分身转让给其它团队（超管与BOSS可管理团队内所有分身，经理默认不开启此权限，一旦开启则只能管理自己名下的分身）"),
  SHOP_FINGERPRINT_MANAGE: null, // "对已有分身浏览器指纹的管理权", "可对分身的浏览器指纹进行查看、新增、编辑与删除"),
  SHOP_BIND_IP_MANAGE: null, // "对已有分身IP地址的管理权", "可对分身的IP地址进行绑定、更换与解绑"),

  // /**
  //  * RPA
  //  */
  RPA_LIST: null, // "RPA流程定义查看权", "可查看团队内所有的RPA流程定义，是RPA管理模块其它权限的基础权限"
  RPA_CREATE_DELETE: null, // "RPA流程定义的创建、编辑与删除权", "可创建新的RPA流程定义，可编辑、删除已有的RPA流程定义"
  RPA_RUN: null, // "RPA流程的执行权与流程任务的查看权", "可对已有的RPA流程定义进行执行操作，并可查看所有的流程任务"
  RPA_PLAN: null, // "RPA流程计划的创建、编辑、删除权", "可创建新的RPA流程计划，可编辑、删除已有的RPA流程计划
  RPA_OPEN_API: null, // RPA_OPEN_API 可启用/关闭 Open API，可创建/销毁 Access Key,
  RPA_CARD_MANAGER: null, // 可购买、激活、绑定、解绑流程任务卡等各种管理操作

  //   /*团队管理，4个*/
  TEAM_AUDIT: null, // "团队审核权", "可对团队的申请行为（如加入团队、转让店铺、接收店铺等）进行审批"),
  TEAM_CRITICAL_MANAGE: null, // "团队解散权", "超级管理员所特有的权限，可将团队转移或者解散，不可再转授给其它人员"),
  TEAM_MANAGE: null, // "团队管理权", "可邀请其它人员加入团队，可更换/关闭成员邀请链接，可对团队组织架构与成员管理，可对团队成员进行功能授权，以及其它对团队的各种操作与管理"),
  TEAM_RESOURCE_MANAGE: null, // 团队资源管理
  TEAM_VIEW_MEMBER: null, // 新增团队成员查看权限
  // 云盘
  DISK_USER_MANAGER: null, // 拥有此权限的人，可查看并管理团队内自己个人的云盘空间及个人收藏（任何其它人员无权查看）
  DISK_TEAM_MANAGER: null, // 拥有此权限的人，可查看并管理团队内共享的云盘空间（团队内共享）
  DISK_SPACE_MANAGER: null, // 拥有此权限的人，可查看并管理云盘存储空间分析并设置审计录像与截屏文件的清理策略
  // 插件
  EXTENSION_LIST: null, // 可查看插件主页签，是插件模块其它功能的基础权限
  EXTENSION_MANAGER: null, // 可安装/卸载插件，能够对插件进行各种管理操作
  // 手机管理
  MOBILE_IMPORT_DELETE: null, // 可导入新的手机，或删除已有的手机
  MOBILE_CONFIG: null, // 可对已有的手机进行属性设置、权限分配、创建分组等管理操作,
});
export default Functions;
