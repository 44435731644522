import I18N from '@/i18n';
import _ from 'lodash';
import constants from '@/constants';
import phone, { PhoneResult } from 'phone';
/**
 * 各种表单校验工具类
 */
export default {
  /**
   * 密码：
   * 长度为8~40个字符，需至少包含以下四类字符中的三类：大写字母、小写字母、数字、特殊符号
   * @param text
   */
  pwd: (text: string = '') => {
    const digitReg = /^.*[0-9]+.*$/;
    const lowerCaseReg = /^.*[a-z]+.*$/;
    const upperCaseReg = /^.*[A-Z]+.*$/;
    const specialCharReg = /^.*[^a-zA-Z0-9]+.*$/;
    let count = 4;
    [digitReg, lowerCaseReg, upperCaseReg, specialCharReg].forEach((reg) => {
      if (!reg.test(text)) {
        count--;
      }
    });
    if (count >= 3) {
      return Promise.resolve(true);
    }
    return Promise.reject(
      new Error(
        I18N.t(
          '长度为8~40个字符，需至少包含以下四类字符中的三类：大写字母、小写字母、数字、特殊符号',
        ) as unknown as string,
      ),
    );
  },

  /**
   * 确认密码
   * 两次输入的密码是否一致
   * @param pwd1
   * @param pwd2
   */
  pwdConfirm: (pwd1: string = '', pwd2: string = '') => {
    if (pwd1 !== pwd2) {
      return Promise.reject(new Error(I18N.t('两次输入的密码不一致')));
    }
    return Promise.resolve();
  },
  ipv4(value: string, async?: boolean) {
    const string = _.trim(value) || '';
    if (string) {
      if (constants.regs.ipv4.test(value)) {
        return async ? Promise.resolve() : true;
      }
      return async ? Promise.reject(new Error(I18N.t('请输入正确的IPv4地址'))) : false;
    }
  },
  /**
   * 国内手机号验证
   * @param value
   * @param label
   */
  cnPhone(value: string, label = I18N.t('请输入您的手机号码')) {
    return new Promise((resolve, reject) => {
      const val = _.trim(value);
      if (!val) {
        return reject(new Error(label));
      }
      const reg = constants.regs.cellPhone;
      if (!reg.test(val)) {
        return reject(new Error(I18N.t('请输入正确的中国手机号')));
      }
      return resolve(val);
    });
  },
  /**
   * 手机号验证
   * @param value
   * @param country
   * @param label
   */
  phone(
    value: string,
    country = undefined,
    label = I18N.t('请输入您的手机号码'),
  ): Promise<PhoneResult> {
    return new Promise((resolve, reject) => {
      const val = _.trim(value);
      if (!val) {
        return reject(new Error(label));
      }
      const reg = constants.regs.cellPhone;
      const result = phone(val, {
        strictDetection: true,
        validateMobilePrefix: true,
        country,
      });
      if (result.isValid || reg.test(val)) {
        if (result.isValid) {
          return resolve(result);
        }
        return resolve({
          isValid: true,
          phoneNumber: val,
          countryIso2: 'CN',
          countryIso3: 'CHN',
          countryCode: '+86',
        });
      }
      let errorString = I18N.t('请输入正确的手机号') as unknown as string;
      if (!val.startsWith('+')) {
        errorString = I18N.t('您可能需要输入国际电话区号，例如+1') as unknown as string;
      }
      return reject(new Error(errorString));
    });
  },
  /**
   * 邮箱验证
   * @param value
   */
  email(value: string) {
    return new Promise((resolve, reject) => {
      const val = _.trim(value);
      if (!val) {
        return reject(new Error(I18N.t('请输入您的邮箱')));
      }
      const reg = constants.regs.email;
      if (!reg.test(val)) {
        return reject(new Error(I18N.t('请输入正确的邮箱')));
      }
      return resolve(val);
    });
  },

  /**
   * http URL校验
   * @param url
   * @param ignoreProtocol
   */
  httpUrl(url: string, ignoreProtocol = false) {
    return new Promise((resolve, reject) => {
      const val = _.trim(url);
      const reg = ignoreProtocol ? constants.regs.urlIgnoreProtocol : constants.regs.url;
      if (!reg.test(val)) {
        return reject(new Error(I18N.t('请输入正确的URL')));
      }
      return resolve(val);
    });
  },
  /**
   * 名称
   * @param val
   * @param reqMsg
   */
  account(val: string, reqMsg?: any) {
    return new Promise((resolve, reject) => {
      const value = _.trim(val);
      if (!value && reqMsg) {
        return reject(new Error(reqMsg));
      }
      if (value.includes('*') || value.includes('?')) {
        return reject(new Error(I18N.t('不能包含？或*')));
      }
      if (constants.regs.emoji.test(value)) {
        return reject(new Error(I18N.t('不能包含emoji表情符号')));
      }
      if (value.length > 128) {
        return reject(new Error(I18N.t('长度不能大于128个字符')));
      }
      return resolve(val);
    });
  },
  domain(val: string) {
    return new Promise((resolve, reject) => {
      const value = _.trim(val);
      if (!value) {
        return reject(new Error(I18N.t('请输入站点')));
      }
      if (value === '*') {
        return resolve(value);
      }
      const reg = /^(([\w-]+\.)|(\*\.))+[\w-]+$/;
      if (!reg.test(value)) {
        reject(new Error(I18N.t('请输入正确的站点')));
      }
      return resolve(value);
    });
  },
  nickname(val: string | undefined, name?: string) {
    return new Promise((resolve, reject) => {
      const value = _.trim(val);
      if (!value || value?.length < 3) {
        return reject(
          new Error(
            `${I18N.t('请输入您的')}${name || I18N.t('昵称')}${I18N.t('（不小于3个字符）')}`,
          ),
        );
      }

      return resolve(value);
    });
  },
  port(val: string | undefined) {
    return new Promise((resolve, reject) => {
      const value = _.trim(val);
      if (!/^([0-9]){1,5}$/.test(value)) {
        return reject(new Error(`${I18N.t('请输入正确的端口号')}`));
      }

      return resolve(value);
    });
  },

  /**
   * 校验花括号参数 "{bar}"
   * @param val
   */
  braceParam(val?: string) {
    return new Promise((resolve, reject) => {
      const value = _.trim(val);
      if (!/^{[a-zA-Z0-9._$]+}$/.test(value)) {
        return reject(new Error(I18N.t('您输入的变量不合法')));
      }
      return resolve(value);
    });
  },
  ipv6(val: string) {
    return new Promise((resolve, reject) => {
      const value = _.trim(val);
      if (!constants.regs.ipv6.test(value)) {
        return reject(new Error(I18N.t('请输入正确的IPv6地址')));
      }
      return resolve(value);
    });
  },
  ip(val: string) {
    return new Promise((resolve, reject) => {
      const value = _.trim(val);
      if (!constants.regs.ipv6.test(value) && !constants.regs.ipv4.test(value)) {
        return reject(new Error(I18N.t('请输入正确的IP地址')));
      }
      return resolve(value);
    });
  },
  phoneOrEmail(val: string) {
    const emailReg = constants.regs.email;
    const phoneReg = constants.regs.cellPhone;
    const valueTrimmed = _.trim(val || '');
    if (!valueTrimmed) {
      return Promise.reject(new Error(I18N.t('请输入手机号或邮箱')));
    }
    const result = phone(valueTrimmed, { strictDetection: true });
    if (result.isValid || phoneReg.test(valueTrimmed)) {
      return Promise.resolve();
    }
    if (emailReg.test(valueTrimmed)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(I18N.t('请输入正确的手机号或邮箱')));
  },
  filename(val: string) {
    return new Promise((resolve, reject) => {
      const value = _.trim(val);
      if (!value) {
        return reject(new Error(I18N.t('请输入文件名')));
      }
      if (!/^[^\\/:*?"<>|]+$/.test(value)) {
        return reject(new Error(I18N.t('文件名包含非法字符')));
      }
      if (/^\./.test(value)) {
        return reject(new Error(I18N.t('文件名非法')));
      }
      return resolve(value);
    });
  },
};
