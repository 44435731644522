import I18N from '@/i18n';
import { FC, useState, useEffect } from 'react';
import { Button, Space, Table } from 'antd';

import styles from './messageDetail.less';
import color from '@/style/color.less';
import { openInNewWindow } from '@/utils/pageUtils';
import { paymentFindOrderResourcesGet } from '@/services/api-PaymentAPI/PaymentController';
import IconFontIcon from '@/components/Common/IconFontIcon';
import DMModal from '@/components/Common/Modal/DMModal';
import CopyableText from '@/components/Common/CopyableText';
import OrderDetailTable from '@/components/MessageCenter/components/OrderDetailTable';
import constants from '@/constants';

type Props = {
  message: API.UserMessageVo;
  goto: (path: string) => void;
};

/**
 * IP生产消息详情
 * @param props
 * @constructor
 */
const IpProductDetail: FC<Props> = (props) => {
  const { message, goto } = props;
  const [orderResourceList, setOrderResourceList] = useState<API.OrderResourceVo[]>([]);
  const [modalVisible, setModalVisible] = useState(false);

  const { messageType, content, resourceId, resourceType } = message;

  useEffect(() => {
    (async () => {
      try {
        if (resourceType === 'Orders') {
          const { data: orderResourceListRs } = await paymentFindOrderResourcesGet({
            orderIds: resourceId!,
          });
          setOrderResourceList(orderResourceListRs ? orderResourceListRs[resourceId || 0] : []);
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [resourceId, resourceType]);

  return (
    <div>
      <p>
        {content}{' '}
        <a style={{ marginLeft: 20 }} onClick={() => setModalVisible(true)}>
          {I18N.t('查看详情')}
        </a>
      </p>
      {messageType === 'Ip_Product_Failed' && (
        <p>
          {I18N.t('如需了解进一步信息，请您')}

          <a style={{ marginLeft: 20 }}>
            <Space
              className="custom-service-item-sub-value custom-service-item-sub-link"
              onClick={() => openInNewWindow(constants.qdSupportLink)}
            >
              <IconFontIcon iconName="kefu_24" />
              {I18N.t('联络在线客服')}
            </Space>
          </a>
        </p>
      )}

      <OrderDetailTable message={message} goto={goto} resourceCount={orderResourceList.length} />
      <DMModal
        title={I18N.t('IP生产详情')}
        visible={modalVisible}
        width={600}
        bodyStyle={{ height: 400 }}
        footer={
          <Button type="primary" onClick={() => setModalVisible(false)}>
            {I18N.t('关闭')}
          </Button>
        }
        onCancel={() => setModalVisible(false)}
      >
        <Table
          className={styles.modalTable}
          size="small"
          pagination={false}
          scroll={{ y: 300 }}
          columns={[
            {
              title: I18N.t('IP地址'),
              key: 'name',
              width: '150px',
              render: (dom, record) => {
                // @ts-ignore
                if (record.resourceId) {
                  return (
                    // @ts-ignore
                    <a onClick={() => goto(`/ipManage/all/${record.resourceId}`)}>
                      {record.ip?.ip ?? '--'}
                    </a>
                  );
                }
                return record.ip?.ip ?? '--';
              },
            },
            {
              title: I18N.t('生产结果'),
              render: (dom, record) =>
                getProduceResult(
                  record.productionStatus as ProductionStatus,
                  record.productionRemarks,
                ),
            },
          ]}
          dataSource={orderResourceList}
        />
      </DMModal>
    </div>
  );
};

type ProductionStatus = 'Finished' | 'NotStart' | 'ProduceError' | 'Producing';

function getProduceResult(productionStatus: ProductionStatus, additionText?: string) {
  if (productionStatus === 'Finished') {
    return (
      <Space size={4}>
        <IconFontIcon iconName="chenggong_24" color={color.successColor} />
        {I18N.t('创建成功')}
      </Space>
    );
  }
  if (productionStatus === 'NotStart') {
    return (
      <Space size={4}>
        <IconFontIcon iconName="info_241" color={color.infoColor} />
        {I18N.t('尚未开始')}
      </Space>
    );
  }
  if (productionStatus === 'Producing') {
    return (
      <Space size={4}>
        <IconFontIcon iconName="info_241" color={color.infoColor} />
        {I18N.t('正在创建')}
      </Space>
    );
  }
  if (productionStatus === 'ProduceError') {
    const errMsg = additionText ? `（${additionText}）` : '';
    return (
      <Space size={4}>
        <IconFontIcon iconName="shibai_24" color={color.errorColor} />
        {I18N.t('创建失败')}

        {errMsg && (
          <CopyableText type={I18N.t('失败原因')} title={additionText} style={{ maxWidth: 295 }}>
            {errMsg}
          </CopyableText>
        )}
      </Space>
    );
  }
  return '--';
}

export default IpProductDetail;
