import type { TableProps } from 'antd';
import { Row } from 'antd';
import type { Props as Pprops } from '@/components/DmPagination';
import Pagination from '@/components/DmPagination';
import styles from './DmTable.less';
import classNames from 'classnames';

interface Props {
  tableProps: TableProps<any> & { pagination?: Pprops };
  children?: any;
  className?: string;
  pageId?: any;
}

const TableFooter = (props: Props) => {
  const { children, className, pageId, tableProps } = props;
  const { pagination } = tableProps;
  return (
    <div className={classNames(styles.dmTableFooter, className, 'dm-table-footer')}>
      <div className="actions">{children}</div>
      {pagination !== false ? <Pagination pageId={pageId} {...pagination} /> : null}
    </div>
  );
};

export default TableFooter;
