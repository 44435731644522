// @ts-nocheck
import React from 'react';
import initialState from '/var/lib/jenkins/workspace/browser-build-portal-statics/src/.umi-production/plugin-initial-state/models/initialState';
import model0 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/actionTodo";
import model1 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/currentTeam";
import model2 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/departmentUserCount";
import model3 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/fpTplList";
import model4 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/ipList";
import model5 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/ipTotal";
import model6 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/menus";
import model7 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/messageCenter";
import model8 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/phoneList";
import model9 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/rpaEditor";
import model10 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/shopList";
import model11 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/shopTotal";
import model12 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/userManageForceUpdate";
import model13 from "/var/lib/jenkins/workspace/browser-build-portal-statics/src/models/windowSync";
// @ts-ignore
import Dispatcher from './helpers/dispatcher';
// @ts-ignore
import Executor from './helpers/executor';
// @ts-ignore
import { UmiContext } from './helpers/constant';

export const models = { '@@initialState': initialState, 'actionTodo': model0, 'currentTeam': model1, 'departmentUserCount': model2, 'fpTplList': model3, 'ipList': model4, 'ipTotal': model5, 'menus': model6, 'messageCenter': model7, 'phoneList': model8, 'rpaEditor': model9, 'shopList': model10, 'shopTotal': model11, 'userManageForceUpdate': model12, 'windowSync': model13 };

export type Model<T extends keyof typeof models> = {
  [key in keyof typeof models]: ReturnType<typeof models[T]>;
};

export type Models<T extends keyof typeof models> = Model<T>[T]

const dispatcher = new Dispatcher!();
const Exe = Executor!;

export default ({ children }: { children: React.ReactNode }) => {

  return (
    <UmiContext.Provider value={dispatcher}>
      {
        Object.entries(models).map(pair => (
          <Exe key={pair[0]} namespace={pair[0]} hook={pair[1] as any} onUpdate={(val: any) => {
            const [ns] = pair as [keyof typeof models, any];
            dispatcher.data[ns] = val;
            dispatcher.update(ns);
          }} />
        ))
      }
      {children}
    </UmiContext.Provider>
  )
}
