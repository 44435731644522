import I18N from '@/i18n';
import type { ModalProps } from 'antd';
import { Button, Col, Row, Space } from 'antd';
import ReactDOM from 'react-dom';
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import EventEmitter from 'events';
import color from '@/style/color.less';
import DMModal from '@/components/Common/Modal/DMModal';
import { generateUUID } from '@/utils/utils';
import IconFontIcon from '@/components/Common/IconFontIcon';

const footerEE = new EventEmitter();

/**
 * 对话框底部的状态栏
 * @param uuid
 * @constructor
 */
export const FooterStatusBar: React.FC<{ uuid?: string }> = ({ uuid = '' }) => {
  const [con, setCon] = useState<React.ReactNode | null>(null);

  const clb = useCallback(
    (data) => {
      const { message = null } = data;
      if (data.uuid === uuid) {
        if (message === null) {
          setCon(null);
        } else if (message.type && message.content) {
          const { type, content } = message;
          let c = color.textMutedColor;
          let iconCls = 'info_24';
          if (type === 'warning') {
            c = color.warningColor;
            iconCls = 'Warning-Circle_24';
          } else if (type === 'error') {
            c = color.errorColor;
            iconCls = 'Warning-Circle_24';
          } else if (type === 'loading') {
            iconCls = 'loading_24';
          }
          setCon(
            <span
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                maxWidth: '100%',
                overflow: 'hidden',
                color: c,
              }}
            >
              <IconFontIcon
                iconName={iconCls}
                spin={type === 'loading'}
                style={{ marginLeft: 1, marginRight: 4 }}
              />

              <span className="text-ellipsis" title={content}>
                {content}
              </span>
            </span>,
          );
        } else {
          setCon(message);
        }
      }
    },
    [uuid],
  );

  useLayoutEffect(() => {
    footerEE.on('show-status-msg', clb);
    return () => {
      footerEE.off('show-status-msg', clb);
    };
  }, [clb, uuid]);

  return <div className="status-bar text-ellipsis">{con}</div>;
};

/**
 * 显示状态栏信息
 * @param uuid
 * @param message 为 null 时，删除状态栏中的内容
 */
export function showStatusMsg(
  uuid: string,
  message: { type: 'info' | 'warning' | 'error' | 'loading'; content: React.ReactNode } | null,
) {
  footerEE.emit('show-status-msg', { uuid, message });
}

/**
 * 仅仅适用于 ModalForm
 * @param options??
 */
export function customFooter(options?: {
  submitButtonProps: { disabled: boolean; loading: boolean };
}) {
  const { placeholder, actions, render, ...otherProps } = options || {};
  return {
    render(props, dom: Element[]) {
      return (
        <Row wrap={false}>
          <Col>{placeholder}</Col>
          <Col flex={1}>
            <Space wrap={false}>
              {actions}
              {dom.reverse()}
            </Space>
          </Col>
        </Row>
      );
    },
    ...otherProps,
  };
}
export type ModalFunctions = {
  close: () => void;
};
type OnSubmit = {
  onOk?: (dialog: ModalFunctions, e: React.MouseEvent<HTMLElement>) => Promise<any | void>;
};

type ModalHackProps = Omit<ModalProps, 'onOk'> &
  OnSubmit & {
    renderFooter?: (dom: React.ReactNode[], dialog: ModalFunctions) => React.ReactNode;
    autoClose?: boolean;
    uuid?: string;
    onEnter?: () => void;
    submitOnEnter?: boolean;
  };

type Options = ModalHackProps & {
  component: ((dialog: ModalFunctions) => JSX.Element) | JSX.Element;
  ghost?: boolean;
};

const ModalHack: React.FC<ModalHackProps> = (props) => {
  const { children, submitOnEnter = false, ...others } = props;
  const {
    onCancel,
    onOk,
    cancelButtonProps,
    cancelText = I18N.t('取消'),
    okButtonProps,
    okText = I18N.t('确定'),
    okType,
    renderFooter,
    autoClose,
    ...modalProps
  } = others;
  const [visible, toggle] = useState(true);
  const [confirmLoading, toggleLoading] = useState(false);
  const close = useCallback(() => {
    toggle(false);
    toggleLoading(false);
  }, []);
  const dialog: ModalFunctions = useMemo(() => {
    return {
      close,
    };
  }, [close]);
  const handleOkBtnClick = useCallback(
    (e) => {
      if (onOk) {
        toggleLoading(true);
        onOk(dialog, e)
          .then(() => {
            toggleLoading(false);
            if (autoClose) {
              close();
              onCancel?.(e);
            }
          })
          .catch((error) => {
            toggleLoading(false);
          });
      } else {
        close();
        onCancel?.(e);
      }
    },
    [autoClose, close, dialog, onCancel, onOk],
  );
  const footer = useMemo(() => {
    const dom = [
      <Button
        key={'ok'}
        loading={confirmLoading}
        disabled={confirmLoading}
        type={'primary'}
        onClick={handleOkBtnClick}
        {...okButtonProps}
      >
        {okText}
      </Button>,
      <Button
        key="cancel"
        onClick={(e) => {
          close();
          onCancel?.(e);
        }}
        {...cancelButtonProps}
      >
        {cancelText}
      </Button>,
    ];

    if (renderFooter) {
      return renderFooter(dom, dialog);
    }
    return (
      <Row wrap={false} align="middle" style={{ width: '100%', justifyContent: 'flex-end' }}>
        <FooterStatusBar uuid={props.uuid} />
        {dom}
      </Row>
    );
  }, [
    cancelButtonProps,
    cancelText,
    close,
    confirmLoading,
    dialog,
    handleOkBtnClick,
    okButtonProps,
    okText,
    onCancel,
    props.uuid,
    renderFooter,
  ]);
  const options: ModalProps = {
    visible,
    footer,
    confirmLoading,
    onCancel: (e) => {
      close();
      onCancel?.(e);
    },
    ...modalProps,
  };
  const content = useMemo(() => {
    if (typeof children === 'function') {
      return children(dialog);
    }
    return children;
  }, [children, dialog]);
  return (
    <DMModal
      destroyOnClose
      onEnter={(e) => {
        if (!submitOnEnter || confirmLoading) return;
        handleOkBtnClick(e);
      }}
      {...options}
    >
      {content}
    </DMModal>
  );
};
/**
 * 已知bug component 内无法使用useModel，拿不到context
 * 已知约束，单例modal
 */
export const useModalCaller = (ghost?: boolean) => {
  const id = 'wrapper_mask_uuid';
  return useCallback(
    (options: Options) => {
      let div = document.querySelector(`#${id}`);
      if (div) {
        try {
          document.body.removeChild(div);
        } catch (e) {
          console.log(e);
        }
      }
      div = document.createElement('div');
      const { component, ...others } = options;
      div.id = id;
      const close = () => {
        try {
          ReactDOM.unmountComponentAtNode(div);
        } catch (e) {
          console.log(e);
        }
      };
      const modal = (
        <ModalHack onCancel={close} {...others}>
          {component}
        </ModalHack>
      );

      if (ghost || options.ghost) {
        const node = typeof component === 'function' ? component({ close }) : component;
        ReactDOM.render(node, div);
      } else {
        ReactDOM.render(modal, div);
      }
      document.body.appendChild(div);
    },
    [ghost],
  );
};
export const unmountGhostModal = (id: string) => {
  const div = document.querySelector(`#${id}`);
  try {
    if (div) {
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    }
  } catch (e) {
    console.log(e);
  }
};

export const GhostModalCaller = (component: JSX.Element, uuid?: string) => {
  const id = uuid || generateUUID();
  try {
    unmountGhostModal(id);
  } catch (e) {
    console.log(e);
  }
  const div = document.createElement('div');
  div.id = id;
  const node = React.cloneElement(component, { ghostModalId: id, key: id });
  ReactDOM.render(node, div);
  document.body.appendChild(div);
  return () => {
    unmountGhostModal(id);
  };
};
export type GhostModalProps = {
  ghostModalId?: string;
};
