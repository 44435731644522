// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 创建团队 POST /api/team/ */
export async function teamPost(
  params: {
    // query
    /** 团队名称 */
    teamName: string;
    /** teamType */
    teamType?: 'crs' | 'gh' | 'krShop' | 'normal' | 'partner' | 'plugin' | 'tk';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultTeamDto>('/api/team/', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取审核详情 GET /api/team/audit/${param0} */
export async function teamAuditByAuditIdGet(
  params: {
    // path
    /** auditId */
    auditId: number;
  },
  options?: { [key: string]: any },
) {
  const { auditId: param0, ...queryParams } = params;
  return request<API.WebResultAuditDetailVo>(`/api/team/audit/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 通过审批 PUT /api/team/audit/${param0}/pass */
export async function teamAuditByAuditIdPassPut(
  params: {
    // query
    /** 团队昵称 */
    teamNickname?: string;
    /** 默认角色code */
    roleCode?: 'boss' | 'manager' | 'staff' | 'superadmin';
    /** 部门ID，不设置时放在根部门（用逗号连接） */
    departmentIds?: string;
    // path
    /** auditId */
    auditId: number;
  },
  options?: { [key: string]: any },
) {
  const { auditId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/audit/${param0}/pass`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 拒绝审批 PUT /api/team/audit/${param0}/reject */
export async function teamAuditByAuditIdRejectPut(
  params: {
    // query
    /** reason */
    reason?: string;
    // path
    /** auditId */
    auditId: number;
  },
  options?: { [key: string]: any },
) {
  const { auditId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/audit/${param0}/reject`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取成员审核列表 GET /api/team/audits */
export async function teamAuditsGet(
  params: {
    // query
    /** teamId */
    teamId: number;
    /** auditType */
    auditType?:
      | 'JOIN_TEAM'
      | 'NEW_DEVICE'
      | 'RECEIVE_SHOP'
      | 'RECEIVE_SHOPS'
      | 'SHARED_MOBILES'
      | 'SHARED_SHOP'
      | 'SHARED_SHOPS'
      | 'TRANSFER_SHOP';
    /** auditStatus */
    auditStatus?: 'APPROVED' | 'CANCEL' | 'NEW' | 'NOT_PASS';
    /** 是否已完成 */
    done?: boolean;
    /** 昵称或手机号搜索关键字 */
    word?: string;
    /** pageNum */
    pageNum: number;
    /** pageSize */
    pageSize: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultAuditResultVo>('/api/team/audits', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查看团队头像 GET /api/team/avatar/${param0} */
export async function teamAvatarByFileGet(
  params: {
    // path
    /** file */
    file: string;
  },
  options?: { [key: string]: any },
) {
  const { file: param0, ...queryParams } = params;
  return request<Record<string, any>>(`/api/team/avatar/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取官方免费配额 GET /api/team/free-quotas */
export async function teamFreeQuotasGet(options?: { [key: string]: any }) {
  return request<API.WebResultListTeamQuotaVo>('/api/team/free-quotas', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取指定团队功能配置详情 GET /api/team/function-configs */
export async function teamFunctionConfigsGet(options?: { [key: string]: any }) {
  return request<API.WebResultListTeamFunctionConfigVo>('/api/team/function-configs', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 根据邀请码获取邀请详情 GET /api/team/inviteInfo/${param0} */
export async function teamInviteInfoByInviteCodeGet(
  params: {
    // path
    /** inviteCode */
    inviteCode: string;
  },
  options?: { [key: string]: any },
) {
  const { inviteCode: param0, ...queryParams } = params;
  return request<API.WebResultInviteInfoVo>(`/api/team/inviteInfo/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取邀请加入团队链接 GET /api/team/inviteLink */
export async function teamInviteLinkGet(
  params: {
    // query
    /** 团队ID */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultInviteLinkVo>('/api/team/inviteLink', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 加入团队 POST /api/team/join */
export async function teamJoinPost(body: API.JoinTeamParamVo, options?: { [key: string]: any }) {
  return request<API.WebResultJoinTeamResultVo>('/api/team/join', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取已经加入的团队列表 GET /api/team/joinList */
export async function teamJoinListGet(
  params: {
    // query
    /** status */
    status?: 'Blocked' | 'Deleted' | 'Pending' | 'Ready';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListTeamWithRoleVo>('/api/team/joinList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 队页面点进入团队按钮之后需要调用该接口 PUT /api/team/loginTeam/${param0} */
export async function teamLoginTeamByTeamIdPut(
  params: {
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/loginTeam/${param0}`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取团队特定配额详情 GET /api/team/quotaDetail */
export async function teamQuotaDetailGet(
  params: {
    // query
    /** quotaName */
    quotaName:
      | 'ShopQuota'
      | 'ShopSecurityPolicy'
      | 'StorageQuota'
      | 'TeamMemberQuota'
      | 'TeamMobileQuota';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultTeamQuotaDetailVo>('/api/team/quotaDetail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取指定团队配额详情 GET /api/team/quotas */
export async function teamQuotasGet(options?: { [key: string]: any }) {
  return request<API.WebResultListTeamQuotaVo>('/api/team/quotas', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取指定项目的剩余配额 GET /api/team/remainQuota */
export async function teamRemainQuotaGet(
  params: {
    // query
    /** quotaName */
    quotaName:
      | 'ShopQuota'
      | 'ShopSecurityPolicy'
      | 'StorageQuota'
      | 'TeamMemberQuota'
      | 'TeamMobileQuota';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultint>('/api/team/remainQuota', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 角色配置信息 GET /api/team/roleConfig */
export async function teamRoleConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultTeamRoleConfig>('/api/team/roleConfig', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 修订角色默认权限 PUT /api/team/roleFunction */
export async function teamRoleFunctionPut(
  params: {
    // query
    /** code */
    code: 'boss' | 'manager' | 'staff' | 'superadmin';
    /** customGranted */
    customGranted: boolean;
    /** function */
    function:
      | 'DISK_SPACE_MANAGER'
      | 'DISK_TEAM_MANAGER'
      | 'DISK_USER_MANAGER'
      | 'EXPENSE_MANAGE'
      | 'EXPENSE_VIEW'
      | 'EXTENSION_LIST'
      | 'EXTENSION_MANAGER'
      | 'FINGERPRINT_CONFIG'
      | 'FINGERPRINT_LIST'
      | 'FINGERPRINT_MANAGER'
      | 'IP_CONFIG'
      | 'IP_LIST'
      | 'IP_MANAGE'
      | 'KOL_LIST'
      | 'KOL_MANAGE'
      | 'MOBILE_CONFIG'
      | 'MOBILE_IMPORT_DELETE'
      | 'OPERATE_LOG_GET_IP'
      | 'OPERATE_LOG_GET_LOGIN'
      | 'OPERATE_LOG_GET_SHOP'
      | 'OPERATE_LOG_GET_TEAM_MANAGE'
      | 'OPERATE_LOG_MANAGE_SHOP'
      | 'RPA_CARD_MANAGER'
      | 'RPA_CREATE_DELETE'
      | 'RPA_LIST'
      | 'RPA_OPEN_API'
      | 'RPA_PLAN'
      | 'RPA_RUN'
      | 'SHOP_AUTHORIZE'
      | 'SHOP_BIND_IP_MANAGE'
      | 'SHOP_CONFIG'
      | 'SHOP_FINGERPRINT_MANAGE'
      | 'SHOP_IMPORT_DELETE'
      | 'TEAM_AUDIT'
      | 'TEAM_CRITICAL_MANAGE'
      | 'TEAM_MANAGE'
      | 'TEAM_RESOURCE_MANAGE'
      | 'TEAM_VIEW_MEMBER'
      | 'TK_PACK_MANAGE'
      | 'TK_流程计划编排'
      | 'TK_达人管理'
      | 'TK_达人运营'
      | 'TK_达人邀约'
      | 'TK_运营辅助';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/team/roleFunction', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询团队的RPA限定设备 GET /api/team/rpaDevices */
export async function teamRpaDevicesGet(options?: { [key: string]: any }) {
  return request<API.WebResultListLoginDeviceDto>('/api/team/rpaDevices', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取团队系统配置 GET /api/team/systemConfig */
export async function teamSystemConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultTeamSystemConfig>('/api/team/systemConfig', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 交接账户 PUT /api/team/user/${param0}/shopTransfer */
export async function teamUserByUserIdShopTransferPut(
  params: {
    // path
    /** userId */
    userId: number;
  },
  body: API.UserShopTransferParamVo,
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/user/${param0}/shopTransfer`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 更新成员状态 PUT /api/team/user/${param0}/status */
export async function teamUserByUserIdStatusPut(
  params: {
    // query
    /** status */
    status: 'ACTIVE' | 'BLOCK' | 'DELETED' | 'INACTIVE';
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/user/${param0}/status`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取指定团队详情 GET /api/team/${param0} */
export async function teamByTeamIdGet(
  params: {
    // path
    /** 团队ID */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResultTeamDetailVo>(`/api/team/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改团队信息 PUT /api/team/${param0} */
export async function teamByTeamIdPut(
  params: {
    // query
    /** teamName */
    teamName: string;
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/${param0}`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取团队头像URL GET /api/team/${param0}/avatar */
export async function teamByTeamIdAvatarGet(
  params: {
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResultstring>(`/api/team/${param0}/avatar`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 更新团队头像 POST /api/team/${param0}/avatar */
export async function teamByTeamIdAvatarPost(
  params: {
    // path
    /** teamId */
    teamId: number;
  },
  body: Record<string, any>,
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/${param0}/avatar`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 修改团队设置 PUT /api/team/${param0}/config */
export async function teamByTeamIdConfigPut(
  params: {
    // query
    /** inviteEnable */
    inviteEnable: boolean;
    /** inviteAuditEnable */
    inviteAuditEnable: boolean;
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/${param0}/config`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 查看其他团队的一个联系人信息 GET /api/team/${param0}/contactByPhone/${param1} */
export async function teamByTeamIdContactByPhoneByPhoneGet(
  params: {
    // query
    /** areaCode */
    areaCode?: string;
    // path
    /** teamId */
    teamId: number;
    /** phone */
    phone: string;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, phone: param1, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/${param0}/contactByPhone/${param1}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 解散团队 PUT /api/team/${param0}/dissolve */
export async function teamByTeamIdDissolvePut(
  params: {
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/${param0}/dissolve`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** （取消）收藏团队 PUT /api/team/${param0}/favorite */
export async function teamByTeamIdFavoritePut(
  params: {
    // query
    /** favorite */
    favorite: boolean;
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/${param0}/favorite`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取邀请信息 GET /api/team/${param0}/inviteInfo */
export async function teamByTeamIdInviteInfoGet(
  params: {
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResultTeamInviteVo>(`/api/team/${param0}/inviteInfo`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 刷新我的邀请链接 GET /api/team/${param0}/refreshInviteCode */
export async function teamByTeamIdRefreshInviteCodeGet(
  params: {
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResultstring>(`/api/team/${param0}/refreshInviteCode`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 转移团队BOSS PUT /api/team/${param0}/transfer */
export async function teamByTeamIdTransferPut(
  params: {
    // query
    /** toUserId */
    toUserId: number;
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/team/${param0}/transfer`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 实名认证信息 GET /api/team/${param0}/verifiedInfo */
export async function teamByTeamIdVerifiedInfoGet(
  params: {
    // path
    /** 团队ID */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResultTeamVerifyDto>(`/api/team/${param0}/verifiedInfo`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
