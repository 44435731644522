// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 删除已经结束的任务 DELETE /api/task/finished */
export async function taskFinishedDelete(options?: { [key: string]: any }) {
  return request<API.WebResultint>('/api/task/finished', {
    method: 'DELETE',
    ...(options || {}),
  });
}

/** 查询任务列表 GET /api/task/page */
export async function taskPageGet(
  params: {
    // query
    /** taskType */
    taskType?: string;
    /** createTimeFrom */
    createTimeFrom?: string;
    /** createTimeTo */
    createTimeTo?: string;
    /** finishTimeFrom */
    finishTimeFrom?: string;
    /** finishTimeTo */
    finishTimeTo?: string;
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultTaskDto>('/api/task/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 为创建任务 POST /api/task/task */
export async function taskTaskPost(body: API.CreateTaskRequest, options?: { [key: string]: any }) {
  return request<API.WebResultlong>('/api/task/task', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查看任务的详情 GET /api/task/${param0} */
export async function taskByTaskIdGet(
  params: {
    // path
    /** taskId */
    taskId: number;
  },
  options?: { [key: string]: any },
) {
  const { taskId: param0, ...queryParams } = params;
  return request<API.WebResultTaskDetailVo>(`/api/task/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 删除特定任务 DELETE /api/task/${param0} */
export async function taskByTaskIdDelete(
  params: {
    // path
    /** taskId */
    taskId: number;
  },
  options?: { [key: string]: any },
) {
  const { taskId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/task/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 终止特定任务 PUT /api/task/${param0}/abort */
export async function taskByTaskIdAbortPut(
  params: {
    // path
    /** taskId */
    taskId: number;
  },
  options?: { [key: string]: any },
) {
  const { taskId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/task/${param0}/abort`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 查看任务的执行结果 GET /api/task/${param0}/result */
export async function taskByTaskIdResultGet(
  params: {
    // path
    /** taskId */
    taskId: number;
  },
  options?: { [key: string]: any },
) {
  const { taskId: param0, ...queryParams } = params;
  return request<API.WebResultTaskResult>(`/api/task/${param0}/result`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
