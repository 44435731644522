import I18N from '@/i18n';
import React from 'react';
import { Empty } from 'antd';

import styles from './index.less';

type Props = {
  image?: React.ReactNode;
  description?: React.ReactNode;
  style?: React.CSSProperties;
};

/**
 * 空白视图
 * @param props
 * @constructor
 */
const EmptyView: React.FC<Props> = (props) => {
  const { image = Empty.PRESENTED_IMAGE_SIMPLE, description = I18N.t('暂无数据'), style } = props;

  return (
    <div className={styles.wrap} style={style}>
      <Empty image={image} description={description} />
    </div>
  );
};

export default EmptyView;
