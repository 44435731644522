import { useState } from 'react';

export default () => {
  // 指纹模版详情的缓存
  const [fpTplList, setFpTplList] = useState<Record<number, API.FingerprintDetailVo>>({});
  const setFpTplDetails = (fpTplDetails: API.FingerprintDetailVo[]) => {
    setFpTplList((oldFpTplList) => {
      const newFpTplList = { ...oldFpTplList };
      fpTplDetails.forEach((o) => {
        newFpTplList[o.id || 0] = o;
      });

      return newFpTplList;
    });
  };
  const getFpTplDetail = (tplId: number) => fpTplList[tplId];
  return { getFpTplDetail, setFpTplDetails };
};
