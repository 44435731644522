// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取活跃会话 GET /api/dashboard/activeSessions */
export async function dashboardActiveSessionsGet(
  params: {
    // query
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    /** sortFiled */
    sortFiled?: 'create_time' | 'creator_nickname' | 'shop_name';
    /** sortOrder */
    sortOrder?: 'asc' | 'desc';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultActiveShopSessionVo>('/api/dashboard/activeSessions', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查看绑定手机号的奖励 GET /api/dashboard/bindPhoneReward */
export async function dashboardBindPhoneRewardGet(options?: { [key: string]: any }) {
  return request<API.WebResultGiftCardPackDetailVo>('/api/dashboard/bindPhoneReward', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查看绑定手机号和微信的奖励 GET /api/dashboard/bindRewardObjects */
export async function dashboardBindRewardObjectsGet(options?: { [key: string]: any }) {
  return request<API.WebResultListGiftCardPackDetailVo>('/api/dashboard/bindRewardObjects', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查看绑定微信的奖励 GET /api/dashboard/bindWechatReward */
export async function dashboardBindWechatRewardGet(options?: { [key: string]: any }) {
  return request<API.WebResultGiftCardPackDetailVo>('/api/dashboard/bindWechatReward', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 关闭所有会话 GET /api/dashboard/closeAllSessions */
export async function dashboardCloseAllSessionsGet(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/dashboard/closeAllSessions', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 费用概览 GET /api/dashboard/feeSummary */
export async function dashboardFeeSummaryGet(options?: { [key: string]: any }) {
  return request<API.WebResultFeeSummaryVo>('/api/dashboard/feeSummary', {
    method: 'GET',
    ...(options || {}),
  });
}

/** IP统计信息 GET /api/dashboard/ipStat */
export async function dashboardIpStatGet(options?: { [key: string]: any }) {
  return request<API.WebResultTeamIpStatVo>('/api/dashboard/ipStat', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取用户登录信息 GET /api/dashboard/lastLogin */
export async function dashboardLastLoginGet(options?: { [key: string]: any }) {
  return request<API.WebResultUserActiveDayVo>('/api/dashboard/lastLogin', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 团队成员（按最近活动排列） GET /api/dashboard/lastUserActivity */
export async function dashboardLastUserActivityGet(
  params: {
    // query
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    /** sortFiled */
    sortFiled?: 'last_login_time' | 'online';
    /** sortOrder */
    sortOrder?: 'asc' | 'desc';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultUserLastActivityVo>('/api/dashboard/lastUserActivity', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 标记用户1天内绑定手机号赠送花瓣 PUT /api/dashboard/markPhoneReward */
export async function dashboardMarkPhoneRewardPut(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/dashboard/markPhoneReward', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 标记用户1天内绑定微信赠送花瓣 PUT /api/dashboard/markWechatReward */
export async function dashboardMarkWechatRewardPut(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/dashboard/markWechatReward', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 获取我最近访问的分身列表 GET /api/dashboard/myShopSessionLastAccess */
export async function dashboardMyShopSessionLastAccessGet(
  params: {
    // query
    /** limit */
    limit?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListShopSessionStatVo>('/api/dashboard/myShopSessionLastAccess', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取即将执行的RPA计划 GET /api/dashboard/pendingRpaPlans */
export async function dashboardPendingRpaPlansGet(
  params: {
    // query
    /** limit */
    limit?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListPendingRpaPlanVo>('/api/dashboard/pendingRpaPlans', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取RPA流程统计信息 GET /api/dashboard/rpaFlowStat */
export async function dashboardRpaFlowStatGet(options?: { [key: string]: any }) {
  return request<API.WebResultRpaFlowStatVo>('/api/dashboard/rpaFlowStat', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取RPA计划列表 GET /api/dashboard/rpaPlans */
export async function dashboardRpaPlansGet(
  params: {
    // query
    /** limit */
    limit?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListPendingRpaPlanVo>('/api/dashboard/rpaPlans', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 最近rpaTask GET /api/dashboard/rpaTaskList */
export async function dashboardRpaTaskListGet(
  params: {
    // query
    /** limit */
    limit?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListRpaSimpleHisVo>('/api/dashboard/rpaTaskList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取团队分身按平台分布情况 GET /api/dashboard/shopPlatformStat */
export async function dashboardShopPlatformStatGet(options?: { [key: string]: any }) {
  return request<API.WebResultListShopPlatformStatVo>('/api/dashboard/shopPlatformStat', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取团队最近访问的分身列表 GET /api/dashboard/teamShopSessionLastAccess */
export async function dashboardTeamShopSessionLastAccessGet(
  params: {
    // query
    /** limit */
    limit?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListShopSessionStatVo>('/api/dashboard/teamShopSessionLastAccess', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
