import React from 'react';
import type { HTMLProps } from 'react';
import classNames from 'classnames';
import styles from './iconFontIcon.less';

interface Props {
  iconName: string;
  disabled?: boolean;
  // 图标尺寸，优先级比 style 的 fontSize 要高
  size?: number | string | 'inherit';
  color?: string;
  spin?: boolean;
}

const IconFontIcon: React.FC<Props & Omit<HTMLProps<HTMLDivElement>, 'size'>> = (props) => {
  const { iconName, className, disabled, size, color, spin = false, ...otherProps } = props;
  const style = {
    ...props.style,
    ...(size ? { fontSize: size } : null),
    ...(color ? { color } : null),
  };
  return (
    <div
      {...otherProps}
      style={style}
      className={classNames(
        'dm-iconFontIcon iconfont',
        `icon-${iconName}`,
        { disabled },
        { [styles.spin]: spin },
        className,
      )}
    />
  );
};

export default IconFontIcon;
