import React, { useEffect, useMemo } from 'react';
import { Avatar, Space, Spin, Typography } from 'antd';
import classNames from 'classnames';
import styles from './index.less';
import {
  getTeamNameFirstChar,
  getTeamColorById,
  getApiUrl,
  SkipErrorNotifyOption,
} from '@/utils/utils';
import { teamByTeamIdAvatarGet } from '@/services/api-Account/TeamController';
import { useRequest } from 'umi';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  team: API.TeamDto;
  className?: string;
  size?: number;
}

const TeamAvatar: React.FC<Props> = (props) => {
  const { team, className, size = 32, ...otherProps } = props;
  const {
    data: avatar,
    loading,
    run,
  } = useRequest(
    () => {
      if (team.avatar) {
        const data = getApiUrl(team?.avatar);
        return Promise.resolve({
          data,
          success: true,
        });
      }
      return teamByTeamIdAvatarGet(
        {
          teamId: team.id!,
        },
        SkipErrorNotifyOption,
      ).then((res) => {
        const data = res?.data ? getApiUrl(res.data) : undefined;
        return {
          data,
        };
      });
    },
    {
      manual: true,
    },
  );
  useEffect(() => {
    run();
  }, [team?.avatar, team?.id, run]);
  const inner = useMemo(() => {
    if (avatar) {
      return <Avatar size={size} src={avatar} shape={'square'} />;
    }
    const color = getTeamColorById(team.id!);

    return (
      <Spin spinning={loading}>
        <div className="text" style={{ backgroundColor: color }}>
          {getTeamNameFirstChar(team.name!)}
        </div>
      </Spin>
    );
  }, [avatar, loading, size, team?.id, team?.name]);

  return (
    <div
      className={classNames(styles.teamAvatar, className, styles[`size${size}`])}
      {...otherProps}
    >
      {inner}
    </div>
  );
};

export const TeamNamePlate: React.FC<Props & { wrapStyle?: React.CSSProperties }> = (props) => {
  const { size = 24, wrapStyle, ...otherProps } = props;
  return (
    <Space size={4} className={styles.teamNamePlateWrap} style={wrapStyle}>
      <TeamAvatar size={size} {...otherProps} />
      <Typography.Text ellipsis={{ tooltip: props.team.name }}>{props.team.name!}</Typography.Text>
    </Space>
  );
};

export default TeamAvatar;
