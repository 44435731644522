// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 登录 POST /api/account/login */
export async function accountLoginPost(
  params: {
    // query
    /** account */
    account: string;
    /** password */
    password: string;
    /** rememberMe */
    rememberMe?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultLoginResultVo>('/api/account/login', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 验证码登录 POST /api/account/loginByCode */
export async function accountLoginByCodePost(
  params: {
    // query
    /** areaCode */
    areaCode?: string;
    /** account */
    account: string;
    /** verifyCode */
    verifyCode: string;
    /** rememberMe */
    rememberMe?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultLoginResultVo>('/api/account/loginByCode', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 注销当前会话或token GET /api/account/logout */
export async function accountLogoutGet(options?: { [key: string]: any }) {
  return request<API.WebResultboolean>('/api/account/logout', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取系统的密码策略 GET /api/account/passwordPolicy */
export async function accountPasswordPolicyGet(options?: { [key: string]: any }) {
  return request<API.PasswordPolicyVo>('/api/account/passwordPolicy', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 检查账号预登陆信息 GET /api/account/preLogin */
export async function accountPreLoginGet(
  params: {
    // query
    /** account */
    account: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.PreLoginVo>('/api/account/preLogin', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 系统基本配置信息 GET /api/account/productConfig */
export async function accountProductConfigGet(options?: { [key: string]: any }) {
  return request<API.ProductConfigVo>('/api/account/productConfig', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 更新JWT GET /api/account/refreshToken */
export async function accountRefreshTokenGet(options?: { [key: string]: any }) {
  return request<API.WebResultJwtResultVo>('/api/account/refreshToken', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取当前客户端的位置信息 GET /api/account/remoteLocation */
export async function accountRemoteLocationGet(options?: { [key: string]: any }) {
  return request<API.RemoteLocationVo>('/api/account/remoteLocation', {
    method: 'GET',
    ...(options || {}),
  });
}
