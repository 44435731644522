//@i18n-ignore
import { Dropdown, Menu, Select, Space, Typography } from 'antd';
import { useMemo } from 'react';
import { AreaIcon } from '@/components/Common/LocationCascader';
import I18N from '@/i18n/I18N';

/**
 * 用户登录状态
 * @constructor
 */
const SwitchLocale = (props: {
  onChange?: (val: 'zh-CN' | 'en-US') => void;
  value?: 'zh-CN' | 'en-US';
  type?: 'dropdown' | 'select';
  currentRender?: (isCn: boolean) => React.ReactNode;
}) => {
  const { onChange, value = I18N.getLocale(), type = 'dropdown', currentRender } = props;
  const items = useMemo(() => {
    return [
      {
        value: 'zh-CN',
        label: (
          <Space>
            <AreaIcon area={'China'} />
            简体中文
          </Space>
        ),
      },
      {
        value: 'en-US',
        label: (
          <Space>
            <AreaIcon area={'United_States'} />
            English
          </Space>
        ),
      },
    ];
  }, []);
  const isCn = useMemo(() => {
    return value === 'zh-CN';
  }, [value]);
  const current = useMemo(() => {
    let inner = (
      <Space>
        <AreaIcon area={'China'} />
        简体中文
      </Space>
    );
    if (!isCn) {
      inner = (
        <Space>
          <AreaIcon area={'United_States'} />
          English
        </Space>
      );
    }
    if (currentRender) {
      return currentRender(isCn);
    }
    if (onChange) {
      return <Typography.Link>{inner}</Typography.Link>;
    }
    return inner;
  }, [isCn, onChange, currentRender]);

  if (!onChange) {
    return current;
  }
  if (type === 'select') {
    return <Select options={items} value={value} onChange={onChange} />;
  }
  return (
    <Dropdown
      overlay={
        <Menu>
          {items.map((item) => {
            const { value: itemValue, label } = item;
            return (
              <Menu.Item
                key={itemValue}
                onClick={() => {
                  if (itemValue !== value) {
                    onChange(itemValue);
                  }
                }}
              >
                {label}
              </Menu.Item>
            );
          })}
        </Menu>
      }
    >
      {current}
    </Dropdown>
  );
};
export default SwitchLocale;
