import React, { useCallback } from 'react';
import { isElectron, sendAsync } from '@/utils/ElectronUtils';
import { message } from 'antd';
import I18N from '@/i18n';
import { openOfficialSiteByAppWindow } from '@/utils/pageUtils';
import constants from '@/constants';
import styles from '@/pages/UserLogin/components/index.less';
import DMConfirm from '@/components/Common/DMConfirm';
import CountdownBtn from '@/components/Common/CountdownBtn';

export default function useAppCheck() {
  // 检查客户与系统的兼容性
  const checkAppCompatibility = useCallback(() => {
    if (!isElectron()) return Promise.resolve();
    return Promise.all([sendAsync('get-app-platform'), sendAsync('get-os-platform')]).then(
      ([appPlatformCode, osPlatformInfo]) => {
        if (appPlatformCode && osPlatformInfo) {
          if (appPlatformCode === 'Win7' && osPlatformInfo.version > 6) {
            message.error({
              content: (
                <span>
                  {I18N.t('版本不匹配，请重新下载适用于Win10的客户端版本')}
                  <a
                    style={{ marginLeft: 20 }}
                    onClick={() => {
                      openOfficialSiteByAppWindow(constants.appDownloadLinkFromApp);
                    }}
                  >
                    {I18N.t('立即下载')}
                  </a>
                </span>
              ),

              duration: 0,
              key: 'appVersionUnMatch',
              className: styles.appVersionTips,
            });
          } else if (appPlatformCode === 'Win10' && osPlatformInfo.version < 10) {
            message.error({
              content: (
                <span>
                  {I18N.t('版本不匹配，请重新下载适用于Win7的客户端版本')}
                  <a
                    style={{ marginLeft: 20 }}
                    onClick={() => {
                      openOfficialSiteByAppWindow(constants.appDownloadLinkFromApp);
                    }}
                  >
                    {I18N.t('立即下载')}
                  </a>
                </span>
              ),
              duration: 0,
              key: 'appVersionUnMatch',
              className: styles.appVersionTips,
            });
          }
        }
      },
    );
  }, []);

  const showNewVersionToInstallConfirm = useCallback((countdownSec = 3) => {
    const modalRef = DMConfirm({
      title: I18N.t('花漾客户端有新版本，建议立即升级'),
      content: I18N.t(
        '花漾客户端新版本已经准备就绪，强烈建议您立即升级，整个过程大概需要持续1分钟左右的时间',
      ),
      footerBtns: [
        {
          content: (
            <CountdownBtn
              type="primary"
              availableAllTime
              autoClick
              seconds={countdownSec}
              onClick={() => {
                modalRef.destroy();
                sendAsync('quit-and-install-updater');
              }}
            >
              {I18N.t('确定')}
            </CountdownBtn>
          ),
        },
        {
          label: I18N.t('取消'),
          key: 'cancel',
        },
      ],
    });
  }, []);

  return {
    checkAppCompatibility,
    showNewVersionToInstallConfirm,
  };
}
