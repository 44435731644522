// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 请求远程支持 返回支持码 POST /api/support/request */
export async function supportRequestPost(
  params: {
    // query
    /** timezone */
    timezone?: string;
    /** lang */
    lang?: string;
    /** clientTimestamp */
    clientTimestamp?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultstring>('/api/support/request', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
