import I18N from '@/i18n';
import { useCallback, useMemo, useState } from 'react';
import DMModal from '@/components/Common/Modal/DMModal';
import { Button, Form, Input, Typography } from 'antd';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import Credit from '@/pages/Cost/widget/Credit';
import { dateFormat, getTeamIdFromUrl, trimValues } from '@/utils/utils';
import { useModalCaller } from '@/mixins/modal';
import { paymentGiftCardActiveAGiftCardPost } from '@/services/api-PaymentAPI/GiftCardController';
import { useDebounceFn, useMount } from '@umijs/hooks';
import pMinDelay from 'p-min-delay';
import DMConfirm from '@/components/Common/DMConfirm';
import { history } from 'umi';

type ExpectedType = 'Credit' | 'RpaVoucher';

export const ActivationQueryKey = 'giftActivation';
type Card = {
  cardNumber: string;
  cardPassword: string;
};
export const Store = (() => {
  let data: Card | undefined;
  let timer: NodeJS.Timeout;
  return {
    set(card?: Card) {
      clearTimeout(timer);
      if (card?.cardPassword && card?.cardNumber) {
        data = {
          cardPassword: card.cardPassword,
          cardNumber: card.cardNumber,
        };
        timer = setTimeout(() => {
          // 30秒后自动清空
          this.set();
        }, 30 * 1000);
      } else {
        data = undefined;
      }
    },
    get() {
      if (data) {
        const card = _.clone(data);
        this.set();
        return card;
      }
      return {
        cardNumber: '',
        cardPassword: '',
      };
    },
    clear() {
      this.set();
    },
  };
})();

/**
 * 礼品卡激活
 * @param props
 * @constructor
 */
const GiftCardActivation = (props: { onFinish: () => void; expectedType?: ExpectedType }) => {
  const { onFinish, expectedType = 'Credit' } = props;
  const [visible, changeVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  useMount(() => {
    form.setFieldsValue(Store.get());
  });
  const [data, setData] = useState<API.GiftCardPackItemVo>();
  const { run: submit } = useDebounceFn(() => {
    form.submit();
  }, 300);
  const footer = useMemo(() => {
    if (data) {
      return (
        <>
          <Button
            type={'primary'}
            onClick={submit}
            disabled={data && !data?.valid}
            loading={loading}
          >
            {I18N.t('激活')}
          </Button>
          <Button
            onClick={() => {
              changeVisible(false);
            }}
          >
            {I18N.t('取消')}
          </Button>
        </>
      );
    }
    return (
      <>
        <Button type="primary" onClick={submit} loading={loading}>
          {I18N.t('验证')}
        </Button>
        <Button
          onClick={() => {
            changeVisible(false);
          }}
        >
          {I18N.t('取消')}
        </Button>
      </>
    );
  }, [data, loading, submit]);
  const giftCardType = useMemo(() => {
    if (!data) {
      return null;
    }
    if (data?.giftCardType === 'RpaVoucher') {
      return (
        <DMFormItem label={I18N.t('任务卡类型')} hidden={!data}>
          <Typography.Text type={'warning'}>
            {I18N.t('流程任务包')}
            {data?.periodUnit}
            {I18N.t('卡（')}
            {data.goodsType === 'Rpa_Voucher_Performance' ? I18N.t('>4核') : I18N.t('<=4核')}）
          </Typography.Text>
        </DMFormItem>
      );
    }
    if (data?.giftCardType === 'Credit') {
      return (
        <DMFormItem label={I18N.t('花瓣数量')} hidden={!data}>
          <Credit value={data?.amount} size={'inherit'} />
        </DMFormItem>
      );
    }
    return (
      <DMFormItem label={I18N.t('礼品卡类型')} hidden={!data}>
        <Typography.Text type={'warning'}>{data?.giftCardType}</Typography.Text>
      </DMFormItem>
    );
  }, [data]);
  const expireTime = useMemo(() => {
    if (!data) {
      return null;
    }
    return (
      <DMFormItem label={I18N.t('有效期至')} hidden={!data}>
        <Typography.Text type={data?.valid ? 'success' : 'danger'}>
          {dateFormat(new Date(data?.expireDate))}
          {data?.valid ? '' : I18N.t('（已过期）')}
        </Typography.Text>
      </DMFormItem>
    );
  }, [data]);
  return (
    <DMModal
      onEnter={submit}
      bodyStyle={{ paddingBottom: 0 }}
      width={600}
      footer={footer}
      title={`${I18N.t('激活')}${
        expectedType === 'Credit' ? I18N.t('花瓣') : I18N.t('流程任务')
      }${I18N.t('礼品卡')}`}
      visible={visible}
      onCancel={() => {
        changeVisible(false);
      }}
    >
      <DMFormItemContext.Provider value={{ labelWidth: 100 }}>
        <Form
          form={form}
          requiredMark
          onFinish={(values) => {
            const card = trimValues(values) as Card;
            setLoading(true);
            const activeNow = !!data;
            pMinDelay(
              paymentGiftCardActiveAGiftCardPost({
                ...card,
                activeNow,
              }),
              400,
            )
              .then((res) => {
                setLoading(false);
                if (activeNow) {
                  onFinish();
                  changeVisible(false);
                } else if (res.data?.giftCardType !== expectedType) {
                  const { giftCardType } = res.data;
                  const cardName =
                    giftCardType === 'Credit' ? I18N.t('花瓣礼品卡') : I18N.t('流程任务礼品卡');
                  const targetName =
                    giftCardType === 'Credit' ? I18N.t('费用中心页签') : I18N.t('RPA页签');
                  DMConfirm({
                    width: 460,
                    title: `${I18N.t('您输入的是一张')}${cardName}`,
                    content: `${I18N.t('您输入的是一张')}${cardName}${I18N.t(
                      '，是否到',
                    )}${targetName}${I18N.t('进行激活？')}`,
                    onOk() {
                      Store.set(card);
                      if (giftCardType === 'Credit') {
                        history.push(
                          `/team/${getTeamIdFromUrl()}/costManage/overview/?${ActivationQueryKey}`,
                        );
                      } else {
                        history.push(
                          `/team/${getTeamIdFromUrl()}/rpa/voucher/?${ActivationQueryKey}`,
                        );
                      }
                      changeVisible(false);
                    },
                  });
                } else {
                  setData({ ...res.data, expired: true });
                }
              })
              .catch(() => {
                setLoading(false);
              });
          }}
        >
          <DMFormItem
            label={I18N.t('礼品卡卡号')}
            name="cardNumber"
            rules={[
              {
                required: true,
                message: I18N.t('请输入礼品卡卡号'),
                whitespace: true,
              },
            ]}
          >
            <Input
              autoFocus
              readOnly={loading}
              onChange={() => {
                setData(undefined);
              }}
            />
          </DMFormItem>
          <DMFormItem
            label={I18N.t('礼品卡卡密')}
            name="cardPassword"
            rules={[
              {
                required: true,
                message: I18N.t('请输入礼品卡卡密'),
                whitespace: true,
              },
            ]}
          >
            <Input.Password
              onChange={() => {
                setData(undefined);
              }}
              readOnly={loading}
              visibilityToggle
            />
          </DMFormItem>
          {giftCardType}
          {expireTime}
        </Form>
      </DMFormItemContext.Provider>
    </DMModal>
  );
};
export default GiftCardActivation;
export function useGiftCardActivationModal(onFinish: () => void) {
  const modalCaller = useModalCaller(true);
  return useCallback(
    (expectedType?: ExpectedType) => {
      modalCaller({
        component: <GiftCardActivation expectedType={expectedType} onFinish={onFinish} />,
      });
    },
    [modalCaller, onFinish],
  );
}
