import EventEmitter from 'events';

export interface ResponseHandler {
  onResponse(response: any): void;
}

export class RpcTransport extends EventEmitter {
  responseHandler!: ResponseHandler;

  start() {}

  stop() {}

  isConnected(): boolean {
    return false;
  }

  setResponseHandler(responseHandler: ResponseHandler) {
    this.responseHandler = responseHandler;
  }

  send(request: any): string {
    throw 'default not supported';
  }
}

export class ChatRoom extends EventEmitter {
  roomId: string;
  currentUser: string;
  transport: RpcTransport;
  connectListener: any;

  userCount = 0;

  requestQueue: any[];

  constructor(roomId: string, currentUser: string, transport: RpcTransport) {
    super();
    this.roomId = roomId;
    this.currentUser = currentUser;
    this.transport = transport;
    this.requestQueue = [];
    this.connectListener = this.onConnectedEvent.bind(this);
    this.transport.addListener('connected', this.connectListener);

    //服务器端推送过来的事件
    this.addListener('room', (event: any)=>{
      this.onRoomEvent(event);
    });
  }


  join() {
    let request = this.makeJoinRequest();
    if(this.transport.isConnected()) {
      this.transport.send(request);
    } else {
      this.requestQueue.push(request);
    }
  }

  leave() {
    try {
      this.transport.send(this.makeLeaveRequest());
    } catch (ignore: any) {}
    this.transport.removeListener('connected', this.connectListener);
  }

  makeJoinRequest() {
    return {
      action: 'room',
      event: 'join',
      roomId: this.roomId,
      fromUser: this.currentUser,
    };
  }

  makeLeaveRequest() {
    return {
      action: 'room',
      event: 'leave',
      roomId: this.roomId,
      fromUser: this.currentUser,
    };
  }

  sendMessage(data: any, toUser?: string) {
    let request: any = {
      action: 'room',
      event: 'message',
      roomId: this.roomId,
      fromUser: this.currentUser,
      toUser: toUser,
      data: data,
    };
    if(this.transport.isConnected()) {
      this.transport.send(request);
    } else {
      this.requestQueue.push(request);
    }
  }

  onConnectedEvent() {
    //如果重新连接成功，需要重新join
    this.transport.send(this.makeJoinRequest());
    while(this.requestQueue.length > 0) {
      let request = this.requestQueue.shift();
      if(request.action == 'room' && request.event == 'join') {
        //上面已经发了join了，队列里的join不再重发
      } else {
        this.transport.send(request);
      }
    }
  }

  private onRoomEvent(event: any) {
    let eventName = event.event;
    switch (eventName) {
      case 'join':
      case 'leave':
        this.userCount = event.data;
        this.emit('users-change', event);
        break;
      case 'message':
        this.emit('message', event);
        break;
    }
  }

}

