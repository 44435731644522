// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 添加部门 POST /api/department/ */
export async function departmentPost(
  body: API.DepartmentParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResultDepartmentDto>('/api/department/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 移动成员 PUT /api/department/member/move */
export async function departmentMemberMovePut(
  body: API.DepartmentMemberMoveParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/department/member/move', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 编辑成员 PUT /api/department/member/${param0} */
export async function departmentMemberByUserIdPut(
  params: {
    // path
    /** userId */
    userId: number;
  },
  body: API.DepartmentMemberUpdateParamVo,
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/department/member/${param0}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 移动部门顺序 PUT /api/department/orders */
export async function departmentOrdersPut(
  body: API.UpdateDepartmentOrderParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/department/orders', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列出部门树,根据当前用户拥有的最大部门权限 GET /api/department/tree */
export async function departmentTreeGet(
  params: {
    // query
    /** 是否包含成员，默认：false */
    includeMembers?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListDepartmentTreeNode>('/api/department/tree', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询部门 GET /api/department/${param0} */
export async function departmentByDepartmentIdGet(
  params: {
    // path
    /** departmentId */
    departmentId: number;
  },
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.WebResultDepartmentDto>(`/api/department/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改部门信息 PUT /api/department/${param0} */
export async function departmentByDepartmentIdPut(
  params: {
    // query
    /** name */
    name?: string;
    // path
    /** departmentId */
    departmentId: number;
  },
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/department/${param0}`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 删除部门 DELETE /api/department/${param0} */
export async function departmentByDepartmentIdDelete(
  params: {
    // path
    /** departmentId */
    departmentId: number;
  },
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/department/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 给部门授权特定资源权限（更新不是追加） PUT /api/department/${param0}/grantResources */
export async function departmentByDepartmentIdGrantResourcesPut(
  params: {
    // path
    /** departmentId */
    departmentId: number;
  },
  body: API.GrantDepartmentResourcesRequest,
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/department/${param0}/grantResources`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 给部门授权特定资源权限（更新不是追加） GET /api/department/${param0}/grantedResources */
export async function departmentByDepartmentIdGrantedResourcesGet(
  params: {
    // query
    /** resourceType */
    resourceType:
      | 'AK'
      | 'Activity'
      | 'Audit'
      | 'BlockElements'
      | 'Cloud'
      | 'CrsOrder'
      | 'CrsProduct'
      | 'DiskFile'
      | 'FingerPrint'
      | 'FingerPrintTemplate'
      | 'Gateway'
      | 'GhCreator'
      | 'GhGifter'
      | 'GhUser'
      | 'GhVideoCreator'
      | 'GiftCardPack'
      | 'Invoice'
      | 'Ip'
      | 'IpPool'
      | 'IppIp'
      | 'KakaoAccount'
      | 'KakaoFriend'
      | 'KolCreator'
      | 'MobileAccount'
      | 'None'
      | 'Orders'
      | 'PluginTeamPack'
      | 'Record'
      | 'RpaFlow'
      | 'RpaTask'
      | 'RpaTaskItem'
      | 'RpaVoucher'
      | 'Shop'
      | 'ShopSession'
      | 'Tag'
      | 'TeamDiskRoot'
      | 'TeamMobile'
      | 'TkBuyer'
      | 'TkCreator'
      | 'TkTeamPack'
      | 'TrafficPack'
      | 'TunnelVps'
      | 'Users'
      | 'View'
      | 'Voucher'
      | 'XhsAccount';
    // path
    /** departmentId */
    departmentId: number;
  },
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.WebResultListlong>(`/api/department/${param0}/grantedResources`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 添加部门成员 POST /api/department/${param0}/member */
export async function departmentByDepartmentIdMemberPost(
  params: {
    // path
    /** departmentId */
    departmentId: number;
  },
  body: number[],
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/department/${param0}/member`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 列出部门成员 GET /api/department/${param0}/members */
export async function departmentByDepartmentIdMembersGet(
  params: {
    // path
    /** departmentId */
    departmentId: number;
  },
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.WebResultUserDepartmentVo>(`/api/department/${param0}/members`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
