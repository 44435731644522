import I18N from '@/i18n';
import { Badge, Cascader, Col, Dropdown, Input, Menu, Row, Space, Typography } from 'antd';
import {
  metaIpLocationCountriesGet,
  metaIpLocationTreeGet,
  metaIpSearchGet,
} from '@/services/api-MetaAPI/MetaIpController';
import styles from './index.less';
import selectorStyles from '@/components/Common/Selector/index.less';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RegionalMaintenance from '@/components/Common/LocationCascader/RegionalMaintenance';
import { OptionPlaceholder } from '@/components/Common/Placeholder';
import type { SelectorProps } from '../Selector';
import Selector from '../Selector';
import IpLocation from '@/pages/IpManage/widgets/IpLocation';
import { metaCountriesGet } from '@/services/api-MetaAPI/MetaGeoController';
import { useRequest } from '@umijs/hooks';
import MiddleSpin from '@/components/Common/MiddleSpin';
import classNames from 'classnames';
import ColoursIcon from '@/components/Common/ColoursIcon';
import { isIconValid } from '@/utils/iconUtils';
import _ from 'lodash';
import { ipCountriesGet } from '@/services/api-ShopAPI/IpController';
import { ippCountriesGet } from '@/services/api-IpPoolAPI/IpPoolController';

interface Props {
  onChange?: (values: any[]) => void;
  value?: any[];
  defaultCountryCode?: string;
  placeholder?: string;
}
/**
 * 以IP归属地为参照，
 */
const HackIconMap = {
  Salvador: 'El_Salvador',
  Korea: 'South_Korea',
  HongKong: 'Hong_Kong',
  Portuguese: 'Portugal',
  Global: 'Global',
  Macau: 'Macao',
};

export function hasValidCountryFlag(area: string) {
  return HackIconMap[area] || isIconValid(area);
}
export function hackAreaIcon(area: string, defaultIcon: string) {
  if (HackIconMap[area]) {
    return HackIconMap[area];
  }
  if (!isIconValid(area)) {
    return defaultIcon;
  }
  return area;
}
export const AreaIcon = (props: {
  [x: string]: any;
  area: any;
  defaultIcon?: string;
  size?: number;
}) => {
  const { area, size = 16, defaultIcon = 'IPguishushiqu_24', ...others } = props;
  return <ColoursIcon size={size} className={hackAreaIcon(area, defaultIcon)} {...others} />;
};

export function getInitialLocation(location: API.IpLocationDto) {
  const _location = location || {};
  const {
    level,
    countryCode,
    cityEn,
    provinceCode,
    id,
    country,
    countryEn,
    province,
    provinceEn,
    city,
  } = _location;
  const country_item = {
    type: 'country',
    value: countryCode,
    title: (I18N.isCn() ? country : countryEn) || countryEn,
  };
  const province_item = {
    type: 'province',
    value: provinceCode,
    title: (I18N.isCn() ? province : provinceEn) || provinceEn || province,
  };
  const city_item = {
    type: 'city',
    value: cityEn,
    id,
    title: (I18N.isCn() ? city : cityEn) || cityEn || city,
  };
  switch (level) {
    case 'District':
    case 'City':
      return [country_item, province_item, city_item];
    case 'Province':
      return [country_item, { province_item, id }];
    case 'Country':
    case 'Continent':
      return [{ ...country_item, id }];
    default:
      return [];
  }
}

export function getDynamicStrategyLocation(location: API.IpLocationDto) {
  return getInitialLocation(location);
}
export function getDynamicStrategyLevel(locs: { type: 'Country' | 'Province' | 'City' }[]) {
  return _.capitalize(_.last(locs)?.type || 'None');
}
export function getDynamicLocationId(locs: { id: number }[]) {
  return _.last(locs)?.id;
}
/**
 * 如果要实现完整需求， 应该是要自己写一套级联组件，menu自定义
 * @param props
 * @constructor
 */
const LocationCascader = (props: Props) => {
  const { value = [], onChange, defaultCountryCode, placeholder = I18N.t('请选择') } = props;
  const [options, setOptions] = useState([]);
  const changeTag = useRef<boolean>(false);
  const [mode, changeMode] = useState<'normal' | 'search' | 'single'>('normal');
  const [keyword, updateKeyword] = useState('');
  const [searchResult, updateResult] = useState(null);
  const updateTargetChildren = useCallback((targetOption, cb) => {
    const { value: countryCode } = targetOption;
    targetOption.loading = true;
    targetOption.children = [];
    metaIpLocationTreeGet({ countryCode }).then((res) => {
      targetOption.loading = false;
      _.sortBy(res.data, ['provinceEn']).forEach((item) => {
        const { province, cities, provinceCode, provinceEn, id } = item;
        const cityArray: {
          label: any;
          value: number | undefined;
          id: number | undefined;
          type: 'city';
          title: any;
        }[] = [];
        if (cities?.length) {
          cities?.forEach((item) => {
            if (item) {
              const { cityEn, id, city } = item;
              const city_label = (I18N.isCn() ? city : cityEn) || cityEn || city;
              // city 为空过滤掉
              cityArray.push({
                label: (
                  <Typography.Text ellipsis title={city_label}>
                    {city_label}
                  </Typography.Text>
                ),
                title: city_label,
                value: id,
                id,
                type: 'city',
              });
            }
          });
        }
        if (id) {
          //
          const province_label = (I18N.isCn() ? province : provinceEn) || provinceEn || province;
          targetOption.children.push({
            label: (
              <Typography.Text ellipsis title={province_label}>
                {province_label}
              </Typography.Text>
            ),
            title: province_label,
            value: provinceCode,
            id,
            type: 'province',
            children: cityArray,
          });
        } else {
          targetOption.children.push(...cityArray);
        }
      });
      cb();
    });
  }, []);
  const _onChange = useCallback(
    (val: any[]) => {
      onChange?.(val);
      changeTag.current = true;
    },
    [onChange],
  );
  const onDynamicChange = useCallback(
    (value, selectedOptions) => {
      if (mode === 'search') {
        const { data } = selectedOptions[0];
        const {
          city,
          countryCode,
          provinceCode,
          id,
          country,
          countryEn,
          province,
          provinceEn,
          level,
          cityEn,
        } = data as API.IpLocationDto;
        switch (level) {
          case 'Country':
            _onChange([
              {
                type: 'country',
                value: countryCode,
                id,
                title: (I18N.isCn() ? country : countryEn) || countryEn,
              },
            ]);
            break;
          case 'Province':
            _onChange([
              {
                type: 'country',
                value: countryCode,
                title: (I18N.isCn() ? country : countryEn) || countryEn,
              },
              {
                type: 'province',
                value: provinceCode,
                id,
                title: (I18N.isCn() ? province : provinceEn) || provinceEn || province,
              },
            ]);
            break;
          default:
            _onChange([
              {
                type: 'country',
                value: countryCode,
                title: (I18N.isCn() ? country : countryEn) || countryEn,
              },
              {
                type: 'province',
                value: provinceCode,
                title: (I18N.isCn() ? province : provinceEn) || provinceEn || province,
              },
              {
                type: 'city',
                value: id,
                id,
                title: (I18N.isCn() ? city : cityEn) || cityEn || city,
              },
            ]);
        }
      } else {
        _onChange(selectedOptions);
      }
    },
    [mode, _onChange],
  );

  const search = useCallback(() => {
    changeMode('search');
    const word = _.trim(keyword);
    return metaIpSearchGet({
      pageNum: 1,
      pageSize: 999,
      query: word,
    }).then((res) => {
      const { data } = res;
      const { list = [] } = data || {};
      const result = list.map((item) => {
        const { country, countryEn, city, cityEn, province, id, provinceCode, level, provinceEn } =
          item;
        switch (level) {
          case 'Country':
            return {
              label: <IpLocation location={{ countryEn, country }} />,
              data: item,
              value: id,
            };

          case 'Province':
            return {
              label: (
                <Space align={'center'}>
                  <IpLocation location={{ countryEn, country }} />
                  <span>/{(I18N.isCn() ? province : provinceEn) || provinceEn || province}</span>
                </Space>
              ),
              title: I18N.isCn() ? country : countryEn,
              value: id,
              data: item,
            };
          default:
            return {
              label: (
                <Space align={'center'}>
                  <IpLocation location={{ countryEn, country }} />
                  {provinceCode && (
                    <span>/{(I18N.isCn() ? province : provinceEn) || provinceEn || province}</span>
                  )}
                  <span>/{(I18N.isCn() ? city : cityEn) || cityEn || city}</span>
                </Space>
              ),
              value: id,
              data: item,
            };
        }
      });
      updateResult(result);
    });
  }, [keyword]);

  const ref = useRef();
  const input = useRef();
  const selected = useMemo(() => {
    const array = value || [];
    // 过滤掉没有value的
    return array.filter((item) => !!item.title || !!item.value);
  }, [value]);
  const innerValue = useMemo(() => {
    return selected.map((item) => item.value);
  }, [selected]);
  const fetch = useCallback(
    (countryCode) => {
      const targetOption:
        | {
            children: [];
            loading: boolean;
          }
        | undefined = _.find(options, (item) => item.countryCode === countryCode);
      if (!targetOption) {
        return;
      }
      metaIpLocationTreeGet({ countryCode }).then((res) => {
        targetOption.loading = false;
        targetOption.children = [];
        res.data?.forEach((p_item) => {
          const { province, cities, provinceCode, provinceEn, id } = p_item;
          const cityArray = cities?.map((c_item) => {
            const { cityEn, id, city } = c_item;
            const label = (I18N.isCn() ? city : cityEn) || cityEn || city;
            return {
              label: (
                <Typography.Text ellipsis title={label}>
                  {label}
                </Typography.Text>
              ),
              title: label,
              value: id,
              id,
              type: 'city',
            };
          });
          const label = (I18N.isCn() ? province : provinceEn) || provinceEn || province;
          targetOption.children.push({
            label: (
              <Typography.Text ellipsis title={label}>
                {label}
              </Typography.Text>
            ),
            title: label,
            value: provinceCode,
            id,
            type: 'province',
            children: cityArray,
          });
        });
        if (!targetOption.children?.length) {
          targetOption.isLeaf = true;
        }
      });
    },
    [options],
  );
  const onSearch = useCallback(() => {
    const word = _.trim(keyword);
    if (!word) {
      changeMode('normal');
    } else {
      search();
    }
  }, [keyword, search]);

  const dynamicOptions = useMemo(() => {
    if (mode !== 'normal') {
      return searchResult;
    }
    return options;
  }, [mode, options, searchResult]);

  const dynamicLoadData = useCallback(
    (selectedOptions) => {
      if (mode === 'normal') {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        updateTargetChildren(targetOption, () => {
          setOptions([...options]);
        });
      }
    },
    [mode, options, updateTargetChildren],
  );
  const notFoundContent = useMemo(() => {
    if (mode === 'search' && searchResult?.length === 0) {
      return I18N.t('请更换搜索关键字');
    }
    return <MiddleSpin />;
  }, [mode, searchResult?.length]);

  useEffect(() => {
    metaIpLocationCountriesGet({}).then((res) => {
      let targetIndex = 0;
      const list = _.orderBy(res.data, ['countryEn']).map((item, index) => {
        const { countryEn, country, countryCode, id } = item;
        if (countryCode === defaultCountryCode) {
          targetIndex = index;
        }
        return {
          label: <IpLocation location={{ countryEn, country }} />,
          value: countryCode,
          id,
          isLeaf: false, // 不加这个，选择不会请求数据
          type: 'country',
          title: I18N.isCn() ? country : countryEn,
        };
      });
      if (defaultCountryCode) {
        const targetOption = list[targetIndex];
        updateTargetChildren(targetOption, () => {
          setOptions(list);
        });
      } else {
        setOptions(list);
      }
    });
  }, [defaultCountryCode, updateTargetChildren]);

  return (
    <div className={classNames(styles.locationsCascaderWrapper)}>
      <Cascader
        autoFocus
        notFoundContent={notFoundContent}
        ref={ref}
        value={innerValue}
        expandTrigger="click"
        onPopupVisibleChange={(visible) => {
          if (visible) {
            // autofocus
            setTimeout(() => {
              try {
                input.current?.focus();
              } catch (e) {
                console.log(e);
              }
            }, 10);
            updateKeyword('');
            changeMode('normal');
            updateResult(null);
          }
        }}
        loadData={dynamicLoadData}
        placeholder={placeholder as unknown as string}
        displayRender={(label, selectedOptions) => {
          const title = selected?.map((item) => item.title).join('/');
          return (
            <Typography.Text ellipsis title={title}>
              {title}
            </Typography.Text>
          );
        }}
        dropdownRender={(menus) => {
          return (
            <div className={styles.dropdownWrap}>
              <Row wrap={false} align={'middle'} style={{ padding: 8 }}>
                <Col flex={1}>
                  <Input.Search
                    ref={input}
                    autoFocus
                    placeholder={I18N.t('请输入关键字') as unknown as string}
                    value={keyword}
                    onChange={(e) => {
                      updateKeyword(e.target.value);
                    }}
                    onPressEnter={onSearch}
                    onSearch={onSearch}
                  />
                </Col>

                <Col
                  onClick={() => {
                    // 隐藏弹出层
                    ref.current?.setState({ popupVisible: false });
                  }}
                >
                  <RegionalMaintenance
                    onUpdate={(res) => {
                      if (mode === 'search') {
                        onSearch();
                      } else if (innerValue?.[0] && res?.country) {
                        if (res.countryCode === innerValue[0]) {
                          fetch(res.countryCode);
                        }
                      }
                    }}
                  />
                </Col>
              </Row>
              <div className={classNames(styles.antCascaderMenuWrap)}>{menus}</div>
            </div>
          );
        }}
        options={dynamicOptions}
        allowClear={true}
        onChange={onDynamicChange}
        changeOnSelect={mode === 'normal'}
      />
    </div>
  );
};
function useLocationMetaRequest(
  options: { type?: 'meta' | 'ip' | 'ipp'; valuePropName?: string } = {},
) {
  const { type = 'ip', valuePropName = 'name' } = options;
  return useRequest(
    () => {
      if (type === 'meta') {
        return metaCountriesGet({ includeLocation: true });
      }
      if (type === 'ipp') {
        return ippCountriesGet();
      }
      return ipCountriesGet();
    },
    {
      formatResult(res) {
        const list: API.CountryDto[] = [];
        let headList: API.CountryDto[] = [];
        res.data?.forEach((item) => {
          const { code } = item;
          if (SortHack.includes(code!)) {
            headList.push(item);
          } else {
            list.push(item);
          }
        });
        headList = _.sortBy(headList, ({ code }) => SortHack.indexOf(code!));

        return [...headList, ...list].map((item) => {
          const { nameEn, name } = item;
          return {
            label: <IpLocation iconSize={14} location={{ countryEn: nameEn, country: name }} />,
            value: item[valuePropName],
            ...item,
          };
        });
      },
    },
  );
}

const SortHack = ['CN', 'HK', 'MO', 'TW', 'US'];
export const CuteLocationDropdown = (props: {
  value: string | any[];
  onChange: (value: any, option: any) => void;
  type?: 'meta' | 'ip' | 'ipp';
  valuePropName?: 'name' | 'code' | 'nameEn';
}) => {
  const { valuePropName = 'name', type = 'ip', onChange, value } = props;
  const { loading, data } = useLocationMetaRequest({ valuePropName, type });
  const [cuteState, setCuteState] = useState<string[]>(() => {
    return _.isArray(value) && value.length > 0 ? value : [];
  });
  const _onChange = useCallback(
    (info: { selectedKeys?: string[] } = {}) => {
      const { selectedKeys = [] } = info;
      let _value = selectedKeys;
      if (selectedKeys.length) {
        _value = [selectedKeys.pop()!];
      }
      setCuteState(_value);
      onChange?.(
        _value,
        _.filter(data, (item) => {
          return _value.includes(item.value);
        }),
      );
    },
    [data],
  );
  return (
    <Dropdown
      arrow={false}
      placement={'bottomLeft'}
      overlayClassName={selectorStyles.horizontalOverlay}
      overlayStyle={{ maxWidth: 420 }}
      overlay={
        <Menu
          multiple
          selectable
          selectedKeys={cuteState}
          onDeselect={_onChange}
          onSelect={_onChange}
        >
          {data?.map((item: any) => {
            return <Menu.Item key={item.value}>{item.label}</Menu.Item>;
          })}
        </Menu>
      }
    >
      <Badge dot={!!cuteState.length} size={'small'} showZero={false}>
        <Typography.Link disabled={loading}>
          <ColoursIcon disabled={loading} size={20} className="IPguishushiqu_24" />
        </Typography.Link>
      </Badge>
    </Dropdown>
  );
};
export const LocationSelector: FC<
  SelectorProps & { valuePropName?: string; type?: 'meta' | 'ipp' | 'ip' }
> = (props) => {
  const {
    showSearch = true,
    showPlaceholderOption = true,
    placeholder = <OptionPlaceholder type="location" />,
    valuePropName = 'name',
    type = 'ip',
    ...others
  } = props;
  const { loading, data } = useLocationMetaRequest({ valuePropName, type });

  return (
    <Selector
      showSearch={showSearch}
      virtual={false}
      horizontal
      loading={loading}
      options={data}
      showPlaceholderOption={showPlaceholderOption}
      placeholder={showPlaceholderOption ? placeholder : I18N.t('请选择归属地')}
      filterOption={(input, option: API.CountryDto & { value: string }) => {
        const { name, nameEn, value, code } = option;
        const searchVal = input.toLowerCase();
        // 从name, nameEn, value中查找
        return _.some([name, nameEn, value, code], (item) => {
          return item?.toLowerCase().includes(searchVal);
        });
      }}
      {...others}
    />
  );
};
export default LocationCascader;
