import type { FC, ReactNode, CSSProperties } from 'react';
import color from '@/style/color.less';
import styles from './errorTips.less';
import IconFontIcon from '@/components/Common/IconFontIcon';
import classNames from 'classnames';

type Props = {
  iconName?: string;
  title: ReactNode;
  desc?: ReactNode;
  direction?: 'vertical' | 'horizontal';
  className?: string;
  style?: CSSProperties;
};

/**
 * 错误提示组件（哭脸）
 * @param props
 * @constructor
 */
const ErrorTips: FC<Props> = (props) => {
  const {
    iconName = 'baoqian_24',
    title,
    desc,
    direction = 'horizontal',
    className,
    style,
  } = props;

  return (
    <div
      className={classNames(
        styles.wrap,
        { [styles.vertical]: direction === 'vertical' },
        className,
      )}
      style={style}
    >
      <IconFontIcon iconName={iconName} size={48} color={color.primaryColor} />
      <div className={styles.content}>
        <div>{title}</div>
        {desc && <div className={styles.desc}>{desc}</div>}
      </div>
    </div>
  );
};

export default ErrorTips;
