import { Image } from 'antd';
import { captchaTokenGet as token, captchaGetBase64Get as getBase64 } from '@/services/api-OtherAPI/CaptchaController';
import ErrorBlock from '@/components/Common/ErrorBlock';
import type { PropsWithoutRef, RefAttributes } from 'react';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useRequest } from 'umi';
import MiddleSpin from '@/components/Common/MiddleSpin';

export interface CaptchaInstance {
  update: () => void;
}
const Captcha: React.FC<PropsWithoutRef<any> & RefAttributes<CaptchaInstance>> = forwardRef(
  (props, ref) => {
    const [captchaId, setCaptchaId] = useState(new Date().getTime());
    const { data, error, loading } = useRequest<{ success: boolean; data: string }>(
      () => {
        return token().then((rs) => {
          return getBase64({ token: rs.data?.token });
        });
      },
      {
        refreshDeps: [captchaId],
      },
    );
    useImperativeHandle(ref, () => {
      return {
        update() {
          setCaptchaId(new Date().getTime());
        },
      };
    });

    let children;
    if (loading || !data) {
      children = <MiddleSpin />;
    } else if (error) {
      children = <ErrorBlock error={error} />;
    } else {
      children = (
        <Image
          src={`data:image/jpeg;base64,${data.base64.replace(/_/g, '/').replace(/-/g, '+')}`}
          preview={false}
          placeholder={true}
          onClick={() => setCaptchaId(new Date().getTime())}
        />
      );
    }

    return <div style={{ width: 120, height: 50 }}>{children}</div>;
  },
);

export default Captcha;
