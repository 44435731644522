import React from 'react';
import styles from './index.less';

type Props = {};

/**
 *
 * @param props
 * @constructor
 */
const Index: React.FC<Props> = (props) => {
  const {} = props;

  return (
    <div className={styles.wrap}>
      <img src={`${window.publicPath}logo-loading.gif`} alt="logo" style={{ width: 128 }} />
    </div>
  );
};

export default Index;
