import I18N from '@/i18n';
import type { ReactNode } from 'react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Button, Checkbox, Col, Row, Tabs } from 'antd';
import DMModal from '@/components/Common/Modal/DMModal';
import styles from './index.less';
import UserAvatarAndName from '@/components/Common/UserAvatarAndName';
import DepartmentTree from '@/components/Common/Tree/DepartmentTree';
import modalStyles from '@/style/modal.less';
import pMinDelay from 'p-min-delay';
import DMConfirm from '@/components/Common/DMConfirm';
import UserList from '@/components/Common/AuthModal/UserList';
import { Department } from '@/components/Common/DepartmentDescriptions';
import IconFontIcon from '@/components/Common/IconFontIcon';
import useCurrentRole from '@/hooks/useCurrentRole';
import ErrorTips from '@/components/Common/ErrorTips';
import InfoTip from '@/components/Tips/InfoTip';
import HelpLink from '@/components/HelpLink';
import _ from 'lodash';

type OnSubmit = (
  keyInfo: {
    users: number[];
    departments: number[];
    cleanFirst: boolean;
  },
  rowInfo: {
    users: Record<any, any>[];
    departments: Record<any, any>[];
    cleanFirst: boolean;
  },
) => Promise<any>;
interface Props {
  selected?: {
    users?: number[];
    departments?: number[];
  };
  onSubmit: OnSubmit;
  title?: JSX.Element | string;
  showClean?: boolean;
  tooltip?: ReactNode;
}

const AuthModal: React.FC<Props> = (props) => {
  const { title, onSubmit, selected, showClean, tooltip } = props;
  const [loading, toggleLoading] = useState(false);
  const [tabKey, changeTabKey] = useState('user');
  const [visible, changVisible] = useState(true);
  const [users, setUsers] = useState<API.UserVo[]>([]);
  const { role, isSpecialRole } = useCurrentRole();
  const [departments, setDepartments] = useState<Record<'id', number>[]>([]);
  const init = useRef({ department: false, user: false });
  const [cleanFirst, changeCleanFirst] = useState(!showClean);
  const selectedDepartments = useMemo(() => {
    if (init.current.department) {
      return departments.map((item) => String(item.id));
    }
    return selected?.departments?.map((id) => String(id)) || [];
  }, [departments, selected?.departments]);
  const selectedUsers = useMemo(() => {
    if (init.current.user) {
      return users.map(({ id }) => id!);
    }
    return selected?.users || [];
  }, [selected?.users, users]);
  const removeUser = useCallback((id) => {
    setUsers((prevState) => _.filter(prevState, (vo) => vo.id !== id));
  }, []);
  const removeDepartment = useCallback((id) => {
    setDepartments((prevState) => _.filter(prevState, (vo) => vo.id !== id));
  }, []);

  const usersNodes = useMemo(() => {
    if (users?.length) {
      return users?.map((user) => {
        return (
          <div className={styles.rowItem} key={user.id}>
            <UserAvatarAndName user={user} />
            <IconFontIcon iconName="Close-Circle_24" onClick={() => removeUser(user.id)} />
          </div>
        );
      });
    }
    return null;
  }, [removeUser, users]);
  const departmentNodes = useMemo(() => {
    if (departments?.length && isSpecialRole) {
      return departments?.map((department) => {
        return (
          <div className={styles.rowItem} key={department.id}>
            <Department style={{ display: 'flex' }} data={department} />
            <IconFontIcon
              iconName="Close-Circle_24"
              onClick={() => removeDepartment(department.id)}
            />
          </div>
        );
      });
    }
    return null;
  }, [departments, isSpecialRole, removeDepartment]);
  const status = useMemo(() => {
    if (showClean) {
      return (
        <Checkbox
          checked={cleanFirst}
          onChange={(e) => {
            changeCleanFirst(e.target.checked);
          }}
        >
          {I18N.t('清除已有的用户与组织单元授权关系')}
        </Checkbox>
      );
    }
    if (tabKey === 'user' && tooltip) {
      return tooltip;
    }
    return (
      <InfoTip
        message={
          <div>
            {I18N.t('经理只支持对自己所属组织单元内的员工进行授权')}
            <HelpLink style={{ marginLeft: 12 }} href={'/team/permission'} />
          </div>
        }
      />
    );
  }, [showClean, tabKey, tooltip, cleanFirst]);
  const onOk = useCallback(async () => {
    toggleLoading(true);
    pMinDelay(
      onSubmit(
        {
          cleanFirst,
          users: selectedUsers,
          departments: selectedDepartments?.map(Number),
        },
        {
          users,
          departments,
          cleanFirst,
        },
      ),
      showClean ? 2000 : 0,
    )
      .then(() => {
        toggleLoading(false);
        changVisible(false);
      })
      .catch(() => {
        toggleLoading(false);
      });
  }, [showClean, cleanFirst, departments, onSubmit, selectedDepartments, selectedUsers, users]);
  const footer = useMemo(() => {
    return (
      <Row className={modalStyles.footer}>
        <Col flex={1}>{status}</Col>
        <Col>
          <Button
            disabled={loading}
            type={'primary'}
            onClick={() => {
              if (cleanFirst && showClean) {
                DMConfirm({
                  title: I18N.t('清除已有的用户与组织单元授权关系？'),
                  content: I18N.t('将清除旧有的授权关系，请确认是否要继续'),
                  onOk,
                });
              } else {
                onOk();
              }
            }}
          >
            {I18N.t('确定')}
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              changVisible(false);
            }}
          >
            {I18N.t('取消')}
          </Button>
        </Col>
      </Row>
    );
  }, [showClean, cleanFirst, loading, onOk, status]);
  const departmentTree = useMemo(() => {
    if (role.code === 'manager') {
      return <ErrorTips title={I18N.t('经理角色不支持对组织单元授权')} />;
    }
    return (
      <div className={'tree-wrapper'}>
        <DepartmentTree
          onInit={(rows) => {
            setDepartments(rows);
            init.current.department = true;
          }}
          selectedKeys={selectedDepartments}
          onSelect={(keys, rows) => {
            setDepartments(rows);
          }}
        />
      </div>
    );
  }, [role.code, selectedDepartments]);
  const selectedStatus = useMemo(() => {
    let text = `${I18N.t('已选：')}${users?.length || 0}${I18N.t('个成员')}`;
    if (isSpecialRole) {
      text += `，${departments?.length || 0}${I18N.t('个部门')}`;
    }
    return text;
  }, [departments?.length, isSpecialRole, users?.length]);

  return (
    <DMModal
      footer={footer}
      visible={visible}
      onCancel={() => {
        changVisible(false);
      }}
      title={title || I18N.t('选择成员对象')}
      width="800px"
      className={styles.authUser}
    >
      <div className="authUser-left">
        <div className="authUser-left-tree">
          <Tabs
            centered
            style={{ overflow: 'hidden' }}
            activeKey={tabKey}
            onChange={(v) => {
              changeTabKey(v);
            }}
          >
            <Tabs.TabPane tab={I18N.t('用户授权')} key={'user'}>
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  overflow: 'hidden',
                }}
              >
                <UserList
                  selected={selectedUsers}
                  onInit={(rows) => {
                    setUsers(() => rows);
                    init.current.user = true;
                  }}
                  onChange={(keys, rows) => {
                    if (init.current.user) {
                      setUsers(() => rows);
                    }
                  }}
                />
                {role.code === 'manager' && showClean && (
                  <InfoTip message={I18N.t('经理只支持对自己所属组织单元内的员工进行授权')} />
                )}
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab={I18N.t('组织单元授权')} key={'org'} forceRender>
              {departmentTree}
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
      <div className="authUser-right">
        <div className="authUser-right-title">{selectedStatus}</div>
        <div className="authUser-right-users">
          {usersNodes}
          {departmentNodes}
        </div>
      </div>
    </DMModal>
  );
};

export default AuthModal;
