// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取团队所有IP池的国家（地区）列表 GET /api/ipp/countries */
export async function ippCountriesGet(options?: { [key: string]: any }) {
  return request<API.WebResultListCountryDto>('/api/ipp/countries', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查询IP池供应商 GET /api/ipp/ipp/providers */
export async function ippIppProvidersGet(
  params: {
    // query
    /** valid */
    valid?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListIppProviderDto>('/api/ipp/ipp/providers', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询供应商的参数定义列表 GET /api/ipp/paramDefs */
export async function ippParamDefsGet(
  params: {
    // query
    /** provider */
    provider: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListIppParamDefDto>('/api/ipp/paramDefs', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 创建IP池 POST /api/ipp/pool */
export async function ippPoolPost(body: API.CreateIpPoolRequest, options?: { [key: string]: any }) {
  return request<API.WebResultIpPoolDto>('/api/ipp/pool', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 分页查询IP池 GET /api/ipp/pool/page */
export async function ippPoolPageGet(
  params: {
    // query
    /** 供应商 */
    provider?: string;
    /** 国家 */
    country?: string;
    /** 城市 */
    city?: string;
    /** 按标签id过滤 */
    tagIds?: string;
    /** name */
    name?: string;
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    /** 排序字段 */
    sortFiled?: 'capacity' | 'createTime' | 'lastApiTime' | 'lifetime' | 'provider';
    /** 顺序 */
    sortOrder?: 'asc' | 'desc';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultIppPoolVo>('/api/ipp/pool/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询IP池详情 GET /api/ipp/pool/${param0} */
export async function ippPoolByIppIdGet(
  params: {
    // path
    /** ippId */
    ippId: number;
  },
  options?: { [key: string]: any },
) {
  const { ippId: param0, ...queryParams } = params;
  return request<API.WebResultIppPoolWithParamsVo>(`/api/ipp/pool/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 删除IP池 DELETE /api/ipp/pool/${param0} */
export async function ippPoolByIppIdDelete(
  params: {
    // path
    /** ippId */
    ippId: number;
  },
  options?: { [key: string]: any },
) {
  const { ippId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ipp/pool/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 分配一个ip池中的IP GET /api/ipp/pool/${param0}/allocIppIp */
export async function ippPoolByIppIdAllocIppIpGet(
  params: {
    // path
    /** ippId */
    ippId: number;
  },
  options?: { [key: string]: any },
) {
  const { ippId: param0, ...queryParams } = params;
  return request<API.WebResultAllocIppIpResult>(`/api/ipp/pool/${param0}/allocIppIp`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改IP池属性 PUT /api/ipp/pool/${param0}/basicInfo */
export async function ippPoolByIppIdBasicInfoPut(
  params: {
    // path
    /** ippId */
    ippId: number;
  },
  body: API.UpdateIpPoolBasicInfoRequest,
  options?: { [key: string]: any },
) {
  const { ippId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ipp/pool/${param0}/basicInfo`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 分页查询IP池IP列表 GET /api/ipp/pool/${param0}/ips */
export async function ippPoolByIppIdIpsGet(
  params: {
    // query
    /** minActiveSessions */
    minActiveSessions?: number;
    /** valid */
    valid?: boolean;
    /** ip */
    ip?: string;
    /** host */
    host?: string;
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    /** 排序字段 */
    sortFiled?:
      | 'areaCode'
      | 'createTime'
      | 'directDownTraffic'
      | 'directUpTraffic'
      | 'expireTime'
      | 'host'
      | 'invalidTime'
      | 'lastAllocTime'
      | 'outboundIp'
      | 'proxyType'
      | 'status'
      | 'transitDownTraffic'
      | 'transitUpTraffic'
      | 'username'
      | 'valid';
    /** 顺序 */
    sortOrder?: 'asc' | 'desc';
    // path
    /** ippId */
    ippId: number;
  },
  options?: { [key: string]: any },
) {
  const { ippId: param0, ...queryParams } = params;
  return request<API.WebResultPageResultIppIpDto>(`/api/ipp/pool/${param0}/ips`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 批量删除IP池IP DELETE /api/ipp/pool/${param0}/ips */
export async function ippPoolByIppIdIpsDelete(
  params: {
    // query
    /** ippIpIds */
    ippIpIds: number;
    // path
    /** ippId */
    ippId: number;
  },
  options?: { [key: string]: any },
) {
  const { ippId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ipp/pool/${param0}/ips`, {
    method: 'DELETE',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 解析Ip池生产结果 POST /api/ipp/pool/${param0}/parseIppIp */
export async function ippPoolByIppIdParseIppIpPost(
  params: {
    // path
    /** ippId */
    ippId: number;
  },
  body: API.ClientProduceIpResult,
  options?: { [key: string]: any },
) {
  const { ippId: param0, ...queryParams } = params;
  return request<API.WebResultlong>(`/api/ipp/pool/${param0}/parseIppIp`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 修改IP池属性 PUT /api/ipp/pool/${param0}/providerParam */
export async function ippPoolByIppIdProviderParamPut(
  params: {
    // path
    /** ippId */
    ippId: number;
  },
  body: API.UpdateIpPoolProviderParamRequest,
  options?: { [key: string]: any },
) {
  const { ippId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ipp/pool/${param0}/providerParam`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 修改IP池属性 PUT /api/ipp/pool/${param0}/strategy */
export async function ippPoolByIppIdStrategyPut(
  params: {
    // path
    /** ippId */
    ippId: number;
  },
  body: API.UpdateIpPoolStrategyRequest,
  options?: { [key: string]: any },
) {
  const { ippId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ipp/pool/${param0}/strategy`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 批量删除IP池 DELETE /api/ipp/pools */
export async function ippPoolsDelete(
  params: {
    // query
    /** ippIds */
    ippIds: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ipp/pools', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询白名单配置 GET /api/ipp/whitelistConfig */
export async function ippWhitelistConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultIppWhitelistConfig>('/api/ipp/whitelistConfig', {
    method: 'GET',
    ...(options || {}),
  });
}
