import ProTable from '@ant-design/pro-table';
import { scrollProTableOptionFn } from '@/mixins/table';
import I18N from '@/i18n/I18N';
import ColoursIcon from '@/components/Common/ColoursIcon';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import CopyableText from '@/components/Common/CopyableText';
import styles from './index.less';
import buttonStyles from '@/style/button.less';
import { Button, Typography } from 'antd';
import Constants from '@/constants';
import {
  promotionGetRewardObjectGet,
  promotionMyPromoUrlGet,
  promotionPromotedUsersGet,
} from '@/services/api-Account/UserPromotionController';
import { useRequest, history } from 'umi';
import { isElectron } from '@/utils/ElectronUtils';
import { getTeamIdFromUrl, removeHttpPrefix } from '@/utils/utils';
import {
  dashboardBindPhoneRewardGet,
  dashboardBindWechatRewardGet,
} from '@/services/api-ShopAPI/DashboardController';
import _ from 'lodash';
import { openByDefaultBrowser } from '@/utils/pageUtils';
import { useCallback, useMemo, useState } from 'react';
import DMModal from '@/components/Common/Modal/DMModal';
import { GhostModalCaller } from '@/mixins/modal';
import { ActivationQueryKey, Store } from '@/pages/Cost/components/GiftCardAtivation';

export const useRewardStatus = (id: number, defaultValue?: boolean) => {
  const { data: activated } = useRequest(() => {
    if (_.isNumber(id)) {
      return promotionGetRewardObjectGet({
        id,
      }).then((res) => {
        const data = res.data!;
        const [card] = data.packItems!;
        return { data: !!card.activatedTeamId };
      });
    }
    return Promise.resolve({
      data: defaultValue,
    });
  });
  return activated;
};

export const RewardDetailModal = (props: {
  record: API.UserPromotedRecordVo;
  showActivateButton?: boolean;
}) => {
  const { record, showActivateButton = false } = props;
  const [visible, changeVisible] = useState(true);
  const { data: card, loading } = useRequest(() => {
    const { id } = record;
    if (!_.isNumber(id)) {
      return Promise.resolve({
        data: record,
      });
    }
    return promotionGetRewardObjectGet({
      id,
    }).then((res) => {
      const data = res.data!;
      const [card] = data.packItems!;
      return {
        data: card,
      };
    });
  });
  const href = useMemo(() => {
    return Constants.productSite + '/help/fee/gift';
  }, []);
  const footer = useMemo(() => {
    let _showActivateButton = false;
    if (!loading && !card?.activatedTeamId && showActivateButton) {
      _showActivateButton = true;
    }
    return (
      <>
        <Button
          hidden={!_showActivateButton}
          className={buttonStyles.successBtn}
          onClick={() => {
            changeVisible(false);
            history.push(`/team/${getTeamIdFromUrl()}/costManage/overview?${ActivationQueryKey}`);
            Store.set({ cardNumber: card?.cardNumber, cardPassword: card?.cardPassword });
          }}
        >
          {I18N.t('立即激活')}
        </Button>
        <Button
          type={'primary'}
          onClick={() => {
            changeVisible(false);
          }}
        >
          {I18N.t('关闭')}
        </Button>
      </>
    );
  }, [loading, card, showActivateButton]);
  return (
    <DMModal
      visible={visible}
      onCancel={() => {
        changeVisible(false);
      }}
      footer={footer}
      width={540}
      headless
    >
      <DMFormItemContext.Provider value={{ disableLabelMuted: true, labelWidth: 70 }}>
        <DMFormItem label={I18N.t('卡号')}>
          <CopyableText>{card?.cardNumber}</CopyableText>
        </DMFormItem>
        <DMFormItem label={I18N.t('卡密')}>
          <CopyableText>{card?.cardPassword}</CopyableText>
        </DMFormItem>
        <DMFormItem label={I18N.t('状态')}>
          {card?.activatedTeamId ? (
            <Typography.Text>{I18N.t('已激活')}</Typography.Text>
          ) : (
            <Typography.Text type={'success'}>{I18N.t('未激活')}</Typography.Text>
          )}
        </DMFormItem>
        <DMFormItem label={I18N.t('使用说明')}>
          <Typography.Link
            onClick={() => {
              openByDefaultBrowser(href);
            }}
          >
            {href}
          </Typography.Link>
        </DMFormItem>
      </DMFormItemContext.Provider>
    </DMModal>
  );
};

export function usePromotionList(initPageSize = 99999) {
  return useRequest(
    async () => {
      const phone_reward = await dashboardBindPhoneRewardGet().then((res) => {
        if (!res.data?.packItems?.length) {
          return null;
        }
        const [pack] = res.data.packItems!;
        return {
          rewardTime: pack.activeTime,
          createTime: res.data.createTime,
          rewardAmount: pack.amount,
          id: 'bind_phone',
          activatedTeamId: pack.activatedTeamId,
          cardNumber: pack.cardNumber,
          cardPassword: pack.cardPassword,
        };
      });
      const wechat_reward = await dashboardBindWechatRewardGet().then((res) => {
        if (!res.data?.packItems?.length) {
          return null;
        }
        const [pack] = res.data.packItems!;
        return {
          rewardTime: pack.activeTime,
          rewardAmount: pack.amount,
          createTime: res.data.createTime,
          activatedTeamId: pack.activatedTeamId,
          id: 'bind_wechat',
          cardNumber: pack.cardNumber,
          cardPassword: pack.cardPassword,
        };
      });
      return promotionPromotedUsersGet({
        pageNum: 1,
        pageSize: initPageSize,
      }).then((res) => {
        const _list =
          res.data?.list?.map((item) => {
            const { createTime, id, promotedUserId, rewardAmount, rewardTime, nickname } = item;
            // createTime?: string;
            // id?: number;
            // promoUserId?: number;
            // promotedUserId?: number;
            // rewardAmount?: number;
            // rewardId?: number;
            // rewardTime?: string;
            return {
              createTime,
              rewardAmount,
              id,
              promotedUserId,
              rewardTime,
              nickname,
            };
          }) || [];
        const list = _.sortBy([..._list, phone_reward, wechat_reward].filter(Boolean), (i) =>
          new Date(i.createTime!).getTime(),
        ).reverse();
        return {
          data: {
            list,
            userCount: res.data?.total,
          },
        };
      });
    },
    {
      initialData: {
        userCount: 0,
        list: [],
      },
    },
  );
}

const MyInviteCode = () => {
  const { data } = useRequest(() => {
    return promotionMyPromoUrlGet();
  });
  const { data: tableData, loading } = usePromotionList();

  const onClick = useCallback((record: API.UserPromotedRecordVo) => {
    GhostModalCaller(<RewardDetailModal record={record} />);
  }, []);
  return (
    <div className={styles.myInviteCodeWrapper}>
      <div className={'left-panel'}>
        <ProTable
          cardProps={{
            bodyStyle: {
              padding: '16px 16px 0 16px',
            },
          }}
          dataSource={tableData?.list}
          loading={loading}
          {...scrollProTableOptionFn({
            pageId: 'MyInviteCode',
            pagination: {
              total: tableData?.list?.length,
            },
          })}
          onRow={(record) => {
            return {
              onClick() {
                onClick(record!);
              },
              style: {
                cursor: 'pointer',
              },
            };
          }}
          columns={[
            {
              dataIndex: 'promotedUserId',
              title: I18N.t('奖励原因'),
              ellipsis: true,
              renderText(_text, record) {
                const { id, nickname } = record;
                if (id === 'bind_phone') {
                  return I18N.t('绑定手机');
                } else if (id === 'bind_wechat') {
                  return I18N.t('绑定微信');
                }
                return nickname;
              },
            },
            {
              dataIndex: 'createTime',
              title: I18N.t('奖励时间'),
              valueType: 'dateTime',
              width: '160px',
            },
            {
              dataIndex: 'rewardAmount',
              title: I18N.t('奖励花瓣'),
              valueType: 'digit',
              width: '90px',
            },
            {
              dataIndex: 'status',
              title: I18N.t('状态'),
              width: '70px',
              render(_text, record) {
                const { id } = record;
                const activated = useRewardStatus(id!, !!record?.activatedTeamId);
                if (activated) {
                  return <Typography.Text type={'secondary'}>{I18N.t('已激活')}</Typography.Text>;
                }
                return <Typography.Text type={'success'}>{I18N.t('未激活')}</Typography.Text>;
              },
            },
          ]}
        />
      </div>
      <div className={'right-panel'}>
        <main>
          <div style={{ alignSelf: 'center', textAlign: 'center' }}>
            <div style={{ marginBottom: 24, fontSize: 20 }}>{I18N.t('花漾灵动推广激励计划')}</div>
            <ColoursIcon className={'huaban_24'} size={124} />
          </div>
          <div style={{ marginTop: 24, marginBottom: 32, textIndent: '2em' }}>
            {I18N.t(
              '通过您的专属邀请码或专属邀请链接每发展一名注册用户（该用户必须通过手机注册），您将获得{{count}}花瓣的激励',
              {
                count: 1000,
              },
            )}
          </div>
          <DMFormItemContext.Provider
            value={{ disableLabelMuted: true, labelWidth: I18N.isCn() ? 120 : 160 }}
          >
            <DMFormItem
              label={I18N.t('邀请码')}
              tooltip={I18N.t(
                '您的专属邀请码，通过该邀请码发展用户（该用户必须通过手机注册）后您将获得花瓣激励',
              )}
            >
              <CopyableText type={I18N.t('邀请码')} text={data?.code}>
                <Typography.Link>{data?.code}</Typography.Link>
              </CopyableText>
            </DMFormItem>
            <DMFormItem
              label={I18N.t('邀请链接')}
              tooltip={I18N.t(
                '您的专属邀请链接（内含邀请码），通过该邀请链接发展用户（该用户必须通过手机注册）后您将获得花瓣激励',
              )}
            >
              <CopyableText type={I18N.t('邀请链接')} text={data?.url}>
                <Typography.Link>{removeHttpPrefix(data?.url)}</Typography.Link>
              </CopyableText>
            </DMFormItem>
            <DMFormItem label={I18N.t('已发展的用户')}>{tableData?.userCount}</DMFormItem>
            <DMFormItem label={I18N.t('最近30天奖励花瓣')}>
              {data?.totalRewardAmount?.toLocaleString()}
            </DMFormItem>
          </DMFormItemContext.Provider>
        </main>

        {isElectron() && (
          <div
            style={{
              flex: '0 0 100px',
              padding: '0 12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              block
              style={{}}
              type={'primary'}
              onClick={() => {
                window.close();
              }}
            >
              {I18N.t('关闭')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default MyInviteCode;
