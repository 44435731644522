import I18N from '@/i18n';
import type { RowProps } from 'antd';
import { Col, Row, Tooltip } from 'antd';
import type { FC } from 'react';
import { useMemo } from 'react';
import IconFontIcon from '@/components/Common/IconFontIcon';

export type Order = {
  key: string;
  ascend: boolean;
};
interface SortProps extends RowProps {
  order: Order;
  orderKey?: string;
  label: JSX.Element | string;
  onSort?: (order: Order) => void;
  showSortTooltip?: boolean;
}

/**
 * 待排序功能的title
 * @param props
 * @constructor
 */
const SortTitle: FC<SortProps> = (props) => {
  const { orderKey, order, label, onClick, showSortTooltip = true, onSort, ...others } = props;
  const arrow = useMemo(() => {
    if (order.key === orderKey) {
      if (order.ascend) {
        return <IconFontIcon iconName="asc" />;
      }
      return <IconFontIcon iconName="desc" />;
    }
    return null;
  }, [order.ascend, order.key, orderKey]);
  if (!orderKey) {
    return label;
  }
  const innerNode = (
    <Row
      style={{ cursor: onSort && 'pointer' }}
      align="middle"
      wrap={false}
      {...others}
      onClick={(e) => {
        if (onSort) {
          e.preventDefault();
          onSort({
            key: orderKey,
            ascend: order.key === orderKey ? !order.ascend : true,
          });
        } else {
          onClick?.(e);
        }
      }}
    >
      <Col flex={1} style={{ overflow: 'hidden' }}>
        {label}
      </Col>
      <Col style={{ fontSize: '12px', color: '#999' }}>{arrow}</Col>
    </Row>
  );

  if (onSort && showSortTooltip) {
    return (
      <Tooltip title={order.ascend ? I18N.t('点击降序') : I18N.t('点击升序')}>{innerNode}</Tooltip>
    );
  }
  return innerNode;
};
export default SortTitle;
