import I18N from '@/i18n';
import type { ReactNode } from 'react';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import type { NodeJS } from 'timers';
import InfoTip from '@/components/Tips/InfoTip';
import { Button, Image, Result, Typography } from 'antd';
import styles from './index.less';
import buttonStyles from '@/style/button.less';

type Status = 'CONFLICTED' | 'EXPIRED' | 'FAIL' | 'REJECTED' | 'SCANNED' | 'SUCCESS' | 'WAIT_SCAN';

const QrCode = forwardRef(
  (
    props: {
      onScanned: (res: any) => void;
      getSrc: () => Promise<API.WxQrCodeVo>;
      getResult: (uuid: string) => Promise<Record<'status', any>>;
      title?: ReactNode;
    },
    ref,
  ) => {
    const { onScanned, getSrc, getResult, title } = props;
    const [url, setUrl] = useState('');
    const [status, setStatus] = useState<Status>('WAIT_SCAN');
    const [tips, setTips] = useState<string>();
    const timer = useRef<NodeJS.Timer>();
    const expireTimer = useRef<NodeJS.Timer>();
    const expireSeconds = useRef<number>(120);
    const fetch = useCallback(() => {
      clearInterval(timer.current);
      setStatus('WAIT_SCAN');
      getSrc()
        .then((res) => {
          setUrl(res.url!);
          clearInterval(timer.current);
          clearInterval(expireTimer.current);
          if (!res?.url) {
            setStatus('FAIL');
          } else {
            expireSeconds.current = res?.expireSeconds || 120;
            expireTimer.current = setInterval(() => {
              expireSeconds.current -= 1;
              if (expireSeconds.current === 0) {
                setStatus('EXPIRED');
                clearInterval(expireTimer.current);
                clearInterval(timer.current);
              }
            }, 1000);
            timer.current = setInterval(() => {
              getResult(res.uuid)
                .then((response) => {
                  const { status: rStatus, message } = response;
                  setStatus(response.status);
                  if (message) {
                    setTips(message);
                  }
                  if (!['SCANNED', 'WAIT_SCAN'].includes(rStatus)) {
                    clearInterval(timer.current);
                    onScanned(response);
                  }
                })
                .catch((e) => {
                  // 失败
                  const { status: rStatus, message } = e?.data || {};
                  setStatus(rStatus! || 'FAIL');
                  setTips(message || I18N.t('扫码失败'));
                  clearInterval(timer.current);
                });
            }, 1000);
          }
        })
        .catch(() => {
          setStatus('FAIL');
          setTips(I18N.t('二维码生成失败'));
          clearInterval(expireTimer.current);
          clearInterval(timer.current);
        });
    }, [getResult, getSrc, onScanned]);
    useEffect(() => {
      fetch();
      return () => {
        clearInterval(timer.current);
      };
    }, [fetch]);
    useImperativeHandle(ref, () => {
      return {
        refresh() {
          fetch();
        },
      };
    });
    const img = useMemo(() => {
      const size = 220;
      if (url) {
        return (
          <Image
            src={url}
            width={size}
            height={size}
            preview={false}
            onError={() => {
              setUrl('');
              setStatus('FAIL');
            }}
          />
        );
      }
      return <Image placeholder width={size} height={size} preview={false} />;
    }, [url]);

    const statusText = useMemo(() => {
      if (status !== 'WAIT_SCAN' || !title) {
        return null;
      }
      return <InfoTip message={title} />;
    }, [status, title]);
    const titleWrapper = useCallback((text: string) => {
      return (
        <Typography.Paragraph title={text} ellipsis={{ rows: 2 }}>
          {text}
        </Typography.Paragraph>
      );
    }, []);
    const code = useMemo(() => {
      if (status === 'EXPIRED') {
        return (
          <Result
            className={styles.wechatResult}
            status={'warning'}
            title={titleWrapper(I18N.t('二维码已失效'))}
          >
            <Button block className={buttonStyles.successBtn} onClick={fetch}>
              {I18N.t('重试')}
            </Button>
          </Result>
        );
      }
      if (status === 'SCANNED') {
        return (
          <Result
            className={styles.wechatResult}
            status={'success'}
            title={titleWrapper(I18N.t('扫描成功'))}
          >
            <Button block className={buttonStyles.successBtn} onClick={fetch}>
              {I18N.t('重新获取')}
            </Button>
          </Result>
        );
      }
      if (status === 'FAIL') {
        return (
          <Result className={styles.wechatResult} status={'error'} title={titleWrapper(tips)}>
            <Button block className={buttonStyles.successBtn} onClick={fetch}>
              {I18N.t('重试')}
            </Button>
          </Result>
        );
      }
      if (status === 'REJECTED') {
        return (
          <Result
            className={styles.wechatResult}
            status={'error'}
            title={titleWrapper(I18N.t('你已取消此次登录'))}
          >
            <Button className={buttonStyles.successBtn} onClick={fetch}>
              {I18N.t('重试')}
            </Button>
          </Result>
        );
      }
      return (
        <div style={{ position: 'relative' }}>
          {img}
          {url && <div className={styles.logo} />}
        </div>
      );
    }, [fetch, img, status, tips, titleWrapper, url]);

    return (
      <div className={styles.wechatCode}>
        {code}
        {statusText}
      </div>
    );
  },
);
export default QrCode;
