import { ReactNode, useEffect, useRef, useState } from 'react';
import { Button } from 'antd';

export default function CountdownBtn(props: {
  seconds: number;
  availableAllTime?: boolean;
  autoClick?: boolean;
  children: ReactNode;
  [k: string]: any;
}) {
  const { seconds, availableAllTime = false, autoClick = false, children, ...otherProps } = props;
  const [remain, setRemain] = useState(seconds);
  const remainRef = useRef(remain);

  useEffect(() => {
    remainRef.current = remain;
  }, [remain]);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemain(remainRef.current - 1);
      if (remainRef.current - 1 === 0) {
        clearInterval(timer);
        if (autoClick) {
          props.onClick?.();
        }
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [autoClick, props]);

  return (
    <Button disabled={!availableAllTime && remain > 0} {...otherProps}>
      {children}
      {remain > 0 && <span>（{remain}）</span>}
    </Button>
  );
}
