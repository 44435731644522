import I18N from '@/i18n';
import React, { useMemo, useState } from 'react';
import { useRequest } from 'umi';
import { Button, message as antMessage } from 'antd';
import { teamAuditByAuditIdGet } from '@/services/api-Account/TeamController';
import {
  shopShareAuditByAuditIdPassPut,
  shopShareAuditByAuditIdRejectPut,
} from '@/services/api-ShopAPI/ShopShareController';
import styles from '@/components/MessageCenter/components/messageDetail.less';
import DMModal from '@/components/Common/Modal/DMModal';
import buttonStyles from '@/style/button.less';
import PlatformCateIcon from '@/components/Common/ShopCategorySelector/PlatformCateIcon';
import ColoursIcon from '@/components/Common/ColoursIcon';
import {
  shopAuditByAuditIdPassPut,
  shopAuditByAuditIdRejectPut,
} from '@/services/api-ShopAPI/ShopController';
import { TEAM_INSUFFICIENT_CREDIT } from '@/constants/ErrorCode';
import DMConfirm from '@/components/Common/DMConfirm';
import { SkipErrorNotifyOption } from '@/utils/utils';
import ProTable from '@ant-design/pro-table';
import { scrollProTableOptionFn } from '@/mixins/table';

type Props = {
  message: API.UserMessageVo;
};

const statusMap = {
  NEW: I18N.t('待接收'),
  APPROVED: I18N.t('已接收'),
  NOT_PASS: I18N.t('已拒绝'),
};

/**
 * 手机联营
 * @param props
 * @constructor
 */
const ShopSharingDetail: React.FC<Props> = (props) => {
  const { message } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const { messageType, content, resourceId } = message;
  let parameters: any = {};
  try {
    parameters = JSON.parse(message.parameters ?? '{}');
  } catch (e) {
    console.error('JSON parse failed', message.parameters);
  }
  const { data = {}, run: reloadAudit } = useRequest(
    () => {
      if (parameters.result !== 'Cancelled') {
        return teamAuditByAuditIdGet({ auditId: resourceId! });
      }
      return Promise.resolve({ data: {} });
    },
    {
      refreshDeps: [resourceId],
    },
  );

  const { status = '', paramContent, handleUser = {} } = data;
  let params: any = {};
  try {
    params = JSON.parse(paramContent ?? '{}');
  } catch (e) {
    console.error('JSON parse failed', paramContent);
  }
  const modalCon = useMemo(() => {
    return (
      <DMModal
        visible={modalVisible}
        title={I18N.t('详情')}
        width={680}
        onCancel={() => setModalVisible(false)}
        footer={
          status === 'NEW'
            ? [
                <Button
                  key={'accept'}
                  type="primary"
                  className={buttonStyles.successBtn}
                  onClick={() => {
                    shopShareAuditByAuditIdPassPut(
                      {
                        auditId: resourceId!,
                      },
                      SkipErrorNotifyOption,
                    )
                      .then(() => reloadAudit())
                      .catch((error) => {
                        if (error?.data?.code === TEAM_INSUFFICIENT_CREDIT) {
                          DMConfirm({
                            title: I18N.t('当前团队的花瓣余额不够'),
                            content: I18N.t(
                              '当前团队的花瓣余额必须大于所转让手机一天的花瓣消耗量才允许接收手机',
                            ),
                          });
                        } else {
                          antMessage.error(error?.message);
                        }
                      });
                  }}
                >
                  {I18N.t('全部接收')}
                </Button>,
                <Button
                  type="primary"
                  danger
                  key={'reject'}
                  onClick={() => {
                    shopShareAuditByAuditIdRejectPut({
                      auditId: resourceId!,
                    }).then(() => reloadAudit());
                  }}
                >
                  {I18N.t('全部拒绝')}
                </Button>,
                <Button type="primary" key={'close'} onClick={() => setModalVisible(false)}>
                  {I18N.t('关闭')}
                </Button>,
              ]
            : [
                <Button type="primary" key={'close'} onClick={() => setModalVisible(false)}>
                  {I18N.t('关闭')}
                </Button>,
              ]
        }
      >
        <div style={{ height: 400, overflow: 'hidden' }}>
          <ProTable
            size="small"
            dataSource={params?.mobiles ? params.mobiles : [params]}
            {...scrollProTableOptionFn({
              pagination: false,
            })}
            rowKey={'mobileId'}
            columns={[
              {
                title: I18N.t('手机名称'),
                dataIndex: 'mobileName',
                ellipsis: true,
              },
              { title: I18N.t('状态'), key: 'status', width: 80, render: () => statusMap[status] },
            ]}
          />
        </div>
      </DMModal>
    );
  }, [messageType, modalVisible, params, reloadAudit, resourceId, status]);
  return (
    <>
      <p>
        {content ?? '--'}
        <a style={{ marginLeft: 20 }} onClick={() => setModalVisible(true)}>
          {I18N.t('查看详情')}
        </a>
      </p>
      <div className={styles.table}>
        <div className={styles.tableRow}>
          <div>{I18N.t('发起者')}</div>
          <div>{params.sourceUserNickname ?? '--'}</div>
        </div>
        <div className={styles.tableRow}>
          <div>{I18N.t('手机')}</div>
          <div>{params.sourceUserPhone ?? '--'}</div>
        </div>
        <div className={styles.tableRow}>
          <div>{I18N.t('手机数量')}</div>
          <div>
            <a onClick={() => setModalVisible(true)}>
              {params?.mobiles?.length || 1}
              {I18N.t('个')}
            </a>
          </div>
        </div>
      </div>
      {modalCon}
    </>
  );
};

export default ShopSharingDetail;
