import EventEmitter from 'events';
import { useEffect, useState } from 'react';

const event = new EventEmitter();
const On = (props: { id: symbol }) => {
  const { id } = props;
  const [node, setNode] = useState(null);
  useEffect(() => {
    event.on(id, setNode);
    return () => {
      event.off(id, setNode);
    };
  }, [id]);
  return node;
};
const Emit = (props: { id: symbol; children?: any }) => {
  const { id, children } = props;
  useEffect(() => {
    if (children) {
      event.emit(id, children);
    } else {
      event.emit(id, null);
    }
    return () => {
      event.emit(id, null);
    };
  }, [children, id]);
  return null;
};
const Slot = {
  On,
  Emit,
  event,
};
export default Slot;
