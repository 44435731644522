import I18N from '@/i18n';
import { shopSessionBySessionIdClosedPut } from '@/services/api-ShopAPI/ShopSessionController';
import { isElectron, sendSync } from '@/utils/ElectronUtils';
import { getTeamEntryUrl, getTeamIdFromUrl } from '@/utils/utils';
import { Checkbox, Spin, Typography } from 'antd';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { useModel, history } from 'umi';
import DMConfirm, { DMLoading } from '../DMConfirm';
import DMModal from '../Modal/DMModal';
import styles from './index.less';

interface Props {
  targetTeam?: API.TeamDto | null;
}

const SwitchTeamLoading = (props: Props) => {
  const { targetTeam } = props; // 检测是否有打开的分身会话
  const { initialState, setInitialState } = useModel('@@initialState');
  const [popoverVisible, setPopoverVisible] = useState(false);
  const checkShopSession = () => {
    return new Promise((resolve, reject) => {
      if (!isElectron()) {
        resolve(true);
        return;
      }
      // 检测是否有打开的分身会话
      const sessionIds = sendSync('get-opening-session-ids', { teamId: getTeamIdFromUrl() });
      if (sessionIds.length > 0) {
        let checked = false;
        const confirmRef = DMConfirm({
          width: 460,
          title: I18N.t('确定要切换团队吗？'),
          content: (
            <>
              <Typography.Paragraph>
                {I18N.t('您在当前团队正在打开访问分身的会话，请确认是否需要关闭这些会话')}
              </Typography.Paragraph>
              <Checkbox
                defaultChecked={false}
                onChange={() => {
                  checked = !checked;
                }}
                style={{ fontSize: 12 }}
              >
                {I18N.t('帮我中断我在当前团队中打开的所有分身访问会话')}
              </Checkbox>
            </>
          ),

          onOk: async () => {
            confirmRef.destroy();
            if (checked) {
              const loadingRef = DMLoading({
                width: 460,
                title: I18N.t('正在为您关闭分身会话'),
                content: I18N.t('分身访问会话结束后，将自动为您切换团队，请稍候...'),
              });
              Promise.all(
                sessionIds.map((sessionId: number) =>
                  shopSessionBySessionIdClosedPut({ sessionId }),
                ),
              ).catch((e) => {
                console.error(e);
              });
              loadingRef.destroy();
            }
            resolve(true);
          },
          onCancel: () => {
            reject();
          },
        });
      } else {
        resolve(true);
      }
    });
  };
  const enterTeamSpin = (team: API.TeamWithRoleVo) => {
    checkShopSession().then(() => {
      setInitialState({ ...initialState, teamId: team.id });
      setPopoverVisible(true);
      setTimeout(async () => {
        const url = await getTeamEntryUrl(team.id!);
        history.push(url);
        setTimeout(() => {
          setPopoverVisible(false);
        }, 2000);
      }, 2000);
    });
  };
  useEffect(() => {
    let teamId;
    try {
      teamId = getTeamIdFromUrl();
    } catch (e) {
      console.error(e);
    }
    if (targetTeam && targetTeam.id !== teamId) {
      enterTeamSpin(targetTeam);
    }
  }, [targetTeam]);
  return (
    <DMModal
      destroyOnClose
      visible={popoverVisible}
      wrapClassName={styles.switchTeamSpin}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      footer={null}
      closable={false}
    >
      <Space className="switchTeam-loading">
        <Spin />
        {I18N.t('正在进入团队“{{name}}”，请稍候...', {
          // 超过20位，截取前20位然后显示...，否则直接显示
          name:
            targetTeam?.name?.length! > 20
              ? targetTeam?.name?.substring(0, 20) + '...'
              : targetTeam?.name,
        })}
      </Space>
    </DMModal>
  );
};

export default SwitchTeamLoading;
