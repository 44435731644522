// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 判断是否对路径有权限 GET /api/disk/checkPathGranted */
export async function diskCheckPathGrantedGet(
  params: {
    // query
    /** path */
    path: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultboolean>('/api/disk/checkPathGranted', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 收藏文件（夹） POST /api/disk/collect */
export async function diskCollectPost(
  params: {
    // query
    /** diskType */
    diskType: 'LocalDisk' | 'TeamDisk' | 'UserDisk';
    /** name */
    name: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/disk/collect', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 文件（夹） POST /api/disk/copy */
export async function diskCopyPost(
  params: {
    // query
    /** sourceDiskType */
    sourceDiskType: 'LocalDisk' | 'TeamDisk' | 'UserDisk';
    /** targetDiskType */
    targetDiskType: 'LocalDisk' | 'TeamDisk' | 'UserDisk';
    /** srcName */
    srcName: string;
    /** targetName */
    targetName: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/disk/copy', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 创建目录（无任务） POST /api/disk/directory */
export async function diskDirectoryPost(
  params: {
    // query
    /** 相对网盘根目录的目录名 */
    name: string;
    /** diskType */
    diskType: 'LocalDisk' | 'TeamDisk' | 'UserDisk';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultCreateFileResult>('/api/disk/directory', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 分页查询收藏列表 GET /api/disk/favorites/page */
export async function diskFavoritesPageGet(
  params: {
    // query
    /** collectTimeFrom */
    collectTimeFrom?: string;
    /** collectTimeTo */
    collectTimeTo?: string;
    /** query */
    query?: string;
    /** sortField */
    sortField?: 'collectTime' | 'id' | 'name' | 'size';
    /** sortOrder */
    sortOrder?: 'asc' | 'desc';
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultFavoriteDto>('/api/disk/favorites/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 创建文件 POST /api/disk/file */
export async function diskFilePost(
  params: {
    // query
    /** 相对网盘根目录的文件名 */
    name: string;
    /** mineType */
    mineType?: string;
    /** diskType */
    diskType: 'LocalDisk' | 'TeamDisk' | 'UserDisk';
    /** size */
    size: number;
    /** confirm */
    confirm?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultCreateFileResult>('/api/disk/file', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 更新上传文件的状态 PUT /api/disk/file/${param0}/finished */
export async function diskFileByFileIdFinishedPut(
  params: {
    // query
    /** status */
    status: 'Abort' | 'Fail' | 'Pending' | 'Running' | 'Success' | 'Timeout';
    /** remarks */
    remarks?: string;
    // path
    /** fileId */
    fileId: number;
  },
  options?: { [key: string]: any },
) {
  const { fileId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/disk/file/${param0}/finished`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 删除文件或目录（会递归删除子目录和文件） DELETE /api/disk/fileOrDirectory */
export async function diskFileOrDirectoryDelete(
  params: {
    // query
    /** 相对网盘根目录的目录名或文件名 */
    name: string;
    /** diskType */
    diskType: 'LocalDisk' | 'TeamDisk' | 'UserDisk';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/disk/fileOrDirectory', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 移动文件（夹） POST /api/disk/move */
export async function diskMovePost(
  params: {
    // query
    /** sourceDiskType */
    sourceDiskType: 'LocalDisk' | 'TeamDisk' | 'UserDisk';
    /** targetDiskType */
    targetDiskType: 'LocalDisk' | 'TeamDisk' | 'UserDisk';
    /** srcName */
    srcName: string;
    /** targetName */
    targetName: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/disk/move', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取下载签名URL GET /api/disk/preSignUrl */
export async function diskPreSignUrlGet(
  params: {
    // query
    /** diskType */
    diskType: 'LocalDisk' | 'TeamDisk' | 'UserDisk';
    /** name */
    name: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultstring>('/api/disk/preSignUrl', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询可用的上传路径 GET /api/disk/prepareName */
export async function diskPrepareNameGet(
  params: {
    // query
    /** diskType */
    diskType: 'LocalDisk' | 'TeamDisk' | 'UserDisk';
    /** name */
    name: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultstring>('/api/disk/prepareName', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 团队网盘根目录授权给用户 POST /api/disk/team/grantToUsers */
export async function diskTeamGrantToUsersPost(
  body: API.DiskRootGrant2UsersRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/disk/team/grantToUsers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列出团队网盘中的文件 GET /api/disk/team/list */
export async function diskTeamListGet(
  params: {
    // query
    /** nextMarker */
    nextMarker?: string;
    /** path */
    path?: string;
    /** limit */
    limit?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultOssListResult>('/api/disk/team/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取团队网盘根目录的详情 GET /api/disk/team/root */
export async function diskTeamRootGet(
  params: {
    // query
    /** path */
    path: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultTeamDiskRootVo>('/api/disk/team/root', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取团队网盘签名 GET /api/disk/team/signature */
export async function diskTeamSignatureGet(options?: { [key: string]: any }) {
  return request<API.WebResultDiskStsVo>('/api/disk/team/signature', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 客户端出发一个网盘路径修改事件 GET /api/disk/triggerChanged */
export async function diskTriggerChangedGet(
  params: {
    // query
    /** path */
    path: string;
    /** eventType */
    eventType: 'Created' | 'Deleted' | 'Modified';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/disk/triggerChanged', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取个人网盘签名 GET /api/disk/user/signature */
export async function diskUserSignatureGet(options?: { [key: string]: any }) {
  return request<API.WebResultDiskStsVo>('/api/disk/user/signature', {
    method: 'GET',
    ...(options || {}),
  });
}
