import I18N from '@/i18n';
import type { ButtonProps } from 'antd';
import { Button } from 'antd';
import _ from 'lodash';
import useCurrentTeam from '@/hooks/useCurrentTeam';
import useCurrentUser from '@/hooks/useCurrentUser';
import DMConfirm from '@/components/Common/DMConfirm';
import type { ReactNode } from 'react';
import React, { useCallback, useMemo } from 'react';

// 测试代码，用于无权限交互校验
const testCodes: string[] = [];
export function useAuthJudgeCallback() {
  const { hasAuthedFunction } = useCurrentTeam();
  const { user } = useCurrentUser();
  return useCallback(
    (code: string | string[]) => {
      if (typeof code === 'string' && testCodes.includes(code)) {
        return false;
      }
      if (_.isArray(code) && _.some(code, (c) => testCodes.includes(c))) {
        return false;
      }
      return hasAuthedFunction(code, user?.id);
    },
    [hasAuthedFunction, user?.id],
  );
}
export function useAuthJudgeResult(code: string | string[]) {
  const hasAuthFn = useAuthJudgeCallback();
  return useMemo<boolean>(() => {
    return hasAuthFn(code);
  }, [code, hasAuthFn]);
}

export const NO_AUTH_TIP = I18N.t(
  '您当前的角色身份没有权限执行此类操作，请联络团队管理员以获取进一步的信息',
);
export function showAlert(content: ReactNode = NO_AUTH_TIP) {
  DMConfirm({
    type: 'warn',
    title: I18N.t('无权限执行此类操作'),
    content,
  });
}

interface FunctionProps {
  as?: React.ComponentClass | 'string';
  code: string | string[];
  feedback?: 'alert' | 'hidden' | 'disabled' | 'none';
  description?: ReactNode;
  disabled?: boolean;
  onClick?: (e: Event) => void;
  children?: any;
}
export function FunctionCodeComponent<T extends Record<string, any>>(
  props: T | FunctionProps,
): JSX.Element | null {
  const {
    as,
    code,
    feedback = 'alert',
    description = I18N.t(
      '您当前的角色身份没有权限执行此类操作，请联络团队管理员以获取进一步的信息',
    ),
    disabled,
    children,
    onClick,
    ...others
  } = props as T & FunctionProps;
  const hasAuth = useAuthJudgeResult(code);
  const innerDisabled = useMemo(() => {
    if (!hasAuth && feedback === 'disabled') {
      return true;
    }
    return disabled;
  }, [disabled, feedback, hasAuth]);
  if (!hasAuth && feedback === 'hidden') {
    return null;
  }
  const ps = {
    onClick(e: Event) {
      if (!hasAuth && feedback === 'alert') {
        showAlert(description);
        return false;
      }
      if (!hasAuth && feedback === 'none') {
        return false;
      }
      if (onClick) {
        return onClick(e);
      }
      return true;
    },
    disabled: innerDisabled,
    ...others,
  };
  if (typeof as === 'string') {
    return React.createElement(as, ps, children);
  }
  const Component = as || Button;
  return <Component {...ps}>{children}</Component>;
}
/**
 * 权限按钮
 * @param props
 * @constructor
 */
const FunctionButton = (
  props: ButtonProps & {
    as?: React.ComponentClass | 'string';
    code: string | string[];
    feedback?: 'alert' | 'hidden' | 'disabled' | 'none';
    pure?: boolean;
    description?: ReactNode;
  },
) => {
  const { style = {}, pure, description, ...others } = props;
  const styleObj = { ...style };
  if (pure) {
    styleObj.padding = 0;
    styleObj.height = 'auto';
    styleObj.border = 'none';
  }

  return (
    <FunctionCodeComponent<ButtonProps> style={styleObj} description={description} {...others} />
  );
};
export default FunctionButton;
