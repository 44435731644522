import I18N from '@/i18n';
import { Space, Row, Col, Typography, Statistic, Form, Input, message } from 'antd';
import { useState, useMemo, useCallback } from 'react';
import { useRequest } from 'umi';
import moment from 'moment';
import ProCard from '@ant-design/pro-card';
import styles from './userInfo.less';
import { teamJoinListGet as joinList } from '@/services/api-Account/TeamController';
import {
  accountAvatarPost as avatar,
  accountPhonePut,
  accountEmailPut,
  accountNicknameAndSignaturePut,
  accountNotifyWeixinBindByTeamIdPut,
  accountUnbindPhonePut,
  accountUnbindEmailPut,
} from '@/services/api-Account/AccountController';
import IconFontIcon from '@/components/Common/IconFontIcon';
import DMModal from '@/components/Common/Modal/DMModal';
import { Avatar } from '@/components/Common/UserAvatarAndName';
import constants from '@/constants';
import { dateFormat, getApiUrl, removeHttpPrefix, SkipErrorNotifyOption } from '@/utils/utils';
import { CaptchaGroup } from '@/components/Common/CaptchaButton';
import NicknameModal from './components/NicknameModal';
import { GhostModalCaller, useModalCaller } from '@/mixins/modal';
import QrCode from '@/components/QrCode';
import DMConfirm from '@/components/Common/DMConfirm';
import useCurrentUser, { fetchCurrentUser } from '@/hooks/useCurrentUser';
import validate from '@/utils/validate';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import { accountCheckEmailOrPhoneGet } from '@/services/api-Account/RegisterController';
import {
  wxBindQrCodeGet,
  wxBindQrCodeResultGet,
  wxUnbindPut,
} from '@/services/api-Weixin/WxQrcodeController';
import useCurrentTeam from '@/hooks/useCurrentTeam';
import _ from 'lodash';
import { EmailCaptchaGroup } from '../Common/EmailCaptchaButton';
import Placeholder from '@/components/Common/Placeholder';
import AvatarModal from '@/components/Common/AvatarModal';
import CopyableText from '@/components/Common/CopyableText';
import PhoneNumberInput from '@/components/PhoneNumberInput';
import ProTable from '@ant-design/pro-table';
import { scrollProTableOptionFn } from '@/mixins/table';
import { promotionMyPromoUrlGet } from '@/services/api-Account/UserPromotionController';
import { RewardDetailModal, usePromotionList, useRewardStatus } from '@/pages/MyInviteCode';
import { useVT } from 'virtualizedtableforantd4';
import { isElectron } from '@/utils/ElectronUtils';
import { hackModalPosition } from '@/components/LeftMenu';
let inviteWindow: Window | null;

export const PhoneModal = () => {
  const [visible, changeVisible] = useState(true);
  const [form] = Form.useForm();
  const { user } = useCurrentUser();
  const title = useMemo(() => {
    if (!user?.phone) {
      return I18N.t('绑定手机');
    }
    validate.phone(user?.phone).then((result) => {
      if (result.isValid) {
        form.setFieldsValue({
          phone: result.phoneNumber.replace(result.countryCode, ''),
          areaCode: result.countryCode.replace('+', ''),
        });
      }
    });

    return I18N.t('修改手机号');
  }, [user?.phone]);

  return (
    <DMModal
      title={title}
      visible={visible}
      destroyOnClose={true}
      onCancel={() => changeVisible(false)}
      onOk={() => {
        form.submit();
      }}
    >
      <DMFormItemContext.Provider value={{ disableLabelMuted: true, labelWidth: 70 }}>
        <Form
          requiredMark={false}
          form={form}
          onFinish={async (values) => {
            const { phone, verifyCode, areaCode } = values;
            accountCheckEmailOrPhoneGet(
              {
                account: _.trim(phone),
                areaCode,
              },
              SkipErrorNotifyOption,
            )
              .then(async () => {
                await accountPhonePut({
                  phone: _.trim(phone),
                  verifyCode: _.trim(verifyCode),
                  areaCode,
                });
                changeVisible(false);
                fetchCurrentUser();
              })
              .catch(() => {
                message.error(I18N.t('该手机号已经绑定其它账号，不可重复绑定'));
              });
          }}
        >
          {user?.phone && (
            <DMFormItem label={I18N.t('已绑号码')}>
              {user?.phone?.replace(/^(\d{3})(\d{4})(\d{4})/, '$1****$3')}
            </DMFormItem>
          )}

          <DMFormItem
            label={user?.phone ? I18N.t('新手机号') : I18N.t('手机号')}
            style={{ marginBottom: 0 }}
            shouldUpdate
          >
            {(f) => {
              return (
                <PhoneNumberInput
                  f={f}
                  size={'middle'}
                  selectProps={{}}
                  inputProps={{
                    onPressEnter: () => {
                      form.submit();
                    },
                    autoFocus: true,
                  }}
                />
              );
            }}
          </DMFormItem>
          <DMFormItem shouldUpdate noStyle>
            {(f) => {
              return (
                <CaptchaGroup
                  type="resetPhone"
                  areaCode={f.getFieldValue('areaCode')}
                  label={I18N.t('验证码')}
                  form={form}
                  identify="phone"
                />
              );
            }}
          </DMFormItem>
        </Form>
      </DMFormItemContext.Provider>
    </DMModal>
  );
};

export const EmailModal = () => {
  const [visible, changeVisible] = useState(true);
  const [form] = Form.useForm();
  const { user } = useCurrentUser();
  const title = useMemo(() => {
    if (!user?.email) {
      return I18N.t('绑定邮箱');
    }
    return I18N.t('修改邮箱');
  }, [user?.email]);
  return (
    <DMModal
      title={title}
      visible={visible}
      destroyOnClose={true}
      onCancel={() => changeVisible(false)}
      onOk={() => {
        form.submit();
      }}
    >
      <DMFormItemContext.Provider value={{ disableLabelMuted: true, labelWidth: 70 }}>
        <Form
          requiredMark={false}
          form={form}
          onFinish={async (values) => {
            const { email, verifyCode } = values;
            accountCheckEmailOrPhoneGet(
              {
                account: _.trim(email),
              },
              SkipErrorNotifyOption,
            )
              .then(async () => {
                await accountEmailPut({ email: _.trim(email), verifyCode: _.trim(verifyCode) });
                changeVisible(false);
                fetchCurrentUser();
              })
              .catch(() => {
                message.error(I18N.t('该邮箱已经绑定其它账号，不可重复绑定'));
              });
          }}
        >
          {user?.email && <DMFormItem label={I18N.t('已绑邮箱')}>{user?.email}</DMFormItem>}
          <DMFormItem
            label={user?.email ? I18N.t('新邮箱') : I18N.t('邮箱')}
            name="email"
            rules={[
              {
                validator(rule, value) {
                  return validate.email(value);
                },
              },
              {
                validator(rule, value) {
                  return new Promise((resolve, reject) => {
                    const val = _.trim(value);
                    if (user?.email === value) {
                      return reject(new Error(I18N.t('重复的邮箱')));
                    }
                    return resolve(val);
                  });
                },
              },
            ]}
          >
            <Input
              autoFocus
              onPressEnter={() => {
                form.submit();
              }}
            />
          </DMFormItem>
          <EmailCaptchaGroup
            type="bindEmail"
            label={I18N.t('验证码')}
            form={form}
            identify="email"
          />
        </Form>
      </DMFormItemContext.Provider>
    </DMModal>
  );
};
const InviteCodeModal = () => {
  const { data } = useRequest(() => {
    return promotionMyPromoUrlGet();
  });
  const { data: tableData, loading } = usePromotionList();
  const onClick = useCallback((record: API.UserPromotedRecordVo) => {
    GhostModalCaller(<RewardDetailModal record={record} showActivateButton />);
  }, []);
  const [vt, setVt] = useVT(() => {
    return {
      overscanRowCount: 20,
      scroll: {
        y: 300,
      },
    };
  }, []);

  return (
    <div
      style={{
        width: 320,
        padding: '24px 24px 0 24px',
        background: 'white',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DMFormItem
        disableLabelMuted
        labelWidth={I18N.isCn() ? 100 : 150}
        label={I18N.t('邀请码')}
        tooltip={I18N.t(
          '您的专属邀请码，通过该邀请码发展用户（该用户必须通过手机注册）后您将获得花瓣激励',
        )}
      >
        <CopyableText type={I18N.t('邀请码')} text={data?.code}>
          <Typography.Link ellipsis>{data?.code}</Typography.Link>
        </CopyableText>
      </DMFormItem>
      <DMFormItem
        disableLabelMuted
        labelWidth={I18N.isCn() ? 100 : 150}
        label={I18N.t('邀请链接')}
        tooltip={I18N.t(
          '您的专属邀请链接（内含邀请码），通过该邀请链接发展用户（该用户必须通过手机注册）后您将获得花瓣激励',
        )}
      >
        <CopyableText type={I18N.t('邀请链接')} text={data?.url}>
          <Typography.Link ellipsis>{removeHttpPrefix(data?.url)}</Typography.Link>
        </CopyableText>
      </DMFormItem>
      <div style={{ flex: 1, overflow: 'hidden' }}>
        <ProTable
          components={vt}
          cardBordered
          loading={loading}
          dataSource={tableData?.list}
          size={'small'}
          {...scrollProTableOptionFn({
            pagination: false,
          })}
          columns={[
            {
              dataIndex: 'nickname',
              title: I18N.t('奖励原因'),
              ellipsis: true,
              renderText(_text, record) {
                const { id, nickname } = record;
                if (id === 'bind_phone') {
                  return I18N.t('绑定手机');
                } else if (id === 'bind_wechat') {
                  return I18N.t('绑定微信');
                }
                return nickname;
              },
            },
            {
              dataIndex: 'createTime',
              width: '90px',
              ellipsis: true,
              title: I18N.t('奖励时间'),
              render(_text, record) {
                const { createTime } = record;
                return dateFormat(new Date(createTime), 'yyyy-MM-dd');
              },
            },
            {
              dataIndex: 'status',
              title: I18N.t('花瓣'),
              ellipsis: true,
              width: '60px',
              render(_text, record) {
                const { rewardAmount, id } = record;
                const activated = useRewardStatus(id, !!record?.activatedTeamId);
                return (
                  <Typography.Text
                    style={{ cursor: 'pointer' }}
                    type={!activated ? 'success' : 'secondary'}
                    onClick={() => {
                      onClick(record);
                    }}
                  >
                    {rewardAmount.toLocaleString()}
                  </Typography.Text>
                );
              },
            },
          ]}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flex: '0 0 36px',
        }}
      >
        <Typography.Text type={'secondary'}>
          {I18N.t('最近30天奖励花瓣')}：{data?.totalRewardAmount?.toLocaleString()}
        </Typography.Text>
        <Typography.Link
          onClick={() => {
            if (isElectron()) {
              if (inviteWindow && !inviteWindow?.closed) {
                inviteWindow.ipcRenderer?.invoke?.('window-action', {
                  action: 'show',
                });
                return;
              }
              inviteWindow = window.open(
                '/myProfile',
                'myProfile',
                'width=1000,height=690',
              ) as unknown as Window;
            } else {
              if (inviteWindow && !inviteWindow.closed) {
                inviteWindow.focus();
                return;
              }
              inviteWindow = window.open('/myProfile', 'myProfile');
            }
          }}
        >
          {I18N.t('查看更多')}
        </Typography.Link>
      </div>
    </div>
  );
};
const UserInfoModal = () => {
  const { user } = useCurrentUser();
  const [visible, changeVisible] = useState(true);
  const { team } = useCurrentTeam();
  const { data } = useRequest(joinList, {
    formatResult: (rs) => rs,
  });
  const modalCaller = useModalCaller();
  const [nicknameModalVisible, setNicknameModalVisible] = useState(false);

  // 修改昵称
  const handleNicknameModalSubmit = async (params: {
    nickname: string;
    updateTeamNickname: boolean;
    signature: string;
  }) => {
    await accountNicknameAndSignaturePut(params);
    setNicknameModalVisible(false);
    fetchCurrentUser();
  };
  const wxBind = useMemo(
    () => _.find(user?.openAccounts || [], (item) => item.oauthType === 'WEIXIN'),
    [user?.openAccounts],
  );
  const phoneBind = useMemo(() => !!user?.phone, [user?.phone]);
  const emailBind = useMemo(() => !!user?.email, [user?.email]);
  const wechatStatus = useMemo(() => {
    if (wxBind) {
      return (
        <div className="bind-status">
          <Typography.Text>{I18N.t('已绑定')}</Typography.Text>
          <Space>
            <Typography.Link
              onClick={() => {
                if (user?.phone) {
                  DMConfirm({
                    title: I18N.t('确定要解绑微信账号吗？'),
                    content: I18N.t('一旦解绑则无法通过微信接收花漾的站内消息'),
                    onOk: async () => {
                      await wxUnbindPut();
                      fetchCurrentUser();
                      message.success(I18N.t('微信解绑成功'));
                    },
                  });
                } else {
                  DMConfirm({
                    type: 'info',
                    title: I18N.t('未绑定手机号无法解绑微信'),
                    content: I18N.t('您需要先为该账号绑定一个手机号，然后再解绑微信'),
                  });
                }
              }}
            >
              {I18N.t('解绑')}
            </Typography.Link>
          </Space>
        </div>
      );
    }
    return (
      <div className="bind-status">
        <div>
          <Placeholder>{I18N.t('未绑定')}</Placeholder>
        </div>
        <Typography.Link
          id="bind-weixin-btn"
          onClick={() => {
            modalCaller({
              title: null,
              footer: null,
              width: 300,
              bodyStyle: {
                height: 300,
              },
              component: ({ close }) => {
                return (
                  <QrCode
                    title={I18N.t('请使用微信扫一扫完成绑定')}
                    getSrc={() => {
                      return wxBindQrCodeGet().then((res) => {
                        return res.data;
                      });
                    }}
                    getResult={(qrcodeId) => {
                      return wxBindQrCodeResultGet({ qrcodeId }).then((res) => {
                        return res.data;
                      });
                    }}
                    onScanned={async (res) => {
                      close();
                      const { status } = res;
                      if (status === 'SUCCESS') {
                        message.success(I18N.t('微信绑定成功'));
                        await accountNotifyWeixinBindByTeamIdPut({ teamId: team.id! });
                        fetchCurrentUser();
                      } else {
                        DMConfirm({
                          type: 'warn',
                          title: I18N.t('该微信号已经绑定其它账号'),
                          content: I18N.t(
                            '一个微信账号只能绑定一个花漾账号，请更换其它的微信号完成绑定',
                          ),
                        });
                      }
                    }}
                  />
                );
              },
            });
          }}
        >
          {I18N.t('立即绑定')}
        </Typography.Link>
      </div>
    );
  }, [modalCaller, team?.id, user?.phone, wxBind]);
  const phoneStatus = useMemo(() => {
    if (phoneBind) {
      return (
        <div className="bind-status">
          <Typography.Text ellipsis>
            {user?.phone?.replace(/^(\d{3})(\d{4})(\d{4})/, '$1****$3')}
          </Typography.Text>
          <Space>
            {emailBind && (
              <Typography.Link
                onClick={() => {
                  DMConfirm({
                    title: I18N.t('确定要解绑手机吗？'),
                    content: I18N.t('解绑后需要重新绑定'),
                    onOk() {
                      accountUnbindPhonePut().then(() => {
                        fetchCurrentUser();
                      });
                    },
                  });
                }}
              >
                {I18N.t('解绑')}
              </Typography.Link>
            )}

            <Typography.Link
              onClick={() => {
                modalCaller({
                  ghost: true,
                  component: <PhoneModal />,
                });
              }}
            >
              {I18N.t('更换')}
            </Typography.Link>
          </Space>
        </div>
      );
    }
    return (
      <div className="bind-status">
        <div>
          <Placeholder>{I18N.t('未绑定')}</Placeholder>
        </div>
        <Typography.Link
          onClick={() => {
            modalCaller({
              ghost: true,
              component: <PhoneModal />,
            });
          }}
        >
          {I18N.t('立即绑定')}
        </Typography.Link>
      </div>
    );
  }, [emailBind, modalCaller, phoneBind, user?.phone]);
  const emailStatus = useMemo(() => {
    if (emailBind) {
      return (
        <div className="bind-status">
          <Typography.Text ellipsis title={user?.email}>
            {user?.email}
          </Typography.Text>
          <Space>
            {phoneBind && (
              <Typography.Link
                onClick={() => {
                  DMConfirm({
                    title: I18N.t('确定要解绑邮箱吗？'),
                    content: I18N.t('解绑后需要重新绑定'),
                    onOk() {
                      accountUnbindEmailPut().then(() => {
                        fetchCurrentUser();
                      });
                    },
                  });
                }}
              >
                {I18N.t('解绑')}
              </Typography.Link>
            )}

            <Typography.Link
              onClick={() => {
                modalCaller({
                  ghost: true,
                  component: <EmailModal />,
                });
              }}
            >
              {I18N.t('更换')}
            </Typography.Link>
          </Space>
        </div>
      );
    }
    return (
      <div className="bind-status">
        <div>
          <Placeholder>{I18N.t('未绑定')}</Placeholder>
        </div>
        <Typography.Link
          id={user?.email ? '' : 'bind-email-btn'}
          ellipsis
          title={user?.email ? user?.email : I18N.t('立即绑定')}
          onClick={() => {
            modalCaller({
              ghost: true,
              component: <EmailModal />,
            });
          }}
        >
          {I18N.t('立即绑定')}
        </Typography.Link>
      </div>
    );
  }, [emailBind, modalCaller, phoneBind, user?.email]);

  return (
    <DMModal
      disabledDraggable
      visible={visible}
      width={620 + 320}
      asideWrapperStyle={{ flex: '0 0 320px' }}
      asidePanel={<InviteCodeModal />}
      footer={false}
      onCancel={() => {
        changeVisible(false);
      }}
      title={false}
      style={{
        paddingBottom: 0,
        borderRadius: 5,
        overflow: 'hidden',
      }}
      bodyStyle={{
        padding: 0,
      }}
      maskClosable
      closeIcon={<IconFontIcon iconName="guanbi_24" style={{ color: '#fff' }} />}
    >
      <div className={styles.headerWrap}>
        <div className={styles.title}>{I18N.t('个人资料')}</div>
        <Row gutter={24} align="middle" wrap={false} style={{ marginTop: 24 }}>
          <Col>
            <div
              className={styles.avatarWrap}
              onClick={() => {
                GhostModalCaller(
                  <AvatarModal
                    title={I18N.t('设置头像')}
                    avatar={user?.avatar ? getApiUrl(user.avatar) : constants.defaultAvatar}
                    onSubmit={async (src: string) => {
                      await avatar({ dataUrl: src });
                      fetchCurrentUser();
                    }}
                  />,
                );
              }}
            >
              <Avatar src={user?.avatar || constants.defaultAvatar} size={76} />
              <div className={styles.hoverTips}>
                <IconFontIcon iconName="shangchuantupian_24" />
              </div>
            </div>
          </Col>
          <Col flex="auto">
            <Typography.Title ellipsis level={4} style={{ width: 250, color: '#fff' }}>
              {user?.nickname}
            </Typography.Title>
            <div style={{ marginBottom: 4 }}>
              ID：
              <CopyableText type={I18N.t('用户ID')} text={user?.id}>
                {user?.id}
              </CopyableText>
            </div>
            <Typography.Paragraph
              ellipsis={{ rows: 2 }}
              style={{ marginBottom: 0, color: '#fff', opacity: 0.6 }}
            >
              {user?.signature || I18N.t('此人很懒，什么都没留下')}
            </Typography.Paragraph>

            <a className={styles.modifyBtn} onClick={() => setNicknameModalVisible(true)}>
              <IconFontIcon iconName="edit_24" />
              {I18N.t('修改昵称')}
            </a>
          </Col>
        </Row>
      </div>
      <Row style={{ marginTop: 32, marginBottom: 24, textAlign: 'center' }}>
        <ProCard.Group size="small">
          <ProCard bodyStyle={{ padding: 8 }}>
            <Statistic title={I18N.t('登录次数')} value={user?.userActivity?.loginCount} />
          </ProCard>
          <ProCard bodyStyle={{ padding: 8 }}>
            <Statistic
              title={I18N.t('注册时间')}
              value={moment(user?.createTime).format('YYYY-MM-DD')}
            />
          </ProCard>
          <ProCard bodyStyle={{ padding: 8 }}>
            <Statistic title={I18N.t('加入团队')} value={data?.data?.length} />
          </ProCard>
        </ProCard.Group>
      </Row>
      <ProCard title={I18N.t('绑定信息')} size="small" className={styles.bindInfoWrap}>
        <Row gutter={12} wrap={false} style={{ width: '100%' }}>
          <Col span={8}>
            <Typography.Text type="secondary">{I18N.t('手机号')}</Typography.Text>
            <div className={styles.bindMethodWrap}>
              <div className={styles.bindMethodIcon} style={{ backgroundColor: '#FF902F' }}>
                <IconFontIcon iconName="shouji_24" />
              </div>
              {phoneStatus}
            </div>
          </Col>
          <Col span={8}>
            <Typography.Text type="secondary">{I18N.t('微信')}</Typography.Text>
            <div className={styles.bindMethodWrap}>
              <div className={styles.bindMethodIcon} style={{ backgroundColor: '#4EC73D' }}>
                <IconFontIcon iconName="weixin_24" />
              </div>
              {wechatStatus}
            </div>
          </Col>
          <Col span={8}>
            <Typography.Text type="secondary">{I18N.t('电子邮箱')}</Typography.Text>
            <div className={styles.bindMethodWrap}>
              <div className={styles.bindMethodIcon}>
                <IconFontIcon iconName="xiaoxizhongxin_24" />
              </div>
              {emailStatus}
            </div>
          </Col>
        </Row>
      </ProCard>
      <NicknameModal
        visible={nicknameModalVisible}
        onCancel={() => setNicknameModalVisible(false)}
        onSubmit={handleNicknameModalSubmit}
      />
    </DMModal>
  );
};

const fn = _.throttle(() => {
  hackModalPosition();
  GhostModalCaller(<UserInfoModal />, 'UserInfo');
}, 300);
export default fn;
