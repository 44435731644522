// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 检查手机号或邮箱是否合法 如果是手机号，区号连接到account前面 GET /api/account/checkEmailOrPhone */
export async function accountCheckEmailOrPhoneGet(
  params: {
    // query
    /** areaCode */
    areaCode?: string;
    /** account */
    account: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/checkEmailOrPhone', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 校验邀请码 GET /api/account/checkInviteCode */
export async function accountCheckInviteCodeGet(
  params: {
    // query
    /** inviteCode */
    inviteCode: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/checkInviteCode', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 注册账号 POST /api/account/register */
export async function accountRegisterPost(
  params: {
    // query
    /** 邀请码，非必需 */
    inviteCode?: string;
    /** areaCode */
    areaCode?: string;
    /** 账号（手机号或邮箱） */
    account: string;
    /** 验证码 */
    verifyCode: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultRegisterAccountVo>('/api/account/register', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 系统注册配置信息 GET /api/account/registerConfig */
export async function accountRegisterConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultRegisterConfig>('/api/account/registerConfig', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取验证码 GET /api/account/verifyCode */
export async function accountVerifyCodeGet(
  params: {
    // query
    /** areaCode */
    areaCode?: string;
    /** 账号（手机号或邮箱） */
    account: string;
    /** 进行的操作 */
    action:
      | 'bindEmail'
      | 'download'
      | 'findPassword'
      | 'joinTeam'
      | 'login'
      | 'mobile3e'
      | 'partnerApply'
      | 'register'
      | 'resetPhone'
      | 'testing'
      | 'twoFa';
    /** captcha */
    captcha: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/verifyCode', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
