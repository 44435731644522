import I18N from '@/i18n';
import { Space, List, Typography } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useModel } from 'umi';
import EventEmitter from 'events';
import MiddleSpin from '@/components/Common/MiddleSpin';
import IconFontIcon from '@/components/Common/IconFontIcon';
import DMConfirm from '@/components/Common/DMConfirm';
import UserAvatarAndName from '@/components/Common/UserAvatarAndName';
import AccountSetting from './AccountSetting';
import UserSetting from './UserSetting';
import useCurrentUser from '@/hooks/useCurrentUser';
import openUserInfo from './UserInfo';
import { GhostModalCaller } from '@/mixins/modal';
import CancelAccount, {
  logoutAndCloseBrowsers,
} from '@/components/UserCenter/components/CancelAccount';

interface Props {
  hidePopover: () => void;
}

export const userCenterEmitter = new EventEmitter();

export const logoutConfirm = () => {
  const dialog = DMConfirm({
    title: I18N.t('确认退出？'),
    content: (
      <div>
        <div>{I18N.t('如果当前有打开花漾浏览器，这些浏览器将会被自动关闭，请确认是否继续')}</div>
        <div style={{ marginTop: 4 }}>
          {I18N.t('如需要注销账号，请点击{{action}}', {
            action: (
              <Typography.Link
                style={{ marginLeft: '1em' }}
                onClick={() => {
                  dialog.destroy();
                  GhostModalCaller(<CancelAccount />);
                }}
              >
                {I18N.t('账号销毁')}
              </Typography.Link>
            ),
          })}
        </div>
      </div>
    ),
    okText: I18N.t('确认'),
    cancelText: I18N.t('取消'),
    onOk() {
      logoutAndCloseBrowsers();
    },
  });
};

const UserCenter: React.FC<Props> = forwardRef(({ hidePopover }: Props, ref) => {
  const [userSettingSubMenu, setUserSettingSubMenu] = useState<string>();
  const { loading } = useModel('@@initialState');
  const { user } = useCurrentUser();
  const userInfoRef = React.createRef<HTMLAnchorElement>();
  const userSettingRef = React.createRef<HTMLAnchorElement>();

  // 点击头像时触发修改昵称
  const triggerUserInfo = () => {
    userInfoRef.current?.click();
  };

  useImperativeHandle(ref, () => ({
    openUserInfoModal: () => triggerUserInfo(),
    openUserSettingModal: (subMenu?: string) => {
      setUserSettingSubMenu(subMenu);
      userSettingRef.current?.click();
    },
  }));

  if (loading || !user) {
    return <MiddleSpin />;
  }

  return (
    <div className="userCenter">
      <Space direction="vertical">
        <div className="userAvatar" onClick={triggerUserInfo}>
          <UserAvatarAndName user={{ avatar: user.avatar }} avatarSize={60} />
          <Typography.Text ellipsis className="nickname" style={{ width: 230 }}>
            {user?.nickname}
          </Typography.Text>
        </div>
        <List>
          <List.Item key="userInfo" onClick={hidePopover}>
            <a
              ref={userInfoRef}
              onClick={() => {
                openUserInfo();
              }}
            >
              <IconFontIcon iconName="gerenziliao_24" />
              {I18N.t('个人资料')}
            </a>
          </List.Item>
          <List.Item key="userSetting" onClick={hidePopover}>
            <UserSetting triggerRef={userSettingRef} initMenu={userSettingSubMenu} />
          </List.Item>
          <List.Item key="accountSetting" onClick={hidePopover}>
            <AccountSetting />
          </List.Item>
          <List.Item key="logout" onClick={hidePopover}>
            <Typography.Link type="danger" onClick={logoutConfirm}>
              <IconFontIcon iconName="tuichu_24" />
              {I18N.t('退出登录')}
            </Typography.Link>
          </List.Item>
        </List>
      </Space>
    </div>
  );
});

export default UserCenter;
