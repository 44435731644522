import React, { ReactNode, useRef } from 'react';
import styles from './index.less';
import { Collapse } from 'antd';

type Props = {
  header: ReactNode;
  defaultOpen?: boolean;
};

/**
 *
 * @param props
 * @constructor
 */
const DmCollapse: React.FC<Props> = (props) => {
  const { header, defaultOpen = false, children } = props;
  const panelAnchorRef = useRef<any>(null);

  return (
    <Collapse
      ghost
      accordion
      defaultActiveKey={defaultOpen ? ['1'] : []}
      className={styles.collapse}
      onChange={(key) => {
        if (key && key.length > 0) {
          setTimeout(() => {
            panelAnchorRef.current?.scrollIntoViewIfNeeded();
          }, 400);
        }
      }}
    >
      <Collapse.Panel key="1" header={header} forceRender>
        {children}
        <span ref={panelAnchorRef} />
      </Collapse.Panel>
    </Collapse>
  );
};

export default DmCollapse;
