import I18N from '@/i18n';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Space, Result, Row, Col } from 'antd';
import Lottie from 'react-lottie';
import classNames from 'classnames';
import { history } from 'umi';
import colors from '@/style/color.less';
import buttonStyles from '@/style/button.less';
import styles from './newUserActivityModal.less';
import { useModalCaller } from '@/mixins/modal';
import lottieImg from '@/components/LeftMenu/lottie-assets/gift.json';
import DMConfirm from '@/components/Common/DMConfirm';
import { getTeamIdFromUrl } from '@/utils/utils';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { openInNewWindow } from '@/utils/pageUtils';

type Props = {
  view?: 'init' | 'step1' | 'ip' | 'step2';
  onClose: () => void;
};

export const newUserMissionOrder = ['2022.05.ac2.mark.ip', '2022.05.ac2.step2'];

export const newUserMissionText = {
  '2022.05.ac2.mark.ip': I18N.t('代金券一张，只需再支付2元即可购买公有云IP一个（为期一周）'),
  '2022.05.ac2.step2': I18N.t('创建并访问分身成功后，即可获得1000花瓣激励'),
};

export function showBuyIpConfirmModal() {
  DMConfirm({
    iconType: 'info',
    width: 550,
    title: I18N.t('恭喜，您已获得面值10元的代金券一张'),
    content: (
      <>
        <div>{I18N.t('1.只需再支付2元，您即可购买一个公有云IP（为期1周）')}</div>
        <div>{I18N.t('2.购买IP后，可将IP绑定在分身上，并访问分身以打开花漾灵动')}</div>
      </>
    ),

    okText: I18N.t('立即购买'),
    onOk: () => {
      history.push(`/team/${getTeamIdFromUrl()}/ipMarket`);
    },
  });
}

export function showCreateShopConfirmModal() {
  history.push(`/team/${getTeamIdFromUrl()}/shopManage/all`);
  DMConfirm({
    iconType: 'info',
    width: 550,
    title: I18N.t('创建分身并访问分身，即可获得1000花瓣激励'),
    content: (
      <>
        <div>{I18N.t('1.现在可以新建一个分身，每个分身就是一个完全隔离的浏览器分身环境')}</div>
        <div>{I18N.t('2.为分身绑定浏览器指纹实例和IP地址，访问此分身以打开花漾灵动')}</div>
        <div>{I18N.t('3.当成功打开花漾灵动后，您将再次获得1000花瓣的激励')}</div>
      </>
    ),

    okText: I18N.t('立即创建'),
    onOk: () => {
      history.push(`/team/${getTeamIdFromUrl()}/shopManage/all?import=true`);
    },
  });
}

/**
 * 新手有礼活动
 * @param props
 * @constructor
 */
const NewUserActivityModal: React.FC<Props> = (props) => {
  const { view = 'init', onClose } = props;
  const [modalStyle, setModalStyle] = useState({});

  const doClose = useCallback(() => {
    const left = -(document.body.offsetWidth - 900) / 2;
    const top = (document.body.offsetHeight - 450) / 2;
    setModalStyle({ transform: `translate(${left}px, ${top}px) scale(0.05)`, opacity: 0 });
    setTimeout(() => {
      onClose();
    }, 400);
  }, [onClose]);

  const closeAlways = useCallback(() => {
    const teamId = getTeamIdFromUrl();
    localStorage.setItem(`hide-new-user-activity-modal-${teamId}`, 'true');
    doClose();
  }, [doClose]);

  const handleOkBtnClick = useCallback(() => {
    if (view === 'step1') {
      showBuyIpConfirmModal();
    } else if (view === 'ip') {
      showCreateShopConfirmModal();
    } else if (view === 'step2') {
      history.push(`/team/${getTeamIdFromUrl()}/costManage/overview`);
    }
    doClose();
  }, [doClose, view]);

  const activityCon = useMemo(() => {
    if (view === 'step1') {
      return (
        <Result
          status="success"
          title={I18N.t('恭喜，您已获得1000花瓣的激励')}
          extra={
            <div style={{ textAlign: 'left', width: 440 }}>
              <div style={{ textIndent: -18, marginLeft: 18, marginBottom: 4 }}>
                {I18N.t('1.您已经创建了指纹实例，指纹实例用于绑定在分身上')}
              </div>
              <div style={{ textIndent: -18, marginLeft: 18, marginBottom: 4 }}>
                {I18N.t(
                  '2.您已获得1000花瓣激励，花瓣是一种代币（100花瓣=1RMB），可用于系统内部结算',
                )}
              </div>
              <div style={{ textIndent: -18, marginLeft: 18 }}>
                {I18N.t(
                  '3.点击继续参与，您将获得一张价值10元代金券，只需支付2元，既可购买一个公有云IP（为期1周）',
                )}
              </div>
            </div>
          }
        />
      );
    }
    if (view === 'ip') {
      return (
        <Result
          status="success"
          title={I18N.t('恭喜，您已成功购买IP地址')}
          extra={
            <div style={{ textAlign: 'left', width: 440 }}>
              <div style={{ textIndent: -18, marginLeft: 18, marginBottom: 4 }}>
                {I18N.t('1.您已成功购买了平台IP地址，IP地址可用于绑定在分身上')}
              </div>
              <div style={{ textIndent: -18, marginLeft: 18, marginBottom: 4 }}>
                {I18N.t('2.您现在可以创建一个分身，并为此分身绑定刚才创建的指纹实例与IP地址')}
              </div>
              <div style={{ textIndent: -18, marginLeft: 18 }}>
                {I18N.t('3.当访问分身以打开花漾灵动后，您将再次获得1000花瓣的激励')}
              </div>
            </div>
          }
        />
      );
    }
    return (
      <div style={{ textAlign: 'center', width: 440 }}>
        <img src="/qrcode.png" style={{ width: 200 }} />
        <div className="ant-result-title" style={{ marginBottom: 16 }}>
          {I18N.t('恭喜，您已获得1000花瓣的激励')}
        </div>
        <p style={{ textAlign: 'left' }}>
          {I18N.t('本次新手有礼活动（阶段一）已经结束，请关注我们的公众号，持续参与我们的活动')}
        </p>
      </div>
    );
  }, [view]);

  const content = useMemo(() => {
    if (view === 'init') {
      return (
        <div className={styles.initView}>
          <Lottie
            height={300}
            options={{
              animationData: lottieImg,
            }}
          />

          <div className={styles.activityName}>
            <h2 style={{ color: 'white' }}>{I18N.t('新手有礼活动（阶段一）')}</h2>
            <h2 style={{ color: 'white' }}>{I18N.t('现在参与，即送180元大礼包')}</h2>
          </div>
        </div>
      );
    }
    return (
      <>
        <div className={styles.leftPart}>
          <div className={styles.activityIcon}>
            <Lottie
              options={{
                animationData: lottieImg,
              }}
            />
          </div>
          <div className={styles.activityName}>
            <h2>{I18N.t('新手有礼活动')}</h2>
            <h2>{I18N.t('阶段一')}</h2>
          </div>
        </div>
        <div className={styles.rightPart}>{activityCon}</div>
      </>
    );
  }, [activityCon, view]);

  const okBtnText = useMemo(() => {
    if (view === 'init') return I18N.t('我要参加');
    if (view === 'step2') return I18N.t('查看费用中心');
    return I18N.t('继续参与');
  }, [view]);

  return (
    <Modal
      visible
      centered
      title={null}
      footer={null}
      className={classNames(styles.modalWrap)}
      width={800}
      bodyStyle={{ height: 450, padding: 0 }}
      style={modalStyle}
      transitionName=""
      closeIcon={
        <IconFontIcon
          iconName="guanbi_24"
          style={{ color: view === 'init' ? 'white' : 'inherit' }}
        />
      }
      onCancel={doClose}
    >
      <div className={styles.wrap}>
        {content}
        <Row
          align="middle"
          style={{ position: 'absolute', left: 16, right: 35, bottom: 13, height: 'auto' }}
        >
          <Col flex="auto">
            {view === 'init' && (
              <Space size={4} style={{ fontSize: 12, lineHeight: '16px' }}>
                <IconFontIcon iconName="info_24" color={colors.primaryColor} />
                <span>{I18N.t('更多信息请阅读')}</span>
                <a onClick={() => openInNewWindow('https://www.szdamai.com/marketing/')}>
                  {I18N.t('新手有礼活动')}
                </a>
              </Space>
            )}
          </Col>
          <Col>
            <Space>
              <Button type="primary" onClick={handleOkBtnClick}>
                {okBtnText}
              </Button>
              {view !== 'step2' && (
                <Button className={buttonStyles.dangerBtn} onClick={closeAlways}>
                  {I18N.t('不再提醒')}
                </Button>
              )}

              <Button onClick={doClose}>
                {view === 'step2' ? I18N.t('关闭') : I18N.t('回头再说')}
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default NewUserActivityModal;

export function useNewUserActivityModal() {
  const modalCaller = useModalCaller(true);
  return useCallback(
    (view?: 'init' | 'step1' | 'ip' | 'step2') => {
      modalCaller({
        component: ({ close }) => <NewUserActivityModal view={view} onClose={close} />,
      });
    },
    [modalCaller],
  );
}
