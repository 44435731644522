import { useState } from 'react';

export default () => {
  // 当前店铺详情的缓存
  const [shopList, setShopList] = useState<Record<number, API.ShopDetailDto>>({});
  const [shopIds, setShopIds] = useState<number[]>([]);
  const setShopDetails = (shopDetails: API.ShopDetailDto[]) => {
    setShopList((oldShopList) => {
      const newShopList = { ...oldShopList };
      shopDetails.forEach((o) => {
        newShopList[o.id] = o;
      });

      return newShopList;
    });
    setShopIds(shopDetails.map(({ id }) => id));
  };
  const getShopDetail = (shopId: number) => shopList[shopId];
  return { shopIds, getShopDetail, setShopDetails };
};
