import I18N from '@/i18n';
import React from 'react';
import _ from 'lodash';
import { Space, Tooltip, Typography } from 'antd';
import ColoursIcon from '@/components/Common/ColoursIcon';
import IconFontIcon from '@/components/Common/IconFontIcon';

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  value?: any;
  text?: any;
}

/**
 * 占位符
 * 用于显示#cccccc的颜色文字
 * @param props
 * @constructor
 */
const Placeholder: React.FC<Props> = (props) => {
  const { children, value, style, text, ...otherProps } = props;
  if (_.isNull(children) || _.isUndefined(children)) {
    return (
      <span style={{ color: '#cccccc', ...style }} {...otherProps}>
        {text || '--'}
      </span>
    );
  }
  if (_.isNull(value) || _.isUndefined(value)) {
    let placeholder;
    if (_.isString(children) || _.isNumber(children)) {
      placeholder = children;
    } else if (text) {
      placeholder = text;
    }
    return (
      <span style={{ color: '#cccccc', ...style }} {...otherProps}>
        {placeholder || ''}
      </span>
    );
  }
  return <>{children}</>;
};
export default Placeholder;

type OptionPlaceholderProps = {
  type: 'tag' | 'user' | 'category' | 'platform' | 'location' | 'pool' | 'site' | 'source';
  text?: React.ReactNode;
  iconSize?: number;
};
const TypeIconMap = {
  tag: 'quanbubiaoqian_24',
  user: 'quanbushouquanchengyuan_24',
  category: 'quanbupinlei_24',
  platform: 'quanbupingtai_24',
  location: 'IPguishushiqu_24',
  pool: 'IPchi_24',
  site: 'nationaarea',
  source: 'quanbulaiyuan_24',
};
const TypeTextMap = {
  tag: I18N.t('全部标签'),
  user: I18N.t('全部用户'),
  category: I18N.t('全部品类'),
  platform: I18N.t('全部平台'),
  location: I18N.t('全部归属地'),
  pool: I18N.t('全部IP池'),
  site: I18N.t('全部站点'),
  source: I18N.t('全部来源'),
};

/**
 * 全部XX占位符
 * @param props
 * @constructor
 */
export const OptionPlaceholder: React.FC<OptionPlaceholderProps> = (props) => {
  const { type, iconSize, text } = props;
  return (
    <Space wrap={false} size={4}>
      <span style={{ minWidth: iconSize }}>
        <ColoursIcon size={iconSize} className={`${TypeIconMap[type]}`} />
      </span>
      <Typography.Text>{text || TypeTextMap[type]}</Typography.Text>
    </Space>
  );
};
interface InputTooltipPlaceholderProps {
  title: JSX.Element | string;
}

/**
 * 表单末尾的提示
 * @param props
 * @constructor
 */
export const InputTooltipPlaceholder: React.FC<InputTooltipPlaceholderProps> = (props) => {
  const { title } = props;
  return (
    <Tooltip title={title}>
      <IconFontIcon iconName="bangzhu_24" style={{ fontSize: 'inherit' }} />
    </Tooltip>
  );
};
