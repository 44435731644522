import { useRef, useState } from 'react';

export default () => {
  // 采集指纹模版
  const [addFpTemplate, setAddFpTemplate] = useState(false);
  // 购买平台IP
  const [buyIp, setBuyIp] = useState(false);
  const [importIp, setImportIp] = useState(false);
  // 创建单个浏览器分身
  const [createAccount, setCreateAccount] = useState(false);
  // 批量创建浏览器分身
  const [batchCreateAccount, setBatchCreateAccount] = useState(false);
  // 导入浏览器分身
  const [importAccount, setImportAccount] = useState(false);
  const importIpActionType = useRef<string>();
  const batchCreateAccountArguments = useRef<{ amount?: string; platform?: string }>();
  // 创建流程定义
  const [createFlow, setCreateFlow] = useState(false);
  const createFlowArguments = useRef<{ name?: string; description?: string }>();
  const [importFlow, setImportFlow] = useState(false);
  const [buyFlow, setBuyFlow] = useState(false);
  // 执行流程定义
  const [runTask, setRunTask] = useState(false);
  const runTaskArguments = useRef<{ name?: string }>();
  // 创建流程计划
  const [createRpaPlan, setCreateRpaPlan] = useState(false);
  const createRpaPlanArguments = useRef<{ name?: string; description?: string }>();
  // 执行流程计划
  const [runRpaPlan, setRunRpaPlan] = useState(false);
  const runRpaPlanArguments = useRef<{ name?: string }>();
  // 查看任务
  const [viewTask, setViewTask] = useState(false);
  const viewTaskArguments = useRef<{ name?: string }>();
  // 邀请团队成员
  const [inviteTeamMember, setInviteTeamMember] = useState(false);
  // 创建团队成员
  const [createTeamMember, setCreateTeamMember] = useState(false);
  // 加入团队
  const [joinTeam, setJoinTeam] = useState(false);

  return {
    addFpTemplate,
    setAddFpTemplate,
    buyIp,
    setBuyIp,
    importIp,
    setImportIp,
    importIpActionType,
    createAccount,
    setCreateAccount,
    batchCreateAccount,
    setBatchCreateAccount,
    batchCreateAccountArguments,
    importAccount,
    setImportAccount,
    createFlow,
    setCreateFlow,
    createFlowArguments,
    importFlow,
    setImportFlow,
    buyFlow,
    setBuyFlow,
    runTask,
    setRunTask,
    runTaskArguments,
    createRpaPlan,
    setCreateRpaPlan,
    createRpaPlanArguments,
    runRpaPlan,
    setRunRpaPlan,
    runRpaPlanArguments,
    viewTask,
    setViewTask,
    viewTaskArguments,
    inviteTeamMember,
    setInviteTeamMember,
    createTeamMember,
    setCreateTeamMember,
    joinTeam,
    setJoinTeam,
  };
};
