import React, { useEffect } from 'react';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import { Col, Form, FormInstance, InputNumber, Radio, Row, Select, Space } from 'antd';
import I18N from '@/i18n';
import ColoursIcon from '@/components/Common/ColoursIcon';
import { sendAsync } from '@/utils/ElectronUtils';
import { Display } from '@/models/windowSync';
import { WindowSizeCon } from '@/pages/ShopManage/WindowSync/components/DiagonalLayoutForm';
import DmCollapse from '@/components/Common/DmCollapse';

type Props = {
  form: FormInstance;
  hidden?: boolean;
  orderVisible?: boolean;
  onValuesChange?: () => void;
};

/**
 * 宫格布局
 * @param props
 * @constructor
 */
const GridLayoutForm: React.FC<Props> = (props) => {
  const { form, hidden = false, orderVisible = false, onValuesChange } = props;
  const [displays, setDisplays] = React.useState<Display[]>([]);

  useEffect(() => {
    sendAsync('get-screen-info').then((data) => {
      setDisplays(data.allDisplays);
      if (!form.getFieldValue('displayId')) {
        form.setFieldsValue({ displayId: data.allDisplays[0].id });
      }
    });
  }, [form]);

  return (
    <Form form={form} requiredMark={false} hidden={hidden} onValuesChange={onValuesChange}>
      <DMFormItemContext.Provider value={{}}>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return (
              <>
                <DMFormItem label={I18N.t('显示器')} initialValue={1} name={'displayId'}>
                  <Select
                    options={displays.map((d) => ({
                      label: (
                        <Space size={4}>
                          <ColoursIcon className="kehuduan_24" />
                          {d.label}
                          <span style={{ color: '#999' }}>
                            （{d.size.width}x{d.size.height}）
                          </span>
                        </Space>
                      ),

                      value: d.id,
                    }))}
                  />
                </DMFormItem>
                {orderVisible && (
                  <DMFormItem label={I18N.t('窗口布局顺序')} initialValue="asc" name={'order'}>
                    <Radio.Group>
                      <Radio value="asc">{I18N.t('正序排列')}</Radio>
                      <Radio value="desc">{I18N.t('倒序排列')}</Radio>
                    </Radio.Group>
                  </DMFormItem>
                )}
                <DMFormItem
                  label={I18N.t('每行窗口数量')}
                  initialValue={3}
                  name={'columns'}
                  rules={[{ required: true }]}
                  style={{ marginBottom: 0 }}
                >
                  <InputNumber min={1} max={10} />
                </DMFormItem>
                <DmCollapse header={I18N.t('高级选项')}>
                  <DMFormItem
                    label={I18N.t('起始位置')}
                    shouldUpdate
                    style={{ marginBottom: 0, alignItems: 'baseline', lineHeight: '30px' }}
                  >
                    <Row>
                      <Col flex={'3em'}>X</Col>
                      <Col flex={1}>
                        <DMFormItem
                          name={'left'}
                          initialValue={0}
                          rules={[{ required: true, message: I18N.t('请输入起始位置') }]}
                        >
                          <InputNumber max={1000} />
                        </DMFormItem>
                      </Col>
                      <Col flex={'3em'}>Y</Col>
                      <Col flex={1}>
                        <DMFormItem
                          name={'top'}
                          initialValue={0}
                          rules={[{ required: true, message: I18N.t('请输入起始位置') }]}
                        >
                          <InputNumber max={1000} />
                        </DMFormItem>
                      </Col>
                    </Row>
                  </DMFormItem>
                  <DMFormItem
                    label={I18N.t('窗口间距')}
                    shouldUpdate
                    style={{ marginBottom: 0, alignItems: 'baseline', lineHeight: '30px' }}
                  >
                    <Row>
                      <Col flex={'3em'}>{I18N.t('横向')}</Col>
                      <Col flex={1}>
                        <DMFormItem
                          name={'hs'}
                          initialValue={0}
                          rules={[{ required: true, message: I18N.t('请输入窗口间距') }]}
                        >
                          <InputNumber max={200} />
                        </DMFormItem>
                      </Col>
                      <Col flex={'3em'}>{I18N.t('纵向')}</Col>
                      <Col flex={1}>
                        <DMFormItem
                          name={'vs'}
                          initialValue={0}
                          rules={[{ required: true, message: I18N.t('请输入窗口间距') }]}
                        >
                          <InputNumber max={200} />
                        </DMFormItem>
                      </Col>
                    </Row>
                  </DMFormItem>
                  <WindowSizeCon
                    display={
                      displays.find((d) => d.id === form.getFieldValue('displayId')) ?? displays[0]
                    }
                  />
                </DmCollapse>
              </>
            );
          }}
        </Form.Item>
      </DMFormItemContext.Provider>
    </Form>
  );
};

export default GridLayoutForm;
