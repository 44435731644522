import { Spin } from 'antd';
import PageLoading from '@/components/PageLoading';

interface Props {
  height?: string;
  shape?: 'normal' | 'gif';
}

const MiddleSpin: React.FC<Props> = (props) => {
  if (props.shape === 'gif') {
    return <PageLoading />;
  }
  return (
    <Spin
      style={{
        width: '100%',
        height: props.height || '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    />
  );
};

export default MiddleSpin;
