import {ChatRoom, ResponseHandler} from './type';
import WsTransport from './WsTransport';
import {errorMessageI18n, getTeamIdFromUrl} from '@/utils/utils';
import I18N from '@/i18n';
import {message} from 'antd';
import {MEMBER_ERROR_CODE_COLLECTION} from '@/constants/ErrorCode';
import random, {low_char_and_num} from "../../electron/src/utils/random";
import EventEmitter from "events";

const mvcCallbacks: Map<string, MvcCallback> = new Map();

interface MvcOption {
  suitable: boolean;
  pattern: string;
}

class MvcCallback {
  requestId: string;
  _resolve: Function;
  _reject: Function;
  done = false;
  _timeout: any;
  constructor(requestId: string, resolve: Function, reject: Function) {
    this.requestId = requestId;
    this._resolve = resolve;
    this._reject = reject;

    this._timeout = setTimeout(() => {
      if (!this.done) {
        this.reject('timeout');
      }
    }, 60000);
  }

  resolve(data: any) {
    this.onDone();
    this._resolve(data);
  }

  reject(err: any) {
    this.onDone();
    this._reject(err);
  }

  private onDone() {
    this.done = true;
    clearTimeout(this._timeout);
    mvcCallbacks.delete(this.requestId);
  }
}

export class PortalRpcClient extends EventEmitter implements ResponseHandler {
  readonly transport: WsTransport;
  ajaxPushHandle: any;

  rooms: Map<string, ChatRoom> = new Map();

  constructor(transport: WsTransport) {
    super();
    this.transport = transport;
    this.transport.setResponseHandler(this);
  }

  start() {
    this.transport.start();
  }

  stop() {
    this.transport.stop();
  }

  isConnected(): boolean {
    return this.transport.isConnected();
  }

  joinChatRoom(roomId: string): ChatRoom {
    let currentUser = random.nextString(16, low_char_and_num);
    let room = new ChatRoom(roomId, currentUser, this.transport);
    room.join();
    this.rooms.set(roomId, room);
    return room;
  }

  leaveChatRoom(roomId: string) {
    const room = this.rooms.get(roomId);
    if(room != null) {
      room.leave();
      this.rooms.delete(roomId);
    }
  }

  sendToChatRoom(roomId: string, data: any, toUser?: string) {
    let room = this.rooms.get(roomId);
    if(room) {
      room.sendMessage(data, toUser);
    }
  }

  directOptionCache: Map<string, any> = new Map();
  patternOptionCache: Map<RegExp, any> = new Map();
  async mvcOption(path: string, method: string): Promise<any> {
    method = method.toUpperCase();
    let key = method + '_' + path;
    if(this.directOptionCache.has(key)) {
      return this.directOptionCache.get(key);
    }
    for (let [pattern, patternOption] of this.patternOptionCache) {
      if (pattern.test(key)) {
        return patternOption;
      }
    }
    let request: any = {
      action: 'mvcOption',
      data: {
        path: path,
        method: method,
      },
    };
    const requestId = this.transport.send(request);
    try {
      let option: MvcOption =  await new Promise((resolve, reject) => {
        mvcCallbacks.set(requestId, new MvcCallback(requestId, resolve, reject));
      });
      if(option.pattern) {
        if(/\{[^}]+}/g.test(option.pattern)) { //说明是一个pattern请求
          let pattern = new RegExp(method.toUpperCase() + '_' + option.pattern.replaceAll(/\{[^}]+}/g, '[^}]+'));
          this.patternOptionCache.set(pattern, option);
        } else {
          this.directOptionCache.set(key, option);
        }
      }
      return option;
    } catch (e) {
      console.error(e);
    }
  }

  async invokeMvc(path: string, options: { [propName: string]: any }): Promise<any> {
    const { data, method = 'get', params = {}, timeout = 0, headers, errorHandler } = options || {};
    const finalHeaders: any = { ...(headers || {}) };
    let teamId;
    try {
      teamId = getTeamIdFromUrl();
    } catch (e) {
      console.log(e);
    }
    if (teamId && !finalHeaders['x-dm-team-id']) {
      finalHeaders['x-dm-team-id'] = teamId;
    }
    // if (getJwt()) {
    //   finalHeaders['Authorization'] = getJwt();
    // }
    // if (isOpenByShopShortcut()) {
    //   finalHeaders['Authorization'] = '';
    // }
    finalHeaders['Accept-Language'] = I18N.getLocale();
    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      if (searchParams.get('runtimeJwt')) {
        finalHeaders['x-dm-runtime-jwt'] = searchParams.get('runtimeJwt');
      }
      if (searchParams.get('sscToken')) {
        finalHeaders['x-dm-device-token'] = searchParams.get('sscToken');
      }
    }
    let request: any = {
      action: 'mvc',
      timeout,
      data: {
        path: path,
        method: method,
        params: params || {},
        headers: finalHeaders,
      },
    };
    if (typeof data != 'undefined') {
      request.data.body = JSON.stringify(data);
    }
    const requestId = this.transport.send(request);
    return new Promise((resolve, reject) => {
      mvcCallbacks.set(
        requestId,
        new MvcCallback(requestId, resolve, (err: any) => {
          // if (err && err?.data.code) {
          //   if (!isBrowserEnv && err?.data?.code === 401) {
          //     setJwt('');
          //     if (!isPublicPage()) {
          //       redirectToLogin();
          //     }
          //     return;
          //   }
          // }
          if (!MEMBER_ERROR_CODE_COLLECTION.includes(err?.data?.code) && !errorHandler) {
            message.error(errorMessageI18n(err.message));
          }
          reject(err);
        }),
      );
    });
  }

  onResponse(response: any): void {
    switch (response.action) {
      case 'mvc':
      case 'mvcOption':
        let callback = mvcCallbacks.get(response.requestId);
        if (callback) {
          let data = response.data;
          if (data.body) {
            data = data.body;
          }
          if (response.action == 'mvcOption') {
            callback.resolve(data);
            return;
          }

          if (data.success) {
            callback.resolve(data);
          } else {
            const err: any = new Error(data.message);
            err.data = data;

            callback.reject(err);
          }
        }
        break;
      case 'ajax-push':
        this.emit('on-ajax-push', response.data);
        break;
      case 'room':
        this.rooms.get(response.roomId)?.emit('room', response);
        break;
      default:
        //todo
        console.log('unknown action', response.action, response);
    }
  }
}
export const portalRpcClient: PortalRpcClient = new PortalRpcClient(new WsTransport());
