import I18N from '@/i18n';
import { openOfficialSiteByAppWindow } from '@/utils/pageUtils';
import type { AnchorHTMLAttributes, FC } from 'react';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { useMemo } from 'react';

const HelpLink: FC<
  AnchorHTMLAttributes<HTMLAnchorElement> & { showIcon?: boolean; underline?: boolean }
> = (props) => {
  const { href, style = {}, children = I18N.t('了解更多'), showIcon, ...others } = props;
  const styleObj = useMemo(() => {
    return {
      fontSize: 'inherit',
      whiteSpace: 'nowrap',
      ...style,
    };
  }, [style]);
  const link = useMemo(() => {
    let url = href || '';
    const prefix = '/help';
    url = url.replace('//', '/');
    if (url.startsWith('.')) {
      url.replace('.', '');
    }
    if (url.startsWith(prefix)) {
      return url;
    }
    if (!url.startsWith('/')) {
      url = `/${url}`;
    }
    return `${prefix}${url}`;
  }, [href]);
  return (
    <a
      style={styleObj}
      {...others}
      target="_blank"
      onClick={(e) => {
        e.preventDefault();
        openOfficialSiteByAppWindow(link);
      }}
    >
      {showIcon && <IconFontIcon iconName="bangzhu_241" />}
      {children}
    </a>
  );
};
export default HelpLink;
