import styles from './index.less';
import FileIcon from '@/pages/Yunpan/components/FileIcon';
import { Typography } from 'antd';
import classNames from 'classnames';
import type { HTMLProps } from 'react';

const FileName = (
  props: {
    name: string;
    hack?: 'directory' | 'record';
    iconSize?: number;
  } & HTMLProps<HTMLDivElement>,
) => {
  const { name, hack, iconSize, ...others } = props;
  return (
    <div className={classNames(styles.fileNameRow, 'file-name')} title={name} {...others}>
      <FileIcon name={name} hack={hack} size={iconSize} />
      <Typography.Text ellipsis style={{ color: 'inherit' }}>
        {name}
      </Typography.Text>
    </div>
  );
};
export default FileName;
