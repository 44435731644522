import I18N from '@/i18n';
import { Input, message, Form, Select } from 'antd';
import React, { useState } from 'react';
import { useModel } from 'umi';
import MiddleSpin from '@/components/Common/MiddleSpin';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { accountUpdatePwdByVerifyCodePost } from '@/services/api-Account/AccountController';
import validate from '@/utils/validate';
import DMModal from '@/components/Common/Modal/DMModal';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import { CaptchaGroup } from '@/components/Common/CaptchaButton';
import { useModalCaller } from '@/mixins/modal';
import { PhoneModal } from '@/components/UserCenter/UserInfo';
import DMConfirm from '@/components/Common/DMConfirm';
import useCurrentUser from '@/hooks/useCurrentUser';

const AccountSetting: React.FC = () => {
  const [form] = Form.useForm();
  const { loading } = useModel('@@initialState');
  const { user } = useCurrentUser();
  const [visible, setVisible] = useState(false);
  const modalCaller = useModalCaller();
  if (loading || !user) {
    return <MiddleSpin />;
  }
  return (
    <>
      <DMModal
        title={I18N.t('账号密码')}
        width="480px"
        onEnter={form.submit}
        visible={visible}
        bodyStyle={{
          paddingBottom: 0,
        }}
        onCancel={() => setVisible(false)}
        onOk={form.submit}
      >
        <Form
          requiredMark={false}
          form={form}
          onFinish={async () => {
            const values = form.getFieldsValue();
            try {
              await accountUpdatePwdByVerifyCodePost(values);
              message.success(I18N.t('密码修改成功'));
              setVisible(false);
            } catch (e) {
              console.error(e);
            }
          }}
        >
          <DMFormItemContext.Provider value={{ disableLabelMuted: true, labelWidth: 90 }}>
            <DMFormItem
              label={I18N.t('登录账号')}
              name="account"
              initialValue={user?.phone || user.email}
            >
              <Select>
                {user.phone && <Select.Option value={user.phone}>{user.phone}</Select.Option>}
                {user.email && <Select.Option value={user.email}>{user.email}</Select.Option>}
              </Select>
            </DMFormItem>
            <CaptchaGroup type={'findPassword'} form={form} label={I18N.t('验证码')} />
            <DMFormItem
              label={I18N.t('新密码')}
              name="password"
              help={I18N.t(
                '长度为8~40个字符，需至少包含以下四类字符中的三类：大写字母、小写字母、数字、特殊符号',
              )}
              rules={[
                () => ({
                  validator: (_, value) => validate.pwd(value),
                }),
              ]}
            >
              <Input.Password placeholder={I18N.t('请输入密码')} />
            </DMFormItem>
            <DMFormItem
              name="repeatPassword"
              label={I18N.t('确认密码')}
              rules={[
                ({ getFieldValue }) => ({
                  validator: (_, value) => validate.pwdConfirm(getFieldValue('password'), value),
                }),
              ]}
            >
              <Input.Password placeholder={I18N.t('请再次输入密码')} />
            </DMFormItem>
          </DMFormItemContext.Provider>
        </Form>
      </DMModal>
      <a
        onClick={() => {
          if (user?.phone || user?.email) {
            setVisible(true);
          } else {
            DMConfirm({
              type: 'info',
              title: I18N.t('请先设置手机号或邮箱'),
              onOk() {
                modalCaller({
                  ghost: true,
                  component: <PhoneModal />,
                });
              },
            });
          }
        }}
      >
        <IconFontIcon iconName="zhanghaomima_24" />
        {I18N.t('账号密码')}
      </a>
    </>
  );
};

export default AccountSetting;
