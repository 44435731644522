// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 记录推广码 GET /api/p/${param0} */
export async function pByCodeGet(
  params: {
    // path
    /** code */
    code: string;
  },
  options?: { [key: string]: any },
) {
  const { code: param0, ...queryParams } = params;
  return request<Record<string, any>>(`/api/p/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 领取奖励 POST /api/promotion/gainReward */
export async function promotionGainRewardPost(
  params: {
    // query
    /** id */
    id: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultGiftCardPackDetailVo>('/api/promotion/gainReward', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查看奖励内容 GET /api/promotion/getRewardObject */
export async function promotionGetRewardObjectGet(
  params: {
    // query
    /** id */
    id: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultGiftCardPackDetailVo>('/api/promotion/getRewardObject', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取我的个人邀请码信息 GET /api/promotion/myPromoUrl */
export async function promotionMyPromoUrlGet(options?: { [key: string]: any }) {
  return request<API.WebResultUserPromoInfo>('/api/promotion/myPromoUrl', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查询用户发展的用户列表 GET /api/promotion/promotedUsers */
export async function promotionPromotedUsersGet(
  params: {
    // query
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultUserPromotedRecordVo>('/api/promotion/promotedUsers', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
