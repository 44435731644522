import I18N from '@/i18n';
import React, { useCallback } from 'react';

import { useModalCaller } from '@/mixins/modal';
import DMModal from '@/components/Common/Modal/DMModal';
import { Form, FormInstance, TimePicker } from 'antd';
import DMFormItem from '@/components/Common/DMFormItem';
import Tips from '@/components/Common/Tips';
import moment from 'moment';

type Props = {
  formIns: FormInstance;
  onClose: () => void;
};

/**
 * 自动安装更新时段设置
 * @param props
 * @constructor
 */
const AutoInstallTimeRangeSettingsModal: React.FC<Props> = (props) => {
  const { formIns, onClose } = props;
  const [form] = Form.useForm();

  const [startTimeStr, endTimeStr] = (
    formIns.getFieldValue('autoInstallTimeRange') ?? '02:00-05:59'
  ).split('-');
  return (
    <DMModal visible title={I18N.t('自动升级时段设置')} onOk={form.submit} onCancel={onClose}>
      <Form
        requiredMark={false}
        form={form}
        initialValues={{ range: [moment(startTimeStr, 'HH:mm'), moment(endTimeStr, 'HH:mm')] }}
        onFinish={(fields) => {
          formIns.setFieldsValue({
            autoInstallTimeRange: `${fields.range[0].format('HH:mm')}-${fields.range[1].format(
              'HH:mm',
            )}`,
          });
          onClose();
        }}
      >
        <DMFormItem
          label={I18N.t('自动升级时段')}
          name="range"
          rules={[
            { required: true },
            {
              validator: (rule, value) => {
                const minutes = value[1].diff(value[0], 'minutes');
                if (minutes < 30) {
                  return Promise.reject(new Error(I18N.t('时间段跨度不能小于30分钟')));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <TimePicker.RangePicker showSecond={false} format="HH:mm" style={{ width: '100%' }} />
        </DMFormItem>
        <Tips
          content={[
            I18N.t(
              '只有在上述指定时段内且客户端处于空闲状态（没有打开花漾浏览器）时才会自动静默安装升级',
            ),
          ]}
        />
      </Form>
    </DMModal>
  );
};

export default AutoInstallTimeRangeSettingsModal;

export function useAutoInstallTimeRangeSettingsModal() {
  const modalCaller = useModalCaller(true);
  return useCallback(
    (props: Omit<Props, 'onClose'>) => {
      modalCaller({
        component: ({ close }) => <AutoInstallTimeRangeSettingsModal {...props} onClose={close} />,
      });
    },
    [modalCaller],
  );
}
