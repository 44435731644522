import I18N from '@/i18n';
import { useCallback, useRef, useState } from 'react';
import EventEmitter from 'events';
import { message } from 'antd';
import _ from 'lodash';
import {
  rpaByRpaFlowIdUpdateFlowConfigPost,
  rpaFlowByRpaFlowIdGet,
  rpaFlowConfigByConfigIdGet,
} from '@/services/api-RPAAPI/RpaController';
import { SkipErrorNotifyOption } from '@/utils/utils';
import type { NodeVo } from '@/pages/Rpa/FlowDetail/layout/EditorPanel';
import { findNodes } from '@/pages/Rpa/FlowDetail/utils/utils';
import { getAppUUID, getAppVersion } from '@/utils/ElectronUtils';

export interface FlowTreeNodeProps {
  id: string;
  name: string;
  type: string;
  children?: FlowTreeNodeProps[];
  level: number;
}

const emitter = new EventEmitter();
emitter.setMaxListeners(9999);

export default () => {
  const [rpaFlowVo, setRpaFlowVo] = useState<API.RpaFlowVo | null>(null);
  const [rpaConfig, setRpaConfig] = useState<API.RpaConfig | null>(null);
  const [subFlowsState, setSubFlowsState] = useState<Record<string, API.RpaSubConfig>>({});
  const [flowTree, setFlowTree] = useState<FlowTreeNodeProps>();
  const [flowNodesState, setFlowNodesState] = useState<Record<
    string,
    Record<string, NodeVo>
  > | null>(null);
  // 选中的节点ID列表
  const [flowSelectedNodeIds, setFlowSelectedNodeIds] = useState<
    Record<string, string[] | undefined>
  >({});
  // 折叠的节点
  const [flowCollapseNodeIds, setFlowCollapseNodeIds] = useState<
    Record<string, string[] | undefined>
  >({});
  // 选中的流程，主流程为 index, 子流程为其流程ID
  const [activeFlow, setActiveFlow] = useState<string>('index');
  // 打开的子流程ID列表
  const [mountedSubFlow, setMountedSubFlow] = useState<string[]>(['index']);
  // 预览状态
  const [previewStatus, setPreviewStatus] = useState('stopped');
  // 正在拖动的指令/节点
  const [draggingItemId, setDraggingItemId] = useState('');
  // 是否为 debug 模式
  const [isDebug, setDebug] = useState(false);
  // debug 模式下的断点列表
  const [breakpoints, setBreakpoints] = useState<Record<string, string>>({});
  // 当前执行到的断点
  const [currentDebugPointer, setCurrentDebugPointer] = useState<{
    nid: string;
    fid: string;
  } | null>(null);
  // 录制
  const [recording, setRecording] = useState(false);
  // 是否已保存
  const [saved, setSaved] = useState(true);

  const rpaConfigRef = useRef(rpaConfig);
  // 是否正在更新配置
  const updatingRpaConfigRef = useRef(false);
  // 待更新的配置
  const pendingRpaConfigRef = useRef<API.RpaConfig | null>(null);

  const loadFlowData = useCallback((flowId: number) => {
    return new Promise((resolve, reject) => {
      rpaFlowByRpaFlowIdGet({ rpaFlowId: flowId }).then((res) => {
        if (res.data) {
          if (res.data.sharedFlowId) {
            reject(new Error(I18N.t('该流程定义由好友共享而来，不允许编辑')));
            return;
          }
          setRpaFlowVo(res.data);
          document.title = `${I18N.t('流程编辑-')}${res.data.name}`;
        }
      });
    });
  }, []);

  const loadConfigData = useCallback(
    (configId?: string) => {
      if (!rpaFlowVo?.configId && !configId) return Promise.resolve();
      // @ts-ignore
      return rpaFlowConfigByConfigIdGet({ configId: configId ?? rpaFlowVo?.configId }).then(
        (res) => {
          if (res.data) {
            rpaConfigRef.current = res.data;
            setRpaConfig(res.data);
          }
          return res;
        },
      );
    },
    [rpaFlowVo?.configId],
  );

  const saveFlowConfigToServer = useCallback(() => {
    if (!pendingRpaConfigRef.current) return Promise.resolve();
    const config = pendingRpaConfigRef.current;
    updatingRpaConfigRef.current = true;
    const appVersion = getAppVersion();
    if (appVersion) {
      config.appVersion = appVersion;
    }
    // 计算流程执行最小版本
    let minorVersion = 0;
    _.forEach(config?.nodes, (node: Record<string, any>) => {
      if (node.disabled !== true && node.minorVersion > minorVersion) {
        minorVersion = node.minorVersion;
      }
    });
    _.forEach(config?.subFlows, (subFlow) => {
      _.forEach(subFlow?.nodes, (node: Record<string, any>) => {
        if (node.disabled !== true && node.minorVersion > minorVersion) {
          minorVersion = node.minorVersion;
        }
      });
    });
    if (!config.extra) {
      config.extra = {};
    }
    config.minorVersion = minorVersion;
    console.log('save flow config', config);
    return rpaByRpaFlowIdUpdateFlowConfigPost(
      { rpaFlowId: rpaFlowVo!.id! },
      config,
      SkipErrorNotifyOption,
    )
      .then((res) => {
        message.success(I18N.t('流程保存成功'));
        pendingRpaConfigRef.current = null;
        loadConfigData(res.data?.configId).finally(() => {
          updatingRpaConfigRef.current = false;
          if (pendingRpaConfigRef.current) {
            saveFlowConfigToServer();
            pendingRpaConfigRef.current = null;
          } else {
            setSaved(true);
          }
        });
        return res;
      })
      .catch((e) => {
        message.error(e.message);
        updatingRpaConfigRef.current = false;
        throw e;
      });
  }, [loadConfigData, rpaFlowVo]);

  const updateConfig = useCallback((config: API.RpaConfig) => {
    pendingRpaConfigRef.current = config;
    console.log('update flow config', config);
    setRpaConfig(config);
    setSaved(false);
    return Promise.resolve();
  }, []);

  const updateParams = useCallback(
    (options: { params: API.RpaParam[]; subFlowId?: string; reloadData?: boolean }) => {
      if (!rpaFlowVo?.id) return;
      const { params, subFlowId } = options;
      const config = rpaConfigRef.current!;
      if (subFlowId && config?.subFlows?.[subFlowId]) {
        config.subFlows[subFlowId].params = params;
      } else {
        config.params = params;
      }
      emitter.emit('params-update');
      console.log('update flow params', params);
      updateConfig(config);
    },
    [rpaFlowVo?.id, updateConfig],
  );

  const overwriteConfig = useCallback(
    (values: any) => {
      _.forEach(values, (v, k) => {
        rpaConfigRef.current![k] = v;
      });
      return updateConfig({ ...rpaConfigRef.current, ...values });
    },
    [updateConfig],
  );

  // 更新流程节点列表
  const updateFlowNodesState = useCallback(
    (flowId?: string, nodeState?: Record<string, NodeVo>) => {
      const _id = flowId || 'index';
      if (!flowNodesState?.[_id] || !nodeState) return;
      setFlowNodesState({
        ...flowNodesState,
        [_id]: nodeState,
      });
    },
    [flowNodesState],
  );

  // 移除满足条件的节点
  const removeFlowNodesStateInConditions = useCallback(
    (flowId?: string, conditions?: { key: string; value: any }[]) => {
      const _id = flowId || 'index';
      if (!flowNodesState?.[_id] || !conditions) return;
      const targetNodes = findNodes(flowNodesState?.[_id] ?? {}, conditions) ?? {};
      targetNodes.forEach((targetNode) => {
        const { nodeId, prevNodeId, nextNodeId, parentNodeId, controlNodeName } = targetNode;
        if (nodeId && (prevNodeId || (parentNodeId && controlNodeName))) {
          const _nodes = { ...flowNodesState?.[_id] };
          const prevNode = _nodes[prevNodeId ?? ''];
          if (prevNode) {
            if (prevNode.next && _nodes[prevNode.next]) {
              delete _nodes[prevNode.next];
            }
            prevNode.next = nextNodeId;
          } else if (parentNodeId && controlNodeName) {
            (_nodes[parentNodeId].props ?? {})[controlNodeName] = nextNodeId;
          }
          if (_id === 'index') {
            rpaConfigRef.current!.nodes = _nodes;
          } else {
            rpaConfigRef.current!.subFlows![_id].nodes = _nodes;
          }
          updateFlowNodesState(_id, _nodes);
        }
      });
    },
    [flowNodesState, updateFlowNodesState],
  );

  const changeNodeCollapse = useCallback(
    (nId: string | string[], collapsed?: boolean) => {
      let ids = flowCollapseNodeIds[activeFlow] ?? [];
      if (_.isArray(nId)) {
        ids = nId;
      } else if (collapsed) {
        ids = [...ids, nId];
      } else {
        ids = ids.filter((v) => v !== nId);
      }
      setFlowCollapseNodeIds({
        ...flowCollapseNodeIds,
        [activeFlow]: ids,
      });
    },
    [activeFlow, flowCollapseNodeIds],
  );

  return {
    emitter,
    rpaFlowVo,
    rpaConfig,
    rpaConfigRef,
    subFlowsState,
    setSubFlowsState,
    flowTree,
    setFlowTree,
    flowNodesState,
    setFlowNodesState,
    updateFlowNodesState,
    flowSelectedNodeIds,
    setFlowSelectedNodeIds,
    flowCollapseNodeIds,
    changeNodeCollapse,
    activeFlow: mountedSubFlow.includes(activeFlow) ? activeFlow : 'index',
    setActiveFlow,
    mountedSubFlow,
    setMountedSubFlow,
    previewStatus,
    setPreviewStatus,
    isDebug,
    setDebug,
    breakpoints,
    setBreakpoints,
    currentDebugPointer,
    setCurrentDebugPointer,
    recording,
    setRecording,
    saved,
    draggingItemId,
    setDraggingItemId,
    loadFlowData,
    loadConfigData,
    updateConfig,
    saveFlowConfigToServer,
    updateParams,
    overwriteConfig,
    removeFlowNodesStateInConditions,
  };
};
