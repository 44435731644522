// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 取消监听某个事件 PUT /api/ajax-event/batch-un-listener */
export async function ajaxEventBatchUnListenerPut(
  params: {
    // query
    /** pageId */
    pageId: string;
    /** resIds */
    resIds: string;
    /** eventName */
    eventName: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ajax-event/batch-un-listener', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 监听某个事件 PUT /api/ajax-event/batchListener */
export async function ajaxEventBatchListenerPut(
  params: {
    // query
    /** pageId */
    pageId: string;
    /** resIds */
    resIds: string;
    /** eventName */
    eventName: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ajax-event/batchListener', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 监听某个事件 PUT /api/ajax-event/listener */
export async function ajaxEventListenerPut(
  params: {
    // query
    /** pageId */
    pageId: string;
    /** resId */
    resId: string;
    /** eventName */
    eventName: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ajax-event/listener', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 定时轮循某个页面的服务器推送事件 GET /api/ajax-event/query */
export async function ajaxEventQueryGet(
  params: {
    // query
    /** pageId */
    pageId: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListAjaxEventVo>('/api/ajax-event/query', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 取消监听某个事件 PUT /api/ajax-event/un-listener */
export async function ajaxEventUnListenerPut(
  params: {
    // query
    /** pageId */
    pageId: string;
    /** resId */
    resId: string;
    /** eventName */
    eventName: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ajax-event/un-listener', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 通知websocket已经就绪 PUT /api/ajax-event/ws_ready */
export async function ajaxEventWs_readyPut(
  params: {
    // query
    /** pageId */
    pageId: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ajax-event/ws_ready', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
