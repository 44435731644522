import React, { useRef } from 'react';
import Cropper from 'react-cropper';
import _ from 'lodash';
import 'cropperjs/dist/cropper.css';

interface Props {
  // 初始图片 url，为空时显示默认用户头像
  url?: string;
  onCropFinish: (dataUrl: string) => void;
  placeholder?: JSX.Element;
}

/**
 * 图片裁切
 * @param url
 * @param onCropFinish
 * @param placeholder
 * @constructor
 */
export default function ImageCropper({ url, onCropFinish, placeholder }: Props) {
  const cropperRef = useRef<HTMLImageElement>(null);
  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;

    onCropFinish(cropper.getCroppedCanvas().toDataURL());
  };

  const onCropDebounce = _.debounce(onCrop, 200, { trailing: true });
  if (!url && placeholder) {
    return React.cloneElement(placeholder, {
      style: {
        height: 200,
        width: 200,
      },
    });
  }
  return (
    <Cropper
      modal
      guides
      highlight
      center
      background
      src={url}
      style={{ height: 200, width: 200 }}
      aspectRatio={1}
      crop={onCropDebounce}
      ref={cropperRef}
    />
  );
}
