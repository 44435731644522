import I18N from '@/i18n';
import type { FC } from 'react';
import { Form, Input } from 'antd';
import _ from 'lodash';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import DMModal from '@/components/Common/Modal/DMModal';
import useCurrentUser from '@/hooks/useCurrentUser';

type Props = {
  visible: boolean;
  onCancel: () => void;
  onSubmit: (params: { nickname: string; updateTeamNickname: boolean; signature: string }) => void;
};

const NicknameModal: FC<Props> = (props) => {
  const { visible, onSubmit, onCancel } = props;
  const { user } = useCurrentUser();
  const [nicknameForm] = Form.useForm();

  const handleSubmit = async () => {
    await nicknameForm.validateFields();
    const values = nicknameForm.getFieldsValue();
    onSubmit({
      updateTeamNickname: true,
      ...values,
    });
  };

  const initialValues = {
    nickname: user?.nickname,
    signature: user?.signature,
  };
  return (
    <DMModal
      title={I18N.t('修改昵称')}
      bodyStyle={{
        paddingBottom: 0,
      }}
      visible={visible}
      onCancel={onCancel}
      onOk={handleSubmit}
    >
      <DMFormItemContext.Provider value={{ labelWidth: 80, disableLabelMuted: true }}>
        <Form form={nicknameForm} requiredMark={false} initialValues={initialValues}>
          <DMFormItem
            label={I18N.t('个人昵称')}
            name="nickname"
            rules={[
              () => ({
                validator: (rule, value) => {
                  if (!_.trim(value)) return Promise.reject(new Error(I18N.t('请输入个人昵称')));
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input maxLength={36} autoFocus onPressEnter={handleSubmit} />
          </DMFormItem>
          <DMFormItem
            label={I18N.t('个性签名')}
            name="signature"
            rules={[{ max: 128, message: I18N.t('请不要超过128个字符') }]}
          >
            <Input.TextArea
              autoSize={{ minRows: 3, maxRows: 3 }}
              placeholder={I18N.t('最长128个字符')}
            />
          </DMFormItem>
        </Form>
      </DMFormItemContext.Provider>
    </DMModal>
  );
};

export default NicknameModal;
