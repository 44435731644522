import I18N from '@/i18n';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useModel } from 'umi';
import { Badge, Popover, Typography } from 'antd';
import classNames from 'classnames';
import SwitchTeam from './SwitchTeam';
import Menus from './Menus';
import UserCenter, { userCenterEmitter } from '@/components/UserCenter';
import { Avatar } from '@/components/Common/UserAvatarAndName';
import styles from './index.less';
import CustomService from '@/components/CustomService';
import { AiChatMenuItem } from '@/components/AiChat';
import ajaxEventClient from '@/utils/AjaxEventClient';
import {
  msgCenterMessagesGet,
  msgCenterUnreadCountGet,
} from '@/services/api-MessageCenterAPI/MessageCenterController';
import { activityIsActivityFinishedGet } from '@/services/api-ActivityAPI/ActivityController';
import { getJwt, getPortalUrl, SkipErrorNotifyOption } from '@/utils/utils';
import Lottie from 'react-lottie';
import mail from './lottie-assets/mail.json';
import { useThrottleFn } from '@umijs/hooks';
import { Howl } from 'howler';
import { isNotifyMuted } from '@/components/UserCenter/components/MsgSubscribeForm';
import {
  newUserMissionOrder,
  useNewUserActivityModal,
} from '@/components/LeftMenu/NewUserActivityModal';
import useCurrentRole from '@/hooks/useCurrentRole';
import openCriticalInspection from '@/components/LeftMenu/CriticalInspection';
import useCurrentUser from '@/hooks/useCurrentUser';
import { ipcRenderOn, addEventListener, sendAsync, isElectron } from '@/utils/ElectronUtils';
import { createPortal } from 'react-dom';
import { useCustomServiceModal } from '@/components/CustomService/CustomServiceModal';
import { useUserSettingsModal } from '@/components/UserCenter/components/UserSettingsModal';
import useCurrentTeam from '@/hooks/useCurrentTeam';
import { useRequest } from '@@/plugin-request/request';

interface Props {
  matchMenuKeys: string[];
}

export function hackModalPosition(selector = '#hackConfirm') {
  const hackConfirm = document.querySelector(selector);
  if (!hackConfirm) return;
  hackConfirm.dispatchEvent(
    new PointerEvent('click', {
      clientX: hackConfirm.getBoundingClientRect().left,
      clientY: hackConfirm.getBoundingClientRect().top,
    }),
  );
}
/**
 * 左侧导航菜单
 * @param props
 * @constructor
 */
const LeftMenu: React.FC<Props> = (props) => {
  const { loading, initialState } = useModel('@@initialState');
  const { team: currentTeam } = useCurrentTeam();
  const { show, unreadMsgCountList, setUnreadMsgCountList, emitter } = useModel('messageCenter');
  const { role } = useCurrentRole();
  const { user } = useCurrentUser();
  const [userCenterVisible, setUserCenterVisible] = useState(false);
  const [newMsgAnimation, setNewMsgAnimation] = useState(false);
  const [animating, setAnimating] = useState(false);
  const ref = useRef();
  const userCenterRef = useRef<any>(null);
  const showNewUserActivityModal = useNewUserActivityModal();
  const showCustomServiceModal = useCustomServiceModal();
  const showUserSettingsModal = useUserSettingsModal();

  const howl = useMemo(
    () =>
      new Howl({
        src: getPortalUrl('/alert.mp3'),
      }),
    [],
  );

  const getUserMsgUnreadCountData = useCallback(async () => {
    if (!initialState?.teamId) return;
    const res = await msgCenterUnreadCountGet({ targetTeamId: initialState?.teamId });
    setUnreadMsgCountList(res?.data ?? []);
  }, [initialState?.teamId]);
  const { run: play } = useThrottleFn(() => {
    if (!isNotifyMuted()) {
      howl.play();
    }
    ref?.current?.play?.();
    setNewMsgAnimation(true);
    setTimeout(() => {
      setNewMsgAnimation(false);
    }, 1500);
  }, 1500);
  const { run: stop } = useThrottleFn(() => {
    setAnimating(false);
  }, 1000);
  useRequest(async () => {
    if (isElectron()) {
      sendAsync('disk-space-stat', {
        force: true,
      }).then((res) => {
        if (res) {
          const destroy = openCriticalInspection({
            messageType: 'Critical_Message',
            createTime: new Date().toString(),
            content: (
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 12,
                  textIndent: '2em',
                }}
              >
                <div>花漾的存储位置所在磁盘空间不足，您需要将其移动到其它位置 </div>
                <div>
                  {I18N.t('请打开{{action}}进行修订', {
                    action: (
                      <Typography.Link
                        onClick={() => {
                          showUserSettingsModal({
                            initMenu: 'dataDir',
                          });
                          destroy?.();
                        }}
                        style={{ margin: '0 8px' }}
                      >
                        偏好设置
                      </Typography.Link>
                    ),
                  })}
                </div>
              </div>
            ),
          });
        }
      });
    }
  });
  const getUnReadMsg = useCallback(() => {
    // 获取 是否有未读的关键事项提醒
    msgCenterMessagesGet(
      {
        pageNum: 1,
        pageSize: 1,
        messageType: 'Critical_Message,OP_Message',
        unread: true,
      },
      SkipErrorNotifyOption,
    ).then((res) => {
      if (res?.data?.total && res?.data?.list?.[0]) {
        const content = res.data?.list?.[0];
        if (
          (content.messageType === 'Critical_Message' &&
            new Date().getTime() - new Date(content.createTime!).getTime() <=
              24 * 60 * 60 * 1000) ||
          content.messageType === 'OP_Message'
        ) {
          // 关键事项检查，仅提醒一天内的信息
          // 运营公告，不考虑
          openCriticalInspection(content);
        }
      }
    });
  }, []);
  useEffect(() => {
    if (currentTeam?.id) {
      let hd: string;
      (async () => {
        if (!getJwt()) return;
        hd = await ajaxEventClient.on('msg-center-new-message', 0, (content) => {
          if (content?.teamId === currentTeam?.id) {
            setAnimating(true);
            getUserMsgUnreadCountData();
            getUnReadMsg();
            play();
          }
        });
      })();
      getUserMsgUnreadCountData();
      getUnReadMsg();
      return () => {
        if (hd) {
          ajaxEventClient.un(hd);
        }
      };
    }
    return () => {};
  }, [currentTeam?.id]);

  // DAMAI-3593 暂不校验指纹是否需要升级
  // useEffect(() => {
  // if (!isElectron() || !currentTeam?.id) return () => {};
  // message.destroy('needUpgradeFinger');
  // const timer = setTimeout(() => {
  //   const clientBrowserVersion = getClientBrowserVersion();
  //   fingerCheckWhetherNeedUpgradeGet({ clientMajorVersion: clientBrowserVersion }).then((res) => {
  //     if (res.data?.upgradeClient) {
  //       // 客户端浏览器内核要更新
  //       message.warn({
  //         content: (
  //           <span>
  //             {I18N.t('当前客户端的浏览器内核过旧，建议升级客户端以更新浏览器内核')}
  //
  //             <a
  //               style={{ marginLeft: 20 }}
  //               onClick={() => {
  //                 openOfficialSiteByAppWindow(constants.appDownloadLinkFromApp);
  //               }}
  //               target="_blank"
  //             >
  //               {I18N.t('立即下载')}
  //             </a>
  //             <IconFontIcon
  //               iconName="guanbi_24"
  //               style={{ marginLeft: 20, cursor: 'pointer' }}
  //               onClick={() => message.destroy('needUpgradeClient')}
  //             />
  //           </span>
  //         ),
  //
  //         duration: 0,
  //         key: 'needUpgradeClient',
  //         className: styles.newAppVersionTips,
  //       });
  //     } else if (res.data?.upgradeFingers && hasFpAuth) {
  //       // 有指纹需要要更新
  //       message.warn({
  //         content: (
  //           <span>
  //             {I18N.t('花漾浏览器内核升级，当前团队的浏览器指纹需要同步升级')}
  //
  //             <a
  //               style={{ marginLeft: 20 }}
  //               onClick={() => {
  //                 showFpUpgradeConfirm('global', { hasAuth: hasFpAuth });
  //               }}
  //               target="_blank"
  //             >
  //               {I18N.t('了解更多')}
  //             </a>
  //             <IconFontIcon
  //               iconName="guanbi_24"
  //               style={{ marginLeft: 20, cursor: 'pointer' }}
  //               onClick={() => message.destroy('needUpgradeFinger')}
  //             />
  //           </span>
  //         ),
  //
  //         duration: 0,
  //         key: 'needUpgradeFinger',
  //         className: styles.newAppVersionTips,
  //       });
  //     }
  //   });
  // }, 2000);
  // return () => {
  //   clearTimeout(timer);
  // };
  // }, [hasFpAuth, currentTeam?.id]);

  useEffect(() => {
    userCenterEmitter.on('open', async (menu = 'userInfo', subMenu?: string) => {
      setUserCenterVisible(true);
      if (!userCenterRef.current) {
        await new Promise((resolve) => {
          setTimeout(resolve, 100);
        });
      }
      if (menu === 'userSetting') {
        userCenterRef.current?.openUserSettingModal(subMenu);
        userCenterRef.current?.openUserSettingModal(subMenu);
      } else {
        userCenterRef.current?.openUserInfoModal();
      }
    });
  }, []);

  useEffect(() => {
    let hd: string;
    let timer: any = 0;
    timer = setTimeout(() => {
      if (role?.code !== 'superadmin') return;
      let hidden = localStorage.getItem(`hide-new-user-activity-modal-${currentTeam?.id}`);
      if (hidden) return;
      activityIsActivityFinishedGet({ activityCode: '2022.05.ac2.step0' }).then(async (res) => {
        if (res.data) {
          const promiseArr: Promise<API.WebResultboolean>[] = [];
          newUserMissionOrder.forEach((code) => {
            promiseArr.push(activityIsActivityFinishedGet({ activityCode: code }));
          });
          Promise.all(promiseArr).then((resList) => {
            const currentStep = resList.findIndex((item) => item.data === false);
            if (currentStep === 0) {
              showNewUserActivityModal('init');
            } else if (currentStep === 1) {
              showNewUserActivityModal('step1');
            } else if (currentStep === 2) {
              showNewUserActivityModal('ip');
            }
          });
          hd = await ajaxEventClient.on('join_activity_success', 0, (message) => {
            hidden = localStorage.getItem(`hide-new-user-activity-modal-${currentTeam?.id}`);
            if (hidden) return;
            const { activityCode } = message;
            if (activityCode === '2022.05.ac2.step1') {
              showNewUserActivityModal('step1');
            } else if (activityCode === '2022.05.ac2.mark.ip') {
              showNewUserActivityModal('ip');
            } else if (activityCode === '2022.05.ac2.step2') {
              showNewUserActivityModal('step2');
            }
          });
        }
      });
    }, 2000);
    emitter.on('show-activity-modal', (view) => {
      showNewUserActivityModal(view);
    });
    return () => {
      clearTimeout(timer);
      if (hd) {
        ajaxEventClient.un(hd);
      }
    };
  }, [emitter, role?.code, showNewUserActivityModal, currentTeam?.id]);

  useEffect(() => {
    ipcRenderOn('open-user-center', () => {
      if (!userCenterRef.current) {
        setUserCenterVisible(() => true);
      }
      userCenterRef.current?.openUserInfoModal();
    });
    addEventListener('open-system-pref', (evt, data = {}) => {
      showUserSettingsModal({
        ...data,
      });
    });
  }, [showUserSettingsModal]);

  if (loading || !initialState?.currentUser) {
    return null;
  }

  try {
    return (
      <>
        <div className={styles.leftMenu}>
          <div className="head">
            <SwitchTeam />
            <Menus matchMenuKeys={props.matchMenuKeys} />
          </div>
          <div className={styles.links}>
            <AiChatMenuItem />
            <a
              style={{ overflow: 'hidden' }}
              className="menu-item"
              onClick={() => {
                show();
                // 测试铃铛动画 play();
              }}
            >
              <div className={styles.ringAlert}>
                <Badge
                  offset={[-7, 3]}
                  dot={unreadMsgCountList.length > 0}
                  className={classNames({
                    [styles.newMsgAnimation]: newMsgAnimation,
                  })}
                >
                  <div className={'lottie-wrapper'}>
                    <Lottie
                      ref={ref}
                      isStopped={!animating}
                      isPaused={!animating}
                      eventListeners={[
                        {
                          eventName: 'complete',
                          callback() {
                            stop();
                          },
                        },
                        {
                          eventName: 'segmentStart',
                          callback() {
                            setAnimating(true);
                          },
                        },
                      ]}
                      isClickToPauseDisabled
                      options={{
                        autoplay: false,
                        loop: false,
                        animationData: mail,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid',
                        },
                      }}
                    />
                  </div>
                </Badge>
                <span className="menu-item-name">{I18N.t('消息')}</span>
              </div>
            </a>
            <Typography.Link
              id={'hackConfirm'}
              style={{ visibility: 'hidden', width: 0, height: 0, overflow: 'hidden' }}
            ></Typography.Link>
            <CustomService />
            <Popover
              visible={userCenterVisible}
              placement="rightBottom"
              content={
                <UserCenter ref={userCenterRef} hidePopover={() => setUserCenterVisible(false)} />
              }
              trigger="hover"
              className="currentUser"
              overlayClassName={styles.currentUserOverlay}
              onVisibleChange={(visible) => setUserCenterVisible(visible)}
            >
              <Avatar src={user?.avatar} style={{ cursor: 'pointer' }} />
            </Popover>
            {!!document.querySelector('#window-title-bar-help-tooltips') &&
              createPortal(
                <div>
                  <a onClick={showCustomServiceModal}>{I18N.t('在线客服')}</a>
                </div>,
                document.querySelector('#window-title-bar-help-tooltips')!,
              )}
            {!!document.querySelector('#window-title-bar-custom-buttons') &&
              createPortal(
                <div
                  id="window-title-bar-settings-button"
                  className="window-title-btn"
                  onClick={() => showUserSettingsModal({})}
                >
                  <svg
                    className="icon"
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    p-id="10341"
                    width="16"
                    height="16"
                  >
                    <path
                      d="M576 64a64 64 0 0 1 64 64v56a351.616 351.616 0 0 1 91.968 53.12l48.64-27.968a64 64 0 0 1 87.36 23.424l64 110.848a64 64 0 0 1-23.424 87.424l-48.512 28.032a354.752 354.752 0 0 1 0 106.24l48.512 28.032a64 64 0 0 1 23.424 87.424l-64 110.848a64 64 0 0 1-87.424 23.424l-48.576-28.032c-27.52 22.08-58.496 40.128-91.904 53.12L640 896a64 64 0 0 1-64 64H448a64 64 0 0 1-64-64v-56a351.616 351.616 0 0 1-91.904-53.12l-48.64 27.968a64 64 0 0 1-87.424-23.424l-64-110.848a64 64 0 0 1 23.424-87.424l48.512-28.032a354.752 354.752 0 0 1 0-106.24l-48.512-28.032a64 64 0 0 1-23.424-87.424l64-110.848a64 64 0 0 1 87.424-23.424l48.64 27.968c27.52-22.016 58.432-40.064 91.904-53.12V128a64 64 0 0 1 64-64h128z m0 64H448v99.712l-40.768 15.936c-27.072 10.56-52.48 25.216-75.136 43.456l-34.112 27.328-86.528-49.856-64 110.848 86.4 49.92-6.592 43.136a290.752 290.752 0 0 0 0 87.04l6.528 43.2-86.4 49.856 64 110.848 86.592-49.856 34.112 27.328c22.72 18.24 48 32.896 75.136 43.52l40.704 15.872L448 896h128l0.128-99.712 40.64-15.936c27.136-10.56 52.48-25.28 75.2-43.52l34.112-27.328 86.464 49.92 64-110.848-86.4-49.92 6.592-43.136a290.752 290.752 0 0 0 0-87.04l-6.528-43.2 86.4-49.856-64-110.848-86.528 49.92-34.112-27.392a287.616 287.616 0 0 0-75.2-43.52l-40.64-15.872L576 128zM512 384a128 128 0 1 1 0 256 128 128 0 0 1 0-256z m0 64a64 64 0 1 0 0 128 64 64 0 0 0 0-128z"
                      fill="currentColor"
                      p-id="10342"
                    ></path>
                  </svg>
                  <div className="tooltip">
                    <div className="tooltip-inner">{I18N.t('偏好设置')}</div>
                  </div>
                </div>,
                document.querySelector('#window-title-bar-custom-buttons')!,
              )}
          </div>
        </div>
      </>
    );
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default LeftMenu;
