import type { ReactNode } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GhostModalCaller } from '@/mixins/modal';
import DMModal from '@/components/Common/Modal/DMModal';
import I18N from '@/i18n';
import { Button, Col, ConfigProvider, Row, Tabs } from 'antd';
import classNames from 'classnames';
import styles from '@/components/UserCenter/userSetting.less';
import SystemPrefForm from '@/components/UserCenter/components/SystemPrefForm';
import {
  addEventListener,
  getAppBuildNumber,
  getAppVersion,
  isElectron,
  sendAsync,
} from '@/utils/ElectronUtils';
import DataDirForm from '@/components/UserCenter/components/DataDirForm';
import MsgSubscribeForm from '@/components/UserCenter/components/MsgSubscribeForm';
import type { DeviceFormInstance } from '@/components/UserCenter/components/Devices';
import Devices from '@/components/UserCenter/components/Devices';
import type { FormInstance } from 'antd/lib/form/hooks/useForm';
import DMConfirm from '@/components/Common/DMConfirm';
import type { FormBaseProps } from '@/components/UserCenter/UserSetting';
import { UserSettingContext } from '@/components/UserCenter/UserSetting';
import BrowserPrefForm from '@/components/UserCenter/components/BrowserPrefForm';
import { metaAppVersionConfigsGet } from '@/services/api-MetaAPI/MetaController';
import { getVersionConfig } from '@/components/CustomService';
import { buildAppVersion } from '@/utils/utils';
import { openOfficialSiteByAppWindow } from '@/utils/pageUtils';
import constants from '@/constants';

const { TabPane } = Tabs;

type TabKey = 'systemPref' | 'msgSubscribe' | 'devices' | 'dataDir' | string;

type Props = {
  initMenu?: TabKey;
};

/**
 *
 * @param props
 * @constructor
 */
const UserSettingsModal: React.FC<Props> = (props) => {
  const [visible, setVisible] = useState(true);
  const onClose = useCallback(() => {
    setVisible(false);
  }, []);
  const [editing, setEditing] = useState(false);
  const [updateInfo, setUpdateInfo] = useState({ state: 'pending', percent: 10 });
  const loadingRef = useRef(false);
  const releaseVersion = getAppVersion();

  useEffect(() => {
    addEventListener('app-updater-info', (evt, data) => {
      setUpdateInfo(data.state);
    });
    sendAsync('check-app-updater');
  }, []);

  const checkNewestAppVersion = useCallback(() => {
    if (loadingRef.current) return;
    loadingRef.current = true;
    metaAppVersionConfigsGet()
      .then(async (versionConfigsData = {}) => {
        const appBuildNumber = getAppBuildNumber();

        const appPlatformCode: string = await new Promise((resolve) => {
          sendAsync('get-app-platform').then((code) => resolve(code));
          setTimeout(() => resolve(''), 100);
        });
        const config = getVersionConfig(versionConfigsData.data?.versionConfigs, appPlatformCode);
        if (config && config.earlyTryVersion! > Number(appBuildNumber)) {
          DMConfirm({
            iconType: 'info',
            title: I18N.t('有最新版本需要更新'),
            content: `${I18N.t('有新版本')}${
              config.appVersion
                ? ` ${buildAppVersion(config.appVersion, config.earlyTryVersion)} `
                : ''
            }${I18N.t('可以更新，请下载最新版本后，重新安装即可（原有数据将完整保留）')}`,

            okText: I18N.t('立即下载'),
            onOk: () => {
              openOfficialSiteByAppWindow(constants.appDownloadLinkFromApp);
            },
          });
        } else {
          DMConfirm({
            type: 'info',
            title: I18N.t('当前已经是最新版本'),
            content: I18N.t('恭喜，当前已经是最新版本，无需更新'),
          });
        }
      })
      .finally(() => {
        loadingRef.current = false;
      });
  }, []);
  const [activeKey, setActiveKey] = useState<TabKey>(props.initMenu ?? 'systemPref');
  const systemPrefForm = useRef<{
    submit: () => Promise<void>;
    resetFields: () => void;
  }>(null);
  const browserPrefForm = useRef<{
    submit: () => Promise<void>;
    resetFields: () => void;
  }>(null);
  const [loading, setLoading] = useState(false);
  const msgSubscribeForm = useRef<FormInstance>(null);

  useEffect(() => {
    if (props.initMenu) {
      setActiveKey(props.initMenu);
    }
  }, [props.initMenu]);

  /**
   * 保存
   */
  const handleSave = async () => {
    try {
      setLoading(true);
      if (activeKey === 'systemPref') {
        await systemPrefForm.current?.submit();
      } else if (activeKey === 'browserPref') {
        await browserPrefForm.current?.submit();
      } else if (activeKey === 'msgSubscribe') {
        await msgSubscribeForm.current?.submit();
      }
      setEditing(false);
      setLoading(false);
    } catch (e) {
      console.warn(e);
      setLoading(false);
      throw e;
    }
  };

  const footer = useMemo(() => {
    let btns: ReactNode[] = [];
    if (['devices', 'dataDir'].includes(activeKey)) {
      btns = [
        <Button key="close" onClick={onClose}>
          {I18N.t('关闭')}
        </Button>,
      ];
    } else if (editing) {
      btns = [
        <Button key="save" type="primary" loading={loading} onClick={() => handleSave()}>
          {I18N.t('保存')}
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            setEditing(false);
            if (activeKey === 'msgSubscribe') {
              msgSubscribeForm?.current?.reset?.();
            }
            if (activeKey === 'systemPref') {
              systemPrefForm?.current?.resetFields?.();
            }
          }}
        >
          {I18N.t('取消')}
        </Button>,
      ];
    } else {
      btns = [
        <Button key="edit" type="primary" onClick={() => setEditing(true)}>
          {I18N.t('编辑')}
        </Button>,
        <Button key="close" onClick={onClose}>
          {I18N.t('关闭')}
        </Button>,
      ];
    }
    return (
      <Row align={'middle'}>
        <Col flex={1} style={{ textAlign: 'left' }}>
          {isElectron() && (
            <>
              {I18N.t('版本：')}
              {releaseVersion}
              {updateInfo.state === 'downloading' && (
                <span>（正在下载新版本：{Math.ceil(updateInfo.percent)}%）</span>
              )}
              {updateInfo.state === 'downloaded' && (
                <span>
                  （新版本已就绪，
                  <a
                    onClick={() => {
                      sendAsync('quit-and-install-updater');
                    }}
                  >
                    立即安装
                  </a>
                  ）
                </span>
              )}
              <a style={{ marginLeft: 16 }} onClick={checkNewestAppVersion}>
                {I18N.t('检查更新')}
              </a>
            </>
          )}
        </Col>
        <Col>{btns}</Col>
      </Row>
    );
  }, [
    activeKey,
    editing,
    releaseVersion,
    updateInfo.state,
    updateInfo.percent,
    checkNewestAppVersion,
    onClose,
    loading,
    handleSave,
  ]);

  const formBaseProps: FormBaseProps = {
    editing,
  };

  return (
    <DMModal
      visible={visible}
      title={I18N.t('偏好设置')}
      width={912}
      bodyStyle={{ height: 470, padding: 0 }}
      onCancel={onClose}
      footer={footer}
    >
      <ConfigProvider form={{ requiredMark: false, validateMessages: { required: '' } }}>
        <UserSettingContext.Provider value={{ editing }}>
          <Tabs
            tabPosition="left"
            className={classNames(styles.tabs, { disabled: editing })}
            activeKey={activeKey}
            onTabClick={(key) => !editing && setActiveKey(key)}
          >
            <TabPane tab={I18N.t('花漾客户端')} key="systemPref">
              <SystemPrefForm {...formBaseProps} ref={systemPrefForm} />
            </TabPane>
            <TabPane tab={I18N.t('花漾浏览器')} key="browserPref">
              <BrowserPrefForm {...formBaseProps} ref={browserPrefForm} />
            </TabPane>
            {isElectron() && (
              <TabPane tab={I18N.t('配置文件')} key="dataDir">
                <DataDirForm />
              </TabPane>
            )}
            <TabPane tab={I18N.t('消息订阅')} key="msgSubscribe">
              <MsgSubscribeForm {...formBaseProps} ref={msgSubscribeForm} />
            </TabPane>
            <TabPane tab={I18N.t('登录设备')} key="devices">
              <Devices />
            </TabPane>
          </Tabs>
        </UserSettingContext.Provider>
      </ConfigProvider>
    </DMModal>
  );
};

export default UserSettingsModal;

export function useUserSettingsModal() {
  return useCallback((props: Props) => {
    GhostModalCaller(<UserSettingsModal {...props} />, 'UserSettingsModal');
  }, []);
}
