import { useState } from 'react';

export default () => {
  // 强制更新用户列表
  const [updateTime, setUpdateTime] = useState<number>(0);
  const resetUpdateTime = () => {
    setUpdateTime(new Date().getTime());
  };
  return { updateTime, resetUpdateTime };
};
