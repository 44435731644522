// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 分页查询FAQ GET /api/meta/faq/page */
export async function metaFaqPageGet(
  params: {
    // query
    /** title */
    title?: string;
    /** hide */
    hide?: boolean;
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultKfFaqDto>('/api/meta/faq/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
