import I18N from '@/i18n';
import { useCallback } from 'react';
import { message } from 'antd';
import { history } from 'umi';
import { getTeamIdFromUrl } from '@/utils/utils';
import { useModel } from '@@/plugin-model/useModel';

export default function useAiChatAction() {
  const {
    setBuyIp,
    setImportIp,
    importIpActionType,
    setBatchCreateAccount,
    batchCreateAccountArguments,
    setImportAccount,
    setCreateFlow,
    createFlowArguments,
    setImportFlow,
    setBuyFlow,
    setRunTask,
    runTaskArguments,
    setCreateRpaPlan,
    createRpaPlanArguments,
    setRunRpaPlan,
    runRpaPlanArguments,
    setViewTask,
    viewTaskArguments,
    setInviteTeamMember,
    setCreateTeamMember,
    setJoinTeam,
  } = useModel('actionTodo');
  const getAction = useCallback((code: string, actionArguments?: any) => {
    // console.log('actionArguments', actionArguments);
    const teamId = getTeamIdFromUrl();
    switch (code) {
      case 'ai_buy_platform_ip':
        return () => {
          history.push(`/team/${teamId}/ipManage/all`);
          setBuyIp(true);
        };
      case 'ai_import_ip':
      case 'ai_import_static_ip':
      case 'ai_import_dynamic_ip':
      case 'ai_batch_import_ip':
      case 'ai_create_ip_pool':
        return () => {
          history.push(`/team/${teamId}/ipManage/all`);
          setImportIp(true);
          importIpActionType.current = code;
        };
      case 'ai_create_account':
      case 'ai_batch_create_account':
        return () => {
          history.push(`/team/${teamId}/shopManage/all`);
          setBatchCreateAccount(true);
          batchCreateAccountArguments.current = actionArguments;
        };
      case 'ai_batch_import_account':
        return () => {
          history.push(`/team/${teamId}/shopManage/all`);
          setImportAccount(true);
        };
      case 'ai_create_rpa_flow':
        return () => {
          history.push(`/team/${teamId}/rpa/flow/`);
          setCreateFlow(true);
          createFlowArguments.current = actionArguments;
        };
      case 'ai_import_rpa_flow':
        return () => {
          history.push(`/team/${teamId}/rpa/flow/`);
          setImportFlow(true);
        };
      case 'ai_buy_rpa_flow':
        return () => {
          history.push(`/team/${teamId}/rpa/flow/`);
          setBuyFlow(true);
        };
      case 'ai_execute_rpa_flow':
        return () => {
          history.push(`/team/${teamId}/rpa/flow/`);
          setRunTask(true);
          runTaskArguments.current = actionArguments;
        };
      case 'ai_create_rpa_plan':
        return () => {
          history.push(`/team/${teamId}/rpa/schedule`);
          setCreateRpaPlan(true);
          createRpaPlanArguments.current = actionArguments;
        };
      case 'ai_execute_rpa_plan':
        return () => {
          history.push(`/team/${teamId}/rpa/schedule`);
          setRunRpaPlan(true);
          runRpaPlanArguments.current = actionArguments;
        };
      case 'ai_view_rpa_task':
        return () => {
          history.push(`/team/${teamId}/rpa/task`);
          setViewTask(true);
          viewTaskArguments.current = actionArguments;
        };
      case 'ai_create_team_member':
        return () => {
          history.push(`/team/${teamId}/teamManage/users`);
          setCreateTeamMember(true);
        };
      case 'ai_invite_user_join_team':
        return () => {
          history.push(`/team/${teamId}/teamManage/users`);
          setInviteTeamMember(true);
        };
      case 'ai_join_team':
        return () => {
          setJoinTeam(true);
        };
      case 'ai_certification':
        return () => {
          history.push(`/team/${teamId}/teamManage/setting?showTeamVerify=true`);
        };
      default:
        return () => {
          message.info(I18N.t('暂未开放'));
        };
    }
  }, []);

  return {
    getAction,
  };
}
