import type { AlertProps } from 'antd';
import { Alert } from 'antd';
import type { FC } from 'react';
import classNames from 'classnames';
import styles from './index.less';
import IconFontIcon from '@/components/Common/IconFontIcon';

/**
 *
 * @param props
 * @constructor
 */
const InfoTip: FC<
  Omit<AlertProps, 'showIcon' | 'banner'> & { offsetTop?: number; filled?: boolean }
> = (props) => {
  const { message, type = 'info', offsetTop = 0, filled, className, ...others } = props;
  return (
    <Alert
      type={type}
      className={classNames(className, styles.statusTip, 'status-tip')}
      icon={
        <IconFontIcon
          iconName={filled ? 'info_241' : 'info_24'}
          style={{
            top: offsetTop,
          }}
        />
      }
      banner
      showIcon
      {...others}
      message={message}
    />
  );
};
export default InfoTip;
