import { Tooltip, Typography } from 'antd';
import IconFontIcon from '@/components/Common/IconFontIcon';
import HelpLink from '@/components/HelpLink';

const HelpTooltip = (props: { title: any; link?: string; style?: React.CSSProperties }) => {
  const { title, link, style } = props;
  return (
    <Tooltip
      overlayInnerStyle={{ textAlign: 'justify' }}
      title={
        <div>
          {title}
          {link && (
            <HelpLink
              style={{ float: 'right', color: 'inherit', textDecoration: 'underline' }}
              href={link}
            />
          )}
        </div>
      }
    >
      <Typography.Text
        className={'help-tooltip-trigger'}
        style={{ cursor: 'pointer', color: 'inherit', fontSize: 'inherit', ...style }}
      >
        <IconFontIcon size={'inherit'} iconName="bangzhu_24" />
      </Typography.Text>
    </Tooltip>
  );
};
export default HelpTooltip;
