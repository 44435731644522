import I18N from '@/i18n';
import { history } from 'umi';
import buttonStyles from '@/style/button.less';
import ElectronUtils, { isElectron } from './ElectronUtils';
import Constants from '../constants';
import { getPortalUrl, SkipErrorNotifyOption } from '@/utils/utils';
import DMConfirm, { DMLoading } from '@/components/Common/DMConfirm';
import { shopByShopIdGet } from '@/services/api-ShopAPI/ShopController';
import { ipByIpIdGet } from '@/services/api-ShopAPI/IpController';
import { RESOURCE_NOT_AUTHORIZED, RESOURCE_NOT_FOUND } from '@/constants/ErrorCode';
import { getJwt } from '@/utils/jwt';
import pMinDelay from 'p-min-delay';
import { rpaFlowByRpaFlowIdGet } from '@/services/api-RPAAPI/RpaController';

const portQueue = [47326, 47327, 47328, 47329, 47330];

export function redirectToLogin() {
  history.push(Constants.loginPath);
}

/**
 * 跳转到首页
 * @param url
 */
export function redirectToHome(url = '/') {
  if (ElectronUtils.isElectron()) {
    ElectronUtils.emitEvent('open-home-window', {
      url: `${getPortalUrl(url)}?t=${Date.now()}`,
    });
  } else {
    history.push(url);
  }
}

/**
 * 通过系统系统自带浏览器打开官网链接
 * @param url 官网上的 path
 */
export function openOfficialSiteByAppWindow(url?: string) {
  const target = `${Constants.productSite}${url}`.replace(/\\/, '');
  if (ElectronUtils.isElectron()) {
    ElectronUtils.emitEvent('open-external-url', {
      url: target,
    });
  } else {
    window.open(target);
  }
}

/**
 * 在新窗口或者系统打开 url
 * 如果跟门户不同域，则会在系统默认浏览器打开
 * @param url
 * @param forceDefault 就用默认浏览器打开
 */
export function openInNewWindow(url: string, forceDefault?: boolean) {
  let urlTmp = url;
  if (!/^https?:\/\//.test(url)) {
    try {
      urlTmp = new URL(url, window.location.origin).href;
    } catch (e) {
      console.error(e);
    }
  }
  if (
    ElectronUtils.isElectron() &&
    (forceDefault || new URL(urlTmp).origin !== window.location.origin)
  ) {
    ElectronUtils.emitEvent('open-external-url', {
      url: urlTmp,
    });
  } else {
    window.open(urlTmp);
  }
}

/**
 * 拦截跳转行为
 * 跳转前拦一道
 * @param resourceType 资源类习惯
 * @param resourceId
 */
export const beforeJumpToDetail = (resourceType: 'Shop' | 'Ip' | 'RpaFlow', resourceId: number) => {
  let title = I18N.t('资源');
  return new Promise<void>((resolve, reject) => {
    let action: any | void = () => Promise.resolve();
    if (resourceType === 'Shop') {
      title = I18N.t('分身');
      action = () => shopByShopIdGet({ shopId: resourceId }, SkipErrorNotifyOption);
    } else if (resourceType === 'Ip') {
      title = 'IP';
      action = () => ipByIpIdGet({ ipId: resourceId }, SkipErrorNotifyOption);
    } else if (resourceType === 'RpaFlow') {
      title = 'RPA';
      action = () => rpaFlowByRpaFlowIdGet({ rpaFlowId: resourceId }, SkipErrorNotifyOption);
    }
    action()
      .then(resolve)
      .catch((res) => {
        const { code } = res?.data || {};
        switch (code) {
          case RESOURCE_NOT_FOUND:
            DMConfirm({
              type: 'info',
              title: `${I18N.t('该')}${title}${I18N.t('不存在')}`,
              content: `${I18N.t('该')}${title}${I18N.t(
                '不存在，可能已经被删除，无法查看其详情信息',
              )}`,
            });
            reject(new Error(`${title}${I18N.t('不存在')}`));
            break;
          case RESOURCE_NOT_AUTHORIZED:
            DMConfirm({
              type: 'warn',
              title: `${I18N.t('无权限执行此类操作')}`,
              content: `${I18N.t(
                '您当前的角色身份没有权限执行此类操作，请联络团队管理员以获取进一步的信息',
              )}`,
            });
            reject(new Error(`${I18N.t('无权限执行此类操作')}`));
            break;

          default:
            resolve();
        }
      });
  });
};

/**
 * 默认浏览器打开在线客服
 */
export function openOnlineService() {
  const url = Constants.qdSupportLink;
  if (ElectronUtils.isElectron()) {
    ElectronUtils.emitEvent('open-external-url', {
      url,
    });
  } else {
    window.open(url);
  }
}
/**
 * 默认浏览器打开链接
 */
export function openByDefaultBrowser(url: string) {
  if (ElectronUtils.isElectron()) {
    ElectronUtils.emitEvent('open-external-url', {
      url,
    });
  } else {
    window.open(url);
  }
}

/**
 * 简单的打开客户端
 */
export function openPureClient(path?: string) {
  location.href = `huayoung://${path || ''}`;
}

export function checkClient(timeout = 2000) {
  const startTime = Date.now();
  return new Promise((resolve, reject) => {
    let targetPort = 0;
    const ping = () => {
      const promiseArr = [];
      for (const port of portQueue) {
        promiseArr.push(
          new Promise((rsv, rjc) => {
            const t = setTimeout(() => {
              rjc(new Error('timeout'));
            }, 1000);
            pMinDelay(
              window.fetch(`http://127.0.0.1:${port}/ping`, {
                credentials: 'omit',
              }),
              500,
            )
              .then((r) => {
                clearTimeout(t);
                targetPort = port;
                resolve(port);
                rsv(r);
              })
              .catch((err) => {
                rjc(err);
              });
          }),
        );
      }
      Promise.allSettled(promiseArr).then(() => {
        if (!targetPort) {
          if (Date.now() - startTime > timeout) {
            reject(new Error(I18N.t('花漾客户端没有启动/安装')));
          } else {
            ping();
          }
        } else {
          resolve(targetPort);
        }
      });
    };
    ping();
  });
}

export function showClientUnavailable(url?: string) {
  DMConfirm({
    iconType: 'info',
    title: I18N.t('花漾客户端没有启动（或没有安装）'),
    content: I18N.t(
      '花漾客户端没有启动或者没有安装，如果您已经正确安装，请点击“打开花漾客户端”，否则，请下载并重新安装花漾客户端',
    ),

    footerBtns: [
      {
        key: 'openClient',
        label: I18N.t('打开花漾客户端'),
        btnProps: {
          type: 'primary',
        },
        onClick: () => {
          openPureClient(url);
        },
      },
      {
        key: 'download',
        label: I18N.t('下载'),
        btnProps: {
          className: buttonStyles.successBtn,
        },
        onClick: () => {
          openOfficialSiteByAppWindow(Constants.appDownloadLink);
        },
      },
      {
        key: 'cancel',
        label: I18N.t('取消'),
      },
    ],
  });
}

/**
 * 打开指定路径（登录态）
 * @param path
 * @param queryObj
 */
export async function openClient(path?: string, queryObj: Record<string, any> = {}) {
  const obj = {
    jwt: getJwt(),
    ...queryObj,
  };
  const queryStr = new URLSearchParams(obj).toString();
  const loadingModal = DMLoading({
    title: I18N.t('正在唤醒花漾客户端，请稍候...'),
    content: I18N.t('系统正在为您唤醒花漾客户端使其按照您的指令行事，唤醒成功后此对话框会自动消失'),
  });
  try {
    const port = await checkClient();
    loadingModal.destroy();
    const res = await window.fetch(`http://127.0.0.1:${port}/checkJwt?jwt=${obj.jwt}`);
    const data = await res.json();
    if (data.hasJwt && !data.isSame) {
      DMConfirm({
        iconType: 'info',
        title: I18N.t('花漾客户端当前登录者的身份不一致'),
        content: I18N.t(
          '花漾客户端当前登录者的身份与您的身份不一致，如果确信要切换登录者身份，请点击“重新登录”，请注意，此举会中断旧有登录者当前的工作',
        ),

        okText: I18N.t('重新登录'),
        onOk: () => {
          window.fetch(`http://127.0.0.1:${port}/openUrl`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ url: `${path || ''}?${queryStr}` }),
          });
        },
      });
    } else {
      window.fetch(`http://127.0.0.1:${port}/openUrl`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: `${path || ''}?${queryStr}` }),
      });
    }
  } catch (e) {
    loadingModal.destroy();
    showClientUnavailable(`${path || ''}?${queryStr}`);
  }
}
type SessionWindowParams = {
  teamId: number | string;
  shopId: number | string;
  sessionId: number | string;
};
export const openSessionRecordWindow = (params: SessionWindowParams) => {
  const { teamId, shopId, sessionId } = params || {};
  const url = `/team/${teamId}/record/shop/${shopId}/session/${sessionId}`;
  window.open(url, `session-record-player@${sessionId}`);
};
export const openSessionWatchWindow = (params: SessionWindowParams) => {
  const { teamId, shopId, sessionId } = params || {};
  const url = `/team/${teamId}/watch/shop/${shopId}/session/${sessionId}`;
  window.open(url, `session-watch@${sessionId}`);
};
type TracksWindowParams = {
  teamId: number | string;
  resourceType: 'shop' | 'ip';
  resourceId: number | string;
};
export const openTracksWindow = (params: TracksWindowParams) => {
  const { teamId, resourceType, resourceId } = params || {};
  const url = `/team/${teamId}/tracks/${resourceType}/${resourceId}`;
  const win = window.open(url, `tracks-${resourceType}@${resourceId}`);
  if (isElectron() && win) {
    win.ipcRenderer?.invoke?.('window-action', {
      action: 'show',
    });
  }
};
export function isOpenByShopShortcut() {
  if (window.location.search) {
    const searchParams = new URLSearchParams(window.location.search);
    return !!searchParams.get('sscToken');
  }
  return false;
}
// 浏览器页面 env
export const isBrowserEnv = process.env.UMI_ENV === 'browser';
