import I18N from '@/i18n';
import type { CSSProperties, ReactNode } from 'react';
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import type { ModalProps } from 'antd';
import { Modal, Button, ConfigProvider } from 'antd';
import type { SubmitterProps } from '@ant-design/pro-form/es/components/Submitter';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';
import { FooterStatusBar, unmountGhostModal } from '@/mixins/modal';
import styles from './index.less';
import classNames from 'classnames';
import EventEmitter from 'events';
import modalStyles from '@/style/modal.less';
import 'moment/locale/zh-cn';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import enUS from 'antd/lib/locale-provider/en_US';

interface Props extends ModalProps {
  uuid?: string;
  extraButtons?: ReactNode[];
  onEnter?: (e: any) => void;
  stepGuide?: boolean;
  asidePanel?: ReactNode;
  asideWrapperStyle?: CSSProperties;
  ghostModalId?: string;
  headless?: boolean;
  disabledDraggable?: boolean;
}

/**
 * 默认底部按钮：确定、取消
 * @param props
 * @constructor
 */
export function DefaultFooter(props: Props) {
  const {
    okText = I18N.t('确定'),
    cancelText = I18N.t('取消'),
    confirmLoading,
    uuid,
    extraButtons = [],
  } = props;

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { onCancel } = props;
    onCancel?.(e);
  };

  const handleOk = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { onOk } = props;
    onOk?.(e);
  };

  return (
    <>
      <FooterStatusBar uuid={uuid} />
      {extraButtons}
      <Button type="primary" loading={confirmLoading} onClick={handleOk} {...props.okButtonProps}>
        {okText}
      </Button>
      <Button onClick={handleCancel} {...props.cancelButtonProps}>
        {cancelText}
      </Button>
    </>
  );
}

export function getDefaultModalProps(props: Props = {}): ModalProps {
  let options: any = {
    destroyOnClose: true,
    maskClosable: false,
    centered: true,
    footer: <DefaultFooter {...props} />,
  };
  if (props.stepGuide) {
    options = {
      ...options,
      title: false,
      footer: null,
      width: 916,
      wrapClassName: styles.stepGuideModal,
    };
  }
  return {
    ...options,
    ...props,
  };
}

/**
 * ModalForm submitter 默认配置
 * @param props
 */
export function getModalFormSubmitter(
  props: SubmitterProps & { okText?: ReactNode; cancelText?: ReactNode } = {},
): SubmitterProps {
  return {
    render: ({ submit, reset, submitButtonProps, resetButtonProps }) => {
      // @ts-ignore
      const { preventDefault, ..._resetButtonProps } = resetButtonProps;
      return (
        <>
          <Button type="primary" onClick={submit} {...submitButtonProps}>
            {props.okText ?? I18N.t('确定')}
          </Button>
          <Button onClick={reset} {..._resetButtonProps}>
            {props.cancelText ?? I18N.t('取消')}
          </Button>
        </>
      );
    },
    ...props,
  };
}
export function DMContextProvider(props: any) {
  return <ConfigProvider locale={I18N.isCn() ? zhCN : enUS}>{props.children}</ConfigProvider>;
}

/**
 * 对 Modal 进行封装，改变其默认行为
 * @param props
 * @constructor
 */
const DMModal: React.FC<Props> = (props) => {
  const {
    onEnter,
    headless,
    className,
    asidePanel,
    ghostModalId,
    asideWrapperStyle,
    disabledDraggable,
    ...others
  } = props;
  const draggleRef = useRef<HTMLDivElement>(null);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

  const isDescendant = useCallback((parent, child) => {
    let node = child.parentNode;
    while (node != null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }, []);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && isDescendant(draggleRef.current, e.target)) {
        // @ts-ignore
        if (e.target?.tagName?.toLowerCase() !== 'textarea' || e.target.dataset.enterSubmit) {
          e.preventDefault();
          onEnter?.(e);
        }
      }
    },
    [isDescendant, onEnter],
  );

  useEffect(() => {
    if (onEnter) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (onEnter) {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);
  useEffect(() => {
    if (others.onCancel) {
      onceOnClose(others.onCancel);
    }
    return () => {
      if (others.onCancel) {
        removeClose(others.onCancel);
      }
    };
  }, []);
  useEffect(() => {
    if (!others.visible) {
      if (ghostModalId) {
        setTimeout(() => {
          unmountGhostModal(ghostModalId);
        }, 500);
      }
    }
  }, [ghostModalId, others.visible]);

  const onStart = (event: DraggableEvent, uiData: DraggableData) => {
    if (!draggleRef?.current) {
      return;
    }
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef.current.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };
  const _props = getDefaultModalProps(props);
  const _draggableArea = useMemo(() => {
    if (disabledDraggable) {
      return null;
    }
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: 24,
          background: 'transparent',
          opacity: 0,
          cursor: 'move',
          zIndex: 2,
          pointerEvents: 'visible',
        }}
        onMouseOver={() => {
          if (disabled) {
            setDisabled(false);
          }
        }}
        onMouseOut={() => {
          setDisabled(true);
        }}
        // fix eslintjsx-a11y/mouse-events-have-key-events
        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
        onFocus={() => {}}
        onBlur={() => {}}
      />
    );
  }, [disabledDraggable, disabled]);
  return (
    <DMContextProvider>
      <Modal
        {..._props}
        className={classNames(className, {
          [styles.dmModalWithAsidePanel]: asidePanel,
          [modalStyles.headlessModal]: headless,
        })}
        {...others}
        title={_props.title}
        modalRender={(modal) => {
          return (
            <Draggable
              disabled={disabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              {asidePanel ? (
                <div
                  style={{ display: 'flex', gap: 16, overflow: 'hidden', alignItems: 'stretch' }}
                  ref={draggleRef}
                >
                  <div
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      flex: 1,
                    }}
                  >
                    {_draggableArea}
                    {modal}
                  </div>
                  <div className="ant-modal-aside-panel" style={asideWrapperStyle}>
                    {asidePanel}
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    flex: 1,
                  }}
                  ref={draggleRef}
                >
                  {_draggableArea}
                  {modal}
                </div>
              )}
            </Draggable>
          );
        }}
      />
    </DMContextProvider>
  );
};
const eventEmitter = new EventEmitter();
export function dispatchClose() {
  eventEmitter.emit('close');
}
function onceOnClose(fn: (...args: any[]) => void) {
  eventEmitter.once('close', fn);
}
function removeClose(fn: (...args: any[]) => void) {
  eventEmitter.off('close', fn);
}
export default DMModal;
