import I18N from '@/i18n/I18N';

export default {
  ImportIps: I18N.t('导入IP'),
  ImportIp: I18N.t('导入IP'),
  ImportAccounts: I18N.t('导入分身'),
  ImportShop: I18N.t('导入分身'),
  UploadDiskFile: I18N.t('文件传输'),
  ImportIppIps: I18N.t('导入IP池IP'),
  OpenaiChatGenerate: I18N.t('AI对话生成'),
  TkSendEmail: I18N.t('TK发送邮件'),
  ProbeIps: I18N.t('IP质量测试'),
  UserRefreshIp: I18N.t('更换出口IP'),
  // DeleteIps: I18N.t('删除IP'),
};
