import UserAvatarAndName from '@/components/Common/UserAvatarAndName';
import { userByTeamByTeamIdUsersGet } from '@/services/api-Account/UserController';
import { getTeamIdFromUrl, SkipErrorNotifyOption } from '@/utils/utils';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useAuthJudgeResult } from '@/components/Common/FunctionButton';
import Functions from '@/constants/Functions';
import ErrorTips from '@/components/Common/ErrorTips';
import I18N from '@/i18n/I18N';
import ProTable from '@ant-design/pro-table';
import { scrollProTableOptionFn } from '@/mixins/table';
import { Checkbox, Col, Input, Radio, Row } from 'antd';
import constants from '@/constants';
import IconFontIcon from '@/components/Common/IconFontIcon';
import styled from 'styled-components';
import { useRequest } from 'umi';
import _ from 'lodash';
import DMModal from '@/components/Common/Modal/DMModal';

interface Props {
  selected?: number[];
  onChange: (keys: number[], rows: API.UserVo[]) => void;
  onInit?: (rows: API.UserVo[]) => void;
  type?: 'checkbox' | 'radio';
}

const StyledInput = styled(Input)`
  border-radius: 16px;
  &.ant-input-affix-wrapper-focused {
    .ant-input-prefix {
      color: #389fff;
    }
  }
  input {
    font-size: 12px;
  }
`;
const UserList: FC<Props> = (props) => {
  const { selected = [], onChange, onInit, type } = props;
  const [keyword, setKeyword] = useState('');
  const { data, loading, run } = useRequest(
    () => {
      return userByTeamByTeamIdUsersGet(
        {
          teamId: getTeamIdFromUrl(),
          pageNum: 1,
          pageSize: 99999,
        },
        SkipErrorNotifyOption,
      );
    },
    {
      manual: true,
      formatResult(res) {
        const _data = res?.data?.list?.filter((vo) => vo.viewDetail) || [];
        if (onInit) {
          const rows = _data.filter(({ id }) => selected.includes(id!));
          onInit([...rows]);
        }
        return _data;
      },
    },
  );
  const hasAuth = useAuthJudgeResult(Functions.TEAM_VIEW_MEMBER);
  useEffect(() => {
    if (hasAuth) {
      run();
    }
  }, [run, hasAuth]);
  if (!hasAuth) {
    return (
      <div style={{ height: '100%', overflow: 'hidden' }}>
        <ErrorTips
          title={I18N.t('无权限查看{{model}}', {
            model: I18N.t('团队成员'),
          })}
        />
      </div>
    );
  }
  const data_filtered =
    data?.filter((vo) => {
      const q = _.trim(keyword);
      return q ? vo.nickname?.toLowerCase().includes(q.toLowerCase()) : true;
    }) || [];

  return (
    <div style={{ overflow: 'hidden', flex: 1 }} className={classNames('user-list')}>
      <ProTable<API.UserDetailVo>
        size="small"
        loading={loading}
        dataSource={data_filtered}
        {...scrollProTableOptionFn({
          pagination: false,
        })}
        onRow={(record) => {
          return {
            onClick() {
              let ids = [...selected];
              if (type === 'radio') {
                ids = [record.id!];
              } else {
                const index = _.findIndex(selected, (i) => i === record.id);
                if (index !== -1) {
                  ids.splice(index, 1);
                } else {
                  ids.push(record.id!);
                }
              }

              onChange(ids, data?.filter((vo) => ids.includes(vo.id!)) || []);
            },
            style: {
              cursor: 'pointer',
            },
          };
        }}
        columns={[
          {
            width: 42,
            dataIndex: 'checkbox',
            align: 'center',
            title: () => {
              if (type === 'radio') {
                return false;
              }
              const allChecked = _.every(data_filtered, (item) => {
                return selected.includes(item.id!);
              });
              const someChecked = _.some(data_filtered, (item) => {
                return selected.includes(item.id!);
              });
              return (
                <Checkbox
                  checked={allChecked}
                  onChange={(e) => {
                    const diff_ids = data_filtered.map((item) => item.id!);
                    let ids = [...selected];
                    if (e.target.checked) {
                      ids.push(...diff_ids);
                    } else {
                      _.remove(ids, (id) => {
                        return diff_ids.includes(id);
                      });
                    }
                    ids = _.uniq(ids);
                    onChange(ids, data?.filter((vo) => ids.includes(vo.id!)) || []);
                  }}
                  indeterminate={!allChecked && someChecked}
                />
              );
            },
            render(_text, record) {
              const { id } = record;
              if (type === 'radio') {
                return <Radio checked={selected.includes(id!)} />;
              }
              return <Checkbox checked={selected.includes(id!)} />;
            },
          },
          {
            dataIndex: 'name',
            title: (
              <Row align={'middle'} gutter={[16, 16]} wrap={false}>
                <Col style={{ whiteSpace: 'nowrap' }}>{I18N.t('用户')}</Col>
                <Col flex={1}>
                  <StyledInput
                    prefix={<IconFontIcon size={12} iconName={'sousuo_24'} />}
                    placeholder={I18N.t('输入用户昵称进行检索')}
                    style={{ width: 220, fontSize: 12 }}
                    value={keyword}
                    onChange={(e) => {
                      setKeyword(e.target.value);
                    }}
                    allowClear
                  />
                </Col>
              </Row>
            ),
            render(_text, record) {
              return <UserAvatarAndName user={record} />;
            },
          },
          {
            dataIndex: 'role',
            width: 100,
            title: I18N.t('角色'),
            render(_text, record) {
              return constants.Role[record.role?.code] || record.role?.code;
            },
          },
        ]}
      />
    </div>
  );
};

export const UserListModal = (props: {
  type?: 'checkbox' | 'radio';
  selected: number[];
  onSubmit: (keys: number[], rows?: any[]) => void;
}) => {
  const { type, selected, onSubmit } = props;
  const [ids, setSelected] = useState(selected || []);
  const [visible, setVisible] = useState(true);
  return (
    <DMModal
      headless
      visible={visible}
      okButtonProps={{
        disabled: ids.length === 0,
      }}
      onOk={() => {
        onSubmit(ids);
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      width={640}
      bodyStyle={{
        height: 420,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      }}
    >
      <UserList onChange={setSelected} selected={ids} type={type} />
    </DMModal>
  );
};

export default UserList;
