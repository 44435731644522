import I18N from '@/i18n';
import { Button, Dropdown, Menu } from 'antd';
import styles from './index.less';
import Placeholder from '@/components/Common/Placeholder';
import type { OverflowProps } from 'rc-overflow';
import Overflow from 'rc-overflow';
import type { CSSProperties, HTMLProps } from 'react';
import { useCallback, useRef } from 'react';
import classNames from 'classnames';
import IconFontIcon from '@/components/Common/IconFontIcon';
import TagItem from '@/components/Common/TagManage/TagItem';
import VirtualList from '@/components/Common/VirtualList';
import styled from 'styled-components';

interface DropdownWrapperProps {
  data: any[];
  renderItem: (item: any, props?: HTMLProps<any>) => JSX.Element;
  renderRestItem?: (item: any, props?: HTMLProps<any>) => JSX.Element;
  itemKey?: string | number;
  overlayClassName?: string;
}

type Props = OverflowProps<any> & DropdownWrapperProps;

function useRenderDropdown(props: Omit<DropdownWrapperProps, 'data'>) {
  const { renderRestItem, renderItem, itemKey = 'id', overlayClassName } = props;
  return useCallback(
    (items: any[]) => {
      return (
        <Dropdown
          placement="bottomCenter"
          trigger={['click']}
          overlay={
            <Menu className={classNames(styles.menus, overlayClassName)}>
              <VirtualList
                height={240}
                rowKey={(item) => item[itemKey]}
                dataSource={items}
                onRow={() => {
                  return {
                    style: {
                      cursor: 'pointer',
                      padding: 0,
                    },
                  };
                }}
                renderItem={(item, key) => {
                  return (
                    <Menu.Item key={key} eventKey={key}>
                      {(renderRestItem || renderItem)(item)}
                    </Menu.Item>
                  );
                }}
              />
            </Menu>
          }
        >
          <Button
            size={'small'}
            type={'link'}
            className={classNames('overflow-more')}
            onClick={(e) => {
              e.stopPropagation();
            }}
            icon={<IconFontIcon size={16} iconName="gengduo_24" />}
          />
        </Dropdown>
      );
    },
    [itemKey, overlayClassName, renderItem, renderRestItem],
  );
}
/**
 * 显示几个，然后多的打点
 * @param props
 * @constructor
 */
const MoreDropdown = (props: Props) => {
  const {
    data = [],
    maxCount = 'responsive',
    renderItem,
    itemKey = 'id',
    renderRestItem,
    className,
    overlayClassName,
    ...others
  } = props;
  const ref = useRef<HTMLDivElement>();

  const renderDropdown = useRenderDropdown({
    itemKey,
    renderItem,
    renderRestItem,
    overlayClassName,
  });

  if (!data?.length) {
    return <Placeholder>{I18N.t('无数据')}</Placeholder>;
  }

  return (
    <div className={classNames(styles.moreDropdownWrapper, className, 'more-dropdown-wrapper')}>
      <Overflow
        ref={ref}
        data={data}
        itemKey={itemKey}
        maxCount={maxCount}
        renderItem={renderItem}
        renderRest={(items) => {
          if (ref.current) {
            if (items.length) {
              ref.current.classList.add('ellipsis');
            } else {
              ref.current.classList.remove('ellipsis');
            }
          }
          return renderDropdown(items);
        }}
        {...others}
      />
    </div>
  );
};

export default MoreDropdown;
export const TagMoreDropdown = (props: {
  tags?: API.TagDto[];
  style?: CSSProperties;
  className?: string;
  overlayClassName?: string;
  tagStyle?: CSSProperties;
}) => {
  const { tags, style, className, overlayClassName, tagStyle } = props;
  if (!tags?.length) {
    return <Placeholder />;
  }
  return (
    <MoreDropdown
      style={style}
      className={classNames(styles.tagMoreWrapper, className)}
      overlayClassName={classNames(styles.tagMoreOverlay, overlayClassName)}
      data={tags}
      maxCount={'responsive'}
      renderItem={(tag) => {
        return <TagItem tag={tag} key={tag.id} style={tagStyle} />;
      }}
    />
  );
};
export const StyledOverflow = styled(Overflow)`
  position: relative;
  flex: 1;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  margin-right: 32px;

  .rc-overflow-item {
    display: inline-block;
    overflow: hidden;
    vertical-align: bottom;

    &:not(.rc-overflow-item-rest) {
      padding-right: 8px;
    }
  }
`;
