import React from 'react';
import { Space } from 'antd';
import classNames from 'classnames';
import IconFontIcon from '@/components/Common/IconFontIcon';
import styles from './tips.less';

interface Props {
  title?: string;
  content: (string | React.ReactElement)[];
  className?: any;
  style?: React.CSSProperties;
}

const Tips: React.FC<Props> = (props) => {
  return (
    <div className={classNames(styles.tips, props.className)} style={props.style}>
      <Space size={8} align="start">
        <IconFontIcon iconName="info_241" />
        <Space direction="vertical">
          {props.title && <div className="tips-title">{props.title}</div>}
          {props.content &&
            props.content.map((item) => (
              <div
                key={typeof item === 'string' ? item : item.key || item.toString()}
                className="content-item"
              >
                {item}
              </div>
            ))}
        </Space>
      </Space>
    </div>
  );
};

export default Tips;
