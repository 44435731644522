import { useCallback, useEffect, useState } from 'react';
import EventEmitter from 'events';
import { setCurrentRole } from '@/hooks/useCurrentRole';
import { accountCurrentRoleGet } from '@/services/api-Account/AccountController';
import { clearOnTeamChange } from '@/hooks/useTags';
import { getTeamIdFromUrl, SkipErrorNotifyOption } from '@/utils/utils';
import { teamByTeamIdGet } from '@/services/api-Account/TeamController';

const emitter = new EventEmitter();
emitter.setMaxListeners(9999);
const UPDATE_EVENT_TEAM = Symbol('update_team');
const UPDATE_EVENT_AUTH = Symbol('update_auth');

let currentTeam: API.TeamDto;
let currentAuths: API.FunctionVo[];

export async function fetchCurrentTeam() {
  const teamId = getTeamIdFromUrl();
  return await teamByTeamIdGet({ teamId }).then((res) => {
    setCurrentTeam(res.data!);
    return res.data!;
  });
}
export function getCurrentTeam() {
  return currentTeam;
}
export function setCurrentTeam(data: API.TeamDto) {
  currentTeam = data;
  clearOnTeamChange();
  accountCurrentRoleGet(SkipErrorNotifyOption)
    .then((res) => {
      setCurrentRole(res.data!);
    })
    .catch((error) => {
      console.log(error);
    });
  emitter.emit(UPDATE_EVENT_TEAM, data);
}
export function setCurrentAuths(list: API.FunctionVo[]) {
  currentAuths = list;
  emitter.emit(UPDATE_EVENT_AUTH, list);
}
export default function useCurrentTeam() {
  const [team, setTeam] = useState<API.TeamDto>(currentTeam);
  const [auths, setAuths] = useState<API.FunctionVo[]>(currentAuths);
  const hasAuthedFunction = useCallback(
    (code: string | string[], userId: number | undefined): boolean => {
      const authCodes = auths?.map((o) => o.id);
      if (typeof code === 'string') {
        return team?.creatorId === userId || authCodes?.includes(code as any);
      }

      return team?.creatorId === userId || !(code ?? []).find((c) => !authCodes.includes(c as any));
    },
    [team?.creatorId, auths],
  );
  useEffect(() => {
    emitter.addListener(UPDATE_EVENT_TEAM, setTeam);
    emitter.addListener(UPDATE_EVENT_AUTH, setAuths);
    return () => {
      emitter.removeListener(UPDATE_EVENT_TEAM, setTeam);
      emitter.removeListener(UPDATE_EVENT_AUTH, setAuths);
    };
  }, []);

  return {
    team,
    setTeam: setCurrentTeam,
    auths,
    setAuths: setCurrentAuths,
    hasAuthedFunction,
  };
}
