import React, { createContext, useContext } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import type { FormItemProps } from 'antd';
import { Form } from 'antd';
import styles from './dmFormItem.less';

export const DMFormItemContext = createContext<{
  labelWidth?: number;
  disableLabelMuted?: boolean;
  wrapperColStyle?: React.CSSProperties;
}>({
  labelWidth: 115,
  disableLabelMuted: false,
  wrapperColStyle: undefined,
});

export function formItemFn(props: FormItemProps = {}): any {
  const { labelCol, ...otherProps } = props;

  const defaultOptions: FormItemProps = {
    colon: true,
    className: 'dm-form-item',
    labelCol: {
      className: classNames(labelCol?.className, styles.dmFormItemLabel),
      flex: 'none',
      style: {
        width: 115,
        ...labelCol?.style,
      },
    },
    style: {
      flexWrap: 'nowrap',
    },
  };

  return _.merge(defaultOptions, otherProps);
}

const DMFormItem: React.FC<FormItemProps & { labelWidth?: number; disableLabelMuted?: boolean }> = (
  props,
) => {
  const config = useContext(DMFormItemContext);
  const { children, labelWidth, wrapperCol, ...otherProps } = props;

  const mergedProps = _.merge(
    formItemFn(
      _.merge(otherProps, {
        labelCol: {
          className: classNames(
            otherProps.labelCol?.className,
            otherProps.disableLabelMuted ?? config.disableLabelMuted ? '' : styles.labelMuted,
          ),
        },
      }),
    ),
    {
      labelCol: {
        style: {
          width: labelWidth ?? config.labelWidth,
        },
      },
      wrapperCol: {
        style: config.wrapperColStyle ?? {
          overflow: 'auto',
        },
        ...wrapperCol,
      },
    },
  );
  return <Form.Item {...mergedProps}>{children}</Form.Item>;
};

export default DMFormItem;
