// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 创建账户功能屏蔽 POST /api/shop/policies/createBlockElement */
export async function shopPoliciesCreateBlockElementPost(
  params: {
    // query
    /** operateSharing */
    operateSharing?: boolean;
  },
  body: API.CreateBlockElementRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultBlockElementVo>('/api/shop/policies/createBlockElement', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 关闭所有安全策略 PUT /api/shop/policies/disableSecurityPolicy */
export async function shopPoliciesDisableSecurityPolicyPut(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/shop/policies/disableSecurityPolicy', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 查询团队默认分身属性 GET /api/shop/policies/getDefaultShopPolicy */
export async function shopPoliciesGetDefaultShopPolicyGet(options?: { [key: string]: any }) {
  return request<API.WebResultShopPolicyVo>('/api/shop/policies/getDefaultShopPolicy', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查询团队默认无痕分身属性 GET /api/shop/policies/getDefaultStatelessShopPolicy */
export async function shopPoliciesGetDefaultStatelessShopPolicyGet(options?: {
  [key: string]: any;
}) {
  return request<API.WebResultStatelessShopPolicyVo>(
    '/api/shop/policies/getDefaultStatelessShopPolicy',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 批量设置分身浏览器首页 PUT /api/shop/policies/setBookmarkBar */
export async function shopPoliciesSetBookmarkBarPut(
  body: API.UpdateBookmarkRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setBookmarkBar', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置团队默认分身属性 PUT /api/shop/policies/setDefaultShopPolicy */
export async function shopPoliciesSetDefaultShopPolicyPut(
  body: API.ShopPolicyVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setDefaultShopPolicy', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置团队无痕分身属性 PUT /api/shop/policies/setDefaultStatelessShopPolicy */
export async function shopPoliciesSetDefaultStatelessShopPolicyPut(
  body: API.StatelessShopPolicyVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setDefaultStatelessShopPolicy', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置分身黑白名单 PUT /api/shop/policies/setDomainPolicy */
export async function shopPoliciesSetDomainPolicyPut(
  params: {
    // query
    /** 是否联营策略 */
    operateSharing?: boolean;
  },
  body: API.UpdateDomainPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setDomainPolicy', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置分身首页 PUT /api/shop/policies/setFrontUrl */
export async function shopPoliciesSetFrontUrlPut(
  body: API.UpdateFrontUrlRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setFrontUrl', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置分身浏览器首页 PUT /api/shop/policies/setHomePage */
export async function shopPoliciesSetHomePagePut(
  body: API.UpdateHomePageRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setHomePage', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置账户其他策略 PUT /api/shop/policies/setOtherConfig */
export async function shopPoliciesSetOtherConfigPut(
  body: API.UpdateShopOtherPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setOtherConfig', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置账户会话访问策略 PUT /api/shop/policies/setPolicyConfig */
export async function shopPoliciesSetPolicyConfigPut(
  params: {
    // query
    /** 是否联营策略 */
    operateSharing?: boolean;
  },
  body: API.UpdateShopBasicInfoRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setPolicyConfig', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置私密网站 PUT /api/shop/policies/setPrivateDomain */
export async function shopPoliciesSetPrivateDomainPut(
  body: API.UpdatePrivateDomainRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setPrivateDomain', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置分身打开会话要求绑定IP、IP池 PUT /api/shop/policies/setRequireIp */
export async function shopPoliciesSetRequireIpPut(
  body: API.SetRequireIpRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setRequireIp', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置账户资源加载策略 PUT /api/shop/policies/setResourcePolicy */
export async function shopPoliciesSetResourcePolicyPut(
  body: API.UpdateResourcePolicyRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setResourcePolicy', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置分身是否开启安全策略 PUT /api/shop/policies/setSecurityPolicy */
export async function shopPoliciesSetSecurityPolicyPut(
  body: API.UpdateSecurityPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setSecurityPolicy', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置无痕分身策略 PUT /api/shop/policies/setStatelessPolicy */
export async function shopPoliciesSetStatelessPolicyPut(
  body: API.UpdateStatelessPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setStatelessPolicy', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量（全量）设置团队无痕分身属性 PUT /api/shop/policies/setStatelessShopPolicy */
export async function shopPoliciesSetStatelessShopPolicyPut(
  body: API.SetStatelessShopPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setStatelessShopPolicy', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置账户云端同步策略 PUT /api/shop/policies/setSyncPolicy */
export async function shopPoliciesSetSyncPolicyPut(
  body: API.UpdateSyncPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/setSyncPolicy', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置账户功能屏蔽 POST /api/shop/policies/updateBlockElements */
export async function shopPoliciesUpdateBlockElementsPost(
  params: {
    // query
    /** 是否联营策略 */
    operateSharing?: boolean;
  },
  body: API.UpdateBlockElementsRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/updateBlockElements', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置账户绕过代理域名 POST /api/shop/policies/updateDirectDomains */
export async function shopPoliciesUpdateDirectDomainsPost(
  params: {
    // query
    /** 是否联营策略 */
    operateSharing?: boolean;
  },
  body: API.UpdateDirectDomainsRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/updateDirectDomains', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量设置账户的插件配置（会清空原有插件） POST /api/shop/policies/updateShopExtension */
export async function shopPoliciesUpdateShopExtensionPost(
  params: {
    // query
    /** 是否联营策略 */
    operateSharing?: boolean;
  },
  body: API.UpdateShopExtensionRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/policies/updateShopExtension', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取账户访问方式配置 GET /api/shop/policies/${param0}/accessConfig */
export async function shopPoliciesByShopIdAccessConfigGet(
  params: {
    // query
    /** 是否联营策略 */
    operateSharing?: boolean;
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultShopAccessConfigVo>(`/api/shop/policies/${param0}/accessConfig`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取某个账户绕过代理域名 GET /api/shop/policies/${param0}/directDomains */
export async function shopPoliciesByShopIdDirectDomainsGet(
  params: {
    // query
    /** 是否联营策略 */
    operateSharing?: boolean;
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultListDirectDomainsDto>(`/api/shop/policies/${param0}/directDomains`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取分身的黑白名单 GET /api/shop/policies/${param0}/domainPolicy */
export async function shopPoliciesByShopIdDomainPolicyGet(
  params: {
    // query
    /** 是否联营策略 */
    operateSharing?: boolean;
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultShopDomainPolicyVo>(`/api/shop/policies/${param0}/domainPolicy`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取某个账户功能屏蔽列表（包括引用自团队的） GET /api/shop/policies/${param0}/shopBlockElements */
export async function shopPoliciesByShopIdShopBlockElementsGet(
  params: {
    // query
    /** 是否联营策略 */
    operateSharing?: boolean;
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultListBlockElementVo>(
    `/api/shop/policies/${param0}/shopBlockElements`,
    {
      method: 'GET',
      params: {
        ...queryParams,
      },
      ...(options || {}),
    },
  );
}
