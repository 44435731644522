import { useState } from 'react';

export default () => {
  const [directMemberCount, setDirectMemberCount] = useState(0);
  const [subDepartmentMemberCount, setSubDepartmentMemberCount] = useState(0);
  return {
    directMemberCount,
    setDirectMemberCount,
    subDepartmentMemberCount,
    setSubDepartmentMemberCount,
  };
};
