import React from 'react';
import type { FormItemProps } from 'antd/lib/form/FormItem';
import { UserSettingContext } from '../UserSetting';
import DMFormItem from '@/components/Common/DMFormItem';

type IFormItemProps = {
  label: string;
  name: string;
  [key: string]: any;
} & FormItemProps;

const FormItem: React.FC<IFormItemProps> = ({ label, name, children, ...restProps }) => {
  return (
    <UserSettingContext.Consumer>
      {({ editing = false }) => (
        <DMFormItem label={label} name={editing ? name : undefined} {...restProps}>
          {children}
        </DMFormItem>
      )}
    </UserSettingContext.Consumer>
  );
};

export default FormItem;
