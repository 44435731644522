import { request as httpRequest } from 'umi';
import { portalRpcClient } from '@/websocket/portalRpcClient';

export default async function request<T>(
  path: string,
  options?: { [propName: string]: any },
): Promise<T> {
  const {
    data,
    method = 'get',
    params,
    ws = false, //
    debug = false,
  } = options || {};
  const _data =
    typeof data != 'undefined'
      ? data
      : ['post', 'put', 'patch'].includes(method.toLowerCase())
      ? {}
      : undefined;
  const isFormData = _data instanceof FormData;
  if (ws && !isFormData && portalRpcClient.isConnected()) {
    let httpMethod = (method || 'get').toUpperCase();
    switch (httpMethod) {
      case 'GET':
      case 'POST':
      case 'PUT':
      case 'DELETE':
        //多发了一次请求，检查当前mvc调用是否可通过websocket发送。
        //todo 1.缓存mvcOption 2.找找有没有不通过发请求的方式来判断
        let mvcOption = await portalRpcClient.mvcOption(path, httpMethod);
        if (mvcOption.suitable || debug) {
          options = options || {};
          options.params = options.params || {};
          options.params = params;
          return portalRpcClient.invokeMvc(path, options);
        }
    }
  }
  return httpRequest(path, options);
}
