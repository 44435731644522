import I18N from '@/i18n';
import type { LegacyRef } from 'react';
import React, { forwardRef } from 'react';
import Placeholder from '@/components/Common/Placeholder';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { useUserSettingsModal } from '@/components/UserCenter/components/UserSettingsModal';

type TabKey = 'systemPref' | 'msgSubscribe' | 'devices' | string;

type Props = {
  triggerRef: LegacyRef<HTMLAnchorElement>;
  initMenu?: TabKey;
};

export type FormBaseProps = {
  editing: boolean;
};

export const UserSettingContext = React.createContext({
  editing: false,
});

export function renderReadOnlyFiled(value?: string) {
  if (!value) {
    return <Placeholder>{I18N.t('未设置')}</Placeholder>;
  }
  return value;
}

/**
 * 偏好设置
 */
const UserSetting: React.FC<Props> = forwardRef((props, ref) => {
  const showUserSettingsModal = useUserSettingsModal();

  return (
    <>
      <a ref={props.triggerRef} onClick={() => showUserSettingsModal({ initMenu: props.initMenu })}>
        <IconFontIcon iconName="pianhaoshezhi_24" />
        {I18N.t('偏好设置')}
      </a>
    </>
  );
});

export default UserSetting;
