import { useState } from 'react';

export default () => {
  // 当前IP详情的缓存
  const [ipList, setIpList] = useState<Record<number, API.IpPlatformDetailVo>>({});
  const setIpDetails = (ipDetails: API.IpPlatformDetailVo[]) => {
    setIpList((oldIpList) => {
      const newIpList = { ...oldIpList };
      ipDetails.forEach((o) => {
        newIpList[o.id || 0] = o;
      });

      return newIpList;
    });
  };
  const getIpDetail = (ipId: number) => ipList[ipId];
  return { getIpDetail, setIpDetails };
};
