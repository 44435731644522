import ColoursIcon from '@/components/Common/ColoursIcon';

export function getFileExt(name: string) {
  const filename = name.split('/').pop()?.split('?').pop() || '';
  return filename?.split('.')?.pop()?.toLowerCase();
}

const IconMap = {
  directory: 'wenjian-wenjianjia_24',
  mpg: 'wenjian-MPG_24',
  xls: 'wenjian-excal_24',
  xlsx: 'wenjian-excal_24',
  exe: 'wenjian-exe_24',
  zip: 'wenjian-ZIP_24',
  avi: 'wenjian-AVI_24',
  mp4: 'a-wenjian-MP4_24',
  pdf: 'wenjian-pdf_24',
  doc: 'wenjian-word_24',
  docx: 'wenjian-word_24',
  ppt: 'wenjian-ppt_24',
  pptx: 'wenjian-ppt_24',
  gif: 'wenjian-GIF_24',
  bat: 'wenjian-bat_24',
  mov: 'wenjian-MOV_24',
  js: 'wenjian-JS_24',
  bmp: 'wenjian-BMP_24',
  png: 'wenjian-PNG_24',
  html: 'wenjian-HTML_24',
  jpg: 'wenjian-JPG_24',
  rar: 'wenjian-RAR_24',
  htm: 'wenjian-HTM_24',
  css: 'wenjian-CSS_24',
  txt: 'wenjian-wenjian_24',
  record: 'luxiang_24',
};

const FileIcon = (props: { name: string; hack?: 'directory' | 'record'; size?: number }) => {
  const { name, hack, size } = props;
  if (hack) {
    return <ColoursIcon className={IconMap[hack]} size={size} />;
  }
  const type = getFileExt(name);
  const iconClass = (type && IconMap[type]) || 'wenjian-weizhiwenjian_24';
  return <ColoursIcon className={iconClass} size={size} />;
};
export default FileIcon;
