export default {
  '您的网络发生异常，无法连接服务器': 'Network error,cannot connect to the server.',
  您在当前团队中被禁用: 'You are banned from the team',
  您已从当前团队中被踢出: 'You have been kicked out of the team',
  '请联络当前团队管理员，以获取进一步的信息，我们将为您跳转到团队选择页面':
    "Pls contact the team's administrator for further information",
  请求失败: 'Request failed',
  解析流程失败: 'Parsing process failed',
  AI辅助流程: 'AI assisted process',
  插入至选中节点之后: 'Insert after selected node',
  变量提取: 'Variable extraction',
  '您可以将流程中出现的值提取为变量，提取为变量的好处是：流程拥有更广泛的适配性；变量可以提取为流程变量或者输入变量':
    'You can extract values that appear in the process as variables, thhis will make the process has wider adaptability.',
  变量名称: 'Variable',
  '该变量已存在，故使用旧有重名变量': 'The variable name already exists,.',
  '如不合适，请改名使其声明为新变量': 'If not appropriate, Pls change the name.',
  声明新变量: 'Declare Variable',
  变量类型: 'Variable Type',
  输入变量: 'Input Vars',
  流程变量: 'Process Vars',
  初始值: 'Initial Value',
  流程节点: 'Process Node',
  已复制到粘贴板: 'Copied Success',
  复制: 'Copy',
  删除: 'Delete',
  '确定要清空所有的聊天记录吗？': 'Are you sure to clear all chat history?',
  '聊天记录清空意味着会话的上下文也将被重置，请确认是否要继续':
    'Clearing the history means the context of the conversation will also be reset. Pls confirm.',
  清空: 'Clear',
  花漾: 'HuaYoung',
  麦克风已被禁用: 'Microphone disabled',
  '根据您的要求，我们为您生成了一个流程框架，您可以点击查看并决定是否将这些流程节点保存至流程中':
    'We generated a process code snippet for you. You can save it to the proc script.',
  '【点击查看】': '[View]',
  '您是否要执行操作：': 'Do you want to :',
  'RPA流程市场推荐：': 'RPA marketplace:',
  '参考资料：': 'References:',
  参考资料: 'References',
  '您的发言涉及敏感话题，本次会话结束。请不要再次出现类似话题，否则将导致AI助手对您不可用。':
    'Your speech involves a sensitive topic and this session is over. Pls do not raise similar topics again.',
  '为了符合相关法律法规的要求，您的每一次询问我们都需要和AI服务交互多次，以避免涉黄、涉暴、涉政等敏感话题，由于交互次数过多导致响应较慢，敬请谅解；如果响应太慢，您可以点击停止生成，然后重新提问':
    'In order to comply with the requirements of relevant laws and regulations, we need to interact with the AI service multiple times for each of your inquiries to avoid sensitive topics. If the response is too slow, you can click to stop the generation and ask again',
  '正在为您生成答案...': 'Generating answer for you...',
  停止生成: 'Stop generating',
  '请注意，您在这里沟通的内容请避免任何涉黄、涉赌、涉恐、以及涉及政治等内容，否则将会影响AI助手的正常使用':
    'Pls note that the content you communicate here should avoid any content involving pornography, gambling, terrorism, etc., otherwise it will affect the use of the assistant.',
  '-没有更多消息啦-': '-No more news-',
  '有问题请问我...': 'Pls ask me if you have any questions...',
  发送: 'Send',
  AI助手小漾同学: 'AI Assistant Mr. Young',
  AI助手: 'Assistant',
  无权限执行此类操作: 'Access Denied',
  '您当前的角色身份没有权限执行此类操作，请联络团队管理员以获取进一步的信息':
    "You does not have permission to perform this action, pls contact the team's administrator",
  清除已有的用户与组织单元授权关系:
    'Clear the existing user and department authorization relationship',
  '除超管与BOSS外，其它用户必须授权后才能够访问此分身':
    'Users(except boss) must be authorized before they can access.',
  经理只支持对自己所属组织单元内的员工进行授权:
    'Managers can only authorize employees to which they belong.',
  '清除已有的用户与组织单元授权关系？': 'Clear the existing user and department authorization?',
  '对指定分身进行授权，将清除分身旧有的授权关系，请确认是否要继续':
    "Authorizing the browser will clear it's old authorization relationship. Pls confirm.",
  确定: 'OK',
  取消: 'Cancel',
  经理角色不支持对组织单元授权: "Manager doesn't support auth to departments",
  '已选：': 'Selected:',
  个成员: ' Users',
  个部门: ' Dept',
  选择成员对象: 'Select member',
  用户授权: 'Users',
  组织单元授权: 'Departments',
  批量修改所选分身授权: 'Modify in batches',
  文件大小超过限制: 'File size exceeds limit',
  请选择图片: 'Pls select a picture',
  选择图片: 'Select Image',
  '支持JPG，PNG格式，文件大小不得超过10MB': 'Supports JPG/PNG, file size < 10MB',
  '桥接码：': 'Bridge Code:',
  复制成功: 'Copied Successfully',
  更换: 'Change',
  '内有效，到期将自动变更': ' valid, auto changed upon expiration.',
  秒后重新获取: 'seconds retrieve again',
  获取验证码: 'Verification Code',
  验证码已成功发送至: 'Verification code has been sent to',
  手机号已存在: 'Phone already exists',
  手机号已经存在: 'Phone already exists',
  '请确认您已经输入了正确的手机号，如果确认手机号无误，您可以选择找回密码':
    ' If you confirm that the mobile number is correct, you can reset password by phone.',
  邮箱已存在: 'Email already exists',
  邮箱已经存在: 'Email already exists',
  '请确认您已经输入了正确的邮箱，如果确认邮箱无误，您可以选择找回密码':
    'If the email address is correct, you can reset password by email.',
  请输入图形验证码: 'Pls enter the captcha',
  图形验证码: 'Captcha',
  请输入验证码: 'Pls enter the verification code',
  未标记任何标签: 'No tags tagged',
  标签: 'Tag',
  已复制到剪切板: ' copied to clipboard',
  '创建成功！': 'Created successfully!',
  我的团队: 'my team',
  的团队: 'team',
  创建并进入团队: 'Create & Start',
  已有团队: 'Existing Teams',
  立即加入: 'Join Now',
  '已申请加入团队，等待审核': 'Teams Applied and Awaiting Approval',
  刷新团队列表: 'Refresh',
  '团队是花漾非常重要的概念，任何资源如分身、指纹、IP地址等都隶属于团队；在使用花漾之前，您需要创建/加入一个团队：':
    'Team is a very important concept in HuaYoung. Before using HuaYoung, you need to create/join a team:',
  创建一个您的团队来管理您的资源: 'Create a Team and Begin Work',
  请输入团队名称: 'Team Name',
  '团队名称长度为1-100个字符！': 'Team name length is 1-100 characters!',
  基本信息: 'Basic Info',
  过滤条件: 'Filter Conditions',
  可见范围: 'Visible Range',
  编辑视图信息: 'Edit view information',
  对已有分身的属性设置权: 'Management the attributes of an existing browser',
  对已有IP的管理权: 'Management existing IPs',
  '您没有“': "You don't have ",
  '”的权限，无法将': '” permission, it is impossible to',
  视图授权给他人使用: 'Authorize the view to others',
  视图可见范围: 'Visible Range',
  仅视图创建者可见: 'Only to creator',
  团队所有成员可见: 'Visible to all members',
  经理及以上角色可见: 'Visible to BOSS and managers',
  指定部门可见: 'Visible to specified departments',
  已选: 'Selected ',
  部门: 'department',
  指定成员可见: 'Autorize to specified members',
  人: ' ',
  请输入视图名称: 'View Name',
  视图名称已存在: 'View name already exists',
  视图名称: 'View name',
  '请输入一个言简意赅的视图名称，建议32个字以内': 'Recommended to be within 32 characters.',
  视图介绍: 'Introduction',
  请输入视图介绍: 'Pls enter view introduction',
  所属平台: 'Platform',
  '请指定所属平台（可多选）': 'Pls specify the platform (multiple)',
  分身名称: 'Name',
  请输入正确的格式: 'Pls enter the correct format',
  '支持*和?通配符，其中：': 'Supports * and ? wildcards, where:',
  '*代表0至多个字符': '* means 0 to more char',
  '?代表1个字符': '? means 1 char',
  '如：我的*分身': 'Such as: my*browser',
  了解更多: 'Learn More',
  '支持*和?通配符，以半角逗号分隔多个条件':
    'Supports * and ? wildcards, and separates multiple conditions with ,',
  关联标签: 'Tags',
  '请指定分身关联标签（可多选）': 'Associated tags (multiple choices available)',
  导入日期: 'Import date',
  开始日期: 'Start date',
  结束日期: 'End date',
  IP类型: 'IP Type',
  请指定IP类型: 'Pls select IP type',
  全部: 'All',
  平台IP: 'Platform IP',
  自有IP: 'User IP',
  '*代表0至多个字符，?代表1个字符': '* means 0 to more char, ? means 1 char',
  '以半角逗号分隔多个条件，如：112.*.*.*,112.2??.2*.?1':
    "Separate multiple conditions with ',', such as: 112.*.*.*,112.2??.2*.?1",
  指定IP: 'Specify IP',
  IP状态: 'IP Status',
  所属国家: 'Country',
  '将各种分身过滤条件以视图的方式保存，并可以分享给团队其他成员，方便快捷查找各类分身':
    'Save various filter conditions as views and share them with other members',
  '将各种IP过滤条件以视图的方式保存，并可以分享给团队其他成员，方便快捷查找各类IP':
    'Save various IP filtering conditions in the form of views and share them with other team members.',
  输入视图信息: 'View Information',
  设置过滤条件: 'Filter Conditions',
  指定可见范围: 'Visible Range',
  完成: 'Finish',
  新视图创建成功: 'New view created successfully',
  新视图: 'New View',
  知道了: 'Got It',
  关闭: 'Close',
  暂无数据: 'No data',
  邮箱验证码已成功发送至: 'The verification code has been successfully sent to',
  请输入邮箱验证码: 'Pls enter the verification code',
  '请确认您的输入是否正确。如有疑问请联系客服':
    'Pls confirm your input is correct. Any questions Pls contact online support',
  重新加载: 'Reload',
  出错了: 'Error',
  重新选择团队: 'Reselect team',
  回到分身列表: 'Return to browser list',
  共: 'Total ',
  项: 'item',
  浏览器参数: 'Browser Paras',
  硬件指纹: 'Hardware',
  媒体设备指纹: 'Media Device',
  插件指纹: 'Plug-in',
  端口防护: 'Port Protection',
  WebRTC指纹: 'WebRTC',
  保存: 'Save',
  浏览器指纹特征码: 'Checksum',
  '浏览器指纹特征码是指针对全部指纹信息进行消息摘要算法计算出来的唯一编码，指纹中任何一个属性的变化都会影响到特征码，可通过特征码是否重复判断指纹的唯一性':
    'The FP checksum refers to a unique code calculated by the message digest algorithm for all fingerprint information. Changes in any attribute will affect the checksum.',
  编辑: 'Edit',
  查看浏览器指纹: 'View fingerprint',
  指纹来源: 'FP Source',
  来源地: 'Origin',
  指纹模板名称: 'Template Name',
  指纹模板名称的更改并不会影响到特征码的变化:
    'Changing the name of the fp template will not affect the checksum',
  指纹模板描述: 'Description',
  指纹模板描述的更改并不会影响到特征码的变化:
    'Changing the description will not affect the checksum',
  计算机名: 'Computer name',
  操作系统: 'OS',
  '操作系统和浏览器来源于UserAgent参数，我们并不建议您去修订UserAgent参数':
    'The operating system are derived from the UserAgent parameters. We do not recommend that you modify it.',
  浏览器: 'Browser',
  创建者: 'Creator',
  创建时间: 'Creation time',
  导入者: 'Importer',
  导入时间: 'Import',
  可用字体: 'Available Fonts',
  额外浏览器头: 'Extra Headers',
  通过换行符进行分隔: 'Separated by newlines',
  语言: 'Language',
  '语言是指浏览器运行态所属时区的默认语言，指纹实例运行态的值将会根据IP地址的地理位置回填真实的默认语言':
    'Language will backfill the real default language based on the geographical location of the IP address.',
  经纬度: 'Lat. & Long.',
  '经纬度是指浏览器运行态的地理位置，指纹实例运行态的值将会根据IP地址的地理位置回填真实的经纬度':
    'Latitude and longitude will backfill based on the geographical location of the IP address.',
  时区: 'Time Zone',
  '时区是指浏览器运行态的时区，指纹实例运行态的值将会根据IP地址的地理位置回填真实的的时区':
    'The value of the will backfill the real time zone based on the geographical location of the IP address.',
  '格式如：116.4,39.9': 'Such as: 116.4,39.9',
  请选择系统时区: 'Pls select time zone',
  跟随IP: 'By IP',
  禁用: 'Disable',
  设置: 'Settings',
  简体中文: 'Simplified Chinese',
  '繁体中文（台湾）': 'Traditional Chinese (Taiwan)',
  '繁体中文（香港）': 'Traditional Chinese (Hong Kong)',
  韩语: 'Korean',
  泰语: 'Thai',
  '印度尼西亚语-Indonesia': 'Indonesian-Indonesia',
  '菲律宾语-Filipino': 'Filipino-Filipino',
  '越南语-Ti?ngVi?t': 'Vietnamese-Ti?ngVi?t',
  '日语-日本語': 'Japanese - Japanese',
  '马来语-Melayu': 'Malay-Melayu',
  语言设置: 'Language Settings',
  '浏览器指纹中的语言属性，将影响到浏览器分身访问网站时的语言设置':
    'This attribute will affect the language setting when the browser visit the website.',
  当前在用的语言列表: 'List of Currently Languages',
  隐藏的语言列表: 'Hidden Language List',
  同步修订已生成的指纹实例: 'Synchronize revisions to generated fp instances',
  修改: 'Revise',
  修改UA: 'Modify UA',
  'UA是浏览器指纹中的重要组成部分，为了确保UA信息的真实性，强烈不建议您自行修改UA值，除非您觉得有必要，系统无法帮您验证修改后UA值的合法性':
    'In order to ensure the authenticity of the UA information, it is strongly not recommended that you modify the UA value yourself. ',
  'UA值：': 'UA value:',
  请输入UA: 'Pls enter UA',
  域名: 'Site',
  Cookie数量: 'Amount',
  自动: 'Automatic',
  请选择CPU内核个数: 'Pls select CPU cores',
  核: 'C',
  噪音模式: 'Noise pattern',
  启用: 'Enable',
  请选择WebGLvendor: 'Pls select WebGLvendor',
  请选择WebGLrenderer: 'Pls select WebGLrenderer',
  分辨率: 'Resolution',
  '分辨率是指浏览器的显示分辨率，指纹实例运行态的值将会回填主机分辨率的真实值':
    "Resolution will backfill the actual value of the computer's resolution.",
  CPU核数: 'CPU Cores',
  内存大小: 'Memory',
  Canvas保护: 'Canvas Protection',
  WebGL噪音: 'WebGL Noise',
  ClientRects噪音: 'ClientRects Noise',
  AudioContext保护: 'AudioContext Protection',
  WebGL保护: 'WebGL Protection',
  电池信息保护: 'Battery Protection',
  电量百分比: 'Battery Percentage',
  电源状态: 'Power Status',
  充电: 'Charge',
  断电: 'Power Outage',
  充满剩余时间: 'Full Remaining Time',
  分钟: ' min',
  使用剩余时间: 'Use Remaining Time',
  指定: 'Specify',
  请选择屏幕分辨率: 'Pls select screen resolution',
  '经纬度/时区设置': 'Latitude & longitude/time zone settings',
  '确定要将经纬度与时区属性设置为跟随IP吗？':
    'Are you sure to set the latitude, longitude and time zone attributes to follow the IP?',
  '指纹实例的经纬度与时区属性原则上应该和IP地址紧密相关，默认情况下，这两者的取值为“跟随IP”':
    'In principle, the latitude, longitude and time zone attributes of a fp instance should be closely related to the IP address.',
  恢复默认值: 'Restore Defaults',
  '确定要更改经纬度与时区的属性值吗？': 'Are you sure to change the these attribute values?',
  '指纹的经纬度与时区属性原则上应该和IP地址紧密相关，否则很容易造成两者不一致的情况，请确认是否继续':
    'The longitude, latitude, and time zone attributes of the fingerprint should be closely related to the IP address. Pls confirm.',
  '指纹实例中的经纬度与时区设置，原则上应该和IP地址紧密相关，如果在此处手工指定，请务必谨慎设置':
    'The latitude, longitude and time zone settings in the fingerprint instance should be closely related to the IP address. Be sure to set them carefully.',
  经纬度格式不正确: 'Latitude and longitude is incorrect',
  指定经纬度: 'Specify',
  '经度在前,纬度在后,英文逗号分隔': 'Longitude first, latitude last, separated by commas',
  百度: 'Baidu',
  谷歌: 'Google',
  拾取坐标: 'Pick',
  禁用经纬度: 'Disable',
  自动设置: 'Auto',
  手工指定: 'Manually',
  自定义媒体设备: 'Media Device',
  视频输入设备: 'Video Input Device',
  音频输入设备: 'Audio Input Device',
  音频输出设备: 'Audio Output Device',
  操作: 'Oper',
  添加设备: 'Add Device',
  设置设备信息: 'Set device information',
  随机生成设备信息: 'Randomly generate device information',
  自定义插件: 'Plugins',
  插件名称: 'Plugin Name',
  插件描述: 'Description',
  插件版本: 'Version',
  '插件列表：': 'Plug-in List:',
  添加插件: 'Add Plugin',
  设置插件信息: 'Set plug-in information',
  文件名称: 'File Name',
  端口扫描保护: 'Port Scan Protect',
  允许扫描的端口: 'Ports allowed to be scanned',
  请输入端口: 'Pls enter the port',
  端口格式不合法: 'The port format is illegal',
  '多个端口请用半角逗号分隔，例如：80,81,82':
    'Pls separate multiple ports with commas, for example: 80,81,82',
  已禁用: 'Disabled',
  已启用: 'Activated',
  自动填充: 'Autofill',
  返回真实IP: 'Return real IP',
  请输入IP: 'Pls enter IP',
  启用WebRTC: 'Enable WebRTC',
  WebRTC公网IP: 'WebRTC Public IP',
  '基于WebRTC技术获取浏览器的出口IP，生成指纹实例的运行态该值将会回填真实的IP地址':
    "Obtain the browser's public IP based on WebRTC technology. This value will backfill the real IP address.",
  WebRTC内网IP: 'WebRTC Intranet IP',
  '基于WebRTC技术获取浏览器的内网IP，生成指纹实例的运行态该值将会回填真实的IP地址':
    "Obtain the browser's intranet IP based on WebRTC technology. This value will backfill the real IP address.",
  真实值: 'Actual value',
  请更换搜索关键字: 'Pls change the keyword',
  请选择: 'Pls choose',
  请输入城市名称: 'Pls enter city name',
  请选择归属地: 'Pls select location',
  地域维护: 'Territory maintenance',
  '如果您有新的国家/省（州）/城市并没有出现在我们的知识库中，您可以自行添加这些地域至我们的知识库':
    'If you have new countries/provinces (states)/cities that do not appear in our library, you can add these regions to our knowledge base yourself',
  国家: 'Nation',
  请选择国家: 'Pls select country',
  '省（州）': 'Province(State)',
  '请输入省（州）的名称': 'Pls enter the province(state)',
  城市: 'City',
  请输入城市的名称: 'Pls enter the city',
  '恭喜，分身克隆成功': 'Congratulations, the browser was successfully cloned',
  '已从指定的VPS/云主机中将浏览器信息同步至此分身':
    'Browser information has been synchronized from the specified VPS host',
  'VPS/云主机克隆': 'VPS cloning',
  '在分身克隆成功之前，请不要关闭此对话框':
    'Pls do not close this dialog until clone successfully.',
  '确定要关闭此对话框吗？': 'Are you sure to close this dialog?',
  '如果克隆过程已经开始且尚未结束，请不要关闭此对话框':
    'If the cloning has started and has not ended yet, dont close this dialog',
  继续关闭: 'Continue Close',
  '1、如果您以前通过VPS/云主机或者其它PC管理分身，可通过分身克隆将其一键迁移至花漾灵动':
    '1. If you previously managed through VPS or other PCs, you can migrate them to HuaYoung with one click.',
  '2、迁移后不再建议使用VPS/云主机的浏览器访问网站，以避免Cookie数据产生干扰或者混乱':
    '2. After migration, it is no longer recommended to use VPS to access the website.',
  'VPS/云主机操作系统': 'Operating System',
  分身克隆步骤: 'Steps',
  安装麦可隆迁移工具: 'Install the Migration Tool',
  '麦可隆迁移工具是一个绿色程序，请复制下述链接，并在目标VPS/云主机上下载后运行':
    'The Migration Tool is a green program. Pls copy the link below and download it on the VPS and run it.',
  复制下载链接: 'Copy Download Link',
  分身环境迁移: 'Migration the Environment',
  '运行麦可隆迁移工具后，请输入当前分身的专属桥接码':
    'After running the Migration tool, Pls enter the bridging code.',
  暂未支持: 'Not Supported',
  '研发中，即将推出': 'Coming Soon',
  无数据: 'No data',
  优秀: 'Excellent',
  一般: 'Generally',
  较差: 'Poor',
  未知: 'Unknown',
  不可用: 'Unavailable',
  '测速...': 'Testing...',
  全部标签: 'All Tags',
  全部用户: 'All Users',
  全部品类: 'All Categories',
  全部平台: 'All Platforms',
  全部归属地: 'All Location',
  全部IP池: 'All Pools',
  全部站点: 'All Sites',
  根据站点名称搜索: 'Search by name',
  请指定所属平台: 'Pls specify the platform',
  可多选: 'Multiple',
  资源: 'Resource',
  该: 'Should',
  不存在: 'Does not exist',
  无法访问: 'Inaccessible',
  '不存在，可能已经被删除，无法查看其详情信息':
    'Not exist. It may have been deleted. Its details cannot be viewed.',
  '无法访问，无法查看其详情信息': 'Unable to access and view its details',
  浏览: 'Browse',
  选择: 'Choose',
  个平台: 'platforms',
  花瓣收入: 'Token Income',
  花瓣支出: 'Token Expense',
  全部类型: 'All Types',
  不支持会话保持特性: 'IP-Hold not supported',
  关于动态IP的会话保持特性: 'About the IP-Hold feature of dynamic IP',
  '动态IP是指“出口IP会发生变化的”代理服务，因此，我们必须知道如何控制其出口IP何时变化，我们把这种特性称之为“会话保持”。':
    'Dynamic IP refers to a proxy service whose outbound IP will change. We must know how to control when its outbound IP changes. We call this feature "IP-hold".',
  '动态IP的使用场景是：将动态IP绑定在某个分身上，当访问分身打开浏览器时，自动更换一个新的出口IP，在此浏览器生命周期内，该出口IP维持不变，直到该浏览器关闭；下次打开浏览器时再次更换一个新的出口IP，如此反复。':
    'The usage scenario of dynamic IP is: when the browser is opened, it will automatically change to a new outbound IP. During the life cycle of the browser, the outbound IP will remain unchanged until the browser is closed; the next time you open the browser, change it to a new outbound IP, and so on.',
  '为了实现“会话保持”的特性，需要针对不同的动态IP厂商进行适配，如果您的IP服务商不在上述列表，可联络花漾客服要求适配。':
    'In order to realize the "IP-Hold" feature, it is necessary to adapt to different dynamic IP vendors. If your IP service provider is not in the above list, you can contact the online support.',
  在线客服: 'Online Support',
  雅虎: 'Yahoo',
  雅虎日本: 'Yahoo Japan',
  zoho邮箱: 'zoho mailbox',
  RoyalMail邮箱: 'RoyalMailmail',
  网易163邮箱: 'NetEase 163 mailbox',
  网易126邮箱: 'NetEase 126 mailbox',
  网易yeah邮箱: 'NetEase yeah email',
  网易VIP邮箱: 'Netease VIP mailbox',
  腾讯企业邮箱: 'Tencent email',
  QQ邮箱: 'QQ mailbox',
  搜狐邮箱: 'Sohu Mail',
  新浪邮箱: 'Sina-mail',
  移动139邮箱: 'ChinaMobile 139 mailbox',
  电信189邮箱: 'ChinaTelecom 189 mailbox',
  '263企业邮箱': '263 email',
  TOM邮箱: 'TOM mailbox',
  其他: 'Other',
  请指定IP状态: 'Specify IP status',
  不限IP状态: 'All Status',
  依据语言关键词检索: 'Search by language',
  请选择所属平台: 'Pls select the platform',
  空中云汇: 'Airwallex',
  连连支付: 'LianLian pay',
  万里汇: 'Wanlihui',
  网易支付: 'NetEase Pay',
  全球: 'Global',
  个站点: 'sites',
  个标签: 'tags',
  依据时区关键词检索: 'Search by time zone',
  退出登录: 'Logout',
  请选择分身经营品类: 'Pls select a category',
  '（可多选）': '(Multiple)',
  '确定要切换团队吗？': 'Are you sure to switch teams?',
  '您在当前团队正在打开访问分身的会话，请确认是否需要关闭这些会话':
    'You are opening browsers in the current team. Pls confirm whether to close these browsers.',
  帮我中断我在当前团队中打开的所有分身访问会话:
    'Close all the browsers I have open in the current team',
  正在为您关闭分身会话: 'Closing the browsers',
  '分身访问会话结束后，将自动为您切换团队，请稍候...':
    'After the browser ends, your team will be automatically switched, pls wait...',
  '正在进入团队“{{name}}”，请稍候...': 'Entering the team "{{name}}", pls wait...',
  指纹模板: 'FP Template',
  依据标签名称进行检索: 'Search by tag name',
  标签管理: 'Tags Mgmt',
  标签不能为空: 'Tag cannot be empty',
  标签长度应小于60个字符: 'Tag length should be less than 60 characters',
  新标签: 'New tag',
  标签至少两个字符: 'Tag must be at least 2 chars',
  '确定要删除此标签吗？': 'Are you sure to delete this tag?',
  '在此处删除标签意味着会对所有打过此标签的对象移除此标签，请确认是否继续':
    'Deleting a tag here means that the tag will be removed from all objects that have been tagged. Pls confirm.',
  '正在为您删除此标签，请稍候...': 'This tag is being deleted, pls wait...',
  正在加载数据: 'Loading data',
  查看更多: 'More',
  暂无IP切换记录: 'No IP switching records',
  暂无操作轨迹: 'No operation tracks',
  您没有查看团队其它成员操作轨迹的权限:
    'You do not have permission to view the tracks of other members',
  我的: 'Mine',
  IP切换: 'Switch',
  '（IP已删除）': '(IP has been deleted)',
  归属地: 'Location',
  指纹特征码: 'Checksum',
  操作详情: 'Details',
  操作者: 'Operator',
  操作时间: 'Oper Time',
  操作结果: 'Result',
  成功: 'Success',
  失败: 'Fail',
  系统: 'System',
  '版本升级提示：花漾客户端有最新版本，请您尽快升级':
    'New Version: New Version Found, Please Upgrade ASAP',
  有最新版本需要更新: 'There is a new version',
  有新版本: 'There is a new version',
  '可以更新，请下载最新版本后，重新安装即可（原有数据将完整保留）':
    'It can be updated, Pls download the latest version and reinstall it',
  立即下载: 'Download Now',
  下次再说: 'Later',
  '错误号：': 'Error Number:',
  系统遇到了异常情况: 'The system encountered an abnormal error',
  '花漾浏览器引擎文件可能不完整，请您按照以下步骤进行修复：':
    'The HuaYoung Browser file may be incomplete. Pls follow the steps below to repair it:',
  '1.如果电脑打开了360安全卫士等杀毒软件，请将其临时关闭':
    '1. If anti-virus software is opened, Pls temporarily close it.',
  '2.关闭上述杀毒软件后，请点击“立即修复”':
    '2. After closing the above anti-virus software, Pls click "Repair Now',
  立即修复: 'Fix now',
  '正在为您进行修复，请稍候': 'We are repairing it for you, pls wait.',
  '这可能需要一点时间，请稍候': 'This may take a moment, pls wait',
  修复花漾浏览器引擎文件超时: 'Fix HuaYoung browser file timeout',
  '建议您下载最新版本，重新安装': 'Pls download the latest version and reinstall it',
  系统已完成修复: 'The system has been repaired',
  '请重新访问分身，验证是否修复成功':
    'Pls open the browser again to verify whether the repair is successful.',
  修复花漾浏览器引擎文件失败: 'Failed to repair HuaYoung browser file',
  '非常抱歉，系统遇到了中断性错误': 'Sorry, the system has encountered a disruptive error',
  '造成此错误的原因通常是因为：': 'The cause of this error is usually because:',
  错误信息复制成功: 'Copied successfully',
  重新下载: 'Download again',
  '浏览器窗口大小发生了变化，这会导致群控出错，请对窗口重新布局':
    'The size of the browser has changed, which will cause group control errors. Pls rearrange the window.',
  '您正在访问我们的应用站点，建议您下载并安装“花漾客户端”，否则您将无法使用访问分身以打开花漾浏览器等功能。':
    'You are visiting our application site. It is recommended that you download and install the HuaYoung client app',
  '版本升级提示：花漾客户端有最新版本，即将为您自动升级（':
    'New version: HuaYoung has a new version and will be automatically upgraded for you (',
  暂不升级: 'Not upgrading',
  当前已经是最新版本: 'It is currently the latest version',
  '恭喜，当前已经是最新版本，无需更新': 'Congratulations, it is already the latest version',
  已经为您准备了最新版本: 'The latest version has been prepared for you',
  '最新版本已经就绪，请点击“立即重启”为您更新（原有数据将完整保留）':
    'The latest version is ready. Pls click "Restart Now" to complete the upgrade (the original data will be completely retained)',
  立即重启: 'Restart Now',
  升级: 'Upgrade',
  发现有新版本可供升级: 'Found a new version',
  '强烈建议您立即下载最新版本，重新安装（无需卸载）即可无缝升级':
    'It is recommended that you download the latest version and reinstall (no need to uninstall) to upgrade seamlessly',
  新版本: 'New Ver',
  支持: 'Support',
  常见问题: 'FAQs',
  查看更多常见问题: 'View More',
  '有任何关于技术支持、商务、合作等各类需求请与我们的在线客服进行联系':
    'Any questions, pls contact our online support',
  微信客服: 'WeChat Support',
  '在线时间，08:00-24:00': 'Online, 08:00-24:00',
  其余时间可留言: 'Leave message during rest time',
  帮助文档: 'Help Documents',
  '或请参考我们的帮助文档，': 'Or refer to our help documentation,',
  进行自助式查询: 'Make self-service inquiries',
  产品技术支持: 'Product',
  技术支持客服: 'Technical Service',
  商务合作: 'Business',
  商务合作洽谈: 'Business Cooperation',
  热线电话: 'Hotline',
  邮件咨询: 'Email',
  技术支持邮件: 'Email',
  '（2小时内响应）': '(Response 2 hours)',
  '版本：': 'Version:',
  检查更新: 'New Version?',
  每页显示条数应该在: 'The num of items on each page should be',
  之间: 'between',
  请输入您要跳转的页码: 'Pls enter the page number',
  '条记录，': ' records,',
  每页显示: 'Every page shows',
  条: 'items',
  '条/页': ' /page',
  页: 'pages',
  跳转到: 'Goto',
  跳转: 'Goto',
  全部菜单: 'All menus',
  创建您的新团队: 'New Team',
  创建并切换到新团队: 'Create and switch to a new team',
  请输入您的新团队名称: 'Pls enter new team name',
  注意事项: 'Precautions',
  该消息产生于: 'Message generated ',
  '前，如果您已处理完毕，请忽略此消息': ' ago, if addressed, pls ignore',
  '当前团队存在以下事项，需要您的注意': 'Attention Needed',
  关键事项检查: 'Key Issue',
  我知道了: 'Got it',
  '当前客户端的浏览器内核过旧，建议升级客户端以更新浏览器内核':
    'The current browser kernel is too old. It is recommended to upgrade.',
  '花漾浏览器内核升级，当前团队的浏览器指纹需要同步升级':
    "HuaYoung browser kernel upgrade, the current team's browser fingerprint needs to be upgraded",
  消息: 'Message',
  创建浏览器指纹实例即可获得1000花瓣激励:
    'Create a browser fingerprint instance to get 1,000 tokens as an incentive',
  '代金券一张，只需再支付2元即可购买公有云IP一个（为期一周）':
    'With one voucher, you only need to pay another 2 ¥ to purchase a public cloud IP (for one week)',
  '创建并访问分身成功后，即可获得1000花瓣激励':
    'After successfully creating and accessing the avatar, you will receive 1,000 tokens as an incentive.',
  '创建浏览器指纹实例，即可获得1000花瓣激励':
    'Create a browser fingerprint instance to get 1,000 tokens as an incentive',
  '1.每个分身就是一个隔离的浏览器分身环境，而指纹实例则是浏览器分身环境的特征信息，需要保证指纹实例的唯一性与真实性':
    '1. Each avatar is an isolated browser avatar environment, and the fingerprint instance is the characteristic information of the browser avatar environment. It is necessary to ensure the uniqueness and authenticity of the fingerprint instance.',
  '2.为确保指纹实例的唯一性与真实性，需要先采集物理世界的浏览器指纹以形成指纹模板，再通过指纹模板裂变生成指纹实例':
    '2. In order to ensure the uniqueness and authenticity of the fingerprint instance, it is necessary to first collect the browser fingerprint in the physical world to form a fingerprint template, and then generate the fingerprint instance through fission of the fingerprint template.',
  '3.指纹实例创建成功后，您将获得1000花瓣激励，花瓣是一种代币（100花瓣=1RMB），可用于系统内部支付':
    '3. After the fingerprint instance is successfully created, you will receive 1,000 tokens as an incentive. Tokens are a token (100 tokens = 1RMB) that can be used for internal payment within the system.',
  立即创建: 'Create Now',
  '恭喜，您已获得面值10元的代金券一张':
    'Congratulations, you have received a voucher with a face value of 10 ¥',
  '1.只需再支付2元，您即可购买一个公有云IP（为期1周）':
    '1. For only 2 ¥ more, you can purchase a public cloud IP (for 1 week)',
  '2.购买IP后，可将IP绑定在分身上，并访问分身以打开花漾灵动':
    '2. After purchasing the IP, you can bind the IP to the avatar and open the browser to open HuaYoung.',
  立即购买: 'Buy Now',
  '创建分身并访问分身，即可获得1000花瓣激励':
    'Create an avatar and visit the avatar to get 1,000 tokens as an incentive',
  '1.现在可以新建一个分身，每个分身就是一个完全隔离的浏览器分身环境':
    '1. Now you can create a new avatar. Each avatar is a completely isolated browser environment.',
  '2.为分身绑定浏览器指纹实例和IP地址，访问此分身以打开花漾灵动':
    '2. Bind the browser fingerprint instance and IP address to the avatar, and open the browser to open HuaYoung',
  '3.当成功打开花漾灵动后，您将再次获得1000花瓣的激励':
    '3. When you successfully open the Flower Splash, you will receive another incentive of 1,000 tokens.',
  '恭喜，您已获得1000花瓣的激励': 'Congratulations, you have received the incentive of 1000 tokens',
  '1.您已经创建了指纹实例，指纹实例用于绑定在分身上':
    '1. You have created a fingerprint instance, which is used to bind to the avatar.',
  '2.您已获得1000花瓣激励，花瓣是一种代币（100花瓣=1RMB），可用于系统内部结算':
    '2. You have received 1,000 tokens as an incentive. Tokens are a token (100 tokens = 1RMB) that can be used for internal settlement in the system.',
  '3.点击继续参与，您将获得一张价值10元代金券，只需支付2元，既可购买一个公有云IP（为期1周）':
    '3. Click to continue participating, and you will receive a voucher worth 10 ¥. You only need to pay 2 ¥ to purchase a public cloud IP (for 1 week)',
  '恭喜，您已成功购买IP地址': 'Congratulations, you have successfully purchased an IP address',
  '1.您已成功购买了平台IP地址，IP地址可用于绑定在分身上':
    '1. You have successfully purchased the platform IP address. The IP address can be used to bind to the avatar.',
  '2.您现在可以创建一个分身，并为此分身绑定刚才创建的指纹实例与IP地址':
    '2. You can now create a avatar and bind the fingerprint instance and IP address just created to this avatar.',
  '3.当访问分身以打开花漾灵动后，您将再次获得1000花瓣的激励':
    '3. After visiting the avatar to open the flower agility, you will get the incentive of 1000 tokens again',
  '本次新手有礼活动（阶段一）已经结束，请关注我们的公众号，持续参与我们的活动':
    'This novice courtesy event (Phase 1) has ended, Pls follow our official account and continue to participate in our activities',
  '新手有礼活动（阶段一）': 'Novice courtesy activities (Phase 1)',
  '现在参与，即送180元大礼包': 'Participate now and get a gift package worth 180 ¥',
  新手有礼活动: 'Beginner’s Gift Activities',
  阶段一: 'Stage one',
  我要参加: 'I want to participate',
  查看费用中心: 'View cost centers',
  继续参与: 'continue to participate',
  更多信息请阅读: 'For more information pls read',
  不再提醒: "Don't Remind Again",
  回头再说: 'Later',
  '非法的邀请链接，请检查邀请链接并重新输入':
    'Illegal invitation link, Pls check the link and re-enter it.',
  '正在为您验证邀请链接的合法性...': 'Verifying the invitation link for you...',
  '合法的邀请链接，点击确定马上加入吧': 'Legitimate invitation link, click OK to join now',
  请填写邀请链接: 'Please fill in the invitation link',
  '您已成功加入“{{name}}”': 'You have successfully joined "{{name}}"',
  进入团队: 'Enter the Team',
  '恭喜，您已成功加入团队“': 'Congratulations, you have successfully joined the team',
  '您已经在“{{name}}”团队中了': 'You\'re already in the "{{name}}" team.',
  '您已经是“{{name}}”团队的成员，无需重复申请':
    'You are already a member of the "{{name}}" team, no need to reapply!',
  '您已经申请加入“{{name}}”团队': 'You have applied to join the "{{name}}" team!',
  '您申请加入“{{name}}”团队正在等待团队管理员的审核，无需重复申请':
    'Your application to join the "{{name}}" team is waiting to be reviewed by the team administrator, no need to repeat the application!',
  '加入“{{name}}”团队的申请已发出': 'Applications to join the "{{name}}" team have been sent.',
  '您的团队加入申请已提交，正在等待审核，如果您绑定了手机，审核通过后将得到一条短信通知，请留意查收':
    'Your application to join the team has been submitted and is waiting for review.',
  团队创建者: 'Team creator',
  团队名称: 'Team Name',
  加入团队: 'Join Team',
  邀请链接: 'Invite Link',
  当前所处团队: 'Current Team',
  '团队ID：': 'Team ID:',
  团队ID: 'Team ID',
  '我的角色：': 'Role: ',
  超管: 'SuperAdmin',
  '我加入的其他团队（共{{count}}个）': "Other teams I've joined (total {{count}})",
  团队标识: 'Team ID',
  我的角色: 'My Role',
  当前未加入其他团队: 'No other teams',
  创建新团队: 'Create Team',
  加入已有团队: 'Join Team',
  全部分身: 'All',
  未绑定IP的分身: 'Unbound IP',
  未设置指纹的分身: 'Unbound Fingerprint',
  待授权的分身: 'Unauthorized',
  我收藏的分身: 'My Favorites',
  窗口同步: 'Windows Sync',
  全部IP: 'All',
  空闲的IP: 'Free',
  临期的IP: 'Expiring',
  已失效的IP: 'Expired',
  IP池: 'IP Pool',
  全部指纹模板: 'FP Templates',
  全部指纹实例: 'FP Instances',
  模板生成的指纹实例: 'Instance By Template',
  单独的指纹实例: 'Instance Individual',
  流程定义: 'Proc Script',
  流程任务卡: 'Task Card',
  我的云盘: 'My Drive',
  团队云盘: 'Team Drive',
  我的收藏: 'My Favorites',
  存储空间分析: 'Analysis',
  自定义视图: 'Custom view',
  默认视图: 'Default view',
  指纹: 'FP',
  机器人RPA: 'RPA',
  视图排序调整: ' View Sorting ',
  视图: ' View',
  调整: 'Adjustment ',
  视图的显示顺序: ' display order',
  '请采集指纹模板，并通过指纹模板生成指纹实例，指纹实例创建成功后，您将获得1000花瓣的激励':
    'Pls collect a fingerprint template and generate a fingerprint instance, you will receive 1,000 tokens.',
  '1、指纹实例是判断浏览器唯一性的特征标识':
    '1. The fingerprint is an identifier that determines the uniqueness of browser.',
  '2、花漾在业界首创“采集物理世界的浏览器特征信息以形成指纹模板，再对指纹模板加以微调与噪音从而裂变成若干指纹实例”的创新性做法，确保了每一个指纹实例都是真实且唯一的':
    '2. HuaYoung is the first to adopt the innovative approach of "collecting browser information to form a fingerprint template, and then fine-tuning and adding noise to split it into several fingerprint instances", ensuring that each fingerprint instance is true and only',
  '3、有了指纹实例后，您便可以创建分身并将指纹实例绑定到分身上，每个分身则相当于一个完全隔离的浏览器分身环境，而指纹实例则赋予此浏览器分身环境唯一的特征信息':
    '3. Once you have a fingerprint instance, you can create an browser and bind the fp instance to it. Each browser is equivalent to a completely isolated browser environment, and the fingerprint instance gives this browser a unique Feature information',
  '4、花瓣是花漾提供的一种内部结算单位（或称代币），1元=100花瓣':
    '4. Tokens are an internal settlement token provided by HuaYoung, 1 ¥ = 100 tokens',
  立即采集指纹模板: 'Collect FT Template ',
  '您有一张新的代金券已经到账，使用此代金券后只需再支付2元，即可购买一个海外IP地址（使用期：一周）':
    'You have a new voucher that has arrived. After using this voucher, you only need to pay another 2 ¥ to purchase an overseas IP ',
  立即查看: 'View Now',
  面值: 'Value',
  元: '¥',
  最小订单价格: 'Minimum order price',
  有效天数: 'valid date',
  天: 'days',
  适用产品: 'Applicable Products',
  购买IP: 'Buy IP',
  '恭喜您成功创建了指纹实例，已获得':
    'Congratulations on creating a fingerprint instance. You have obtained',
  '花瓣激励，需要提醒您的是：': 'Token Inspiration, what I need to remind you is:',
  '1、有了指纹实例后，您便可以创建分身并将指纹实例绑定在分身上':
    '1. Once you have a fingerprint instance, you can create an avatar and bind the fingerprint instance to the avatar.',
  '2、每个分身相当于一个完全隔离的浏览器分身环境，而指纹实例则赋予此浏览器分身环境唯一的特征信息':
    '2. Each avatar is equivalent to a completely isolated browser environment, and the fingerprint instance gives unique characteristic information to this browser avatar environment.',
  '3、我们强烈推荐您一个指纹实例只能绑定在一个分身上，尽量避免重复使用指纹实例':
    '3. We strongly recommend that you bind a fingerprint instance to only one avatar and try to avoid reusing fingerprint instances.',
  立即创建分身: 'Create an avatar now',
  '恭喜您成功创建了分身，已获得':
    'Congratulations on successfully creating a avatar. You have obtained',
  '1、分身创建成功后，您需要为每个分身绑定一个指纹实例和一个IP地址':
    '1. After the avatar is successfully created, you need to bind a fingerprint instance and an IP address to each avatar.',
  '2、您可通过“访问分身”以打开花漾灵动——这是一个和其它分身完全隔离的浏览器分身环境':
    '2. You can open HuaYoung through "Access Avatar" - this is a browser environment that is completely isolated from other browsers.',
  '恭喜您成功访问了分身，已获得':
    'Congratulations on successfully accessing the avatar. You have obtained',
  '1、您可以设置分身的访问策略，以约束团队其它成员访问分身时的规则与限制':
    '1. You can set the access policy of the avatar to restrict the rules and restrictions for other team members to access the avatar.',
  '2、您也可以通过分身联营或转让，与其它团队共同合作运营您的分身资源':
    '2. You can also cooperate with other teams to operate your avatar resources through alliance or transfer.',
  '现在购买花瓣，只要订单金额大于1元，即可获得1000花瓣的激励：':
    'If you purchase tokens now, as long as the order amount is greater than 1 ¥, you can get an incentive of 1,000 tokens:',
  '1、花瓣是花漾提供的一种内部结算单位（或称代币），1元=100花瓣':
    '1. Tokens are an internal settlement unit (or token) provided by HuaYoung, 1 ¥ = 100 tokens',
  '2、针对SaaS应用的常见报价模式是基于套餐的报价，如专业版可以管理多少分身、企业版可以管理多少分身等等，这种方式最大的缺点是让用户为没有使用的配额提前买单；为最大程度让利于用户，花漾提供基于花瓣这种类似于点数的报价模式，让用户先用再买，用多少买多少':
    '2. The common quotation model for SaaS applications is based on package quotations, such as how many avatars can be managed by the professional version, how many avatars can be managed by the enterprise version, etc. The biggest disadvantage of this method is that it requires users to pay in advance for unused quotas; To maximize the benefit to users, HuaYoung provides a point-based quotation model based on tokens, allowing users to use it first and then buy, and buy as much as they use.',
  '3、指纹实例配额、团队成员数量、存储空间大小、RPA执行次数等等，都会影响到花瓣的消耗，请确保团队内拥有足够数量的花瓣余额':
    '3. Fingerprint instance quota, number of team members, storage space size, number of RPA executions, etc. will all affect the consumption of tokens. Pls ensure that the team has a sufficient number of token balances.',
  '恭喜您成功购买了花瓣，已获得':
    'Congratulations on your successful purchase of tokens, you have received',
  '1、指纹实例配额、团队成员数量、存储空间大小、RPA执行次数等等，都会影响到花瓣的消耗':
    '1. Fingerprint instance quota, number of team members, storage space size, number of RPA executions, etc. will all affect the consumption of tokens.',
  '2、每天零点以后，系统会根据团队前一天的配额使用情况进行花瓣的扣除，请确保团队内拥有足够数量的花瓣余额，否则将影响部分功能特性的正常使用':
    "2. After midnight every day, the system will deduct tokens based on the team's quota usage of the previous day. Pls ensure that the team has a sufficient number of tokens, otherwise the normal use of some features will be affected.",
  '您尚未绑定手机，存在安全风险，现在绑定手机，即可获得1000花瓣的激励':
    'You have not bound your mobile phone yet, which is a security risk. Bind your mobile phone now to get an incentive of 1,000 tokens.',
  立即绑定: 'Bind Now',
  '恭喜您成功绑定了手机，已获得':
    'Congratulations on successfully binding your mobile phone. You have obtained',
  花瓣激励: 'tokens motivation',
  '您可邀请您的小伙伴们加入团队，即可获得30元代金券和3000花瓣的激励':
    'You can invite your friends to join the team and get an incentive of 30 ¥ voucher and 3000 tokens.',
  立即邀请: 'Invite now',
  元代金券: '¥ voucher',
  花瓣: 'Token',
  和: 'and',
  '恭喜您成功邀请小伙伴加入了团队，已获得':
    'Congratulations on successfully inviting your friends to join the team. You have obtained',
  '激励，需要提醒您的是：': 'Incentive, I need to remind you:',
  '1、您可以创建组织单元，并将不同的团队成员置于不同的组织单元':
    '1. You can create departments and place different team members in different departments',
  '2、团队成员可承担不同的角色': '2. Team members can assume different roles',
  '3、可通过授权将分身授权给团队成员或者组织单元':
    '3. Avatars can be authorized to team members or departments through authorization',
  '您可以关注我们的微信公众号以获得1000花瓣激励，关注微信公众号后，您可以：':
    'You can follow our WeChat official account to get 1,000 tokens as an incentive. After following our WeChat official account, you can:',
  '1、可通过微信公众号获得所有的消息推送':
    '1. All news push can be obtained through WeChat official account',
  '2、可通过微信扫码登录花漾': '2. You can log in to HuaYoung by scanning the QR code on WeChat',
  '恭喜您成功绑定了微信，已获得':
    'Congratulations on successfully binding WeChat. You have obtained',
  '现在对账户余额充值，只要订单金额大于1元，即可获得10元现金的激励：':
    'Now recharge your account balance, and as long as the order amount is greater than 1 ¥, you can get a 10 ¥ cash incentive:',
  '1、账户余额可以用来购买IP地址、续费IP地址、购买花瓣等':
    '1. Account balance can be used to purchase IP addresses, renew IP addresses, purchase flower tokens, etc.',
  '2、如果您购买的平台IP设置为自动续费，请确保拥有足够的账户余额':
    '2. If the platform IP you purchased is set to automatically renew, Pls make sure you have sufficient account balance.',
  '恭喜您成功完成了充值，已获得':
    'Congratulations on successfully completing the recharge and receiving',
  '元现金激励，需要提醒您的是：': '¥ cash incentive, you need to be reminded that:',
  活动内容: 'Activities',
  状态: 'State',
  已完成: 'Completed',
  未完成: 'Undone',
  '新手有礼活动第一阶段，完成本次全部任务后，会开启后续阶段，总共可获得180元激励礼包':
    'The first phase of the Beginner’s Gift Activity. After completing all tasks this time, the subsequent phase will begin, and you will receive a total of 180 ¥ incentive gift pack.',
  活动已结束: 'The event is over',
  '活动已结束，无法继续参与，您可以关注我们的微信公众号，以持续参与我们的活动':
    'The event has ended and you cannot continue to participate. You can follow our WeChat official account to continue to participate in our activities.',
  '申请原因：': 'Reason for application:',
  '审核结果：同意': 'Review result: Agree',
  '网址白名单已生效，请重新打开花漾灵动即可访问申请的网站地址':
    'The URL whitelist has taken effect. Pls reopen HuaYoung to access the applied website address.',
  '请注意：花漾提供的所有服务均遵循国家相关法律法规的规定，请确保不要用于任何违背国家相关法律法规的用途':
    'Pls note: All services provided by HuaYoung comply with relevant national laws and regulations. Pls make sure not to use them for any purpose that violates relevant national laws and regulations.',
  '审核结果：拒绝': 'Review result: Rejected',
  '拒绝原因：': 'Denial Reason:',
  查看详情: 'View Details',
  '如需了解进一步信息，请您': 'For further information, Pls',
  联络在线客服: 'Contact Online Support',
  IP生产详情: 'IP Production Details',
  生产结果: 'Production Results',
  创建成功: 'Created Succ',
  尚未开始: 'Not Started',
  正在创建: 'Creating',
  创建失败: 'Creation Failed',
  失败原因: 'Reason',
  查看IP详情: 'View Details',
  返回列表: 'Back to List',
  下一条未读: 'Next Unread',
  订单编号: 'Order No.',
  订单类型: 'Order Type',
  商品明细: 'Product details',
  应付金额: 'Ppayable',
  实付金额: 'Actually',
  支付状态: 'Status',
  待接收: 'To be received',
  已接收: 'Received',
  已拒绝: 'Rejected',
  详情: 'Details',
  接收: 'Take over',
  拒绝: 'Reject',
  平台: 'Plat',
  站点: 'Site',
  转让者: 'Transferor',
  发起者: 'Initiator',
  手机: 'Mobile',
  分身数量: 'Browsers',
  '1个': 1,
  接收者: 'Receiver',
  参与者: 'Participants',
  接收团队: 'Receiving',
  消息内容: 'Content',
  消息类型: 'Type',
  发送时间: 'Send time',
  开始时间: 'Start Time',
  结束时间: 'End Time',
  查看: 'Check',
  全部标为已读: 'Mark All as Read',
  消息中心: 'Messages',
  任务中心: 'Jobs',
  源文件: 'Source File',
  文件大小: 'File Size',
  目标地址: 'Target Address',
  任务进度: 'Task Progress',
  任务状态: 'Status',
  名称: 'Name',
  导入结果: 'Import Results',
  描述: 'Describe',
  进行中: 'In progress',
  超时: 'Time out',
  等待: 'Wait',
  任务名称: 'Name',
  任务类型: 'Task Type',
  '确定要中止该任务嘛？': 'Are you sure to abort this task?',
  任务已中止: 'Task aborted',
  任务已删除: 'Task deleted',
  清空已完成的任务: 'Clear completed tasks',
  通知: 'Totify',
  你已查看所有通知: 'You have viewed all messages',
  您已读完所有消息: 'You have read all messages',
  待办: 'Upcoming',
  你已完成所有待办: 'You have completed all jobs',
  购买: 'Buy',
  续费: 'Renewal',
  供应商: 'Supplier',
  时长: 'Duration',
  到期时间: 'Exp. Date',
  单价: 'Unit Price',
  '元/月': '¥/Month',
  小计: 'Subtotal',
  批量: 'Batch',
  等待付款: 'Waiting for payment',
  立即支付: 'Pay Now',
  订单详情: 'Order Details',
  订单金额: 'Order Amount',
  清单: 'Checklist',
  购买成功: 'Purchase successful',
  '正在为您创建IP，由于您购买的IP需要我们和供货商进行对接，故IP生产需要一定的时间，工作日2小时之内生产完毕，其它时间8小时之内生产完毕，如有进一步需求，请联络':
    'Since the IP you purchased requires to connect with the supplier. The production will be completed within 2 hours on working days and within 8 hours at other times. If there is further demand, ,Pls contact',
  '正在为您创建购买，平均每个IP的生产过程需要5分钟左右的时间，创建成功后您将获得消息通知，请稍候...':
    'The production process of each IP takes about 5 minutes on average. You will receive a message notification after the creation is successful. Pls wait...',
  个IP: 'IP',
  订单状态: 'Order Status',
  '3天': '3 days',
  '60分钟': '60 minutes',
  订单已生成: 'Order has been generated',
  '您的订单已生成，请': 'Your order has been generated, Pls',
  '在内完成支付，逾期订单将取消':
    'complete payment within the deadline, overdue orders will be cancelled.',
  周: ' weekly ',
  月: ' monthly ',
  没有合适的IP: 'No suitable IP',
  商品名称: 'Product',
  网络类型: 'Network',
  流量配额: 'Traffic Quota',
  超额流量单价: 'Traffic Unit Price',
  '花瓣/GB': ' tokens/GB',
  限制条件: 'Limitation factor',
  个起售: 'Minimum sale',
  无: 'None',
  类别: 'Category',
  '元/': '¥/',
  数量: 'Quantity',
  '我们为您购买的是按量付费型主机，请确保您的公有云账户有充足的余额':
    'We purchased a pay-as-you-go host for you. Pls ensure that your account has sufficient balance.',
  购买周期: 'Cycle',
  按周购买: 'Buy Weekly',
  按月购买: 'Buy Monthly',
  IP数量合计: 'Total number of IPs',
  费用合计: 'Total cost',
  惠: 'Discount',
  实付: 'Actually Paid',
  金额: 'Amount',
  购买时长: 'Duration',
  不限: 'No limit',
  '正在为您检查拟购商品的库存情况，请稍候...':
    'We are checking the inventory of the items you want to purchase, pls wait...',
  '很抱歉，您拟购的商品出现了一些问题，异常信息为：':
    'There are some problems with the product. The exception message is:',
  请您和: 'Pls ',
  联系: 'connect',
  选择IP供应商与数量: 'Select IP Provider & Quantity',
  请选择您要购买的商品: 'Pls select the product you want',
  订单确认: 'Order Confirmation',
  已完成转账: 'Transfer Completed',
  稍后支付: 'Pay Later',
  上一步: 'Previous',
  进入订单: 'Order Detail',
  按主机规格购买公有云IP: 'Purchase IP by specifications',
  根据不同的主机规格选择购买适合自己的公有云IP地址:
    'Choose an IP that suits you based on different specifications.',
  您尚未设置团队的公有云账户: "You haven't set up public cloud account yet",
  '1、创建自有云平台IP是指系统使用您自己的云账户来创建主机与IP地址':
    '1. Creating your IP means that the system uses your own cloud account.',
  '2、使用您自己的云账户的好处是：主机与IP地址属于您名下，您可以长期持有，从而保证IP地址的纯净性':
    '2. The advantage of using your own cloud account is that the IP belong to you, and you can hold them for a long time.',
  '3、您需要在团队资源中设置好云账户才能使用此功能':
    '3. You need to set up a cloud account to use this function',
  设置团队云账户: 'Set up a cloud account',
  随机: 'random',
  中国: 'China',
  '国家：': 'nation:',
  '#NAME?': '#NAME?',
  '（不限城市）': '(Not limited)',
  '#NAME?__1': '#NAME?',
  指定城市: 'City',
  没有可用的IP方案: 'No IP plan available',
  云账户: 'Account',
  IP区域: 'IP area',
  IP城市: 'IP city',
  'IP+独享式主机': 'IP+dedicated host',
  '（您将获得IP以及专用的独享主机': '(You will get an IP and a dedicated exclusive host',
  'IP+共享式主机': 'IP+shared hosting',
  '（您只获得IP，主机非独享': '(You only get the IP, the host is not exclusive',
  北美: 'North America',
  亚太: 'Asia Pacific',
  欧洲: 'Europe',
  南美: 'South America',
  非洲: 'Africa',
  没有可用的IP区域: 'No IP zone available',
  选择IP的地理位置: 'Select IP location',
  下一步: 'Next',
  '结合无痕分身及动态IP的会话保持特性，通常用来做对网站的批量、隐匿式访问':
    'Combined with the ip-hold of dynamic IPs, it is usually used for batch and invisible access to websites.',
  '结合无痕分身及动态IP的会话保持特性，通常用来做大批量分身的注册':
    'Combined with the ip-hold of dynamic IP, it is usually used to register.',
  请选择动态IP类型: 'Pls select type',
  '购买动态、': '',
  的: '',
  动态IP由花漾与全球伙伴合作推出:
    'Dynamic IP is launched by HuaYoung in cooperation with global partners',
  '适用场景：动态IP可切换出口IP值，结合花漾的无痕分身以及动态IP的会话保持特性，通常用来做批量分身的注册，或者对网站进行批量、隐匿式访问':
    'Dynamic IP can switch the outbound IP , combined with stateless browser and the ip-hold of dynamic IP. It is usually used for batch registration , or batch and invisible access to websites.',
  '使用限制：根据动态IP的网络类型会有不同的限制':
    'Restrictions: There will be different restrictions based on the type of dynamic IP',
  只适用于CPU: 'Only available for CPU ',
  核的主机设备: ' cores device',
  当前电脑主机的CPU核数为: 'CPU cores of this computer: ',
  了解如何查看CPU核数: 'How to check CPU cores',
  流程任务包: 'RPA',
  '卡（': 'Card(',
  '核）': 'cores)',
  有效期: 'Period',
  'RPA流程任务分为“按量计费”与“包周包月”两种计费模式，如果您有大量的RPA流程任务，通过购买流程任务卡，能够有效降低RPA使用成本':
    'RPA has two billing models: "pay-by-use" and "weekly-card or monthly-card". If you have a large number of tasks, you can effectively reduce RPA usage costs by task card',
  包周卡: 'Weekly Card',
  包月卡: 'Monthly Card',
  请先同意: 'Pls agree',
  用户协议: 'User Agreement',
  购买流程任务卡: 'Buy Task Card',
  可为RPA流程任务购买流程任务卡以降低RPA运行成本: 'Task card can reduce RPA runtime costs',
  选择商品: 'Select Product',
  '确定要选择银行对公转账方式支付订单款项吗？': 'Sure to choose bank transfer to pay?',
  '如果选择银行对公转账方式支付，请在提交订单后48小时内，向我们提供的银行账号中转入':
    'If you choose to pay by bank transfer, Pls transfer it to the bank account within 48 hours.',
  '元，银行账号信息将在您点击“确认”按钮后展示':
    '¥, bank account information will be displayed after you click the "Confirm" button',
  请确认您已完成银行转账: 'Pls confirm that you have completed the bank transfer',
  '当您确认已完成银行转账后，付款流程将流转到我们的后台工作人员，一旦他们确认款项已到账，您的订单将自动完成':
    'When you confirm that the bank transfer has been completed, your order will be automatically completed.',
  确认已付款: 'Confirm payment',
  户名: 'Name',
  账号: 'Account',
  开户行: 'Bank',
  转账备注: 'Notes',
  '订单编号：': 'Order No.:',
  我们的工作人员正在确认银行转账信息: 'Our staff is confirming the bank transfer information',
  请在付款后将: 'Pls send the',
  付款凭证截图: ' screenshot of payment voucher ',
  '）提供给': ')provide to',
  '，加速审核流程': ', speed up the process',
  等待您向我们的银行账号付款: 'Waiting for your payment ',
  支付: 'pay',
  代金券抵扣: 'Voucher deduction',
  已经选择代金券抵扣: 'Voucher has been selected',
  重新选择: 'Reselect',
  不使用代金券: 'No vouchers used',
  未选择代金券: 'No voucher selected',
  账户余额扣减: 'Balance Payment',
  '从账户余额（可用￥{{balance}}）中扣减': 'Deduct from account balance (available ¥{{balance}})',
  现金支付方式: 'Payment Method',
  我已阅读和同意: 'Read & Agreed',
  '用户协议》': ' User Agreement》',
  '关于《花漾灵动用户协议》的提示': 'Tips about "HuaYoung User Agreement',
  '您需要充分理解，云上悦动提供的产品或服务受国家法律法规的严格监管，主机资源有严格的白名单限制，不得访问国家明令禁止的敏感网站，云上悦动不提供任何VPN/翻墙服务；您在使用云上悦动提供的产品或服务时，严禁用于任何涉黄、涉赌或者其它国家法律法规所禁止的行为，一旦发觉有上述行为，云上悦动有权立即停止相关服务，包括且不限于收回IP资源、封禁用户账户、冻结团队账户余额等':
    'You need to fully understand that the products or services provided by HuaYoung are strictly supervised by national laws and regulations. When you use the products or services provided by HuaYoung, you are strictly prohibited from engaging in any pornography, gambling or other activities prohibited by national laws and regulations. ',
  '正在校验优惠码...': 'Verifying the code...',
  帮您节省: 'Help you save',
  '无效的优惠码，请检查后重新输入': 'Invalid discount code, Pls check and re-enter',
  '如果您有优惠码可以填入，将以更优惠价格购买花瓣':
    'If you have a discount code , you will get a better price',
  优惠码: 'Discount Code',
  选填: 'Optional',
  支付成功: 'Payment Successful',
  您购买的花瓣已支付成功: 'The tokens have been paid successfully',
  花瓣数量: 'Amount',
  '（其中': '(in',
  '为赠送花瓣）': '(for gift tokens)',
  '每100花瓣=': 'per 100 tokens =',
  购买数量: 'Quantity',
  '或：': 'or:',
  花瓣标准单价: 'Unit Price',
  赠送花瓣: 'Free Giveaway',
  个: ' ',
  优惠后花瓣单价: 'Unit Price(After)',
  购买花瓣: 'Buy Tokens',
  折扣: 'Discount',
  原过期时间: 'Original expiration time',
  续费后过期时间: 'Expiration time after renewal',
  个月: 'Months',
  个周: 'Weeks',
  年: 'Year',
  按: 'according to',
  购买的IP: 'Purchased IP',
  续费时长: 'Renewal time',
  续费主体: 'Renewal subject',
  云厂商平台购买的IP过期后无法续费: 'The IP purchased cannot be renewed after it expires.',
  '当前IP已经在云厂商平台中释放，无法续费':
    'The current IP has been released and cannot be renewed.',
  请确保此IP在云厂商中未被释放: 'Pls ensure that this IP has not been released',
  '如果此IP在云厂商平台中已被释放，续费操作无法帮您找回IP':
    'If this IP has been released, the renewal operation cannot retrieve the IP.',
  变更续费周期: 'Change renewal cycle',
  设置自动续费: 'Set up automatic renewal',
  开启自动续费: 'Turn on automatic renewal',
  '已为您过滤选中的自有IP（只有平台IP才需要续费）': 'Only platform IPs need to be renewed)',
  合计金额: 'Total Amount',
  IP服务续费: 'IP Renewal',
  等: 'wait',
  您所选择的IP地址续费已成功: 'The IP you selected has been renewed successfully',
  '如果您的订单中包含购买IP地址的操作，大概需要5分钟左右的时间，创建成功后您将获得消息通知，请稍候...':
    'If your order includes an IP address, it will take about 5 minutes. Pls wait...',
  '付款成功，正在充值，请稍候...': 'Payment successful, pls wait...',
  取消支付: 'Cancel',
  账户充值: 'Recharge',
  '恭喜，充值成功': 'Congratulations, recharge successful',
  '本次充值金额：': 'The amount of this recharge:',
  '当前账户余额：': 'Current account balance:',
  商品: 'commodity',
  流程任务卡续费: 'RPA Card Renewal',
  您所选择的流程任务卡已成功续费: 'The RPA card has been successfully renewed',
  扫码失败: 'Scan failed',
  二维码生成失败: 'QR code generation failed',
  二维码已失效: 'The QR code has expired',
  重试: 'Retry',
  扫描成功: 'Scan successful',
  重新获取: 'Reacquire',
  你已取消此次登录: 'You have canceled this login',
  个人资料: 'Profile',
  偏好设置: 'Preferences',
  依据资源关键字进行全局检索: 'Search by resource keywords',
  排序调整: 'Sort adjustment',
  上移: 'Move up',
  下移: 'Move down',
  点击降序: 'Order Descending',
  点击升序: 'Order Ascending',
  可拖动改变位置: 'Can be dragged to change position',
  '（不可移除，不可移动）': '(Undeletable & Unmovable)',
  '（不可移动）': '(Unmovable)',
  '（不可移除）': '(Undeletable)',
  字段: 'Field',
  当前显示: 'Currently ',
  待显示: 'To Be Displayed ',
  此项为必填项: 'This is required',
  提交: 'Submit',
  关闭右侧全部页签: 'Close all right tabs',
  关闭右侧页签: 'Close the right tab',
  关闭其它页签: 'Close other tabs',
  账号密码: 'Password',
  密码修改成功: 'Password reset complete',
  登录账号: 'Account',
  验证码: 'Code',
  新密码: 'New Pwd',
  '长度为8-40个字符，需至少包含以下四类字符中的三类：大写字母、小写字母、数字、特殊符号':
    '8-40 characters and must contain 3 of the uppe-letters, lower-letters, numbers, and special symbols such as !@#$%^&*()',
  请输入密码: 'Password',
  确认密码: 'Confirm',
  请再次输入密码: 'Enter password again',
  请先设置手机号或邮箱: 'Pls set up your phone or email first',
  自动升级时段设置: 'Upgrade Time Setting',
  自动升级时段: 'Upgrade Period',
  时间段跨度不能小于30分钟: 'The time span cannot be less than 30 minutes',
  '只有在上述指定时段内且客户端处于空闲状态（没有打开花漾浏览器）时才会自动静默安装升级':
    'The upgrade will be automatically and silently installed only during the above specified period and when the client is idle (no HuaYoung Browser is opened)',
  需要关闭所有浏览器实例才能够修订此位置:
    'All browser instances need to be closed to revise this location',
  '浏览器配置文件存储了浏览器分身的缓存数据，为避免出现数据紊乱，需要关闭所有花漾浏览器才允许修订此配置文件所在位置':
    'The browser configuration file stores the cached data of the browser avatars. To avoid data confusion, you need to close all HuaYoung browsers before you are allowed to modify the location of this configuration file.',
  帮我关闭所有花漾浏览器: 'Help me close all HuaYoung browsers',
  '花漾配置文件的存储位置，如果空间不足，可以考虑移动其存储位置':
    'The default storage location of HuaYoung configuration files is in the system "Documents" folder, and you can revised it.',
  浏览器配置文件: 'Browser Configuration',
  '存储浏览器分身环境的缓存信息，随着分身的增多，它往往会占据较大的硬盘空间，建议将其从系统盘中移出':
    'The browser configuration file stores the cache information of the browser environment. As the num of browsers increases, it often takes up a larger amount of hard disk space. It is recommended to move it from the system disk.',
  更改: 'Change',
  打开: 'Open',
  录像文件下载位置: 'Video Download',
  '查看分身访问日志并希望下载录像文件时，录像文件的默认存放位置':
    'When you view the access log and want to download the video file, the default storage location of the video file',
  RPA运行预览存储位置: 'RPA Preview Storage',
  '当您在流程定义编辑器中运行预览RPA流程时，RPA流程生成的文件的默认存储位置':
    'The default storage location for files generated by the RPA process when you run a preview in the proc script editor',
  花漾客户端缓存: 'HuaYoung App Cache',
  '花漾客户端缓存存储的是花漾门户的缓存信息，当发觉花漾客户端无法正常工作时，可以考虑进行清理':
    'The HuaYoung app cache stores the cache information of the HuaYoung portal. When you find that the HuaYoung app is not working properly, you can consider cleaning it.',
  清理: 'clean up',
  推迟自动升级: 'Defer automatic upgrade',
  '花漾客户端有最新版本且已经为您下载完毕，强烈建议您立即升级，如果现在不合适升级，您也可以指定下次升级时间':
    'HuaYoung app has the latest version and has been downloaded for you. It is strongly recommended that you upgrade immediately. If it is not suitable to upgrade now, you can also specify the next upgrade time.',
  当前版本: 'Version',
  最新版本: 'Latest Version',
  下次升级时间: 'Next upgrade time',
  设备名称: 'Device Name',
  设备标识: 'Device ID',
  设备配置: 'Device Specs',
  最近登录时间: 'Last Login',
  本机: 'Local',
  '从此设备中注销（暨解除此设备的登录态）': 'Log out of this device (and un-login this device)',
  '确认要退出登录此设备吗？': 'Sure to log out of this device?',
  '一旦退出登录后，下次在该设备上重新登录时需要验证您的身份':
    'Once you log out, you will need to verify your identity the next time you log back in on that device',
  已登录的客户端列表: 'List Of Devices',
  静音: 'Mute',
  播放声音: 'Play Sound',
  新消息通知: 'New Message',
  修改昵称: 'Change Nickname',
  个人昵称: 'Nickname',
  请输入个人昵称: 'Pls enter your nickname',
  个性签名: 'Self-Intro',
  请不要超过128个字符: 'Pls do not exceed 128 characters',
  最长128个字符: 'Maximum 128 characters',
  '静默安装（升级时段：': 'Silent Instal(Upgrade Period:',
  自动下载: 'Auto Download',
  仅提醒: 'Reminder',
  开机启动: 'Auto Start on Startup',
  开启: 'Turn On',
  浏览器界面语言: 'Browser Language',
  '花漾指纹浏览器界面使用的语言版本，默认为“跟随客户端设置”，但用户可自行设置，请注意，团队设置中的优先级将高于个人偏好设置':
    'The language version used by HuaYoung Fingerprint Browser interface defaults to "Follow client settings", but users can set it themselves. Please note that the priority in team settings will be higher than personal preference settings.',
  浏览器Debug模式: 'Browser Debug',
  '当开启Debug模式时，花漾灵动可通过按F12打开开发者工具DevTools；请注意，团队设置中的优先级将高于个人偏好设置':
    'When Debug mode is turned on, HuaYoung can open the developer tools DevTools by pressing F12; Pls note that the priority in team settings will be higher than personal preference settings.',
  浏览器沙盒模式: 'Sandbox Mode',
  '在某些情况下，由于操作系统配置、不兼容的硬件驱动程序或其他因素导致启用沙盒模式后无法正常运行浏览器。此时，可以关闭沙盒模式，以解决这些问题':
    'In some cases, the browser may not function properly when sandbox mode is enabled due to operating system configuration, incompatible hardware drivers, or other factors. At this point, sandbox mode can be turned off to resolve these issues',
  GPU加速: 'GPU Acceleration',
  '如果出现浏览器闪屏的现象，可通过开启或关闭GPU加速予以缓解':
    'If browser flickering occurs, it can be alleviated by turning on or off GPU acceleration.',
  禁止电脑休眠: 'Prevent Sleep',
  '当开启时，则电脑将不会自动进入休眠状态，该场景适用于需要定时运行RPA流程计划等场景':
    'When turned on, the computer will not automatically enter sleep state. This scenario is suitable for scenarios where RPA process plans need to be run regularly.',
  关闭主窗口时: 'Closing Main-Win',
  '当关闭花漾主窗口时，可以是强制退出（所有打开的花漾浏览器会自动关闭），也可以隐藏到任务栏（通过任务栏可再次激活花漾客户端）':
    'When closing the main window of HuaYoung, you can force quit (all open HuaYoung browsers will be automatically closed), or you can hide it to the taskbar (the HuaYoung app can be activated again through the taskbar)',
  退出: 'Quit',
  隐藏到任务栏: 'Hide to Taskbar',
  发现新版本时: 'New Version',
  '仅提醒：有新版本时，在客户端左下角发出提醒，由用户自行决定是否下载':
    'Reminder only: When a new version is available, a reminder will be issued in the lower left corner of the client, and it is up to the user to decide whether to download it.',
  '自动下载：自动下载最新版本，在客户端左下角发出提醒，由用户自行决定是否安装':
    'Automatic download: Automatically download the latest version, and issue a reminder in the lower left corner of the client. It is up to the user to decide whether to install it.',
  '静默安装：不仅自动下载最新版本，还会在指定时间内且客户端空闲时（指没有打开任何花漾浏览器时）自动完成静默安装，适用于客户端长期值守运行RPA流程等场景':
    'Silent installation: not only automatically downloads the latest version, but also automatically completes the silent installation within the specified time and when the client is idle (meaning when no HuaYoung browser is opened). It is suitable for scenarios where the client is on duty for a long time to run the RPA process.',
  '自动升级时段：': 'Automatic upgrade period: ',
  静默安装: 'Silent Install',
  '确认退出？': 'Confirm Logout?',
  '如果当前有打开花漾浏览器，这些浏览器将会被自动关闭，请确认是否继续':
    'If there are HuaYoung browsers currently open, these browsers will be automatically closed. Pls confirm whether to continue.',
  确认: 'Confirm',
  绑定手机: 'Bind Mobile Phone',
  修改手机号: 'Modify Mobile Phone Number',
  '该手机号已经绑定其它账号，不可重复绑定':
    'This mobile has been bound to other accounts and cannot be bound again.',
  已绑号码: 'Old Phone',
  新手机号: 'New Phone',
  手机号: 'Mobile',
  绑定邮箱: 'Bind Email',
  修改邮箱: 'Modify Email',
  '该邮箱已经绑定其它账号，不可重复绑定':
    'This email address has been bound to another account and cannot be bound again.',
  已绑邮箱: 'Email',
  新邮箱: 'New Email',
  邮箱: 'Email',
  重复的邮箱: 'Duplicate email',
  已绑定: 'Bound',
  '确定要解绑微信账号吗？': 'Sure to unlink your WeChat account?',
  一旦解绑则无法通过微信接收花漾的站内消息:
    'Once unbound, you will not be able to receive HuaYoung’s site messages through WeChat.',
  微信解绑成功: 'WeChat unbinding successfully',
  未绑定手机号无法解绑微信: 'Unbound mobile cannot be unbound from WeChat',
  '您需要先为该账号绑定一个手机号，然后再解绑微信':
    'You need to bind a mobile to this account first, and then unbind WeChat',
  解绑: 'Unbind',
  未绑定: 'None',
  请使用微信扫一扫完成绑定: 'Pls use WeChat to scan it.',
  微信绑定成功: 'WeChat binding successful',
  该微信号已经绑定其它账号: 'This WeChat account has been bound to other accounts',
  '一个微信账号只能绑定一个花漾账号，请更换其它的微信号完成绑定':
    'One WeChat account can only be bound to one HuaYoung account. Pls change other WeChat IDs to complete the binding.',
  '确定要解绑手机吗？': 'Sure to unbind your phone?',
  解绑后需要重新绑定: 'Need to rebind after unbinding',
  '确定要解绑邮箱吗？': 'Sure to unbind your email address?',
  设置头像: 'Profile Picture',
  用户ID: 'User ID',
  '此人很懒，什么都没留下': 'This person is lazy and leaves nothing',
  登录次数: 'Logins',
  注册时间: 'Registration Time',
  绑定信息: 'Information',
  微信: 'WeChat',
  电子邮箱: 'Email',
  未设置: 'Not Set',
  正在为您进行文件的迁移: 'Files are being migrated for you',
  '配置文件的路径发生了变化，正在为您进行相关文件的迁移，这可能需要一点时间，请稍等...':
    'The path to the configuration file has changed, and the related files are being migrated for you. This may take a while, pls wait...',
  花漾客户端: 'App Settings',
  花漾浏览器: 'Browser Settings',
  配置文件: 'Configuration',
  消息订阅: 'Message',
  登录设备: 'Logged Devices',
  花漾灵动: 'HuaYoung',
  深圳市云上悦动科技有限公司: 'Shenzhen HuaYoung Technology Co., Ltd.',
  平台提供: 'Platform provides',
  '自有IP（VPS）': 'Own IP (VPS)',
  花漾IPGO代理: 'HuaYoung IPGO agent',
  用户自有代理: 'User-owned agent',
  有效: 'Efficient',
  失效: 'Invalid',
  准备: 'Prepare',
  澳大利亚: 'Australia',
  巴西: 'Brazil',
  加拿大: 'Canada',
  中国大陆: 'Chinese mainland',
  法国: 'France',
  德国: 'Germany',
  印度: 'India',
  意大利: 'Italy',
  日本: 'Japan',
  墨西哥: 'Mexico',
  荷兰: 'Netherlands',
  波兰: 'Poland',
  沙特阿拉伯: 'Saudi Arabia',
  新加坡: 'Singapore',
  西班牙: 'Spain',
  瑞典: 'Sweden',
  土耳其: 'Türkiye',
  阿联酋: 'United Arab Emirates',
  英国: 'U.K.',
  美国: 'USA',
  阿根廷: 'Argentina',
  爱尔兰: 'Ireland',
  奥地利: 'Austria',
  巴拉圭: 'Paraguay',
  巴拿马: 'Panama',
  白俄罗斯: 'belarus',
  比利时: 'Belgium',
  波多黎各: 'Puerto Rico',
  玻利维亚: 'Bolivia',
  多米尼加: 'dominican',
  俄罗斯: 'Russia',
  厄瓜多尔: 'Ecuador',
  菲律宾: 'the Philippines',
  哥伦比亚: 'Colombia',
  哥斯达黎加: 'Costa Rica',
  哈萨克斯坦: 'Kazakhstan',
  韩国: 'South Korea',
  洪都拉斯: 'Honduras',
  马来西亚: 'Malaysia',
  秘鲁: 'Peru',
  尼加拉瓜: 'Nicaragua',
  葡萄牙: 'Portugal',
  瑞士: 'Switzerland',
  萨尔瓦多: 'El Salvador',
  危地马拉: 'Guatemala',
  委内瑞拉: 'Venezuela',
  乌拉圭: 'Uruguay',
  以色列: 'Israel',
  智利: 'Chile',
  中国台湾: 'Taiwan, China',
  中国香港: 'China Hong Kong',
  印度尼西亚: 'Indonesia',
  泰国: 'Thailand',
  越南: 'Vietnam',
  埃及: 'Egypt',
  保密: 'Confidential',
  男: 'male',
  女: 'female',
  亚马逊: 'Amazon',
  社交媒体: 'Social Media',
  阿里巴巴: 'Alibaba',
  抖音: 'Dou Yin',
  哔哩哔哩: 'BiliBili',
  知乎: 'ZhiHu',
  未激活: 'Inactivated',
  正常: 'Normal',
  标记为删除: 'Mark for deletion',
  转让分身: 'Transfer',
  接收分身: 'Receive',
  联营分身: 'Affiliate',
  您不在此团队: 'You are not on this team',
  超级管理员: 'SuperAdmin',
  经理: 'Manager',
  员工: 'Staff',
  分身管理: 'Browser',
  IP管理: 'IP',
  费用管理: 'Orders',
  操作日志: 'Logs',
  团队管理: 'Team',
  指纹库管理: 'Fingerprint',
  电商平台: 'e-Business',
  支付平台: 'Payment',
  其他类别: 'Others',
  费用中心: 'Orders',
  运营推广: 'Promotion',
  IP生产: 'IP Production',
  IP生产成功: 'IP Production Succ',
  IP生产失败: 'IP Production Failed',
  IP导入: 'IP Import',
  IP移除: 'IP Removal',
  IP到期: 'IP Expires',
  IP过期: 'IP Expired',
  IP销毁成功: 'IP Destroyed Succ',
  IP即将销毁: 'IP to be Destroyed',
  IP续费成功: 'IP Renewal Succ',
  IP续费失败: 'IP Renewal Failed',
  转让分身审核: 'Transfer Review',
  分身克隆成功: 'Avatar Clone Succ',
  分身转让确认: 'Avatar Transfer Confirm',
  分身转让结果: 'Result of Transfer',
  分身联营确认: 'Avatar Joint Confirm',
  分身联营结果: 'Avatar Joint Results',
  成员加入: 'Join Team',
  成员加入审批: 'Join Approval',
  成员退出: 'Member Exits',
  网址加白成功: 'Whitelist Succ',
  网址加白失败: 'Whitelist Failed',
  银行转账到账通知: 'Bank Payment Message',
  待支付订单通知: 'Pending Order Message',
  花瓣购买通知: 'Token Purchase Message',
  通用消息: 'General Message',
  CRS数据同步: 'CRS Synchronization',
  购买流量: 'Buy Traffic',
  退款: 'Refund',
  IP续费: 'IP Renewal',
  补差价: 'Adjustment',
  购买TK套餐: 'TK Package',
  TK套餐续费: 'TK Package Renewal',
  共享IP: 'Shared IP',
  用户自有云账号IP: 'User’s Own Cloud IP',
  流量加速: 'Traffic Acceleration',
  团队成员: 'Team Member',
  指纹实例: 'FP',
  存储空间: 'Storage',
  代金券: 'Voucher',
  公有云: 'Public cloud',
  数据中心: 'Data Center',
  家庭住宅: 'Resident',
  移动网络: 'Mobile',
  阿里云: 'Ali Cloud',
  腾讯云: 'Tencent Cloud',
  Google云: 'Google Cloud',
  亚马逊中国: 'Amazon',
  微软Azure: 'Azure',
  百度云: 'Baidu Cloud',
  华为云: 'Huawei Cloud',
  京东云: 'JD Cloud',
  住宅IP: 'Res. IP',
  通用: 'Universal',
  IP购买: 'IP Purchase',
  流量购买: 'Traffic Purchase',
  兴业银行股份有限公司深圳分行: 'XingYe Bank Co., Ltd. Shenzhen Branch',
  流量: 'Flow',
  高性价比: 'Cost-Effective',
  大流量: 'High Traffic',
  高并发: 'High Concurrency',
  可远程: 'Remote Available',
  美元: 'Dollar',
  鞋服箱包: 'Fashion & Accessories',
  母婴玩具: 'Baby & Toys',
  护肤美妆: 'Skincare & Beauty',
  电脑平板: 'PCs & Tablets',
  手机数码: 'Mobile & Electronics',
  家电电器: 'Home Appliances',
  汽车配件: 'Car Accessories',
  工业用品: 'Industrial Products',
  艺术珠宝: 'Art & Jewelry',
  手表眼镜: 'Watches & Eyewear',
  户外运动: 'Outdoor Sports',
  花园聚会: 'Outdoor Party',
  生活家居: 'Home & Living',
  家具建材: 'Furniture Materials',
  宠物用品: 'Pet Supplies',
  食品生鲜: 'Food & Fresh',
  鲜花绿植: 'Flowers & Plants',
  医药保健: 'Health & Medicine',
  计生情趣: 'Romantic Items',
  图书文具: 'Books & Stationery',
  音乐影视: 'Music & Video',
  软件程序: 'Software Program',
  电商其他: 'E-commerce Others',
  导入IP: 'Import IP',
  导入分身: 'Import Avatars',
  批量导入分身: 'Import In Batches',
  上传文件: 'Upload Files',
  正在查询安全策略配额设置: 'Querying security policy quota settings',
  开启安全策略将产生费用: ' Turning on the security policy will incur fees ',
  ' （{{price}}花瓣每分身每日），但可随时关闭，关闭后将不再产生费用':
    '({{price}} tokens per avatar per day), but can be closed at any time.',
  当前分身开启安全策略免费: 'The current avatar enables security policy for free',
  '确认要开启本分身的安全策略吗？': "Sure to enable this browser's security policy?",
  '1.安全策略是指可以通过事前预防、事中监管、事后审计等维度，将该分身纳入安全沙箱托管':
    '1. Security policy means that the avatar can be included in the security sandbox hosting through dimensions such as prior prevention, supervision, and auditing.',
  '2.当分身所维护的账号信息高度敏感时，强烈建议您开启安全策略，这样可以确保日常运营的安全可控':
    '2. When the account information maintained by the avatar is highly sensitive, it is strongly recommended that you enable the security policy to ensure the security and controllability of daily operations.',
  取消开启安全策略: 'Cancel security policy',
  安全策略配额获取失败: 'Security policy quota acquisition failed',
  请稍后重试: 'Pls try again later',
  暂未开放: 'Not open yet',
  浏览器不支持webAudioApi相关接口: 'The browser does not support webAudioApi related interfaces',
  'chrome下获取浏览器录音功能，因为安全性问题，需要在localhost或127.0.0.1或https下才能获取权限':
    'Obtain the browser recording function under chrome. Due to security issues, you need to obtain permission under localhost or 127.0.0.1 or https.',
  '无法获取浏览器录音功能，请升级浏览器或使用chrome':
    'Unable to obtain browser recording function, Pls upgrade your browser or use chrome',
  请求麦克风失败: 'Microphone request failed',
  '无法连接到服务器，请检查您的网络是否通畅，是否开启了全局代理。':
    'Unable to connect to server, pls check your network.',
  系统维护: 'Maintenance',
  错误: 'Error',
  '无法连接服务器，请检查您的网络是否通畅。':
    'Unable to connect to the server, Pls check if your network is open.',
  网络错误: 'Network Error',
  打开的标签页过多: 'Too many tabs open',
  '您当前打开的标签页过多，这将严重影响系统响应，建议您关闭一些不必要的标签页再尝试打开新的标签页':
    'You currently have too many open tabs, which will seriously affect system response. It is recommended that you close some unnecessary tabs and then try to open new tabs.',
  '加载中...': 'loading...',
  宫格布局: 'Grid Layout',
  将主控窗口放到左上角第一个宫格位置: 'Place the main control window in the first grid position',
  对角线布局: 'Diagonal Layout',
  将主控窗口放到右下角最后一个窗口位置: 'Move the main control window to the last window position',
  '以主控窗口为标准，统一所有窗口的标签':
    'Using the main control window as the standard, unify the tabs of all windows',
  群控技术依赖于浏览器窗口采用相同的大小:
    'Group control technology depends on the browser windows being the same size',
  群控: 'Group control',
  '群控程序需要更新，是否立即下载？':
    'The group control program needs to be updated. Do you want to download it now?',
  '确定要开启群控吗？': 'Sure to enable group control?',
  '1.当您点击确定后，我们将为您下载群控程序，并存储到':
    '1. When you click OK, we will download the group control program for you and store it in',
  '“偏好设置”': 'Preferences',
  中的: ' ',
  '“浏览器配置文件”': 'Browser Profile',
  所在位置: 'Location',
  '2.群控程序使用了Windows的底层技术，这往往会被杀毒软件误报为病毒，如果出现误报，请您暂时关闭杀毒软件，或者在杀毒告警中点击信任':
    '2. The group control program uses the underlying technology of Windows, which will often be mistakenly reported as a virus by anti-virus software. If a false positive occurs, Pls temporarily turn off the anti-virus software, or click Trust in the anti-virus alarm.',
  正在下载群控程序: 'Downloading group control program',
  '已完成0%': '0% completed',
  仅支持Windows平台的群控技术: 'Group control technology only supports Windows platform',
  目前暂不支持MacOS与Linux平台: 'MacOS and Linux platforms are currently not supported.',
  '该流程定义由好友共享而来，不允许编辑':
    'This proc script was shared by friends and is not allowed to be edited.',
  '流程编辑-': 'Process editor-',
  流程保存成功: 'Process saved successfully',
  继续申请: 'Continue to apply',
  您试图访问的页面被管理员屏蔽:
    'The page you are trying to access has been blocked by the administrator',
  '该分身的安全策略中，将此页面纳入了禁止访问页面，请联络您的上级或者系统管理员以获取进一步信息':
    "This avatar's security policy includes this page as a prohibited page. Pls contact your superior or system administrator for further information.",
  您的网址加白申请已提交: 'Your URL whitening application has been submitted',
  '1、一般而言，当天之内您的申请即可完成审批':
    '1. Generally, your application can be approved within the same day.',
  '2、审批完毕后，您将在花漾客户端中收到站内消息':
    '2. After the approval is completed, you will receive site messages in the HuaYoung app.',
  '3、收到站内消息后，点击下方“重新加载页面”，即可继续访问':
    '3. After receiving the message on the site, click "Reload Page" below to continue visiting.',
  '4、如有需要，您可以通过联络在线客服加速审批过程':
    '4. If necessary, you can speed up the approval process by contacting online customer service',
  重新加载页面: 'Reload page',
  请补充您的手机号码: 'Pls add your mobile number',
  立即完善: 'Perfect now',
  点击重新获取: 'Click to reacquire',
  '已完善？': 'Already perfected?',
  您试图访问的网址已被禁止访问: 'The URL you are trying to access has been blocked',
  '1、非常抱歉，根据相关法律法规和政策规定，您当前访问的网址不符合规定，所以无法继续访问':
    '1. We are very sorry. According to relevant laws, regulations and policies, the URL you are currently visiting does not comply with the regulations, so you cannot continue to access it.',
  '2、您可以通过申请“网址加白”，在相关机构授权下方可访问相应的网址':
    '2. You can apply for "website whitening" and can access the corresponding website with authorization from the relevant organization.',
  '3、网址加白申请需要您的团队通过实名认证，提交加白申请后，一般当天之内即可审批完毕':
    '3. Your website whitening application requires your team to pass real-name authentication. After submitting the whitening application, the approval can usually be completed within the same day.',
  请先完成实名认证: 'Pls complete real-name authentication first',
  '应国家网络监管部门要求，必须完成实名认证后方允许申请网址加白':
    'In response to the requirements of the national network regulatory authorities, real-name authentication must be completed before the application for website whitening is allowed.',
  立即认证: 'Verify Now',
  网址加白申请: 'Website whitening application',
  '原则上只允许申请个别电商平台的访问网址，针对国家严格受控的社交媒体等网站无法申请通过':
    'In principle, only access URLs to individual e-commerce platforms are allowed. Applications for websites such as social media that are strictly controlled by the country cannot be approved.',
  关于网址申请加白的须知: 'Things to know about applying for whitening on your website address',
  '1、您需要充分理解，花漾灵动受国家法律法规的严格监管，国家相关执法机构随时有权限查询您在花漾灵动上的操作行为':
    '1. You need to fully understand that HuaYoung is strictly supervised by national laws and regulations, and relevant national law enforcement agencies have the authority to inquire about your operating behavior on HuaYoung at any time.',
  '2、您正在申请访问的网站，严禁用于任何涉黄、涉赌或者其它国家法律法规所禁止的行为，一旦发觉有上述行为，云上悦动有权立即停止相关服务，包括且不限于收回云主机（含IP地址）、封禁用户账户、冻结团队账户余额等':
    '2. The website you are applying to visit is strictly prohibited from being used for any activities related to pornography, gambling or other activities prohibited by national laws and regulations. Once the above-mentioned activities are discovered, HuaYoung has the right to immediately stop related services, including but not limited to withdrawing cloud services. Host (including IP address), banned user accounts, frozen team account balance, etc.',
  我已阅读并已充分理解: 'I have read and fully understood',
  '网址加白申请提交失败：': 'The URL whitening application submission failed:',
  申请域名: 'Apply for a domain name',
  申请原因: 'Reason for application',
  '请输入您希望访问此网站的原因（不超过256个字符）':
    'Pls enter the reason why you wish to visit this website (no more than 256 characters)',
  申请人: 'applicant',
  '建议您留下真实姓名，这样可以加速您的申请流程':
    'It is recommended that you leave your real name to speed up your application process',
  公司实体: 'corporate entity',
  如果是个人行为可以不填: 'If it is a personal behavior, you don’t need to fill it in.',
  '强烈建议您留下公司全称，这样可以大大加速您的申请流程':
    'It is strongly recommended that you leave your full company name, which can greatly speed up your application process.',
  手机号码: 'Mobile',
  当前团队花瓣余额已消耗完毕: 'The current team token balance has been consumed',
  请购买新的花瓣并确保花瓣余额大于:
    'Pls purchase new tokens and make sure the token balance is greater than',
  '，1分钟后，花漾浏览器将自动关闭，如果您当前正在执行重要工作，请注意及时保存':
    ', after 1 minute, HuaYoung Browser will automatically close. If you are currently performing important work, Pls save it in time.',
  当前分身所属团队的花瓣余额已消耗完毕:
    "The token balance of the current avatar's team has been consumed.",
  '1分钟后，花漾浏览器将自动关闭，如果您当前正在执行重要工作，请注意及时保存':
    'After 1 minute, HuaYoung Browser will automatically close. If you are currently performing important work, Pls save it in time.',
  汇率换算: 'Currency Exchange',
  汇率计算器: 'Calculator',
  '100日元/人民币': '100 yen/RMB',
  日元: 'JPY',
  '美元/人民币': 'USD/CNY',
  '欧元/人民币': 'EUR/CNY',
  欧元: 'EUR',
  '英镑/人民币': 'GBP/CNY',
  英镑: 'GBP',
  '港币/人民币': 'HKD/RMB',
  港币: 'Hong Kong dollar',
  '澳门元/人民币': 'MOP/RMB',
  澳门元: 'Macau patacas',
  通过浏览器提供的API查询您的经纬度: 'Get the latitude and longitude',
  '系统将为您打开新的标签页并访问“花漾接入点”网页，在该网页中请点击”立即查询“经纬度，通过对查询出来的经纬度和指纹中设置的经纬度进行对比，以验证浏览器指纹模拟的真实性':
    'The system will open a new tab for you and visit the "HuaYoung Access Point" webpage. In this webpage, Pls click "Query Now" to verify the browsing by comparing the latitude and longitude queried with the latitude and longitude set in the fingerprint.',
  立即查询: 'Query Now',
  浏览器指纹信息: 'Browser Fingerprint',
  '特征码：': 'Checksum:',
  关于浏览器指纹实例的动态属性: 'About dynamic properties of browser fingerprint',
  '浏览器指纹实例中的分辨率、语言、经纬度、时区等属性是”动态“属性，只有在“运行期”才会拥有最终的真实值，它们往往和IP地址紧密相关；一般而言，您只需要确保IP地址的准确性即可，如有需要，您也可以自行设置这些动态属性':
    'The attributes such as resolution, language, longitude and latitude, and time zone in the browser fingerprint are "dynamic" attributes. They will only have the final real value during the "runtime". They are often closely related to the IP. Generally, you only make sure the IP address is accurate. If necessary, you can also set these dynamic attributes yourself.',
  查看指纹详情: 'View Details',
  浏览器指纹动态属性: 'Dynamic Properties of Fingerprint',
  指纹详情: 'Fingerprint details',
  通过浏览器指纹检测服务可以观察当前浏览器所使用的指纹信息:
    'Through the browser fingerprint detection service, you can observe the fingerprint information used by the current browser.',
  '请选择拟使用的浏览器指纹检测服务：':
    'Pls select the browser fingerprint detection service you intend to use:',
  浏览器指纹检测: 'Browser fingerprint detection',
  'IP无法连接，建议重新测试以获得错误日志':
    'IP cannot connect, it is recommended to retest to get the error log',
  '当前的IP加速通道无法访问检测网站，建议您切换IP加速通道重试':
    'The current IP acceleration channel cannot access the detection website. It is recommended that you switch the IP acceleration channel and try again.',
  切换: 'Switch',
  '无法通过IP检测机构对指定代理IP进行检测（检测超时）':
    'Unable to detect the specified IP through the IP detection page (detection timeout)',
  访问质量检测失败: 'Network quality check failed',
  '指定的代理IP“': 'Specified IP',
  '”与检测机构所检测到的IP“': 'With the IP detected by the detection page',
  '”不符': 'not match',
  '访问质量检测结果：无法连通': 'Network quality test result: Unable to connect',
  '备注:--': 'Notes:--',
  错误日志: 'Error Log',
  切换加速通道: 'Switch Acceleration Channel',
  错误日志复制成功: 'Error log copied successfully',
  复制错误信息: 'Copy Message',
  详细信息: 'Details',
  '当前的连接方式无法通过IP检测，您可以切换IP连接方式进行尝试，否则，请复制以下错误信息和您的IP服务商联络':
    'The current connection method cannot pass IP detection. You can switch the IP connection method and try. Otherwise, Pls copy the following error message and contact your IP service provider.',
  当前团队的花瓣余额小于: 'The token balance of the current team is less than',
  '，会影响到此分身的会话过程，您需要先购买适量花瓣后再行访问分身':
    ', will affect the session process of this avatar. You need to purchase an appropriate amount of tokens before accessing the avatar.',
  '，这可能会影响到此分身的会话过程，建议您先购买适量花瓣后再行访问分身':
    ', this may affect the session process of this avatar. It is recommended that you purchase an appropriate amount of tokens before accessing the avatar.',
  关于本地代理的IP检测: 'About IP detection of local proxy',
  浏览器指纹: 'Fingerprint',
  访问质量: 'Quality',
  无法连通: 'Unable to connect',
  安全: 'Safety',
  风险: 'Risk',
  '检测中...': ' checking...',
  '检测结果：使用本机IP地址，忽略': ' detection: Use local IP, ignore',
  '检测结果：使用本地代理，忽略': ' detection: Use local proxy, ignore',
  '检测结果：': ' test results: ',
  未检测: 'Not detected',
  '1.IP检测主要检测当前的出口IP是否和绑定的IP保持一致':
    '1. IP detection mainly detects whether the current exit IP is consistent with the bound IP',
  '2.考虑到本地代理的出口IP可能会经常变化，故浏览器分身绑定本地代理时不做出口IP的检测':
    '2. Considering that the exit IP of the local proxy may change frequently, the browser does not detect the exit IP when binding local proxy.',
  '3.您可以通过本页面显示的出口IP做进一步的判断':
    '3. You can make further judgments based on the exit IP displayed on this page.',
  绑定IP: 'Bind IP',
  '来源：': 'Source:',
  '性质：': 'Type:',
  动态IP: 'Dynamic',
  静态IP: 'Static',
  '网络类型：': 'Network Type:',
  '使用范围：': 'Scope:',
  '确定要从IP池中申请新的IP吗？': 'Sure to apply for a new IP from the IP pool?',
  '确定要更换动态IP的出口IP吗？': 'Sure to change the outbound IP of the dynamic IP?',
  '申请新的IP后，与出口IP相关的地理位置、时区、语言等浏览器指纹信息都会发生相应的变化':
    'After applying for a new IP, the browser fingerprint information such as geographical location, time zone, and language related to the export IP will change accordingly.',
  '当更换出口IP后，与出口IP相关的地理位置、时区、语言等浏览器指纹信息都会发生相应的变化':
    'When the exit IP is changed, the browser fingerprint information such as geographical location, time zone, and language related to the exit IP will change accordingly.',
  清理Cookie等缓存信息: 'Clear cache information such as cookies',
  重新申请IP: 'Reapply for IP',
  更换出口IP: 'Change export IP',
  '正在为您获取最新的IP值...': 'Getting the latest exit IP...',
  通过IP检测机构可以准确的检测出当前浏览器所使用的IP地址:
    'The IP detection agency can accurately detect the IP address currently used by the browser.',
  '请选择拟使用的IP检测服务：': 'Pls select the IP detection service you plan to use:',
  IP检测: 'IP detection',
  未开启: 'Disabled',
  '页面，': 'page, ',
  元素: ' elements',
  已开启: 'On',
  已关闭: 'Closed',
  事前预防: 'Precaution',
  事中监管: 'Supervision',
  事后审计: 'Audit',
  '，允许跳过敏感操作': ', allowing sensitive operations to be skipped',
  '，不允许跳过敏感操作': ', not allowed to skip sensitive operations',
  关于分身的安全策略: 'Security strategies for browsers',
  '分身的安全策略从“事前预防、事中监管、事后审计”三个维度全方位的内控账号的安全运营，您可在分身详情页面中决定是否开启安全策略':
    'The avatar\'s security policy covers the safe operation of internal control accounts from three dimensions: "prevention, in-process supervision, and post-event auditing". You can decide whether to enable the security policy on the avatar details page.',
  '分身运营是否安全？关于分身安全策略': 'Is it safe to operate separately? About security policy',
  直连优先: 'Direct First',
  直连: 'Direct Conn',
  质量优先: 'Quality First',
  本地代理: 'Local Proxy',
  '正在切换加速通道，请稍候...': 'Switching conn method, pls wait...',
  '正在尝试第{{count}}次切换加速通道，请稍候...':
    'Trying the {{count}}th time to switch conn method, pls wait...',
  切换加速通道失败: 'Failed to switch conn method',
  无法与指定的加速通道建立连接: 'Unable to establish conn with specified method',
  连接方式: 'Connection',
  网络性质: 'Network Type',
  客户端直连: 'Direct Conn',
  VIP加速通道: 'VIP Accel Channel',
  免费加速通道: 'Free Accel Channel',
  收费标准: 'Charges',
  '当采用直连与免费的加速通道时，不会产生任何额外的花瓣支出；当采用VIP加速通道时，会根据收费标准按照实际产生的流量扣除一定数量的花瓣':
    'When using direct connection and free acceleration channels, no additional tokens will be incurred; when using VIP acceleration channels, a certain number of tokens will be deducted based on the actual traffic generated based on the charging standards.',
  '确定要切换至VIP加速通道吗？': 'Sure to switch to VIP Channel?',
  '除了直连与免费加速通道以外以外，当选择接入点等连接方式时，将会根据消耗的流量扣除相应数量的花瓣，请确认是否继续':
    'In addition to direct connection or free accel channel, when selecting a vip accel channel, the corresponding number of tokens will be deducted based on the traffic consumed. Pls confirm whether to continue.',
  记住我的选择: 'Remember',
  '存储在客户端，下次在本客户端设备中访问此分身时会默认使用此连接方式':
    'Stored on the client, this connection will be used by default the next time.',
  该IP的连接方式为: 'The connection of this IP is',
  测速: 'Speed Test',
  '您可以通过测速查看不同连接方式的质量再决定如何切换（只能在IP详情中设置的连接方式范围中切换）；需要注意的是，连接方式的切换并不会暴露真实IP地址，但可能会产生加速流量并而带来花瓣的消耗':
    'You can check the quality of different connection methods through speed testing and then decide how to switch (you can only switch within the connection method range set in the IP details); it should be noted that switching the connection method will not expose the real IP address, but it may Generating accelerated flow and resulting consumption of tokens',
  会话详情: 'Session details',
  '会话ID：': 'Session ID:',
  '开始时间：': 'Start time:',
  主IP连接方式: 'Main IP connection method',
  副IP连接方式: 'Secondary IP connection method',
  '使用本地代理，无法进行IP连接方式的切换':
    'When using a local proxy, the IP connection method cannot be switched.',
  '网页加载过慢？切换IP连接方式以开启海外加速':
    'Page loading too slowly? Switch the IP connection method to enable overseas acceleration',
  切换IP网络加速: 'Switch IP Acceleration Channel',
  无法连接: 'unable to connect',
  关于浏览器分身首页的定制: "Customization Browser's Homepage",
  '针对每个分身，您都可以在分身属性中定义打开花漾浏览器时的默认首页':
    "You can define the default homepage in the browser avatar's properties.",
  团队成员访问此分身时均可看到此处设置的快捷方式: 'Team members all can see it.',
  浏览器检测页: 'Detection Page',
  修改快捷方式: 'Modify',
  移除: 'Remove',
  添加快捷方式: 'Add Shortcut',
  快捷方式: 'Shortcuts',
  链接: 'Link',
  '时间：': 'Time:',
  'YYYY年MM月DD日，dddd': 'YYYY/MM/DD, dddd',
  'IP地址所在位置：': 'IP Time Zone:',
  '花漾客户端所在位置：': 'Client Time Zone:',
  定制浏览器首页: 'Customize Homepage',
  安全策略: 'Security Policy',
  访问详情: 'Visit details',
  世界时间: 'World Time',
  支付失败: 'Payment failed',
  管理联系地址: 'Mailing Addresses',
  收件人: 'Recipient',
  请填写收件人: 'Pls fill in the recipient',
  电子邮件: 'Email',
  请输入您的邮箱地址: 'Pls enter your e-mail address',
  请输入正确的邮箱地址: 'Pls input the correct email address',
  地址: 'Address',
  请输入地址: 'Pls enter address',
  地址不能少于3个字符: 'Address cannot be less than 3 characters',
  激活: 'Activation',
  验证: 'Verify',
  任务卡类型: 'Task card type',
  '>4核': '>4 cores',
  '<=4核': '<=4 cores',
  礼品卡类型: 'Gift card type',
  有效期至: 'Valid Until',
  '（已过期）': '(expired)',
  礼品卡: 'Token Card',
  花瓣礼品卡: 'Token Card',
  流程任务礼品卡: 'Process Task Gift Card',
  费用中心页签: 'Expense Center Tab',
  RPA页签: 'RPA tab',
  您输入的是一张: 'What you entered is a',
  '，是否到': ', whether to arrive',
  '进行激活？': 'Activation?',
  礼品卡卡号: 'Gift Card No.',
  请输入礼品卡卡号: 'Pls enter your gift card number',
  礼品卡卡密: 'Password',
  请输入礼品卡卡密: 'Pls enter the gift card password',
  '有{{count}}笔交易可申请发票，可开票金额：{{total}}元，请选择':
    'You have {{count}} transactions that can be applied for invoice, the amount of the invoice can be opened: {{total}} yuan, please choose',
  交易内容: 'Transaction content',
  交易时间: 'transaction hour',
  交易金额: 'Amount of the transaction',
  发票信息: 'Invoice Information',
  开票信息: 'Billing Information',
  联系地址: 'Mailing Address',
  发票申请已提交: 'Invoice request has been submitted',
  '平台会根据您提交的申请开具发票，请耐心等待':
    'The platform will issue an invoice based on the application you submitted, pls wait patiently.',
  不支持的发票类型: 'Unsupported invoice type',
  '若需要修改默认发票信息及联系地址，请在相关界面进行设置':
    'If you need to modify the default invoice information and mailing address, Pls set it in the relevant interface.',
  提交申请: 'submit application',
  申请开票: 'Apply Invoice',
  确定开票金额: 'Determine the invoice amount',
  确认发票信息及联系地址: 'Confirm invoice information and mailing address',
  '当前可开票金额不足{{amount}}元':
    'The current amount that can be invoiced is less than {{amount}} yuan',
  '开票金额需要大于{{amount}}元才能开票':
    'The amount that can be invoiced needs to be greater than {{amount}} yuan to issue an invoice',
  当前未设置开票信息: 'No billing information is currently set',
  需要先设置开票信息才能够开具发票:
    'You need to set up the invoicing information before you can issue an invoice.',
  立即设置: 'Set up now',
  当前未设置发票联系地址: 'No invoice mailing address is currently set',
  需要先设置发票的联系地址信息才能够开具发票:
    'You need to set the mailing address information for the invoice before you can issue the invoice.',
  发票抬头: 'Invoice',
  请输入发票抬头: 'Pls enter the invoice title',
  请输入纳税识别号: 'Pls enter tax identification number',
  纳税识别号: 'Tax ID',
  开户银行: 'Bank of deposit',
  请输入开户银行名称: 'Pls enter the name of the bank where the account is opened',
  银行账号: 'Account number',
  请输入银行账号: 'Pls enter the account number',
  注册场所地址: 'Registered address',
  请输入注册场所地址: 'Pls enter the registered location address',
  公司注册电话: 'Company registration mobile number',
  请输入公司注册电话: 'Pls enter company registration mobile number',
  管理开票信息: 'Manage billing information',
  请选择发票类型: 'Pls select invoice type',
  发票类型: 'Invoice Type',
  合并支付: 'Consolidated payment',
  支付宝: 'Alipay',
  微信支付: 'WeChat Pay',
  订单: 'Order',
  请联系: 'Pls contact',
  '，并提供': ', and provide',
  '收款方：': 'Beneficiary:',
  '请支付：': 'Pls pay:',
  扫码付款: 'Scan code to pay',
  扫一扫: 'scan it',
  现金支付: 'cash payment',
  改价折现: 'Price change discount',
  '实付金额：': 'The amount actually paid:',
  '支付方式：': 'Payment:',
  充值: 'Recharge',
  余额支付: 'balance payment',
  银行转账: 'Bank Transfer',
  已创建: 'Created',
  已锁定: 'Locked',
  待确认: 'Confirmed',
  已支付: 'Paid',
  已取消: 'Cancelled',
  已退款: 'Refunded',
  个人普通发票: 'Personal ordinary invoice',
  ' 电子发票（普通发票）': 'Electronic invoice (ordinary invoice)',
  '电子发票（增值税专用发票）': 'Electronic invoice (VAT-specialized invoice)',
  已申请: 'Already applied',
  已开票: 'Invoiced',
  未开票: 'Not Invoiced',
  生产成功: 'Production Successful',
  未开始: 'Not Started',
  生产失败: 'Production Failure',
  生产中: 'In Production',
  消耗花瓣: 'Consume Tokens',
  初始赠送: 'Initial Gift',
  花瓣转入: 'Tokens Turn In',
  花瓣礼品卡激活: 'Token Gift Card',
  订单退款: 'Order Refund',
  RPA市场流程: 'RPA Market',
  RPA发送邮件: 'RPA Email',
  RPA发送微信模板消息: 'RPA WeChat Message',
  RPA发送短信: 'RPA SMS',
  指纹消耗: 'FP Consumption',
  超额流量: 'Excess Traffic',
  代理流量: 'Proxy Traffic',
  RPA执行: 'RPA Execution',
  分身安全策略: 'Security Policy',
  花瓣转出: 'Token Out',
  中转流量: 'Accel Traffic',
  账户总览: 'Overview',
  订单管理: 'Order Mgmt',
  花瓣收支: 'Token Balance',
  发票管理: 'Invoice',
  花瓣明细下载: 'Details Download',
  '把花瓣收支明细下载成Excel表格，方便您进行分析':
    'Download the token income/expenditure details into an Excel to facilitate your analysis.',
  时间范围: 'Time Range',
  花瓣收支类型: 'Income/Expense',
  记录类型: 'Record Type',
  记录来源: 'Source',
  收支时间: 'Time',
  发起人: 'Creator',
  IP流量日志: 'Traffic Logs',
  接入点加速流量: 'Accelerate Traffic',
  接入点: 'Access Point',
  配额外流量: 'Extra Traffic Quota',
  流量单价: 'Traffic unit price',
  运行设备: 'Device',
  '花瓣/GB/天': ' Tokens/GB/Day',
  '花瓣/': 'Token/',
  '/天': '/Day',
  次: ' ',
  计费数量: 'Quantity',
  计费单价: 'Unit Price',
  花瓣余额: 'Token Balance',
  市场流程ID: 'Market Proc ID',
  '花瓣收支记录【': ' Token Transactions [',
  '】详情': ' ]Details',
  请输入记录编号: 'Pls enter the record No',
  查询: 'Search',
  记录编号: 'Record No',
  来源: 'Source',
  明细下载: 'Details Download',
  流水号: 'Serial No',
  消费类型: 'Expense Type',
  消费时间: 'Expense Time',
  关联订单: 'Associated Orders',
  消费金额: 'Expense Amount',
  消费后余额: 'Balance',
  '订单支付明细：': 'Order Details:',
  '收支流水【': 'Income [',
  交易类型: 'Expense Type',
  支付方式: 'Payment Method',
  账户余额: 'Account Balance',
  发票号码: 'Invoice No',
  快递公司: 'Courier',
  快递单号: 'Tracking No',
  已开票信息: 'Invoiced Info',
  申请开票时间: 'Invoicing Time',
  '金额（元）': 'Amount',
  查看发票信息: 'View Invoice Info',
  可开票金额: 'Invoiceable Amount',
  发票申请记录: 'Invoice App. Records',
  '确定要取消此订单吗?': 'Sure to cancel this order?',
  订单一旦取消将无法恢复: 'Once an order is canceled it cannot be restored',
  '订单取消成功！': 'Order canceled success!',
  全部订单类型: 'All Types',
  全部订单状态: 'All Status',
  请输入订单编号: 'Pls enter order No',
  '确定要批量取消所选订单吗?': 'Sure to batch cancel the selected orders?',
  '批量取消订单成功！': 'Batch order cancellation success!',
  批量取消: 'Batch cancellation',
  请先选中1至多个订单: 'Pls select 1 or more orders first',
  合并支付订单: 'Combine payment orders',
  只能选择状态为已创建的订单或已经合并的订单进行合并支付:
    'Only orders with a status of Created or Merged can be selected for combined payment.',
  选择多笔订单进行合并支付: 'Select multiple orders for combined payment',
  批量取消订单: 'Cancel orders in batches',
  '只能取消状态为已创建、已锁定、待确认的订单':
    'Only orders with the status of Created, Locked, or Pending Confirmation can be cancelled.',
  IP来源: 'Source',
  生产状态: 'Manufacturing state',
  价格: 'price',
  支付明细: 'Payment details',
  充值金额: 'Recharge',
  '或指定：': 'or Specify:',
  立即充值: 'Recharge',
  '每月费用预估：': 'Monthly Est:',
  '距离下次自动扣费尚余：': 'Days Left Until Next Auto Debit:',
  已购买的平台IP: 'Purchased IP',
  已开启自动续费的平台IP: 'Auto Renewal IP',
  '当购买的平台IP开启自动续费时，请确保账户余额充足':
    'When the purchased IP turns on automatic renewal, Pls ensure that the account balance is sufficient',
  充值记录: 'Recharge Records',
  账户余额消耗: 'Balance Consumption',
  '最近30天，平均每天消耗花瓣数量：': 'Average Daily Token Consumption: ',
  '昨天消耗花瓣数量：': 'Tokens Consumption Yesterday: ',
  收支记录: 'Inc/Exp Records',
  浏览器分身: 'Browsers',
  IP流量: 'IP traffic',
  RPA流程: 'RPA',
  昨日消耗花瓣: 'Yesterday',
  昨日共消耗花瓣数量: 'Total number of tokens consumed yesterday',
  '请确保花瓣余额充足，否则将影响到您的日常使用':
    'Pls make sure the token balance is sufficient, otherwise it will affect your daily use',
  昨日花瓣消耗: 'Yesterday',
  花费: 'spend',
  最近30天没有任何支出: 'No expenses in the last 30 days',
  最近30天支出明细: 'Spending Details',
  最近30天支出概览: 'Spending Overview Last 30 Days',
  开票信息维护: 'Invoicing Info Mgmt',
  张可用: ' Voucher Available',
  如何获得代金券: 'How to get a voucher',
  代金券面值: 'Voucher Value',
  '（限用一次）': '(Used Once)',
  激活新的代金券: 'Activate Voucher',
  代金券卡号: 'Card No',
  请输入代金券卡号: 'Pls enter the voucher card number',
  代金券卡密: 'Card PWD',
  请输入代金券卡密: 'Pls enter the voucher password',
  '，最小可用': ', the minimum available',
  余额: 'Balance',
  '（0次可用）': '(0 times available)',
  立即使用: 'use immediately',
  可用代金券: 'Available vouchers',
  暂无可用的代金券: 'No vouchers available yet',
  不可用代金券: 'Vouchers not available',
  暂无不可用的代金券: 'There are currently no unavailable vouchers',
  选择代金券: 'Select Voucher',
  '代金券一旦删除，不可恢复，请确认':
    'Once the voucher is deleted, it cannot be recovered, Pls confirm',
  没有找到相关的代金券: 'No relevant vouchers found',
  当前团队没有代金券: 'This team has no vouchers',
  当前可用: 'Available',
  即将过期: 'Expires Soon',
  已失效: 'Expired',
  已消费: 'Consumed',
  '内容出现乱码？可尝试通过左侧切换编码':
    'The content is garbled? You can try switching encodings on the left',
  Cookie数据: 'Cookies',
  网站密码: 'Password',
  IP代理: 'IP proxy',
  已确认: 'confirmed',
  已忽略: 'Ignored',
  请确认当前浏览器的指纹: 'Pls confirm the fingerprint of the current browser',
  请选择拟迁移的Cookie数据: 'Pls select the cookie data to be migrated',
  忽略: 'Ignore',
  '汇报成功！': 'Report success!',
  '汇报失败！': 'Report failed!',
  '浏览器指纹采集完毕，是否立即上报?':
    'After browser fingerprint collection is completed, do you want to report it immediately?',
  '浏览器指纹采集完毕，需要上报后才会生效，您可以立即上报，也可以检查完确认无误后再点击页面上的“上报当前浏览器指纹”按钮提交':
    'The browser fingerprint collection is completed and needs to be reported before it will take effect. You can report it immediately, or you can click the "Report current browser fingerprint" button on the page to submit after checking and confirming that it is correct.',
  立即上报: 'Report immediately',
  我再想想: 'let me think again',
  '所谓浏览器指纹，是指根据浏览器所在主机的软硬件特征（如操作系统、浏览器内核、字体、显卡、音频设备等）计算出一个唯一的特征码，您可采集当前浏览器所在指纹，并将其导入至花漾客户端内。':
    'The so-called browser fingerprint refers to calculating a unique signature based on the software and hardware characteristics of the host where the browser is located (such as operating system, browser kernel, fonts, graphics card, audio device, etc.). You can collect the fingerprint of the current browser. And import it into HuaYoung app.',
  开始采集当前浏览器: 'Start collecting the current browser',
  浏览器指纹已上报: 'Browser fingerprint has been reported',
  上报当前浏览器指纹: 'Report current browser fingerprint',
  上报指纹: 'Report fingerprints',
  '浏览器指纹信息采集完毕，需要上报后才会生效':
    'The browser fingerprint information has been collected and needs to be reported before it will take effect.',
  不支持的浏览器: 'Unsupported browser',
  '非常抱歉，当前只支持采集Android手机中Chromium内核的浏览器，请不要采集除此之外的其它浏览器':
    'We are very sorry. Currently, we only support browsers that collect the Chromium kernel in Android phones. Pls do not collect other browsers.',
  '非常抱歉，当前只支持采集Chrome和Edge浏览器信息，请不要采集除此之外的其它浏览器':
    'We are very sorry. Currently, we only support the collection of Chrome and Edge browser information. Pls do not collect information from other browsers.',
  '链接已失效，无法采集浏览器信息':
    'The link has expired and browser information cannot be collected.',
  '当前采集链接已过期，如需要采集当前浏览器相关信息，请重新获取采集链接':
    'The current collection link has expired. If you need to collect information related to the current browser, Pls obtain the collection link again.',
  '所属团队：': 'Team:',
  '有效时间：': 'Effective time:',
  m分s秒: 'm minutes s seconds',
  '正在为您上报数据，请稍候': 'Data is being reported to you, pls wait.',
  '这可能需要一点时间，请稍候...': 'This may take a moment, pls wait...',
  '浏览器信息采集成功，请确认信息后点击“上报数据”':
    'Browser information collection is successful, Pls confirm the information and click "Report Data',
  '请确认已经采集的所有数据，确认无误后请点击“上报数据”，采集后的信息将被上报至花漾中':
    'Pls confirm all the data that has been collected. After confirming that it is correct, Pls click "Report Data". The collected information will be reported to HuaYoungzhong',
  '未安装浏览器插件Cooke，确定要继续采集吗？':
    'The browser plug-in Cooke is not installed. Sure to continue collecting?',
  '未安装浏览器插件Cook，这意味着无法采集Cookie信息，分身克隆将失去意义，确定要继续吗？':
    'The browser plug-in Cookie is not installed, which means that cookie information cannot be collected and cloning will be meaningless. Sure to continue?',
  无法采集Cookie数据: 'Unable to collect cookie data',
  '当前浏览器未安装花漾浏览器插件Cook，无法采集Cookie信息。':
    'The HuaYoung browser plug-in Cookie is not installed on the current browser, so cookie information cannot be collected.',
  '当前浏览器未安装浏览器插件Cook，无法采集Cookie信息。':
    'The browser plug-in Cookie is not installed on the current browser, so cookie information cannot be collected.',
  Chrome网上应用商店: 'Chrome Web Store',
  Edge网上应用商店: 'Edge online app store',
  浏览器分身克隆: 'Browser Clone',
  '采集当前浏览器的指纹信息和Cookie数据，并将其导入至花漾中，这样可以保证分身在不同浏览器之间的无缝迁移':
    'Collect the fingerprint information and cookie data of the current browser and import them into HuaYoung, which ensures seamless migration of avatars between different browsers.',
  开始采集: 'Start collecting',
  数据已上报: 'Data has been reported',
  上报数据: 'Report data',
  正在为您寻找本机: 'Looking for this machine for you',
  '浏览器的安装路径...': 'Browser install path...',
  正在为您采集: 'Collecting for you',
  '的指纹信息，请稍候...': 'fingerprint information, pls wait...',
  '指纹信息采集失败，无法导入指纹模板':
    'Fingerprint information collection failed and fingerprint template cannot be imported.',
  指纹采集成功: 'Fingerprint collection successful',
  '安装路径：': 'Install path:',
  未在当前电脑上寻找到Chrome浏览器的安装路径:
    'The install path of Chrome browser was not found on this computer.',
  未在当前电脑上寻找到Edge浏览器的安装路径:
    'The install path of Edge browser was not found on this computer.',
  当前只支持采集Chrome与Edge浏览器的指纹信息:
    'Currently only supports collecting fingerprint of Chrome and Edge',
  '花漾的指纹库拥有数十万枚物理世界真实存在的指纹信息，当前团队还可免费获取':
    "HuaYoung's fingerprint database contains hundreds of thousands of fingerprint that actually exists in the physical worl.",
  '枚指纹模板，意味着最多可以生成': 'fingerprint templates, which means that up to',
  枚唯一且真实的指纹实例: 'A unique and authentic fingerprint instance',
  免费获取: 'Get ',
  枚: ' ',
  '您当前采集的指纹模板的特征码和当前系统中已经存在的指纹模板“':
    'The characteristic code of the fingerprint template you are currently collecting and the fingerprint template that already exists in the current system.',
  '”是一样的，这意味着这两个指纹模板所包含的信息是重复的，请点击上一步获取新的指纹采集链接，并更换另一个浏览器以采集其指纹':
    '” are the same, which means that the information contained in these two fingerprint templates is duplicated. Pls click the previous step to obtain a new fingerprint collection link, and change another browser to collect its fingerprints.',
  指纹模版名称不能为空: 'Fingerprint template name cannot be empty',
  指纹模版名称长度不能超过20个字符: 'The fingerprint template name cannot exceed 20 characters.',
  '是否需要根据指纹模板生成指纹实例？':
    'Want to generate a fingerprint instance based on the fingerprint template?',
  只有通过模板创建的指纹实例才可以被分身绑定使用:
    'Only fingerprint instances created through templates can be bound and used by browsers.',
  '请为指纹模板起一个名称：': 'Pls give the fingerprint template a name:',
  Chrome浏览器: 'Chrome',
  Edge浏览器: 'Edge',
  选择采集方式: 'Collection Method',
  '花漾特有的“采集浏览器特征信息以形成指纹模板，再对指纹模板施加噪音以裂变成若干指纹实例”的创新性做法确保了每一个浏览器指纹实例都是真实且唯一的':
    "HuaYoung's innovative method of collecting real-world browser characteristics and creating unique fingerprint instances from a template ensures each instance is genuine and distinct",
  采集: 'Collect',
  指纹模版: 'FP Template',
  裂变: 'Fission',
  指纹实例1: 'Instance 1',
  指纹实例2: 'Instance 2',
  指纹实例3: 'Instance 3',
  从花漾指纹库中免费获取: 'Get It from HuaYoung Fingerprint Library',
  '（免费配额': '(Free quota ',
  '枚）': ' )',
  需要在花漾客户端下使用: 'To be used within HuaYoung app',
  采集本机已安装的浏览器的指纹信息: 'Collect of browsers installed on this machine',
  '（支持Chrome/Edge浏览器）': '(Chrome/Edge)',
  '采集其它电脑/手机浏览器的指纹信息': 'Collect from other pc/mobile browsers',
  '（支持Win/Mac/Linux/Android）': '(Win/Mac/Linux/Android)',
  等待采集指纹模板: 'Waiting to Collec',
  等待采集指纹模版: 'Waiting for fingerprint template collection',
  '我们为您生成了浏览器指纹模板的采集链接，请您在真实的电脑中打开您希望采集指纹的浏览器并访问下述链接（目前仅支持采集Chrome与Edge浏览器）':
    'We have generated a collection link for the browser fingerprint template for you. Pls open the browser you want to collect fingerprints on a real computer and access the following link (currently only Chrome and Edge browsers are supported for collection)',
  该链接10分钟之内有效: 'This link is valid for 10 minutes',
  复制链接: 'Copy',
  获取: 'Obtain',
  采集浏览器指纹模板: 'Collect fingerprint templates',
  '通过采集物理世界的浏览器指纹模板，以确保生成的浏览器指纹实例的真实性与唯一性':
    'By collecting browser fingerprint templates from the physical world to ensure the authenticity and uniqueness of the generated fp instances',
  浏览器指纹模板详情: 'Fingerprint Template Details',
  指纹模板管理: 'FP Template List',
  '模板数量：': 'Number of templates:',
  '嗓音项过少，无法确保生成足够散列的指纹实例':
    'There are too few voice entries to ensure enough hashed fingerprint instances are generated.',
  '该指纹模板尚未生成任何指纹实例（最多可生成':
    'This fingerprint template has not generated any fingerprint instances (it can generate up to',
  该指纹模板已生成: 'The template has been generated ',
  '个指纹实例，最多还可生成': ' instances, and can generate up to ',
  批量生成浏览器指纹实例: 'Generate Fingerprint Instances in Batches',
  拟生成的实例数量: 'Number of instances',
  'UserAgent噪音，我们会通过对UserAgent的浏览器版本进行微调以保证指纹实例的唯一性，建议开启此选项':
    'UserAgent noise, we will fine-tune the browser version of UserAgent to ensure the uniqueness of the fingerprint instance. It is recommended to turn this option on',
  Canvas噪音: 'Canvas Noise',
  'Canvas噪音，取值范围在-1至1之间，我们会通过Canvas噪音以保证指纹实例的唯一性，建议开启此选项':
    'Canvas noise, the value range is between -1 and 1. We will use Canvas noise to ensure the uniqueness of the fingerprint instance. It is recommended to turn this option on.',
  音频噪音: 'Audio Noise',
  '音频噪音，取值范围在-1000至1000之间，我们会保证通过音频噪音以保证指纹实例的唯一性，建议开启此选项':
    'Audio noise, the value range is between -1000 and 1000. We will ensure the uniqueness of the fingerprint instance through audio noise. It is recommended to turn this option on.',
  'WebGL噪音，取值范围在-1至1之间，我们会通过WebGL噪音以保证指纹实例的唯一性，建议开启此选项':
    'WebGL noise, the value range is between -1 and 1. We will use WebGL noise to ensure the uniqueness of the fingerprint instance. It is recommended to turn this option on.',
  'ClientRects噪音，取值范围在-1至1之间，我们会通过ClientRects噪音以保证指纹实例的唯一性，建议开启此选项':
    'ClientRects noise, the value range is between -1 and 1. We will use ClientRects noise to ensure the uniqueness of the fingerprint instance. It is recommended to turn this option on',
  主机配置规格: 'Host Configuration',
  '我们会为您随机生成诸如n核mGB的主机配置规格，您无需确保主机配置规格的唯一性，但我们会为您生成的配置规格尽可能的均匀散列，建议开启此选项':
    'We will randomly generate host configuration specifications such as n-core mGB for you. You do not need to ensure the uniqueness of the host configuration specifications, but we will hash the configuration specifications generated for you as evenly as possible. It is recommended to turn on this option.',
  '访问网址时允许接受的语言列表，通常建议您将“跟随IP”放在第一顺位':
    'A list of languages that are allowed to be accepted when accessing a URL, it is usually recommended that you put "Follow IP" first',
  关于指纹实例: 'About Fingerprint Instance',
  '1、指纹实例是在指纹模板基础上对个别信息进行调整并加入适当的噪音而形成具体的浏览器指纹信息':
    '1. The fp instance is based on the fp template, adjusting individual information and adding appropriate noise to form specific browser fp information.',
  '2、指纹实例会通过消息摘要算法形成唯一的特征码，可通过特征码判断指纹实例的唯一性':
    '2. The fp instance will form a unique checksum through the message digest algorithm, and the uniqueness of the fingerprint instance can be determined through the checksum.',
  '3、生成的指纹实例可以绑定在分身上，为确保安全性，强烈建议您一个指纹实例只能绑定在一个分身上':
    '3. The generated fp instance can be bound to a browser. To ensure security, it is strongly recommended that you can only bind a fp instance to one browser.',
  '4、一个指纹模板理论上能够创建成千上万枚指纹实例，但强烈建议您同一个指纹模板下只生成不大于100个指纹实例，否则，您很难保证指纹实例的真实性':
    '4. A fp template can theoretically create thousands of instances, but it is strongly recommended that you only generate no more than 100 instances under the same template.',
  '5、无痕分身只能绑定指纹模板而非实例，前提是该指纹模板没有生成任何指纹实例，否则会影响到指纹实例的唯一性':
    '5. Stateless browsers can only bind fp templates instead of instances.',
  或者: ' or ',
  浏览器指纹实例详情: 'Fingerprint Instance Details',
  指纹实例列表字段自定义: 'Fingerprint instance list field customization',
  '为指纹实例“': 'Instance',
  '”绑定分身': 'Bind the avatar',
  绑定成功: 'Binding successful',
  特征码: 'Checksum',
  唯一性: 'Uniq',
  重复: 'Repeat',
  唯一: 'Only',
  主机配置: 'Host Config',
  绑定状态: 'Binding Status',
  空闲: 'Idle',
  删除成功: 'Successfully deleted',
  '个指纹实例，已用': ' fp instances, used ',
  '个，空闲': ' free',
  请先选中1至多个指纹实例: 'Pls select 1 or more fp instances first',
  批量删除: 'Batch Delete',
  设置语言: 'Language Settings',
  当前指纹模板尚未生成指纹实例:
    'The current fingerprint template has not generated a fingerprint instance yet',
  生成新的指纹实例: 'Create Instance',
  全部指纹: 'All FP',
  绑定中: 'Binding',
  依据特征码进行检索: 'Search by Checksum',
  模版名称: 'Template name',
  '指纹模版描述：--': 'FP template description: --',
  创建指纹实例: 'Create Instance',
  指定的指纹模板不存在: 'The specified fingerprint template does not exist',
  该指纹模板可能已经被删除: 'The fingerprint template may have been deleted',
  指纹实例配额超过限制: 'Fingerprint instance quota exceeds limit',
  创建指纹实例失败: 'Failed to create fingerprint instance',
  '创建指纹实例失败：': 'Failed to create fingerprint instance:',
  真实且不重复的浏览器指纹实例: 'Real and Unique Browser Fingerprint',
  '“浏览器指纹”是标识“浏览器分身环境”唯一性的特征信息，花漾灵动可以同时使用不同的浏览器指纹实例访问网站，以确保您的分身安全':
    'Browser Fingerprints uniquely identify the browser environment. HuaYoung browsers can simultaneously use different fingerprint to access websites.',
  浏览器指纹1: 'Fingerprint 1',
  浏览器指纹2: 'Fingerprint 2',
  浏览器指纹3: 'Fingerprint 3',
  '花漾特有的“采集物理世界的浏览器特征信息以形成指纹模板，再通过指纹模板裂变成若干指纹实例”的创新性做法确保了每一个指纹实例都是真实且唯一的':
    'HuaYoung\'s unique innovative approach of "collecting browser characteristic information from the physical world to form a fingerprint template, and then splitting the fingerprint template into several fingerprint instances" ensures that each fingerprint instance is real and unique',
  采集指纹模板: 'New Template',
  指纹模板标签管理: 'FP Tags Mgmt',
  依据模板名称或特征码检索: 'Search by name/checksum',
  系统生成: 'system generated',
  真实环境抓取: 'Real environment capture',
  麦可隆导入: 'Micron import',
  模板生成: 'TempGen',
  转让: 'Transfer',
  '确定要删除选中的指纹实例吗?': 'Sure to delete the selected fingerprint instance?',
  '如果指纹实例已经绑定到分身，会导致分身不可用，需要重新绑定新的指纹实例':
    'If the fingerprint instance has been bound to the browser, the browser will be unavailable, and a new fingerprint instance needs to be re-bound.',
  正在删除: 'deleting',
  '确定要删除选中的指纹模板吗？': 'Sure to delete the selected fingerprint template?',
  '如果指纹模板已经创建了指纹实例，那么会同步删除由该模板创建的所有指纹实例':
    'If the fingerprint template has already created a fingerprint instance, all fingerprint instances created by the template will be deleted simultaneously.',
  已生成: 'Generated ',
  客户端IP: 'Client IP',
  '针对海外IP可选择是否开启加速通道，以及连接方式的切换策略':
    'For overseas IPs, you can choose whether to enable acceleration channels and the switching strategy between acceleration channels.',
  '自动选择加速通道：': 'Auto:  ',
  所有免费加速通道: 'All Free',
  所有收费加速通道: 'All VIP',
  '指定以下加速通道：': 'Specify the Following Accel Channels:',
  可针对不同连接方式下进行网络质量测试: 'Network testing under different conne methods',
  网络测试: 'Network Test',
  设置IP的加速通道: 'Set up IP acceleration channel',
  选择所有免费的加速通道: 'Select all free acceleration channels',
  选择所有收费的加速通道: 'Select all paid acceleration channels',
  加速通道: 'Channel',
  客户端直连IP: 'Direct',
  选择入口位置为境内的加速通道: 'Choose Domestic Entry Acceleration Channel',
  选择入口位置为海外的加速通道: 'Choose Overseas Entry Acceleration Channel',
  入口位置: 'Entrance',
  出口位置: 'Exit',
  '当采用直连时，不会产生任何额外的花瓣支出；当采用接入点连接时，会根据不同的连接方式拥有不同的收费标准':
    'When using direct or free acceleration channels, there will be no additional token expenditure; when using VIP acceleration channels, there will be different charging standards.',
  连接方式切换策略: 'Switching Strategy',
  '默认采用直连，只有当直连不通时才会在当前允许的连接方式内，按照收费标准由低到高依次尝试，除非连接不通才会切换下一个连接方式':
    'Direct connection is used by default. Only when the direct connection fails, the currently allowed connection methods will be tried in order from low to high according to the charging standard. Unless the connection fails, the next connection will be switched.',
  '在当前允许的连接方式内，会按照连接质量的优劣自动选择最佳的连接方式':
    'Within the currently allowed connection methods, the best connection method will be automatically selected based on the quality of the connection.',
  '导入Socks5、Http、SSH协议的IP地址': 'Import IP (Socks5, HTTP, SSH)',
  'Socks5、Http是常见的代理协议，SSH是VPS/云主机的管理协议':
    'Socks5 and HTTP are common proxy protocols, and SSH is the management protocol of VPS.',
  '在VPS/云主机中安装代理软件': 'Install Proxy Software in VPS',
  '只需在VPS/云主机中安装“花漾IPGO”代理程序或通过SSH协议，即可将其公网IP导入花漾':
    'Just install the "HuaYoung IPGO" agent in the VPS or use the SSH protocol to import its public IP',
  导入动态IP地址: 'Import Dynamic IP',
  '为保证动态IP“会话保持”特性的可用，需要针对动态IP的供应商进行适配':
    'To ensure that the dynamic IP "IP-hold" feature is available, adaptation needs to be made to the dynamic IP provider.',
  创建自有公有云账户的云主机: 'Create Cloud Host with Your Own Public Cloud Account',
  '可以通过自己的账户主体购买公有云主机与IP地址（目前仅支持阿里云、AWS）':
    'You can buy public cloud hosts and IP through your own account (currently only Alibaba and AWS are supported)',
  无法在网页端中导入IP地址: 'Unable to import IP in browser',
  '请下载花漾客户端，并在花漾客户端中完成IP地址的导入':
    'Pls download the HuaYoung app and complete the import of the IP in the HuaYoung app.',
  批量导入IP: 'Batch IP import',
  '创建IP池（API提取IP）': 'Create IP Pool (API extract IP)',
  导入自有IP: 'Import IP',
  手动导入: 'Manual Import',
  IP值: 'IP ',
  '系统无法连接您输入的代理服务器，请您重新检查输入条件，也可以忽略错误，手动录入IP的值，完成IP的导入':
    'Cannot connect to the proxy server you entered. Pls recheck your input. You can also ignore the error and manually enter the IP to complete import.',
  IP值不能为空: 'IP cannot be empty',
  请填入IP值: 'Pls fill in the IP ',
  '忽略错误，继续导入': 'Ignore the error and continue importing',
  代理服务器无法连接: 'Proxy server cannot connect',
  '系统无法连接代理服务器，请重新检查输入条件，也可以忽略错误，继续导入':
    'The system cannot connect to the proxy server. Pls recheck the input conditions. You can also ignore the error and continue importing.',
  出口IP: 'Exit IP',
  动态IP名称: 'Dynamic IP name',
  '由于动态IP经常变化，请给其起一个有助记忆的名称':
    'Since dynamic IPs change frequently, Pls give them a name that is helpful to remember.',
  请输入名称: 'Pls enter name',
  '最少4个字符，最长128个字符': 'Minimum 4 characters, maximum 128 characters',
  名称已存在: 'name already exists',
  内容为空: 'Content is empty',
  代理信息格式不完整: 'Proxy info is incomplete',
  '端口（port）格式不正确': 'The port format is incorrect',
  '主机（host）格式不正确': 'The host format is incorrect',
  '请输入或粘贴格式为host:port:username:passport的文本，然后点击提交，会帮您快速填入表单':
    'Pls enter or paste the text in the format host:port:username:passport, and then click Submit. It will help you quickly fill in the form.',
  动态: 'Dynamic',
  静态: 'Static',
  提交成功: 'Submitted successfully',
  快速填写: 'Quick Fill',
  '您要使用剪切板中的代理信息吗？': 'Do you want to use the proxy information from the clipboard?',
  代理协议: 'Protocol',
  代理地址: 'Proxy',
  请输入代理地址: 'Pls enter proxy address',
  请输入代理IP地址或域名: 'Pls enter the proxy ip or domain name',
  '请输入正确的代理IP地址（支持IPV6）或域名':
    'Pls enter the correct proxy IP address (supports IPV6) or domain name',
  代理地址最大长度为512个字符: 'The maximum length of the proxy address is 512 characters',
  请输入端口号: 'Pls enter the port number',
  端口号必须为数字: 'Port number must be numeric',
  端口号: 'Port',
  认证方式: 'Verification',
  密码: 'Password',
  密钥: 'Key',
  登录密码: 'Password',
  密码不能为空: 'Password can not be blank',
  密钥文件: 'Key',
  请上传密钥文件: 'Pls upload key file',
  密钥文件过大: 'Key file is too large',
  密钥格式不正确: 'Key format is incorrect',
  IP协议: 'IP Type',
  '勾选此选项意味着当采用接入点方式连接时，将根据代理地址的IP类型进行自适应连接；如果代理地址被解析成一个IPv4地址，则使用接入点的IPv4连接；如果代理地址被解析成一个IPv6地址，则使用接入点的IPv6连接；如果代理地址被解析成一个IPv4和一个IPv6，则优先使用IPv4连接':
    'Checking this option means that when connecting using an access point, an adaptive connection will be made based on the IP type of the proxy address; if the proxy address is resolved to an IPv4 address, the IPv4 connection of the access point will be used; if the proxy address is If the proxy address is resolved to an IPv6 address, the IPv6 connection of the access point is used; if the proxy address is resolved to an IPv4 and an IPv6, the IPv4 connection is used first.',
  自适应: 'Adaptive',
  '勾选此选项意味着当采用接入点方式连接时，将使用接入点的IPv4地址连接代理地址，因此，要求代理地址必须可被解析成一个正确的IPv4地址':
    'Checking this option means that when connecting through an access point, the IPv4 address of the access point will be used to connect to the proxy address. Therefore, the proxy address must be able to be resolved into a correct IPv4 address.',
  '勾选此选项意味着当采用接入点方式连接时，将使用接入点的IPv6地址连接代理地址，因此，要求代理地址必须可被解析成一个正确的IPv6地址':
    'Checking this option means that when connecting through an access point, the IPv6 address of the access point will be used to connect to the proxy address. Therefore, the proxy address must be able to be resolved into a correct IPv6 address.',
  请指定供应商: 'Pls specify supplier',
  '动态IP是指虽然访问的代理服务是固定的，但其出口IP的值是动态变化的；动态IP往往会和“无痕账号”配合使用，常用于对网站的匿名访问等场景；为保证动态IP“会话保持”特性的可用，需要针对动态IP的供应商进行适配':
    'Dynamic IP means that although the accessed proxy service is fixed, the value of its exit IP changes dynamically; dynamic IP is often used in conjunction with "stateless accounts" and is often used in scenarios such as anonymous access to websites; in order to ensure dynamic The availability of the IP "IP-hold" feature requires adaptation by providers of dynamic IP',
  '支持导入Socks5、Http/Https、SSH等代理协议的':
    'Supports Socks5, Http, SSH and other proxy protocols',
  '地址；如果代理服务器开启了IP白名单，请根据连接方式将本机IP或者花漾接入点IP纳入到IP白名单':
    '; if the proxy server has turned on the IP whitelist, pls include the local IP or HuaYoung Access Point IP into the whitelist according to the connection method.',
  导入: 'Import',
  '导入Socks5/Http协议的静态IP地址': 'Import IP of the Socks5/Http/SSH',
  '拟导入的是本地代理而非IP，本地代理可直接在分身中绑定使用':
    'What is to be imported is the local proxy instead of the IP. The local proxy can be directly bound to the avatar.',
  '公网IP拥有一个可在公网访问的代理服务地址，而本地代理的代理服务位于本机或者本机所在局域网内；针对本地代理无需导入，可直接在分身中绑定使用':
    'The public IP has a proxy service address that can be accessed on the internet, and the proxy service of the local proxy is located on the local network. There is no need to import the local proxy, and it can be directly bound to the avatar.',
  '正在进行IP探测...': 'IP detection in progress...',
  '是否开启对海外IP地址的专线加速？':
    'Do you want to enable acceleration channels for overseas IP?',
  '针对海外IP，建议您开启加速通道，并设置连接方式的切换策略为“质量优先”，请确认是否开启':
    'For overseas IPs, it is recommended that you enable the acceleration channel and set the connection mode switching policy to "Quality Priority".',
  开启免费加速通道: 'All Free Acceleration Channels',
  开启收费VIP加速通道: 'All VIP Acceleration Channels',
  '花瓣/GB）': 'Tokens/GB)',
  不必了: 'No Need',
  '运行脚本并安装“花漾IPGO”': 'Run the Script & Install "HuaYoung IPGO',
  '1、必须以管理员administrator身份执行上述脚本':
    '1. The above script must be executed as administrator.',
  '2、您也可以切换至图形界面安装形态':
    '2. You can also switch to the graphical interface installation mode',
  立即切换: 'Switch Now',
  '安装遇到问题？': 'Having Trouble?',
  '请在开始菜单中运行“CMD”程序，在弹出的命令行窗口中运行以下脚本':
    'Run the "CMD" program in the start menu and run the following script in command line window',
  '请确保VPS/云主机可正常访问公网，点击了解': 'Pls ensure the VPS can access internet. ',
  'VPS/云主机安全组规则设置参考': 'Security Group Rule Settings',
  复制脚本: 'Copy Script',
  验证导入的IP地址: 'Verify Imported IP',
  '安装完成后，您可在命令行窗口中输入命令“ips”查看VPS/云主机的公网IP是否已经成功导入':
    'After the installation, you can enter the command "ips" in the cmd window to check whether the public IP of the VPS has been successfully imported.',
  '安装“花漾IPGO”': 'Install "HuaYoung IPGO',
  '1、必须以管理员administrator身份执行安装程序':
    '1. The installation program must be executed as an administrator.',
  '2、您也可以切换至命令行界面安装形态':
    '2. You can also switch to the command line interface installation mode',
  '请在目标VPS/云主机中下载“花漾IPGO安装包”，解压缩后双击“install.cmd”以运行安装程序。由于花漾IPGO需要修改系统配置，会被360安全卫士、腾讯电脑管家等软件误拦截，安装前请将这类安全软件退出':
    'Pls download the "HuaYoung IPGO Installation" in the target VPS, unzip it and double-click "install.cmd" to run the program. Since HuaYoung IPGO needs to modify the system configuration, it will be mistakenly intercepted by some antivirus software. Pls exit such antivirus before installation.',
  关联团队: 'Related Teams',
  '打开程序后，输入您的团队专属桥接码，以让该程序与您的团队进行关联':
    'Once you open the program, enter your team-specific bridge code to associate with your team',
  '安装完成后，去"IP地址"菜单观察IPGO的IP是否导入，如果没有出现，点击刷新':
    'After the installation, go to the "IP" menu to see if IP is imported. If not appear, click Refresh.',
  '1、必须以管理员root身份执行上述脚本':
    '1. The above script must be executed as administrator root.',
  '2、请设置安全组规则，允许主机访问外网':
    '2. Pls set security group rules to allow the host to access the external network.',
  '复制花漾IPGO安装脚本，并在目标VPS/云主机上以管理员身份执行脚本并安装“花漾IPGO”':
    'Copy the HuaYoung IPGO installation script, and execute the script as an administrator on the target VPS and install "HuaYoung IPGO',
  '如果您的VPS/云主机已经开启了SSH协议（一般而言，所有的Linux主机均会默认开启SSH协议），也可以通过SSH协议将其公网IP导入至花漾，这种方式最为简单（不用安装任何软件）':
    'If your VPS has the SSH protocol enabled (all Linux hosts will enable the SSH protocol by default), you can also import its public IP to HuaYoung through the SSH protocol. This method is the simplest (No need to install any software)',
  打开SSH协议导入向导: 'Open Import Wizard',
  '导入已有VPS/云主机的静态IP地址': 'Import IP From VPS',
  '如果您已经拥有VPS/云主机，只需在您的VPS/云主机中安装“花漾IPGO”服务程序，即可将VPS/云主机的公网IP导入到花漾IP池中；请根据您的VPS/云主机的操作系统而选择不同的安装脚本':
    'If you already have a VPS, you only need to install the "HuaYoung IPGO" program in your VPS to import the public IP into HuaYoung; Pls choose different installation scripts depending on the os of your VPS',
  IPGO安装步骤: 'Install Steps',
  购买用于店铺运营的公有云主机的注意事项: 'About Public Cloud Hosts',
  '您购买的云主机严禁用于任何涉黄、涉赌或者其它国家法律法规所禁止的行为，一旦发觉有上述行为，云上悦动有权立即停止相关服务，包括且不限于收回云主机、封禁账户、冻结账户余额等':
    'The cloud host is strictly prohibited from being used for any activities related to pornography, gambling or other activities prohibited by national laws and regulations. Once any of the above activities are discovered, HuaYoung has the right to immediately stop related services.',
  继续购买: 'Continue Buy',
  'BrightData（Luminata）是业界知名的IP代理服务提供商，总部位于以色列，号称拥有7200万动态住宅IP地址':
    'BrightData (Luminata) is a well-known IP proxy service provider in the industry. It is headquartered in Israel and claims to have 72 million dynamic residential IP addresses.',
  'NetNut是一家位于以色列的公司，号称拥有3000万个动态住宅IP和100万个静态住宅IP':
    'NetNut is an Israel-based company that claims to have 30 million dynamic residential IPs and 1 million static residential IPs',
  'Soax是一家位于英国的公司，号称在全球拥有850万个活跃的IP地址':
    'Soax is a UK-based company that claims to have 8.5 million active IP addresses worldwide',
  '位于立陶宛的一家公司，号称在195个不同城市提供具有超过1.02亿个IP的代理池':
    'A company based in Lithuania that claims to offer a proxy pool with over 102 million IPs in 195 different cities',
  '位于江苏徐州的一家国内公司，号称拥有覆盖220个国家9000万真实住宅IP资源':
    'A domestic company located in Xuzhou, Jiangsu, claims to have 90 million real residential IP resources covering 220 countries',
  购买公有云主机: 'Purchase a public cloud host',
  获取IP资源: 'Get IP resources',
  云上悦动为您推荐以下IP资源提供商:
    'HuaYoung recommends the following IP resource providers for you',
  '云上悦动与阿里云、腾讯云、AWS、Google合作推出的公有云主机，内置静态、独享的公网IP地址':
    'The public cloud host launched by HuaYoung in cooperation with Alibaba Cloud, Tencent Cloud, AWS, and Google has a built-in static and exclusive public IP address.',
  购买IP资源: 'Buy IP',
  IP值检测失败: 'IP value detection failed',
  正在为您检测IP最新值: 'Detecting the latest IP value for you',
  '您当前输入的IP修订值和检测值并不相符，确认要保存吗？':
    'The IP revision value you currently entered does not match the detected value. Sure to save it?',
  IP当前值: 'IP current value',
  IP检测值: 'IP detection value',
  IP修订值: 'IP revision value',
  请输入IP地址: 'Pls enter IP address',
  '静态IP的值一般而言是固定不变的，请确认是否要更新此IP的值':
    'Generally, the value of static IP is fixed. Pls confirm whether you want to update the value of this IP.',
  购买的公有云主机的出口IP值不会发生变化:
    'The export IP value of the purchased public cloud host will not change.',
  '公有云主机内置的是静态、独享的IP，其出口IP不会发生变化':
    'The public cloud host has a static and exclusive IP built-in, and its export IP will not change.',
  批量导入IP地址: 'Import IP In Batches',
  通过Excel文件批量导入IP地址: 'Import IP addresses in batches through Excel files',
  上传模板文件: 'Upload File',
  花漾Excel模板文件: 'HuaYoung Excel Template',
  '由花漾为您提供的基于Excel的模板文件，在里面填入IP的信息，然后上传此文件即可批量导入IP地址':
    'Fill in the Excel-based template file provided by HuaYoung, and then upload this file to import IP addresses in batches.',
  下载模板文件: 'Download Template File',
  通用txt文件: 'Universal Text File',
  '此类文件的格式为：代理服务器:端口:用户名:密码': 'Format: host:port:username:password',
  '诸如BrightData、WebShare等IP服务商通常会采用类似格式':
    'Most IP service providers adopt similar formats',
  粘贴文本: 'Paste Text',
  '文本的格式为：代理服务器:端口:用户名:密码': 'Format: host:port:username:password',
  多个以回车分隔: 'Multiple separated by line breaks',
  '正在解析文件内容，请稍候...': 'Parsing file content, pls wait...',
  请选择文件: 'Pls select file',
  txt文件内容为空: 'txt file content is empty',
  文本内容为空: 'Text content is empty',
  选择导入内容: 'Select Content',
  代理服务器: 'Proxy Server',
  用户名: 'User',
  协议: 'Protocol',
  一键设置协议: 'Set protocol with one click',
  一键设置连接方式: 'Set the connection method with one click',
  一键设置ipv6: 'Set up ipv6 with one click',
  是: 'Yes',
  否: 'No',
  一键设置动态IP: 'Set up dynamic IP with one click',
  请选择您要导入的IP: 'Pls select the IP you want to import',
  确认导入内容: 'Confirm',
  测试结果: 'Test Results',
  未获取到IP值: 'IP value not obtained',
  请确认您要导入的IP: 'Pls confirm the IP you want to import',
  '正在为您批量导入IP，请稍候...': 'IPs are being imported in batches for you, pls wait...',
  导入失败: 'Import Failed',
  导入成功: 'Import Successful',
  '，失败': ',fail',
  IP导入失败: 'IP Import Failed',
  修改成功: 'Successfully modified',
  批量设置自动续费: 'Set up automatic renewal in batches',
  'IP自动续费时，需从账户余额中扣除款项，请确保您的账户中余额充足':
    'When the IP automatically renews, the amount needs to be deducted from the account balance. Pls ensure that there is sufficient balance in your account.',
  自动续费策略: 'Auto Renewal Strategy',
  IP列表: 'IP List',
  自动续费: 'Auto Renewal',
  支出成本预估: 'Cost estimate',
  '元/续费周期': '¥/renewal period',
  'IP数量：': 'Number of IPs:',
  手动填写IP: 'Manually fill in IP',
  IP标签管理: 'IP Tags Mgmt',
  IP池标签管理: 'IP Pool Tags',
  不限归属地: 'Any Place',
  IP纯净度检查: 'Purity Check',
  时间: 'Time',
  最近1个月: 'Last month',
  最近3个月: 'Last 3 months',
  最近6个月: 'Last 6 months',
  检索: 'Search',
  没有找到相匹配的访问记录: 'No matching access record found',
  访问者: 'Visitor',
  当前团队: 'Current team',
  其他团队: 'Other Teams',
  访问时间: 'Start Time',
  什么是IP纯净度检查: 'What is IP purity check',
  '1、IP纯净度检查是指花漾依据自己的大数据系统，对指定IP在指定时间周期内访问过的所有网站的罗列，它能够比较有效的反应一个IP的纯净度':
    '1. IP purity check refers to HuaYoung’s list of all websites visited by a specified IP within a specified time period based on its own big data system. It can more effectively reflect the purity of an IP.',
  '2、凡是在花漾内购买的平台IP，均被纳入到花漾的IP监测大数据系统；不是在花漾内购买的IP，自导入花漾后也将被纳入到花漾的IP监测大数据系统':
    '2. All platform IPs purchased within HuaYoung will be included in HuaYoung’s IP monitoring big data system; IPs not purchased within HuaYoung will also be included in HuaYoung’s IP monitoring big data system after being imported into HuaYoung. data system',
  '3、出于信息安全与数据隐私的考虑，针对某个网站的访问，我们仅会记录访问的域名与访问者归属的组织与时间，并不会记录具体的访问路径，更不会存储任何会话数据':
    '3. For information security and data privacy considerations, for a visit to a website, we will only record the domain name visited and the organization and time the visitor belongs to. We will not record the specific access path, nor will we store any sessions. data',
  IP性质: 'Properties',
  续费设置: 'Renewal Settings',
  地理位置: 'Location',
  IP属性: 'Attributes',
  请输入备注: 'Pls enter remarks',
  使用范围: 'Scope',
  备注: 'Notes',
  经纬度格式不合法: 'The latitude and longitude format is invalid',
  '确定要更改IP归属地吗？': 'Sure to change the IP location?',
  'IP归属地将影响到所在时区与默认语言，并影响到绑定在同一分身上的浏览器指纹实例的动态属性”语言“、”经纬度“与”时区“，请确认您要做IP归属地的修订':
    'IP location will affect the time zone and default language, and will affect the dynamic attributes "language", "latitude and longitude" and "time zone" of the fp instance bound to the same avatar. Pls confirm you want to change the IP location',
  同步更改时区设置: 'Synchronously change time zone settings',
  同步更改语言设置: 'Change language settings simultaneously',
  同步更改经纬度: 'Synchronously change latitude and longitude',
  '确定要更改IP的时区吗？': 'Sure to change the time zone of your IP?',
  '一般而言，IP的时区是跟归属地紧密相关的，请确认是否要继续修订时区属性':
    'Generally, the time zone of an IP is closely related to its location. Pls confirm whether you want to continue to modify the time zone attribute.',
  '确定要更改IP的语言吗？': 'Sure to change the IP language?',
  '一般而言，IP的语言是跟归属地紧密相关的，请确认是否要继续修订语言属性':
    'Generally, the language of IP is closely related to the place of ownership. Pls confirm whether you want to continue to modify the language attribute.',
  '确定要更改IP的经纬度吗？': 'Sure to change the latitude and longitude of your IP?',
  '一般而言，IP的经纬度是跟归属地紧密相关的，请确认是否要继续修订经纬度属性':
    'Generally, the longitude and latitude of an IP are closely related to the location. Pls confirm whether you want to continue to modify the longitude and latitude attributes.',
  'IP的地理位置来源于花漾的“IP地理位置库”，受限于IP地理位置库的精度，系统无法保证每个IP归属地的准确性，如发觉存在较大出入，可自行指定IP的地理位置，请注意，这并不会影响到所绑分身的安全性':
    'The IP location comes from HuaYoung\'s "IP Location Database". Limited by the accuracy of the IP location database, the system cannot guarantee the accuracy of the location of each IP. If there is a large discrepancy, you can specify the IP location yourself. Geographical location, Pls note that this will not affect the security of the bound avatar.',
  请选择时区: 'Pls select a time zone',
  请选择语言: 'Pls select language',
  性质: 'Nature',
  会话保持: 'IP-Hold',
  '会话保持是指能够通过参数来控制IP值的动态切换，需要供应商明确是否支持以及支持的方式':
    'IP-hold refers to the ability to control the dynamic switching of IP values through parameters. The supplier needs to clarify whether it supports it and how it is supported.',
  不支持: 'CanNot',
  实例规格: 'Instance specifications',
  不可远程: 'Not available remotely',
  商品类别: 'Product Category',
  云厂商: 'Cloud vendors',
  供货商: 'supplier',
  不适用: 'Not Applicable',
  '确定要由按周续费变更为按月续费吗？': 'Sure to change from weekly renewal to monthly renewal?',
  按周费用: 'Weekly Fee',
  '/周': '/Week',
  按月费用: 'Monthly Fee',
  '/月': '/Mon',
  立即续费: 'Renew Now',
  用户取消操作: 'Cancels Operation',
  '自有IP导入至花漾且采用直连方式时不收取任何费用，但如果采用花漾接入点连接并产生了加速流量，会按照实际产生的加速流量扣除花瓣':
    'There is no charge when importing your own IP to HuaYoung and using the direct connection. However, if you use a HuaYoung accel channels and generate accelerated traffic, the tokens will be deducted according to the actual traffic generated.',
  '计价周期为“周”的公有云主机，可以变更续费周期为“月”从而节省成本；一旦开启自动续费请确保账户余额充足':
    'For public cloud hosts with a pricing cycle of "weekly", you can change the renewal cycle to "monthly" to save costs; once automatic renewal is turned on, Pls ensure that the account balance is sufficient',
  永久免费: 'Free',
  按实际产生的接入点加速流量扣取花瓣:
    'Tokens are deducted based on the actual access point acceleration traffic.',
  '0元': '0 ¥',
  计价周期: 'Cycle',
  列表价格: 'List Price',
  购买时间: 'Purchase',
  当前IP已经绑定在此分身: 'The current IP has been bound to this avatar',
  根据名称检索: 'Search by name',
  '将同一个{{type}}绑定在多个分身上请注意是否会引起分身关联':
    'If you bind the same {{type}} to multiple avatars, pls pay attention to whether it will cause the avatars to be related.',
  '我们不建议您将同一个IP同时绑定到多个{{type}}分身':
    'We do not recommend that you bind the same IP to multiple {{type}} avatars at the same time.',
  当前IP尚未绑定分身: 'The current IP has not been bound',
  当前分身: 'Current avatar ',
  当前未绑定: 'Not currently bound',
  历史绑定: 'History',
  分身绑定状态: 'Binding',
  秒: ' sec',
  小时: ' hours',
  已: 'already',
  未: 'Not',
  到期: 'Maturity',
  长期有效: 'Long Term',
  成本: 'Cost',
  IP价格: 'Price',
  持有时长: 'Holding period',
  '正在更新IP接入点...': 'Updating IP access point...',
  已超额: 'Already exceeded',
  已用: 'Used ',
  网络质量: 'Quality',
  已用流量: 'Used Traffic',
  '错误日志：': 'Error log:',
  代理服务连接失败: 'Proxy service connection failed',
  花漾客户端所在位置: 'HuaYoung App Location',
  '出口IP：': 'Exit IP:',
  '地理位置：': 'Location:',
  不支持测速: 'Does not support speed test',
  花漾灵动接入点: 'HuaYoung access point',
  '域名：': 'domain name:',
  '位置：': 'Location:',
  未获取到IP详情信息: 'IP details not obtained',
  正在进行质量测试: 'Quality testing in progress',
  '当前IP正处于准备状态，请稍候...': 'The current IP is in preparation status, pls wait...',
  IPGO已停止: 'IPGO has stopped',
  IPGO已被卸载: 'IPGO has been uninstalled',
  IP状态异常: 'Abnormal IP status',
  IPGO运行正常: 'IPGO is running OK',
  代理协议连接异常: 'Proxy Protocol Exception',
  代理协议检测正常: ' Proxy Protocol OK',
  只能在IP允许的连接方式内进行质量测试:
    'Testing can only be performed within the conns allowed by IP',
  更改IP连接方式: 'Change IP Conn',
  暂不支持: 'Not Supported',
  '新版本特性，当前版本暂未支持，请检查更新':
    'New version features are not supported by the current version, Pls check for updates',
  访问质量测试: 'Quality Testing',
  不支持在网页下进行IP质量测试: 'IP quality testing under the web page is not supported',
  只有在花漾客户端中才能够进行IP质量测试:
    'IP quality testing can only be performed in HuaYoung app',
  个分身: ' browser',
  至今: 'Up to Now',
  绑定: 'Bind ',
  绑定时间: 'Binding',
  访问次数: 'Visits',
  解绑时间: 'Unbinding',
  未解绑: 'Not Unbind',
  查看分身详情: 'View Details',
  次访问: ' visits',
  间隔: 'interval',
  绑定新的分身: 'Bind New',
  'IP流量包含自身流量以及通过接入点连接时所产生的加速流量，可在IP流量日志中查看每一笔流量的支出细节':
    'IP traffic includes its own traffic and accelerated traffic generated when connecting through the accel channels. The expenditure details can be viewed in Traffic Logs.',
  '自有IP不受流量配额的限制，只有当自有IP通过接入点连接产生加速流量时才会扣除花瓣':
    'Self-owned IP is not subject to traffic quota restrictions. Tokens will be deducted only when generates accelerated traffic through the accel channels.',
  '平台IP在购买周期内可免费使用的流量称之为流量配额，超过配额时将按照实际超额流量扣除花瓣':
    'The traffic that the platform IP can use for free during the purchase period is called the traffic quota. When the quota is exceeded, the tokens will be deducted according to the actual excess traffic.',
  配额内已用流量: 'Used traffic within quota',
  公有IP: 'Public IP',
  会话打开失败: 'Session opening failed',
  '请打开SSH终端，并输入以下命令：': 'Pls open an SSH terminal and enter the following command:',
  脚本已复制到剪切板: 'Script copied to clipboard',
  请妥善保管您的主机密码: 'Pls keep your host password safe',
  '我们需要您的私钥文件，才能够帮您获取到主机密码：':
    'We need your private key file to help you obtain the host password:',
  请输入私钥: 'Pls enter private key',
  可直接将私钥文件粘贴在此: 'You can directly paste the private key file here',
  无密码: 'No password',
  '主机尚未就绪，请稍后再获取密码': 'The host is not ready yet, pls wait to get the password.',
  获取密码: 'Get password',
  '私钥文件已下载，请妥善保存': 'The private key file has been downloaded, Pls keep it properly',
  '通过平台购买的独享主机，共享同一份密钥对，因此，下载后的密钥文件请您妥善保存,':
    'Exclusive hosts purchased through the platform share the same key pair, so Pls keep the downloaded key file properly.',
  '下载没有开始？': 'Download not starting?',
  下载私钥文件: 'Download private key file',
  下载远程桌面文件: 'Download remote desktop files',
  '由于Mac系统限制,需要您手动输入密码':
    'Due to Mac system limitations, you need to enter your password manually',
  远程访问: 'Remote Access',
  请在花漾客户端中访问: 'Pls visit in HuaYoung app',
  立即访问: 'Visit now',
  '远程访问VPS/云主机': 'Remote access VPS',
  '关于远程访问VPS/云主机': 'About remote access to VPS',
  '1、您购买的平台IP本质上是绑定在VPS/云主机之上的IP地址':
    '1. The platform IP you purchased is essentially the IP address bound to the VPS.',
  '2、为保证IP地址的独享性与纯净性，这台VPS/云主机只归您所独有，同时，我们强烈建议您长期持有这台云主机，以避免由于VPS/云主机被释放而导致绑定的IP地址失效':
    '2. In order to ensure the exclusivity and purity of the IP address, this VPS is only yours. At the same time, we strongly recommend that you hold this cloud host for a long time to avoid being released due to the VPS. As a result, the bound IP address becomes invalid.',
  '3、在这台云主机中安装了我们为您提供的IP代理软件“花漾IPGO”，请注意不要轻易卸载或停止IPGO服务':
    '3. The IP proxy software "HuaYoung IPGO" we provide you is installed on this cloud host. Pls be careful not to uninstall or stop the IPGO service easily.',
  '4、我们为您提供这台云主机的用户名和密码，您可以远程登录这台云主机':
    '4. We provide you with the username and password of this cloud host. You can log in to this cloud host remotely.',
  '当前IP所在主机不是可远程主机，无法远程桌面':
    'The host where the current IP is located is not a remote host and cannot be remotely desktop.',
  '您可以购买平台为您提供的独享IP，它们具备远程桌面的能力':
    'You can purchase exclusive IPs provided by the platform, which have remote desktop capabilities',
  '为IP“': 'for IP',
  知道: 'Know',
  自有IP无需续费: 'No need to renew your own IP',
  '如果自有IP采用接入点连接方式，会产生网络加速流量从而带来花瓣的消耗，因此，请注意保证团队的花瓣余额充足':
    "If your own IP uses access point connection, network acceleration traffic will be generated, which will consume tokens. Therefore, Pls pay attention to ensuring that the team's token balance is sufficient.",
  '该IP正在转让给其它团队，无法执行任何操作':
    'The IP is being transferred to another team and no operations can be performed',
  属性: 'Attributes',
  绑定分身: 'Bind',
  网络加速: 'Acceleration',
  质量测试: 'Testing',
  'IP描述：--': 'IP description: --',
  操作轨迹: 'Operation Track',
  将本IP导入到平台: 'Import this IP to the platform',
  从平台购买本IP: 'Purchase this IP from the platform',
  '设置IP：': 'Set IP:',
  未做修改: 'Unmodified',
  '将本IP与分身【': 'Combine this IP with the avatar【',
  '】绑定': '】Binding',
  '】解绑': '】Unbind',
  '分身【': 'avatar【',
  '】绑定本IP': '】Bind this IP',
  '】解绑本IP': '】Unbind this IP',
  '本IP地址由【': 'This IP address is provided by [',
  '】变更为【': '】changed to【',
  '】切换为【': '】Switch to【',
  购买平台为您提供的IP资源: 'Buy IP',
  购买平台IP资源: 'Buy IP',
  '我们为您提供覆盖全球主要城市的IP资源，您可按需购买':
    'We provide you with cloud hosts covering major cities around the world.',
  '公有云主机由花漾与AWS、阿里云、Google云等公有云厂商合作推出，网络质量稳定，百兆带宽起步，性价比较高，是花漾的首选推荐':
    'The public cloud host is in cooperation with public cloud vendors such as AWS, Alibaba, and Google Cloud. The network quality is stable, and the price-performance ratio is high. It is your first choice.',
  '适用场景：适用于某些账号（如电商、社媒）的长期运营':
    'Scenarios: Suitable for long-term operations of certain accounts (such as e-commerce, social media).',
  '使用限制：不适用于某些敏感账号（如电商、支付等）的注册等场景':
    'Restrictions: Not applicable to scenarios such as registration of certain sensitive accounts.',
  '花漾为您提供遍布全球主要城市的公有云主机（含静态IP资源）':
    'HuaYoung provides you with public cloud hosts (including static IP resources) located in major cities around the world.',
  已有IP资源: 'Already Have IP',
  '如果您已经采购了其它IP服务商的资源，您可以将其导入至花漾中以供使用':
    'If you have IP resource, you can import them into HuaYoung',
  导入IP地址: 'Import IP',
  '如果您已有IP地址，可将其导入至花漾，支持Socks5、Http、SSH等代理协议':
    'If you already have an IP, you can import it to HuaYoung, which supports proxy protocols such as Socks5, HTTP, and SSH.',
  立即导入: 'Import Now',
  您可以基于txt文本文件或者Excel文件将多个IP地址一次性批量导入至花漾:
    'You can import multiple IP into HuaYoung in batches at one time based on text or Excel files.',
  批量导入: 'Batch Import',
  创建IP池: 'Create IP Pool',
  'IP池是指由若干IP地址组成的池子，常用于国内IP提供商，它们允许用户以API的形式批量提取IP地址':
    'An IP pool refers to a pool composed of several IP. It is commonly used by domestic IP providers. They allow users to extract IP in the form of APIs.',
  '自己已经拥有了IP资源，可将其导入至花漾中':
    'If you already have IP resources, you can import them into HuaYoung',
  '已有VPS/云主机': 'Have a VPS',
  麦可隆浏览器迁移工具: 'HuaYoung Migration Tool',
  '麦可隆能够将VPS/云主机的浏览器环境无缝迁移至':
    'HuaYoung Migration Tool can migrate the browser environment of VPS,',
  '中，包括主机浏览器的指纹信息、Cookie数据、网站密码等，并可指定是否在主机中安装IPGO以便将主机的IP地址导入至花漾中':
    " including the fingerprint, cookie data, website password, etc., and you can specify whether to install IPGO on the host so that the host's IP can be imported into HuaYoung",
  '是否创建/导入分身？': 'Want to create/import a browser avatar?',
  '需要先创建分身才能开启麦可隆迁移，是否立即创建/导入分身？':
    'You need to create a browser avatar before you can start migration. Create the browser avatar immediately?',
  分身克隆: 'Clone',
  花漾IPGO: 'HuaYoung IPGO',
  'IPGO是云上悦动团队自主研发的针对VPS/云主机的IP代理服务程序，只需在VPS/云主机中安装IPGO，即可将VPS/云主机的IP地址一键导入至花漾中':
    'IPGO is an IP proxy program for VPSs independently developed by HuaYoung. You only need to install IPGO in the VPS, and you can import the IP of the VPS into HuaYoung with one click.',
  '添加VPS/云主机IP': 'IPGO',
  '已有VPS/云主机，可将其IP地址导入至花漾中':
    'If you already have a VPS, you can import its IP into HuaYoung.',
  处于准备或转让状态的IP不能删除: 'IPs in preparation or transfer status cannot be deleted',
  '当前IP正处于准备中或者转让中的状态，无法删除':
    'The current IP is in the state of being prepared or transferred and cannot be deleted.',
  '确定要删除选择的IP吗?': 'Sure to delete the selected IP?',
  '自有IP一旦删除，将自动切断所有绑定此IP的分身的绑定关系，除非用户手动恢复':
    'Once a self-owned IP is deleted, the binding relationships of all avatars bound to this IP will be automatically cut off unless the user manually restores it.',
  质量: 'Quality',
  '购买的平台IP不允许删除，到期后会自动回收；如果希望长期持有此IP，请注意开启自动续费功能，并确保拥有充足的账户余额':
    'The purchased platform IP is not allowed to be deleted and will be automatically recycled after expiration; if you want to hold this IP for a long time, Pls turn on the automatic renewal function and ensure that you have sufficient account balance.',
  IP列表字段自定义: 'IP list field customization',
  依据IP进行检索: 'Search by IP',
  类型: 'Type',
  请先选中1至多个IP: 'Pls select 1 or more IPs first',
  '您选中的都是自有IP，无需续费':
    'The IPs you selected are all self-owned IPs, no renewal is required.',
  批量续费: 'Batch Renewal',
  批量接入点修改成功: 'Batch access point modification successful',
  平台购买的IP不可以删除: 'IP purchased on the platform cannot be deleted',
  平台购买的IP到期后会自动删除:
    'The IP purchased by the platform will be automatically deleted after expiration.',
  可复用: 'Reusable',
  丢弃: 'throw away',
  按最小流量分配: 'Allocate according to minimum flow',
  按负载分配: 'Distribution by load',
  顺序分配: 'sequential allocation',
  随机分配: 'Randomly assigned',
  需要时生产: 'Produce when needed',
  手工生产: 'Handmade production',
  为空时生产: 'Produced when empty',
  不支持的IP池供应商: 'Unsupported IP pool provider',
  '不同的IP池供应商会有不同的IP获取机制，您可将您的IP池供应商反馈给花漾的在线客服，花漾会在1个工作日之内完成对您的供应商的适配':
    'Different IP pool providers will have different IP acquisition mechanisms. You can feedback your IP pool provider to HuaYoung’s online customer service, and HuaYoung will complete the adaptation to your provider within 1 working day.',
  当前供应商推荐使用海外接入点: 'Current vendors recommend using overseas access points',
  当前供应商推荐使用直连或国内接入点:
    'Current vendors recommend using direct connect or domestic access points',
  高级设置: 'Advanced',
  是否独占访问: 'Exclusive access',
  独占式: 'Exclusive',
  提取方式: 'Extrac Method',
  '如果IP服务位于海外，建议使用花漾海外接入点；如果IP服务位于国内，建议使用客户端或花漾国内接入点；如果IP服务商要求采用白名单且需要唯一的访问IP时，则只能使用您的客户端IP（您的客户端IP是唯一的）':
    'If the IP service is located overseas, it is recommended to use HuaYoung overseas access point; if the IP service is located in China, it is recommended to use the client or HuaYoung domestic access point; if the IP service provider requires a whitelist and requires a unique access IP, then Only use your client IP (your client IP is unique)',
  '（取决于IP自身）': '(depends on the IP itself)',
  客户端: 'App',
  基本设置: 'Basic',
  IP池名称: 'Pool Name',
  请输入IP池名称: 'Pls enter the IP pool name',
  请选择供应商: 'Pls select a supplier',
  需要在花漾客户端内完成IP导入操作: 'The IP import operation needs to be completed in HuaYoung app',
  IP批量导入: 'IP batch import',
  IP池创建完毕后开启IP批量导入向导:
    'After the IP pool is created, start the IP batch import wizard.',
  '您可将分身绑定在IP池，当访问分身以打开花漾浏览器时，系统会从IP池中申请一个IP绑定到该分身上，IP池适用于通过API提取IP地址的服务商':
    'You can bind the browser to the IP pool. When you open HuaYoung browser, the system will apply for an IP from the IP pool and bind it to it. The IP pool is suitable for service providers that extract IP through APIs.',
  请输入: 'Pls enter',
  引用已有IP: 'Reference existing IP',
  '您可将当前团队已有IP地址导入至IP池，这并不会影响到被导入IP与其它分身的绑定关系':
    'You can import existing IP addresses of the current team into the IP pool. This will not affect the binding relationship between the imported IP and other browsers.',
  已引用到当前IP池: 'Referenced to the current IP pool',
  '请下载模板文件，在里面填入希望导入的IP信息，然后再上传此文件即可批量导入（目前仅支持Socks5、Http等代理协议）':
    'Pls download the template file, fill in the IP information you want to import, and then upload this file to batch import (currently only Socks5, HTTP and other proxy protocols are supported)',
  'files/IP池批量导入导出IP模板.xlsx': 'files/IP pool batch import and export IP template.xlsx',
  '正在解析Excel文件，请稍候...': 'Parsing Excel file, pls wait...',
  主机: 'Host',
  端口: 'Port',
  获取时间: 'Get Time',
  '当前IP正在使用中，无法删除': 'The current IP is in use and cannot be deleted',
  '当前IP正在被其它分身所使用，只有当解除使用关系后方可删除':
    'The current IP is being used by other browsers and can only be deleted after the use relationship is terminated.',
  确定要删除该IP吗: 'Sure to delete this IP?',
  '如果是引用自IP列表的，仅删除引用，不会删除原有IP':
    'If it is referenced from the IP list, only the reference will be deleted and the original IP will not be deleted.',
  过期时间: 'Expiration',
  '描述：--': 'describe:--',
  IP生产策略: 'IP production strategy',
  IP分配策略: 'IP allocation strategy',
  IP回收策略: 'IP recycling strategy',
  IP生命时长: 'IP life',
  IP池属性: 'IP pool properties',
  当前IP池不支持测试: 'The current IP pool does not support testing',
  基于IP列表的IP池无需测试: 'IP pool based on IP list does not require testing',
  只有连接方式中包含至少一个接入点才支持测试:
    'Testing is only supported if the connection method contains at least one access point',
  '确定要对此IP池进行测试吗？': 'Sure to test this IP pool?',
  '测试内容为尝试获取一个IP，尽管并不会用这个IP来访问网站，但如果此IP是短效IP，意味着有可能产生浪费':
    'The test content is to try to obtain an IP. Although this IP will not be used to access the website, if the IP is a short-lived IP, it means that waste may occur.',
  正在尝试获取IP: 'Trying to get IP',
  IP获取成功: 'IP obtained successfully',
  '获取的IP为：': 'The obtained IP is:',
  IP获取失败: 'Failed to obtain IP',
  '错误信息为：': 'The error message is:',
  测试: 'test',
  刷新IP状态: 'Refresh IP status',
  '当前有IP正在使用中，无法删除': 'There is an IP currently in use and cannot be deleted.',
  '当前有IP正在被其它分身所使用，只有当解除使用关系后方可删除':
    'There is currently an IP that is being used by another avatar. It can only be deleted after the use relationship is terminated.',
  '确定要删除指定的IP地址吗？': 'Sure to delete the specified IP address?',
  '当IP地址从IP池删除后，只能通过批量导入再次导入至IP池，如果是引用自IP列表的，仅删除引用，不会删除原有IP':
    'After the IP address is deleted from the IP pool, it can only be imported into the IP pool again through batch import. If it is referenced from the IP list, only the reference will be deleted and the original IP will not be deleted.',
  '正在批量删除IP，请稍候...': 'Deleting IPs in batches, pls wait...',
  池容量: 'Pool capacity',
  '确定要删除指定的IP池吗？': 'Sure to delete the specified IP pool?',
  请先选中1至多个IP池: 'Pls select 1 or more IP pools first',
  '正在删除IP池，请稍候': 'Deleting IP pool, pls wait.',
  基本属性: 'Basic',
  相关策略: 'Related strategies',
  相关策略更详细的介绍请阅读: 'For a more detailed introduction to related strategies, Pls read',
  IP池策略介绍: 'IP pool strategy introduction',
  '如果供应商返回的IP数据支持过期时间，则优先使用过期时间。':
    'If the IP data returned by the provider supports expiration time, the expiration time will be used first.',
  指定时长: 'Specify ',
  请选择您要绑定的IP池: 'Pls select the IP pool you want to bind to',
  绑定IP池: 'Bind IP Pool',
  当前正在绑定该IP池: 'The IP pool is currently being bound',
  缺少可用的IP池: 'Missing available IP pool',
  '当前团队尚未创建IP池，您需要先创建IP池':
    'The current team has not created an IP pool. You need to create an IP pool first.',
  查看IP池: 'View IP pool',
  'IP池名称为4-32个字符': 'IP pool name is 4-32 characters',
  通过您的客户端直接访问IP地址: 'Access the IP address directly through your client',
  '您当前的客户端IP地址为：': 'Your current client IP address is:',
  '入口IPv4：': 'Ingress IPv4:',
  '入口IPv6：': 'Ingress IPv6:',
  '入口位置：': 'Entrance location:',
  '出口IPv4：': 'Exit IPv4:',
  '出口IPv6：': 'Exit IPv6:',
  '出口位置：': 'Exit location:',
  关于IP白名单: 'About IP whitelist',
  '如果您的IP服务商已开启白名单，请将下述IP加入白名单列表':
    'If IP provider turned on the whitelist, add the following IPs.',
  显示IPv4: 'Show IPv4',
  显示IPv6: 'Show IPv6',
  局域网: 'LAN',
  临期: 'impending',
  过期: 'Expired',
  '错误提示为：': 'The error message is:',
  '您购买的IP正处于“准备”状态，预期还需要几分钟的时间才能够就绪，请稍候':
    'The IP you purchased is in the "Ready" state. It is expected to take a few minutes to be ready. Pls wait.',
  '当前IP服务已过期，无法正常使用':
    'The current IP service has expired and cannot be used normally.',
  '当前IP已失效，': 'The current IP has expired.',
  '我们建议的处理措施为：若排除服务器自身及网络问题，请检查IPGO是否正常运行，如无法运行可以':
    'Our recommended handling measures are: If you exclude problems with the server itself and the network, Pls check whether IPGO is running normally. If it cannot run, you can',
  重新安装IPGO: 'Reinstall IPGO',
  '当前IP（': 'Current IP (',
  '代理自有IP）已失效，': "Agent's own IP) has expired,",
  '我们建议的处理措施为：请在': 'Our recommended handling measures are: Pls',
  IP设置: 'IP Settings',
  中检查代理配置信息是否正确: 'Check whether the proxy configuration information is correct',
  '当前IP服务即将到期，请您及时续费，否则将无法正常使用':
    'The current IP service is about to expire. Pls renew in time, otherwise it will not be able to be used normally.',
  指定城市过滤: 'Filter by specified city',
  您可以选择购买指定的城市的IP地址: 'You can choose to purchase an IP address in a specified city',
  '请选择您希望购买的IP所在的城市：':
    'Pls select the city where the IP you wish to purchase is located:',
  重复点击: 'Repeat click',
  '应国家网络监管部门要求，所有的公有云厂商要求公有云主机购买/使用者必须完成实名认证；实名认证信息仅用于公有云厂商的资质要求':
    'In response to the requirements of the national network regulatory authorities, all public cloud vendors require purchasers/users of public cloud hosts to complete real-name authentication; the real-name authentication information is only used for the qualification requirements of public cloud vendors.',
  拟购商品名称: 'Product name to be purchased',
  拟购时长: 'Proposed purchase time',
  优惠折扣: 'Discount',
  合计应付: 'Total payable',
  IP用途过滤: 'IP usage filtering',
  '某些平台会限制公有云IP的正常访问（特别是用来注册账号时），这些限制会时常变化，我们依据于经验为您过滤不适合访问指定平台的公有云厂商，当您无法确定时，建议按周购买':
    'Some platforms will restrict normal access to public cloud IPs (especially when used to register accounts). These restrictions will change from time to time. Based on our experience, we will filter out public cloud vendors that are not suitable for accessing the designated platform. When you are not sure, Recommended to buy by the week',
  '请选择您购买IP用来访问的平台：': 'Pls select the platform you purchased the IP to access:',
  您可以选择只购买指定的公有云厂商的IP地址:
    'You can choose to only purchase IP addresses from specified public cloud vendors',
  '请选择您希望购买的公有云厂商：': 'Pls select the public cloud vendor you wish to purchase:',
  '高性价比IP：其所在主机的操作系统为Linux，价格非常优惠，适用于入门级用户，性价比极高':
    'Highly cost-effective IP: The operating system of the host is Linux, the price is very favorable, suitable for entry-level users, and the price-performance ratio is extremely high',
  '高并发IP：其所在主机的操作系统也为Linux，但配置较高，且能够模拟WindowsTCP指纹，流量配额较高，适用于企业级用户':
    'High-concurrency IP: The operating system of the host is also Linux, but the configuration is higher, and it can simulate WindowsTCP fingerprints, the traffic quota is higher, and it is suitable for enterprise-level users',
  '可远程IP：其所在主机的操作系统为Windows，配置高，支持Windows远程桌面，配额流量非常充足，适用于经常访问视频类网站或对远程桌面有诉求的用户':
    'Remote IP: The operating system of the host is Windows, the configuration is high, it supports Windows remote desktop, and the quota traffic is very sufficient. It is suitable for users who frequently visit video websites or have demands for remote desktop.',
  '流量配额极高，适用于访问短视频等对流量有较大需求的网站':
    'The traffic quota is extremely high and is suitable for accessing short videos and other websites that have a large demand for traffic.',
  '1个月': '1 month',
  '3个月': '3 months',
  '6个月': '6 months',
  '12个月': '12 months',
  '您尚未完成实名认证，需要实名认证后才能够购买公有云主机':
    'Real-name authentication is required before you can purchase public cloud host.',
  中东: 'Middle East',
  '1周': '1 week',
  请登录后进行购买: 'Pls log in to purchase',
  当前无可售卖的云主机: 'There are currently no cloud hosts for sale',
  使用用途: 'Purpose',
  不限用途: 'Any',
  指定用途: 'Useage',
  '已为您过滤掉不适于访问“': 'Has been filtered out and is not suitable for access ',
  '”平台的公有云厂商': ' cloud vendor',
  公有云厂商: 'Vendors',
  不限厂商: 'No Restrictions',
  指定厂商: 'Specify',
  商品规格: 'Specs',
  地域: 'Area',
  '国家/地区': 'Country/Region',
  不限城市: 'No City Limit',
  '通过按周购买以降低您的试错成本，按周购买的商品可随后切换至按月购买以降低长期持有成本':
    'Reduce your trial and error costs by purchasing weekly, which can then be switched to monthly purchases to reduce long-term holding costs',
  '支持按月购买，一次性购买多月有优惠。':
    'Monthly purchase is supported, and there are discounts for multiple-month purchases at one time.',
  商品列表: 'Product List',
  不适用平台: 'Not applicable to the platform',
  '注：不适用平台是指该IP地址可能无法正常访问指定的平台网站，此类知识源于经验的积累，未必准确，仅供参考':
    'Note: Not applicable to the platform means that the IP address may not be able to access the designated platform website normally. This kind of knowledge comes from the accumulation of experience and may not be accurate. It is for reference only.',
  所在城市: 'City',
  '配额流量是指IP在其购买周期内可免费使用的流量总额，超过流量配额时将按照实际超额的流量乘以超额流量单价扣除一定数量的花瓣':
    'Quota traffic refers to the total amount of traffic that an IP can use for free during its purchase cycle. When the traffic quota is exceeded, a certain number of tokens will be deducted based on the actual excess traffic multiplied by the unit price of the excess traffic.',
  '当使用的流量总额超过流量配额时，系统会按照实际超额的流量乘以超额流量单价扣除一定数量的花瓣；其中，1元可购买100花瓣（购买花瓣较多时会有优惠），150花瓣意味着1.5元/GB':
    'When the total amount of traffic used exceeds the traffic quota, the system will deduct a certain number of tokens based on the actual excess traffic multiplied by the unit price of the excess traffic; among them, 1 ¥ can purchase 100 tokens (there will be a discount when purchasing more tokens), 150 tokens means 1.5 ¥/GB',
  拟购IP数量: 'Amout',
  注册并加入: 'Register & Join',
  登录并加入: 'Log in & Join',
  '您当前以其它的身份登录了花漾系统，需要注销后才能够完成后续工作':
    'You are currently logged into the HuaYoung system with a different identity. You need to log out to complete subsequent tasks.',
  立即注销: 'Log Out Now',
  您已成功加入团队: 'Successfully Joined the Team',
  进入团队看看: 'Enter the Team',
  您的登录账号: 'Login Account',
  邀请人: 'Inviter',
  拟加入团队: 'Team to Join',
  '8-40个字符，需至少包含以下三类：大写字母、小写字母、数字、特殊符号':
    '8-40 chars and must contain 3 of the upper/lower-letters, numbers, and special symbols such as !@#$%^&*()',
  请确认密码: 'Pwd confirm',
  两次输入的密码不一致: 'The passwords inconsistent',
  '高级查询：': 'Advanced:',
  高级查询: 'Advanced',
  请至少输入一个查询条件: 'Pls enter at least one query condition',
  全部操作类型: 'All Types',
  操作者ID: 'Operator ID',
  全部团队成员: 'All Members',
  全部状态: 'All Status',
  日志详情: 'Log details',
  IP管理日志: 'IP Mgmt Logs',
  操作类型: 'Oper Type',
  添加方式: 'Add method',
  将: ' will ',
  '】添加至平台': '】Add to platform',
  IP地域: 'IP region',
  '购买位置为【': 'The purchase location is [',
  '】的【': '】of【',
  '个月】': 'months]',
  '将【': 'Will【',
  '】续费【': '】Renewal【',
  '设置【': 'set up【',
  '】为': '】for',
  设置内容: 'Set content',
  分身平台: 'avatar platform',
  '将IP绑定至【': 'Bind IP to【',
  '将IP从【': 'Change the IP from [',
  将IP: 'will IP',
  从平台中删除: 'Remove from platform',
  '分身绑定IP【': 'Avatar binds IP【',
  '分身解绑IP【': 'Unbind IP by avatar【',
  原IP: 'Original IP',
  新IP: 'New IP',
  '分身将IP【': 'The avatar will IP【',
  '】更换为【': '】replaced with【',
  异常信息: 'Exception information',
  添加自有IP: 'Import IP',
  续费IP: 'Renew IP',
  解绑分身: 'Unbind',
  分身更换IP: 'Change IP',
  删除IP: 'Delete IP',
  "操作者ID='": "Oper ID='",
  "操作类型='": "Oper Type='",
  "访问日期在'": "Visited on '",
  "至'": "to'",
  之间__1: 'between',
  并且: 'And',
  未支付: 'unpaid',
  '生产失败（': 'Production failed (',
  待生产: 'To be produced',
  日志ID: 'Log ID',
  会话访问ID: 'Session access ID',
  IP池ID: 'IP pool ID',
  IP角色: 'IP role',
  主IP: 'Main IP',
  副IP: 'Vice IP',
  IP上行流量: 'Upstream',
  IP下行流量: 'Downstream',
  配额内流量: 'Traffic Quota',
  花瓣消耗: 'Token Consumption',
  访问分身: 'Visits',
  请检查流量日志ID是否正确: 'Pls check whether the traffic log ID is correct',
  流量日志ID: 'Traffic Log ID',
  '流量日志ID：': 'Traffic Log ID:',
  会话访问日志ID: 'Session Log ID',
  上行流量: 'Upstream',
  下行流量: 'Downstream',
  总流量: 'Total',
  "流量日志ID='": "Traffic Log ID='",
  "操作日期在'": "The Operation Date'",
  客户端版本: 'Version',
  地址位置: 'Location',
  '系统会根据操作者登录时的IP地址来查询出其登录的地理位置，受限于IP库的精准程度，为您提供的地理位置仅供参考':
    "The system will query the geographical location of the operator's login based on the IP address when the operator logs in. Limited by the accuracy of the IP database, the geographical location provided to you is for reference only.",
  登录结果: 'Results',
  登录时间: 'Login Time',
  "客户端IP='": "Client IP='",
  访问日期: 'Visit Date',
  操作分身: 'Operation ',
  '】分身【': ']browser[',
  '】导入至平台': ']Import to the platform',
  访问策略: 'Access Policy',
  关联支付平台: 'Related payment platform',
  关联邮箱平台: 'Associated email platform',
  跳过敏感操作: 'Skip Sens. Oper.',
  分身访问录屏: 'Screen Recording',
  分身密码: 'Password',
  '设置分身【': 'Set up [',
  '添加授权用户：': 'Authorized Users:',
  '取消授权用户：': 'Unauthorize User:',
  '添加授权分身：': 'Add Avatar:',
  '取消授权分身：': 'Cancel Authorized :',
  原设置: 'Original Settings',
  新设置: 'New Settings',
  '通过【': 'pass[',
  '】访问分身【': ']Visit [',
  '将分身【': 'Will [',
  '】从平台【': ']From the platform[',
  '】删除': '】delete',
  受让团队ID: 'Transferee team ID',
  受让团队名称: 'Transferee team name',
  受让人昵称: 'Assignee nickname',
  受让人手机: 'Transferee’s mobile phone',
  '将分身转让给【': 'Transfer the avatar to [',
  解绑IP: 'Unbind IP',
  设置分身: 'Settings',
  安全策略设置: 'Security Policy',
  浏览器指纹设置: 'FP Settings',
  授权分身: 'Avatars',
  删除分身: 'Delete',
  "分身ID='": "Avatar ID='",
  '，等': ',wait',
  分身ID: 'ID',
  操作形态: 'Operation Mode',
  手工访问: 'Manual Access',
  主IPID: 'Primary IPID',
  副IPID: 'Secondary IPID',
  主IP下行总流量: 'Main IP Downstream',
  主IP加速流量: 'Main IP accelerates traffic',
  副IP下行总流量: 'Secondary IP Downstream',
  副IP加速流量: 'Secondary IP accelerates traffic',
  是否录像: 'Whether to record',
  未录像或已清理: 'Not recorded or cleared',
  访问日志ID: 'Access log ID',
  '日志ID：': 'Log ID:',
  本机IP: 'Local IP',
  播放录像: 'Play Video',
  查看录像: 'View Video',
  清理录像: 'Clean Video',
  '确定要清理此录像文件吗？': 'Sure to clean this video file?',
  '录像文件一旦被清理将无法恢复，也可通过设置审计录像自动清理策略对录像文件进行自动清理':
    'Once the video files are cleaned, they cannot be recovered. You can also automatically clean the video files by setting the audit video automatic cleaning policy.',
  '录像文件数量：': 'Number of video files:',
  '录像文件大小：': 'Video file size:',
  '录像文件状态：': 'Video file status:',
  '已锁定（不会被自动清理）': 'Locked (will not be automatically cleaned)',
  '未锁定（将会被自动清理）': 'Unlocked (will be automatically cleared)',
  '录像文件状态：已清理': 'Video file status: Cleaned',
  已清理: 'Cleaned',
  '录像文件大小：未录像': 'Video file size: not recorded',
  '录像文件状态：--': 'Video file status: --',
  未录像: 'Not recorded',
  邀请者: 'Inviter',
  加入时间: 'Join Time',
  被踢出: 'kicked out',
  主动退出: 'Take the initiative to exit',
  退出者: 'quitters',
  退出时间: 'Exit time',
  退出原因: 'Reason for exit',
  转移时间: 'transfer time',
  新超管: 'New SuperAdmin',
  新团队名称: 'New team name',
  修改时间: 'Modified',
  成员加入审核: 'Join Aduit',
  更换邀请链接: 'Change Invitation Link',
  成员邀请: 'Invitation',
  修改内容: 'Modify Content',
  添加时间: 'Added Time',
  部门名称: 'Depart Name',
  上级部门: 'Higher Depart',
  旧部门名称: 'Old Depart Name',
  新部门名称: 'New Depart Name',
  '【无】': '[None]',
  编辑时间: 'Edit Time',
  编辑成员: 'Edit Members',
  编辑内容: 'Edit Content',
  '团队昵称变更为【': 'The team nickname is changed to [',
  '角色变更为【': 'The role is changed to [',
  所属部门变更为: 'The department is changed to',
  赋予: 'Bestow',
  撤销: 'Cancel',
  授权成员: 'Authorized',
  授权功能: 'Authorization function',
  创建团队: 'Create Team',
  退出团队: 'Quit Team',
  转让团队: 'Transfer',
  修改团队信息: 'Modify Team',
  修改邀请设置: 'Invitation Settings',
  添加部门: 'Create Dept',
  编辑部门: 'Edit Dept',
  删除部门: 'Delete Dept',
  成员分身授权: 'Avatar Authorization',
  成员功能授权: 'Function Authorization',
  成员交接分身: 'Avatars Handover',
  编辑云账户: 'Cloud Account',
  编辑功能屏蔽页面: 'Edit Function Block',
  暂停: 'Pause',
  播放: 'Play',
  '播放速度：': 'Play Speed:',
  缩放至原始尺寸: 'Original Size',
  缩放至与窗口匹配尺寸: 'Fit Window',
  缩放: 'Zoom',
  退出全屏: 'Exit Full Screen',
  进入全屏: 'Full Screen',
  录像文件已清理: 'Video files have been cleaned',
  本次访问未进行录像: 'This visit was not recorded',
  操作者暂停录像: 'Operator pauses recording',
  '，停止时长：': ', stop duration:',
  s秒: 'seconds',
  后即将播放开始下一段录像: 'After that, the next video will be played and started.',
  立即播放: 'Play Now',
  允许跳过敏感操作: 'Allow sensitive operations to be skipped',
  不允许跳过敏感操作: 'Sensitive operations are not allowed to be skipped',
  '客户端所在时区：': 'Client time zone:',
  '代理IP所在时区：': 'Time zone of proxy IP:',
  访问信息: 'Access Info',
  持续时长: 'Duration',
  '客户端IP指打开花漾灵动所在主机的IP，并非花漾灵动在访问分身时的代理IP':
    'The client IP refers to the IP of the host where HuaYoung is opened, not the proxy IP of HuaYoung when accessing the avatar.',
  分身信息: 'Avatar Info',
  IP信息: 'IP information',
  当地时区: 'local time zone',
  指纹信息: 'Fingerprint Info',
  '录像-': 'Video-',
  未填写暂停原因: 'Reason for suspension not filled in',
  进入全屏失败: 'Failed to enter full screen',
  退出全屏失败: 'Failed to exit full screen',
  '是否确定下载录像？': 'Sure to download the video?',
  '当前的录像文件由若干个视频片断组成，如果要下载录像，我们将为您重新渲染成一个视频文件，这意味着将消耗您当前主机的CPU/GPU等计算资源能力，耗时较长，请确认是否继续':
    "The current video file consists of several video clips. If you want to download the video, we will re-render it into a video file for you, which means that it will consume your current host's CPU/GPU and other computing resources and take a long time. Pls Confirm whether to continue",
  找不到可下载的视频文件: 'Downloadable video file not found',
  正在为您生成视频文件: 'Video file is being generated for you',
  '视频文件渲染中，请不要关闭当前查看录像的窗口，否则将导致视频渲染失败':
    'While the video file is rendering, Pls do not close the window currently viewing the video, otherwise the video rendering will fail.',
  '下载录像（未生成）': 'Download Video (NotGenerated)',
  渲染中: 'Rendering',
  '下载录像（已生成）': 'Download Video (Generated)',
  访问记录: 'Access Records',
  操作者已停止录像: 'The operator has stopped recording',
  后即将播放开始本次访问中的下一段录像: 'The next video in this interview will be played soon.',
  进入监管: 'Enter Supervision',
  退出监察: 'Exit Monitoring',
  开始访问: 'Start Visiting',
  结束访问: 'End Visit',
  '访问者：': 'Visitor:',
  '监管者：': 'Supervisor:',
  开始录像: 'Start Recording',
  停止录像: 'Stop Recording',
  恢复录像: 'Restore Video',
  暂停录像: 'Pause Recording',
  结束录像: 'End Recording',
  '恭喜，实名认证已通过': 'Congratulations, the real-name authentication has passed',
  '感谢您对我们的信任，我们将一如继往的为您提供优秀的产品与服务':
    'Thank you for your trust in us, we will continue to provide you with excellent products and services',
  '现在，您可以': 'Now you can',
  这个页面了: 'This page',
  '实名认证失败，错误信息为：': 'Real-name authentication failed, the error message is:',
  流程名称: 'Name',
  流程名称重复: 'Duplicate process name',
  适用平台: 'Platforms',
  至少选择一个平台: 'Choose at least one platform',
  版本号: 'Version',
  流程备注: 'Notes',
  流程适用平台: 'Process applicable platform',
  '为流程定义指定平台，在流程执行时能够更好的筛选合适的浏览器分身':
    'Specify the platform for the proc script to better select suitable browser browsers when the process is executed.',
  流程属性: 'Process Properties',
  新建浏览器流程定义: 'New Browser Process',
  新建手机APP流程定义: 'New Mobile Process',
  '您可以从头新建一个流程定义，也支持通过本地文件导入、好友分享，或者通过花漾RPA生态市场获取您需要的流程定义':
    'You can create a new proc script, import it through local files, share it with friends, or get the proc script through HuaYoung RPA market.',
  浏览器自动化流程: 'Browser Proc Script',
  手机App自动化流程: 'Mobile App Proc Script',
  从RPA市场获取流程: 'Get Proc Script From Market',
  通过好友共享获得流程: 'Get Proc Script From Friends',
  '通过对一个浏览器分身进行流程定义的编辑，流程发布后，可同时对多个浏览器分身同时执行流程':
    'By editing the proc script on a HuaYoung browser, after the process script is published, the process script can be executed on multiple browsers at the same time.',
  '适用场景：基于浏览器的Web应用的自动化':
    'Scenarios: automation of browser-based web applications',
  '操控 Android 手机 APP 应用': 'Auto control Android applications',
  '适用场景：需要操控手机上的APP，如微信、钉钉、企业微信等场景':
    'Scenarios: where you need to control APPs on your mobile phone, such as WeChat, DingTalk, WeCom, etc.',
  '花漾灵动为您提供的官方RPA流程市场，可根据不同的业务场景选择适合的流程':
    'The official RPA process market provided by HuaYoung allows you to choose the appropriate script according to different business scenarios.',
  '需要提醒您的是，部分流程定义是收费的（扣除一定数量的花瓣），但绝大部分流程都以免费、开源的形态提供，您可以获取这些流程的源码，再根据自己的需要进行简单的修订即可匹配您的需求':
    'Please note that some process script incur charges (deducting tokens), while most processes are open source and free. You can read their source code and customize them as needed.',
  '您的好友可将他的流程定义分享给您，通过桥接码即可获得流程定义':
    'Your friend can share his proc script with you, and you can get the proc script through a bridge code.',
  '请注意，获得流程时需要了解流程定义是否可读，以及当流程版本升级时是自动更新还是手动更新':
    'Pls note that when get the proc script, you need to know whether the script is readable and whether it will be updated automatically or manually when the version is upgraded.',
  新建流程定义: 'New Process Script',
  基于花漾指纹分身浏览器构建自动化流程定义: 'Build automated proc script based on HuaYoung browser',
  '通过RPA市场获取流程定义，请注意，部分流程定义是收费的':
    'Get proc scripts through the RPA market. Pls note that some proc scripts are chargeable.',
  通过桥接码获取您的好友共享给您的流程定义:
    'Get the proc script shared by your friends through a bridge code',
  绑定客户端: 'Bind client',
  '一张券同时只能绑定在一台客户端；当客户端绑定流程任务卡后，在该客户端中执行RPA流程将不再扣除花瓣':
    'A coupon can only be bound to one client at a time; when the client binds the process task card, tokens will no longer be deducted when executing the RPA process in that client.',
  拟绑定的客户端设备: 'Client device to be bound',
  '（本机）': '(native)',
  流程分享: 'Process sharing',
  '此流程定义由他人分享给您，请您确认此流程定义来源的可信性，避免因为执行此流程定义而给您的数据资产带来风险与损失':
    'This proc script was shared with you by others. Pls confirm the credibility of the source of this proc script to avoid risks and losses to your data assets due to the execution of this proc script.',
  分享者: 'Sharer',
  来源团队: 'Source team',
  '（团队标识：': '(Team ID:',
  流程定义内容: 'Process script content',
  '可执行：接受分享的用户（您）只允许执行此流程定义，无法阅读流程定义的内容，也无法转发分享给其它人员':
    'Executable: The user who accepts the sharing (you) is only allowed to execute this proc script, and cannot read the content of the proc script, nor forward and share it to other people.',
  '可读：接受分享的用户（您）不仅允许执行此流程定义，还可以另存为新的流程定义，这也意味着可以在新的流程中基于分享的流程进行修改':
    'Readable: The user who accepts the sharing (you) is not only allowed to execute this proc script, but can also save it as a new proc script, which also means that modifications can be made based on the shared process in the new process.',
  可读: 'Readable',
  可执行: 'Executable',
  流程定义更新: 'Process script update',
  '手动更新：当流程分享方更新流程定义时，接受分享的用户（您）可以自行决定是否升级；':
    'Manual update: When the process sharing party updates the proc script, the user who accepts the sharing (you) can decide whether to upgrade;',
  '自动更新：当流程分享方更新流程定义时，接受分享的用户（您）将自动获得流程定义的最新版本':
    'Automatic update: When the process sharing party updates the proc script, the user who accepts the sharing (you) will automatically get the latest version of the proc script.',
  自动更新: 'Automatic',
  手动更新: 'Manual',
  流程定义分组: 'Proc Script Grouping',
  '当流程定义分组为“包含”时，您在接收流程定义后，系统会自动创建相应的流程定义分组':
    'When the proc script group is "Include", the system will automatically create the corresponding proc script group after you receive the proc script.',
  包含: 'Include',
  共分享: 'shared',
  个流程: 'process',
  请输入流程定义名称: 'Pls enter a proc script name',
  指定版本新建流程: 'Specify version new process',
  开启鼠标轨迹: 'Enable Mouse Tracking',
  '开启鼠标轨迹是指在浏览器中可以实时显示鼠标的移动与点击轨迹，请注意，这样会消耗一定的系统资源':
    'Turning on mouse tracks means that the movement and click tracks of the mouse can be displayed in real time in the browser. Pls note that this will consume a certain amount of system resources.',
  开启审计录像: 'Turn on Audit Recording',
  '最终是否开启审计录像受分身的安全策略决定，分身首先要开启安全策略；如果在安全策略中约定为“强制录像”，即便此处不开启最终也会进行审计录像；如果在安全策略中约定为“由用户选择”，则是否录像由此处决定；如果在安全策略中约定为“不允许录像”，即便在此处开启也无法录像':
    'Whether to turn on audit recording is ultimately determined by the avatar\'s security policy. The avatar must first turn on the security policy; if it is agreed to be "forced recording" in the security policy, audit recording will eventually be performed even if it is not turned on; if it is agreed to be "forced recording" in the security policy; "Selected by the user", then whether to record or not is determined here; if it is agreed in the security policy that "recording is not allowed", even if it is turned on here, recording will not be possible.',
  '需要提醒您的是，如果流程在云端执行，那么实时查看的前提是：分身必须要进行审计录像':
    'We need to remind you that if the process is executed in the cloud, the prerequisite for real-time viewing is that the avatar must conduct audit recording.',
  未绑定IP的浏览器分身不允许在云端执行:
    'Browsers without bound IP are not allowed to execute in the cloud',
  '当前选中的部分分身没有绑定IP，一旦云端执行意味着会使用云端主机的IP地址，这可能会对您的分身带来安全风险':
    'Some of the currently selected avatars are not bound to IP. Once executed in the cloud, the IP address of the cloud host will be used, which may bring security risks to your avatars',
  继续: 'Continue',
  并发数量: 'Conc-Exec',
  '并发数量在1～100之间': 'Between 1 and 100',
  并发间隔: 'Interval',
  '并发间隔在5～3600秒之间': 'Between 5 and 3600 seconds',
  当分身的浏览器已打开时: 'When Browser Open',
  当分身的流程执行结束后: 'When Proc Task Ends',
  关闭浏览器: 'Close Browser',
  事件通知: 'Event',
  审计策略: 'Audit',
  创建计划: 'Create Proc Plan',
  '流程计划是指将流程定义与分身进行适度编排，可自动、可手动的予以定期执行':
    'Arrangement of proc scripts and browser avatars, which can be executed automatically or manually',
  选择流程与分身: 'Selection Script & Browsers',
  阅读流程说明: 'Read the proc script description',
  控制台流程: 'Console',
  常规流程: 'Normal',
  计划名称: 'Plan Name',
  请输入计划名称: 'Pls enter plan name',
  已存在相同名称的计划: 'A plan with the same name already exists',
  任务命名规则: 'Task Naming',
  '为尽可能的让每个生成的流程任务拥有一个不重复且易区分的任务名称，您可在流程任务命名规则中使用变量，可包含以下变量：':
    'To ensure that each generated process task has a unique and easily distinguishable task name as much as possible, you can use variables in the process task naming rules, which can include the following variables:',
  '{date}：yyyyMMdd（日期）': '{date}:yyyyMMdd (date)',
  '{datetime}：yyyyMMddHHmmss（时间）': '{datetime}:yyyyMMddHHmmss (time)',
  '{index}：随机生成一个serialNumber': '{index}: Randomly generate a serialNumber',
  分身列表: 'Browsers List',
  请至少选择一个分身: 'Pls select at least one avatar',
  '该流程的分身策略为动态指定分身，在初始子流程中决定拟在哪些分身中执行此流程':
    'The avatar strategy of this process is to dynamically specify browsers. In the initial subprocess, it is decided in which browsers the process is to be executed.',
  设置流程输入变量: 'Set Input Variables Value',
  该流程定义并未指定任何输入变量: 'The proc script does not specify any input variables',
  切换至为每个分身输入相同值: 'Same Value for Each',
  切换至为每个分身输入不同值: 'Diff Value for Each',
  _输入变量: '_Input Variables',
  设置计划类型: 'Set Plan Type',
  设置执行策略: 'Set Execution Strategy',
  创建任务计划成功: 'Task plan created successfully',
  周一: 'Monday',
  周二: 'Tuesday',
  周三: 'Wednesday',
  周四: 'Thursday',
  周五: 'Friday',
  周六: 'Saturday',
  周日: 'Sunday',
  自动计划: 'Automatic Plan',
  循环计划: 'Loop Plan',
  触发计划: 'Triggered plan',
  手动计划: 'Manual Plan',
  生成共享桥接码: 'Generate Shared Bridge Code',
  桥接码有效时长: 'Code Validity',
  流程内容: 'Proc Content',
  '可执行：接受分享的用户只允许执行此流程定义，无法阅读流程定义的内容，也无法转发分享给其它人员':
    'Executable: users who accept sharing are only allowed to execute this proc script. They cannot read the content of the proc script, and cannot forward and share it to other people.',
  '可读：接受分享的用户不仅允许执行此流程定义，还可以另存为新的流程定义，这也意味着可以在新的流程中基于分享的流程进行修改':
    'Readable: users who accepts the sharing is not only allowed to execute this proc script, but can also save it as a new proc script, which also means that modifications can be made based on the shared process in the new process.',
  流程更新: 'Proc Update',
  '手动更新：当您的流程定义有新版本时，接受分享的用户可以自行决定是否升级；':
    'Manual update: When there is a new version of your proc script, the users who accept the sharing can decide whether to upgrade;',
  '自动更新：当您的流程定义有新版本时，您可以主动将所有接受分享的用户进行流程定义的自动升级':
    'Automatic update: When there is a new version of your proc script, you can proactively upgrade the proc script to all users who accept sharing.',
  分组信息: 'Group Info',
  '如果您希望将分组信息一并共享出去，请选择“包含”，此时流程定义接收者收到的流程定义将带有分组信息':
    'If you want to share the grouping information together, Pls select "Include". At this time, the proc script received by the proc script recipient will have the grouping information.',
  '桥接码有效时长默认30分钟；将桥接码分享给您的好友后，他们可以按照约定的规则获得此流程定义':
    'The bridge code is valid for 30 minutes by default and the longest is 24 hours (1440 minutes); after sharing the bridge code with your friends, they can obtain this proc script according to the agreed rules.',
  '正在为您生成流程定义共享桥接码，请稍候...':
    'Generating proc script shared bridge code for you, pls wait...',
  '您的好友共享给您一个流程定义，请在“新建流程定义”对话框中点击“好友共享”，并输入以下桥接码：':
    'Your friend has shared a proc script with you. Pls click "Get Proc via Friend Share" and enter the following bridge code:',
  '注：此桥接码': 'Note: This bridge code ',
  分钟之内有效: ' mins valid',
  请复制上述文字并将其发送给您的目标好友:
    'Pls copy the above text and send it to your target friends',
  单行文本输入框: 'Single line text input box',
  按钮: 'button',
  多行文本输入框: 'Multi-line text input box',
  标题: 'title',
  元素类型: 'Type',
  页面节点: 'Page Node',
  设置元素映射: 'Set element mapping',
  流程新建成功: 'Process created successfully',
  流程定义必须在编辑状态时才允许恢复到指定版本:
    'The proc script must be in the editing state to allow recovery to the specified version',
  '您需要将流程定义重新进入编辑状态，才允许恢复到指定的历史版本':
    'You need to re-enter the editing state of the proc script to be allowed to restore to the specified historical version.',
  请关闭该流程定义的编辑器: 'Pls close the editor of this proc script',
  '该流程定义的编辑器已打开，为避免误操作，请关闭该流程定义的编辑器再执行恢复操作':
    'The editor of the proc script has been opened. To avoid misoperation, Pls close the editor of the proc script and then perform the recovery operation.',
  该流程定义是个空流程: 'The proc script is an empty process',
  '尚未对该流程定义编辑任何节点内容，无法恢复':
    'No node content has been edited for this proc script and cannot be restored',
  '确定要恢复到指定的历史版本吗？': 'Sure to revert to the specified historical version?',
  '系统将获取指定版本的流程定义内容，并覆盖当前内容，请确认是否继续':
    'The system will obtain the specified version of the proc script content and overwrite the current content. Pls confirm whether to continue.',
  '已成功将流程定义恢复到“': 'Successfully restored proc script to ',
  '”版本': 'Version',
  '您可以查看流程定义的历史版本，并随时获取指定版本的流程定义的具体内容':
    'You can view historical versions of the proc script and obtain the specific content of the specified version of the proc script at any time.',
  发布日期: 'Release Date',
  发版说明: 'Release Notes',
  将该流程恢复到指定版本: 'Revert the process to a specified version',
  另存为新的流程定义: 'Save as new proc script',
  查看发版说明: 'View release notes',
  获取历史版本信息: 'Historical Versions',
  请选择分组: 'Pls select a group',
  '流程个数：': 'Number of processes:',
  '版本号：': 'version number:',
  流程发版说明: 'Process Release Instructions',
  该流程定义暂未添加流程说明: 'This proc script has no process description added yet',
  流程说明: 'Description',
  该流程定义已过期: 'The proc script has expired',
  '该流程定义已过期，您需要续费才能够更新版本':
    'The proc script has expired. You need to renew to update the version.',
  '确定要更新该流程定义的版本吗？': 'Sure to update the version of this proc script?',
  同步升级当前团队内包含此流程定义的流程计划:
    'Upgrade the proc plan containing this script in the current team',
  处于已发布状态下的流程定义不可编辑: 'Proc script in the published state cannot be edited.',
  '当流程定义处于“已发布”状态时，无法对流程定义进行编辑操作，除非将该流程定义重新进入编辑状态':
    'When the proc script is in the "Published" state, the proc script cannot be edited unless the proc script is re-entered into the editing state.',
  重新编辑: 'Re-Edit',
  查看桥接码: 'View Bridge Code',
  创建新的共享: 'New Share',
  退还共享: 'Return Share',
  禁止共享: 'No Sharing',
  '来源于好友共享或者RPA市场引用的流程定义，无法共享':
    'Process scripts shared by friends or referenced by the RPA market cannot be shared.',
  '可将流程来源为个人新建、文件导入、RPA市场复制的流程定义共享给您的好友':
    'You can share proc scripts with your friends.',
  还没有团队接收过此流程: 'No team has received this process yet',
  分享状态: 'Share Status',
  接收时间: 'Receiving Time',
  停止共享: 'Stop Sharing',
  '确定要停止与所选团队的共享状态吗？': 'Sure to stop sharing status with the selected team?',
  '一旦停止与所选团队的流程共享，该流程定义将从所选团队中移除，且无法再次执行此流程定义':
    'Once process sharing with the selected team is stopped, the proc script will be removed from the selected team and cannot be executed again',
  流程来源: 'Proc Source',
  接收人: 'Receiver',
  共享团队名称: 'Shared Team',
  共享团队标识: 'Shared team ID',
  分享方式: 'Share Method',
  流程当前版本: 'Current Ver',
  流程最前版本: 'Latest Ver',
  更新: 'Renew',
  获取人: 'Acquirer',
  获取方式: 'Method',
  流程最新版本: 'Latest version',
  创建人: 'Creator',
  执行策略: 'Exec Strategy',
  分身策略: 'Browser Strategy',
  共享状态: 'Shared Status',
  共享桥接码列表: 'Bridge Code',
  刷新: 'Refresh',
  '当前处于有效期内的桥接码：': 'Valid bridge code:',
  接收码: 'Code',
  接受码: 'Code',
  距失效尚余时长: 'Time Remaining',
  '确定要删除该桥接码吗？': 'Are you sure to delete this code?',
  '删除后，将无法恢复，其它用户无法再通过该桥接码获取您分享的流程':
    'Once deleted, it cannot be restored, and other users can no longer obtain the process.',
  分享详情: 'Share details',
  停止分享: 'Stop sharing',
  接受团队名称: 'Accept team name',
  接受团队标识: 'Accept team ID',
  分享时间: 'Share time',
  创建新的流程定义: 'New Definition',
  '引用源流程意味着您以只读方式获取此流程定义，好处是可以随着该流程定义的版本升级自动获得最新的版本，通常用于您只希望执行此流程定义而不需要查看或者编辑此流程定义的场景':
    'Referencing the source process means that you obtain the proc script in read-only mode, you can automatically obtain the latest version as the version of the proc script is upgraded. ',
  引用源流程: 'Reference source process',
  '复制并生成一份新的流程定义意味着您可以查看甚至编辑此流程定义的源码，但如果源流程定义的版本升级后，无法自动获取流程定义的升级（但可以获取并创建另外一个新的流程定义），通常用于您希望阅读或者编辑此流程定义的场景':
    'You can view and even edit the source code of this proc script, but if the source proc script is upgraded, you cannot automatically obtain the upgrade of the proc script (but you can obtain and create another new proc script)',
  '该RPA流程仅可执行，无法复制': 'This proc script is only executable and cannot be replicated',
  复制并生成一份新的流程定义: 'Copy and generate a new proc script',
  进入编辑状态: 'Begin editing',
  流程分组: 'Groups',
  导入好友分享流程: 'Import Sharing Proc Script',
  无法获得自己共享的流程定义: 'Unable to get shared proc script by yourself',
  '流程定义只能共享给其它团队，无法在当前团队内共享':
    'Process scripts can only be shared with other teams.',
  '请输入好友发给您的流程定义共享桥接码：': 'Pls enter the proc script sharing bridge code:',
  文件来源定义: 'From file',
  文件来源: 'file',
  至少要选择最少一种文件来源: 'At least one file must be selected',
  本地文件: 'Local Files',
  个人云盘: 'Personal Cloud Drive',
  文件扩展名: 'File Extension',
  '多个扩展名用英文逗号分隔，如：xlsx,csv': "Multiple separated by ',', such as: xlsx,csv",
  缺省值: 'Default Value',
  '请输入初始值，通过换行分隔': 'Pls enter initial values, separated by newlines',
  '请选择默认选中的值，为空则选中第1个':
    'Pls select the value selected by default. If it is empty, select the first one.',
  '请选择默认选中的值，可为空': 'Pls select the default value, can be empty',
  单选框列表: 'Radio button list',
  下拉列表: 'Drop-Down List',
  定义序列内容: 'Sequence Content',
  序列内容: 'Content',
  可选序列内容: ' content',
  '可选序列内容，通过换行分隔': ' optional content, separated by newline',
  缺省值并未包含在序列内容中: 'Default value is not included in the sequence content',
  缺省值并未全部包含在序列内容中: 'Default values are not all included in the sequence content',
  该流程没有定义输入变量: 'The process has no input variables defined',
  变量: 'Variable',
  值: 'Value',
  '无法解析为数组，请检查输入内容': 'Unable to parse as array, Pls check',
  该流程定义未设置输入变量: 'The proc script has no input variables',
  '参数模板.xlsx': 'Parameter template.xlsx',
  通过Excel文件传递输入变量: 'Pass input variables through Excel file',
  '（请下载模板文件再在文件中录入参数':
    '(Pls download the template file and enter the parameters in it',
  固定变量: 'fixed variable',
  '（针对每个分身都采用以下输入值': '(Take the following input values for each',
  运行预览: 'Preview',
  '请设置输入变量的值：': 'Pls set the value of the input variable:',
  推荐: 'Recommend',
  '获取方式分为“可读”与“可执行”两种方式，可读包含可执行；当为可读时，意味着您不仅可以执行此流程定义，可以获取该流程定义的源码':
    'The acquisition method is "Readable" or "Executable". Readable means you can not only execute this proc script, but also obtain the source code of it.',
  '支付方式分为“免费”与“收费”两种方式，当为收费方式时，需要扣除一定的花瓣，并在指定时间段内有效（周、月或者永久授权）':
    'Payment methods is "Free" or "NotFree". NotFree means a certain amount of tokens needs to be deducted and is valid within a specified period of time (weekly, monthly or permanent authorization)',
  花瓣每周: ' tokens weekly',
  花瓣每月: ' tokens monthly',
  花瓣永久授权: ' tokens perpetual license',
  正在为您查询流程状态: 'Querying process status',
  '如有需要，您可以重新获取此流程定义': 'If necessary, you can re-obtain this proc script',
  '如有需要，您可以重新获取此流程定义（无需再次扣除花瓣）':
    'You can re-obtain this proc script if needed (no need to deduct tokens again)',
  当前团队已经拥有此流程定义: 'The current team already has this proc script',
  '确定要获取选定的流程定义吗？': 'Are you sure to get the selected proc script?',
  '正在为您购买...': 'Buying for you...',
  花瓣起: ' tokens',
  出品方: 'Producer',
  版本: 'Version ',
  依据RPA流程名称检索: 'Search by name',
  未找到相关内容: 'No relevant content found',
  TikTok店铺: 'TikTok Store',
  教程示例: 'Tutorials',
  其它类别: 'Others',
  RPA市场: 'RPA Market',
  '执行任务：': 'Run tasks:',
  RPA任务启动成功: 'RPA task started successfully',
  '您正在执行的流程定义是控制台流程，是否为您进入任务详情页面并为您打开此流程任务的控制台窗口？':
    'The proc script you are executing is a console process. Will you enter the task details page and open the console window?',
  'RPA任务已经下发至指定的运行设备，并开始运行':
    'The RPA task has been delivered to the designated device and started running.',
  '温馨提示：由于花漾RPA拟人化执行，在流程执行期间请不要移动您的鼠标，以避免流程执行出错':
    'Warm reminder: Due to the anthropomorphic execution of HuaYoung RPA, Pls do not move your mouse during process execution to avoid errors.',
  打开控制台: 'Open the console',
  查看流程任务: 'View process tasks',
  RPA任务启动失败: 'RPA task startup failed',
  '正在下发RPA任务，请稍候...': 'RPA tasks are being distributed, pls wait...',
  重新执行: 'Re-exec',
  '以指定的流程输入变量值重新生成一笔新的流程任务，方便您排错':
    'Re-exec a new task with the specified values to facilitate your troubleshooting',
  '拟执行的{{target}}': '{{target}} to be executed',
  执行设备: 'Device',
  '拟使用以上流程变量执行本次任务，可以': 'Use the above variables values, or you can',
  重新设置: 'Reset',
  执行流程计划: 'Exec Proc Plan',
  立即执行: 'Execute',
  当前团队花瓣余额不足: 'Teams token balance is insufficient',
  当前团队花瓣余额小于: 'Teams token balance is less than',
  '，无法创建流程任务，请补充新的花瓣再次尝试':
    ', the task cannot be created, Pls buy new tokens and try again',
  '温馨提示：您可以实时查看云端执行情况，也可进入任务详情查看细节信息':
    'Warm reminder: You can view the cloud execution status in real time, or enter the task details to view detailed information.',
  已成功下发了一条RPA流程任务: 'A process task has been successfully started',
  执行计划失败: 'Execution plan failed',
  '是否要在本台设备上执行此计划？': 'Do you want to run this plan on this device?',
  '该计划约定在“': 'The plan is stipulated in ',
  '”设备上执行，请选择您拟执行此计划的设备：':
    'To execute on the device, Pls select the device on which you plan to execute this plan:',
  '（请确保该设备已开机并正常登录）':
    '(Pls make sure the device is powered on and logged in normally)',
  '（当前设备）': '(current device)',
  '跳过（不执行流程）': 'Skip(Dont Execute)',
  关闭浏览器重新打开: 'Close Browser and Reopen It',
  在现有浏览器实例中执行流程: 'Execute in the Browser Instance',
  不关闭浏览器: "Don't close browser",
  当前团队被限制只允许在指定的设备中执行RPA流程:
    'The current team is restricted to only executing RPA processes on designated devices.',
  '当前团队被花漾伙伴限制只能在指定的设备中执行RPA流程，请和您的伙伴联络以获取进一步信息':
    'The current team is restricted by HuaYoung partners to only perform the RPA process in designated equipment. Pls contact your partner for further information.',
  任务: 'Task',
  执行任务: 'Exec Proc Tasks',
  '选择一个流程，并在指定的分身或手机上执行自动化流程':
    'Select a proc script and execute it on the specified browsers or mobile',
  '确定要取消任务吗？': 'Sure to cancel the task?',
  '一旦取消任务，刚才所做的所有设置都将丢失':
    'Once the task is canceled, all settings just made will be lost.',
  任务_: 'Task_',
  '控制台流程会同时打开所有浏览器，请谨慎选择分身':
    'The console process will open all browsers at the same time, Pls choose the avatar carefully.',
  当前团队不允许在本地设备执行RPA流程任务:
    'The current team is not allowed to execute RPA process tasks on local devices',
  已成功创建并下发了一条RPA流程任务在执行的云端设备中执行:
    'An RPA process task has been successfully created and delivered to the executing cloud device.',
  取消任务: 'Cancel',
  任务准备就绪: 'Task Ready',
  '任务准确就绪，点击立即执行将为您创建任务并导航至流程任务中以查看任务的执行情况':
    'The task is ready, click Execute now to create the task for you and navigate to the process task to view the detail of the task',
  添加分身: 'Add Browser',
  选择分身: 'Choose',
  '该分身没有绑定指纹模板，无法访问':
    'This avatar is not bound to a fingerprint template and cannot be accessed.',
  '该分身没有绑定指纹实例，无法访问':
    'The avatar is not bound to the fingerprint instance and cannot be accessed.',
  请设置流程输入变量: 'Pls set process input variables',
  '分身“': 'Browsers',
  '”参数未设置': 'Parameter not set',
  '并发数量指的是同时打开的浏览器数量，该数量和运行设备的性能紧密相关，一般而言，4核的CPU请不要超过10，8核的CPU请不要超过20':
    'the num of concurrency refers to the browsers opened at the same time. This number is closely related to the performance of the running device. Generally, a 4-core CPU should not exceed 10, and an 8-core CPU should not exceed 20.',
  '云端执行时，并发数量为1且不可调整，意味着每个分身依次串行':
    'When executed in the cloud, the num of concurrency is 1 and cannot be adjusted, which means that each avatar is serialized in sequence.',
  '并发间隔指的是打开一个新的浏览器的间隔时长，并发间隔过短极易造成运行设备的瞬间负载过高并导致失败，最小值为5秒，最大值为3600秒':
    'The concurrency interval refers to the interval between opening a new browser. If the concurrency interval is too short, it can easily cause the instant load of the running device to be too high and lead to failure. The minimum value is 5 seconds and the maximum value is 3600 seconds.',
  '一旦启用事件通知，当该流程计划创建的流程任务执行结束后，会在当前团队内推送一笔消息，该消息包含指定的消息ID，其它触发计划可以监听此消息ID并决定是否触发执行':
    'Once event notification is enabled, when the process task created by the process plan is executed, a message will be pushed within the current team. The message contains the specified message ID. Other triggered plans can monitor this message ID and decide whether to trigger execution.',
  流程结束: 'End After',
  请输入消息发送延时: 'Pls enter the message sending delay',
  '分钟后发送事件：': 'mins, Send Event:',
  '请输入事件ID，最长36个字符': 'Pls enter event ID, up to 36 characters',
  请输入事件ID: 'Pls enter event ID',
  开启网页截屏: 'Enable Screenshots',
  '网页截屏只对浏览器窗口进行截屏，截屏文件会占据您的存储空间，并进而会影响到团队花瓣的消费':
    'Web page screenshots only take screenshots of the browser window. The screenshot files will occupy your storage space and thus affect the consumption of Team Tokens.',
  每个流程节点执行完毕后自动截屏: 'Take Screenshots After Node Executed',
  只有流程节点执行失败才截屏: 'Screenshots will only be taken if the node fails to execute.',
  '关于花漾灵动RPA（自动化机器人）': 'About HuaYoung RPA (Aautomated Robot)',
  '1.花漾灵动RPA能够为您大幅提升运营效率':
    '1. HuaYoung RPA can greatly improve your operational efficiency',
  '2.当前团队没有可执行的流程定义，无法执行流程':
    '2. The current team does not have an proc script.',
  '3.建议您到花漾灵动RPA市场中检索您需要的流程':
    '3. It is recommended that you search the process script you need in HuaYoung RPA Market',
  访问流程市场: 'Goto Market',
  为每个分身使用相同的输入变量值: 'Use the same input variable value for each avatar',
  为不同分身使用不同的输入变量值: 'Use different input variable values for different avatar',
  '执行此流程定义需要指定以下输入变量，如需要为':
    'To execute this proc script, you need to specify the following variables values.',
  不同分身指定不同的值: ' or different browsers specify different values.',
  每个分身指定相同的值: ' or specify the same value for each avatar',
  '，请': ' ',
  切换输入方式: 'Switch Input Method',
  '确定要更新流程到最新版本吗？': 'Sure to update the process to the latest version?',
  '流程更新后，将在下一次计划执行开始生效，执行中的计划不受影响':
    'After the process is updated, it will take effect from the next plan execution, and the plan in execution will not be affected.',
  更新时间: 'Update Time',
  创建: 'Create',
  计划类型: 'Plan Type',
  执行时间: 'Exec Time',
  重复日期: 'Repeat Date',
  每天: 'Every Day',
  间隔时间: 'Intervals',
  失败策略: 'Failure',
  不再循环执行: 'Stop Loop Execution',
  继续循环执行: 'Continue Loop Execution',
  触发类型: 'Trigger Type',
  目录: 'Dirs',
  文件类型: 'File Type',
  监控事件: 'Events',
  发件人: 'Sender',
  主题Token: 'Topic Token',
  访问地址: 'URL',
  消息ID: 'Message ID',
  云端执行: 'Cloud',
  设备不存在: 'Device does not exist',
  鼠标轨迹: 'Mouse Track',
  审计录像: 'Audit Video',
  网页截屏: 'Page Screenshot',
  流程节点执行失败时才截屏: 'Take Screenshot When Proc Node Fails',
  计划属性: 'Plan Properties',
  '可包含以下变量：': 'The following variables can be included:',
  '自动计划是指在指定的周期与时间内自动创建新的流程任务，当指定时间到达时如果前一任务还未结束，新的任务也将创建执行；自动计划可以禁用，一旦禁用则不会创建新的流程任务':
    'Automatic planning refers to automatically creating new process tasks within a specified period and time. When the specified time arrives, if the previous task has not ended, a new task will also be created and executed; automatic planning can be disabled, and once disabled, it will not be created. new process tasks',
  '手动计划是最简单的计划类型，只有当用户明确手动执行时才会创建新的流程任务':
    'Manual planning is the simplest type of planning, new process tasks are only created when the user explicitly executes them manually',
  '循环计划是指当前一流程任务执行结束后，在指定的间隔时间到达后会创建一个新的流程任务，需要注意的是，如果上一任务没有结束，则新的任务将不会被创建；循环计划可以禁用，一旦禁用则不再自动创建新的流程任务':
    'Cyclic planning means that after the execution of the current process task ends, a new process task will be created after the specified interval time is reached. It should be noted that if the previous task has not ended, the new task will not be created; loop The plan can be disabled. Once disabled, new process tasks will no longer be automatically created.',
  '触发计划支持以文件、邮件或者Http方式来主动触发；触发计划可以禁用，一旦禁用则不再响应触发动作':
    'The triggered plan supports active triggering through files, emails, or HTTP; the triggered plan can be disabled, and once disabled, it will no longer respond to trigger actions.',
  请至少添加一项执行时间: 'Pls add at least one execution time',
  请选择执行时间: 'Pls select execution time',
  增加一笔精准执行: 'Add an accurate execution',
  增加一笔随机执行: 'Add a random execution',
  请选择重复日期: 'Pls select a repeat date',
  '无论前一流程任务的执行结果是怎样的，都会创建新的流程任务':
    'A new process task will be created regardless of the execution result of the previous process task.',
  '如果前一流程的执行结果为“全部失败”，则不再创建新的流程任务':
    'If the execution result of the previous process is "all failed", no new process tasks will be created.',
  文件触发: 'File Trigger',
  邮件触发: 'Email Trigger',
  Http触发: 'HTTP Trigger',
  事件触发: 'Event Trigger',
  复制访问网址: 'Copy the URL',
  访问网址已复制到剪切板: 'The access URL has been copied to the clipboard',
  '更换访问网址，当您怀疑Token被泄露时，可以点击此按钮以更换访问网址':
    'Change the URL. When you suspect that the Token has been leaked, you can click this button to change the access URL.',
  '1. 访问上述 URL 时会触发此流程计划，请妥善保存':
    '1. This process plan will be triggered when accessing the above URL, please save it properly',
  '2. 可通过附加参数 variable=value 向流程计划传递输入变量的值':
    '2. The value of the input variable can be passed to the process plan via the additional parameter variable=value',
  '3. 可通过附加参数 clientToken=clientID 避免重复触发流程计划':
    '3. You can avoid repeatedly triggering the process plan by passing the additional parameter clientToken=clientID',
  '当接收邮箱收到由指定发件人发送的、并且主题包含有指定Token的，并且正文包含有指定内容（可选）的邮件时，会触发此流程计划':
    'This process plan will be triggered when the receiving mailbox receives an email sent by the specified sender, the subject contains the specified Token, and the body contains the specified content (optional)',
  文件夹: 'Folder',
  支持监听本地和云盘文件夹: 'Local or cloud drive folders',
  包含子文件夹: 'Contains Subfolders',
  '支持明确的文件名或者通配符，如：demo.txt、*.csv':
    'Supports file names wildcards, such as: demo.txt, *.csv',
  至少选择一项监控事件: 'Select at least one monitoring event',
  接收邮箱: 'Recipient',
  复制邮箱地址: 'Copy email address',
  复制主题Token: 'Copy topic token',
  '更换主题Token，当您怀疑Token被泄露时，可以点击此按钮以更换Token':
    'Change the theme token. When you suspect that the token has been leaked, you can click this button to change the token.',
  '当发件人包含指定发件人时才会触发（必填）':
    'Triggered when the senders contains the sender (required)',
  正文包含: 'Body',
  '当邮件正文包含指定内容时才会触发（可选，并且关系）':
    'Triggered when email body contains the content (optional)',
  '请输入消息ID，最长36个字符': 'Pls enter message ID, up to 36 characters',
  '卡号：': 'card number:',
  '过期时间：': 'Expiration:',
  '根据流程任务的执行时长扣除花瓣，不足1分钟按1分钟扣除':
    'Tokens will be deducted according to the execution time of the process task. If it is less than 1 minute, the tokens will be deducted as 1 minute.',
  'RPA执行费用：': 'RPA execution costs:',
  花瓣每分钟: ' tokens/min',
  选择客户端设备: 'Select client device',
  核数: 'Cores',
  收费模式: 'Charging',
  按量计费: 'Pay-per-Use',
  花瓣每分身每分钟: ' tokens every min',
  该设备CPU核数超出所选流程任务卡限制:
    'The num of CPU cores of the device exceeds the limit of the selected process task card',
  该设备CPU核数不符: 'The num of CPU cores of this device does not match',
  包: 'Bag',
  '花瓣每分身每分钟）': ' tokens/avatar/min)',
  任意本地设备: 'Any Local Device',
  '此处罗列的是以您的身份登录的所有客户端设备；如果选择的设备已经绑定了流程任务卡，则在此设备中执行流程任务将不再扣除花瓣，否则将根据流程任务的执行时长扣除花瓣':
    'Listed are all client devices logged in as you; if the selected device has been bound to a proc task card, tokens will no longer be deducted for executing the proc task on this device, otherwise tokens will be deducted based on the execution time.',
  本地设备: 'Local',
  '云资源费用（': 'Cloud Res Fees (',
  '合计：': 'Total:',
  选择云端执行: 'Choose cloud execution',
  '云端执行流程任务会根据不同的区域有不同的收费标准，请选择拟在拟运行RPA流程的云主机所在区域':
    'Cloud execution process tasks will have different charging standards according to different regions. Pls select the region where the cloud host where you plan to run the RPA process is located.',
  云端位置: 'cloud location',
  '位于中国香港阿里云，海外网络，适用于海外分身的流程执行':
    'Located in Alibaba Cloud, Hong Kong, China, overseas network, suitable for process execution of overseas browsers',
  '位于中国大陆（张家口）阿里云，大陆网络，适用于大陆分身的流程执行':
    'Alibaba Cloud, mainland network located in mainland China (Zhangjiakou), suitable for process execution of mainland browsers',
  当前团队不允许在该区域内执行流程:
    'The current team is not allowed to execute processes in this area',
  '确定不选择一个分身来辅助流程的编辑？':
    "Are you sure you don't want to choose a avatar to assist in editing the process?",
  '您可以选择一个分身来辅助流程的编辑，此时，系统将为您打开一个花漾灵动，并可以通过元素选择器或者录制的功能辅助您完成脚本的编写工作':
    'You can choose a avatar to assist in editing the process. At this time, the system will open a HuaYounglingli for you, and can assist you in completing the script writing work through the element selector or recording function.',
  '请为流程的编辑选择一个分身，此分身将仅用作流程编辑使用':
    'Pls select a browser avatar for editing.',
  '流程：': 'process:',
  流程任务字段自定义: 'Process task field customization',
  日期: 'Date',
  日期时间: 'Date Time',
  系统当前: 'Current ',
  请选择日期时间: 'Pls select date time',
  请选择日期: 'Pls select a date',
  缺省值不能小于最小值: 'The default value cannot be less than the minimum value',
  缺省值不能大于最大值: 'The default value cannot be greater than the maximum value',
  最小值: 'Minimum',
  最小值不能大于最大值: 'The minimum value cannot be greater than the maximum value',
  最大值: 'Maximum',
  最大值不能小于最小值: 'The maximum value cannot be less than the minimum value',
  缺省值不能小于最小长度: 'The default value cannot be less than the minimum length',
  缺省值不能大于最大长度: 'The default value cannot be greater than the maximum length',
  字符最小长度: 'Min Length',
  字符最小长度不能大于字符最大长度: 'Min character length cannot be greater than the max length',
  字符最大长度: 'Max Length',
  字符最大长度不能小于字符最小长度: 'Maxi character length cannot be less than the minimum length',
  变量高级设置: 'Variable Advanced Settings',
  字符串: 'String',
  单行文本框: 'Single Line Txt',
  数字: 'Number',
  多行文本框: 'Multiline Txt',
  布尔值: 'Boolean',
  复选框: 'Checkbox',
  单选框: 'Radio',
  数组: 'Array',
  '下拉列表（多选）': 'Drop-down List',
  文件选择框: 'File Selection',
  目录选择框: 'Directory Selection',
  日期选择框: 'Date Selection',
  日期时间选择框: 'Date Time Selection',
  时间选择框: 'Time Selection',
  单选分组变量: 'Grouping',
  新建变量: 'New Variable',
  输入变量设置: 'Input Var Settings',
  流程变量设置: 'Process Var Settings',
  变量名称不能与系统变量重名: 'Variable cannot have the same name as system variables',
  '变量名称“': 'Variable Name',
  '”是系统内置变量，请另起一个变量名称，避免重名':
    ' is a built-in system variable. Pls give another variable name to avoid duplication.',
  查看系统变量列表: 'View System Variables',
  系统内置变量: 'System Variables',
  变量名称不能与JS保留关键字重名:
    'Variable names cannot have the same name as JS reserved keywords',
  '”是JS保留关键字，请另起一个变量名称，避免重名':
    ' is a reserved keyword in JS. Pls give another name to avoid duplication of names.',
  '流程变量是由用户自行声明的、只在当前流程内有效的变量':
    'Process variables are declared by the user and only valid within the current process.',
  '输入变量是在执行流程前必须由用户指定具体值的变量，通过输入变量能够很好的增强流程定义的弹性':
    'Input variable values must be specified by user before executing the process. Input variables can enhance the flexibility of process.',
  变量不能为空: 'Variable cannot be empty',
  '变量需以英文字母开头，只能包含英文字母、数字与下划线':
    'Variables must start with an english letter and can only contain letters, numbers and underscores.',
  已存在相同名称变量: 'A variable with the same name already exists',
  变量名称一般使用英文字母和数字来命名: 'Named using english letters and numbers.',
  变量标签是提醒用户在输入具体值时的标签: 'Labels that remind users when entering specific values',
  请在高级设置中定义具体内容: 'Pls define the specific content in the advanced settings',
  '请根据变量类型设置初始值，可为空': 'Set the initial value, can be empty',
  是否必填: 'Required',
  变量说明: 'Var Desc',
  请上传流程定义文件: 'Pls upload proc script file',
  请选择流程定义: 'Pls select a proc script',
  控制台流程无法成为子流程: 'Console process cannot be made a subprocess',
  '您选择的流程为控制台流程，控制台流程无法成为其它流程的子流程':
    'The process you selected is a console process, and cannot be a subprocess of other processes.',
  流程定义文件不合法: 'The proc script file is illegal',
  '您选中的文件不是一个合法的流程定义文件，无法覆盖当前流程定义':
    'The file you selected is not a legal proc script file and cannot overwrite the current proc script.',
  新建子流程: 'New Subprocess',
  子流程是将复杂流程进行解耦与复用的良好手段:
    'Subprocesses are a good way to decouple & reuse complex processes.',
  子流程名称: 'Name',
  子流程名称不可以重复: 'Subprocess names cannot be repeated',
  请输入子流程名称: 'Pls enter the subprocess name',
  子流程来源: 'Source',
  引用当前团队已有流程: 'Reference the existing proc script of current team',
  '只能引用当前团队内已发布的流程，被引入后可读但不可编辑，当原有流程更新时，此子流程自动更新':
    'Only published processes within the current team can be referenced. After being introduced, it can be read but cannot be edited. When the original process is updated, this subprocess will be automatically updated.',
  复制当前团队已有流程: 'Copy the existing proc script of current team',
  '只能复制当前团队内已发布的流程，被引入后可读且可编辑，在此处编辑不会影响原有流程':
    'Only published processes within the current team can be copied. They are readable and editable after being introduced. Editing here will not affect the original process.',
  上传子流程定义文件: 'Upload Subproc File',
  '上传流程定义文件后，在此处可读且可编辑':
    'Once the proc script file is uploaded, it will be readable and editable here',
  新建全新子流程: 'Create a New Subprocess',
  从头创建一个全新的子流程: 'Create a new subprocess from scratch',
  子流程类型: 'Subprocess Type',
  一个主流程只能拥有一个初始子流程: 'A main process can only have one initial subprocess',
  一个主流程只能拥有一个清理子流程: 'A main process can only have one cleaning subprocess',
  常规子流程: 'Regular Subprocess',
  事件子流程: 'Event Subprocess',
  初始子流程: 'Initial Subprocess',
  清理子流程: 'Cleanup Subprocess',
  子流程备注: 'Notes',
  文件重命名: 'File Rename',
  '在流程中引用资源文件通过”attachment://文件名称“完成':
    'Reference resource files in the process are completed through "attachment://file name',
  暂停录制: 'Pause',
  继续录制: 'Continue',
  开始录制: 'Start',
  '流程录制中...': 'Recording in progress...',
  流程录制: 'Process Recording',
  '当点击“开始录制”按钮后，请操纵花漾浏览器，系统将会录制您的操作过程':
    'After clicking the "Start Recording" button, Pls operate HuaYoung Browser and the system will record your operation process',
  不包含: 'Not Included',
  等于: 'Equal',
  不等于: 'Not Equal',
  大于: 'Greater',
  大于等于: 'Greater or Equal',
  小于: 'Less',
  小于等于: 'Less or Equal',
  存在: 'Exist',
  为真: 'True',
  为假: 'False',
  是空字符串: 'Empty String',
  不是空字符串: 'Not Empty String',
  以对象2开头: 'Start With',
  不以对象2开头: 'Not Start With',
  以对象2结束: 'End With',
  不以对象2结束: 'Not End With',
  可见: 'Visible',
  条件判断: 'Conditional',
  操作数: 'Operator',
  请输入值: 'Pls enter a value',
  运算符: 'Operator',
  表达式: 'Expression',
  常量: 'Constant',
  操作数模式: 'Operator',
  对象1: 'Object 1',
  请输入对象1: 'Pls enter object 1',
  关系: 'Relationship',
  对象2: 'Object 2',
  请输入对象2: 'Pls enter object 2',
  表达式模式: 'Expression',
  条件设置: 'Condition settings',
  条件: 'Condition',
  以: 'by',
  开头: 'Start With',
  不以: 'Not',
  结束: 'Finish',
  个条件: 'conditions',
  引入子流程: 'Import Subprocess',
  '您试图引入的子流程内嵌套有其它的子流程，需要将这些内置一并引入，请为这些子流程起一个有意义且不重复的名称':
    'The subprocess you are trying to inport has other subprocesses nested within it, need to be inported together. Pls give a non-duplicate name.',
  子流程的名称不允许重名: 'The names of subprocesses are not allowed to have the same name.',
  '重新编辑意味着该流程定义的版本号将进行升级，这并不会影响到已有流程计划（已有流程计划将按照当前版本继续执行）':
    'Re-editing means that the version number of the proc script will be upgraded, which will not affect the existing process plan (the existing process plan will continue to be executed according to the current version)',
  请输入一个不超过36个字符的版本号: 'Pls enter a version number not exceeding 36 characters',
  单个网页元素: 'Single',
  网页元素列表: 'List',
  子网页元素: 'SubElement',
  '是否打开一个浏览器分身用来捕获元素？': 'Open a browser avatar to capture the element?',
  '未找到浏览器运行实例，无法进行元素的捕获':
    'The browser running instance was not found and the element cannot be captured.',
  编辑此元素: 'Edit this element',
  '以此元素为父容器，捕获其子元素':
    'Use this element as the parent container to capture its child elements',
  删除此元素: 'Remove this element',
  未分组: 'unclassified',
  '已过滤非“': 'Filtered non-',
  全部显示: 'Show All',
  通过元素捕获器捕获网页元素: 'Capture web elements',
  全部元素类型: 'All',
  依据名称检索: 'Search by name',
  元素名称: 'Name',
  '元素名称（单个/列表）': 'Element name (single/list)',
  子网页元素名称: 'Subpage element name',
  元素截图: 'Screenshot',
  捕获新元素: 'Capture New',
  元素库: 'Element Library',
  请输入文件路径: 'Pls enter file path',
  请输入文件夹路径: 'Pls enter the folder path',
  请选择资源文件: 'Pls select a resource file',
  切换为选择资源文件模式: 'Switch to select resource file mode',
  切换为输入文件路径模式: 'Switch to input file path mode',
  '可设置日志输出的Level，并可自定义日志输出的格式':
    'You can set the level of log output and customize the format of log output.',
  格式化: 'Format',
  缓冲区: 'Buffer',
  行: ' records',
  年月日: 'YMD',
  时分秒: 'HMS',
  节点ID: 'Node ID',
  节点名称: 'Name',
  节点类型: 'Node Type',
  日志内容格式化: 'Log Formatting',
  网页元素: 'Web Elements',
  文件路径: 'File Path',
  Excel对象: 'Excel Object',
  普通类型: 'Common Type',
  分身描述: 'Browser Desc',
  分身所属平台: 'Browser Platform',
  分身平台所属区域: 'Browser Site',
  分身所属平台的登录链接: 'Browser account login url',
  分身所属平台的登录用户名: 'Browser account login user name',
  当前团队的ID: 'Team ID',
  当前流程的ID: 'Proc ID',
  当前流程的名称: 'Proc Name',
  当前任务的ID: 'Task ID',
  当前任务的名称: 'Task Name',
  当前任务的创建者的ID: 'Creator ID',
  当前任务项的ID: 'Task Item ID',
  当前流程节点的nid: 'Proc Nde ID',
  上一个节点的返回值: 'The return value of the previous node',
  '系统变量是流程运行时系统传入的上下文信息，主子流程共用一套系统变量':
    'System variables are context information passed in by the system when the process is running. The main and subprocesses share a set of system variables.',
  '只在当前主/子流程内有效，不能跨主/子流程引用':
    'It is only valid within the current main/subprocess and cannot be referenced across main/subprocesses.',
  '输入变量是指用户在执行此流程定义时，允许用户输入具体值以增强流程弹性':
    'Input variables allow users to enter specific values when executing this proc script to enhance process flexibility.',
  系统当前时间: 'Current time',
  勾选: 'Check',
  不勾选: 'Uncheck',
  所选变量已复制至剪切板: 'Copied to clipboard',
  粘贴: 'Paste',
  增加: 'Add',
  子: 'Sub',
  主: 'Main',
  流程: 'Script',
  的变量: 'Variables',
  系统变量: 'System Variables',
  主流程: 'Main Proc',
  暂不支持定位到该节点: 'Locating this node is not currently supported.',
  变量定义: 'Variable definitions',
  执行此子流程: 'Execute this subprocess',
  执行此主流程: 'Execute this main process',
  '“引用”方式下的子流程不可编辑': 'Subprocesses in "reference" mode cannot be edited.',
  指令输入: 'Input',
  指令输出: 'Output',
  当前指令不包含任何输出内容: 'The current command does not contain any output content',
  '请输入iframe选择器，多层iframe通过">>"进行分隔':
    'Iframe selector, multiple are separated by ">>',
  您似乎在文本模式中输入了一个表达式: 'It looks like you entered an expression in text mode',
  切换为表达式模式: 'Switch to Expression Input Mode',
  切换为文本模式: 'Switch to Text Input Mode',
  '当前为表达式模式，在表达式模式中输入的是JS表达式，字符串需要加引号，变量可直接引用，返回值为表达式的值':
    'Currently in expression input mode. The input text is a JS expression. The string needs to be quoted. Variables can be quoted directly. The return value is the value of the expression.',
  '当前为文本模式，在文本模式中输入的是文本，字符串不需要加引号，可通过{}引用变量':
    'Currently in text input mode. In text mode, Strings do not need to be quoted, variables can be quoted through {}.',
  请选择变量: 'Pls select a variable',
  表达式输入模式: 'Expression input mode',
  文本输入模式: 'Text input mode',
  已为您筛选最适合的变量: 'Most suitable vars',
  已显示全部变量: 'All variables shown',
  显示全部变量: 'Show All',
  当前过滤条件下无可用变量: 'There are no variables available under the current filter conditions',
  没有找到匹配的已有变量: 'No matching existing variable found',
  没有声明任何输入变量或流程变量: 'No input variables or process variables are declared',
  根据变量名称进行检索: 'Search by variable name',
  '变量不存在，是否声明为新的变量？':
    'The variable does not exist. Do you want to declare it as a new variable?',
  花漾RPA中的变量需要先声明再使用: 'Variables in HuaYoung RPA need to be declared before use',
  选择变量: 'Select Variable',
  设置变量分组: 'Set Var Grouping',
  分组类型: 'Groupe Type',
  '折叠分组：': 'Collapse Groupe:',
  '当输入变量过多时，可以通过建立折叠分组并将部分输入变量放到此分组中进行折叠':
    'When there are too many input variables, you can create a folding group and put some of the input variables into this group for folding.',
  '单选分组：': 'Single choice grouping:',
  '当输入变量过多且不同的输入变量之间是互斥时，可以通过建立单选分组并将互斥的输入变量分别放到不同的单选分组中':
    'When there are too many input variables and different input variables are mutually exclusive, you can create radio-select groups and put the mutually exclusive input variables into different radio-select groups.',
  折叠分组: 'Collapse group',
  单选分组: 'radio grouping',
  分组名称: 'Group Name',
  分组变量: 'grouping variable',
  '具有相同变量名的分组会形成互斥关系，变量的值为所选中的分组的名称，默认会选中第一个分组变量':
    'Groups with the same variable name will form a mutually exclusive relationship. The value of the variable is the name of the selected group. By default, the first group variable will be selected.',
  '新变量...': 'New variable...',
  尚未定义变量: 'variable not defined yet',
  请选择或输入变量: 'Pls select or enter a variable',
  '确定要删除该分组吗？': 'Sure to delete this group?',
  删除后无法恢复: 'Unable to recover after deletion',
  创建一个新的分组: 'Create a new group',
  变量列表: 'variable list',
  '缺省值：': 'Default value:',
  引入变量: 'Introduce variables',
  启用变量分组: 'Enable variable grouping',
  '当输入变量过多时，可通过开启变量分组给用户带来更好的指引':
    'When there are too many input variables, you can turn on variable grouping to provide users with better guidance.',
  输入变量推荐形态: 'Input variable recommended form',
  '无论多个分身使用相同的输入变量值或者不同的输入变量值，是一种推荐形态，而非强制形态；当使用相同的输入变量值时，会对流程定义的使用者带来更易用的UI展现':
    'Whether multiple browsers use the same input variable value or different input variable values, it is a recommended form, not a mandatory form; when using the same input variable value, it will bring an easier-to-use UI to the users of the proc script. show',
  多个分身使用相同的输入变量值: 'Multiple browsers use the same input variable value',
  不同分身使用不同的输入变量值: 'Different browsers use different input variable values',
  变量分组: 'variable grouping',
  新增: 'New',
  代码片段: 'Code Snippet',
  '代码片段常用于通用的工具函数，方便代码复用':
    'Code snippets are often used in common utility functions to facilitate code reuse.',
  '名称只支持以英文字母开头，只能包含英文字母、数字以及下划线':
    'The name only supports starting with an English letter and can only contain English letters, numbers and underscores.',
  文件名称非法: 'File name is illegal',
  代码片段名称不可以重复: 'Code snippet names cannot be repeated',
  选择流程定义: 'Select Proc Script',
  已为您过滤未发布与平台不符的流程定义: 'All unpublished proc scripts have been filtered',
  根据流程定义名称检索: 'Search by script name',
  提供方: 'Provider',
  '该流程定义已过期，无法执行': 'The proc script has expired and cannot be executed',
  请选择元素库中的元素: 'Pls select an element from the element library',
  设置网页框架: 'Page Frame',
  框: 'frame',
  从元素库中选择: 'Select from element library',
  主框架页面: 'Main Frame Page',
  'iframe页面：': 'Iframe Page:',
  请输入iframe选择器: 'Pls enter iframe selector',
  '请输入iframe选择器，多层iframe通过“>>”分隔':
    'Iframe selector. Multi iframes are separated by ">>"',
  等待页面节点正常显示: 'Wait Page Node Display Normally',
  设置变量: 'Set variables',
  同一个变量在不同的分组下可以指定不同的缺省值:
    'The same variable can be assigned different default values in different groups.',
  编辑子流程: 'Edit Subprocess',
  选择接收人: 'Select Recipient',
  输入接收人: 'Enter Recipient',
  流程执行人: 'Process Executor',
  '请输入接收人手机号、邮箱或团队昵称，成员之间用逗号“,”分隔':
    'Pls enter recipient phone, email or team nickname, separated by commas ","',
  请选择接收人: 'Pls select recipient',
  请输入接收人: 'Pls enter recipient',
  '请确保收件人绑定了手机/邮箱/微信，系统并不做强制性检查':
    'Pls ensure that the recipient has bound his mobile phone/email/WeChat. The system does not perform mandatory checks.',
  '如何绑定微信？': 'How to bind WeChat?',
  根据用户名称进行检索: 'Search by user name',
  用户: 'User',
  卡详情: 'Card Details',
  卡号: 'Card No',
  当前绑定设备: 'Currently bound device',
  购买人: 'Buyer',
  绑定记录: 'Binding Record',
  绑定设备: 'Bind Device',
  '流程定义在已发布状态下无法对适用平台进行修订，如要修订请重新进入编辑状态':
    'The proc script cannot be revised for the applicable platform in the published state. If you want to revise, Pls re-enter the editing state.',
  流程说明支持markdown语法格式: 'Process description supports MarkDown syntax format',
  进一步了解markdown: 'Learn more about markdown',
  为流程准备一份翔实的使用说明是一个良好的习惯:
    'It is good practice to prepare detailed instructions for the process script',
  中断主流程: 'Interrupt Main Proc',
  退出当前流程: 'Exit Current Proc',
  继续执行: 'Continue',
  '几乎每一个流程节点都拥有以下属性，此处定义的是默认值，通过修改默认值将影响大部分的流程节点（除非流程节点自行设置）':
    'Almost every proc node has the following attributes. What is defined here is the default value. Modifying the value will affect most proc nodes (unless the proc node sets it by itself)',
  默认节点超时时间: 'Node Timeout',
  '每一个流程节点都会有一个超时时长，一旦超过此时长则会强行中断，默认为60秒':
    'Each process node will have a timeout period. Once this period is exceeded, it will be forcibly interrupted. The default is 60 seconds.',
  默认节点间隔时长: 'Node Interval',
  每个流程节点在开始执行时的延时时长:
    'The delay length of each process node when starting execution',
  默认节点拟人操作: 'Personification',
  '当开启拟人化操作时，所有的鼠标移动轨迹、内容输入等等，都会模拟人的操作行为；如果为了提升效率，可以关闭此选项':
    'When anthropomorphic operations are turned on, all mouse movement trajectories, content input, etc. will simulate human operating behaviors; if you want to improve efficiency, you can turn off this option.',
  默认节点异常策略: 'Exception Policy',
  '当流程节点位于”TryCatch代码块“时，执行失败会忽略此处设置，自动跳转至异常处理子流程中':
    'When the process node is located in the "TryCatch code block", if the execution fails, the settings here will be ignored and automatically jump to the exception handling subprocess.',
  默认节点异常日志: 'Log Level',
  '当节点执行异常时，异常日志的输出级别':
    'When a node executes an exception, the output level of the exception log',
  '输出级别（': 'Level (',
  不响应: 'No Response',
  '点击“确定”': 'Click "OK',
  '点击“取消”': 'Click "Cancel',
  设置拟执行流程的浏览器分身相关策略:
    'Set policies related to browsers of the process to be executed',
  分身执行策略: 'Avatars Add',
  '手动添加分身：在流程执行前指定拟在哪些分身中执行此流程':
    'Manually add browsers: Specify in which browsers the process is to be executed before the process is executed.',
  '动态指定分身：可在初始子流程中动态指定拟在哪些分身中执行此流程':
    'Dynamically specify browsers: You can dynamically specify in which browsers the process is to be executed in the initial subprocess.',
  手动添加分身: 'Add Manually',
  动态添加分身: 'Dynamically Add',
  默认打开分身: 'Open browser by default',
  '默认情况下是否打开浏览器分身窗口，默认打开；如果默认不打开，需要通过“打开分身”、”关闭分身“的流程节点控制是否打开分身窗口':
    'Whether to open the browser window by default is open by default; if it is not opened by default, you need to control whether to open the  window through the process nodes of "Open Avatar" and "Close Avatar',
  默认打开: 'Open by default',
  默认不打开: 'Not opened by default',
  分身窗口大小: 'Browser Size',
  请输入浏览器窗口大小: 'Pls enter browser window size',
  可指定打开的浏览器分身的窗口大小: 'You can specify the window size of the opened browser',
  分身窗口位置: 'Browser Position',
  请输入分身窗口位置: 'Pls enter the browser window location',
  可指定打开的浏览器分身的窗口位置: 'You can specify the window position of the opened browser',
  弹框响应策略: 'Web Popups',
  '遇到网页弹出原生窗口时（如alert、退出拦截）的自动响应策略':
    'Automatic response strategy when native windows pop up on web pages (such as alert, exit interception)',
  '（会造成流程中断）': '(Will cause process interruption)',
  '（位于5秒与604800秒之间）': '(5 - 604800 sec)',
  '（位于0秒与120秒之间）': '(0 - 120 sec)',
  最大化: 'Maximize',
  指定大小: 'Specify',
  系统默认: 'Default',
  指定位置: 'Specify',
  '（在同一个客户端中会记忆上次大小）': ' (The last size will be remembered in the same client)',
  宽度: 'Width',
  像素: 'Pixel',
  高度: 'High',
  '（在同一个客户端中会记忆上次位置）':
    ' (The last location will be remembered in the same client)',
  水平: 'Hor',
  垂直: 'Ver',
  '在开启元素映射之前，您需要将该流程定义的属性修订为“控制台”类型，控制台类型的流程定义在执行时不会主动结束':
    'Before turning on element mapping, you need to revise the properties of the proc script to the "console" type. The proc script of the console type will not end automatically during execution.',
  立即开启: 'Turn On',
  关闭控制台特性: 'Turn off console features',
  元素映射: 'element mapping',
  '您确定要离开此页面吗？': 'Sure to leave this page?',
  '是否打开一个浏览器分身用来执行流程？': 'Do you want to open a browser to execute the process?',
  '未找到浏览器运行实例，无法进行预览/断点调试':
    'Browser running instance not found, unable to preview/breakpoint debugging',
  请先设置断点: 'Pls set breakpoint first',
  无法编辑: 'Cannot edit',
  新建标签: 'New Label',
  关闭标签: 'Close Tab',
  激活标签: 'Activate Tag',
  访问网址: 'Visit URL',
  刷新页面: 'Refresh Page',
  滚动页面: 'Scroll Page',
  浏览器脚本: 'Browser Script',
  RPA引擎脚本: 'RPA Engine Script',
  等待元素出现: 'Wait Elmt Appear',
  等待元素消失: 'Wait Elmt Disapp',
  等待指定时间: 'Wait Specified Time',
  等待条件满足: 'Wait Conditions',
  点击元素: 'Click Element',
  输入内容: 'Enter Content',
  密码代填: 'Password Filling',
  悬浮元素: 'Hover Element',
  聚焦元素: 'Focus Element',
  设置下拉框: 'Set Drop-Down',
  设置复选框: 'Set Checkbox',
  滚动鼠标: 'Scroll Mouse',
  移动鼠标: 'Move Mouse',
  拖放鼠标: 'Drag & Drop',
  键盘按键: 'Press Keys',
  下载文件: 'Download',
  IF条件: 'IF',
  次数循环: 'Times Loop',
  WHILE循环: 'WHILE loop',
  无限循环: 'Infinite loop',
  循环遍历数组: 'Loop Array',
  循环遍历键值对: 'Loop K-Value Pairs',
  遍历循环: 'Traverse Loop',
  循环遍历容器元素: 'Loop ContainElmt',
  退出循环: 'Exit Loop',
  继续下一次循环: 'Continue',
  退出主流程: 'Exit Main Proc',
  终止流程任务: 'Terminate Task',
  本机人工干预: 'Local Intervention',
  调用子流程: 'Call SubProcess',
  监听页面事件: 'Listen Page Events',
  定时触发: 'Timing Trigger',
  监听Ajax请求: 'Listen Ajax Req',
  Goto锚点: 'Label',
  Goto跳转: 'Goto',
  'Try-Catch代码块': 'Try-Catch',
  Assert断言: 'Assert',
  获取元素对象: 'Get Element Obj',
  获取元素列表: 'Get Elements List',
  获取元素信息: 'Get Element Info',
  获取关联元素: 'Get Assoc Elmt',
  搜索元素: 'Search Element',
  页面截屏: 'Page Screenshot',
  保存文件: 'Save File',
  读取文件: 'Read File',
  同步文件: 'Sync Files',
  打印日志: 'Log',
  变量赋值: 'Set Var',
  获取网址: 'Get URL',
  获取随机数: 'Random',
  手机短信: 'SMS',
  微信模板消息: 'WeChat Message',
  企业微信消息: 'WeCom Message',
  钉钉消息: 'DingTalk Message',
  飞书消息: 'Feishu Message',
  打开Excel文件: 'Open Excel File',
  保存Excel文件: 'Save Excel File',
  读取数据: 'Read Data',
  循环Excel内容: 'Loop Excel Content',
  写入数据: 'Write',
  获取总行数: 'Rows Total Number',
  获取总列数: 'Cols Total Number',
  获取第一个非空行: '1st Non-Blank Row',
  获取第一个非空列: '1st Non-Empty Col',
  删除行: 'Delete Row',
  删除列: 'Delete Column',
  插入行: 'Insert Row',
  插入列: 'Insert Column',
  获取文件列表: 'Get Files',
  复制文件: 'Copy Files',
  移动文件: 'Move Files',
  写入文件: 'Write File',
  删除文件: 'Delete Files',
  获取文件夹列表: 'Get Folders',
  创建文件夹: 'Create Folder',
  删除文件夹: 'Delete Folder',
  清空文件夹: 'Clear Folder',
  复制文件夹: 'Copy Folder',
  移动文件夹: 'Move Folder',
  文件夹重命名: 'Rename Folder',
  Http请求: 'HTTP Request',
  输入型验证码: 'Input Code',
  远程人工干预: 'Remote Ivtn',
  图片验证码: 'Image CAPTCHA',
  产品评论: 'Product Reviews',
  直播评论: 'Live Comment',
  产品推荐: 'Recommendation',
  忽略分身: 'Ignore Browser',
  打开分身: 'Open Browser',
  关闭分身: 'Close Browser',
  未命名: 'Unnamed',
  ELSE分支: 'ELSE',
  异常处理机制: 'Exception Handling',
  页面操作: 'Page Operations',
  等待操作: 'Waiting For',
  键鼠操作: 'Keyboard & Mouse',
  流程分支: 'Process Branch',
  获取数据: 'Query Datas',
  Excel操作: 'Excel Operations',
  文件与文件夹操作: 'File & Folders',
  网络请求与消息通知: 'Network & Notifications',
  验证码与AI: 'CAPTCHA & AI',
  分身操作: 'Browsers',
  节点: 'Nodes',
  全部收起: 'Collapse',
  全部展开: 'Expand',
  '名称检索(': 'name search(',
  无符合条件的节点: 'No nodes find',
  在资源管理器中显示: 'Show in Explorer',
  '断点调试时此处会显示所有的变量值，并可通过表达式更改流程变量与输入变量值':
    'During breakpoint debugging, all variable values will be displayed here, and process variables and input variable values can be changed through expressions.',
  请输入表达式: 'Pls enter an expression',
  打开新的标签页: 'Open New Tab',
  中断流程: 'Interrupt',
  流程等待手工确认后继续执行: 'The process waits for manual confirmation before continuing.',
  复制节点成功: 'Copying node successfully',
  '确定要删除选中的节点吗？': 'Sure to delete the selected node?',
  删除后可通过右键菜单或者快捷键撤销该操作:
    'After deletion, the operation can be undone through the right-click menu or shortcut keys.',
  '该子流程为“引用”方式，只可查看不可编辑，且当原始流程更新时会自动更新本流程':
    'This subprocess is in "reference" mode and can only be viewed but not edited. This process will be automatically updated when the original process is updated.',
  '自动滚动：': 'Autoscroll:',
  开: 'Open',
  关: 'Close',
  复制日志: 'Copy Log',
  日志复制成功: 'Logs copy successful',
  清空日志: 'Clear Log',
  文件太大: 'File size too big',
  单个文件不能超过: 'A single file cannot exceed ',
  已存在同名文件: 'A file with the same name already exists',
  请修改文件名称或者删除已有文件后重试:
    'Pls modify the file name or delete the existing file and try again',
  '确定要删除该资源文件吗？': 'Sure to delete this resource file?',
  '确定要删除所有资源文件吗？': 'Sure to delete all resource files?',
  '此操作不可撤销，请谨慎操作': 'This operation cannot be undone, Pls operate with caution',
  一个流程中导入的资源文件不超过:
    'The num of resource files imported in a process does not exceed ',
  '个，单个文件不超过': ', a single file does not exceed ',
  '在流程中引用资源文件通过“attachment://文件名称”完成':
    'Reference resource files in the process through "attachment://file name',
  '资源文件是只读的，可以另存为，但不能原地保存':
    'Resource files are read-only and can be saved as, but cannot be saved in place.',
  重命名: 'Rename',
  复制文件路径成功: 'File path copied successfully',
  复制文件路径: 'Copy file path',
  新增资源文件: 'Add Resource Eile',
  删除所有资源文件: 'Delete all resource files',
  资源文件: 'Resource',
  新增代码片段: 'Add Code Snippet',
  当前过滤条件下暂无变量: 'There are no variables under the current filter conditions.',
  用户输入变量: 'Input Variable',
  '名称：': 'Name:',
  '类型：': 'Type:',
  '范围：': 'Scope:',
  '描述：': 'Describe:',
  复制名称: 'Copy Name',
  大纲: 'Outline',
  '变量：': 'Variable:',
  声明流程变量: 'Declare Process Variables',
  声明输入变量: 'Declare Input Variables',
  输入变量高级设置: 'Advanced Settings',
  只显示系统变量: 'Show System Vars',
  只显示流程变量: 'Show Process Vars',
  只显示输入变量: 'Show Input Vars',
  调试: 'Debug',
  '没有打开花漾浏览器，无法录制': 'The HuaYoung browser is not open and cannot be recorded.',
  '流程编辑时，系统会提示您是否打开一个花漾浏览器用作流程编写的辅助工具，只有打开此花漾浏览器才支持录制功能':
    'When editing a process, the system will prompt you whether to open a HuaYoung browser to use as an auxiliary tool for process writing. Only when this Huanyang browser is opened will the recording function be supported.',
  '确认要关闭RPA流程编辑器吗？': 'Sure to close the RPA process editor?',
  关闭之前保存对流程的修改: 'Save changes to the proc script before closing',
  '同时关闭打开的花漾浏览器（未打开则忽略）':
    'Close the HuaYoung browser (ignore if it is not open)',
  停止调试: 'Stop Debugging',
  '下一断点（F8）': 'Next Breakpoint (F8)',
  '逐行调试（F10）': 'Line-by-Line (F10)',
  停止预览: 'Stop Preview',
  断点调试: 'Debug',
  完整执行: 'Complete Execution',
  从头执行到选中节点: 'Execute to Selected Node',
  从选中节点开始执行: 'Start Exec from Selected Node',
  '保存流程（': 'Save Process (',
  控制台: 'Console',
  'AI助手（F1）': 'AI Assistant (F1)',
  展开当前编辑器的全部流程节点: 'Expand all process nodes of the current editor',
  收起当前编辑器的全部流程节点: 'Collapse all process nodes in the current editor',
  清空鼠标轨迹: 'Clear mouse tracks',
  服务器设置: 'Email Server',
  打码服务设置: 'CAPTCHA Service',
  继承自流程: 'Inherited Proc',
  自定义: 'Customize',
  '（位于0秒与1200秒之间）': '(0-1200 seconds)',
  输出日志: 'Output Log',
  不输出: 'No Output',
  节点名称不能包含空格: 'Node names cannot contain spaces',
  最长不超过20字符: 'Maximum 20 characters',
  '执行策略决定了流程节点的执行规则，默认值来自于流程属性，可通过修改流程属性影响几乎所有的流程节点':
    'The exec strategy determines the exec rules of the process node. The default value comes from the process attributes, which can affect almost all nodes by modifying the attributes.',
  超时时间: 'Overtime',
  '超时时间须在5-604800之间': 'The timeout 5-604800',
  延时执行: 'DelayedExec',
  '节点间隔时间须在0-1200之间': 'The node interval 0-1200',
  拟人操作: 'Anthropomorphic',
  异常策略: 'Exception',
  '当流程节点位于”流程体“内时，执行失败时会自动跳转至异常处理子流程中':
    'When the process node is located in the "process body", it will automatically jump to the exception handling subprocess when execution fails.',
  异常日志: 'Exception Log',
  '可在控制台中输出您期望的内容，日志级别分别是：Debug（详尽）、Info（提示）和Error（简要）；等价于console.log、console.info、console.error':
    'You can output the content in the console. The log levels: Debug (detailed), Info (prompt) and Error (brief); equivalent to console.log, console.info, console.error',
  日志级别: 'Log Level',
  请输入日志内容: 'Pls enter the log content',
  特定属性: 'Specific Attributes',
  '【未设置】': '[Not Set]',
  '调用ChatGPT引擎生成匹配内容，输出结果保存至变量；可使用用户的Token，如果使用花漾的Token则根据输入+输出内容总字数进行收费，每千字符50花瓣':
    "Call the ChatGPT engine to generate matching content, and save the output results to variables; you can use the user's Token. If you use HuaYoung's Token, you will be charged based on the total number of words of the input + output content, 2 tokens per thousand characters.",
  使用花漾灵动的Key: 'Use HuaYoung Key',
  使用用户自己的Key: 'Use user’s own Key',
  '（请确保花漾客户端网络能够访问ChatGPT）':
    '(Pls ensure that the HuaYoung app can access ChatGPT)',
  GPT版本: 'GPT Version',
  请输入ChatGPTkey: 'Pls enter ChatGPT key',
  内容: 'Content',
  输出内容: 'Output Content',
  '调用ChatGPT生成输出内容，输出结果保存至变量；可使用用户的Token，如果使用花漾的Token则根据输入+输出内容总字数进行收费，每千字符50花瓣':
    "Call ChatGPT to generate output content, you can use the your token. If use HuaYoung's token, you will be charged based on the total number of words of the input + output content, 2 tokens per 1000 characters.",
  期望输出约: 'The expected output is approx.',
  个字符: ' chars',
  '使用花漾的打码服务时，将按1花瓣/次进行收费':
    "When using HuaYoung's coding service, you will be charged 1 tokens per time.",
  使用花漾打码服务: 'CAPTCHA Service',
  服务提供商: 'Service Provider',
  超级鹰: '超级鹰',
  图鉴: '图鉴',
  自行设置打码服务: 'Set up your own CAPTCHA service',
  请根据使用的打码服务设置相应的参数:
    'Pls set the corresponding parameters according to the CAPTCHA service used.',
  验证码图片节点: 'Image Node',
  验证码类型: 'Verification Type',
  '验证码类型的代码可参考：': 'For verification code type codes, Pls refer to:',
  '超级鹰：https://www.chaojiying.com/price.html':
    'Super Eagle: https://www.chaojiying.com/price.html',
  '图鉴：http://www.ttshitu.com/docs/index.html':
    'Picture book: http://www.ttshitu.com/docs/index.html',
  '选填，验证码类型的代码，默认为1~10位英文数字':
    'Optional, verification code type code, default is 1~10 English digits',
  保存结果至变量: 'Save Result',
  '微信接收人：': 'WeChat Recipient:',
  '远程人工干预是指流程执行到此节点时会发送消息给指定人员，指定人员可以通过手机获得浏览器的镜像并能够执行操作':
    'Remote manual intervention means that when the process reaches this node, a message will be sent to the designated person. The designated person can obtain the browser image through the mobile phone and be able to perform operations.',
  通知方式: 'Message',
  微信接收人: 'WeChat',
  '回填元素：': 'Backfill elements:',
  截屏录像: 'Screenshot',
  '截屏（只截取浏览器页面内容）': 'Screenshot (only capture the content of the browser page)',
  '录像（适用于动态显示验证码等场景）':
    'Video recording (suitable for dynamic display of verification codes and other scenarios)',
  '录像时长：': 'Recording duration:',
  回填元素: 'Backfill Elements',
  子流程未设置: 'Subprocess not set',
  注销条件: 'Cancellation Conditions',
  '子流程中定义的输入变量，需要在此处指定具体值':
    'Input variables defined in the subprocess, specific values need to be specified here',
  没有事件子流程: 'No event subprocess',
  只能选择事件子流程: 'Only event subprocesses can be selected',
  URL过滤: 'URL filtering',
  '选填，只有通过正则表达式匹配的URL才会监听事件':
    'Optional, only URLs matched by regular expressions will listen for events',
  监听器注销条件: 'Listener logout conditions',
  条件个数: 'Num Conditions',
  最多只允许设置8个条件: 'Only up to 8 conditions are allowed to be set',
  条件关系: 'Relationship',
  保存返回值至: 'Return Value',
  '锚点名称须在当前流程内唯一，用于GOTO跳转的指定节点':
    'The anchor name must be unique within the current process and is used as the designated node for GOTO jumps.',
  锚点名称: 'Anchor name',
  '锚点名称只能由英文/数字及下划线组成':
    'Anchor name can only consist of English/numbers and underscores',
  当前流程内存在相同名称锚点: 'An anchor with the same name exists in the current process',
  '1至128个英文/数字及下划线组成，在当前流程内唯一':
    'Composed of 1 to 128 English/numbers and underscores, unique within the current process',
  待遍历数组: 'Array Traversed',
  保存循环项至: 'Save Loop Items',
  选择一个用来保存当前循环项数值的变量:
    'Select a variable to hold the value of the current loop item',
  计数器变量: 'Counter',
  '只能选择类型为数字的用户自定义变量，该变量会被置为0，每循环一次该变量加1':
    'Only user-defined variables of type numeric can be selected. The variable will be set to 0 and will be incremented by 1 each time the loop loops.',
  错误提示信息: 'Error Message',
  无条件退出循环: 'Exit Loop',
  循环: 'Cycle',
  无条件强制退出: 'Force Exit',
  循环指定次数后退出: 'Exit after looping a specified number of times',
  固定值: 'Fixed Value',
  符合以下条件退出: 'Exit When Conditions',
  无条件继续下一次循环: 'Continue to the next cycle unconditionally',
  符合以下条件继续下一次循环: 'Continue to the next cycle if the following conditions are met',
  循环父容器: 'Parent Container',
  请先设置循环父容器: 'Pls set up the loop parent container first',
  子元素: 'Child',
  拟提取的子元素: 'The child element to be extracted',
  提取至变量: 'Save to ',
  获取子元素: 'Get Child Elements',
  '循环遍历项将被保存至该变量中，不可为空':
    'The loop traversed items will be saved in this variable and cannot be empty.',
  基于父容器循环直接子元素: 'Loop direct child elements based on parent container',
  '先定位父容器节点，然后对其直接子元素进行遍历循环，直接子元素可以是不同类型的页面节点':
    'First locate the parent container node, and then traverse its direct child elements. The direct child elements can be different types of page nodes.',
  基于重复的网页元素: 'Based on repeated web elements',
  '先查找页面内具备重复特征的页面节点，然后对这些页面节点进行遍历循环':
    'First find the page nodes with repeated characteristics in the page, and then perform a traversal loop on these page nodes.',
  循环体选择: 'Loop Body',
  拟从循环体容器中提取的网页元素数量: 'The num of page elements to be extracted',
  请设定计数器变量: 'Set Counter Variable',
  '任务状态置为：': 'The task status is set to:',
  '无论当前所在上下文，直接中断当前主流程':
    'Regardless of the current context, directly interrupt the current main process.',
  任务状态置为: 'The task status is set to',
  'true：代表成功': 'true: represents success',
  'false：代表失败': 'false: represents failure',
  强行: 'Force',
  不: 'No',
  中断当前正在执行流程的所有分身: 'Interrupt all browsers currently executing the process',
  '终止当前流程任务，如果还有未执行的分身则不再执行':
    'Terminate the current process task. If there are still unexecuted browsers, they will not be executed again.',
  强行中断当前正在执行流程的所有分身: 'Forcibly interrupt all browsers executing the process',
  '无条件跳转至指定的流程节点，锚点名称需要事先声明':
    'Unconditionally jump to the specified process node, the anchor name needs to be declared in advance',
  跳转锚点: 'Goto Anchor',
  包含ELSE语句: 'Contains ELSE Statement',
  '（需在循环体内另行指定退出循环的条件）':
    '(The conditions for exiting the loop need to be specified separately in the loop body)',
  固定次数: 'Fixed Times',
  '当进入循环体前对表达式进行求值（只计算一次），循环指定次数后退出循环，如果在循环体中遇到退出节点则根据退出节点的条件进行判断':
    'When entering the loop body, the expression is evaluated (only calculated once), and the loop is exited after the specified number of times. If an exit node is encountered in the loop body, it is judged according to the conditions of the exit node.',
  循环次数: 'Cycles',
  子流程: 'SubProcess',
  请选择子流程: 'Pls select a subprocess',
  AJAX请求得到响应: 'AJAX request gets response',
  事件: 'Event',
  定时触发间隔: 'Timing Trigger Interval',
  '本机人工干预是指流程执行到此节点时会弹对话框由用户确认是否继续，只有点击“确认完成”或者达到该节点的超时时长后，流程才会继续执行':
    'Native manual intervention means that when the process reaches this node, a dialog box will pop up to ask the user to confirm whether to continue. The process will not continue until "Confirm Complete" is clicked or the timeout of the node is reached.',
  待遍历键值对: 'Key-Value Pairs',
  键值对: 'KeyValue Pair',
  保存循环项键名至: 'Save Loop Item Key',
  '将当前正在循环项的键名（Key）保存至变量':
    'Save the key name (Key) of the currently looped item to a variable',
  请选择拟输出的变量: 'Pls select the variable to be output',
  保存循环项键值至: 'Save Loop Item Value',
  '将当前正在循环项的键值（Value）保存至变量':
    'Save the key value (Value) of the currently looped item to a variable',
  从: 'From',
  开始到: 'Start',
  '结束，递增值为': 'End, Increment',
  '，将当前循环值保存到': ', Save Loop',
  起始值: 'Start Value',
  结束值: 'End Value',
  递增值: 'Increment',
  保存循环值至: 'Save Loop to',
  '保存当前循环数值的变量从起始值开始，此变量的值将随循环的递增值变化':
    'The variable holding the current loop value starts from the starting value, and the value of this variable will change with the incrementing value of the loop.',
  返回值: 'return value',
  '当前上下文是在子流程内，则退出子流程；如果在主流程内，则中断流程':
    'If the current context is within a subprocess, exit the subprocess; if it is within the main process, interrupt the process.',
  当前指令不包含任何输入内容: 'The current command contains no input content',
  定时器注销条件: 'Timer logout conditions',
  'Try-Catch代码块没有特定属性': 'Try-Catch code blocks have no specific properties',
  '在Try-Catch代码块中执行的流程节点，遇到异常时会执行“异常处理机制”中的流程节点':
    'The process node executed in the Try-Catch code block will execute the process node in the "Exception Handling Mechanism" when an exception is encountered.',
  遍历数组: 'Traverse Array',
  保存当前循环项至: 'Save Loop Item',
  个变量赋值: 'variable assignment',
  拟对: 'Set',
  获取全部URL: 'Get All URLs',
  获取域名: 'Get Domain',
  获取网页标题: 'Get Page Title',
  至: 'to',
  将节点: 'Move Node',
  元素文本内容: 'element text content',
  元素源代码: 'Element source code',
  元素值: 'element value',
  链接地址: 'link address',
  元素属性: 'Properties',
  提取节点: 'Extract Nodes',
  属性至变量: 'attribute to variable',
  操作内容: 'Operation',
  '自动补充前缀（http://或https://）': 'Automatically supplement prefix (http:// or https://)',
  属性名称: 'Property name',
  将节点列表: 'List Nodes',
  父元素: 'Parent',
  相邻元素: 'Adjacent',
  关联方式: 'Related',
  子元素位置: 'Child Position',
  所有子元素: 'All Children',
  指定位置的子元素: 'Child at Position',
  相邻元素位置: 'Adjacent Position',
  上一个相邻元素: 'Previous Adjacent',
  下一个相邻元素: 'Next Adjacent',
  '请输入子元素的位置，顺序从1开始': 'Enter the position of the child elements, begin from 1',
  日志内容: 'Log Content',
  获取随机: 'Random',
  数字最小值: 'Minimum',
  数字最大值: 'Maximum',
  数字最大值不能小于数字最小值: 'The maximum value cannot be less than the minimum value',
  字符串长度: 'String Length',
  保存至变量: 'Save to Variable',
  至变量: 'to variable',
  文件编码: 'File Encoding',
  '文件类型处理方式不一样：': 'File types are handled differently:',
  '文本文件：保存为字符串': 'Text file: save as string',
  'JSON文件：保存为JSON对象': 'JSON file: Save as JSON object',
  'Properties文件：保存为key/value对象': 'Properties file: saved as key/value object',
  'YAML文件：以YAML格式解析文件，保存为一个对象':
    'YAML file: parse the file in YAML format and save it as an object',
  'CSV文件：保存为一个二维数组': 'CSV file: saved as a two-dimensional array',
  文本文件: 'Text',
  JSON文件: 'JSON',
  Properties文件: 'Properties',
  YAML文件: 'YAML',
  CSV文件: 'CSV',
  文件内容: 'Content',
  截取当前显示内容: 'Capture the current display content',
  文件名: 'File Name',
  X坐标: 'X',
  坐标像素: 'pixel',
  Y坐标: 'Y',
  尺寸像素: 'pixels',
  另存为文件: 'Save File',
  截图文件路径: 'Screenshot file path',
  提取文件路径至变量: 'Save File Path',
  提取包含文本: 'Extract containing text',
  的元素至变量: 'elements to variables',
  '(element)=>{//element是一个标准的DOM元素//可以修改JS代码来判断该元素是否符合要求returntrue;}':
    '(element)=>{//element is a standard DOM element//You can modify the JS code to determine whether the element meets the requirements returntrue;}',
  查找位置: 'Location',
  '在指定的区域内查找，可为空，为空则在当前页面body内查找':
    'Search within the specified area, which can be empty. If it is empty, it will search within the body of the current page.',
  '可为空，默认为页面body': 'Can be empty, defaults to page body',
  搜索文本: 'Search Text',
  RPA引擎会搜索出包含该文本的网页元素:
    'The RPA engine will search for web page elements that contain this text',
  只返回第1个匹配的元素: 'Return the first matching element',
  '如果页面指定区域包含有多个匹配结果，则只返回第1个结果':
    'If the specified area of the page contains multiple matching results, only the first result will be returned.',
  返回所有匹配的元素: 'Return all matching elements',
  '如果页面指定区域包含有多个匹配结果，则全部返回，返回值是一个数组':
    'If the specified area of the page contains multiple matching results, all will be returned, and the return value is an array.',
  精确查找: 'Exact Search',
  '当找到匹配的结果时，系统会回调此函数，并将网页节点作为参数element传递，请在此函数中判断是否是您要寻找的页面节点，当返回值为true时代表匹配到此节点，一旦返回true则不再继续查找':
    'When a matching result is found, the system will call back this function and pass the web page node as the parameter element. Pls judge whether it is the page node you are looking for in this function. When the return value is true, it means that this node is matched. Once If true is returned, the search will no longer continue.',
  脚本内容最长支持10000个字符: 'Script content can be up to 10,000 characters long',
  '如果存在匹配的结果，系统会回调此函数，并将网页节点作为参数element传递，请在此函数中判断是否是您要寻找的页面节点，当返回值为true时代表匹配到此节点':
    'If there is a matching result, the system will call back this function and pass the web page node as the parameter element. Pls use this function to determine whether it is the page node you are looking for. When the return value is true, it means that this node is matched.',
  目标路径: 'Target Path',
  '同步文件注意事项：': 'Notes on synchronizing files:',
  '1.云盘只能与RPA工作目录之间互相同步':
    '1. The cloud drive can only be synchronized with the RPA working directory.',
  '2.本地只能与RPA工作目录之间互相同步':
    '2. The local computer can only synchronize with the RPA working directory.',
  请输入源文件路径: 'Pls enter the source file path',
  请输入目标路径: 'Pls enter the target path',
  取消勾选: 'Uncheck',
  反选: 'Counter-election',
  直接操作: 'Direct',
  '表达式返回ture代表勾选，返回false代表取消勾选':
    'The expression returns true to represent the check, and returns false to represent the uncheck.',
  左键: 'Left',
  右键: 'Right',
  中键: 'Middle',
  单击: 'Click',
  双击: 'Double Click',
  正中心: 'Center',
  随机位置: 'Random',
  点击类型: 'Click Type',
  点击位置: 'Click Location',
  点击元素随机位置: 'Random Position',
  点击元素正中心: 'Center of the Element',
  点击元素指定位置: 'Specify the Position',
  相对元素左上角的位置: 'Position relative to the upper left corner of the element',
  横坐标: 'x-axis',
  纵坐标: 'y-axis',
  辅助键: 'Auxiliary Keys',
  文件: 'Document',
  指定文件链接: 'File Link',
  下载链接: 'Download Link',
  页面节点点击: 'Page Node Click',
  请输入页面节点: 'Pls enter the page node',
  '文件下载成功后，将文件的本地路径保存到该变量':
    'After the file is downloaded successfully, save the local path of the file to this variable',
  拖放节点: 'Drag and drop nodes',
  到指定: 'to designated',
  位置: 'Location',
  拖动到指定位置: 'Drag to location',
  请设置拖放位置: 'Pls set the drag and drop position',
  水平方向拖动: 'Drag hor',
  '像素，垂直方向拖动': 'pixels, drag ver',
  拖动到指定元素: 'Drag to specified element',
  请输入元素选择器: 'Pls enter element selector',
  '根据当前标签页正在访问的URL及输入的用户名，自动检索并匹配到分身属性中存储的网站密码列表，在无需知晓密码内容的情况下进行代填':
    'Based on the URL being accessed by the current tab and the entered user name, automatically retrieve and match the website password list stored in the avatar attribute, and fill in the password without knowing the content.',
  '如该URL下只有一个用户时可留空，否则需要指定用户名':
    'If only one user, you can leave it blank, otherwise need to specify.',
  用户名节点: 'User Name',
  密码节点: 'Password',
  在节点: 'at node',
  悬浮位置: 'Hover Pos',
  悬浮元素随机位置: 'Random Position',
  悬浮元素正中心: 'Center',
  悬浮元素指定位置: 'Specify the Position',
  回车键: 'Enter',
  退格键: 'Backspace',
  空格键: 'Space',
  方向上: 'Up',
  方向下: 'Down',
  方向左: 'Left',
  方向右: 'Right',
  水平方向: 'Hor',
  垂直方向: 'Ver',
  水平方向坐标: 'Hor Coordinates',
  相对于浏览器视图左上角的坐标值:
    'Coordinates relative to the upper left corner of the browser view',
  坐标: 'Coordinate',
  垂直方向坐标: 'Ver Coordinates',
  设置节点: 'Set Node',
  为: '',
  选择方式: 'Options',
  按选项内容: 'By option content',
  按选项位置: 'By option location',
  选择值: 'Select value',
  在元素: 'in element',
  处输入: 'Enter at',
  输入值: 'Input Value',
  回车辅助键: 'Auxiliary Key',
  输入前先清空已有内容: 'Clear existing content before entering',
  '基于复制/粘贴的快速输入': 'Copy/paste based fast input',
  支持多行文件路径: 'Supports multi-line file paths',
  向上: 'up',
  向下: 'Down',
  滚动: 'Scroll',
  鼠标位置: 'Mouse Position',
  '鼠标滚动事件必须在页面的某个位置进行滚动，请输入定位鼠标位置的页面节点（可为空）':
    'The mouse scroll event must scroll at a certain position on the page. Pls enter the page node that locates the mouse position (can be empty)',
  滚动方向: 'scroll direction',
  滚动次数: 'Number of scrolls',
  '鼠标滚动是通过模拟鼠标滚轮事件而产生的，每次滚动将产生若干像素的距离移动':
    'Mouse scrolling is generated by simulating mouse wheel events. Each scroll will produce a distance movement of several pixels.',
  第: 'No.',
  列: 'Column',
  '开始，共': 'Start, total',
  请选择一个Excel对象: 'Pls select an Excel object',
  Sheet页: 'Sheet',
  '选填，可输入Sheet页位置或名称，默认为第一个Sheet':
    'Optional, the sheet no/name, default is the first',
  列名: 'Column',
  '请输入列名（支持A或1）': 'Pls enter a column name (supports A or 1)',
  列数: 'Num of Columns',
  行数: 'Rows',
  请输入拟要删除多少列: 'Pls enter how many columns you want to delete',
  行号: 'Line Number',
  '请输入行号，从1开始': 'Pls enter the line number, starting from 1',
  请输入拟要删除多少行: 'Pls enter how many rows you want to delete',
  循环行: 'loop row',
  循环列: 'Loop column',
  循环指定区域: 'Loop Designated Areas',
  循环所有数据内容: 'Loop All Data',
  读取方式: 'Reading Meth',
  开始列名: 'Start Col Name',
  开始行号: 'Start Row Num',
  结束列名: 'End Col Name',
  结束行号: 'End Row Num',
  高级选项: 'Advanced',
  仅读取单元格显示的内容: 'Read only what the cell displays',
  '勾选此选项时，返回值为肉眼看到的文本；若不勾选，整数会被读取成小数，日期会被读取成日期对象':
    'When this option is checked, the return value is the text seen by the naked eye; if it is not checked, the integer will be read as a decimal and the date will be read as a date object.',
  清除单元格内容的前后空格: 'Trim cell content spaces',
  '勾选此选项时，返回值会自动截取内容前后的空格与换行符':
    'When this option is checked, the return value will automatically intercept the spaces and newlines before and after the content.',
  保存当前行号至: 'Save current line number to',
  获取Excel对象: 'Get Excel Obj',
  的总列数至: ' total number of columns is up to',
  的第一个可用列号至: 'The first available column number of to',
  的第一个可用行号至: 'The first available line number of to',
  的总行数至: 'The total number of rows is up to',
  列前插入: 'Insert before column',
  请输入拟要插入多少列: 'Pls enter how many columns you want to insert',
  行前插入: 'Insert before line',
  请输入拟要插入多少行: 'Pls enter how many rows you want to insert',
  保存至: 'Save to',
  '可选，留空则会创建一个空白Excel对象': 'Optional, leaving blank will create a blank excel obj',
  文件密码: 'File Password',
  '可选，用于对Excel文件进行解密': 'Optional, used to decrypt excel files',
  单元格内容: 'Cell Content',
  整行内容: 'Entire Row',
  整列内容: 'Entire Column',
  指定区域的内容: 'Contents of the specified area',
  所有数据内容: 'All Data',
  读取Excel对象: 'Read Excel object',
  请输入要读取多少: 'Pls enter how much to read',
  保存路径: 'Save to',
  '指定文件路径（留空则为原文件）':
    'Specify the file path (leave blank to return the original file)',
  写入Excel对象: 'Write to Excel',
  写入方式: 'Writing Method',
  写入内容: 'Write Content',
  要写入的内容: 'Write Content',
  请输入要写入的内容: 'Pls enter what you want to write',
  将源文件夹: 'Change the source folder',
  复制到目录文件夹: 'Copy to directory folder',
  要复制的文件夹: 'Folder to copy',
  请输入要复制的文件夹的路径: 'Pls enter the path to the folder to copy',
  目标文件夹: 'Target Folder',
  文件夹路径: 'Folder Path',
  请输入目标文件夹路径: 'Pls enter the destination folder path',
  如果文件存在: 'If Exists',
  覆盖: 'Overwrite',
  跳过: 'Skip',
  停止: 'Stop',
  要复制的文件: 'Files to Copy',
  在: 'exist',
  下新建文件夹: 'Create a new folder under',
  '，将新建文件夹路径保存至': ', save the new folder path to',
  父文件夹路径: 'Parent Folder',
  请输入要在其中新建文件夹的文件夹路径:
    'Pls enter the folder path where you want to create a new folder',
  文件夹名称: 'Folder name',
  请输入要新建的文件夹名称: 'Pls enter the name of the folder to be created',
  请输入要删除的文件夹的路径: 'Pls enter the path to the folder you want to delete',
  要删除的文件: 'files to delete',
  请输入要清空的文件夹的路径: 'Pls enter the path to the folder you want to empty',
  移动到目录文件夹: 'Move to directory',
  要移动的文件夹: 'Folder to move',
  请输入要移动的文件夹的路径: 'Pls enter the path to the folder you want to move',
  要移动的文件: 'Files to Move',
  请输入您要移动的文件路径: 'Pls enter the path to the file you want to move',
  读取路径: 'Read Path',
  下的文件列表至: 'file list',
  请输入您要读取的文件路径: 'Pls enter the file path you want to read',
  名称匹配规则: 'Name Matching',
  '可选，以逗号分隔开，如“.jpg,.bmp,.png”':
    'Optional, separated by commas, such as ".jpg,.bmp,.png',
  递归查找所有子文件夹: 'Find all subfolders recursively',
  '忽略隐藏的文件/夹': 'Ignore hiddened files/folders',
  排序因素: 'Sorting Factors',
  排序规则: 'Sorting Rules',
  递增: 'Increment',
  递减: 'Decreasing',
  下的文件夹列表至: 'List of folders under',
  请输入您要读取的文件夹路径: 'Pls enter the path to the folder you want to read',
  递归读取子文件夹: 'Read subfolders recursively',
  跳过隐藏文件夹: 'Skip hidden folders',
  内容至: 'Content to',
  'Buffer：保存为一个Buffer对象': 'Buffer: Save as a Buffer object',
  Buffer对象: 'Buffer object',
  将文件夹: 'the folder',
  重命名为: 'Rename to',
  要重命名的文件夹: 'Folder to rename',
  新的文件夹名称: 'new folder name',
  请输入新的文件夹名称: 'Pls enter a new folder name',
  目标文件: 'Target file',
  新的名称: 'New Name',
  '请输入新的名称，不包含路径': 'Pls enter a new name without the path',
  追加: 'Append',
  该节点暂不支持设置特定属性: 'This node does not support specific attributes.',
  '使用此节点前提是：流程属性中的分身执行策略设置为动态指定；只能在初始子流程中使用此节点，只有指定的分身才会执行后续流程':
    'The prerequisite for using this node is that the avatar execution strategy in the process properties is set to dynamically specified; this node can only be used in the initial subprocess, and only the specified avatar will execute subsequent processes.',
  根据分身名称: 'According to Avatar Name',
  根据分身ID: 'According to Avatar ID',
  分身顺序: 'order of browsers',
  '可指定多个值，每行一个': 'Multiple values can be specified, one per line',
  '只适用于流程属性之分身策略为“默认不打开浏览器分身”的场景':
    'Only applicable to scenarios where the avatar policy of the process attribute is "Do not open the browser by default',
  '只能在初始子流程中使用此节点，被指定的分身将不再执行后续流程':
    'This node can only be used in the initial subprocess, and the designated avatar will no longer execute subsequent processes.',
  根据分身顺序: 'According to the order of browsers',
  窗口大小: 'window size',
  窗口位置: 'window position',
  请求: 'ask',
  响应结果至: 'response result to',
  方法: 'method',
  请求参数: 'Request Paras',
  '表达式模式不支持在此处指定请求参数，请直接在URL中指定':
    'Expression mode does not support specifying request parameters here. Pls specify them directly in the URL.',
  '多行，每行请求参数为“参数名=参数值”':
    'Multiple lines, the request parameter for each line is "parameter name = parameter value',
  协议头: 'Protocol Header',
  '多行，每行协议头为“协议头名称:协议头内容':
    'Multiple lines, the protocol header of each line is "Protocol header name: Protocol header content',
  协议体: 'Protocol Body',
  响应类型: 'Response Type',
  普通文本: 'Normal Text',
  JSON对象: 'JSON object',
  保存响应结果: 'Save Response',
  主题: 'Subject',
  检测通过: 'Test Passed',
  自定义邮件服务检测通过: 'Custom mail service detection passed',
  检测失败: 'Detection failed',
  '使用花漾的邮件服务时，只允许给当前团队成员发送电子邮件；使用自己的邮件服务时可给任何人发送邮件，但请注意不要发送垃圾邮件':
    "When using HuaYoung's email service, you are only allowed to send emails to current team members; when using your own email service, you can send emails to anyone, but Pls be careful not to send spam.",
  使用花漾邮件服务器: 'Use HuaYoung mail server',
  自行设置邮件服务器: 'Set up your own mail server',
  SMTP服务器: 'SMTP server',
  立即检测: 'Test now',
  '【花漾RPA通知】': '[HuaYoung RPA Notice]',
  '使用花漾邮件服务，只允许给当前团队成员发送邮件；使用自己的邮件服务，可以给任意人发送':
    'Using the HuaYoung email service, you are only allowed to send emails to current team members; using your own email service, you can send emails to anyone',
  主题最大不超过16个字符: 'The subject cannot exceed 16 characters.',
  不超过5120个字符: 'No more than 5120 characters',
  附件路径: 'Attach',
  '只有使用用户自己的邮件服务时才允许发送附件，一次最多发送5个附件，以换行符分割，附件合计大小不超过100M':
    'Attachments are only allowed when using the own email service. A maximum of 5 attachments can be sent at a time, separated by line breaks. The total size of the attachments does not exceed 100M.',
  '一次最多发送5个附件，以换行符分割，附件合计大小不超过100M':
    'A maximum of 5 attachments can be sent at a time, separated by line breaks. The total size of the attachments does not exceed 100M.',
  只有使用用户自己的邮件服务才允许发送附件: 'Only allowed when using the own mail service',
  '为避免垃圾短信，限制如下：只允许给当前团队内的成员发送手机短信，且每发送1条短信（按收件人数量累积），将扣取10花瓣':
    'In order to avoid spam messages, the restrictions are: allowed to be sent to members of the current team, and 10 tokens will be deducted for each text message sent (accumulated by the num of recipients)',
  内容最大不超过16个字符: 'The content must not exceed 16 characters.',
  不超过16个字符: 'No more than 16 words',
  '为避免骚扰用户，限制如下：只允许给当前团队内的成员发送微信模板消息':
    'In order to avoid harassing users, the restrictions are as follows: only WeChat template messages are allowed to be sent to members of the current team.',
  单笔消息: 'Single Message',
  概览详情消息: 'Master/Details Message',
  '消息摘要：': 'Message summary:',
  消息摘要: 'Message summary',
  消息摘要不能超过128个字符: 'Message summary cannot exceed 128 characters',
  '请输入消息摘要（不超过128个字符），消息摘要会显示在模板消息中':
    'Pls enter a message summary (no more than 128 characters). The message summary will be displayed in the template message.',
  '消息详情：': 'Message details:',
  消息详情: 'Message details',
  消息详情不能超过1000个字符: 'Message details cannot exceed 1000 characters',
  '请输入消息详情（不超过1000个字符，支持markdown语法），当点击模板消息才会查看到消息详情':
    'Pls enter the message details (no more than 1000 characters, markdown is available). You can only view the message details when you click on the template message.',
  消息内容不能超过1000个字符: 'Message content cannot exceed 1000 characters',
  '单笔消息最长不超过1000个字符，只有前128个字符才会显示在概览消息中，用户需点击模板消息查看详情才能够查看到全部内容':
    'The maximum length of a single message cannot exceed 1,000 characters. Only the first 128 characters will be displayed in the overview message. Users need to click on the template message to view details to view the full content.',
  个标签页: 'tabs',
  当前标签页: 'Current tab',
  其它标签页: 'Other tabs',
  关闭当前标签: 'Close current tab',
  关闭第: 'Close section',
  '请输入标签页的位置，-n表示倒数第n个标签页；如：1代表第1个标签，-1代表倒数第1个标签':
    'Pls enter the location of the tab, -n means the nth tab from the bottom; for example: 1 means the 1st tab, -1 means the 1st tab from the bottom',
  关闭其它标签: 'Close other tabs',
  等待页面节点: 'Wait for page node',
  出现: 'Appear',
  等待页面节点出现: 'Wait Page Node',
  '网络异常的容错机制：': 'Network Errors:',
  '当网络连接异常时进行刷新页面，尝试': 'When network is abnormal, refresh & try',
  次为止: ' times',
  网址: 'URL',
  请输入您要访问的网址: 'Pls enter the URL you want to visit',
  '暨网页来源，可不填': 'The source of the website is optional.',
  '加载完毕的判断依据：': 'Judgment basis for loading completed:',
  未输入脚本: 'No script entered',
  '请输入JavaScript脚本，脚本的运行空间为当前RPA引擎（NodeJS环境），您可以操作所有的系统变量与用户自定义变量':
    'Pls enter a JavaScript snippet. The runtime environment is RPA engine (NodeJS environment). You can get all system variables or get/set user variables.',
  请输入JavaScript脚本: 'Pls enter JavaScript snippet',
  元素不能为空: 'Element cannot be empty',
  '请设定网页加载完毕的判断依据：': 'Pls set the basis for judging whether the web page is loaded:',
  '(ctx)=>{//请在此处书写代码，通过ctx.params.{变量名}来引用流程变量}':
    '(ctx)=>{//Write code here and reference variable through ctx.params.{variable name}}',
  '//请在此处书写代码': '//Write code here',
  '请输入JavaScript脚本，脚本的运行空间为当前浏览器，您可以获取window等浏览器对象，也可以存取您在流程定义中的变量':
    'The runtime environment is the browser. You can obtain browser objects such as window, and you can also access variables.',
  滚动到: 'scroll to',
  顶部: 'top',
  底部: 'bottom',
  滚动到元素: 'scroll to element',
  滚动位置: 'scroll position',
  滚动距离: 'scroll distance',
  请输入滚动像素: 'Pls enter scroll pixel',
  滚动到元素出现: 'Scroll until element appears',
  '请确保该页面元素已经加载，如果依赖于页面缓加载技术并通过滚动才能够加载的页面元素不适于使用此选项':
    'Pls make sure that the page element has been loaded. If the page element relies on page caching technology and can be loaded by scrolling, this option is not suitable.',
  距离顶部大约: 'Approximately from the top',
  标签页位置: 'Tab position',
  等待节点不可见: 'Wait for node to be invisible',
  等待节点被删除: 'Wait for the node to be deleted',
  移除该节点: 'Remove this node',
  任意一个元素消失: 'Any element disappears',
  全部元素消失: 'All elements disappear',
  增加新的节点: 'Add new node',
  删除最后的节点: 'Delete the last node',
  固定: 'fixed',
  等待固定时间: 'Wait for fixed time',
  等待随机时间: 'Wait for random time',
  任意一个元素出现: 'Any element appears',
  全部元素出现: 'All elements appear',
  等待结果: 'Waiting Results',
  '满足条件的网页节点出现后，将会根据条件关系，将网页节点保存到对应的变量中':
    'After a web page node that meets the conditions appears, the web page node will be saved to the corresponding variable according to the conditional relationship.',
  文本类型: 'Text',
  可将消息推送给钉钉中的聊天群组: 'Messages can be pushed to chat groups in DingTalk',
  使用教程: 'Tutorial',
  文本内容: 'Text',
  可将消息推送给飞书中的聊天群组: 'Messages can be pushed to chat groups in Feishu',
  可将消息推送给企业微信中的聊天群组: 'Messages can be pushed to chat groups in corporate WeChat',
  请输入群机器人的Webhook: 'Pls enter the Webhook of the group robot',
  剪切: 'Cut',
  重做: 'Redo',
  从此节点开始执行: 'Execution From This Node',
  从此节点开始调试: 'Debug From This Node',
  执行到此节点: 'Execute To This Node',
  '插入到节点“': 'Insert Into',
  的ELSE分支: 'ELSE Branch',
  的异常处理机制: 'Exception handling mechanism',
  后: 'back',
  流程节点不允许跨层级多选: 'Process nodes do not allow cross-level multiple selections',
  请拖动左边的操作节点至此: 'Pls drag the operation node on the left here',
  将节点移动至此: 'Move node here',
  添加操作步骤: 'Add steps',
  暂未设置元素映射: 'No element mapping has been set yet',
  节点使用说明: 'Node usage instructions',
  '确定要删除此子流程吗？': 'Sure to delete this subprocess?',
  '子流程一旦删除，无法恢复，请谨慎操作':
    'Once a subprocess is deleted, it cannot be restored, so Pls operate with caution',
  同步删除流程中的调用节点: 'Synchronously delete the calling node in the process',
  '支持本地文件及以下协议类型：': 'Support local files and the following protocol types:',
  'team_disk://团队云盘文件路径': 'team_disk://team cloud disk file path',
  'user_disk://个人云盘文件路径': 'user_disk://Personal cloud disk file path',
  'attachment://资源文件路径': 'attachment://resource file path',
  'http(s)://远程文件路径': 'http(s)://remote file path',
  '如果路径不含以上前缀且不是绝对路径，则认为是RPA工作目录文件路径，既当前操作系统下的“文档”':
    'If the path does not contain the above prefix and is not an absolute path, it is considered to be the RPA working directory file path, which is the "document" under the current operating system.',
  表达式格式不合法: 'Expression format is illegal',
  当前检索条件下无数据: 'There is no data under the current search conditions',
  请选择至少一个计划: 'Pls select at least one plan',
  个计划: ' plans',
  '流程计划共享-': 'Process plan sharing-',
  导出流程计划: 'Export Plan',
  触发计划暂不支持导出: 'Triggered plans do not currently support export.',
  编辑流程分组: 'Edit Proc Group',
  创建流程分组: 'Create Proc Group',
  '当团队内的流程定义过多时，可通过分组进行管理；每个流程定义可以隶属于多个分组，分组的名称不可重复':
    'When there are too many proc scripts in the team, they can be managed through groups; each script can belong to multiple groups, and the names of the groups cannot be repeated.',
  流程分组名称: 'Group Name',
  分组名称不可重复: 'Group name cannot be repeated',
  流程分组备注: 'Notes',
  流程计划存储文件不合法: 'The process plan storage file is illegal',
  '您选中的文件不是一个合法的流程计划存储文件，无法导入':
    'The file you selected is not a legal process plan storage file and cannot be imported.',
  导入流程计划: 'Import Plan',
  '可将流程计划导出成文件，并在其它团队中导入，以缩短您的实施过程':
    'Process plans can be exported to files and imported into other teams to shorten your implementation process',
  选择存储文件: 'Select storage file',
  '您可以将流程计划存储到文件中，再通过此文件将流程计划导入至其它团队':
    'You can save the process plan to a file and then import the process plan to other teams through this file',
  请选择流程计划存储文件: 'Pls select the process plan storage file',
  未设置流程定义: 'Proc script not set',
  为所有拟导入的流程计划批量指定执行设备:
    'Specify execution devices in batches for all process plans to be imported',
  '如果您选择的设备已经绑定了流程任务卡，则在此设备中执行流程任务将不再扣除花瓣，否则将根据流程任务的执行时长消耗当前团队的花瓣余额':
    'If the device you choose has been bound to a process task card, tokens will no longer be deducted when executing the process task in this device. Otherwise, the token balance of the current team will be consumed based on the execution time of the process task.',
  随机选择: 'random selection',
  为所有拟导入的流程计划批量指定分身列表:
    'Specify avatar lists in batches for all plans to be imported',
  未指定: 'not specified',
  成功导入: 'Imported Successfully',
  个流程计划: ' plans',
  '请输入商品ID，每行一个ID，可为空注：商品列表仅对站内消息有用，其它即时消息软件会忽略此项内容':
    'Pls enter the product ID, one ID per line, can be empty. Note: The product list is only useful for site messages, other instant messaging software will ignore this content',
  会更新所有分身针对此变量的值: 'Will update the value of this variable for all browser avatars',
  流程发布: 'Release',
  '一个流程定义只有在发布后才可以被执行或者被共享；发布后的流程定义可再次进入草稿状态重新编辑，但其版本号会发生变化':
    'A proc script can only be executed or shared after it is published; the published proc script can be re-edited in draft status, but its version number will change.',
  '请为本次版本发布准备一个简短的发版说明以供备忘：':
    'Pls prepare a short note for this release as a reminder:',
  '版本发布说明，最长512个字符': 'Release notes, up to 512 characters',
  '当勾选此选项时，如果流程计划包含此流程定义，则流程计划中包含的流程定义的版本自动升级，但这并不会影响正在运行中的流程任务':
    'When this option is checked, if the process plan contains this proc script, the version of the proc script contained in the process plan will be automatically upgraded, but this will not affect the running process tasks.',
  同步升级共享给其它团队的流程定义与流程计划:
    'Upgrade proc scripts and plans shared with other teams',
  '当勾选此选项时，所有共享选项中约定为“自动更新”的流程定义将会自动更新到最新版本，而包含此流程定义的流程计划也会同步更新':
    'When this option is checked, all proc scripts agreed to "auto-update" in the sharing options will be automatically updated to the latest version, and the process plan containing this proc script will also be updated simultaneously.',
  免费: 'free',
  流程定义续费: 'Process script renewal',
  缺少流程定义: 'Missing proc script',
  '首先需要有流程定义，对流程定义与分身列表进行编排，才能够创建流程计划':
    'First, you need to have a proc script, and arrange the proc script and browser list before you can create a process plan.',
  '首先需要有流程定义，然后执行流程定义才能够生成流程任务':
    'First, you need to have a proc script, and then execute the proc script to generate process tasks.',
  新建流程任务: 'New Proc Task',
  新建流程计划: 'New Proc Plan',
  '对指定的浏览器分身执行某个流程定义，形成流程任务':
    'Execute a proc script on the specified browser to form a task',
  '适用于偶发、单次执行的场景': 'Suitable for occasional and single exec scenarios',
  '流程定义+分身列表+执行条件，编排成流程计划':
    'Script + Browsers + Conditions, arranged into a Plan',
  适用于需要重复执行流程的场景: 'Suitable for scenarios that require repeated exec',
  '流程计划条件满足开始执行，形成流程任务': 'When conditions are met, exec begins to form a task.',
  '手动计划、自动计划、循环计划、触发计划': 'Manual, Auto, Loop, Triggered plan',
  流程市场: 'Marketplace',
  '花漾RPA流程市场上架了非常丰富的自动化流程，可到市场中查询您中意的流程':
    'HuaYoung RPA market has a very rich range of script. You can go to the market to check for your favorite.',
  视频教程: 'Video Tutorials',
  '通过花漾灵动为您提供的视频教程，了解并学习花漾灵动RPA的强大能力':
    'Understand and learn the powerful capabilities of HuaYoung RPA through the video tutorials.',
  '流程计划则是指将流程定义与指定的分身列表（暨浏览器分身环境）进行编排，当流程计划执行时，则形成一笔流程任务':
    'Process planning refers to arranging the proc script and the specified avatar list When the process plan is executed, a process task is formed.',
  '流程计划分为手动、自动、循环、触发等不同类型，分别适用于用户手动执行、自动定时执行、间隔指定时间后循环执行、条件满足时（如文件变化、接收邮件、网络请求等）触发执行等应用场景':
    'Process plans are divided into different types such as manual, automatic, loop, and trigger. They are respectively suitable for manual execution by users, automatic scheduled execution, loop execution after specified intervals, and triggered execution when conditions are met (such as file changes, receiving emails, network requests, etc.) and other application scenarios',
  流程任务是指机器人执行一笔流程时所形成的工作记录:
    'Process tasks refer to the work records formed when the robot executes a process.',
  '当把某个流程定义对指定的分身列表（暨浏览器分身环境）依次执行时，或者当某个流程计划被执行时，都会形成一笔流程任务':
    'When a proc script is executed sequentially against the specified avatar list, or when a process plan is executed, a process task will be formed.',
  '执行流程任务时可以指定在“本地设备”或者“云端执行”，由于花漾RPA采用“拟人化”操作，在本地设备中执行流程时会抢占设备的键鼠，从而影响您同时做其它工作；当在云端执行时能够避免占用您的电脑，但需要支付一点额外的成本':
    'When executing process tasks, you can specify "local device" or "cloud execution". Since HuaYoung RPA adopts "anthropomorphic" operation, executing the process on the local device will preempt the device\'s keyboard and mouse, thus affecting your ability to do other work at the same time; Avoids tying up your computer when running in the cloud, but comes at a little extra cost',
  '流程任务卡是花漾RPA的一种计费模式，针对部分需要长时间或者频繁执行流程的用户，可以通过购买流程任务卡，将流程集中在某台或某几台电脑中执行，从而有效降低RPA的使用成本':
    'The Task Card is a HuaYoung RPA billing model. For users needing long or frequent process execution, they can buy task cards to centralize execution on one or more computers, reducing RPA costs.',
  '流程任务卡分为包周、包月两种形态，并根据绑定设备的CPU核数划分为“CPU<=4核”与“CPU>4核”等不同类型，购买时请您根据您的设备CPU核数进行选择':
    "Task cards come in weekly and monthly subscriptions, categorized by bound device CPU cores (e.g., 'CPU <= 4 cores' and 'CPU > 4 cores'). When purchasing, select based on your device's CPU core count.",
  '流程定义暨流程脚本，是指您希望机器人完成的工作内容的流程化的表达':
    'Process script and process script refer to the process expression of the work content you want the robot to complete.',
  '流程定义有“草稿”与“已发布”两种状态，草稿状态下，可以随时编辑流程定义，在编辑器中能够执行预览，但无法批量执行，也无法编排进某个流程计划；已发布状态下，流程定义可以批量执行，也可以将其编排进某个流程计划':
    'The proc script has two states: "Draft" and "Published". In the Draft state, the proc script can be edited at any time, and preview can be performed in the editor, but it cannot be executed in batches, nor can it be arranged into a process plan; Published state , the proc script can be executed in batches or arranged into a process plan',
  流程执行方式: 'Process execution method',
  执行流程任务: 'Create a Proc Task',
  '指定分身或手机执行此流程，适用于单次、偶尔执行的场景':
    'Specify browser or mobile to execute this proc script, which is suitable for single-time and occasional execution scenarios.',
  创建流程计划: 'Create a Proc Plan',
  '创建流程计划，设置自动、触发、或循环执行等条件，适用于按照某种规则自动执行流程的场景':
    'Create a proc plan and set automatic, triggered, or loop execution conditions, which is suitable for scenarios where processes are automatically executed according to certain rules.',
  可在指定的时间内自动执行流程: 'Automatically execute process within specified time',
  当条件满足时触发执行流程定义: 'Trigger execution proc script when conditions are met',
  前一流程结束后在指定间隔时间到达后创建新的流程:
    'Create a new process task after the specified interval expires after the previous process task ends',
  只有当用户明确手动执行时才会创建新的流程任务:
    'New process tasks are only created if the user explicitly executes them manually',
  '在指定的周期与时间内自动创建新的流程任务，当指定时间到达时如果前一任务还未结束，新的任务也将创建执行':
    'Automatically create new process tasks within the specified time. When the specified time arrives, if the previous task has not ended, a new task will also be created and executed.',
  '自动任务可以禁用，一旦禁用则不会创建新的流程任务':
    'Automatic tasks can be disabled, once disabled no new process tasks will be created',
  '当指定的条件满足时触发执行流程定义，包括文件触发（指定的文件/夹发生变动）、邮件触发（收到指定邮件）、Http请求触发（指定网址被访问）、事件触发（收到一个消息）等':
    'The execution proc script is triggered when the specified conditions are met, including file triggering (the specified file/folder changes), email triggering, HTTP request triggering , and event triggering)',
  '触发任务可以禁用，一旦禁用则不再响应触发动作':
    'Trigger tasks can be disabled. Once disabled, they will no longer respond to trigger actions.',
  '循环计划是指当前一流程任务执行结束后，在指定的间隔时间到达后会创建一个新的流程任务，需要注意的是，如果上一任务没有结束，则新的任务将不会被创建':
    'Loop plan means that after the execution of the current process task ends, a new process task will be created after the specified interval. It should be noted that if the previous task has not ended, the new task will not be created.',
  '循环任务可以禁用，一旦禁用则不再自动创建新的流程任务':
    'Loop plan can be disabled. Once disabled, new process tasks will no longer be automatically created.',
  '手动任务是最简单的计划类型，只有当用户明确手动执行时才会创建新的流程任务':
    'Manual tasks are the simplest type of schedule, new process tasks are only created when the user explicitly executes them manually',
  '是否要立即执行此流程计划？': 'Do you want to execute this process plan now?',
  '您创建的是一个循环计划，但它并不会主动循环执行，必须由您手动发起第一个流程，请确认是否要立即执行？':
    'What you created is a cyclic plan, but it will not automatically execute in a cyclical manner. You must manually initiate the first process. Pls confirm whether you want to execute it immediately?',
  已过滤所有草稿态或好友共享的流程定义: 'Filtered for all draft or friend-shared proc scripts',
  请选择至少一个流程定义: 'Pls select at least one proc script',
  个流程定义: ' scripts',
  共享流程定义: 'Shared Sript',
  '可将流程定义批量共享给您的好友，TA们可以按照约定获得您的流程定义':
    'You can share proc scripts in batches with your friends.',
  选择云盘: 'Cloud Drive',
  本地: 'Local',
  参数模板: 'Parameter template',
  '您可先下载Excel模板文件，在Excel中为每个分身填入输入变量的值后，再上传Excel':
    'You can download the Excel template file first, fill in the value of the input variablein Excel, and then upload it.',
  通过Excel指定输入变量值: 'Specify Values through Excel',
  下载模版文件: 'Download Template',
  上传模版文件: 'Upload File',
  个人新建: 'Personal Creation',
  RPA市场引用: 'Market Ref',
  好友分享: 'Shared by Friends',
  RPA市场复制: 'Market Copied',
  文件导入: 'File Imported',
  花漾Tiktok解决方案: 'HuaYoung Tiktok Solution',
  已发布: 'Published',
  草稿: 'Draft',
  客户端版本需要升级: 'Client version needs to be upgraded',
  '您将要执行的流程定义“': 'The proc script you are about to perform',
  '”需要的客户端版本最小为': 'The minimum required client version is',
  '，当前客户端版本号为': ', the current client version number is',
  '，请点击花漾客户端左侧的支持按钮，进行升级':
    'Pls click the support button on the left side of HuaYoung app to upgrade',
  '该流程未添加操作节点，无法执行':
    'This process has no operation node added and cannot be executed.',
  '尚未对该流程定义编辑任何节点内容，无法发布':
    'No node content has been edited for this proc script and cannot be published.',
  该流程定义正处于编辑状态中: 'The proc script is being edited',
  '请关闭该流程定义的编辑器（关闭前请注意保存）后再发布此流程':
    'Pls close the editor of this proc script (Pls save before closing) before publishing this process.',
  '该流程定义被其它流程计划所引用，无法':
    'This proc script is referenced by other process plans and cannot',
  '流程定义被其它流程计划所引用时，无法':
    'When the proc script is referenced by other process plans, it cannot',
  '，请先删除引用此流程定义的流程计划':
    ', Pls first delete the process plan that references this proc script',
  '确定要退还此流程定义的共享吗？': 'Sure to return the shares defined by this process?',
  '确认要删除流程“': 'Confirm to delete process',
  '”吗？': '?',
  '一旦退还流程定义的共享，该流程定义将从当前团队中移除，您无法再次执行此流程定义':
    'Once the sharing of a proc script is returned, the proc script will be removed from the current team and you will not be able to execute this proc script again.',
  '流程定义一旦删除，不可恢复，但并不会影响已经执行的流程任务':
    'Once the proc script is deleted, it cannot be restored, but it will not affect the process tasks that have been executed.',
  '流程正处于共享状态，无法删除': 'The process is being shared and cannot be deleted',
  '当前流程正在分享给他人，您需要关闭所有分享关系与桥接码后才允许删除':
    'The current process is being shared with others. You need to close all sharing relationships and bridging codes before deleting it.',
  已过期: 'Expired',
  重新进入编辑状态: 'Re-Edit',
  查看流程说明: 'View instructions',
  流程执行: 'Execution',
  编辑流程定义的属性: 'Edit Script Properties',
  删除流程定义: 'Delete Script',
  已发布的流程定义无法编辑: 'Published proc scripts cannot be edited',
  '该流程定义处于已发布状态，需要重新进入草稿状态后才能够编辑':
    'The proc script is in a published state and needs to be re-entered into draft state before it can be edited.',
  处于编辑状态的流程定义无法执行: 'The proc script in editing state cannot be executed',
  '该流程定义处于编辑状态，需要发布后才能够执行':
    'The proc script is in editing status and needs to be published before it can be executed.',
  TK达人管理系统: 'TK Creator Management System',
  建议您在TK子系统中执行此流程:
    'It is recommended that you perform this process in the TK subsystem',
  '当前团队已经购买了TK解决方案的套餐，建议您到TK子系统中执行此流程定义':
    'The current team has purchased the TK solution package. It is recommended that you execute this proc script in the TK subsystem.',
  新建浏览器自动化流程: 'New Browser Proc Script',
  新建手机App自动化流程: 'New Mobile App Proc Script',
  通过好友共享获取流程: 'Get Proc Script from Friends',
  向好友批量共享流程: 'Share Proc Script with Friends',
  新建自动计划: 'New Auto Plan',
  新建: 'New',
  激活流程任务礼品卡: 'Activate Task Gift Card',
  关于花漾RPA流程市场: 'About HuaYoung RPA Market',
  '花漾灵动RPA流程市场已经上架了非常丰富的自动化流程，绝大部分的流程都以免费、开源的形态提供，您可以获取这些流程的源码，再根据自己的需要进行简单的修订即可匹配您的需求':
    'HuaYoung RPA market has now listed a rich array of proc script. The vast majority of these processes are provided for free and in open-source. You can obtain the source code of these processes and make simple modifications to match your needs',
  不再提示: "Don't remind again",
  RPA流程市场: 'Marketplace',
  依据卡号检索: 'Search by Card No',
  最近执行: 'Recently',
  使用频次: 'Usage Freq',
  执行状态: 'Exec Status',
  全部流程定义: 'All Script',
  全部计划: 'All Plans',
  我的计划: 'My Plans',
  列表: 'List',
  '确定要删除此流程分组吗？': 'Sure to delete this proc group?',
  '流程分组一旦删除将无法恢复，请谨慎操作':
    "Once proc group is deleted, it can't be restored. Please be careful",
  无法删除一个非空的流程分组: 'Unable to delete a non-empty proc group',
  '您需要修订当前分组中所有流程定义的分组信息，只有当流程分组内没有流程定义时才可以删除':
    'It can only be deleted when the group has no proc.',
  团队花瓣余额不足: 'Team token balance is low',
  未找到可执行设备: 'Executable device not found',
  未知原因: 'Unknown reason',
  请先选中1至多个流程计划: 'Pls select 1 or more plans first',
  批量导出: 'Batch Export',
  '确定删除所选流程计划吗？': 'Sure to delete selected plans?',
  流程计划一旦删除不可恢复: "Once proc plan is deleted, it can't be restored.",
  '下次执行时间（预估）': 'Next Exec Time',
  您无法执行他人的流程计划: "You can't run others' proc plans.",
  '您可以查看他人的计划，但无法执行': "You can view others' plans but can't execute them.",
  '分身列表为空，无法执行': 'The browser list is empty cannot be executed.',
  请先添加分身后再执行计划: 'Pls add a browser first before executing',
  '确定删除此流程计划吗？': 'Sure to delete this plan?',
  '您可以查看、禁用、甚至删除他人的计划，但无法编辑与执行':
    "You can view, disable, or even delete others' plans, but can't edit or execute them.",
  '确定要禁用此自动计划吗？': 'Sure to disable this auto plan?',
  '自动执行的流程计划一旦被禁用，则不会再次自动执行，除非重新启用':
    "Auto plan once disabled, won't run again unless re-enabled",
  '确定要禁用此循环计划吗？': 'Sure to disable this loop plan?',
  '循环计划一旦被禁用，则不会再次创建新的流程任务，除非重新启用':
    'Once a loop plan is disabled, new tasks will not be created again unless re-enabled.',
  '确定要禁用此触发计划吗？': 'Sure to disable this triggered plan?',
  '触发计划一旦被禁用，则不会再次创建新的流程任务，除非重新启用':
    'Once a triggered plan is disabled, new tasks will not be created again unless re-enabled.',
  您无法编辑他人的流程计划: "Cannot edit someone else's plan",
  执行间隔: 'Interval',
  停止执行: 'Stop Exec',
  下次执行: 'Exec Next Time',
  未开启网页截屏: 'Screenshot disabled',
  执行历史: 'Exec History',
  '流程任务创建失败，失败原因为：': 'Task creation failed. The reason is:',
  '流程任务创建失败：': 'Task creation failed:',
  暂无执行历史: 'No exec history yet',
  创建新的计划: 'New plan',
  '流程计划是指将流程定义与分身列表进行适度的编排，可自动、可手动的予以定期执行':
    'A plan refers to arranging proc with browser lists, allowing for scheduled execution either automatically or manually.',
  任务ID: 'Task ID',
  系统遇到了不可预知的错误: 'System encountered an unforeseen error.',
  '任务文件大小：': 'Task file size:',
  '任务文件状态：已清理': 'Task file status: Cleaned',
  '任务文件状态：': 'Task file status:',
  '确定要清理此流程任务的文件吗？': 'Sure to clean the files for this task?',
  '1.流程任务中的审计录像归属于会话访问日志，如要清理审计录像请到会话访问日志中处理':
    '1. Audit videos in tasks belong to session access logs. To clear videos, please manage in session access logs.',
  '2.流程任务中的其它文件一旦被清理无法恢复，且会清理该任务的所有输出文件（主要包括所有浏览器分身的截屏文件）':
    "2. Other files in tasks, once cleared, can't be restored (mainly including screenshots of all browser browsers).",
  '3.您可到云盘“存储空间分析”中设置会话审计录像与RPA流程任务输出文件的自动清理策略':
    '3. You can set auto-cleanup policies for audit videos and RPA task output files in the storage "Space Analysis',
  清理文件: 'Clean',
  已结束: 'Over',
  等待调度: 'Waiting',
  启动失败: 'Start Failed',
  执行中: 'Executing',
  全部成功: 'All Succ',
  部分成功: 'Part. Succ',
  全部失败: 'All Failed',
  异常结束: 'Aborted',
  '失败原因：': 'Failure Reason:',
  '一张流程任务卡同时只能绑定在一台客户端（客户端的CPU核数要和流程任务卡相匹配）；当客户端绑定流程任务卡后，在该客户端中执行RPA流程将不再扣除花瓣':
    "A task card can only bind to one device at a time (the client's CPU cores must match). Once a device is bound to a task card, executing RPA tasks on that device won't deduct tokens",
  '当前客户端正在执行流程任务中，无法解绑':
    'The device is currently executing tasks and cannot be unbound.',
  '当前客户端正在执行流程任务，您需要先中断此流程任务后才能够完成解绑':
    'The device is currently executing tasks. You need to interrupt this process task before.',
  '确定要解除与此客户端的绑定关系吗？': 'Sure to unbind this device?',
  '一张卡同时只能绑定在一台客户端；当解除绑定关系后，可以重新绑定到其它的客户端设备':
    'A task card can only be bound to one device at the same time.',
  '确信要删除选中的流程任务卡吗？': 'Sure to delete the selected task card?',
  '流程任务卡一旦删除，无法恢复，请确认是否要继续删除':
    'Once the card is deleted, it cannot be restored. Pls confirm.',
  无法删除尚处于有效期内的流程任务卡: 'Unable to delete a task card that is still valid',
  '处于有效期内的流程任务卡无法删除，只有过期的流程任务卡才能够删除':
    "Active task cards can't be deleted. Only expired ones can be removed",
  解绑客户端: 'Unbind the device',
  '设备名称：': 'Device Name:',
  '设备标识：': 'Device ID:',
  '操作系统：': 'OS:',
  '有效（': 'efficient(',
  '天）': 'days)',
  任务属性: 'Task Properties',
  '任务ID：': 'Task ID:',
  '任务名称：': 'Task name:',
  执行身份: 'Executor',
  '确定要停止在云端执行此流程任务吗？': 'Sure to stop executing this task in the cloud?',
  '系统正在为您寻找空闲的云端设备，这需要一定的时间，请您耐心等候；调度最大时长30分钟，如果30分钟内还未寻找到空闲的云端设备，请您反馈给':
    'This system is searching for available devices for you; this may take some time. The max scheduling time is 30 minutes. If no cloud device is found, please report ',
  继续等待: 'Keep Waiting',
  该流程任务正在等待调度中: 'The task is waiting for scheduling',
  '系统正在您指定的本地设备中执行此流程，这需要一点时间（大概1分钟左右），请您耐心等候':
    'This system is running this task on your specified device. It might take a moment (around 1 minute). Pls be patient.',
  '等待调度中...': 'Waiting for Scheduling...',
  '确定要停止此任务吗？': 'Sure to stop this task?',
  任务一旦停止将无法恢复: 'Once the task is stopped, it cannot be resumed',
  全部停止: 'Stop All',
  录像已清理: 'Video Cleaned',
  '确定要删除选中的截图吗？': 'Sure to delete the selected screenshot?',
  截图一旦删除不可恢复: 'Once deleted, screenshots cannot be recovered',
  删除选中截图: 'Delete Screenshot',
  '确定要清空所有的截图吗？': 'Sure to clear all screenshots?',
  清空全部截图: 'Clear All',
  关于流程审计文件的自动清理: 'About cleaning of task audit files',
  '1.流程任务中的审计录像归属于分身会话访问日志':
    '1. The audit recording in the task belongs to the session access log',
  '2.流程任务中的其它文件主要是截屏文件': '2. Other files in the tasks are mainly screenshot files',
  '3.以上两项均可到云盘“存储空间分析”中设置自动清理策略':
    '3. Both of the above can be set in the storage "Space Analysis" to set automatic cleanup policies.',
  云盘存储空间分析: 'Storage Space Analysis',
  自动清理: 'Auto Cleaning ',
  '节点名称：': 'Node Name:',
  '执行时间：': 'Exec Time:',
  '（第': '( ',
  '次执行）': ' execution)',
  文件已清理: 'File cleaned',
  '日志内容过大，无法复制，请使用下载功能': 'The log is too large. Pls use the download function.',
  下载: 'Download',
  '当前日志格式化Level为：': 'Formatting level is:',
  '持续时间：': 'Duration:',
  '消耗花瓣：': 'Consume tokens:',
  查看任务详情: 'View task details',
  默认查看: 'Viewer',
  视频: 'Video',
  截图: 'Screenshot',
  '针对云端执行的流程提供两种实时查看方式，分别是视频流与截图流，开启视频流的前提是：当前浏览器分身已经开启了安全策略且本次流程任务执行时开启了审计录像':
    'There are two real-time viewing modes: video stream and screenshot stream. To enable video streaming, the current browser must have its security policy activated',
  '确定要停止此分身正在执行的流程吗？': 'Sure to stop the task being executed by this browser?',
  该分身正在执行的流程一旦停止将无法恢复: 'Once the task is stopped, it cannot be resumed.',
  进入云端执行实时查看失败: 'Failed to enter the real-time viewing',
  当前任务启动失败: 'Current task failed to start',
  '正在调度可用的云主机，这需要一点时间，请耐心等候':
    'Available cloud hosts are being scheduled. This will take some time.',
  当前共有: 'Currently there are ',
  '个流程任务正在排队，您的流程任务排在第': ' tasks are being queued, your process task is ranked',
  '位，预计还需要': ', it is expected that more will be needed ',
  '该分身的流程尚未开始执行，请稍候': "The browser's task has not yet started, pls wait.",
  '该分身流程已结束，状态为：成功': 'The task has ended and the status is: Success',
  您可到流程任务详情中查看该分身在执行此流程定义时的具体细节:
    'You can go to the task details to view the specific details.',
  '该分身流程已结束，状态为：': 'The task has ended and the status is:',
  '正在加载实时监管视频数据...': 'Loading real-time surveillance video...',
  '流程已经开始执行，正在获取视频数据，请稍候...':
    'The task has started and video is being obtained, pls wait...',
  '查看方式：': 'View by:',
  该分身无法开启视频流的查看方式: 'The browser cannot enable viewing of the video stream',
  '以视频流模式实时查看流程运行状况的前提是：该浏览器分身需要开启安全策略并在本次流程执行过程中开启了审计录像':
    'To view the task in real-time using video stream mode, the browser must have its security policy activated',
  停止此分身流程: 'Stop this task',
  '云端执行实时查看：': 'Real-time viewing:',
  拟作用的分身: 'Avatars',
  发送成功: 'Sent Successfully',
  手动执行: 'Manual Exec',
  自动执行: 'Auto Execu',
  循环执行: 'Loop Exec',
  触发执行: 'Trigger Exec',
  执行条件: 'Exec',
  审计: 'Audit',
  总耗时: 'Total Time',
  任务执行策略: 'Exec Strategy',
  分身并发执行数: 'Conc-Exec',
  流程定义的编辑过程: 'Proc Editing',
  编辑流程定义: 'Edit Proc',
  流程定义的执行过程: 'Execution of task',
  执行流程定义: 'Exec Proc',
  执行: 'Exec',
  流程计划的执行过程: 'Execution of plan',
  该操作: 'The operation',
  执行该操作: 'do this',
  团队花瓣余额不够: "Team's tokens is low",
  '，无法': ', unable to',
  '，您需要先购买花瓣并使其余额大于':
    ', you need to buy tokens first and make the balance greater than',
  才能够继续: 'to be able to continue',
  '注：当前团队的分身数量、团队成员数量、加速流量的使用等等都会导致花瓣的消耗，您可到费用中心查询花瓣的具体消耗明细':
    "Note: Team's browser avatars, and the usage of accel traffic affect token consumption. You can check detail in cost center.",
  查看花瓣消耗明细: 'View details',
  '，这可能会影响到': ', which may affect',
  '，建议您先购买适量花瓣后再':
    ', it is recommended that you purchase an appropriate amount of tokens first and then',
  流程计划所包含的流程定义已过期: 'The plan contains a proc that is out of date',
  '流程定义已过期，您需要重新获取此流程才能够执行此流程计划':
    'The proc has expired, you need to re-obtain it to execute this plan',
  加载数据超时: 'Loading timed out',
  '正在连接，请稍候...': 'Connecting, pls wait...',
  出现错误: 'Error occurred',
  连接超时: 'Connection timed out',
  '连接超时，请联系客服或刷新页面重试':
    'Connection timed out, Pls contact online support or refresh the page.',
  参数错误: 'Parameter error',
  请检查URL是否正确: 'Pls check the URL',
  '操作完成？': 'Operation completed?',
  '操作完成后，流程将继续执行': 'Once the operation is complete, the task will continue',
  验证码流程节点已失效: 'The verification code process node has expired',
  验证码流程节点已完成: 'Verification code process node has been completed',
  '正在加载，请稍候...': 'Loading, pls wait ...',
  暂停操作: 'Pause operation',
  开始操作: 'Start operation',
  操作完成: 'Operation completed',
  发送文本: 'Send text',
  请在此输入: 'Pls enter here',
  '1.鼠标操作指引：单指点击=鼠标左键，单指点击后按住移动=鼠标拖拽':
    '1. Mouse operation guide: single-finger click = left mouse button, single-finger click and hold to move = mouse drag',
  '2.输入操作指引：使浏览器输入框获得焦点，在输入框中输入值后点击发送回填至浏览器中':
    '2. Input operation guide: Focus the browser input box, enter the value in the input box and click Send to backfill it to the browser.',
  '操作指引：': 'Operation guide:',
  '1.此时可通过双指捏合进行画面的缩放与移动，确保合适大小与位置后请点击“开始操作”':
    '1. At this time, you can zoom and move the screen by pinching with two fingers. After ensuring the appropriate size and position, Pls click "Start Operation',
  '2.开始操作后可模拟鼠标操作或文字输入':
    '2. After starting the operation, you can simulate mouse operations or text input',
  当前元素拥有: 'The current element has ',
  '个子元素，请确认您的操作': ' child element, Pls choose',
  选择全部子元素: 'Select all child elements',
  选择第: 'Select the',
  个子元素: 'child element',
  已捕获: 'Captured ',
  个元素: ' elements',
  '，正在等待捕获第': ', waiting to capture the',
  个相似元素: 'similar elements',
  '，根据共性共匹配出': ', matching similar ',
  '，所有父容器共有': ', common to all parent containers',
  个类似子元素: ' similar children',
  预览所有: 'Preview All',
  相似元素: 'similar elements',
  '（全部）': '(all)',
  '（顺序）': '(order)',
  预览: 'Preview',
  预览当前元素: 'Current element',
  '预览当前元素的子元素（全部）': "Current element's child elements (all)",
  '预览当前元素的子元素（顺序）': "Current element's child elements (sequentially)",
  获取相似元素: 'Similar',
  重新捕获: 'Recapture',
  正在: '',
  捕获: 'Capture ',
  '请输入名称，用于标识该元素选择器': 'Enter a name to identify the element selector',
  请输入网页元素的CSS选择器: 'Enter the CSS selector of the element',
  请输入网页元素的XPath选择器: 'Enter the XPath selector of the element',
  显示第: 'Showing the',
  个容器元素: ' container',
  前一元素: 'Previous',
  上级元素: 'Superior',
  下级元素: 'Subordinate',
  后一元素: 'Latter',
  选择元素类型: 'Select element type',
  请选择父元素选择器: 'Pls select the parent element selector',
  '父元素：': 'Parent:',
  通过元素捕获器来定位窗口与网页元素: 'Capture window & web elements through Element Capture',
  '（父容器：': '(Parent container:',
  '可选，当元素处于iframe标签中时，需要输入iframe的XPath':
    'Optional, when the element is in an iframe tag, you need to enter the XPath of the iframe',
  定位规则: 'Locator',
  优先使用: 'Priority',
  然后使用: 'Secondary',
  暂不使用: 'Not in Use',
  预览元素: 'Preview',
  平台浏览器: 'Platform browser',
  已取消收藏: 'Canceled favorites',
  收藏成功: 'Favorited Successfully',
  '平台：': 'Platform: ',
  '站点：': 'Site: ',
  打开浏览器: 'Open Browser',
  'IP已过期，无法访问分身': 'The IP has expired and cannot be accessed.',
  'IP已过期，需要续费以后才能够访问分身':
    'The IP has expired and needs to be renewed before you can open the browser.',
  激活窗口: 'Activate the Window',
  正在打开: 'opening',
  清理本地缓存文件: 'Clear Local Cache Files',
  '删除本地位于“浏览器配置文件”中的和该分身相关的所有数据，打开浏览器会话时会从云端重新下载缓存数据':
    'Delete all data locally in the "Browser Profile", and cached data will be re-downloaded from the cloud when a browser is opened.',
  清理云端缓存文件: 'Clear Cloud Cache Files',
  '删除云端和该分身相关的所有缓存数据，当关闭浏览器会话时会将本地的数据重新上传至云端（上传哪些数据取决于云端同步策略）':
    'Delete all cached data in the cloud. When the browser is closed, the local data will be re-uploaded to the cloud (which data is uploaded depends on the Cloud Sync Stragety)',
  '正在为您清理，请稍候...': 'Cleaning up for you, pls wait...',
  '分身清理是指清理所选分身的Cookie（含LocalStorage、IndexedDB）以及缓存数据，分身清理等价于常规浏览器中的“清理缓存”操作':
    'Cleaning Data refers to clearing the cookies (including LocalStorage, IndexedDB) and cache data of the selected browser avatar, it is equivalent to the "Clear Cache" operation in a regular browser.',
  分身清理完毕: "The browser's cached data is cleaned up",
  批量分身清理: 'Batch Cleaning',
  分身清理: 'Clean',
  '分身迁移：': 'Migration: ',
  浏览器指纹与Cookie数据迁移至花漾: 'Browser fingerprint and cookies migrated to HuaYoung',
  '如果希望将Chrome、Edge、紫鸟、跨境卫士、AdsPower或其它浏览器的指纹信息和Cookie数据导入至花漾，请选择此选项':
    'If you want to import fingerprint and cookies from Chrome, Edge, Firefox,  AdsPower or other browsers to HuaYoung, Pls select this option',
  '分身克隆：': 'Clone: ',
  'VPS/云主机的整体环境克隆至花漾': 'Clone the overall environment of VPS to HuaYoung',
  '如果希望将VPS/云主机中的环境信息全部克隆至花漾，包括：浏览器指纹信息、Cookie数据、网站密码、安装IPGO将VPS/云主机的IP地址导入至花漾，请选择此选项':
    'If you want to clone all the environment in the VPS to HuaYoung, including: browser fingerprint, cookies, website password, install IPGO and import the IP address of the VPS to HuaYoung, select this option',
  登录链接: 'Login URL',
  '针对个别已知平台，系统为您提供该平台的默认登录链接，如果该链接与预期不符，您可将其修订成正确的登录链接；针对未知平台，您可在这自行输入该平台的登录链接，如果没有，可置为空':
    'For certain known platforms, the system provides you with the default login link of the platform. If the link does not match expectations, you can modify it to the correct login link; for unknown platforms, you can enter the login link of the platform yourself here. , if not available, it can be set to empty',
  '解析Cookie失败，请检测Cookie格式是否正确':
    'Failed to parse the cookie, Pls check whether the cookie format is correct.',
  导入分身的认证信息与Cookie数据: 'Import avatar’s authentication information and cookie data',
  拟创建的指纹实例数量: 'Number of fingerprint instances to be created',
  '（一个指纹模板最多创建100个指纹实例）':
    '(A fingerprint template can create up to 100 fingerprint instances)',
  '系统会从花漾指纹库中免费下载可用的浏览器指纹模板，并通过指纹模板创建指纹实例；如果您希望采集自己的浏览器指纹模板，请切换至指纹库页签':
    'The system will download available browser fingerprint templates from HuaYoung Fingerprint Library for free, and create fingerprint instances from the fingerprint templates; if you want to collect your own browser fingerprint templates, Pls switch to the Fingerprint Library tab',
  名称前缀: 'Name Prefix',
  使用本机IP直连: 'Direct Connection',
  使用当前电脑的IP地址: 'Use Local IP',
  '如果电脑开启了系统代理/VPN则使用代理，否则使用本机IP直连':
    'If the computer has a system proxy VPN enabled, use a proxy, otherwise use the local IP directly',
  向我推荐适合的IP地址: 'Recommend To Me',
  我们会根据您选择的平台向您推荐适合的IP地址:
    'We will recommend a suitable IP address to you based on the platform you choose.',
  IP隔离: 'IP Isolation',
  团队前: 'Team',
  '个分身免费，': ' avatars are free,',
  一次最多创建: 'Max at once ',
  '个分身）': ' avatars)',
  无痕分身必须绑定一个浏览器指纹模板才能够正常访问:
    'The stateless avatar must be bound to a browser fingerprint template.',
  '您没有创建浏览器指纹模板/实例的权限':
    'You do not have permission to create browser fingerprint templates/instances',
  '如需授权，请您和团队管理员联系':
    'If you need authorization, Pls contact your team administrator',
  免费可下载的浏览器指纹模板配额不足:
    'Insufficient quota of free downloadable browser fingerprint templates',
  您的团队目前只允许下载: 'Your team currently only allows downloads',
  '个免费的浏览器指纹模板，您需要自行采集浏览器指纹模板':
    'A free browser fingerprint template, you need to collect the browser fingerprint template yourself',
  自行采集: 'Collect by yourself',
  自动绑定电脑浏览器: 'Bind an Idle Computer Browser ',
  '如果指纹模板不够，会从花漾指纹库中下载电脑浏览器指纹（免费配额10个）':
    'If the fingerprint template is not enough, the computer browser fingerprint will be downloaded from the HuaYoung database (free quota 10)',
  '如果指纹实例不够，会从花漾指纹库中下载电脑浏览器指纹（免费配额10个）并自动创建100个指纹实例':
    'If there are not enough fingerprint instances, computer browser fingerprints (free quota 10) will be downloaded from HuaYoung Fingerprint Database and 100 fingerprint instances will be automatically created.',
  自动绑定手机浏览器: 'Bind an Idle Mobile Browser ',
  '绑定手机浏览器指纹模板时，会模拟手机浏览器':
    'When binding the mobile browser fingerprint template, the mobile browser will be simulated',
  '绑定手机浏览器指纹实例时，会模拟手机浏览器':
    'When binding a mobile browser fingerprint instance, the mobile browser will be simulated',
  创建无痕浏览器分身: 'Create Stateless Browser Avatar',
  '无痕分身相当于“无痕浏览器”，本身不存储Cookie、LocalStorage等数据，每次打开该分身所对应的花漾浏览器都是一个全新的浏览器实例':
    'Stateless browsers are equivalent to "incognito browsers". They do not store cookies and others. Each time open the HuaYoung Browser, it is a new instance.',
  其它平台: 'Other platforms',
  分身配置: 'Browser Configuration',
  拟创建的电脑浏览器分身数量: 'The num of pc browsers',
  '每个浏览器分身都是一个相互隔离、互不干扰的浏览器运行实例，分身需要绑定浏览器指纹（必需）和IP地址（可选）后才能打开对应的浏览器，此处指绑定电脑浏览器指纹':
    'Each browser avatar is a browser instance that is isolated from each other and does not interfere with each other. The avatar needs to bind the browser fingerprint (required) and IP address (optional) before it can open the corresponding browser. This refers to binding',
  最小需要创建1个演示分身: 'Minimum need to create 1 demo avatar',
  '出于演示目的，最多只能创建10个演示分身':
    'For demonstration purposes, only a maximum of 10 demo browsers can be created',
  拟创建的手机浏览器分身数量: 'The num of mobile browsers',
  '每个浏览器分身都是一个相互隔离、互不干扰的浏览器运行实例，分身需要绑定浏览器指纹（必需）和IP地址（可选）后才能打开对应的浏览器，此处指模拟Android手机浏览器指纹':
    'Each browser avatar is a browser instance that is isolated from each other and does not interfere with each other. The avatar needs to bind the browser fingerprint (required) and IP address (optional) before it can open the corresponding browser. This refers to simulating Android Mobile browser fingerprint',
  浏览器分身命名前缀: 'Naming Prefix',
  '系统将为您创建以“命名前缀”-“数字”作为名称的演示分身':
    'The system will create a demo avatar with "naming prefix"-"number" as the name for you.',
  '查找当前系统内已安装的Chrome/Edge浏览器':
    'Find the Chrome/Edge browser installed in the current system',
  采集查找到的浏览器的指纹信息: 'Collect the fingerprint information of the browser',
  根据采集的指纹信息生成指纹模板:
    'Generate fingerprint templates based on collected fingerprint information',
  通过花漾指纹库下载浏览器指纹模板:
    'Download the browser fingerprint template through HuaYoung fp Library',
  根据指纹模板创建指定数量的指纹实例:
    'Create a specified number of fp instances based by the template',
  创建指定数量的浏览器分身: 'Create a specified number of browsers',
  为浏览器分身绑定浏览器指纹实例: 'Bind a browser fingerprint instance to a browser avatar',
  为浏览器分身采用本机IP直连: 'Use direct connection for browsers',
  浏览器分身创建成功: 'Browser avatars created successfully',
  '现在将为您导航至某个分身的详情页面，您可以打开此分身所对应的花漾浏览器':
    'Now you will be navigated to the details page of a browser avatar. You can open the HuaYoung browser of it.',
  '未找到合适的浏览器，为您准备一个系统预置的指纹模板':
    'No suitable browser found. A system-preset fingerprint template is prepared for you.',
  创建多个分身: 'Create Multiple Browsers',
  '我们将为您下载指纹模板、创建指纹实例、创建多个浏览器分身、为分身绑定指纹实例并开启本地代理（暨采用本机IP直连），方便您快速体验':
    'We will download the fingerprint template, create fp instances, create multiple browser avatars, bind the fp instances to the avatars and use local IP to facilitate your quick experience.',
  分身批量导出: 'Batch Export',
  分身导出: 'Export',
  '请选择拟导出到Excel表格的字段列表：': 'Pls select the fields to be exported:',
  所属站点: 'Site',
  分身数量已经达到团队最大配额: 'The number of browsers has reached maximum quota',
  当前团队只允许创建: 'Current team is only allowed to create ',
  '个分身，无法创建新的分身，请联络官网客服咨询进一步信息':
    ' avatar, unable to create new one, Pls contact online support.',
  请联络官网客服咨询进一步信息: 'Pls contact online support',
  批量创建分身: 'Create Browsers Batches',
  新建分身: 'New Browser',
  '请指定分身类型，系统将为您批量创建指定数量的分身':
    'Pls specify the browser type, and the system will create the specified number in batches',
  '将您的电商平台、社交媒体等分身纳入到花漾灵动里管理':
    'Incorporate your e-commerce, social media and others into HuaYoung',
  选择分身类型: 'Type',
  如无合适的分身平台请选择其它: "If there is no suitable platform, pls choose 'Others'.",
  输入基本信息: 'Basic Info',
  店铺类型: 'Store type',
  店铺名称: 'Store name',
  本土店: 'local store',
  跨境店: 'Cross-border store',
  '如果您的跨境店支持多个站点，您需要为其创建多个分身并分别指定不同的站点':
    'If your cross-border store supports multiple sites, you need to create multiple browsers for it and specify different sites respectively.',
  请选择所属站点: 'Pls select the site you belong to',
  经营品类: 'Categories',
  请选择经营品类: 'Pls select a business category',
  最长512个字符: 'Maximum 512 characters',
  '请输入对分身的描述以供备注，最长512个字符':
    'Pls enter a description for remarks. The maximum length is 512 characters.',
  分身批量创建成功: 'Batch avatar created successfully',
  '对分身进行克隆，需要拥有以下几个权限项：IP列表查看权、IP新增、购买与删除权、对已有分身的属性设置权、对已有分身浏览器指纹的管理权、对已有分身IP地址的管理权':
    'To clone a avatar, you need to have the following permissions: the right to view the IP list, the right to add IP, purchase and delete, the right to set the attributes of the existing avatar, the right to manage the browser fingerprint of the existing avatar, the right to Management rights of avatar IP addresses',
  无痕分身不支持该操作: 'Stateless browsers do not support this operation',
  分身创建成功: 'Created successfully',
  全新分身: 'As a New',
  关闭向导页面后将直接进入分身详情页面: 'You will directly enter the details page.',
  分身迁移: 'Migrate',
  将其它浏览器中的环境信息迁移至花漾: 'Migrate from other browsers to HuaYoung',
  无痕分身: 'Stateless',
  强制录像: 'Forced',
  代理: 'Proxy',
  '正在为您批量导入分身，请稍候...': 'Importing browsers in batches for you, pls wait...',
  分身导入失败: 'Avatar import failed',
  请选择您要导入的分身: 'Pls select the avatar you want to import',
  选择模板类型: 'Select Template Type',
  花漾通用模板: 'HuaYoung Template',
  '支持对分身认证信息、Cookie数据以及绑定的IP地址进行批量导入（如果IP地址不存在则自动创建）':
    'Supports batch import of avatar authentication infor, cookies and bound IP (if the IP does not exist in HouYoung, it will be automatically created)',
  AdsPowerExcel模板: 'AdsPower Excel Template',
  '以AdsPower的账号导入/导出文件作为模板，支持一键导入AdsPower的账号列表':
    "Use AdsPower's account import/export file as a template to support one-click import of AdsPower's account list",
  '支持对分身、分身所属平台、认证信息、Cookie数据以及绑定的IP地址进行批量导入（如果IP地址不存在则自动创建，已存在则忽略）':
    'Supports batch import of avatars, platform, authentication information, cookies, and bound IP (if the IP address does not exist in HuaYoung, it will be automatically created, and if it already exists, it will be ignored)',
  '以AdsPower的分身导入/导出文件作为模板，支持一键导入AdsPower的分身列表；导入内容包括：分身名称、用户名、密码、Cookie数据等；如果IP基于Socks5、Http等代理协议，会自动创建IP':
    "Using AdsPower's import/export file as a template, it supports one-click import of AdsPower's avatar list; the imported content includes: avatar name, user name, password, cookie data, etc.; if the IP is based on proxy protocols such as Socks5, Http, etc., the IP will be automatically created",
  通过Excel文件批量导入分身: 'Import browsers in batches through Excel files',
  不能在同一个团队中进行分身的联合运营或转让:
    'Joint operation or transfer of browsers within the same team is not allowed',
  分身的联合运营或者转让只能发生在不同的团队之间:
    'Joint operations or transfers of browsers can only occur between different teams',
  '根据您填写的信息，我们查询到受让方信息如下：':
    'Based on the information, we found the transferee information as follows:',
  联系人手机号: 'Contact Phone',
  联系人昵称: 'Nickname',
  信息准确: 'Infor is Accurate',
  信息有误: 'Infor is Wrong',
  联系人在指定团队中的权限不够:
    'The contact does not have sufficient permissions in the specified team',
  指定的联系人在指定的团队中必须是超管或者BOSS角色:
    'The contact person must have a SuperAdmin or BOSS role in the designated team',
  您的信息输入有误: 'Your information was entered incorrectly',
  '根据您的输入并没有查询到符合条件的信息，请确认您的输入是否准确':
    'No matching information was found based on your input. Pls confirm whether your input is accurate.',
  受让方: ' transferee ',
  合作方: ' partners ',
  '为了确保{{target}}{{optLabel}}操作的正确性，请填写':
    'In order to ensure the {{target}} of the {{optLabel}} operation, Pls fill in ',
  信息: ' information',
  请输入对方的手机号码: "Pls enter the other party's mobile number",
  请输入联系人手机号: 'Pls enter the contact mobile number',
  如何获得: 'How to get',
  '团队ID？': 'Team ID?',
  联系人在团队中必须是超管或者BOSS角色:
    'The contact person must have a SuperAdmin or BOSS role in the team',
  添加新的合作团队: 'Add new collaboration team',
  该分身从未绑定过任何浏览器指纹实例:
    'This avatar has never been bound to any browser fingerprint instance',
  设置时间: 'Set time',
  指纹设置历史: 'Fingerprint setting history',
  正在为您检查分身配额: 'Checking browser avatars quota for you',
  新建浏览器分身: 'New Browser ',
  新建无痕分身: 'New Stateless Browser',
  批量创建浏览器分身: 'Batch New Browsers',
  无法在网页端中导入分身: 'Unable to import data in the browser',
  '请下载花漾客户端，并在花漾客户端中完成分身的导入':
    'Pls download the HuaYoung app and complete the import in the HuaYoung app.',
  批量导入浏览器分身: 'Batch Import Browsers',
  '确定要解绑此IP吗?': 'Sure to unbind this IP?',
  '分身绑定/解绑IP是一种比较慎重的行为，请您确认':
    'Binding/unbinding IP by avatar is a prudent behavior, Pls confirm',
  解绑成功: 'Unbinding successfully',
  '创建时间：': 'Create Time:',
  '历史绑定分身次数：': 'Num of bound in history:',
  IP归属地: 'IP Location',
  该分身从未绑定过任何IP地址: 'This avatar has never been bound to any IP address',
  IP绑定历史: 'IP Binding History',
  IP已过期: 'IP has expired',
  IP已失效: 'IP has expired',
  IP处于: 'IP is in',
  '解绑成功，即将刷新': 'Unbinding successful, will be refreshed soon',
  '该IP当前的状态为过期，即便绑定后也无法正常使用，您可到IP详情中了解关于此IP的进一步信息':
    'The current status of this IP is expired and cannot be used normally even after binding. You can go to the IP details to learn more about this IP.',
  '该IP当前的状态为失效，即便绑定后也无法正常使用，您可到IP详情中了解关于此IP的进一步信息':
    'The current status of this IP is invalid and cannot be used normally even after binding. You can go to the IP details to learn more about this IP.',
  '该IP当前的状态为准备，即便绑定后也无法正常使用，您可到IP详情中了解关于此IP的进一步信息':
    'The current status of this IP is ready and cannot be used normally even after binding. You can go to the IP details to learn more about this IP.',
  该IP曾访问过: 'This IP has visited',
  '分身，距离最近访问未满6个月，选择此IP地址存在一定的风险，确定您要如此操作吗？':
    ' the avatar, the last visit is less than 6 months ago. There are certain risks in choosing this IP address. Sure to do this?',
  该IP正在绑定另一个: 'This IP is binding to another',
  '分身，这可能会造成分身关联从而影响分身安全，确定要继续吗？':
    " avatar, this may cause the avatar to be related and affect the avatar's safety. Sure to continue?",
  '确定要选择此IP吗？': 'Sure to select this IP?',
  已为您显示所有: 'Showed all for you',
  依据IP地址进行检索: 'Search by IP',
  已过滤: 'Filtered',
  设置时间间隔: 'Set Time Interval',
  时间间隔: 'Time Interval',
  '通常而言，6个月未访问过电商平台的IP地址会被认为是干净的IP地址':
    'Generally, IP that have not visited e-commerce platforms for 6 months are considered clean IP.',
  之内绑定过: ' binded within ',
  分身且访问次数大于0或者状态不可用的: ' or the status is unavailable',
  已显示全部可用的: 'Shown all available ',
  返回筛选条件: 'Return to Filters',
  已过滤状态不可用的: 'Filtered out with unavailable ',
  仅显示状态为可用的IP: 'Only show available IPs',
  IP名称: 'IP name',
  '”已从当前团队移除': '” has been removed from the current team',
  已移除IP无法查看详情: 'The IP has been removed and cannot be viewed for details.',
  当前绑定分身: 'Currently Bound',
  '推荐/拒绝理由': 'Reasons',
  正绑定此分身: 'Binding this avatar',
  当前正在绑定: 'Currently Binding',
  距离最近访问: 'Nearest visit',
  分身未满6个月: 'The avatar is less than 6 months old',
  IP可用: 'IP available',
  个月内: ' within months',
  未访问过: 'Not visited ',
  绑定历史: 'Binding History',
  '是否要对选择的IP按序轮流绑定？': 'Do you want to bind the selected IPs in sequence?',
  您选择了: 'you selected',
  '个IP，是否要对选中的IP进行按序轮流绑定？': 'Do you want to bind the selected IPs in sequence?',
  请选择IP: 'Pls select IP',
  没有符合条件的IP地址: 'No matching IP address',
  '缺少“查看IP列表”权限，无法获取IP列表':
    'Lack of "View IP List" permission, unable to obtain IP list',
  选择副IP: 'Select secondary IP',
  '该IP是分身主IP，不可将主IP设置为副IP': 'The main IP cannot be set as the secondary IP.',
  'TKShop（暨TikTok店铺账号）': 'TKShop (TikTok Shop)',
  'TKVideo（暨TikTok媒体端账号）': 'TKVideo (TikTok Media)',
  '如果目前没有您希望的分身平台类型，请选择“其它”，并请通过在线客服反馈给我们':
    'If there is currently no platform type you want, Pls select "Other" and Pls give us feedback through online support',
  不限平台: 'No platform restrictions',
  '（意味着任意浏览器分身都可以执行此流程定义）':
    '(Meaning that any browser can execute this proc script)',
  其它: 'Others',
  '恭喜，浏览器克隆成功': 'Congratulations, browser cloning is successful',
  已将浏览器信息同步至此分身: 'Browser information has been synced to this avatar',
  '我们为您生成了浏览器分身克隆链接，请您在希望采集指纹信息与Cookie数据的浏览器中访问下述链接':
    'We have generated a link for you. Pls visit the following link in the browser where you wish to collect fingerprint and cookies.',
  '该链接10分钟之内有效，在分身克隆成功之前，请不要关闭此对话框':
    'Link is valid within 10 mins. Dont close the dialog until successfully cloned.',
  '分身数量：': 'Amount:',
  '分身属性会影响到访问此分身打开的花漾浏览器的参数设置，请谨慎修订':
    'These attributes will affect the HuaYoung browser, pls revise it carefully.',
  分身类型: 'Type',
  常规分身: 'Regular',
  分身属性: 'Properties',
  分享团队: 'Share team',
  备份: 'Backup',
  无痕分身不保存网站密码: 'Stateless browsers do not save website passwords',
  '如果您希望由花漾浏览器为您进行密码代填，请在这里输入相应的链接与认证信息，目前仅支持Windows平台':
    'If you want HuaYoung Browser to fill in your password for you, pls enter the corresponding login url and authentication information here. Only supports Windows currently.',
  网站: 'Website',
  您设置的分身认证信息: 'The authentication information you set',
  您设置的关联邮箱平台: 'The associated email platform you set up',
  您设置的关联支付平台: 'The associated payment platform you set up',
  'Cookie是维持账号登录态的重要组成，针对Cookie数据，您可以备份、导入、也可以全部删除，一旦清空意味着相关登录态将被清理':
    'Cookies are important in maintaining the login status of your account. You can back up, import, or delete all cookies. Once cleared, it means that the login status will be cleared.',
  无痕分身属性: 'Stateless Avatar Attr',
  插件扩展: 'Plug-in Extensions',
  云端同步策略: 'Sync Strategy',
  资源加载策略: 'Loading Strategy',
  浏览器首页: 'Homepage',
  首页与书签: 'Homepage & Bookmarks',
  其它访问策略: 'Other Strategies',
  默认加载: 'Loaded',
  默认不加载: 'Not Loaded',
  当图片大于: 'When pic larger than',
  KB禁止加载: 'KB prohibited from loading',
  请设置图片大小阈值: 'Pls set image size threshold',
  KB时禁止加载: ' KB loading is prohibit',
  '当打开该分身所对应的花漾浏览器在访问网站资源时针对视频与图片资源的加载策略，通过设置该策略能够有效降低IP或本地代理的流量':
    'When HuaYoung browser is opened, the loading strategy for video and picture resources is used. By setting this strategy, the traffic of IP or local proxy can be effectively reduced.',
  加载视频: 'Video Loaded',
  加载图片: 'Img Loaded',
  账号所属平台: 'The platform to which the account belongs',
  分身用户名: 'Avatar username',
  设置分身的认证信息: 'Set the avatar’s authentication information',
  关联的邮箱平台: 'Associated email platforms',
  邮箱平台用户名: 'Email platform username',
  设置邮箱的认证信息: 'Set email authentication information',
  关联的支付平台: 'Associated payment platforms',
  支付平台用户名: 'Payment platform username',
  设置支付平台的认证信息: 'Set authentication information for payment',
  '如果您希望由系统为您代填分身的登录用户名与密码，请在这里输入分身的认证信息；密码代填能够有效避免分身密码的泄露风险':
    "If you want the system to fill in the avatar's login info, Pls enter the avatar's authentication information here.",
  '确定要开启此选项的云端同步吗？': 'Sure to turn on cloud sync for this option?',
  '无论是LocalStorage还是IndexedDB，一旦开启云端同步策略，意味着会消耗较大的带宽，且会导致打开花漾浏览器过慢':
    'Whether it is LocalStorage or IndexedDB, once the cloud synchronization policy is turned on, it will consume a large amount of bandwidth and cause the opening of HuaYoung Browser to be too slow.',
  同步: 'Synchronize',
  不同步: 'Out of Sync',
  无痕分身不保存: 'Stateless browsers are not saved',
  '标识为“同步”的数据，打开浏览器时会将数据从云端下载到本地，关闭浏览器时会将本地的最新数据同步到云端，云端数据会占据网盘存储空间并可能产生花瓣的消耗':
    'For data marked as "Synchronized", when you open the browser, the data will be downloaded from cloud to local. When close the browser, the latest local data will be synchronized to cloud. The cloud data will occupy the storage space and may consume tokens.',
  'Cookie数据维持着分身的各种状态，强烈建议必须要开启Cookie数据同步':
    'Cookie data maintains various states of avatars. It is strongly recommended that cookie data synchronization be turned on.',
  'LocalStorage是一种高阶的浏览器数据缓存技术，开启此选项意味着云端同步会消耗较高的带宽':
    'LocalStorage is an advanced browser data caching technology. Turning this option on means that cloud synchronization will consume higher bandwidth.',
  LocalStorage数据: 'LocalStoragedata',
  'IndexedDB是一种高阶的浏览器数据缓存技术，开启此选项意味着云端同步会消耗较高的带宽':
    'IndexedDB is a high-end browser data caching technology. Turning this option on means that cloud synchronization will consume higher bandwidth.',
  IndexedDB数据: 'IndexedDB data',
  历史记录: 'History',
  历史记录是指访问各个网站留下的历史痕迹:
    'History records refer to the historical traces left by visiting various websites',
  分身共享书签: 'Avatar Bks',
  '名为“分身共享书签”中的内容会在所有能够访问到该分身的人员之间共享':
    'Content called "Avatar Shared Bookmarks" will be shared among all people who have access to the avatar.',
  团队全局书签: 'Global Bks',
  '名为“团队全局书签”中的内容会在所有团队成员之间共享':
    'Content called "Team Global Bookmarks" is shared among all team members',
  其它收藏夹: 'Other Bks',
  '“其它收藏夹”是指不在“团队收藏夹”中的书签，会在同一个用户之间进行同步':
    'Other Favorites" refers to bookmarks that are not in "Team Favorites" and will be synchronized between the same user',
  '如果要开启在不同客户端下的密码代填，则需要开启网站密码的同步':
    'If you want to enable password filling under different clients, you need to enable the synchronization of website passwords.',
  备份Cookie数据: 'Back up cookie data',
  'Cookie备份-': 'Cookie backup-',
  另存为: 'Save as',
  Cookie复制成功: 'Cookie copied successfully',
  '文件大小超过限制（最大为10MB）': 'File size exceeds limit (maximum 10MB)',
  读取文件失败: 'Failed to read file',
  导入Cookie: 'Import cookies',
  清空已有Cookie: 'Clear existing cookies',
  暂不支持在: 'Not Supported Yet',
  平台上的密码代填特性: 'Password filling feature on the platform',
  '会在后续版本中予以支持，请稍候': 'It will be supported in subsequent versions, pls wait.',
  '当花漾浏览器访问此登录链接时，会自动列出指定的用户名和密码':
    'When HuaYoung Browser accesses this login link, the specified username and password will automatically be listed.',
  '系统为您提供当前电商平台的默认登录链接，如果该链接与您的预期不符，您可以将其修订成正确的登录链接':
    'The system provides you with the default login link for the current e-commerce platform. If the link does not match your expectations, you can modify it to the correct login link.',
  '系统为您提供所选支付平台的默认登录链接，如果该链接与您的预期不符，您可以将其修订成正确的登录链接':
    'The system provides you with a default login link for your chosen payment platform. If the link does not match your expectations, you can revise it to the correct login link.',
  '系统为您提供所选邮箱平台的默认登录链接，如果该链接与您的预期不符，您可以将其修订成正确的登录链接':
    'The system provides you with a default login link for your selected email platform. If the link does not match your expectations, you can revise it to the correct login link.',
  所属支付平台: 'Belonging payment platform',
  请选择所属支付平台: 'Pls select your payment platform',
  所属邮箱平台: 'Email platform to which you belong',
  请选择所属邮箱平台: 'Pls select your email platform',
  清除: 'Clear',
  '登录链接与用户名已经存在，确认要替换吗？':
    'The login link and username already exist, are you sure you want to replace them?',
  '当前输入的登录链接与用户名与系统中已有的记录重复，点击确认将替换旧有的记录':
    'The currently entered login link and user name are duplicates of existing records in the system. Click OK to replace the old records.',
  请输入用户名: 'Pls enter user name',
  选择合作类型: 'Cooperation Type',
  分身转让: 'Transfer',
  '将分身完全转让给其它团队，可以在转让过程中决定是否转让分身的指纹与IP地址，一旦转让完毕后，分身将不再归属于您':
    "Completely transfer the avatar to another team. You can decide whether to transfer the avatar's fingerprints and IP address during the transfer process. Once the transfer is completed, the avatar will no longer belong to you.",
  联合运营: 'Joint Operation',
  '在免交密码的情况下，您当前的团队和其它指定团队一起联合运营分身，但分身的归属权依然在您手里，您也可以随时中断联合运营':
    'No need to share your password with others, your current team can jointly operate the avatar with other designated teams, but the ownership of the avatar is still in your hands, and you can also interrupt the joint operation at any time.',
  分身要开启安全策略后才允许进行联合运营:
    'Browser avatar must enable security policies before joint operations are allowed.',
  '如果要对分身进行联合运营，需要事先开启分身的安全策略才允许进行联合运营':
    'If you want to jointly operate browser avatar, you need to enable the security policy of the avatar in advance to allow joint operations.',
  未绑定IP地址的分身无法进行联合运营:
    'Avatars that are not bound an IP cannot perform joint operations.',
  '所谓联合运营是指将分身交给其它团队合作管理，因此，分身必须要绑定一个公网IP地址才能够联合运营，否则，客队无法正常访问此分身':
    'The so-called joint operation means handing over the avatar to other teams for cooperative management. Therefore, the avatar must be bound to a public IP address before it can be jointly operated. Otherwise, the visiting team will not be able to access the avatar normally.',
  '独占式访问下的分身，无法开启分身联营':
    'Avatars under exclusive access cannot enable avatar association.',
  '请重新修订此分身的访问策略，将访问方式由独占式访问更改为协同式访问，只有协同式访问的分身才允许进行分身联营':
    'Pls revise the access policy of this avatar and change the access method from exclusive access to collaborative access. Only browsers with collaborative access are allowed to join the browsers.',
  填写受让方信息: 'Transferee Information',
  填写合作方信息: 'Partner Information',
  明确策略: 'Clear Strategy',
  转让分身的认证信息: 'Transfer the avatar’s authentication information',
  '（如未设置请忽略）': '(Ignore if not set)',
  转让分身的邮箱账号与密码: 'Transfer the avatar’s email account and password',
  转让分身的支付平台账号与密码: 'Transfer the avatar’s payment account and password',
  转让分身的Cookie数据: 'Transfer cookies',
  '（请确认有无其它敏感信息）': '(Pls confirm whether there is any other sensitive information)',
  转让分身的网站密码: 'Transfer avatar’s website password',
  '（请确认有无和分身无关的网站密码）':
    '(Pls confirm whether there is any other sensitive information)',
  转让分身的浏览器指纹: "Transferring browser's fingerprint",
  '（该浏览器指纹将从本团队中移出）': '(This fingerprint will be moved out of this team)',
  转让分身的IP地址: 'Transfer the IP of the avatar',
  '（请确认该IP没有绑定其它分身）': '(Pls confirm that this IP is not bound to other browsers)',
  '针对分身的联营方，您可以指定一份“分身联营安全策略”，该联营安全策略在所有联营团队中共享，并将约束所有联营团队的所有人员的访问行为；您可以在联营的过程中随时修订针对“分身联营安全策略”':
    'For the affiliated parties, you can specify a "affiliated security policy", which is shared among all affiliated teams and will restrict the access behavior of all personnel of all affiliated teams; you can specify it at any time during the affiliated process. Revised "Available Affiliate Security Policy',
  复制当前分身的安全策略: 'Copy the security policy of the current avatar',
  创建一份新的联营安全策略: 'Create a new federation security policy',
  '当前分身所绑定的主IP还绑定了其它分身，请先移除其它绑定关系再进行转让':
    'The main IP bound to the current avatar is also bound to other browsers. Pls remove other binding relationships before transferring.',
  '当前分身所绑定的浏览器指纹还绑定了其它分身，请先移除其它绑定关系再进行转让':
    'The browser fingerprint bound to the current avatar is also bound to other avatars. Pls remove other binding relationships before transferring.',
  请再次确认是否要转让此分身: 'Pls reconfirm whether you want to transfer this avatar',
  '1、所有访问分身的行为将被强行中断': '1. All access to browsers will be forcibly interrupted.',
  '2、如果指纹被转让，当对方确认后指纹实例将从当前团队中移除':
    '2. If the fingerprint is transferred, the fingerprint instance will be removed from the current team after the other party confirms it.',
  '3、如果IP被转让，当对方确认后IP将从当前团队中移除':
    '3. If the IP is transferred, the IP will be removed from the current team after the other party confirms it.',
  '4、当对方确认后分身也将从当前团队中移除':
    '4. When the other party confirms, the avatar will also be removed from the current team.',
  '分身转让中，等待接收方确认': 'Waiting for confirmation',
  '1、您指定的联系人将收到一笔消息，一旦他确认后，该分身将从您的团队中移除':
    '1. Your designated contact will receive a message. Once he confirms, the avatar will be removed from your team.',
  '2、24小时内，如果您指定的联系人没有确认分身交接，本次分身转让将被置为失败，分身将重新置为正常状态':
    '2. Within 24 hours, if your designated contact does not confirm, the avatar transfer will be set as failed and the avatar will be reset to the normal state.',
  分身已处于联合运营的状态: 'The avatar is already in a state of joint operation',
  '1、您指定的联系人将收到分身联合运营的消息，当他确认后，他就可以和您一起联合运营此分身':
    '1. The contact you designate will receive the message of joint operation of the avatar. After he confirms, he can jointly operate the avatar with you.',
  '2、您指定的联合运营团队在访问此分身时，会使用此分身的指纹实例与IP地址，并受您指定的分身联营安全策略的约束，拥有足够的安全性':
    '2. When the joint operation team you designate accesses this avatar, it will use the fingerprint instance and IP of this avatar, and is subject to the security policy of the avatar joint operation you specify, and has sufficient security.',
  '2、此分身的归属权依然是您，您可以随时中断与其它团队的联合运营行为':
    '2. The ownership of this avatar is still yours, and you can interrupt joint operations with other teams at any time.',
  立即修订分身联营安全策略: 'Immediately revise the association security policy',
  联合运营与转让: 'Joint & Transfer',
  '在免交密码的情况下与其它团队一起联合运营分身，或者将分身直接转让给其它团队':
    'Jointly with other teams without having to hand over password or transfer browsers directly to other teams',
  '当前分身绑定了IP池，请解绑后再操作':
    'The avatar is currently bound to an IP pool, Pls unbind it before proceeding.',
  '黑名单，禁止访问': 'blacklist, prohibited access',
  个网站: ' websites',
  '白名单，允许访问': 'Whitelist, Allow',
  未开启安全策略: 'Security Policy Disabled',
  黑白名单: 'B&W List',
  联营: 'Joint',
  '绑定浏览器指纹失败：': 'Failed to bind browser fingerprint:',
  未获取到指纹数据: 'Fingerprint data not obtained',
  '绑定浏览器指纹模板失败：': 'Failed to bind browser fingerprint template:',
  无法获取到指纹数据: 'Unable to obtain fingerprint data',
  模板名称: 'Template name',
  '动态IP“': 'Dynamic',
  该动态IP不支持会话保持: 'IP-hold feature is not supported',
  '一旦开启会话保持特性，则在访问分身打开的花漾灵动的会话周期内，将尽可能保证动态IP不要切换（前提是动态IP要支持）':
    'Once the IP-hold feature is enabled, during the session period of accessing HuaYoung opened by the avatar, it will try its best to ensure that the dynamic IP does not switch (provided that the dynamic IP must be supported)',
  动态策略: 'Dynamic policy',
  流量计划: 'Traffic',
  'IP池：': 'IP Pool:',
  IP释放策略: 'IP Release Strategy',
  当前分身未设置: 'Not set ',
  '，无法访问': ', inaccessible',
  '确认要关闭本地代理吗？': 'Sure to turn off the local proxy?',
  '关闭本地代理服务后，可以重新绑定其它的IP，或者重新启用本地代理':
    'After closing the local proxy service, you can rebind other IPs or re-enable the local proxy.',
  本地代理已关闭: 'Local proxy is down',
  需要关闭本地代理才可以换绑IP:
    'You need to turn off the local proxy before you can change the IP address',
  '您需要先关闭本地代理服务后，才可以重新绑定其它的IP，确认要关闭本地代理服务吗？':
    'You need to turn off the local proxy service before you can rebind other IPs. Sure to turn off the local proxy service?',
  换绑IP: 'Change IP',
  绑定IP地址: 'Bind IP',
  本地代理与IP直连: 'Local IP & Proxy',
  使用局域网代理: 'Use LAN proxy',
  使用本机浏览器的代理设置: 'Use your native browser’s proxy settings',
  使用本机系统代理设置: 'Use native system proxy settings',
  当前分身尚未绑定IP地址: 'Not Set IP',
  '”授权': 'Authorization',
  授权关系中未包含您自身: 'You are not included in the authorization relationship',
  '您解除了自己本人与该分身的关系，这意味着一旦确定就无法再查看此分身，确定要继续吗':
    'You have terminated the relationship between yourself and this avatar, which means that once confirmed, you can no longer view this avatar. Sure to continue?',
  您没有权限查看授权成员信息: 'You do not have permission to view authorized member information',
  授权信息为空: 'Authorization information is empty',
  立即授权: 'Authorize Now',
  '是否打开IP地址的购买页面？': 'Do you want to open the IP address purchase page?',
  '根据分身的所属平台与站点，我们为您推荐以下适合的IP地址，请打开IP地址购买页面以查看详情':
    'According to the platform and site of the avatar, we recommend the following suitable IP addresses for you. Pls open the IP address purchase page to view details.',
  继续访问: 'Continue to Open',
  '当前分身允许多人同时访问，您可以继续访问此分身':
    'The current avatar allows multiple people to access it at the same time. You can continue to access this avatar.',
  '当前分身允许多人同时访问，您可以查看是谁在访问，也可以继续访问此分身':
    'The current avatar allows multiple people to access it at the same time. You can check who is visiting or continue to access this avatar.',
  查看谁在访问: 'See who is visiting',
  '当前分身正在被其他成员访问，确定要继续访问吗？':
    'The avatar is currently being accessed by other members. Sure to continue?',
  正在访问: 'Visiting',
  正在同步分身数据: 'Synchronizing data',
  您正在访问此分身: 'You are currently visiting this avatar',
  '确信要强制结束您正在打开的会话吗？': 'Sure to force end the session you have open?',
  '您已经打开的和本分身相关的浏览器实例将会被强制结束，请确认是否继续':
    'The browser you have opened related to this avatar will be forcibly terminated. Pls confirm whether to continue.',
  强制结束: 'Force End',
  '当前分身设置为独占式访问，同时只允许一个用户访问分身':
    'The current avatar is set to exclusive access, and only one user is allowed to open the browser at the same time.',
  '该分身正在转让给其它团队，无法执行任何操作':
    'The avatar is being transferred to another team and cannot perform any operations.',
  '确认要取消转让吗？': 'Sure to cancel the transfer?',
  '取消转让后，如果浏览器指纹和IP未曾跟随分身一起转让，请重新绑定指纹与IP':
    'After canceling the transfer, if the browser fingerprint and IP have not been transferred with the avatar, Pls rebind the fingerprint and IP.',
  取消转让: 'Cancel Transfer',
  该分身由他人分享给当前团队联合运营:
    'The avatar is shared by others to the current team for joint operation',
  '确认要退还分身吗？': 'Sure to return the avatar?',
  '一旦退还分身，您当前团队所有成员都将无法访问当前分身，请确认是否继续':
    'Once the avatar is returned, all members of your current team will not be able to access. Pls confirm whether to continue.',
  退还分身: 'Return',
  联营转让: 'Joint/Transfer',
  批量联营转让: 'Joint/Transfer',
  当前分身对应的浏览器处于打开状态: 'The browser corresponding to the current avatar is open',
  请手动关闭该分身所对应的花漾浏览器后再执行清理操作:
    'Pls manually close the HuaYoung browser and then perform the cleaning operation.',
  请在花漾客户端内操作: 'Pls operate in HuaYoung app',
  对: ' pair',
  无痕: 'Stateless',
  获取截图失败: 'Failed to obtain screenshot',
  正在获取截图: 'Screenshot...',
  该分身的安全策略不允许事中监管:
    "The avatar's security policy does not allow for ongoing supervision",
  '该浏览器分身的安全策略中，已经关闭了实时监管，需要将其开启才允许进入监管页面':
    'In the security policy of this avatar, real-time supervision has been turned off, and it needs to be turned on to allow access to the supervision page.',
  '未开启安全策略，无法监管': 'Security policy disabled, cannot be supervised',
  '需要开启此分身的安全策略后，才允许进入监管页面':
    'You need to enable the security policy of this avatar before you are allowed to enter the supervision page.',
  '（联合运营）': '(joint operation)',
  无权限: 'No permission',
  '当前分身现在有以下访问：': 'Currently Visiting:',
  '确定要强制关闭此浏览器窗口吗？': "Sure to force end this member's access?",
  '强制关闭浏览器窗口将中断当前用户的操作，请确认是否继续':
    "Forcibly ending the access will cause the user's browser to be forcibly closed, which may affect his access operations.",
  IP绑定: 'IP Binding',
  以插件采集的形式新增功能屏蔽: 'New function blocking through plug-in collection',
  '系统将为您打开一浏览器，并在浏览器中通过ActionBlock插件采集需要屏蔽的页面与功能节点，新手建议选用此选项':
    'The system will open a browser for you and collect the pages and function nodes that need to be blocked through the ActionBlock plug-in in the browser.',
  批量编辑不支持插件形式采集: 'Batch editing does not support plug-in collection',
  正在通过插件采集的形式新增功能屏蔽:
    'New function blocking is being added in the form of plug-in collection',
  '已为您打开了新的浏览器窗口，请通过ActionBlock插件采集需要屏蔽的页面与功能节点，当浏览器窗口关闭后，采集的页面与功能节点将自动同步':
    'A new browser window has been opened for you. Pls use the ActionBlock plug-in to collect the pages and function nodes that need to be blocked. When the browser window is closed, the collected pages and function nodes will be automatically synchronized.',
  从团队资源中已有的功能屏蔽列表中选取:
    'Choose from a list of feature blocks that already exist in Team Resources',
  可在团队资源中设置可重复利用的功能屏蔽列表:
    'Reusable feature block lists can be set up in team resources',
  手工录入: 'Manual Input',
  批量修改分身属性会清除所选分身旧有的属性设置:
    'Batch revision of avatar attributes will clear the old attribute',
  '缺省设置只会影响新建分身，旧有分身可通过批量操作修订属性':
    'The default attributes will only affect newly created avatars.',
  批量修改所选分身的属性: 'Modify the properties in batches',
  分身缺省设置: 'Default properties',
  '正在为您批量绑定IP，请稍候...': 'Binding IPs in batches for you, pls wait...',
  '一旦批量解绑后，分身需要重新绑定IP后才允许访问':
    'Once unbinded in batches, the avatar needs to rebind the IP before allowing access.',
  '正在为您批量解绑IP，请稍候...': 'Unbinding IPs in batches for you, pls wait...',
  会影响到所选分身的IP绑定关系: 'Will affect the IP binding relationship of the selected avatar',
  '批量绑定/解绑所选分身的IP': 'Batch bind/unbind IPs of selected browsers',
  设置IP: 'Set IP',
  绑定静态IP: 'Bind Static IP',
  '适用于对分身长期、持续的运营访问，如：一个店铺需要长期运营，或者一个社交媒体账号需要经常访问；大部分场景下都应选择此选项':
    'Suitable for long-term, consistent operations, like ongoing store management or regular social media access; choose this for most scenarios',
  绑定动态IP: 'Bind Dynamic IP',
  '指出口IP经常变化的IP地址，适用于网站的账号注册、数据抓取、匿名访问等场景，动态IP一般会和无痕分身配合使用':
    'Refers to the IP that the exit IP changes frequently. It is suitable for account registration, data scraping, anonymous browsing, etc. Typically used with stateless avatars',
  '适用于通过API动态提取IP地址的情况，绑定IP池意味着分身使用的IP也会经常变化，和绑定动态IP适用于类似的场景':
    'Suitable for dynamically extracting IP through API. Binding an IP pool means that the IP used by the avatar will also change frequently. Binding dynamic IP is suitable for similar scenarios.',
  联营分身无法修改IP: 'Affiliate browsers cannot modify the IP',
  页面URL: 'Page URL',
  '请输入以http（https）打头的页面URL（不可为空）':
    'URL starting with http (https) (cannot be empty)',
  功能节点: 'Node',
  '请输入页面的功能节点ID（置空代表屏蔽整个页面）':
    'Node ID of the page (Blank means the entire page)',
  备注不得超过128个字符: 'Comments must not exceed 128 characters',
  请输入不超过128字符的备注: 'Pls enter a comment not exceeding 128 characters',
  查看规则: 'View Rules',
  编辑规则: 'Edit Rules',
  添加规则: 'Add Aule',
  '确定要修改所选分身的屏蔽站点列表吗？':
    "Sure to modify the selected avatar's blocked sites list?",
  '对指定分身进行批量修订屏蔽站点列表，将清除分身旧有的屏蔽站点列表，请确认是否要继续':
    "Modify the blocked site list in batches, this will clear the avatar's old blocked site list. Pls confirm whether to continue.",
  '可以事先指定隐藏某些页面或者隐藏页面上的链接、按钮等（暨功能节点），从而预防普通员工执行某些非法/恶意操作；超管和BOSS可以突破此限制':
    'You can specify in advance to hide certain pages or links, buttons, to prevent staff from performing certain illegal/malicious operations; SuperAdmin and BOSS can break through this restriction',
  '备注：': 'Note:',
  页面: ' page',
  整个页面: 'entire page',
  团队全局: 'Team overview',
  '正在为您批量取消收藏，请稍候...': 'We are canceling your favorites in batches, pls wait...',
  '正在为您批量收藏，请稍候...': 'Collecting batches for you, pls wait...',
  '批量收藏/取消收藏': 'Batch collection/cancel collection',
  批量收藏所选的分身: 'Collection of selected avatars in batches',
  批量取消收藏所选的分身: 'Unfavorite selected avatars in batches',
  查看站点: 'View site',
  编辑站点: 'Edit site',
  添加站点: 'Add site',
  '支持*通配符，如*.baidu.com': 'Support * wildcard, such as *.baidu.com',
  未添加插件: 'No plugin added',
  立即添加: 'Add Now',
  允许: 'Allow',
  禁止: 'Proh',
  '您可以指定是否允许用户自行安装插件，还可以指定默认加载的插件列表，在此处指定的插件会给当前团队所有能够访问此分身的用户自动安装':
    'You can specify whether to allow users to install plug-ins themselves, and also specify a list of plug-ins to be loaded by default. The plug-ins specified here will be automatically installed by all users who can access this avatar.',
  允许自行安装插件: 'Allow Plugins Install',
  '用户自行安装的插件只和浏览器运行时相关，并不会在不同用户之间进行同步':
    'Plug-ins installed by users are only related to the browser runtime and will not be synchronized between different users.',
  默认加载的插件列表: 'Plugins Loaded by Default',
  依据插件名称检索: 'Search by plug-in name',
  '您的自有插件可上传至团队资源中，方便对分身进行批量安装':
    'Your own plug-ins can be uploaded to team resources to facilitate batch installation',
  选品专区: 'Product Selection',
  分身运营: 'Operation',
  生产效率: 'Productivity',
  推广营销: 'Promotion',
  团队自有插件: 'Team Plugins',
  '没有找到您心仪的插件？您可以': "Didn't find favorite plugin? you can ",
  自助提交插件: 'Self-Service Submission',
  插件市场: 'Plug-in Market',
  '确定要批量解绑所选分身的指纹实例/模板吗？':
    'Sure to batch unbind the fingerprint instances/templates of the selected avatars?',
  '一旦批量解绑后，分身需要重新绑定指纹实例/模板后才允许访问':
    'Once unbinded in batches, the avatar needs to rebind the fingerprint instance/template before allowing access.',
  您还没有指定指纹模板: 'You have not specified a fingerprint template',
  空闲的指纹实例数量不够: 'There are not enough free fingerprint instances.',
  请确保当前团队空闲的指纹实例数量必须大于拟创建的分身数量:
    'Pls ensure that the number of idle fingerprint instances in the current team must be greater than the number of browsers to be created',
  查看指纹库: 'View fingerprint library',
  联营分身无法修改指纹: 'Affiliate browsers cannot modify fingerprints',
  '3.当前指纹和分身': '3. Current fp and avatar',
  绑定的指纹是重复的: 'The bound fingerprint is duplicated',
  '3.我们强烈建议您，访问任何一个分身的指纹信息都应该是唯一的':
    '3. We strongly recommend that the fp information used to access any browser avaotr should be unique.',
  '浏览器指纹特征码：': 'Browser fp checksum:',
  '1.浏览器指纹是指浏览器以及浏览器所在主机的所有软硬件信息（不含Cookie数据）的组合，而指纹特征码则是对这些信息组合的MD5（消息摘要）编码':
    '1. Browser fingerprint refers to the combination of all software and hardware information (excluding cookie data) of the browser and the host where the browser is located, and the fingerprint signature is the MD5 (message digest) encoding of this combination of information.',
  '2.浏览器指纹信息组合中的任何一个信息的变化都将产生不同且唯一的指纹特征码，您可以通过特征码的唯一性来判断浏览器指纹信息的唯一性':
    '2. Changes in any information in the browser fingerprint information combination will produce different and unique fingerprint signatures. You can judge the uniqueness of the browser fingerprint information by the uniqueness of the signatures.',
  查看指纹信息: 'View fingerprint information',
  '新的浏览器指纹和分身“': 'New browser fingerprints and browsers',
  '”绑定的指纹重复': 'The bound fingerprint is duplicated',
  新的浏览器指纹是全局唯一的: 'New browser fingerprints are globally unique',
  继续保存: 'Continue to save',
  返回编辑: 'Return to edit',
  使用新的浏览器指纹访问分身会产生安全风险:
    'Using new browser fingerprints to access browsers creates security risks',
  使用新的浏览器指纹访问分身不会产生安全风险:
    'Using new browser fingerprints to access browsers poses no security risk',
  '新的指纹特征码：': 'New fingerprint signature:',
  '1.原有浏览器指纹特征码：': '1. Original browser fingerprint signature:',
  'IP归属地依赖于花漾IP地理库的精准性，请您务必确认该地理位置的准确性':
    'The IP attribution depends on the accuracy of HuaYoung IP geographical database. Pls be sure to confirm the accuracy of the geographical location.',
  IP时区: 'IP Time zone',
  IP语言: 'IP Language',
  每次打开浏览器时都使用上述设置: 'Use the above settings every time you open the browser',
  '每次打开浏览器时自动进行一轮探测（温馨提示：此选项依赖于花漾IP地理库的精准性）':
    'Automatically conduct a round of detection every time you open the browser (Reminder: depends on the accuracy of HuaYoung IP Geographical Database)',
  出口IP设置: 'Exit IP settings',
  检测页: 'HuaYoung Detection Page',
  '检测页会对指纹、IP地址做详细检测，适用于绝大部分场景':
    'The detection page will conduct detailed detection of fingerprints and IP, which is suitable for most scenarios.',
  空白页: 'Blank Page',
  空白页包含一些快捷方式: 'Blank page contains some shortcuts',
  继续浏览上次打开的网页: 'Last Page Opened',
  '打开的网页内容仅记录在本地，不在云端同步':
    'The content of the opened page is only recorded locally.',
  指定页面: 'Specify Page',
  您也可以指定下面的URL作为浏览器的默认首页:
    'You can also specify the following URL as default homepage',
  请输入URL: 'Pls enter URL',
  请输入正确的URL: 'Pls enter the correct URL',
  '请指定页面的网址，如有多个页面请每行一个网址，如：':
    'Pls specify the URL of the page. If there are multiple pages, Pls specify one URL per line',
  可以定制访问分身时的浏览器默认首页: 'You can customize HuaYoung browser default homepage',
  可以定制打开分身时的浏览器默认首页: 'You can customize the default homepage of the browser ',
  单次会话总流量超过: 'Total traffic of a single session exceeds',
  请设定流量阈值: 'Pls set traffic threshold',
  MB时中断会话: 'MB will interrupt',
  每隔: 'Every',
  请设定检查间隔: 'Pls set the check interval',
  '分钟检查IP是否切换，如果切换则': 'mins to check, if so ',
  告警: 'Alarm',
  中断会话: 'Abort',
  独占式访问: 'Exclusive',
  协同式访问: 'Collaborative',
  访问方式: 'Access Method',
  '一旦开启独占式访问，意味着同时只能有一个人访问此分身所对应的花漾浏览器':
    'Once exclusive access is enabled, it means that only one person can access the browser corresponding to this avatar at the same time.',
  流量告警策略: 'Traffic Alarm',
  可以设置当访问此分身时的单次会话总流量超额时的应对策略:
    'You can set a response strategy when the total traffic of a single session when exceeds the quota.',
  IP切换策略: 'Switching',
  可以设置当访问此分身时绑定的IP发生动态切换时的应对策略:
    'You can set the response strategy when the IP bound is dynamically switched.',
  '关闭该设置后，浏览器将会允许加载不安全的内容':
    'When this setting is turned off, the browser will allow unsafe content to be loaded',
  Google翻译加速: 'Google Translate',
  '当开启此特性时，会启用花漾的Google翻译加速技术，在花漾浏览器中可以通过右键菜单“翻译成（中文）”对网页进行翻译':
    'When this feature is turned on, HuaYoung\'s Google translation acceleration technology will be enabled. In HuaYoung browser, you can translate web pages through the right-click menu "Translate to (...)',
  员工选择: 'Free Choice',
  不允许录像: 'Not Allowed',
  允许手动暂停跳过: 'Allow manual pause skipping',
  '安全策略是指可以通过事前预防、事中监管、事后审计等维度，将分身纳入安全沙箱托管，安全策略一旦开启将产生费用，但可随时关闭，关闭后将不再产生费用':
    'Security policy refers to the ability to include browser in a security sandbox. Enabling a security policy will incur charges, but it can be turned off at any time.',
  '开启此选项时，意味着有权限的人可以实时查看当前分身访问者的操作行为':
    'Turned on means that people with permission can view the operation behavior in real time.',
  操作录像: 'Aduit Video',
  '访问分身时是否要强制录像，一旦开启此选项，意味着录像文件将占据当前团队的存储空间':
    'Whether to force recording when open the browser. Turned on means that the recording file will occupy the storage space of the team.',
  '如遇输入密码等敏感操作时，可手动暂停录屏，避免敏感信息泄露':
    'If you encounter sensitive operations such as entering passwords, you can manually pause the screen recording to avoid leaking sensitive information.',
  批量修改所选分身的安全策略: 'Modify the security policies of selected browsers in batches',
  '批量开启安全策略，并进行安全策略的详细设置': 'Enable security policies & settings in batches',
  '安全策略是指可以通过事前预防、事中监管、事后审计等维度，将该分身纳入安全沙箱托管':
    'Security policy means that the browser can be included in the security sandbox hosting through dimensions such as pre-prevention, in-process supervision, and post-event auditing.',
  批量关闭安全策略: 'Turn off security policies in batches',
  开启安全策略: 'Turn on security policy',
  '批量关闭安全策略后，旧有的安全策略设置将被清空':
    'After closing security policies in batches, the old security policy settings will be cleared.',
  联营分身无法修改安全策略: 'Affiliate browsers cannot modify security policies',
  请输入域名: 'Pls enter domain name',
  请输入正确的域名: 'Pls enter the correct domain name',
  '只允许输入域名，如：baidu.com，或*.baidu.com':
    'Only domain names are allowed, such as: baidu.com, or *.baidu.com',
  最长100个字符: 'Maximum 100 characters',
  '确定要开启白名单吗？': 'Sure to enable the whitelist?',
  '一旦开启白名单，则只允许访问白名单列表中的网站':
    'Once the whitelist is enabled, only the websites in the whitelist are allowed to access.',
  '确定要开启黑名单吗？': 'Sure to enable the blacklist?',
  '一旦开启黑名单，则可以访问其它网站但无法访问黑名单列表中的网站':
    'Once the blacklist is turned on, you can access other websites but cannot access the websites in the blacklist.',
  '确定要关闭黑白名单吗？': 'Sure to turn off the black and white lists?',
  '一旦关闭黑白名单，则访问不受限制':
    'Once the black and white lists are turned off, access is unrestricted',
  开启白名单: 'Enable Whitelist',
  开启黑名单: 'Enable Blacklist',
  黑: 'black',
  白: 'white',
  名单: 'list',
  该网站规则已存在: 'This website rule already exists',
  '点击确认将更新旧有规则的备注，是否继续？':
    'Clicking Confirm will update the notes of the old rules. Continue?',
  '一旦开启白名单，则只允许访问白名单列表中的网站；一旦开启黑名单，则可以访问其它网站但无法访问黑名单列表中的网站':
    'Once the whitelist is turned on, you are only allowed to access the websites in the whitelist; once the blacklist is turned on, you can access other websites but not the websites in the blacklist.',
  '已开启黑名单，该分身所对应的浏览器不允许访问以下网站':
    'The blacklist has been turned on. The browser does not allow access to the following websites.',
  '已开启白名单，该分身所对应的浏览器只允许访问以下网站':
    'The whitelist has been turned on. The browser is only allowed to access the following websites.',
  '确认要关闭本分身的安全策略吗？': 'Sure to turn off security policy?',
  '关闭后，本分身的所有安全策略将失效，所有的访问将不受限制':
    'After closing, all security policies of this avatar will be invalid and all access will be unrestricted.',
  关闭安全策略: 'Turn off Security Policy',
  '是否要停止此分身的联营状态？': "Do you want to stop this avatar's affiliate status?",
  '一旦停止此分身的联营状态，所有的合作方团队都将无法再次访问本分身':
    'Once the affiliate status of this avatar is stopped, all partner teams will not be able to access this avatar again.',
  停止联营: 'Stop Joint',
  批量修改安全策略会清除所选分身旧有的安全策略:
    'Modifying security policies in batches will clear the old security policies of the selected browsers.',
  联营团队: 'Joint venture team',
  '可以像看直播一样实时监管当前正在打开此浏览器的用户的所有操作，如果有非法操作还可随时中断其操作行为；监管者须拥有“对已有账号的授权与监管权”权限':
    'You can monitor all operations of the user who is currently opening this browser in real time, just like watching a live broadcast. The supervisor must have "Authorization & Supervision" rights for existing accounts',
  '可以设置用户在打开的花漾浏览器中是否进行云端录像，录像存储在云端且无法篡改，可对出现的任何问题进行回溯追责，录像文件亦可下载至本地以形成教程素材':
    'You can set whether the user performs recording in the opened browser. The recording is stored in cloud and cannot be tampered with. Any problems that occur can be traced. The recording file can also be downloaded locally.',
  '确定要解绑此指纹实例吗?': 'Sure to unbind this fingerprint instance?',
  已过滤所有非空闲的指纹实例: 'All non-idle fingerprint instances have been filtered',
  已显示全部指纹实例: 'All FP Instances Shown',
  设置历史: 'Setting History',
  选择浏览器指纹: 'Select fingerprint',
  '确定要选择此指纹实例吗？': 'Sure to select this fingerprint instance?',
  '该指纹正在绑定另一个分身，这有可能会造成一定的安全风险，确定要继续吗？':
    'This fingerprint is being bound to another avatar, which may cause certain security risks. Sure to continue?',
  没有符合条件的指纹实例: 'There are no matching fp instances',
  '缺少“指纹库列表查看权”权限，无法获取指纹实例列表':
    'Lack of "FP Library List View" permission, unable to obtain the fp instance list',
  '平台/浏览器': 'Platform',
  当前绑定状态: 'Current Binding',
  正绑定在当前分身: 'Currently Bound',
  已绑定其它分身: 'Bound to other',
  可用: 'Available',
  '确定要解绑此指纹模板吗?': 'Sure to unbind this fingerprint template?',
  已过滤所有已生成指纹实例的指纹模板:
    'Fingerprint templates have been filtered for all generated fingerprint instances',
  已显示全部指纹模板: 'All fingerprint templates have been displayed',
  '为了确保绝对安全，无痕分身需要绑定一个没有生成过指纹实例的指纹模板；该指纹模板已生成了指纹实例，需要您重新采集一个新的指纹模板且不要在此模板上创建指纹实例':
    'In order to ensure absolute security, the stateless avatar needs to be bound to a fingerprint template that has not generated a fingerprint instance; this fingerprint template has already generated a fingerprint instance, and you need to collect a new fingerprint template.',
  选择指纹模板: 'Select fingerprint template',
  没有符合条件的指纹模板: 'There are no matching fingerprint templates',
  '缺少“指纹库列表查看权”权限，无法获取指纹模板列表':
    'Lack of "fingerprint database list viewing rights" permission, unable to obtain the fingerprint template list',
  已生成指纹实例: 'Fingerprint instance generated',
  团队昵称: 'Nickname',
  '是否要取消本分身和该团队的联合运营？':
    'Do you want to cancel the joint operation between this avatar and this team?',
  '一旦取消本分身和该团队的联合运营，则本分身将从指定团队中移除，指定团队无法再次访问本分身':
    'Once the joint operation between this avatar and the team is cancelled, the avatar will be removed from the designated team.',
  添加新团队: 'Add new team',
  '联营安全策略在所有联营团队中共享，并将约束所有联营团队的所有人员的访问行为':
    'The joint security policy is shared among all joint teams and will restrict the access behavior of all personnel in all joint teams.',
  '出口IP探测中，请稍候...': 'Exit IP detection is in progress, pls wait...',
  本机并未开启系统代理: 'The system proxy disabled',
  '检测到本机并未开启系统代理，这将直接使用本机的IP地址，请确认是否继续':
    'It is detected that the system proxy is disabled. This will directly use the local IP. Pls confirm whether to continue.',
  '出口IP探测失败，请检查代理配置是否正确':
    'Exit IP detection failed, Pls check whether the proxy configuration is correct.',
  重新修订所选分身的本地代理: 'Revise the local proxy of the selected avatars',
  '正在为您批量关闭本地代理，请稍候...': 'Closing local proxies in batches for you, pls wait...',
  本地代理关闭成功: 'Local proxy closed successfully',
  关闭本地代理: 'Turn Off Local Proxy',
  IP探测: 'IP Detection',
  本机浏览器的代理设置: 'Browser Settings',
  本机系统代理设置: 'System Proxy',
  '批量开启/关闭所选分身的本地代理':
    'Enable/disable local proxies for selected browsers in batches',
  '开启本地代理服务时，系统并不会检查出口IP的正确性，请用户自行确认出口IP是否会对分身运营带来安全风险':
    'When the local proxy is enabled, the system will not check the correctness of the export IP. Users are asked to confirm whether the export IP will bring security risks.',
  本机IP直连: 'Local IP',
  '（无论是否开启系统代理，始终使用本机IP直连）': '(Local IP is always used for direct connection)',
  '（系统代理开启时则使用系统代理，否则使用本机IP直连）':
    '(When system proxy enabled, use system proxy; otherwise using local IP.)',
  局域网代理: 'LAN Proxy',
  '正在为您批量设置本地代理，请稍候...': 'Setting local proxy in batches for you, pls wait...',
  本地代理设置成功: 'Local proxy set up successfully',
  请选择代理协议: 'Pls select protocol',
  代理主机: 'Proxy',
  请输入代理主机地址: 'Pls enter the proxy host address',
  代理主机地址: 'Proxy Host',
  无法在网页端中设置本地代理: 'Unable to set local proxy in browser',
  '请下载花漾客户端，并在花漾客户端中完成设置':
    'Pls download the HuaYoung app and complete the settings in the HuaYoung app',
  '为所选{{type}}批量增加标签': 'Add tags to selected {{type}} in batches',
  '正在为您批量增加标签，请稍候...': 'Adding tags in batches for you, pls wait...',
  批量增加标签成功: 'Adding tags in batches successfully',
  '批量清空后，可以通过批量打标签重新关联新的标签':
    'After the batch is cleared, you can re-associate new tags through batch tagging.',
  '正在为您批量清空标签，请稍候...': 'Clearing tags in batches for you, pls wait...',
  '所选{{type}}标签已清空': 'The selected tags has been cleared',
  '增加/清空标签': 'Add/Clear Tags',
  '为所选{{type}}批量清空所有标签': 'Clear all tags in batches for selected {{type}}',
  团队资源功能屏蔽列表: 'Team resource function blocking list',
  '直连（本机IP）': 'Direct Conn(local IP)',
  规则: 'rule',
  网址规则不能为空: 'URL rule cannot be empty',
  网址规则: 'URL rules',
  通讯方式: 'communication method',
  排序: 'sort',
  网站规则: 'Website rules',
  '确定要关闭流量计划吗？': 'Sure to turn off the data plan?',
  '一旦关闭流量计划，意味着设置的副IP将被解除，所有的网站规则也会被清空，请确认是否继续':
    'Once the traffic plan is closed, it means that the set secondary IP will be released and all website rules will be cleared. Pls confirm whether to continue.',
  未绑定副IP: 'Not bound to the secondary IP',
  关闭流量计划: 'Turn off traffic plan',
  批量修订流量计划会清除所选分身旧有的流量计划:
    'Bulk revision of traffic plans will clear the old traffic plans of the selected browsers',
  一旦在这里设置副IP将影响到所有分身的副IP设置:
    'Once the secondary IP is set here, it will affect the secondary IP settings of all browsers.',
  各个分身有自己的主IP: 'Each avatar has its own main IP',
  分身主IP: 'Main IP',
  分身副IP: 'Sub-IP',
  默认通讯IP: 'Default IP',
  内网网址: 'Intranet URL',
  '一旦允许访问内网网址，则在访问内网时，使用直连（本机IP）访问，但对内网网站而言，这样会暴露您的本机IP，请慎重开启':
    'Once access to the intranet URL is allowed, use direct connection (local IP) when accessing the intranet. However, for intranet websites, this will expose your local IP, so Pls enable it carefully.',
  '当前分身未绑定主IP，无法开启流量计划':
    'The current avatar is not bound to the main IP and cannot enable the traffic plan.',
  '没有绑定主IP，设定流量计划是没有意义的，请为分身绑定主IP后再开启':
    'Without binding the main IP, it is meaningless to set the traffic plan. Pls bind the main IP and then enable it.',
  批量修改所选分身的流量节省计划: 'Modify the data saving plan of selected browsers in batches',
  主副IP: 'Primary and secondary IP',
  '流量计划是指您可以再指定一个IP地址作为分身的副IP，您可以设定URL匹配规则来决定哪些网址走哪个IP进行通讯，它特别适用于以下两种场景：':
    'Traffic planning means that you can specify another IP as the secondary IP. You can set URL matching rules to determine which URLs use which IP for communication. It is especially suitable for the following two scenarios:',
  '1、如果您的IP流量非常昂贵，您可以将一些非敏感网站经由副IP通讯，它将能够大幅减少主IP的流量费用':
    '1. If your IP traffic is very expensive, you can communicate some non-sensitive websites through the secondary IP, which will significantly reduce the traffic cost of the main IP.',
  '2、如果您的主IP无法访问个别网址，可将这些网址交由副IP通讯':
    '2. If your main IP cannot access certain URLs, you can transfer these URLs to the secondary IP for communication',
  '无法匹配上述规则的网址，全部通过': 'URLs that cannot match the above rules will be passed.',
  通讯: 'communication',
  没有分身访问权限: 'No Avatar_Access',
  '对不起，您没有此分身的访问权限，详情请联系团队管理者':
    'Sorry, you do not have access rights to this avaotr. Pls contact the team manager for details.',
  您无法查看联营分身的操作轨迹: 'You cannot view the operation track of affiliated browsers',
  将分身导入到平台: 'Import browsers into the platform',
  '将本分身与IP【': 'Combine this avatar and IP【',
  '将本分身绑定到IP由【': 'Bind this avatar to the IP by [',
  '设置分身：': 'Set up browsers:',
  '】访问分身失败，失败原因：': '】Failed to open browser. Reason for failure:',
  访问尚未结束: 'The visit is not over yet',
  '】访问分身，': ']Open the browser,',
  创建并管理多个浏览器分身: 'Create & Manage Multiple Browser Avatars',
  '在花漾中，“分身”代表的是一个完全隔离的浏览器实例环境，它拥有自己的软硬件特征（浏览器指纹）与网络通路（IP隔离）':
    '"Browser Avatar" represents a completely isolated browser environment, which has its own characteristics (browser fingerprint) and IP.',
  '您可创建并管理多个分身，这意味着可以同时打开若干个隔离的浏览器，同时访问同一个网站而不会相互影响；如有需要请查看':
    'You can create multiple browser avatars, allowing simultaneous visits to the same site without interference.',
  '如有需要，您可为每个分身绑定一个IP地址/本地代理（可选）；您可以购买我们为您提供的IP地址，也可以导入您已经拥有的IP资源':
    'You can bind an IP/local proxy to each browser avatar(optional); you can buy IP here, or import IP resources you already own.',
  '立即购买/导入': 'Buy/Import',
  IP地址已就绪: 'IP address is ready',
  电商平台店铺管理: 'E-commerce Platform Store Management',
  '每个浏览器分身代表一个店铺账号，通过浏览器指纹模拟不同的电脑环境，通过花漾为您提供的静态、独享的 IP 地址实现网络隔离，确保多店铺防关联安全运营':
    'Each browser avatar represents a store account, simulates different computer environments through the browser fingerprint, and realizes network isolation through the static and exclusive IP address provided by Blossom to ensure the safe operation of multiple stores',
  创建店铺账号: 'Create Store Avatar',
  '如何做到跨境电商多店铺防关联安全运营？':
    'How to achieve cross-border e-commerce multi-store anti-association and safe operation?',
  社交媒体账号矩阵: 'Social Media Account Matrix',
  批量创建社媒账号: 'Create Social Media Avatars',
  '如何更好的管理社交媒体账号矩阵？': 'How to better manage the social media account matrix?',
  '当已有浏览器指纹实例与IP地址/本地代理（可选）后，您可依需创建多个浏览器分身，然后便可以打开分身所对应的花漾浏览器':
    'You can create multiple browser avatars as needed, and then open the HuaYoung Browser corresponding to the avatar.',
  '浏览器指纹尚未就绪，确定要新建分身吗？':
    'The browser fingerprint is not ready yet. Sure to continue?',
  '花漾灵动特别适合于对社交媒体账号矩阵的管理，每个分身代表一个社媒账号，在一台电脑中可同时管理上千个社媒矩阵，更可辅以 RPA 机器人完成社媒内容的发布、点赞、评论等自动化操作':
    'HuaYoung is specially designed for managing social media account matrices. Each avatar represents a social media account, and you can manage thousands of social media accounts at the same time on a single computer, and you can also complete automated operations such as social media content publishing, like, comment, etc. through RPA robots.',
  '我们建议您先创建浏览器指纹并完成IP地址购买后，再新建分身':
    'We recommend that you create a browser fingerprint and buy/import the IP before create a browser.',
  继续新建分身: 'Continue',
  'IP地址尚未就绪，确定要新建分身吗？': 'The IP is not ready yet. Sure to continue?',
  '我们建议您先购买/导入IP地址后，再新建分身': 'We recommend that you buy/import IP first',
  '立即新建/导入': 'Create Now',
  '如果您已经对浏览器指纹、IP地址等知识有所了解，通过专家模式能够让您更好的了解花漾的技术内幕与实现机制':
    'If you already know something about browser fingerprints, IP, etc., expert mode will allow you to better understand HuaYoung’s technology and implementation mechanisms.',
  新手模式: 'Newbies',
  一键创建多个浏览器分身: 'Create Multiple Browser Avatars With One Click',
  '作为一名新手，请直接了当的为我创建几个浏览器分身环境，让我立即体验一下':
    'As a newbie, pls create several browser avatars for me directly so that I can experience it immediately.',
  '如果您是一名新手，想快速体验花漾灵动，建议选择新手模式，一键创建多个分身':
    'If you are a novice and want to quickly experience HuaYoung, it is recommended to choose the newbies mode and create multiple browser avatars with one click.',
  最近访问时间: 'Last Visit Time',
  执行流程: 'Exec RPA Proc Script',
  分身列表字段自定义: 'List Field Customization',
  打开时间: 'Opening',
  依据名称或IP检索: 'Search by name/ip',
  请先选中1至多个分身: 'Pls select 1 or more browsers first',
  一次最多打开15个分身: 'A maximum of 15 browsers can be opened at one time',
  请重新选择您要打开的分身: 'Pls reselect the avatar you want to open',
  修改分身属性: 'Properties',
  批量修改授权: 'Authorize',
  '绑定/解绑IP': 'Bind/Unbind IP',
  '开启/关闭本地代理': 'En/Dis Local Proxy',
  流量节省计划: 'Traffic Savings',
  '收藏/取消收藏': 'Favs/Unfavs',
  修改安全策略: 'Security Policy',
  批量清理分身: 'Clean',
  默认属性: 'Default Props',
  无法在普通浏览器下访问分身: 'Unable open HuaYoung broser',
  '请下载花漾客户端程序，花漾客户端内置花漾灵动，只有通过花漾灵动才能访问分身。':
    'Pls download the HuaYoung app. The HuaYoung browser has built-in HuaYoung. ',
  当前客户端版本过低: 'The current client version is too low',
  您需要下载最新的版本后才能够打开花漾灵动:
    'You need to download the latest version before you can open HuaYoung Smart',
  您正在打开了一个访问当前分身的浏览器实例:
    'You are opening a browser instance accessing the current avatar',
  '请关闭正在访问当前分身的浏览器实例，然后再进行访问':
    'Pls close the browser instance that is accessing the current avatar and then access it again',
  当前分身未设置浏览器指纹: 'The current avatar does not have a browser fingerprint set',
  分身需要设置浏览器指纹后才能够访问:
    'The avatar needs to set up a browser fingerprint before it can access',
  设置指纹: 'Set fingerprint',
  当前分身未设置浏览器指纹模板: 'The current avatar does not have a browser fingerprint template',
  分身需要设置浏览器指纹模板后才能够访问:
    'The avatar needs to set up a browser fingerprint template before it can access',
  设置指纹模板: 'Set fingerprint template',
  'IP正处于准备状态，无法访问分身': 'The IP is in preparation state and cannot open the browser.',
  '当前分身绑定的IP正处于准备状态，这大概需要5分钟左右的时间，请您稍候，当IP就绪后方可访问':
    'The IP bound to the current avatar is in the preparation state. This will take about 5 minutes. Pls wait and access it only when the IP is ready.',
  '，无法打开会话，您需要先购买花瓣并使其余额大于':
    ', the session cannot be opened, you need to purchase tokens first and have a balance greater than',
  才能够打开会话: ' to open the session',
  取消访问: 'Cancel access',
  '，这可能会影响到此分身的会话过程，建议您先购买适量花瓣后再访问分身':
    '. It is recommended that you purchase an appropriate amount of tokens before open the browser.',
  '未绑定IP地址，确定要访问吗？': 'The IP address is not bound. Sure to access?',
  '当前分身未绑定IP地址，继续访问意味着将使用本机系统代理（如果开启的话）或者本机IP地址（如果未开启系统代理），系统并不会检查出口IP的正确性，也不会修改指纹中跟IP相关的动态属性（如经纬度、时区、语言等），这可能会对分身运营带来安全风险，确信要继续访问吗？':
    'The current avatar is not bound to an IP address. Continued access means that the local system proxy (if it is enabled) or the local IP address (if the system proxy disabled) will be used. The system will not check the correctness of the exit IP, nor will it Modifying the dynamic attributes related to the IP in the fingerprint (such as latitude and longitude, time zone, language, etc.) may bring security risks to the avatar operation. Sure to continue accessing?',
  IP池没有可用IP: 'There is no available IP in the IP pool',
  IP池没有空闲IP可供分配: 'There are no free IPs in the IP pool for allocation',
  '当前分身绑定的IP池没有空闲的IP可供分配，请您稍候再次尝试访问':
    'The IP pool currently bound to the avatar has no free IPs available for allocation. Pls try to access again later.',
  '打开浏览器失败，请查看以下失败原因':
    'Failed to open the browser, Pls check the following failure reasons',
  '分身绑定的主IP的配额流量已用完，本次访问将消耗花瓣余额':
    'The quota traffic of the main IP has been used up. This visit will consume the token balance.',
  '当前分身绑定的主IP的配额流量（': 'Quota traffic of the primary IP bound to the current avatar (',
  '）已用完，如果继续访问，将产生花瓣消耗，请确保花瓣余额大于':
    ') has been used up. If you continue to access, tokens will be consumed. Pls ensure that the token balance is greater than',
  团队的花瓣余额不足: "The team's token balance is insufficient.",
  '当前团队内免费存储空间已用完，花瓣余额已经不足以支撑当天的存储费用，请您立即对团队花瓣余额进行充值，或修改分身访问策略，禁用录像审计的特性':
    'The free storage space in the current team has been used up, and the token balance is no longer enough to support the storage cost for the day. Pls recharge the team token balance immediately, or modify the avatar access policy to disable the recording audit feature.',
  当前团队的花瓣余额不够: 'The current team’s token balance is not enough',
  '当前团队内可用存储空间不足，这意味着当前访问分身的录像可能记录不完整，建议您立即购买花瓣':
    'There is currently insufficient storage space available within the team, which means that the recording of the current visit to the avatar may be incomplete. It is recommended that you purchase tokens immediately.',
  当前分身正在被其他成员访问: 'The current avatar is being accessed by other members',
  访问分身失败: 'Failed to access avatar',
  '当前分身的缓存数据过大，将导致打开浏览器极为缓慢':
    'The cache data of the current avatar is too large, which will cause the browser to be opened extremely slowly.',
  '强烈建议您将IndexDB与LocalStorage的云端同步策略关闭，这样将大大加速打开浏览器的运行速度':
    'It is strongly recommended that you turn off the cloud synchronization strategy between IndexDB and LocalStorage, which will greatly speed up the speed of opening the browser.',
  帮我关闭: 'help me close',
  忽略且不再提醒: 'Ignore and don’t remind again',
  本次忽略: 'Ignore this time',
  网络连接失败: 'Network connection failed',
  该分身绑定的IP地址无法正常连接:
    'The IP address bound to this avatar cannot be connected normally.',
  '该分身绑定的IP地址无法正常连接，请到此IP的详情页面，更改其连接方式，选择其它的接入点或者开启直连，确保可以正常连接此IP地址后，再重新访问':
    'The IP address bound to this avatar cannot be connected normally. Pls go to the details page of this IP, change its connection method, select another access point or enable direct connection. Make sure that this IP address can be connected normally before accessing again.',
  转到IP详情: 'Go to IP details',
  无法查看进一步信息: 'Unable to view further information',
  '该分身由他人分享给当前团队联合运营，您无法查看进一步信息':
    'This avatar is shared by others and jointly operated by the current team. You cannot view further information.',
  '该分身处于转让中，您无法查看进一步信息':
    'This avatar is under transfer and you cannot view further information.',
  缺少可用的指纹模板: 'Missing fingerprint template available',
  '当前团队尚未创建指纹模板，您需要采集指纹模板':
    'The current team has not created a fingerprint template. You need to collect a fingerprint template.',
  缺少可用的指纹实例: 'Missing available fingerprint instance',
  '当前团队尚未创建指纹实例，您需要采集指纹模板再通过指纹模板裂成生成指纹实例':
    'The current team has not created a fingerprint instance. You need to collect a fingerprint template and then split the fingerprint template to generate a fingerprint instance.',
  缺少可用的IP地址: 'Missing available IP address',
  '当前团队尚未导入/购买IP地址，您需要先导入自有的IP地址或者采购平台提供的IP地址':
    'The current team has not imported/purchased IP addresses. You need to import your own IP addresses or the IP addresses provided by the purchasing platform first.',
  查看IP地址: 'View IP address',
  '确定要使用此分身默认的“访问策略”以访问吗？':
    "Sure to use this avatar's default Access Policy for access?",
  '分身访问策略约定了用户在打开花漾灵动访问分身时的规则与限制，包括是否允许协同式访问、是否开启访问录像、是否开启实时监管等':
    'The avatar access policy stipulates the rules and restrictions for users to open HuaYoung Smart to access avatars, including whether to allow collaborative access, whether to enable access recording, whether to enable real-time supervision, etc.',
  使用默认策略继续访问: 'Continue using default policy',
  查看访问策略: 'View access policy',
  '分身未绑定IP地址，无法访问': 'Not bound to an IP and cannot open.',
  '该分身是一个联营分身，必须要由主队绑定IP地址后才允许客队访问，否则会对运营带来风险':
    'This avatar is a joint avatar. The home team must bind the IP address before allowing the visiting team to access it. Otherwise, it will bring risks to the operation.',
  当前分身绑定的指纹实例需要升级:
    'The fingerprint instance bound to the current avatar needs to be upgraded.',
  '当前分身绑定的指纹实例的浏览器版本与花漾浏览器内核版本不一致，建议进行同步升级，否则可能会给运营带来风险':
    'The browser version of the fingerprint instance bound to the current avatar is inconsistent with the kernel version of HuaYoung Browser. It is recommended to upgrade simultaneously, otherwise it may bring risks to operations.',
  客户端浏览器内核过旧: 'The client browser kernel is too old',
  正在为您进行浏览器指纹的升级: 'The browser fingerprint is being upgraded for you.',
  浏览器指纹升级完毕: 'Browser fingerprint upgrade completed',
  您现在可以正常访问分身并打开花漾浏览器了:
    'You can now open the browser normally and open HuaYoung Browser',
  浏览器指纹升级失败: 'Browser fingerprint upgrade failed',
  当前团队的浏览器指纹需要升级: 'The current team’s browser fingerprint needs to be upgraded',
  花漾浏览器内核升级至: 'HuaYoung browser kernel upgraded to',
  '版本，这意味着浏览器指纹的 UserAgent 需要同步升级进行内核匹配，浏览器指纹的升级只会让您的运营环境更加安全，建议您立即升级':
    'version, which means that the UserAgent of the browser fingerprint needs to be upgraded synchronously for kernel matching. The upgrade will make environment more secure. It is recommended to upgrade.',
  只对当前分身绑定的指纹实例进行升级:
    'Only upgrade the fingerprint instance bound to the current avatar.',
  '对当前团队所有指纹实例进行升级（建议）':
    'Upgrade all fingerprint instances of the current team (recommended)',
  立即升级: 'Upgrade',
  不升级继续访问: 'Continue accessing without upgrading',
  联营分身无法直接删除: 'Affiliate browsers cannot be deleted directly',
  '该分身处于联营状态，请停止联营后方可删除':
    'This avatar is in an affiliated state. Pls stop the affiliated operation before deleting it.',
  确定要删除分身吗: 'Sure to delete the avatar?',
  '分身一旦删除无法撤销，请慎重': 'Once deleted, the avatar cannot be undone, so Pls be careful.',
  同步删除与此分身绑定的指纹实例: 'Delete the fp instance bound to this avatar',
  '如果分身是常规分身，勾选此选项会同步删除绑定在此分身上的指纹实例；如果是无痕分身则忽略此选项':
    'If the avatar is a regular avatar, checking this option will simultaneously delete the fingerprint instance bound to the avatar; if it is a stateless avatar, ignore this option',
  删除分身时同步删除与此分身绑定的指纹实例是为了避免指纹实例无意中被重复使用:
    'When deleting an avatar, the fingerprint instance bound to the avatar is deleted simultaneously to prevent the fingerprint instance from being inadvertently reused.',
  '正在为您批量删除选定的分身，请稍候...':
    'Deleting selected browsers in batches for you, pls wait...',
  '正在为您删除分身，请稍候...': 'Deleting the avatar for you, pls wait...',
  该指纹实例同时绑定了多个分身:
    'This fingerprint instance is bound to multiple browsers at the same time',
  '该分身绑定的指纹实例同时绑定了其它分身，删除指纹实例会导致其它分身不可用，确定要继续吗？':
    'The fingerprint instance bound to this avatar is also bound to other avatars. Deleting the fingerprint instance will make other avatars unavailable. Sure to continue?',
  '等分身绑定的指纹实例同时绑定了其它分身，删除指纹实例会导致其它分身不可用，确定要继续吗？':
    'If the fingerprint instance bound to a avatar is also bound to other browsers, deleting the fingerprint instance will make other browsers unavailable. Sure to continue?',
  联营中: 'In Joint',
  转让中: 'Trans',
  已复制至剪切板: 'Copied to clipboard',
  '差异化文本输入的历史记录，记录格式为：{分身名称}:{inputText1},{inputText2}':
    'The history of text input, the record format is: {AvatarName}:{inputText1},{inputText2}',
  文本导入: 'Import',
  '将输入内容以换行符作为分割，依次填入到分身窗口中':
    'Separate the input content with newline and fill it in the browser window one by one.',
  汉: 'Han',
  文本生成: 'Generate',
  通过表达式生成随机文本: 'Generate random text from expression',
  sequence初始值: 'Sequence Init Value',
  步长: 'Step',
  表达式使用说明: 'Expression Usage',
  '：生成5个随机的小写英文字符': ': Generate 5 random lowercase characters',
  '：生成6个随机的大写英文字符': ': Generate 6 random uppercase characters',
  '：生成7个随机的大小写英文字符': ': Generate 7 random uppercase and lowercase characters',
  '{汉6}': '{Han 6}',
  '：生成6个随机的中文汉字': ': Generate 6 random Chinese characters',
  '：0-9随机的一个数字': ': A random number from 0-9',
  '：从指定数字开始的顺序数字，步长值可设置':
    ': Sequential numbers starting from the specified number, the step value can be set',
  '：符号!@#$%^&*中的任意一个': ': Any one of the symbols !@#$%^&*',
  常用示例: 'Examples',
  '注册账号：': 'Register An Account: ',
  '高强度密码：': 'Strong Password: ',
  选择主控窗口: 'Select the main control window',
  '请选择一个浏览器分身窗口作为主控窗口，对主控窗口的所有操作会同步到其它窗口中':
    'Pls select a browser window as the main control. All operations on the main control window will be synchronized to others.',
  给主控窗口加上边框: 'Add a border to the main control window',
  自动调整窗口大小: 'Autom Resize',
  '（确保摆放全部窗口）': '(make sure to place all windows)',
  '指定窗口大小：': 'Specify Size:',
  宽: 'Width',
  请输入窗口大小: 'Pls enter window size',
  高: 'High',
  请选择您要排列的窗口: 'Pls select the windows you want to arrange',
  显示器: 'Monitor',
  每行窗口数量: 'Windows Per Row',
  起始位置: 'Starting Point',
  请输入起始位置: 'Pls enter starting location',
  窗口间距: 'Window Spacing',
  横向: 'Hor',
  请输入窗口间距: 'Pls enter the window spacing',
  纵向: 'Ver',
  窗口偏移: 'Win offset',
  请输入窗口偏移: 'Pls enter window offset',
  开始排列: 'Arranging',
  窗口布局: 'Windows Layout',
  仅支持Windows平台: 'Only supports Windows OS',
  文本输入: 'Text Input',
  '相同文本输入：': 'Same Text Input:',
  输入: 'Enter',
  '差异化文本输入：': 'Diff Text Input:',
  '为方便您记录所有的输入内容，凡是输入的历史记录都会为您保存下来':
    'In order to facilitate you to record all input , all input history records will be saved for you.',
  查看历史记录: 'View History',
  未开启群控: 'Group control disabled',
  只有开启群控后才能够进行多窗口文本输入:
    'Multi-window text input is only possible after group control is enabled.',
  请至少选择一个打开浏览器窗口的分身名称:
    'Pls select at least one avatar to open the browser window',
  开启群控: 'Enable GC',
  群控程序下载失败: 'Group control program download failed',
  群控程序下载成功: 'Group control program downloaded successfully',
  您当前没有打开的分身窗口: 'You have no browser windows currently open.',
  打开浏览器窗口的分身名称: 'Browser Name',
  '主控（RPA）': 'Master (RPA)',
  主控: 'Master',
  '被控（RPA）': 'Accused (RPA)',
  被控: 'Accused',
  查看窗口: 'View Window',
  关闭窗口: 'Close Window',
  '确定要停止群控吗？': 'Sure to stop group control?',
  停止群控后将不再有主控之分:
    'After stopping group control, there will no longer be a master control',
  停止群控: 'Stop GC',
  最小化: 'Minimize',
  显示: 'Show',
  显示窗口: 'Arrange Windows',
  '确定要统一所有浏览器分身窗口的标签吗？': 'Sure to unify the labels of all browser windows?',
  '以主控窗口为准，会将其它所有浏览器分身的标签进行统一，包括标签访问的网站、顺序、数量等等':
    'Based on the main control window, the tags of all other browsers will be unified, including the websites visited by the tags, order, quantity, etc.',
  统一标签: 'Unified Label',
  可开启: 'Can',
  '系统内置四个角色：超级管理员、BOSS、经理、员工，每个角色默认拥有的权限如下所示；目前暂不支持对角色进行权限定义，但允许对指定的经理或员工进行权限管理':
    'The system has four built-in roles: SuperAdmin, BOSS, Manager, and Staff, each with default permissions. Role-specific permission customization is not available, but you can manage permissions for specific managers or staff.',
  个权限项: ' permission items',
  功能模块: 'Module',
  权限项: 'Permission Items',
  权限项说明: 'Permission Item Description',
  创建成员: 'Create',
  '可通过电子邮件地址创建团队成员账号，接收到邮件的用户在完成用户注册后，将自动按照您约定的角色与部门加入到本团队':
    'Team member accounts can be created through email. Users who receive the email will automatically join the team.',
  邀请邮件已成功发送: 'The invitation email has been sent successfully',
  '系统向您指定的邮箱发送了邮件，收到邮件的小伙伴们点击邀请链接即可加入您的团队':
    'The system sent an email to your designated email. Friends who received the email can click the invitation link to join your team.',
  邮箱地址: 'Email',
  请填写邮箱地址: 'Pls fill in your email',
  请填写正确的邮箱地址: 'Pls fill in the correct email',
  '可一次创建多个团队成员账户，用半角逗号分隔':
    'Multiple team member can be created at one time, separated by ,',
  所属角色: 'Role',
  请选择所属部门: 'Pls select your department',
  所属部门: 'Department',
  'BOSS：拥有团队的全部业务管理权限': 'BOSS: Has all business management rights of the team',
  '“经理”角色默认拥有以下权限：': 'Manager role has the following permissions by default:',
  管理已授权分身的权限: 'Manage the permissions of authorized browsers',
  管理其所在部门成员的权限: 'Manage permissions for members of their department',
  可将其管理的分身授权给部门其他成员:
    'Can authorize the browsers it manages to other members of the department',
  查看其管理的分身及成员日志的权限: 'Permission to view logs of browsers and members it manages',
  '“员工”角色默认拥有以下权限：': 'The "Employee" role has the following permissions by default:',
  查看其授权的分身的权限: 'View the permissions of its authorized browsers',
  访问其授权的分身的权限: 'Permission to access its authorized browsers',
  请输入团队昵称: 'Pls enter team nickname',
  请输入该用户在团队内的的昵称: "Pls enter the user's nickname within the team",
  '同一个用户可以在不同的团队拥有不同的昵称，以示区分':
    'The same user can have different nicknames in different teams to distinguish them.',
  角色: 'Role',
  超级管理员与BOSS只能隶属于一级部门:
    'SuperAdmin and BOSS can only belong to first-level departments',
  '经理/员工可以同时加入多个部门':
    'Managers/employees can join multiple departments at the same time',
  团队审核: 'Audit',
  审核: 'Audit',
  审核成功: 'Aduit successful',
  请再次确认以下: 'Pls confirm the following again',
  '信息：': 'information:',
  转让人: 'Assignor',
  联营人: 'Associate',
  IP转让策略: 'IP transfer strategy',
  随分身一起转让: 'Transferred together with the avatar',
  不随分身一起转让: 'Not transferred with the avatar',
  指纹转让策略: 'Fingerprint transfer strategy',
  请输入拒绝理由: 'Pls enter the reason for rejection',
  拒绝成功: 'reject success',
  '您选择拒绝接收分身，请在下方输入您的拒绝理由：':
    'You choose to refuse to receive the avatar, please enter your reason for refusal below:',
  拒绝理由: 'Reason for rejection',
  '请输入拒绝理由，最多30字': 'Pls enter the reason for rejection, up to 30 characters',
  请至少选择一个部门: 'Pls select at least one department',
  '请配置该成员的角色及部门信息：': "Pls configure the member's role and department information:",
  '是否确定拒绝加入团队申请？': 'Sure to reject the application to join the team?',
  '拒绝后，该用户本次将无法加入您的团队':
    'After rejection, the user will not be able to join your team this time',
  成员昵称: 'Nickname',
  依据成员昵称或手机号检索: 'Search by Nickname or Phone',
  审核类型: 'Review type',
  全部审核类型: 'All Types',
  申请时间: 'Application Time',
  待审批: 'Pending',
  已通过: 'Approved',
  通过: 'Approve',
  未通过: 'Did not pass',
  文件大小不应该超过100K: 'File size should not exceed 100K',
  请设置您的公钥: 'Pls set your public key',
  请妥善保密您的私钥: 'Pls keep your private key secret',
  '密钥对已生成，请下载公钥与私钥文件，请您':
    'The key pair has been generated. Pls download the public key and private key files. Pls',
  务必妥善保管此文件: 'Be sure to keep this document in a safe place',
  '，否则，您将无法登录创建的主机系统':
    ', otherwise, you will not be able to log in to the created host system',
  下载公钥文件: 'Download public key file',
  添加云账户: 'Add Cloud Account',
  选择云厂商: 'Choose Cloud Vendor',
  设置API凭证: 'API Credentials',
  AccessKey是为了调用: 'AccessKey is for calling ',
  'OpenAPI的认证凭证，您需要在您的': ' OpenAPI authentication credentials you need in your ',
  账户中生成AccessKey并录入到这里: ' generateed ',
  云账户名称: 'Cloud Account',
  请输入云账户名称: 'Pls enter the cloud account name',
  请输入AccessKeyID: 'Pls enter AccessKeyID',
  设置密钥对: 'Key Pair',
  '密钥对包含公钥与私钥，系统将持有公钥，而私钥只能保存在您那里。系统将使用公钥创建Linux或Windows主机，而您将通过私钥登录Linux主机或者通过私钥获取Windows主机的登录密码。您必须确保安全、妥当的保存了私钥':
    'The key pair contains a public key and a private key. The system will hold the public key, but the private key can only be kept with you. The system will use the public key to create a Linux or Windows host, and you will use the private key to log in to the Linux host or obtain the login password of the Windows host through the private key.',
  帮我生成一个新的密钥对: 'Generate a New Key Pair',
  '导入一个我已经拥有的密钥对（只导入公钥）': 'Import a Key Pair(Only the Public key)',
  '请选择浏览您的公钥文件，或者将公钥文件粘贴至文本框内：':
    'Pls choose to browse your public key file, or paste it into the text box:',
  云账户设置成功: 'Cloud Account Set Up sSccessfully',
  '恭喜，您的云账户设置成功，您现在可以使用该云账户创建您的主机和IP地址了':
    'Congratulations, your cloud account is set up successfully. You can now use the cloud account to create your host and IP address.',
  '添加团队云账户，并使用该云账户来创建主机与IP地址':
    'Add a team cloud account and use the cloud account to create hosts and IP addresses',
  '确定要删除所选功能屏蔽规则吗？': 'Sure to delete the selected feature blocking rules?',
  '如果所选功能屏蔽规则被分身引用，将无法删除':
    'If the selected function blocking rule is referenced by a avatar, it cannot be deleted.',
  '功能屏蔽是指对指定网站的某些页面或链接、按钮（暨功能节点）进行屏蔽，避免用户误操作或者非法操作':
    'Function blocking refers to blocking certain pages, links, buttons of designated websites to prevent users from misoperation or illegal operations.',
  '确定要删除所选云帐户吗?': 'Sure to delete the selected cloud account?',
  '已经通过该云账户创建的主机与IP地址，并不会因为云账户的删除而自动清理':
    'The hosts that have been created through this cloud account will not be automatically cleared due to the deletion of the cloud account.',
  '删除成功！': 'Successfully deleted!',
  台: ' ',
  添加新的云账户: 'Add Cloud Account',
  团队公有云账户: 'Public Cloud Account',
  '您可以设置自己的公有云账户，系统将使用您的云账户购买IP地址':
    'You can set up your own public cloud account and the system will use your cloud account to purchase the IP address',
  添加团队公有云账户: 'Add a public cloud account',
  '请选择您的私钥文件，我们将为您验证密钥对是否匹配：':
    'Pls select your private key file and we will verify the key pair matches for you:',
  您的私钥文件和系统保存的公钥文件是匹配的:
    'Your private key file matches the public key file saved by the system',
  您的私钥文件和系统保存的公钥文件不匹配:
    'Your private key file does not match the public key file saved by the system',
  密钥对: 'key pair',
  云账户属性: 'Cloud account properties',
  '密钥对是登录Linux主机或者获取Windows主机登录密码的前提条件；无论是系统为您生成的密钥对还是您导入的密钥对，系统只为您保存了公钥文件，请确认您已经妥善保管了私钥文件；同时请注意，一旦您更换密钥对，只对新创建的主机有效':
    'A key pair is a prerequisite for logging into a Linux host or obtaining a login password for a Windows host; whether it is a key pair generated by the system for you or a key pair imported by you, the system only saves the public key file for you, Pls make sure you have properly Keep the private key file; Pls note that once you change the key pair, it will only be valid for the newly created host.',
  公钥文件名称: 'Public key file name',
  公钥文件指纹: 'Public key file fingerprint',
  您可以在这里: 'You can find it here',
  验证密钥对: 'Verify key pair',
  更换密钥对: 'Change key pair',
  '您需要选择当前团队的一个用户作为AccessKey的调用身份，生成后的AccessKey请注意妥善保存':
    'You need to select a user of the current team as the calling identity of AccessKey. Pls save the generated AccessKey properly.',
  用户身份: 'User ID',
  无限制: 'Unlimited',
  '通过花漾OpenAPI，您能够通过程序/脚本来控制花漾客户端与浏览器；在生成AccessKey之前，需要先开启API':
    'Through HuaYoung OpenAPI, you can control HuaYoung app and browser through programs/scripts; before generating AccessKey, you need to enable the API first',
  启用API: 'Enable API',
  '确认要启用OpenAPI吗？': 'Sure to enable OpenAPI?',
  '通过OpenAPI，您可以通过程序/脚本来控制花漾客户端与浏览器':
    'Through OpenAPI, you can control HuaYoung app and browser through programs/scripts',
  '确认要关闭OpenAPI吗？': 'Sure to close OpenAPI?',
  '一旦关闭OpenAPI，以往生成的AccessKey都将全部不可用，请确认是否要继续':
    'Once OpenAPI is closed, all previously generated AccessKeys will be unavailable. Pls confirm whether you want to continue.',
  未启用: 'Disabled',
  调用次数限制: 'Call Limit',
  已生成的Key: 'Generated Key',
  '确认要禁用此AccessKey吗？': 'Sure to disable this AccessKey?',
  '一旦禁用，使用此AccessKey的程序/脚本将无法正常执行':
    'Once disabled, programs/scripts using this AccessKey will not execute properly',
  '确认要启用此AccessKey吗？': 'Sure to enable this AccessKey?',
  '确认要删除此AccessKey吗？': 'Sure to delete this AccessKey?',
  'AccessKey一旦删除，使用此AccessKey的程序将无法正常执行':
    'Once the AccessKey is deleted, programs using this AccessKey will not be able to execute normally.',
  已绑IP: 'IP bound',
  关于功能屏蔽: 'About function blocking',
  '1.请选择一个分身，并确保该分身能够访问到您希望屏蔽的页面':
    '1. Pls select a avatar and make sure that the avatar can access the page you want to block.',
  '2.我们将打开该分身的管理界面，请自行访问您希望屏蔽的站点':
    '2. We will open the management interface of the avatar. Pls visit the site you want to block.',
  '3.打开的花漾灵动将安装有我们的屏蔽插件，请使用此插件选择您希望屏蔽的功能节点':
    '3. When you open HuaYoung, our shielding plug-in will be installed. Pls use this plug-in to select the function nodes you want to block.',
  '4.当关闭花漾灵动时，您设置的页面与屏蔽节点将会被自动存储':
    '4. When HuaYoung Smart is closed, the pages and shielded nodes you set will be automatically stored.',
  插件封装类型: 'Package Type',
  CRX文件: 'CRX File',
  ZIP文件: 'ZIP File',
  文件位置: 'File Location',
  请选择您要上传的文件: 'Pls select the file you want to upload',
  上传自有插件: 'Upload Your Plug-in',
  最少4个字符: 'Minimum 4 characters',
  不要超过64个字符: 'Do not exceed 64 characters',
  请输入插件名称: 'Pls enter the plugin name',
  插件属性: 'Plugin properties',
  更换安装包: 'Replace installation package',
  '确定要删除此插件吗？': 'Sure to delete this plugin?',
  '一旦删除意味着原本安装此插件的分身将会自动卸载，请确认是否要继续':
    'Once deleted, it means that the avatar that originally installed this plug-in will be automatically uninstalled. Pls confirm whether you want to continue.',
  已安装分身: 'Already installed',
  上传新的自有插件: 'Upload new own plugins',
  '如果您自有插件无法提交至Google市场，可上传至团队资源中，方便对分身进行批量安装插件':
    'If your own plug-ins cannot be submitted to the Google Market, you can upload them to team resources to facilitate batch installation of plug-ins',
  上传团队自有插件: 'Upload your own plug-in',
  功能屏蔽列表: 'Function Block List',
  其它设置: 'Other Settings',
  客户端语言: 'App Language',
  '花漾指纹浏览器界面使用的语言版本，默认为“跟随客户端设置”，但用户可自行设置，亦可由团队统一指定':
    'The default language version of the HuaYoung Browser interface is "Follow Client Settings", but users can set it by themselves, or the team can specify it uniformly.',
  '当开启Debug模式时，花漾灵动可通过按F12打开开发者工具DevTools，请注意，这可能会带来一定的安全隐患，请谨慎开启':
    'When debug mode is turned on, HuaYoung can open the developer tools DevTools by pressing F12. Pls note that this may bring certain security risks, so Pls open it with caution.',
  指纹实例内核升级: 'FP Kernel Upgrade',
  '我们强烈建议您，当花漾浏览器内核升级时，团队的浏览器指纹实例也应该同步升级。':
    "We strongly recommend that when the HuaYoung browser kernel is upgraded, the team's browser fingerprint instance should also be upgraded simultaneously.",
  '提醒用户：当发觉花漾浏览器内核升级后，会提醒用户并由用户决定是否升级浏览器指纹':
    'Remind users: When it is discovered that the kernel of HuaYoung Browser has been upgraded, the user will be reminded and the user will decide whether to upgrade the browser fingerprint.',
  '自动同步升级：当发觉花漾浏览器内核升级后，会自动对当前团队的所有浏览器指纹进行同步升级':
    'Automatic synchronization upgrade: When HuaYoung Browser kernel upgrade is detected, all browser fingerprints of the current team will be automatically upgraded simultaneously.',
  请在新打开的支付宝网站内完成认证: 'Pls complete the certification on the newly opened browser',
  手动打开支付宝网站完成验证: 'Manually Open the Browser',
  实名认证: 'Real Name Verification',
  '根据您的使用性质，可以开启个人实名认证或者企业实名认证':
    'Depending on the nature of your use, you can enable personal real-name authentication or corporate real-name authentication.',
  选择认证类型: 'Certification Type',
  '个人实名认证（支付宝扫码）': 'Personal Real-Name Authentication (Alipay Scan Code)',
  '提供您的身份信息并通过支付宝扫码完成身份确认，花漾仅能获取支付宝脱敏后返回的实名认证结果信息，您的实名信息仍将保留在支付宝':
    'Provide your identity information and scan the QR code through Alipay to complete the identity confirmation. HuaYoung can only obtain the real-name authentication result information returned by Alipay after desensitization. Your real-name information will still be retained in Alipay.',
  '个人实名认证（手机三要素）': 'Personal Real-Name Authentication (Mobile Phone)',
  '通过运营商实名认证过的手机号码及您的姓名、身份证号码来验证您的身份':
    "Verify your identity through the operator's real-name authenticated mobile number, your name and ID number",
  '企业实名认证（企业四要素）': 'Company Real-Name Authentication',
  通过提供企业的营业执照相关信息及法人身份信息完成验证:
    "Complete the verification by providing the company's business license-related information and legal person identity information",
  进行实名认证: 'Real-Name Authentication',
  姓名: 'Name',
  请输入您的姓名: 'Pls enter your name',
  请输入正确的姓名: 'Pls enter the correct name',
  身份证号码: 'ID No.',
  请输入您的身份证号码: 'Pls enter your identity number',
  请输入正确的身份证号码: 'Pls enter a valid ID number',
  '公司/组织名称': 'Company Name',
  '请输入公司/组织名称': 'Pls enter company/organization name',
  统一社会信用代码: 'Social Credit Code',
  请输入统一社会信用代码: 'Pls enter the unified social credit code',
  请输入正确的统一社会信用代码: 'Pls enter the correct unified social credit code',
  法定代表人: 'Legal Person',
  请输入法人姓名: 'Pls enter legal person name',
  法人身份证号码: 'Legal Person ID No.',
  请输入法人身份证号码: 'Pls enter your legal person ID number',
  实名认证失败: 'Real-name authentication failed',
  请检查您的输入是否正确: 'Pls check if your input is correct',
  团队信息: 'Information',
  '浏览器的个性化设置，可由团队成员自行决定，也可由团队强行指定（优先级高于团队成员的个人偏好设置）':
    'Personalized browser settings can be decided by team members themselves or forcibly specified by the team (which takes precedence over team members’ personal preferences)',
  全部使用: 'Use ',
  由团队成员自行决定: 'Decided by team member',
  全部禁用: 'Disable All',
  提醒用户: 'Remind',
  自动同步升级: 'Automatic Upgrade',
  '修改其它设置失败：': 'Failed to modify other settings:',
  请选择目标用户: 'Pls select target user',
  请选择新的目标用户: 'Pls select a new target user',
  '确信要将超级管理员转让给选中的成员吗？': 'Sure to transfer SuperAdmin to selected members?',
  '团队的超级管理员角色一旦转让则无法撤回，请您确认是否要继续':
    "Once the team's SuperAdmin role is transferred, it cannot be withdrawn. Pls confirm whether you want to continue.",
  团队转让成功: 'Team transfer successful',
  '将此团队转让给：': 'Transfer this team to:',
  依据昵称或手机号检索: 'Search by name/phone',
  '了解什么是转让团队？': 'About Team Transfer',
  '一个团队只有一个超级管理员，默认由团队创建者承担，他除了拥有Boss的全部权限外，还拥有以下特权：':
    'A team has only one SuperAdmin, who is assumed by the team creator by default. In addition to having all the permissions of the Boss, he also has the following privileges:',
  '1）可以解散团队；': '1) Team disbanded;',
  '2）可以修改团队信息；': '2) Team information modified;',
  '所谓转让团队，是指将团队的超级管理员的身份转让给他人，而原本的超级管理员将降格为团队的Boss角色。':
    "The transfer of a team refers to transferring the identity of the team's SuperAdmin to others, and the original SuperAdmin will be downgraded to the role of the team's Boss.",
  团队邀请设置: 'Team Invitation Settings',
  关闭后将无法在团队中加入新的成员: 'No one can join current team if toggered off',
  '复制邀请链接，通过微信、QQ等工具发送给好友，好友点击链接即可申请加入团队':
    'Copy the invitation link and send it to your friends via WeChat,QQ etc, they can apply to join this team by the link.',
  邀请链接复制成功: 'Invitation link copied successfully',
  '开启审核后，被邀请人申请加入团队后需在＂团队审核＂中进行审批；关闭审核后，被邀请人在输入正确的邀请链拉后直接以员工身份加入团队，可后续更改员工的角色与权限':
    'If toggered on, the invited person needs to be approved in "Team Audit" after applying to join the team. If toggered off, the invited person joins the team as an employee directly,and the roles and permissions can be chagned laterly.',
  '确定要退出团队吗？': 'Sure to leave the team?',
  退出团队后将无法访问团队内所有资源:
    'After you leave the team, you will not be able to access all resources in the team.',
  '退出团队成功！': 'Exiting the team successfully!',
  超级管理员无法退出团队: 'SuperAdmin cannot quit the team',
  '作为团队的超级管理员，无法退出团队，只能解散团队，或者将超级管理员身份转让给其它人员后再退出团队':
    'As a SuperAdmin of a team, you cannot quit the team. You can only disband the team, or transfer the SuperAdmin role to other people before quitting the team.',
  团队角色: 'Role',
  我在团队中的信息: 'My Information',
  不限制: 'not limited',
  伙伴全称: 'Full name of partner',
  分身配额: 'Avatar quota',
  用户配额: 'User quota',
  RPA设备限制: 'RPA device limitations',
  '当前团队是花漾OEM伙伴的签约团队，通过OEM伙伴您能够获得更丰富的服务与更优惠的价格':
    'The current team is the contract team of HuaYoung OEM partners. Through OEM partners, you can get richer services and more preferential prices.',
  '当前团队是花漾伙伴的签约团队，通过伙伴您能够获得更丰富的服务与更优惠的价格':
    'The current team is a contracted team of HuaYoung Partners. Through partners, you can get richer services and more favorable prices.',
  签约信息: 'Contract information',
  分身数量配额: 'Avatar number quota',
  '（已用': '(used',
  团队成员配额: 'Team member quota',
  购买日期: 'Purchase date',
  'TK达人管理系统是由云上悦动推出的针对TikTok店铺自动化智能管理的解决方案；本团队由TK达人管理系统创建，有分身数量最大配额与团队成员最大配额的限制，更进一步的信息请到TK达人管理系统中查询':
    'TikTok@花濑灵动 is a solution for TikTok store automation and intelligent management launched by HuaYoung. This team was created by TikTok@花濓动. There are restrictions on the maximum number of avatars and the maximum quota of team members. For further information, Pls Query in TK subsystem',
  '当前团队是花漾伙伴“': 'The current team is HuaYoung Partners',
  '”的签约团队': 'The signing team',
  当前团队是: 'The current team is',
  创建的团队: 'Team created',
  '确定要解散团队吗?': 'Sure to disband the team?',
  '解散团队会删除团队内所有资源，无法恢复':
    'Disbanding a team will delete all resources in the team and cannot be restored.',
  团队内的分身: 'Browser avatars within the team ',
  被清理: ' be cleared',
  团队内的指纹模板与实例: 'Fingerprint templates and instances within the team ',
  团队内的IP地址: 'IP address within the team ',
  '被删除/释放': ' deleted/released',
  团队内的云盘空间: 'Cloud drive storage',
  被完全清理: ' was completely cleaned',
  团队内的账户余额与花瓣余额已被清理:
    'The account balance and token balance within the team have been cleared',
  '如果团队内还有账户余额或花瓣余额，请与在线客服联络':
    'If there is still account balance or token balance in the team, pls contact online customer service',
  '即将为您解散团队，请再次确认': 'Your team will be disbanded, pls confirm',
  '确定（10s）': 'OK(10s)',
  '正在为您解散团队，请稍候...': 'The team is being disbanded for you, pls wait...',
  团队解散成功: 'The team was disbanded successfully',
  团队基本信息: 'Basic Information',
  设置团队形象: 'Set up team image',
  解散团队: 'Disband',
  身份证: 'ID card',
  当前团队已完成实名认证: 'The current team has completed real-name authentication',
  撤销认证: 'Revoke certification',
  '确定要撤销实名认证吗？': 'Sure to revoke the real-name authentication?',
  '没有实名认证的团队，无法执行某些敏感操作':
    'Teams without real-name authentication cannot perform certain sensitive operations.',
  实名认证已撤销: 'Real-name authentication has been revoked',
  当前团队尚未完成实名认证: 'The current team has not yet completed real-name authentication.',
  '当前团队尚未完成实名认证，只有完成实名认证后才能享受更多高级功能':
    'Only after completing the real-name authentication, you can enjoy more advanced functions.',
  成员数量: 'Members',
  IP数量: 'IPs',
  添加成功: 'Added successfully',
  请输入部门名称: 'Pls enter department name',
  调整顺序: 'Adjust',
  '部门只能在当前层级中调整排序，不可跨层级移动':
    'Departments can only be sorted in the current level and cannot be moved across levels.',
  更新成功: 'update completed',
  'BOSS是一个团队的业务管理员，默认天然拥有团队内所有业务的功能权限，以及分身、IP等资源的数据权限':
    'BOSS is the business administrator of a team. By default, he has functional permissions for all businesses in the team, as well as data permissions for resources such as browsers and IPs.',
  功能授权: 'Function authorization',
  授权更新成功: 'Authorization update successful',
  功能权限: 'Permissions',
  '您可向该成员额外授予以下权限：':
    'You can grant this member the following additional permissions:',
  全选: 'select all',
  '已选分身：': 'Selected:',
  分身授权: 'Avatar authorization',
  部门名称不能超过30个字符: 'Department name cannot exceed 30 characters',
  已授权分身: 'Avatars',
  凡隶属于该部门下的成员均间接获得上述分身的授权:
    'All members affiliated with this department are indirectly authorized by the above-mentioned browsers.',
  部门详情: 'Department details',
  分身交接: 'Avatar Handover',
  '正在为您进行分身交接，请稍候...': 'The avatar handover is in progress for you, pls wait...',
  分身交接成功: 'Avatar handover successful',
  解除所选分身与当前成员的授权关系:
    'Remove the authorization relationship between the selected avatar and the current member',
  '系统只能为您解除当前成员与所选分身的直接授权关系，而无法为您解除因为组织单元授权而带来的授权关系，请确认是否继续':
    'The system can only remove the direct authorization relationship between the current member and the selected avatar for you, but cannot remove the authorization relationship caused by department authorization. Pls confirm whether to continue.',
  交接成员: 'Handover To',
  请选择交接成员: 'Pls select the handover member',
  '请选择（已为您过滤超管与BOSS）': 'Pls select (SuperAdmin & BOSS have been filtered)',
  交接分身: 'Avatars',
  '了解什么是分身交接？': 'Do you know what a avatar handover is?',
  '分身交接是指将团队成员被直接授权的分身，全部授权给另一个指定的团队成员，并默认将该成员与所有被直接授权的分身解除授权关系。':
    'Avatar handover refers to authorizing all directly authorized avatars of a team member to another designated team member, and de-authorizing the member from all directly authorized avatars by default.',
  '分身交接通常用于员工离职的场景，被交接的员工如果已经和某些分身拥有授权关系，分身交接并不会影响这种已经存在的授权关系。':
    'Avatar handover is usually used in scenarios where employees leave. If the employee being handed over already has an authorization relationship with some avatar, the avatar handover will not affect this existing authorization relationship.',
  '分身所选分身与当前成员的授权关系时，只会解除分身与该成员之间的直接授权关系，并不会解除该成员因为所属组织单元而间接获得的授权关系':
    'When the avatar selects an authorization relationship between the avatar and the current member, it will only cancel the direct authorization relationship between the avatar and the member, but not the authorization relationship that the member indirectly obtained due to the department to which the avatar belongs.',
  邀请成员已关闭: 'Invite members is closed',
  '团队BOSS已关闭邀请成员设置，无法向团队中加入新的成员，如有需求请联系BOSS在“团队管理-团队设置”中开启“邀请成员”设置':
    'The team BOSS has turned off the invitation member setting, and new members cannot be added to the team.',
  '1、团队成员加入审核': '1. Team Member Join Approval is ',
  '，被邀请人点击邀请链接后，仍需团队BOSS审批通过后才可加入团队，BOSS可在“团队管理-团队设置”中决定是否开启“加入审核”，以及变更或停用“邀请链接”；':
    ', after the invitee clicks the link, he still needs to be approved by the team BOSS before he can join the team. ',
  '，被邀请人点击邀请链接后无需审核即可直接加入团队，BOSS可在“团队管理-团队设置”中决定是否开启“加入审核”，以及变更或停用“邀请链接”；':
    ', the invitee can join the team directly without approval after clicking the invitation link. BOSS can decide whether to turn on "Join Approval", and change or deactivate the "invitation link";',
  '复制以下邀请链接，通过微信、QQ等分享给好友，好友点击链接即可申请加入团队':
    'Copy the following invitation link and share it with friends, he/she can click the link to apply to join the team',
  邀请成员说明: 'Instructions for inviting members',
  '2、当有好友申请加入团队时，BOSS可在“团队管理-成员审核”中对申请请求进行审批':
    '2. When a friend applies to join the team, BOSS can approve the application request in "Team-Audit"',
  邀请成员: 'Invite',
  请选择部门: 'Pls select a dept',
  请输入昵称: 'Pls enter a nickname',
  '该成员的角色为“超级管理员”，超级管理员拥有团队内所有功能权限，具体请详请查看{{action}}':
    'The role of this member is "SuperAdmin". The SuperAdmin has all functional permissions in the team. Pls check {{action}} for details.',
  '该成员的角色为“BOSS”，除超级管理员所拥有的几项特殊权限外，BOSS拥有团队内所有功能权限，具体请详请查看{{action}}':
    'The role of this member is "BOSS". In addition to several special permissions owned by SuperAdmins, BOSS has all functional permissions in the team. Pls check {{action}} for details.',
  分身直接授权: 'Direct Auth',
  此处只显示用户通过分身直接授权而获得分身列表:
    'Only display the avatars obtained through direct authorization.',
  成员详情: 'Member details',
  部门授权间接获得: 'Depart Auth',
  此处只显示用户通过分身直接授权而获得的分身列表:
    'Only the list of avatars obtained by the user through direct authorization from avatars is displayed here.',
  '确定要删除部门“{{name}}”吗?': 'Sure you want to delete the department "{{name}}"?',
  删除部门操作要求该部门不能拥有成员和下级部门:
    'Deleting a department requires that the department cannot have members and subordinate departments',
  '删除成功，即将刷新': 'Deleted successfully, will be refreshed soon',
  部门管理: 'Department Mgmt',
  一级部门无法删除: 'First-level departments cannot be deleted',
  '一级部门可以更改其名称，但无法删除':
    'A first-level department can change its name but cannot delete it',
  展开全部: 'Expand All',
  收起全部: 'Collapse all',
  成员数量已经达到团队最大配额: "The number of members has reached the team's maximum quota",
  当前团队只允许拥有: 'The current team is only allowed to have',
  '个成员，无法邀请新的成员，请联络官网客服咨询进一步信息':
    'members, cannot invite new members, Pls contact the official website customer service for further information',
  输入成员关键字进行检索: 'Search by name/phone',
  '确定要启用此团队成员吗？': 'Sure to enable this team member?',
  启用团队成员意味着该用户可以重新进入团队:
    'Enabling a team member means the user can re-enter the team',
  成员启用成功: 'Member activated successfully',
  '你无法禁用自己？': "You can't disable yourself?",
  '您无法禁用自己，如果您希望退出团队，请在团队列表中点击”退出团队“':
    'You cannot disable yourself, if you wish to leave the team, Pls click "Exit Team" in the team list',
  '确定要禁用此团队成员吗？': 'Sure to disable this team member?',
  '禁用团队成员意味着该团队成员将继续保留在团队中，但他无法再次进入团队，自然无法对团队的资源进行任何操作，直至您重新启用该成员，或者将其直接踢出团队':
    "Disabling a team member means that the team member will continue to remain in the team, but he will not be able to enter the team again, and naturally will not be able to do anything with the team's resources until you re-enable the member, or kick him out of the team directly",
  成员禁用成功: 'Member disabled successfully',
  你无法踢出自己: "you can't kick yourself out",
  '如果希望退出团队，请在团队设置中点击“退出团队”':
    'If you wish to leave the team, Pls click "Leave Team" in the team settings',
  '确定要踢出此成员吗?': 'Sure to kick this member out?',
  '该成员一旦被踢出，就无法再次进入本团队，也无法访问本团队的任何资源，除非您再次邀请他进入本团队':
    'Once the member is kicked out, he will not be able to re-enter the team or access any resources of the team unless you invite him to the team again.',
  踢出成员成功: 'Kicked out member successfully',
  踢出: 'Kick out',
  '当前部门：': 'Current Dept: ',
  '人，下级部门：': ', Subordinate Dept:',
  全部成员: 'All Members',
  轨迹检索: 'Search',
  检索结果: 'Search Results',
  轨迹开始: 'Trajectory Starts',
  轨迹结束: 'Trajectory End',
  轨迹个数: 'Tracks',
  '分身轨迹-': 'Doppelg?nger trajectory-',
  'IP轨迹-': 'IP trace-',
  加载更多旧的轨迹: 'More Old Tracks',
  网络类型设置: 'Network Settings',
  '花漾客户端访问门户的网络类型（不影响任何浏览器分身）':
    'HuaYoung client app network type for server access (No impact on other browser avatars)',
  不使用代理: 'Direct Connection',
  HTTP代理: 'HTTP Proxy',
  SOCKS5代理: 'Socks5 Proxy',
  使用浏览器设置: 'Use System Browser Settings',
  服务器地址: 'Server',
  当前客户端版本过旧: 'The current app version is too old',
  '请下载最新安装程序，重新安装': 'Pls download the latest app and reinstall',
  升级过程发生错误: 'An error occurred during the upgrade process',
  请手动下载客户端进行安装: 'Pls download and install the app manually',
  客户端升级完毕: 'Upgrade completed',
  '当前版本过旧，正在为您升级...':
    'The current version is too old and is being upgraded for you...',
  '恭喜，已为您升级到最新版本': 'Congratulations, you have been upgraded to the latest version',
  '当前客户端版本过旧，正在为您进行升级，这可能需要您几分钟时间，请稍候':
    'The current version is out of date and is being upgraded for you. This may take you a few minutes. Pls wait.',
  '如升级过慢，请下载最新安装程序，重新安装即可':
    'If the upgrade is too slow, pls download the latest installation program and reinstall it.',
  重启客户端以完成升级工作: 'Restart the client to complete the upgrade',
  '版本不匹配，请重新下载适用于Win10的客户端版本':
    'Version mismatch, pls re-download the client version for Win10',
  '版本不匹配，请重新下载适用于Win7的客户端版本':
    'Version mismatch, pls re-download the client version for Win7',
  注册新用户: 'Register',
  邀请码: 'Invitation Code',
  用户昵称: "User's Nickname",
  '系统检测到此微信号并没有绑定任何账号，请输入您的昵称，系统将为您默认创建一个新用户':
    'This WeChat account is not bound to any account. Pls enter your nickname and the system will create a new user for you.',
  '密码重置成功，请重新登录': 'Password reset successful, pls log in again',
  重置密码: 'Reset Password',
  返回: 'Return',
  请验证您的手机号码或邮箱后重置您的密码: 'Verify your phone or email to reset your password',
  请输入手机号或邮箱: 'Pls enter your mobile number or email',
  请输入您的新密码: 'New password',
  请输入重复密码: 'Repeat password',
  '手机号码既是您的登录账号，请为您的登录账号设置一个密码；登录系统后，可绑定微信，下次可通过微信扫码登录':
    'Your mobile number is your login account, Pls set a password; after logging in to the system, you can bind it to WeChat, and you can scan the QR code to login through WeChat next time',
  您的信息: 'Your Message',
  请阅读并同意协议: 'Pls read and agree to the agreement',
  阅读并接受: 'Read & Accept',
  您需要下载花漾客户端才能够获得花漾灵动的所有功能:
    'Pls download the HuaYoung app to get all the functions',
  您的昵称: 'Your Name',
  等待团队审核: 'Waiting for Aduit',
  '您的团队加入申请已提交，正在等待团队审核，审核通过后，会以短信形式通知到您刚才注册使用的手机':
    'Your application to join the team has been submitted and is waiting for aduit. Once approved, you will be notified via message to the mobile you just registered.',
  您的申请被拒绝: 'Your application was rejected',
  '请您联络邀请您加入团队的好友，向其咨询进一步信息':
    'Pls contact the friend who invited you to join the team for further information',
  申请已再次提交: 'Application has been submitted again',
  重新申请: 'Re-apply',
  进入我的团队: 'Enter Team',
  您已经在当前团队中了: 'You are already in the current team',
  '您无需重复加入同一个团队，请检查您的输入是否正确，如有疑问请联系在线客服':
    'You do not need to join the same team again. Pls check whether your input is correct. Any questions, pls contact online support.',
  团队加入邀请: 'Team Invitation',
  您的好友邀请您加入到Ta的团队中一起协作:
    'Your friend invites you to join his team and collaborate together',
  与本客户端相同的设备ID已经在线: 'The device ID is the same as this client is already online',
  '理论上任何一个客户端只会拥有自己唯一的设备ID，如果相同的设备ID已经在线，可能是存在两台相同设备ID的客户端或者上一个会话暂未失效，您可以选择踢对方下线，也可以自行退出':
    'Any client will only have its own unique device ID. If the same device ID is already online, there may be two clients with the same device ID or the previous session has not expired. You can choose to kick the other party offline.',
  '设备ID：': 'Device ID:',
  'IP地址：': 'IP Address:',
  踢对方下线: 'Kick Off',
  验证码错误: 'Verification Code Error',
  网络设置: 'Network',
  '没有账号，': 'No Account,',
  立即注册: 'Sign Up',
  登录成功: 'Login Successful',
  未注册过的手机号将直接完成用户注册: 'Unregistered mobile numbers auto-register',
  密码登录: 'Login with Password',
  验证码登录: 'Verification Code',
  账户或密码错误: 'Wrong account or password',
  请输入您的手机号码或邮箱: 'Pls enter your mobile number or email',
  请输入您的登录密码: 'Pls enter your password',
  请输入您的手机号码: 'Pls enter your mobile number',
  '请使用微信扫一扫完成注册/登录': 'Use WeChat to scan to complete registration/login',
  '7天内自动登录': 'Auto Login for 7 Days',
  '忘记密码？': 'Forget Password?',
  关于邀请码: 'About Invitation Code',
  '为保证广大卖家的安全运营，花漾灵动采取邀请注册制，必须获得邀请码才能成功注册，请向您的推荐人咨询进一步信息，或者通过右侧的微信公众号、400电话获取邀请码':
    'In order to ensure the safe operation of the majority of sellers, HuaYoung adopts an invitation registration system. You must obtain an invitation code to successfully register. Pls consult your recommender for further information, or obtain the invitation code through the WeChat official account or 400 on the right',
  微信公众号: 'WeChat Public Account',
  '请扫码关注我们的公众号，': 'Pls scan the QR code to follow our official account.',
  '点击菜单“邀请码”即可获取': 'Click the menu "Invitation Code" to get it',
  '请拨打我们的热线电话，': 'Pls call our hotline,',
  通过人工客服即可获取邀请码: 'You can get the invitation code through manual customer service',
  关于邀请码的使用说明: 'Instructions for Invitation Code',
  '如果您是通过我们的合作伙伴介绍而来，强烈建议您使用您的介绍人给予的邀请码完成注册，这会给您和您的介绍人带来后续相关的商务便利；':
    'If you are introduced through our partners, it is strongly recommended that you use the invitation code given by your introducer to complete the registration, which will bring subsequent business convenience to you and your introducer.',
  '如果您并不知晓相关信息，现阶段可使用花漾热线电话':
    'If you don’t know the relevant information, you can use HuaYoung hotline ',
  作为邀请码: '  as invitation code',
  设置登录密码: 'Setting Password',
  '手机号码/邮箱即是您的登录账号，建议您设置一个登录密码':
    'Your mobile number/email is your login account. It is recommended that you set a password.',
  尾号: 'Mobile',
  的用户: '',
  设置密码: 'Set Password',
  请输入登录密码: 'Pls enter your password',
  请输入邀请码: 'Pls enter the invitation code',
  '请输入邀请码（': 'Pls enter the invitation code (',
  必填: 'Required',
  可选: 'Optional',
  注册失败: 'Registration Failed',
  注册账号: 'Register',
  请输入邀请码并验证您的手机号码或邮箱后完成注册:
    'Pls enter the invitation code and verify your mobile number or email to complete the registration',
  请输入手机号码或邮箱: 'Pls enter your mobile number or email',
  '已有账号，直接登录': 'Existing Account, Log in',
  没有已加入的团队: 'No Joined Teams',
  您在当前团队被禁用: 'You are banned from this team',
  '请联络当前团队管理员，以获取进一步的信息':
    'Pls contact your team administrator for further infor',
  进入: 'Enter',
  '您已加入以下团队，请选择一个团队进入：': 'Your Teams:',
  '正在访问分身【': 'Visiting browser[',
  访问者已关闭分身会话: 'The visitor has closed the browser session',
  '确定要强制中断对该分身的访问吗？': 'Sure to forcefully terminate access to this browser?',
  '一旦强制中断此分身的访问，意味着该用户打开的花漾浏览器会被强制退出':
    'Once the session is interrupted, it means that the HuaYoung browser opened by the user will be forced to exit.',
  进入实时监管失败: 'Failed to real-time supervision',
  关闭监管窗口: 'Close Regulatory Window',
  '正在加载视频信息...': 'Loading video information...',
  强制中断分身访问: 'Forced interruption of session',
  踢出登录: 'Kick Out',
  关闭此页面: 'Close this page',
  此次登录会话已结束: 'This session has ended',
  '如果您觉得此次登录会话非您本人或存在其它风险，请点击“踢出登录”以结束此次登录会话':
    'If you feel that this login session is not yours or there are other risks, Pls click "Kick Out" to end this session',
  登录会话不存在或已结束: 'The login session does not exist or has ended',
  您已经从花漾客户端中退出: 'You have exited from HuaYoung app',
  您当前正在登录花漾客户端: 'You are currently logging into HuaYoung app',
  账号昵称: 'Nickname',
  欢迎使用: 'Welcome',
  当前账号: 'Current Account',
  无法查阅这条消息: 'Unable to view this message',
  已复制: 'Copied',
  建设中: 'Under Construction',
  清理策略: 'Cleanup Strategy',
  '超过指定天数的审计录像或流程任务文件将被清理（日志保留），个别被设置为“锁定”的录像文件或任务文件将被保留':
    'Audit recordings or RPA task files that exceed the specified number of days will be cleared (log retention), and individual recording files or task files that are set to "Locked" will be retained.',
  会话访问审计录像: 'Audit Video',
  请设置清理天数: 'Pls set the cleaning days',
  天以前的录像文件: 'days ago videos',
  RPA相关文件: 'RPA Filess',
  天以前的任务文件: 'days ago RPA files',
  '您的“个人云盘”所占据的存储空间': 'The storage space occupied by your "Personal Cloud Drive',
  其他个人云盘: 'Others Drive',
  '团队其它成员的“个人云盘”所占据的存储空间':
    'The storage space occupied by the "personal cloud drive" of other members',
  '“团队云盘”所占据的存储空间': 'The storage space occupied by "Team Cloud Drive',
  '当对分身开启“安全策略”且进行审计录像时，产生的录像文件所占据的存储空间，可通过下方的清理策略进行清理':
    'When the "security policy" is turned on for the avatar and audit recording is performed, the storage space occupied by the generated recording files can be cleaned up through the cleanup policy below.',
  '执行RPA自动化流程时产生的各种文件（主要是流程节点的截图文件）所占据的存储空间，可通过下方的清理策略进行清理':
    'The storage space occupied by various files generated when executing RPA automation processes (mainly screenshot files of process nodes) can be cleaned through the cleaning strategy below.',
  分身同步数据: 'Browser Data',
  '分身云端同步策略中上传至云端的缓存数据所占据的存储空间，可通过分身属性“云端同步策略”来决定同步哪些数据从而减小占据的存储空间':
    'The storage space occupied by the cached data uploaded to the cloud in the avatar\'s cloud synchronization policy can be determined by the avatar\'s attribute "cloud synchronization policy" to reduce the storage space occupied.',
  不隶属以上项目的其它数据: 'Other data not affiliated with the above items',
  RPA任务: 'RPA tasks',
  分身数据: 'Avatar data',
  前十大文件: 'Top 10 Files',
  大小: 'Size',
  存储空间分布: 'Storage Distribution',
  '会话访问录像是指当浏览器分身开启安全策略后，在打开分身所对应的花漾浏览器时，会按照用户要求产生审计录像，由此会占据云盘存储空间':
    "Session access recording means that when the browser avatar turns on the security policy and opens the HuaYoung browser corresponding to the avatar, audit recording will be generated according to the user's requirements, which will occupy the cloud drive storage space.",
  天以前的文件: ' days order files',
  'RPA相关文件主要指当RPA流程任务执行时，出于审计目的会进行截图，由此会占据云盘存储空间':
    'RPA related files mainly refer to screenshots taken for audit purposes when RPA process tasks are executed, which will occupy cloud drive storage space.',
  关于存储空间与消耗的花瓣: 'About storage space and token consumption',
  '系统会赠送您一定的免费存储空间，超过的配额将每日扣除一定数量的花瓣，您可以通过清理策略以确保存储空间的大小':
    'The system will give you a certain amount of free storage space. If the quota is exceeded, a certain number of tokens will be deducted every day.',
  免费存储配额: 'Free Quota',
  存储空间单价: 'Unit Price',
  '存储空间单价：': 'Unit Price:',
  '免费存储配额：': 'Free  Quota:',
  存储空间总计: 'Total Storage',
  新建文件夹: 'New Folder',
  正在创建文件夹: 'Creating folder',
  文件夹创建成功: 'Folder created successfully',
  名称不能为空: 'Name is required',
  名称不能超过60个字符: 'Name cannot exceed 60 characters',
  '文件（夹）名称已存在': 'File (folder) name already exists',
  输入名称: 'Enter name',
  关于分身数据的文件清理: 'About Cleaning',
  '1.每个浏览器分身在关闭浏览器窗口时，会把本地的最新缓存数据上传到云端并占据云盘的存储空间':
    '1. When each browser closes the window, it will upload the latest local cache data to the cloud and occupy the storage space of the cloud drive.',
  '2.一个浏览器分身的缓存数据主要包括Cookie、LocalStorage、IndexedDB等类目':
    '2. The cache data of a browser mainly includes Cookie, LocalStorage, IndexedDB and other categories.',
  '3.通过“分身清理”与“云端同步策略”，可有效减小分身缓存数据所占据的云盘存储空间':
    '3. Through "Avatar Cleanup" and "Cloud Synchronization Strategy", the cloud drive storage space occupied by avatar cache data can be effectively reduced.',
  如何清理: 'How to clean',
  该文件类型不支持预览: 'This file type does not support preview',
  '该文件类型不支持预览，请您下载后再自行查看':
    'This file type does not support preview, Pls download and then view it yourself',
  '文件体积大于20M无法预览，请您下载后再自行查看':
    'Files larger than 20M cannot be previewed. Pls download and view them yourself.',
  文件详情: 'Document details',
  定位: 'Locator',
  '选择文件（夹）': 'Select file (folder)',
  空间分布: 'All',
  该路径下无文件夹: 'There is no folder in this path',
  取消收藏: 'Cancel favorites',
  收藏: 'Favorite',
  取消收藏成功: 'Cancel favorite success',
  '确定删除所选的文件吗？': 'Sure to delete the selected files?',
  '删除后无法恢复，请谨慎操作': 'Deletion cannot be restored, Pls confirm with caution',
  文件删除成功: 'File deleted success',
  云盘字段自定义: 'Cloud Storage Field Customization',
  上传目录: 'Upload Directory',
  上传: 'Upload',
  文件复制中: 'File Copying',
  请耐心等候: 'Pls wait with patience',
  移动: 'Move',
  文件移动中: 'File Moving',
  '正在删除文件（1%）': 'Deleting files (1%)',
  '正在删除文件（': 'Deleting files (',
  依据文件名称检索: 'Search by file name',
  '在“团队云盘”中存储的文件，团队中拥有”可查看并管理团队云盘“权限的成员都可以查看并管理':
    'Files stored in "Team Cloud Drive" can be viewed and managed by members of the team who have the "Can View and Manage Team Cloud Drive" permission.',
  '在“我的云盘”中存储的文件，只有您有权限，团队任何其它成员无权访问':
    "The files stored in 'My Drive' can only be accessed by you, and no other team members have access rights.",
  花漾中的云盘是花漾为您提供的一个非常简单的网络文件存储机制:
    'The cloud drive in HuaYoung is a very simple network file storage mechanism.',
  '花漾云盘主要是为花漾自身的业务需求而服务，如：当您开启访问分身的审计录像时，生成的录像文件会保存在花漾云盘中；再如，当您的RPA流程需要跨不同设备读取/存储文件时，也可以通过花漾云盘来实现':
    "HuaYoung Cloud Drive primarily serves HuaYoung's own business needs. For example, when you enable access to the audit recordings of browsers, the generated video files are saved in HuaYoung Cloud Drive. Similarly, when your RPA processes need to read or store files across different devices, you can also achieve this through HuaYoung Cloud Drive.",
  '未检索到任何文件（夹）': 'No files (folders) retrieved',
  '个文件夹，': 'folders,',
  '个文件，已选中': 'files, selected',
  '个文件（夹）': 'files (folders)',
  收藏时间: 'Collect Time',
  '未收藏任何文件（夹）': 'No favorite files (folders)',
  文件内无文件可下载: 'There are no files in the file to download.',
  您要下载的文件包含文件夹: 'The file you want to download contains the folder',
  '请下载花漾客户端并通过花漾客户端完成文件的上传和下载，这样会带来更好的传输速率与操作体验':
    'Pls download the HuaYoung app and complete the upload and download of files through the HuaYoung app, which will bring better transmission speed and operating experience.',
  '文件体积过大，不支持预览': 'The file size is too large and preview is not supported',
  不支持预览的文件类型: 'File types not supported for preview',
  '当前路径存在同名文件，您希望：':
    'A file with the same name exists in the current path. You want to:',
  自动替换: 'automatic replacement',
  自动更名: 'Automatic name change',
  后续一样处理: 'Follow up with the same treatment',
  用户取消上传: 'User cancels upload',
  文件上传成功: 'File uploaded successfully',
  '正在上传文件...': 'Uploading files...',
  隐藏至后台执行: 'Hide to Background',
  '正在上传文件（': 'Uploading file (',
  个文件: 'files',
  '客户端版本不支持该功能，请升级客户端':
    'The client version does not support this function, pls upgrade',
  检测超时: 'Detection timeout',
  '花漾客户端没有启动/安装': 'HuaYoung app is not started/installed',
  '花漾客户端没有启动（或没有安装）': 'HuaYoung app does not start (or is not installed)',
  '花漾客户端没有启动或者没有安装，如果您已经正确安装，请点击“打开花漾客户端”，否则，请下载并重新安装花漾客户端':
    'HuaYoung app does not start or is not installed. If you have installed it correctly, Pls click "Open HuaYoung app". Otherwise, Pls download and reinstall HuaYoung app.',
  打开花漾客户端: 'Open HuaYoung app',
  '正在唤醒花漾客户端，请稍候...': 'Waking up HuaYoung app, pls wait...',
  '系统正在为您唤醒花漾客户端使其按照您的指令行事，唤醒成功后此对话框会自动消失':
    'The system is waking up the HuaYoung app for you so that it can act according to your instructions. This dialog box will disappear automatically after the awakening is successful.',
  花漾客户端当前登录者的身份不一致:
    'The identity of the current login user in HuaYoung app is inconsistent.',
  '花漾客户端当前登录者的身份与您的身份不一致，如果确信要切换登录者身份，请点击“重新登录”，请注意，此举会中断旧有登录者当前的工作':
    'The identity of the current login user in HuaYoung app is inconsistent with yours. If you are sure you want to switch login identities, Pls click "Re-login". Pls note that this will interrupt the current work of the old login user.',
  重新登录: 're-register',
  未能解析teamId: 'Unable to parse teamId',
  分: 'min',
  毫秒: 'millisecond',
  应该是由纯数字组成: 'should be composed of pure numbers',
  请输入正确的IPv4地址: 'Pls enter the correct IPv4 address',
  请输入正确的手机号: 'Pls enter a valid mobile number',
  请输入您的邮箱: 'Pls enter your email',
  请输入正确的邮箱: 'Pls enter your vaild email',
  '不能包含？或*': "Can't include? or*",
  请输入内容: 'Pls enter content',
  长度不能大于128个字符: 'Length cannot be greater than 128 characters',
  请输入站点: 'Pls enter site',
  请输入正确的站点: 'Pls enter the correct site',
  请输入您的: 'Pls enter your',
  昵称: 'Nick name',
  '（不小于3个字符）': '(not less than 3 characters)',
  请输入正确的端口号: 'Pls enter the correct port',
  您输入的变量不合法: 'The variable you entered is illegal',
  请输入正确的IPv6地址: 'Pls enter the correct IPv6 address',
  请输入正确的IP地址: 'Pls enter the correct IP address',
  请输入正确的手机号或邮箱: 'Pls enter the correct mobile number or email address',
  请输入文件名: 'Pls enter file name',
  文件名包含非法字符: 'File name contains illegal characters',
  文件名非法: 'File name is illegal',
  目前拥有的RPA流程将被删除: 'The RPA processes you currently have will be deleted',
  团队将恢复至每天根据浏览器分身与用户配额扣除花瓣的收费模式:
    'The team will return to the charging model of deducting tokens every day based on browser browsers and user quotas.',
  '解除TK套餐后，您可以继续使用浏览器分身与IP隔离功能，正常运营您的TK店铺；请勾选并明确以上动作后，点击确定按钮':
    'After canceling the TK package, you can continue to use the browser and IP isolation functions to operate your TK store normally; Pls check and confirm the above actions, and click the OK button',
  '正在为您解除TK套餐，请稍候...': 'The TK package is being canceled for you, pls wait...',
  团队套餐已过期: 'Team package has expired',
  '您可以到TK子系统中续费，也可以解除TK套餐，只使用花漾指纹分身浏览器等功能':
    'You can renew in the TK subsystem, or cancel the TK package and only use the HuaYoung fingerprint browser and other functions',
  进入TK子系统: 'Enter the TK subsystem',
  刷新状态: 'Refresh status',
  '（用户名和密码可以为空）': '(username & pwd can be empty)',
  '输入的文本内容存在错误的语法，是否继续？':
    'The entered text contains incorrect syntax. Do you want to continue?',
  '输入的文本内容存在错误的语法，您可以忽略错误继续导入，也可以重新检查输入的内容':
    'The entered text contains incorrect syntax. You can ignore the error and continue importing, or you can recheck the entered content',
  检查输入内容: 'Check the input content',
  会更新表格内所有IP的连接方式: 'Will update the connection method of all IPs in the table',
  连接协议: 'Proxy Protocol',
  会更新表格内所有IP的连接协议: 'Will update the protocol of all IPs in the table',
  未选择代理协议: 'No connection protocol selected',
  '设置IP的连接方式与加速策略，请注意，这将影响到IP服务商白名单的设置':
    'Set the connection method and acceleration strategy of the IP. Please note that this will affect the setting of the IP service provider whitelist',
  邮箱登录: 'Email',
  手机登录: 'Mobile',
  第三方账号登录: 'Third-party Account Login',
  '分身会被解除IP绑定关系并移动到回收站中，7天后会被彻底删除，在此期间内可随时恢复':
    'The browser will be unbound from the IP and moved to the recycle bin. It will be completely deleted after 7 days. It can be restored at any time during this period',
  彻底删除: 'Delete permanently',
  '确定要批量删除选定的分身吗？':
    'Are you sure you want to delete the selected Browsers in batches?',
  清空回收站: 'Empty Recycle Bin',
  删除时间: 'Delete Time',
  清理时间: 'Cleaning Time',
  分身回收站: 'Recycle Bin',
  '回收站中的分身不再消耗花瓣，但如要恢复则需补齐自删除之日起消耗的花瓣；回收站中的分身只会保留7天，到期后自动清理':
    'Avatars in Recycle Bin no longer consume tokens, but to restore, tokens consumed since deletion date must be replenished; avatars in Recycle Bin are kept for 7 days, then auto-cleared.',
  恢复分身: 'Restore',
  批量恢复: 'Batch Restore',
  '确定要清空回收站吗？': 'Are you sure you want to empty the recycle bin?',
  '回收站里的分身将被彻底删除，无法恢复':
    'The Browsers in the recycle bin will be completely deleted and cannot be restored',
  '确定要彻底删除选定的分身吗？':
    'Are you sure you want to permanently delete the selected browsers?',
  '分身一旦被彻底删除，无法恢复': 'Once the browser is permanently deleted, it cannot be restored',
  需补充花瓣: 'Need Tokens',
  '需要扣除{{total}}花瓣用来恢复分身，确定吗？':
    'Need to deduct {{total}} tokens to restore the Browser, are you sure?',
  恢复分身需要补齐从删除之日起消耗的花瓣:
    'Restoring the Browser requires making up the tokens consumed from the day of deletion',
  回收站清理策略: 'Cleaning Strategy',
  '回收站中的分身可随时恢复（但无法恢复原有的IP绑定关系），恢复分身时需要补齐该分身自删除之日起至今消耗的花瓣；回收站中的分身只会保留7天，到期后会被自动清理':
    'The Browsers in the recycle bin can be restored (except the IP binding relationship), and the tokens consumed during the period will be made up when restored; the browsers in the recycle bin will only be kept for 7 days',
  '勾选此选项会同步删除绑定在此分身上的指纹实例（无痕分身则忽略此选项）；同步删除指纹实例是为了避免指纹实例无意中被重复使用':
    'Checking this option will also delete the fp instance bound to this avatar (stateless avatar ignores this option); synchronously deleting the fp instance is to avoid the fp instance being reused inadvertently',
  清理分身时自动删除分身所绑定的指纹实例:
    'Auto delete the fingerprint instance bound to the browser avatars',
  '使用{{oauthType}}账号登录': 'Login with {{oauthType}} account',
  '系统会打开本机默认浏览器访问{{oauthType}}网站，请您在打开的浏览器中完成{{oauthType}}账号的登录':
    'The system will open the default browser of the local machine to access the {{oauthType}} website. Please complete the {{oauthType}} account login in the opened browser',
  验证码快捷登录: 'Quick Login via SMS/Email Code',
  花瓣余额不够: 'Insufficient token balance',
  '恢复分身需要补齐从删除之日起消耗的花瓣，当前花瓣余额不够，请到费用中心购买花瓣':
    'Restoring the Browser requires making up the tokens consumed from the day of deletion. The current token balance is insufficient. Please go to the cost center to purchase tokens',
  '该分身已删除，请先恢复': 'The Browser has been deleted, please restore first',
  '（忽略系统代理始终使用本机IP或VPN出口IP直连）':
    '(Ignore system proxy always use local IP or VPN export IP direct connection)',
  IP尚未导入: 'IP Not Imported',
  '如果您的IP尚未导入，请先导入至花漾中':
    'If your IP has not been imported, please import it to HuaYoung first',
  '选中的分身已绑定IP地址，要继续吗？':
    'The selected Browser is bound to the IP address, do you want to continue?',
  '选中的分身中存在已经绑定IP地址的情况，如果继续将更换为当前IP，请确认是否继续':
    'There are already bound IP addresses in the selected Browser. If you continue, it will be changed to the current IP. Please confirm whether to continue',
  '设置IP的连接协议，目前支持 Http、Socks5、SSH等协议':
    'Set the connection protocol of the IP, currently supports Http, Socks5, SSH',
  中文: 'Chinese',
  '中文(简体，新加坡)': 'Chinese (Simplified, Singapore)',
  '中文(简体)': 'Chinese (Simplified)',
  '中文(新加坡)': 'Chinese (Singapore)',
  '中文(繁体，中国香港特别行政区)': 'Chinese (Traditional, Hong Kong SAR, China)',
  '中文(台湾)': 'Chinese(Taiwan)',
  '中文(繁体)': 'traditional Chinese)',
  '中文(简体，中国香港特别行政区)': 'Chinese (Simplified, Hong Kong SAR, China)',
  '中文(中国)': 'Chinese (China)',
  '中文(简体，中国澳门特别行政区)': 'Chinese (Simplified, Macau SAR, China)',
  '中文(繁体，中国澳门特别行政区)': 'Chinese (Traditional, Macau SAR, China)',
  '中文(简体，中国)': 'Chinese (Simplified, China)',
  '中文(繁体，台湾)': 'Chinese (Traditional, Taiwan)',
  '中文(中国香港特别行政区)': 'Chinese (Hong Kong SAR, China)',
  '英语(纽埃)': 'English(Niue)',
  '英语(蒙特塞拉特)': 'English (Montserrat)',
  '英语(根西岛)': 'English(Guernsey)',
  '英语(牙买加)': 'English(Jamaican)',
  '英语(赞比亚)': 'English (Zambia)',
  '英语(马耳他)': 'English(Malta)',
  '英语(利比里亚)': 'English(Liberia)',
  '英语(加纳)': 'English(Ghana)',
  '英语(以色列)': 'English(Israel)',
  '英语(帕劳)': 'English(Palau)',
  '英语(圣文森特和格林纳丁斯)': 'English (Saint Vincent and the Grenadines)',
  '英语(美国，电脑)': 'English (US, computer)',
  '英语(欧洲)': 'English (Europe)',
  '英语(圣基茨和尼维斯)': 'English (Saint Kitts and Nevis)',
  '英语(中国澳门特别行政区)': 'English(Macau SAR, China)',
  '英语(伯利兹)': 'English(Belize)',
  '英语(瑙鲁)': 'English(Nauru)',
  '英语(北马里亚纳群岛)': 'English(Northern Mariana Islands)',
  '英语(格林纳达)': 'English(Grenada)',
  '英语(博茨瓦纳)': 'English (Botswana)',
  '英语(澳大利亚)': 'English (Australia)',
  '英语(塞浦路斯)': 'English (Cyprus)',
  '英语(卢旺达)': 'English(Rwanda)',
  '英语(爱尔兰)': 'English (Ireland)',
  '英语(基里巴斯)': 'English(Kiribati)',
  '英语(斯威士兰)': 'English(Swaziland)',
  '英语(英国)': 'English(UK)',
  '英语(美属萨摩亚)': 'English (American Samoa)',
  '英语(泽西岛)': 'English (Jersey)',
  '英语(圣诞岛)': 'English(Christmas Island)',
  '英语(奥地利)': 'English(Austria)',
  '英语(荷属圣马丁)': 'English (St. Maarten)',
  '英语(坦桑尼亚)': 'English(Tanzania)',
  '英语(波多黎各)': 'English (Puerto Rico)',
  '英语(巴哈马)': 'English(Bahamas)',
  '英语(肯尼亚)': 'English(Kenya)',
  '英语(荷兰)': 'English (Dutch)',
  '英语(南苏丹)': 'English(South Sudan)',
  '英语(马达加斯加)': 'English(Madagascar)',
  '英语(南非)': 'English (South Africa)',
  '英语(图瓦卢)': 'English(Tuvalu)',
  '英语(皮特凯恩群岛)': 'English (Pitcairn Islands)',
  '英语(马绍尔群岛)': 'English (Marshall Islands)',
  '英语(圭亚那)': 'English (Guyana)',
  '英语(尼日利亚)': 'English(Nigeria)',
  '英语(巴基斯坦)': 'English (Pakistan)',
  '英语(圣卢西亚)': 'English (Saint Lucia)',
  '英语(特立尼达和多巴哥)': 'English (Trinidad and Tobago)',
  '英语(百慕大)': 'English(Bermuda)',
  '英语(瓦努阿图)': 'English(Vanuatu)',
  '英语(美国)': 'English (United States)',
  '英语(诺福克岛)': 'English (Norfolk Island)',
  '英语(关岛)': 'English(Guam)',
  '英语(安圭拉)': 'English (Anguilla)',
  '英语(喀麦隆)': 'English (Cameroon)',
  '英语(汤加)': 'English(Tongan)',
  '英语(巴布亚新几内亚)': 'English(Papua New Guinea)',
  '英语(厄立特里亚)': 'English(Eritrea)',
  '英语(菲律宾)': 'English (Philippines)',
  '英语(多米尼克)': 'English(Dominica)',
  '英语(库克群岛)': 'English (Cook Islands)',
  '英语(布隆迪)': 'English(Burundi)',
  '英语(安提瓜和巴布达)': 'English(Antigua and Barbuda)',
  '英语(萨摩亚)': 'English (Samoa)',
  '英语(纳米比亚)': 'English(Namibia)',
  '英语(塞拉利昂)': 'English(Sierra Leone)',
  '英语(圣赫勒拿)': 'English (Saint Helena)',
  '英语(开曼群岛)': 'English (Cayman Islands)',
  '英语(丹麦)': 'English(Denmark)',
  '英语(津巴布韦)': 'English (Zimbabwe)',
  '英语(美国本土外小岛屿)': 'English(US Minor Outlying Islands)',
  '英语(托克劳)': 'English(Tokelau)',
  '英语(斯洛文尼亚)': 'English (Slovenia)',
  '英语(密克罗尼西亚)': 'English(Micronesia)',
  '英语(比利时)': 'English(Belgium)',
  '英语(新加坡)': 'English(Singapore)',
  '英语(瑞士)': 'English (Switzerland)',
  '英语(苏丹)': 'English(Sudan)',
  '英语(马来西亚)': 'English(Malaysia)',
  '英语(福克兰群岛)': 'English (Falkland Islands)',
  '英语(冈比亚)': 'English(Gambia)',
  '英语(迪戈加西亚岛)': 'English(Digo Garcia)',
  '英语(瑞典)': 'English (Sweden)',
  '英语(世界)': 'English (World)',
  英语: 'English',
  '英语(所罗门群岛)': 'English (Solomon Islands)',
  '英语(马拉维)': 'English (Malawi)',
  '英语(英属印度洋领地)': 'English(British Indian Ocean Territory)',
  '英语(德国)': 'English (Germany)',
  '英语(科科斯（基林）群岛)': 'English(Cocos (Keeling) Islands)',
  '英语(芬兰)': 'English (Finland)',
  '英语(塞舌尔)': 'English (Seychelles)',
  '英语(美属维尔京群岛)': 'English (U.S. Virgin Islands)',
  '英语(乌干达)': 'English(Uganda)',
  '英语(新西兰)': 'English (New Zealand)',
  '英语(斐济)': 'English(Fiji)',
  '英语(巴巴多斯)': 'English (Barbados)',
  '英语(毛里求斯)': 'English (Mauritius)',
  '英语(马恩岛)': 'English(Isle of Man)',
  '英语(莱索托)': 'English(Lesotho)',
  '英语(中国香港特别行政区)': 'English (Hong Kong SAR, China)',
  '英语(直布罗陀)': 'English (Gibraltar)',
  '英语(加拿大)': 'English (Canada)',
  '英语(英属维尔京群岛)': 'English (British Virgin Islands)',
  '英语(特克斯和凯科斯群岛)': 'English (Turks and Caicos Islands)',
  '英语(印度)': 'English(India)',
  南非荷兰语: 'Afrikaans',
  '南非荷兰语(纳米比亚)': 'Afrikaans (Namibia)',
  '南非荷兰语(南非)': 'Afrikaans (South Africa)',
  亚罕语: 'Ahani language',
  '亚罕语(喀麦隆)': 'Ahani (Cameroon)',
  阿肯语: 'Akan',
  '阿肯语(加纳)': 'Akan (Ghana)',
  阿姆哈拉语: 'Amharic',
  '阿姆哈拉语(埃塞俄比亚)': 'Amharic (Ethiopia)',
  阿拉伯语: 'Arabic',
  '阿拉伯语(世界)': 'Arabic (World)',
  '阿拉伯语(阿拉伯联合酋长国)': 'Arabic (United Arab Emirates)',
  '阿拉伯语(巴林)': 'Arabic (Bahrain)',
  '阿拉伯语(吉布提)': 'Arabic (Djibouti)',
  '阿拉伯语(阿尔及利亚)': 'Arabic (Algeria)',
  '阿拉伯语(埃及)': 'Arabic (Egypt)',
  '阿拉伯语(西撒哈拉)': 'Arabic (Western Sahara)',
  '阿拉伯语(厄立特里亚)': 'Arabic (Eritrean)',
  '阿拉伯语(以色列)': 'Arabic (Israel)',
  '阿拉伯语(伊拉克)': 'Arabic (Iraq)',
  '阿拉伯语(约旦)': 'Arabic (Jordan)',
  '阿拉伯语(科摩罗)': 'Arabic (Comoros)',
  '阿拉伯语(科威特)': 'Arabic (Kuwait)',
  '阿拉伯语(黎巴嫩)': 'Arabic (Lebanon)',
  '阿拉伯语(利比亚)': 'Arabic (Libya)',
  '阿拉伯语(摩洛哥)': 'Arabic (Morocco)',
  '阿拉伯语(毛里塔尼亚)': 'Arabic (Mauritania)',
  '阿拉伯语(阿曼)': 'Arabic (Oman)',
  '阿拉伯语(巴勒斯坦领土)': 'Arabic (Palestinian Territories)',
  '阿拉伯语(卡塔尔)': 'Arabic (Qatar)',
  '阿拉伯语(沙特阿拉伯)': 'Arabic (Saudi Arabia)',
  '阿拉伯语(苏丹)': 'Arabic(Sudan)',
  '阿拉伯语(索马里)': 'Arabic (Somali)',
  '阿拉伯语(南苏丹)': 'Arabic (South Sudan)',
  '阿拉伯语(叙利亚)': 'Arabic (Syria)',
  '阿拉伯语(乍得)': 'Arabic (Chad)',
  '阿拉伯语(突尼斯)': 'Arabic (Tunisia)',
  '阿拉伯语(也门)': 'Arabic (Yemen)',
  阿萨姆语: 'Assamese',
  '阿萨姆语(印度)': 'Assamese (India)',
  帕雷语: 'Parei',
  '帕雷语(坦桑尼亚)': 'Pare (Tanzania)',
  阿斯图里亚斯语: 'Asturian',
  '阿斯图里亚斯语(西班牙)': 'Asturian (Spain)',
  阿塞拜疆语: 'Azerbaijani',
  '阿塞拜疆语(西里尔文)': 'Azerbaijani (Cyrillic)',
  '阿塞拜疆语(西里尔文，阿塞拜疆)': 'Azerbaijani (Cyrillic, Azerbaijan)',
  '阿塞拜疆语(拉丁文)': 'Azerbaijani (Latin)',
  '阿塞拜疆语(拉丁文，阿塞拜疆)': 'Azerbaijani (Latin, Azerbaijan)',
  巴萨语: 'Bassa',
  '巴萨语(喀麦隆)': 'Bassa (Cameroon)',
  白俄罗斯语: 'Belarusian',
  '白俄罗斯语(白俄罗斯)': 'Belarusian (Belarus)',
  本巴语: 'Bemba',
  '本巴语(赞比亚)': 'Bemba (Zambia)',
  贝纳语: 'Bena',
  '贝纳语(坦桑尼亚)': 'Bena (Tanzania)',
  保加利亚语: 'Bulgarian',
  '保加利亚语(保加利亚)': 'Bulgarian (Bulgaria)',
  班巴拉语: 'Bambara',
  '班巴拉语(马里)': 'Bambara (Mali)',
  孟加拉语: 'Bengali',
  '孟加拉语(孟加拉国)': 'Bengali(Bangladesh)',
  '孟加拉语(印度)': 'Bengali (India)',
  藏语: 'Tibetan language',
  '藏语(中国)': 'Tibetan (China)',
  '藏语(印度)': 'Tibetan (India)',
  布列塔尼语: 'Breton',
  '布列塔尼语(法国)': 'Breton (France)',
  博多语: 'Bodo',
  '博多语(印度)': 'Bodo (India)',
  波斯尼亚语: 'Bosnian',
  '波斯尼亚语(西里尔文)': 'Bosnian (Cyrillic)',
  '波斯尼亚语(西里尔文，波斯尼亚和黑塞哥维那)': 'Bosnian (Cyrillic, Bosnia and Herzegovina)',
  '波斯尼亚语(拉丁文)': 'Bosnian (Latin)',
  '波斯尼亚语(拉丁文，波斯尼亚和黑塞哥维那)': 'Bosnian (Latin, Bosnia and Herzegovina)',
  加泰罗尼亚语: 'Catalan',
  '加泰罗尼亚语(安道尔)': 'Catalan (Andorra)',
  '加泰罗尼亚语(西班牙)': 'Catalan (Spain)',
  '加泰罗尼亚语(西班牙，瓦伦西亚文)': 'Catalan (Spain, Valencian)',
  '加泰罗尼亚语(法国)': 'Catalan (France)',
  '加泰罗尼亚语(意大利)': 'Catalan (Italy)',
  'ccp(孟加拉国)': 'ccp(Bangladesh)',
  'ccp(印度)': 'ccp(India)',
  车臣语: 'Chechen',
  '车臣语(俄罗斯)': 'Chechen (Russia)',
  奇加语: 'Chiga',
  '奇加语(乌干达)': 'Chiga (Uganda)',
  切罗基语: 'Cherokee',
  '切罗基语(美国)': 'Cherokee (USA)',
  中库尔德语: 'middle kurdish',
  '中库尔德语(伊拉克)': 'Central Kurdish (Iraq)',
  '中库尔德语(伊朗)': 'Central Kurdish (Iran)',
  捷克语: 'Czech',
  '捷克语(捷克)': 'Czech(Czech)',
  教会斯拉夫语: 'Church Slavonic',
  '教会斯拉夫语(俄罗斯)': 'Church Cyrillic (Russian)',
  威尔士语: 'Welsh',
  '威尔士语(英国)': 'Welsh (UK)',
  丹麦语: 'danish',
  '丹麦语(丹麦)': 'Danish (Denmark)',
  '丹麦语(格陵兰)': 'Danish (Greenland)',
  台塔语: 'Taita',
  '台塔语(肯尼亚)': 'Taita (Kenya)',
  德语: 'German',
  '德语(奥地利)': 'German(Austria)',
  '德语(比利时)': 'German (Belgium)',
  '德语(瑞士)': 'German (Switzerland)',
  '德语(德国)': 'German(Germany)',
  '德语(意大利)': 'German(Italy)',
  '德语(列支敦士登)': 'German (Liechtenstein)',
  '德语(卢森堡)': 'German (Luxembourg)',
  哲尔马语: 'Zelma',
  '哲尔马语(尼日尔)': 'Jerma (Niger)',
  下索布语: 'Lower Sorbian',
  '下索布语(德国)': 'Lower Sorbian (Germany)',
  都阿拉语: 'Duara',
  '都阿拉语(喀麦隆)': 'Duara (Cameroon)',
  朱拉语: 'Chola',
  '朱拉语(塞内加尔)': 'Jura (Senegal)',
  宗卡语: 'Dzongkha language',
  '宗卡语(不丹)': 'Dzongkha (Bhutan)',
  恩布语: 'Embu language',
  '恩布语(肯尼亚)': 'Embu (Kenya)',
  埃维语: 'Ewe',
  '埃维语(加纳)': 'Ewe (Ghana)',
  '埃维语(多哥)': 'Ewe (Togo)',
  希腊语: 'Greek',
  '希腊语(塞浦路斯)': 'Greek (Cyprus)',
  '希腊语(希腊)': 'Greek (Greece)',
  世界语: 'Esperanto',
  '世界语(世界)': 'Esperanto (World)',
  西班牙语: 'spanish',
  '西班牙语(拉丁美洲)': 'Spanish (Latin America)',
  '西班牙语(阿根廷)': 'Spanish (Argentina)',
  '西班牙语(玻利维亚)': 'Spanish (Bolivia)',
  '西班牙语(巴西)': 'Spanish (Brazil)',
  '西班牙语(伯利兹)': 'Spanish (Belize)',
  '西班牙语(智利)': 'Spanish (Chile)',
  '西班牙语(哥伦比亚)': 'Spanish (Colombia)',
  '西班牙语(哥斯达黎加)': 'Spanish (Costa Rica)',
  '西班牙语(古巴)': 'Spanish (Cuba)',
  '西班牙语(多米尼加共和国)': 'Spanish (Dominican Republic)',
  '西班牙语(休达及梅利利亚)': 'Spanish (Ceuta and Melilla)',
  '西班牙语(厄瓜多尔)': 'Spanish (Ecuador)',
  '西班牙语(西班牙)': 'Spanish(Spain)',
  '西班牙语(赤道几内亚)': 'Spanish(Equatorial Guinea)',
  '西班牙语(危地马拉)': 'Spanish (Guatemala)',
  '西班牙语(洪都拉斯)': 'Spanish (Honduras)',
  '西班牙语(加纳利群岛)': 'Spanish (Canary Islands)',
  '西班牙语(墨西哥)': 'Spanish (Mexico)',
  '西班牙语(尼加拉瓜)': 'Spanish(Nicaragua)',
  '西班牙语(巴拿马)': 'Spanish (Panama)',
  '西班牙语(秘鲁)': 'Spanish (Peru)',
  '西班牙语(菲律宾)': 'Spanish (Philippines)',
  '西班牙语(波多黎各)': 'Spanish (Puerto Rico)',
  '西班牙语(巴拉圭)': 'Spanish (Paraguay)',
  '西班牙语(萨尔瓦多)': 'Spanish (El Salvador)',
  '西班牙语(美国)': 'Spanish (United States)',
  '西班牙语(乌拉圭)': 'Spanish (Uruguay)',
  '西班牙语(委内瑞拉)': 'Spanish (Venezuela)',
  爱沙尼亚语: 'Estonian',
  '爱沙尼亚语(爱沙尼亚)': 'Estonian (Estonia)',
  巴斯克语: 'basque',
  '巴斯克语(西班牙)': 'Basque (Spain)',
  旺杜语: 'Wangdu',
  '旺杜语(喀麦隆)': 'Wangdu (Cameroon)',
  波斯语: 'Persian',
  '波斯语(阿富汗)': 'Persian (Afghanistan)',
  '波斯语(伊朗)': 'Persian (Iran)',
  富拉语: 'Fula',
  '富拉语(喀麦隆)': 'Fula (Cameroon)',
  '富拉语(几内亚)': 'Fula (Guinea)',
  '富拉语(毛里塔尼亚)': 'Fula (Mauritania)',
  '富拉语(塞内加尔)': 'Fula (Senegal)',
  芬兰语: 'Finnish',
  '芬兰语(芬兰)': 'Finnish (Finland)',
  菲律宾语: 'Filipino',
  '菲律宾语(菲律宾)': 'Tagalog (Philippines)',
  法罗语: 'faroese',
  '法罗语(丹麦)': 'Faroese (Denmark)',
  '法罗语(法罗群岛)': 'Faroese (Faroe Islands)',
  法语: 'French',
  '法语(比利时)': 'French(Belgium)',
  '法语(布基纳法索)': 'French (Burkina Faso)',
  '法语(布隆迪)': 'French(Burundi)',
  '法语(贝宁)': 'French(Benin)',
  '法语(圣巴泰勒米)': 'French (Saint Barthélemy)',
  '法语(加拿大)': 'French (Canada)',
  '法语(刚果（金）)': 'French(DRC)',
  '法语(中非共和国)': 'French (Central African Republic)',
  '法语(刚果（布）)': 'French (Congo (Brazzaville))',
  '法语(瑞士)': 'French (Switzerland)',
  '法语(科特迪瓦)': "French(C?te d'Ivoire)",
  '法语(喀麦隆)': 'French (Cameroon)',
  '法语(吉布提)': 'French (Djibouti)',
  '法语(阿尔及利亚)': 'French (Algeria)',
  '法语(法国)': 'French(France)',
  '法语(加蓬)': 'French (Gabon)',
  '法语(法属圭亚那)': 'French (French Guiana)',
  '法语(几内亚)': 'French (Guinea)',
  '法语(瓜德罗普)': 'French (Guadeloupe)',
  '法语(赤道几内亚)': 'French(Equatorial Guinea)',
  '法语(海地)': 'French (Haiti)',
  '法语(科摩罗)': 'French (Comoros)',
  '法语(卢森堡)': 'French (Luxembourg)',
  '法语(摩洛哥)': 'French (Morocco)',
  '法语(摩纳哥)': 'French(Monaco)',
  '法语(法属圣马丁)': 'French (French Saint Martin)',
  '法语(马达加斯加)': 'French(Madagascar)',
  '法语(马里)': 'French (Mali)',
  '法语(马提尼克)': 'French (Martinique)',
  '法语(毛里塔尼亚)': 'French (Mauritania)',
  '法语(毛里求斯)': 'French (Mauritius)',
  '法语(新喀里多尼亚)': 'French (New Caledonia)',
  '法语(尼日尔)': 'French (Niger)',
  '法语(法属波利尼西亚)': 'French (French Polynesia)',
  '法语(圣皮埃尔和密克隆群岛)': 'French (Saint Pierre and Miquelon)',
  '法语(留尼汪)': 'French (Reunion)',
  '法语(卢旺达)': 'French (Rwanda)',
  '法语(塞舌尔)': 'French (Seychelles)',
  '法语(塞内加尔)': 'French(Senegal)',
  '法语(叙利亚)': 'French (Syria)',
  '法语(乍得)': 'French (Chad)',
  '法语(多哥)': 'French(Togo)',
  '法语(突尼斯)': 'French (Tunisia)',
  '法语(瓦努阿图)': 'French(Vanuatu)',
  '法语(瓦利斯和富图纳)': 'French (Wallis and Futuna)',
  '法语(马约特)': 'French (Mayotte)',
  弗留利语: 'Friulian',
  '弗留利语(意大利)': 'Friulian (Italy)',
  西弗里西亚语: 'West Frisian',
  '西弗里西亚语(荷兰)': 'West Frisian (Dutch)',
  爱尔兰语: 'irish',
  '爱尔兰语(爱尔兰)': 'Irish (Ireland)',
  苏格兰盖尔语: 'Scottish Gaelic',
  '苏格兰盖尔语(英国)': 'Scottish Gaelic (UK)',
  加利西亚语: 'Galician',
  '加利西亚语(西班牙)': 'Galician (Spain)',
  瑞士德语: 'Swiss German',
  '瑞士德语(瑞士)': 'Swiss German (Switzerland)',
  '瑞士德语(法国)': 'Swiss German (France)',
  '瑞士德语(列支敦士登)': 'Swiss German (Liechtenstein)',
  古吉拉特语: 'gujarati',
  '古吉拉特语(印度)': 'Gujarati (India)',
  古西语: 'Old Spanish',
  '古西语(肯尼亚)': 'Old Spanish (Kenya)',
  马恩语: 'Mandarin',
  '马恩语(马恩岛)': 'Manx (Isle of Man)',
  豪萨语: 'Hausa',
  '豪萨语(加纳)': 'Hausa (Ghana)',
  '豪萨语(尼日尔)': 'Hausa (Niger)',
  '豪萨语(尼日利亚)': 'Hausa (Nigeria)',
  夏威夷语: 'Hawaiian',
  '夏威夷语(美国)': 'Hawaiian (United States)',
  希伯来文: 'Hebrew',
  '希伯来文(以色列)': 'Hebrew (Israel)',
  印地语: 'Hindi',
  '印地语(印度)': 'Hindi (India)',
  克罗地亚语: 'Croatian',
  '克罗地亚语(波斯尼亚和黑塞哥维那)': 'Croatian (Bosnia and Herzegovina)',
  '克罗地亚语(克罗地亚)': 'Croatian (Croatian)',
  上索布语: 'Upper Sorbian',
  '上索布语(德国)': 'Upper Sorbian (Germany)',
  匈牙利语: 'hungarian',
  '匈牙利语(匈牙利)': 'Hungarian (Hungary)',
  亚美尼亚语: 'Armenian',
  '亚美尼亚语(亚美尼亚)': 'Armenian (Armenian)',
  印度尼西亚文: 'Indonesian',
  '印度尼西亚文(印度尼西亚)': 'Indonesian (Indonesia)',
  伊博语: 'Igbo',
  '伊博语(尼日利亚)': 'Igbo (Nigeria)',
  四川彝语: 'Sichuan Yi',
  '四川彝语(中国)': 'Sichuan Yi (China)',
  冰岛语: 'Icelandic',
  '冰岛语(冰岛)': 'Icelandic (Iceland)',
  意大利语: 'Italian',
  '意大利语(瑞士)': 'Italian (Switzerland)',
  '意大利语(意大利)': 'Italian (Italy)',
  '意大利语(圣马力诺)': 'Italian (San Marino)',
  '意大利语(梵蒂冈)': 'Italian (Vatican)',
  日语: 'Japanese',
  '日语(日本)': 'Japanese (Japan)',
  '日语(日本，JP，和历)': 'Japanese (Japanese, JP, Japanese calendar)',
  恩艮巴语: 'Ngamba',
  '恩艮巴语(喀麦隆)': 'Ngemba (Cameroon)',
  马切姆语: 'Machame',
  '马切姆语(坦桑尼亚)': 'Machame (Tanzania)',
  格鲁吉亚语: 'Georgian',
  '格鲁吉亚语(格鲁吉亚)': 'Georgian (Georgia)',
  卡拜尔语: 'Kabyle',
  '卡拜尔语(阿尔及利亚)': 'Kabyle (Algeria)',
  卡姆巴语: 'Kamba',
  '卡姆巴语(肯尼亚)': 'Kamba (Kenya)',
  马孔德语: 'Makonde',
  '马孔德语(坦桑尼亚)': 'Makonde (Tanzania)',
  卡布佛得鲁语: 'kabofodru',
  '卡布佛得鲁语(佛得角)': 'Cabo Verdean (Cape Verde)',
  西桑海语: 'Xisanghai language',
  '西桑海语(马里)': 'Western Songhai (Mali)',
  吉库尤语: 'Kikuyu',
  '吉库尤语(肯尼亚)': 'Kikuyu (Kenya)',
  哈萨克语: 'Kazakh',
  '哈萨克语(哈萨克斯坦)': 'Kazakh (Kazakhstan)',
  卡库语: 'Kaku',
  '卡库语(喀麦隆)': 'Kaku (Cameroon)',
  格陵兰语: 'Greenlandic',
  '格陵兰语(格陵兰)': 'Greenlandic (Greenland)',
  卡伦金语: 'Kalenjin',
  '卡伦金语(肯尼亚)': 'Kalenjin (Kenya)',
  高棉语: 'Cambodian',
  '高棉语(柬埔寨)': 'Khmer (Cambodia)',
  卡纳达语: 'kannada',
  '卡纳达语(印度)': 'Kannada (India)',
  '韩语(朝鲜)': 'Korean(North Korea)',
  '韩语(韩国)': 'Korean (South Korea)',
  孔卡尼语: 'Konkani',
  '孔卡尼语(印度)': 'Konkani (India)',
  克什米尔语: 'Kashmiri',
  '克什米尔语(印度)': 'Kashmiri (India)',
  香巴拉语: 'Shambhala',
  '香巴拉语(坦桑尼亚)': 'Shambhala (Tanzania)',
  巴菲亚语: 'Bafian',
  '巴菲亚语(喀麦隆)': 'Bafiya (Cameroon)',
  科隆语: 'Cologne',
  '科隆语(德国)': 'Cologne (Germany)',
  康沃尔语: 'Cornish',
  '康沃尔语(英国)': 'Cornish (UK)',
  柯尔克孜语: 'Kirgiz',
  '柯尔克孜语(吉尔吉斯斯坦)': 'Kirghiz (Kyrgyzstan)',
  朗吉语: 'Langi',
  '朗吉语(坦桑尼亚)': 'Langi (Tanzania)',
  卢森堡语: 'Luxembourgish',
  '卢森堡语(卢森堡)': 'Luxembourgish (Luxembourg)',
  卢干达语: 'Luganda',
  '卢干达语(乌干达)': 'Luganda (Uganda)',
  拉科塔语: 'Lakota',
  '拉科塔语(美国)': 'Lakota (USA)',
  林加拉语: 'Lingala',
  '林加拉语(安哥拉)': 'Lingala (Angola)',
  '林加拉语(刚果（金）)': 'Lingala (DRC)',
  '林加拉语(中非共和国)': 'Lingala (Central African Republic)',
  '林加拉语(刚果（布）)': 'Lingala (Congo (Brazzaville))',
  老挝语: 'Lao',
  '老挝语(老挝)': 'Lao (Laos)',
  北卢尔语: 'North Luhr',
  '北卢尔语(伊拉克)': 'Northern Lur (Iraq)',
  '北卢尔语(伊朗)': 'Northern Lur (Iran)',
  立陶宛语: 'Lithuanian',
  '立陶宛语(立陶宛)': 'Lithuanian (Lithuania)',
  鲁巴加丹加语: 'Rubagatanga',
  '鲁巴加丹加语(刚果（金）)': 'Luba-Katanga (DRC)',
  卢奥语: 'Luo',
  '卢奥语(肯尼亚)': 'Luo (Kenya)',
  卢雅语: 'Luya',
  '卢雅语(肯尼亚)': 'Luya (Kenya)',
  拉脱维亚语: 'Latvian',
  '拉脱维亚语(拉脱维亚)': 'Latvian (Latvian)',
  马赛语: 'Maasai',
  '马赛语(肯尼亚)': 'Maasai (Kenya)',
  '马赛语(坦桑尼亚)': 'Maasai (Tanzania)',
  梅鲁语: 'Meru',
  '梅鲁语(肯尼亚)': 'Meru (Kenya)',
  毛里求斯克里奥尔语: 'Mauritian Creole',
  '毛里求斯克里奥尔语(毛里求斯)': 'Mauritian Creole (Mauritius)',
  马拉加斯语: 'Malagasy',
  '马拉加斯语(马达加斯加)': 'Malagasy (Madagascar)',
  马库阿语: 'Makua',
  '马库阿语(莫桑比克)': 'Makua (Mozambique)',
  梅塔语: 'mehta',
  '梅塔语(喀麦隆)': 'Meta (Cameroon)',
  马其顿语: 'Macedonian',
  '马其顿语(马其顿)': 'Macedonian (Macedonian)',
  马拉雅拉姆语: 'Malayalam',
  '马拉雅拉姆语(印度)': 'Malayalam (India)',
  蒙古语: 'Mongolian',
  '蒙古语(蒙古)': 'Mongolian (Mongolia)',
  马拉地语: 'marathi',
  '马拉地语(印度)': 'Marathi (India)',
  马来语: 'Malay',
  '马来语(文莱)': 'Malay(Brunei)',
  '马来语(马来西亚)': 'Malay (Malaysia)',
  '马来语(新加坡)': 'Malay(Singapore)',
  马耳他语: 'maltese',
  '马耳他语(马耳他)': 'Maltese (Malta)',
  蒙当语: 'Mundang',
  '蒙当语(喀麦隆)': 'Mundang (Cameroon)',
  缅甸语: 'Burmese',
  '缅甸语(缅甸)': 'Burmese (Myanmar)',
  马赞德兰语: 'Mazandalan',
  '马赞德兰语(伊朗)': 'Mazandaran (Iran)',
  纳马语: 'Nama language',
  '纳马语(纳米比亚)': 'Nama (Namibia)',
  书面挪威语: 'written norwegian',
  '书面挪威语(挪威)': 'Written Norwegian (Norway)',
  '书面挪威语(斯瓦尔巴和扬马延)': 'Written Norwegian (Svalbard and Jan Mayen)',
  北恩德贝勒语: 'Northern Ndebele',
  '北恩德贝勒语(津巴布韦)': 'Northern Ndebele (Zimbabwe)',
  低地德语: 'low german',
  '低地德语(德国)': 'Low German (Germany)',
  '低地德语(荷兰)': 'Low German (Netherlands)',
  尼泊尔语: 'Nepali',
  '尼泊尔语(印度)': 'Nepali (India)',
  '尼泊尔语(尼泊尔)': 'Nepali (Nepal)',
  荷兰语: 'Dutch',
  '荷兰语(阿鲁巴)': 'Dutch(Aruba)',
  '荷兰语(比利时)': 'Dutch(Belgium)',
  '荷兰语(荷属加勒比区)': 'Dutch (Dutch Caribbean)',
  '荷兰语(库拉索)': 'Dutch (Cura?ao)',
  '荷兰语(荷兰)': 'Dutch (Netherlands)',
  '荷兰语(苏里南)': 'Dutch(Suriname)',
  '荷兰语(荷属圣马丁)': 'Dutch (St. Maarten)',
  夸西奥语: 'Quasio',
  '夸西奥语(喀麦隆)': 'Kwasio (Cameroon)',
  挪威尼诺斯克语: 'Norwegian Nynorsk',
  '挪威语(挪威，Nynorsk)': 'Norwegian (Norway, Nynorsk)',
  恩甘澎语: 'Ngan Peng language',
  '恩甘澎语(喀麦隆)': 'Ngan Peng (Cameroon)',
  挪威语: 'norwegian',
  '挪威语(挪威)': 'Norwegian(Norway)',
  努埃尔语: 'Nuer',
  '努埃尔语(南苏丹)': 'Nuer (South Sudan)',
  尼昂科勒语: 'Nyangkole',
  '尼昂科勒语(乌干达)': 'Nyangkole (Uganda)',
  奥罗莫语: 'Oromo',
  '奥罗莫语(埃塞俄比亚)': 'Oromo (Ethiopia)',
  '奥罗莫语(肯尼亚)': 'Oromo (Kenya)',
  奥里亚语: 'Oriya',
  '奥里亚语(印度)': 'Oriya (India)',
  奥塞梯语: 'Ossetian',
  '奥塞梯语(格鲁吉亚)': 'Ossetian (Georgia)',
  '奥塞梯语(俄罗斯)': 'Ossetian (Russia)',
  旁遮普语: 'punjabi',
  '旁遮普语(阿拉伯文)': 'Punjabi (Arabic)',
  '旁遮普语(阿拉伯文，巴基斯坦)': 'Punjabi (Arabic, Pakistan)',
  '旁遮普语(果鲁穆奇文)': 'Punjabi (Gorumchi)',
  '旁遮普语(果鲁穆奇文，印度)': 'Punjabi (Gorumchi, India)',
  波兰语: 'Polish',
  '波兰语(波兰)': 'Polish (Poland)',
  普鲁士语: 'Prussian',
  '普鲁士语(世界)': 'Prussian (world)',
  普什图语: 'Pashto',
  '普什图语(阿富汗)': 'Pashto (Afghanistan)',
  葡萄牙语: 'Portuguese',
  '葡萄牙语(安哥拉)': 'Portuguese (Angola)',
  '葡萄牙语(巴西)': 'Portuguese (Brazil)',
  '葡萄牙语(瑞士)': 'Portuguese (Switzerland)',
  '葡萄牙语(佛得角)': 'Portuguese (Cape Verde)',
  '葡萄牙语(赤道几内亚)': 'Portuguese (Equatorial Guinea)',
  '葡萄牙语(几内亚比绍)': 'Portuguese(Guinea-Bissau)',
  '葡萄牙语(卢森堡)': 'Portuguese (Luxembourg)',
  '葡萄牙语(中国澳门特别行政区)': 'Portuguese (Macau SAR, China)',
  '葡萄牙语(莫桑比克)': 'Portuguese (Mozambique)',
  '葡萄牙语(葡萄牙)': 'Portuguese(Portugal)',
  '葡萄牙语(圣多美和普林西比)': 'Portuguese (Sao Tome and Principe)',
  '葡萄牙语(东帝汶)': 'Portuguese (East Timor)',
  克丘亚语: 'Quechua',
  '克丘亚语(玻利维亚)': 'Quechua (Bolivia)',
  '克丘亚语(厄瓜多尔)': 'Quechua (Ecuador)',
  '克丘亚语(秘鲁)': 'Quechua (Peru)',
  罗曼什语: 'Romansh',
  '罗曼什语(瑞士)': 'Romansh (Switzerland)',
  隆迪语: 'Lundi',
  '隆迪语(布隆迪)': 'Lundi (Burundi)',
  罗马尼亚语: 'Romanian',
  '罗马尼亚语(摩尔多瓦)': 'Romanian (Moldova)',
  '罗马尼亚语(罗马尼亚)': 'Romanian (Romania)',
  兰博语: 'Rambo',
  '兰博语(坦桑尼亚)': 'Rambo (Tanzania)',
  俄语: 'Russian',
  '俄语(白俄罗斯)': 'Russian(Belarus)',
  '俄语(吉尔吉斯斯坦)': 'Russian (Kyrgyzstan)',
  '俄语(哈萨克斯坦)': 'Russian (Kazakhstan)',
  '俄语(摩尔多瓦)': 'Russian (Moldova)',
  '俄语(俄罗斯)': 'Russian (Russia)',
  '俄语(乌克兰)': 'Russian(Ukrainian)',
  卢旺达语: 'Kinyarwanda',
  '卢旺达语(卢旺达)': 'Kinyarwanda (Rwanda)',
  罗瓦语: 'Rowa',
  '罗瓦语(坦桑尼亚)': 'Rawa (Tanzania)',
  萨哈语: 'Sakha',
  '萨哈语(俄罗斯)': 'Sakha (Russian)',
  桑布鲁语: 'Samburu',
  '桑布鲁语(肯尼亚)': 'Samburu (Kenya)',
  桑古语: 'Sanggu language',
  '桑古语(坦桑尼亚)': 'Sangu (Tanzania)',
  信德语: 'sindhi',
  '信德语(巴基斯坦)': 'Sindhi (Pakistan)',
  北方萨米语: 'Northern Sami',
  '北方萨米语(芬兰)': 'Northern Sami (Finland)',
  '北方萨米语(挪威)': 'Northern Sami (Norway)',
  '北方萨米语(瑞典)': 'Northern Sami (Sweden)',
  塞纳语: 'Sena',
  '塞纳语(莫桑比克)': 'Sena (Mozambique)',
  东桑海语: 'Eastern Songhai language',
  '东桑海语(马里)': 'Eastern Songhai (Mali)',
  桑戈语: 'Sango',
  '桑戈语(中非共和国)': 'Sango (Central African Republic)',
  希尔哈语: 'Shirkha',
  '希尔哈语(拉丁文)': 'Hilda (Latin)',
  '希尔哈语(拉丁文，摩洛哥)': 'Shirha (Latin, Moroccan)',
  '希尔哈语(提非纳文)': 'Shirkha (Tifna)',
  '希尔哈语(提非纳文，摩洛哥)': 'Shirkha (Tifina, Morocco)',
  僧伽罗语: 'sinhala',
  '僧伽罗语(斯里兰卡)': 'Sinhala (Sri Lanka)',
  斯洛伐克语: 'Slovak',
  '斯洛伐克语(斯洛伐克)': 'Slovak (Slovakia)',
  斯洛文尼亚语: 'slovenian',
  '斯洛文尼亚语(斯洛文尼亚)': 'Slovene (Slovenia)',
  伊纳里萨米语: 'Inari Sami',
  '伊纳里萨米语(芬兰)': 'Inari Sami (Finland)',
  绍纳语: 'Shona',
  '绍纳语(津巴布韦)': 'Shona (Zimbabwe)',
  索马里语: 'Somali',
  '索马里语(吉布提)': 'Somali (Djibouti)',
  '索马里语(埃塞俄比亚)': 'Somali (Ethiopia)',
  '索马里语(肯尼亚)': 'Somali (Kenya)',
  '索马里语(索马里)': 'Somali (Somali)',
  阿尔巴尼亚语: 'Albanian',
  '阿尔巴尼亚语(阿尔巴尼亚)': 'Albanian (Albania)',
  '阿尔巴尼亚语(马其顿)': 'Albanian (Macedonian)',
  '阿尔巴尼亚语(科索沃)': 'Albanian (Kosovo)',
  塞尔维亚语: 'Serbian',
  '塞尔维亚语(波斯尼亚和黑塞哥维那)': 'Serbian (Bosnia and Herzegovina)',
  '塞尔维亚语(塞尔维亚及黑山)': 'Serbian (Serbia and Montenegro)',
  '塞尔维亚语(西里尔文)': 'Serbian (Cyrillic)',
  '塞尔维亚语(西里尔文，波斯尼亚和黑塞哥维那)': 'Serbian (Cyrillic, Bosnia and Herzegovina)',
  '塞尔维亚语(西里尔文，黑山)': 'Serbian (Cyrillic, Montenegro)',
  '塞尔维亚语(西里尔文，塞尔维亚)': 'Serbian (Cyrillic, Serbia)',
  '塞尔维亚语(西里尔文，科索沃)': 'Serbian (Cyrillic, Kosovo)',
  '塞尔维亚语(拉丁文)': 'Serbian (Latin)',
  '塞尔维亚语(拉丁文，波斯尼亚和黑塞哥维那)': 'Serbian (Latin, Bosnia and Herzegovina)',
  '塞尔维亚语(拉丁文，黑山)': 'Serbian (Latin, Montenegro)',
  '塞尔维亚语(拉丁文，塞尔维亚)': 'Serbian (Latin, Serbian)',
  '塞尔维亚语(拉丁文，科索沃)': 'Serbian (Latin, Kosovo)',
  '塞尔维亚语(黑山)': 'Serbian (Montenegro)',
  '塞尔维亚语(塞尔维亚)': 'Serbian (Serbian)',
  瑞典语: 'Swedish',
  '瑞典语(奥兰群岛)': 'Swedish(?land Islands)',
  '瑞典语(芬兰)': 'Swedish (Finland)',
  '瑞典语(瑞典)': 'Swedish (Sweden)',
  斯瓦希里语: 'Swahili',
  '斯瓦希里语(刚果（金）)': 'Swahili(DRC)',
  '斯瓦希里语(肯尼亚)': 'Swahili (Kenya)',
  '斯瓦希里语(坦桑尼亚)': 'Swahili (Tanzania)',
  '斯瓦希里语(乌干达)': 'Swahili (Uganda)',
  泰米尔语: 'tamil',
  '泰米尔语(印度)': 'Tamil (India)',
  '泰米尔语(斯里兰卡)': 'Tamil (Sri Lanka)',
  '泰米尔语(马来西亚)': 'Tamil(Malaysia)',
  '泰米尔语(新加坡)': 'Tamil (Singapore)',
  泰卢固语: 'telugu',
  '泰卢固语(印度)': 'Telugu (India)',
  特索语: 'Teso',
  '特索语(肯尼亚)': 'Teso (Kenya)',
  '特索语(乌干达)': 'Teso (Uganda)',
  塔吉克语: 'Tajik',
  '塔吉克语(塔吉克斯坦)': 'Tajik (Tajikistan)',
  '泰语(泰国)': 'Thai (Thailand)',
  '泰语(泰国，TH，泰文数字)': 'Thai (Thai, TH, Thai numerals)',
  提格利尼亚语: 'Tigrinya',
  '提格利尼亚语(厄立特里亚)': 'Tigrinya (Eritrea)',
  '提格利尼亚语(埃塞俄比亚)': 'Tigrinya (Ethiopia)',
  土库曼语: 'Turkmen',
  '土库曼语(土库曼斯坦)': 'Turkmen (Turkmenistan)',
  汤加语: 'Tongan',
  '汤加语(汤加)': 'Tongan (Tonga)',
  土耳其语: 'turkish',
  '土耳其语(塞浦路斯)': 'Turkish (Cyprus)',
  '土耳其语(土耳其)': 'Turkish(Turkey)',
  鞑靼语: 'Tatar',
  '鞑靼语(俄罗斯)': 'Tatar (Russia)',
  北桑海语: 'Northern Songhai',
  '北桑海语(尼日尔)': 'Northern Songhai (Niger)',
  塔马齐格特语: 'Tamazighut',
  '塔马齐格特语(摩洛哥)': 'Tamazighut (Morocco)',
  维吾尔语: 'Uighur',
  '维吾尔语(中国)': 'Uyghur (China)',
  乌克兰语: 'Ukrainian',
  '乌克兰语(乌克兰)': 'Ukrainian (Ukraine)',
  乌尔都语: 'Urdu',
  '乌尔都语(印度)': 'Urdu (India)',
  '乌尔都语(巴基斯坦)': 'Urdu (Pakistan)',
  乌兹别克语: 'Uzbek',
  '乌兹别克语(阿拉伯文)': 'Uzbek (Arabic)',
  '乌兹别克语(阿拉伯文，阿富汗)': 'Uzbek (Arabic, Afghanistan)',
  '乌兹别克语(西里尔文)': 'Uzbek (Cyrillic)',
  '乌兹别克语(西里尔文，乌兹别克斯坦)': 'Uzbek (Cyrillic, Uzbekistan)',
  '乌兹别克语(拉丁文)': 'Uzbek (Latin)',
  '乌兹别克语(拉丁文，乌兹别克斯坦)': 'Uzbek (Latin, Uzbekistan)',
  瓦伊语: 'Wai',
  '瓦伊语(拉丁文)': 'Wai (Latin)',
  '瓦伊语(拉丁文，利比里亚)': 'Wai (Latin, Liberia)',
  '瓦伊语(瓦依文)': 'Wai language (Wai language)',
  '瓦伊语(瓦依文，利比里亚)': 'Wai (Wai, Liberia)',
  越南语: 'Vietnamese',
  '越南语(越南)': 'Vietnamese(Vietnam)',
  沃拉普克语: 'Warapuk',
  '沃拉普克语(世界)': 'Warapuk (world)',
  温旧语: 'Wen Jiuyu',
  '温旧语(坦桑尼亚)': 'Wenjiu (Tanzania)',
  瓦尔瑟语: 'Walser',
  '瓦尔瑟语(瑞士)': 'Walser (Switzerland)',
  沃洛夫语: 'Wolof',
  '沃洛夫语(塞内加尔)': 'Wolof (Senegal)',
  索加语: 'Soga',
  '索加语(乌干达)': 'Soga (Uganda)',
  洋卞语: 'Foreign language',
  '洋卞语(喀麦隆)': 'Foreign language (Cameroon)',
  依地文: 'Yiddish',
  '依地文(世界)': 'Yiddish (World)',
  约鲁巴语: 'yoruba',
  '约鲁巴语(贝宁)': 'Yoruba (Benin)',
  '约鲁巴语(尼日利亚)': 'Yoruba (Nigeria)',
  粤语: 'Cantonese',
  '粤语(简体)': 'Cantonese (Simplified)',
  '粤语(简体，中国)': 'Cantonese (Simplified, China)',
  '粤语(繁体)': 'Cantonese (Traditional)',
  '粤语(繁体，中国香港特别行政区)': 'Cantonese (Traditional, Hong Kong SAR, China)',
  标准摩洛哥塔马塞特语: 'Standard Moroccan Tamazight',
  '标准摩洛哥塔马塞特语(摩洛哥)': 'Standard Moroccan Tamazight (Morocco)',
  祖鲁语: 'Zulu',
  '祖鲁语(南非)': 'Zulu (South Africa)',
  '希伯来语(以色列)': 'Hebrew (Israel)',
  未确定: 'Undetermined',
  RPA流程预览: 'RPA Preview',
  RPA流程执行: 'RPA Execution',
  系统代理: 'System Proxy',
  IP直连: 'IP Direct',
  浏览器代理: "Browser's Proxy",
  基于IP列表: 'IP List',
  '创建{{typeName}}视图': 'Create {{typeName}} View',
  ' {{oauthType}} 授权结果': '{{oauthType}} Authorization Result',
  '除分身的IP绑定关系与标签外，其它信息均可恢复，但需要补齐该分身自删除之日起至今消耗的花瓣':
    'Except for the IP binding relationship and tag, other information can be recovered, but you need to make up the tokens consumed by the avatar since the date of deletion',
  '支持以下格式的快速导入：': 'Quick import for the formats:',
  '是否为您打开批量IP导入向导？': 'Open the Batch IP Import Wizard for you?',
  '您输入了多笔代理信息，需要通过批量导入IP的功能完成相应功能':
    'You have entered multiple proxy information, and you need to use the function of importing IP addresses in batches to complete the corresponding functions',
  为动态IP批量指定名称: 'Specify a name for the dynamic IP in bulk',
  批量设置: 'Batch Settings',
  '是否忽略对动态IP进行检测？': 'Is it neglecting to detect dynamic IPs?',
  '对动态IP的出口IP进行检测是一个比较耗时的操作，如果忽略检测，则需要手动为动态IP指定一个名称':
    'Detecting the egress IP of a dynamic IP is a time-consuming operation, and if you ignore the detection, you need to manually specify a name for the dynamic IP',
  忽略检测: 'Ignore',
  继续检测: 'Continue',
  '{{name}}的云盘': "{{name}}'s Disk",
  '等待您完成{{oauthType}}的登录': 'Waiting for you to complete {{oauthType}} login',
  取消登录: 'Cancel',
  '登录成功后此对话框会自动关闭...':
    'This dialog box will close automatically after successful login...',
  最近活跃时间: 'Last active time',
  '如果您在电脑中已经安装了代理软件或者VPN方案，再或者您不需要考虑此分身的IP隔离，请选择此项':
    'If you have installed proxy software or VPN solutions on your computer, or if you do not need to consider the IP isolation of this avatar, please select this item',
  依据IP检索: 'Search by IP',
  '拥有“对已有分身的属性设置权”权限的用户才能查看/编辑敏感信息（超管与BOSS默认拥有此权限）':
    "Users with the permission 'Avatar_Mgmt' can view/edit sensitive information (SuperAdmin & BOSS have this permission by default)",
  '可在此处保存和此分身相关的敏感信息，最长2048字符':
    'You can save sensitive information related to this clone here, up to 2048 characters',
  没有保存任何和此分身相关的敏感信息: 'No sensitive information related to this clone is saved',
  跟随客户端语言设置: 'Same as client',
  '为每个分身绑定不同的IP地址（或开启本地代理）是防止账号关联的重要手段，但这并非必需项，请您根据账号所属平台进行判断':
    'Binding different IP (or local proxies) for each avatar is an important method to prevent account association (not mandatory)',
  '选中的分身已绑定IP或开启了本地代理，确定要继续吗？':
    'The selected avatar has bound IP or enabled local proxy, do you want to continue?',
  '选中的分身中存在已经绑定IP地址或开启本地代理的情况，请确认是否继续':
    'The selected avatar has bound IP or enabled local proxy, do you want to continue?',
  '我已经购买/拥有了IP地址': 'Already have an IP address',
  '如果您已经在花漾中购买了IP地址，请稍等片刻，等待IP生产完毕；如果您已经拥有了IP地址，只需将其导入至花漾然后绑定在分身即可':
    'If you already have an IP address, just import it to HuaYoung and bind it to the avatar',
  我希望使用本地代理或者本机IP直连: 'Want to use local proxy or direct connection',
  '我没有IP，希望购买新的IP地址': 'Want to buy a new IP address',
  '如果您希望购买花漾为您提供的IP地址（国内/海外公有云主机），请选择此项':
    'If you want to buy the IP address provided by HuaYoung (domestic/overseas public cloud host), please select this item',
  '恢复上述分身需扣除花瓣： {{amount}}': 'Recovering the browsers will deduct tokens: {{amount}}',
  请选择区号: 'Please select area code',
  手机注册: 'Mobile',
  邮箱注册: 'Email',
  至少选择一个可清理录像: 'Select at least one video to clean up',
  '热线电话：{{hotline}}': 'Hotline: {{hotline}}',
  '新建{{type}}': 'New {{type}}',
  '为平台IP续费或开启/关闭自动续费': 'Renew the platform IP or turn on/off auto-renewal',
  自有IP永远免费: 'Own IP is always free',
  '（{{price}}花瓣每分身每日），但可随时关闭，关闭后将不再产生费用':
    '({{price}} tokens per avatar per day), but can be turned off at any time, and no fees will be charged after closing',
  超管与BOSS可见: 'Visible to SuperAdmin and BOSS',
  只允许视图创建者编辑: 'Only creator can edit',
  可见者都可编辑: 'All can edit',
  视图可编辑性: 'Editability',
  敏感信息: 'Sensitive Info',
  'RPA 发布': 'RPA Publish',
  下载审计录像: 'Download Records',
  清理审计录像: 'Clean Records',
  '确定要下载选中会话的审计录像吗？':
    'Are you sure you want to download the audit video of the selected session?',
  '当前的录像文件由若干个视频片断组成，如果要下载录像，系统将为您重新渲染成一个视频文件，这意味着将消耗当前主机的CPU/GPU等资源，耗时较长，请确认是否继续':
    'If you want to download the video, the system will re-render it into a video file for you, which means that it will consume resources such as CPUGPU of the current host, which will take a long time',
  '录像文件存储在个人“{{link}}”中的指定位置':
    'The recording file is stored in the specified location in the personal "{{link}}"',
  正在为您下载审计录像: 'Downloading records for you',
  '您可以点击”隐藏至后台执行“按钮，隐藏后可随时点击”消息中心“查看该任务的执行情况':
    'You can click the "Hide to the background execution" button, and you can click "Message Center" at any time to view the execution status of the task',
  '确定要清理选中会话的审计录像吗？':
    'Are you sure you want to clean up the audit video of the selected session?',
  清理已锁定的审计录像: 'Clean up locked audit video',
  文件传输: 'File Transfer',
  '请使用 Kakao Talk 扫一扫完成注册/登录': 'Use Kakao to scan to complete registration/login',
  保存成功: 'Saved successfully',
  '当前分身未绑定IP，确定要创建快捷方式吗？':
    'The current avatar is not bound to IP, are you sure you want to create a shortcut?',
  '当前分身使用的是“本机IP直连”，这意味着快捷方式的使用者会使用自己的网络环境，请确认是否继续':
    'The current avatar uses "IP Direct", which means that the user of the shortcut will use their own network environment, please confirm whether to continue',
  '将分身的快捷方式发布到桌面或指定位置，通过快捷方式即可打开花漾浏览器（仅限于在本机使用）':
    'Publish the shortcut to the desktop or a designated location, and use the shortcut to open Huayang Browser',
  '将此分身的快捷方式分享给好友，好友能够以您的身份打开花漾浏览器（需下载花漾运行时）':
    'Share with your friends, and your friends can open the Huayang browser withyour identity (need download & install Huayang-Runtime first)',
  天内有效: ' days validate',
  '最长999天，逾期失效': 'limit to 999 days',
  '如有需要可随时删除分享出去的快捷方式，安全可控':
    'You can delete the shared shortcuts at any time, it is safe',
  手动关闭: 'Manual close',
  只显示生效中的分享链接: 'Only show the actived shortcuts',
  已生成的快捷方式: 'Created shortcuts',
  生成时间: 'Create Time',
  备忘名称: 'Remark',
  天后失效: ' days later',
  复制链接成功: 'Copy Success',
  '确定要删除此快捷方式吗？': 'Are you sure you want to delete this shortcut?',
  '一旦删除，则使用此快捷方式的用户将无法再打开此分身所对应的花漾浏览器':
    'Once deleted, users who use this shortcut will not be able to open the Huayang browser corresponding to this avatar',
  '按住{{key}}可多选': 'Press {{key}} to select multiple',
  花漾运行时: 'HuaYoungRuntime',
  没有可用的连接信息: 'No available connection information',
  IP状态正常: 'IP Available',
  系统偏好设置: 'System Preferences',
  '当前版本：': 'Current Version:',
  检测新版本: 'Check New',
  '不支持的代理协议：{{proxyType}}': 'Unsupported proxy protocol: {{proxyType}}',
  代理协议为空: 'Proxy protocol is empty',
  '您可能需要输入国际电话区号，例如+1': 'You may need to enter the area code, such as +1',
  通过手机号找回密码: 'Retrieve password via mobile phone',
  通过邮箱找回密码: 'Retrieve password via email',
  请输入手机号: 'Please enter your mobile number',
  请输入邮箱: 'Please enter your email',
  '回收站中的分身可随时恢复（需补齐期间消耗的花瓣）；回收站中的分身只会保留7天，到期后会被自动清理':
    'The avatars in the recycle bin can be restored at any time (need to make up for the tokens consumed during the period); the avatars in the recycle bin will only be kept for 7 days and will be automatically cleaned up after expiration',
  '当打开此分身浏览器时，允许直接执行在此处指定的RPA流程':
    'When opening this avatar browser, allow to directly execute the RPA process specified here',
  RPA发布: 'RPA Publish',
  快捷方式创建失败: 'Shortcut creation failed',
  快捷方式已发布到您的桌面: 'The shortcut has been published to your desktop',
  '请注意，快捷方式只能在本机中使用': 'Please note that shortcuts can only be used on this host',
  '正在为您打开花漾浏览器...': 'Opening Huayang browser for you...',
  '安装“{{runtime}}”': 'Install "{{runtime}}"',
  '需要下载并安装“{{runtime}}”才能够打开花漾浏览器，如已安装请{{action}}':
    'You need to download and install "{{runtime}}" to open HuaYoung Browser, if you have installed it, please {{action}}',
  立即打开: 'Open Now',
  存为快捷方式: 'Save as Shortcut',
  下载并安装花漾运行时: 'Download',
  有效日期: 'Valid Date',
  重新测试: 'Test',
  '未绑定IP地址，无法开启海外网络加速': 'Unbound IP address, Network Acceleration is unavailable',
  '{{isPrimary}}IP': '{{isPrimary}} IP',
  副: 'Secondary',
  '凡能够看到此分身的用户均可查看备注，最长512字符':
    'Please enter a description for the avatar (up to 512 characters)',
  刷新此页面: 'Reload Page',
  已生成的分享链接: 'Shared Links',
  '快捷方式已生成：': 'Shortcut created:',
  请复制上述链接并将其发送给您的好友: 'Please copy the above link and send it to your friends',
  快捷方式链接复制成功: 'Shortcut link copied successfully',
  '尚未创建{{typeName}}标签': 'No {{typeName}} tag created',
  '{{typeName}}标签可用于对{{typeName}}进行标记、分类、筛选等用途':
    '{{typeName}} tags can be used to mark, classify, filter, etc. {{typeName}}',
  '标签中的数字代表关联的{{typeName}}数量': 'The number to the right of the tag means total numbers of {{typeName}}',
  '多标签间的关系：': 'Relationship:',
  '您可以点击“隐藏至后台执行”按钮，隐藏后可随时点击“消息中心”查看该任务的执行情况':
    'You can click the "Hide to the background execution" button, and you can click "Message Center" at any time to view the execution status of the task',
  '（已用{{count}}）': '(Used {{count}})',
  '请为本次创建的分享链接起个名称，以供备忘': 'Please name the shared link for this time',
  '请为快捷方式起个名称（发布到桌面，如有重名则覆盖）':
    'Please name the shortcut (publish to desktop)',
  '未找到相关插件，您可以': 'No related plug-ins found, you can',
  关于批量开启分身的安全策略: 'About the security policy of batch opening avatar',
  '指定分身的安全策略已经开启，但每个分身安全策略的默认值并没有统一设置，如需要统一设置，在后续的安全策略设置对话框里，请您逐项修订':
    'The security policy of the specified avatar has been turned on, but the default value of the security policy of each avatar has not been set uniformly. If you need to set it uniformly, please revise it item by item in the subsequent security policy setting dialog box',
  '为所选{{type}}}批量增加标签': 'Add tags in batch for selected {{type}}',
  分身迁移与合并: 'Avatar Migration & Merge',
  '将某个分身的Cookie等数据迁移/合并至当前团队的另外一个分身':
    'Migrate/merge the cookies and other datas of an avatar to another avatar of the current team',
  目标分身: 'Target Avatar',
  请选择分身: 'Please select browser',
  迁移并合并目标分身的Cookie数据: 'Migrate and merge the cookie data of the target browser',
  清空目标分身的Cookie数据: 'Clear the cookie data of the target browser',
  迁移并替换目标分身的IndexedDB: 'Migrate and replace the IndexedDB of the target browser',
  迁移并替换目标分身的LocalStorage:
    'Migrate and replace the LocalStorage data of the target browser',
  迁移并替换目标分身的浏览器指纹:
    'Migrate and replace the browser fingerprint of the target browser',
  Cookie冲突策略: 'Cookie Conflict',
  尚未添加任何流程定义: 'No process definition added yet',
  迁移成功: 'Migrate successfully',
  '从Chrome、Edge、AdsPower、紫鸟等浏览器中迁移至花漾':
    'Migrate from Chrome, Edge, AdsPower, Ziniao, etc. to HuaYoung',
  '将VPS/云主机中的环境整体克隆至花漾，包括浏览器指纹、Cookie数据、网站密码、IP地址等':
    'Clone the environment in VPS/cloud host to HuaYoung, including browser fingerprint, cookie data, website password, IP address, etc.',
  '将某个分身的Cookie等数据迁移/合并至另外一个分身':
    'Migrate/merge the cookie data of an avatar to another avatar',
  所选分身: 'Selected browsers',
  所选分身中有绑定了IP池的分身: 'The selected browser has bound the IP pool',
  请解绑对应分身的IP池后再进行操作:
    'Please unbind the IP pool of the corresponding browser before operation',
  '{{module}}{{action}}成功': '{{module}}{{action}} successfully',
  '请输入{{label}}团队的ID': 'Please enter the ID of {{label}} team',
  其他浏览器导入: 'Import From Other Browsers',
  VPS克隆: 'VPS Environment Clone',
  所选分身中有未开启安全策略的分身: 'The selected browser has not turned on the security policy',
  批量分身联营与转让: 'Batch Joint/Transfer',
  '当前分身转让中，无法继续操作':
    'The current browser is being transferred, and the operation cannot continue',
  所选分身中有转让中的分身: 'The selected browser has a browser being transferred',
  请取消转让后再进行操作: 'Please cancel the transfer before operation',
  动态过滤条件: 'Dynamic Filter Conditions',
  '静态指定{{type}}': 'Static Assignment',
  标签关系: 'Relationship ',
  或者关系: 'OR',
  并且关系: 'AND',
  '当选择多个标签时，标签之间的关系': 'When selecting multiple tags, the relationship between tags',
  '动态过滤条件能够根据平台、名称、标签等条件将符合条件的{{type}}自动分组，即便新创建的{{type}}也会被自动归类':
    ' Automatically group {{type}} that meet certain criteria based on platform, name, tags, etc., and even newly created will be auto categorized',
  '静态指定{{type}}类似于分组，只能将已存在的{{type}}纳入到分组中':
    'Similar to grouping, where only existing {{type}} can be included in the group',
  全部来源: 'All Sources',
  发布到桌面: 'Publish to Desktop',
  '将分身的快捷方式发布到桌面，通过快捷方式即可打开花漾浏览器（仅限于在本机使用）':
    'Publish the shortcut of the avatar to the desktop, and you can open the HuaYoung browser through the shortcut (limited to local use)',
  分享给他人: 'Share with Friends',
  查看已生成的分享链接: 'Actived Shortcuts',
  '选中的分身存在联营分身，需停止联营后方可删除':
    'The selected browser has a joint venture browser, which needs to be stopped before it can be deleted',
  授权: 'Authorize',
  关于: 'About',
  '关于{{P_NAME}}运行时': 'About {{P_NAME}}Runtime',
  '如果您正在通过别人分享给您的链接打开{{P_NAME}}浏览器，现在只需重新刷新页面即可':
    'If you are opening {{P_NAME}} browser through the link shared by others, just refresh the page now',
  '关闭此窗口后，{{P_NAME}}运行时会显示为任务托盘中的图标':
    'After closing this window, {{P_NAME}}Runtime will be displayed as an icon in the task tray',
  暂停其它线程: 'Pause Other Proc',
  恢复其它线程: 'Resume Other Proc',
  '确定要删除当前IP吗?': 'Are you sure you want to delete the current IP?',
  迁移克隆: 'Migrate/Clone',
  选择IP: 'Choose Ip',
  独享: 'Exclusive',
  共享: 'Share',
  '订单【{{orderId}}】详情': 'Order [{{orderId}}] Details',
  所选分身中有未绑定IP地址的分身: 'The selected browser has a browser without bound IP address',
  不能包含emoji表情符号: 'Cannot contain emoji emoticons',
  '无权限查看{{model}}': 'No permission to view {{model}}',
  '请指定{{type}}关联标签（可多选）':
    'Please specify the {{type}} associated tag (multiple choices)',
  批量修改分身联营安全策略: 'Batch modify avatar joint security policy',
  '只针对开启了联合运营的分身有效（未联营的分身会忽略）':
    'Only valid for avatars that have enabled joint operations (avatars that are not joint will be ignored)',
  联营安全策略: 'Joint Security Policy',
  插件URL: 'Plugin URL',
  快速导入: 'Quick Import',
  编辑分身: 'Edit',
  安装插件: 'Install Plugin',
  批量安装插件: 'Install Plugin',
  '自2024年2月1日起，将调整AWS所有规格的商品价格，涉及所有新老客户':
    'From February 1, 2024, the price of all AWS specifications will be adjusted, involving all new and old customers',
  '一旦退出将无法再次进入该团队，除非重新获得邀请':
    'Once you exit, you will not be able to enter the team again unless you get the invitation again',
  请输入插件在Google市场中的链接地址:
    'Please enter the link address of the plugin in the Google market',
  '花漾浏览器的插件体系与Google浏览器完全兼容，您只需要输入Google市场中插件的地址，即可将其提交至花漾插件市场；需要提醒您的是，您提交的插件必须遵循相关法律、法规的规定，云上悦动保留审核并追责的权利':
    'The plugin system of HuaYoung browser is completely compatible with Google browser. You only need to enter the address of the plugin in the Google market to submit it to the HuaYoung plugin market; you need to remind you that the plugin you submit must comply with relevant laws and regulations, and HuaYoung reserves the right to review and pursue it',
  '云上悦动提供的公有云主机来源于阿里云、腾讯云等厂商，这些主机均受中国相关部门监管，您在使用过程中应遵守中国大陆地区的相关法律法规，不得访问国家明令禁止的敏感网站，云上悦动不提供任何VPN/翻墙服务':
    'The public cloud hosts provided by HuaYoung come from vendors such as Alibaba and Tencent Cloud. These hosts are supervised by relevant departments in China. You should comply with relevant laws and regulations in mainland China during the use and not access sensitive websites that are expressly prohibited by the country. HuaYoung does not provide any VPN service',
  购买的公有云主机只能访问白名单内的有限网站:
    'The public cloud host can only access a limited number of websites',
  严禁用于任何国家法律法规所禁止的行为:
    'It is strictly forbidden to use for any behavior prohibited by the laws',
  我已全部知晓并充分理解: 'I have fully understood',
  '性价比高，全球覆盖范围广': 'High cost performance, wide global coverage',
  'IP类型广泛，全球覆盖范围广': 'Wide range of IP types, wide global coverage',
  购买用于布局海外社交媒体账号矩阵的IP服务商推荐:
    'Recommended IP service provider for overseas social media account matrix',
  '注意事项：云上悦动并不保证以上服务商IP资源的纯净性与可用性':
    'Note: HuaYoung does not guarantee the purity and availability of the IP resources',
  购买国内家庭住宅IP请联络在线客服:
    'Please contact online support to purchase domestic residential IP',
  '您可以根据您的使用用途，选择购买适合的IP资源，花漾提供的均为合规的公有云主机，内核静态、独享的IP地址，适合于跨境电商的店铺运营':
    'You can choose the IP resources suitable for your usage, HuaYoung provides them as compliant public cloud hosts, static and exclusive IP addresses, suitable for e-commerce platform shop operation',
  请和在线客服联络: 'Please contact online customer service',
  请根据您的用途选择适合的IP资源:
    'Please choose the appropriate IP resources according to your usage',
  用于店铺运营: 'e-commerce platform shop operation',
  '如果您有Amazon、eBay、Shopee、TikTok小店、Lazada、Etsy等店铺需要长期运营，可以购买花漾为您提供的公有云主机，内含静态、独享的IP地址':
    'If you have Amazon, eBay, Shopee, TikTok store, Lazada, Etsy and other stores that need to be operated for a long time, you can purchase the public cloud host provided by HuaYoung, which contains static and exclusive IP addresses',
  布局海外社交媒体账号矩阵: 'Overseas social media account matrix',
  '如果您希望布局Facebook、TikTok、Instagram等社交媒体账号矩阵，可以了解我们为您推荐的合作伙伴的IP资源':
    'If you want to layout Facebook, TikTok, Instagram and other social media account matrix, you can learn about the IP resources of our recommended partners',
  国内家庭住宅IP: 'Domestic residential IP',
  '国内家庭住宅IP主要用来访问国内的站点，如抖音、小红书、快手、网游等用途':
    'Domestic residential IP is mainly used to access domestic sites, such as Douyin, Xiaohongshu, Kuaishou, online games, etc.',
  浏览我们为您精选的IP服务商合作伙伴: 'Browse our selected IP service provider partners for you',
  '价格合理优惠 VPS 服务器托管': 'Reasonable price and discount VPS server hosting',
  '无法通过IP检测机构对指定代理IP进行检测（网页内容发生变化）':
    'Cannot detect the specified proxy IP through the IP detection agency (the web content changes)',
  IP地址检测网站发生变化: 'IP address detection website changes',
  请在本页面的IP地址区域重新设置IP地址检测网站:
    'Please reset the IP address detection website in the IP address area of this page',
  '该分身绑定的IPGO暂不可用，请和在线客服联络':
    'The IPGO bound by the avatar is temporarily unavailable, please contact online customer service',
  首页设置: 'Homepage',
  '为IP“{{ip}}”绑定分身': 'Bind avatar for IP "{{ip}}"',
  '共{{total}}个IP，筛选出符合条件的：{{count}}个':
    '{{total}} IPs in total, {{count}} IPs meet the conditions',
  检测页快捷方式: 'Shortcut',
  浏览器首页设置: 'Homepage Settings',
  首页与书签设置: 'Homepage & Bookmarks Settings',
  滑块拖拽: 'Drag Slider',
  选择相同物体: 'Pick Same Object',
  '{{count}}周': '{{count}} weeks',
  '{{count}}月': '{{count}} months',
  '{{count}}年': '{{count}} years',
  请先选中1至多张任务卡: 'Please select 1 to multiple task cards first',
  取消联营: 'Cancel Joint',
  '处于联营状态（含联营确认状态）的分身将中断和所有团队的联营；未处于联营状态的分身则自动忽略':
    'The avatar in the joint venture state (including the joint venture confirmation state) will interrupt the joint venture with all teams; the avatar that is not in the joint venture state will automatically ignore',
  分身已取消联合运营: 'The avatar has canceled the joint operation',
  全部接收: 'Receive All',
  全部拒绝: 'Reject All',
  分身生命周期: 'Avatar Life Cycle',
  视频加载策略: 'Video Strategy',
  图片加载策略: 'Image Strategy',
  默认: 'Default',
  不加载视频: 'Do Not Load Video',
  '（如有更多需求请联络在线客服）':
    '(Please contact online customer service for more requirements)',
  隐式运行: 'Headless',
  '隐藏式运行是指浏览器分身在执行流程时不显示窗口，这样不会抢夺对电脑的键鼠控制权，但会对拟人化操作带来影响，请慎重选择':
    'Headless means that the browser avatar does not display the window when executing the process, so it will not grab the control of the keyboard and mouse of the computer, but it will affect the humanized operation, please choose carefully',
  隐式运行只限在本地设备中执行: 'Headless is only executed on the local device',
  '温馨提示：由于您选择了“隐式运行”，浏览器窗口不会显示，也不会抢夺电脑的键鼠控制权':
    'Tips: Since you have selected "Headless", the browser window will not be displayed, nor will it grab the control of the keyboard and mouse of the computer',
  使用客户端的代理设置: 'Use client proxy settings',
  用户名节点和密码节点至少需要设置一个:
    'Username node and password node need to be set at least one',
  更多问题: 'More Qs',
  日志文件: 'Log File',
  远程协助: 'Remote Assist',
  只支持在客户端中打开日志文件: 'Only support opening log files in client',
  请您在花漾客户端中使用本功能: 'Please use this function in the HuaYoung client',
  将花漾官方技术支持添加至您的团队: 'Add HuaYoung official technical support to your team',
  '1）请确认您已经和花漾技术支持团队建立了联系':
    '1) Please confirm that you have established contact with the HuaYoung technical support team',
  '2）点击确定后，名称为“花漾官方技术支持”的账号会临时进入您的团队，并承担“BOSS”角色':
    '2) After clicking OK, the account named "HuaYoung Official Technical Support" will temporarily enter your team and assume the "BOSS" role',
  '3）花漾技术支持团队会通过上述账号，在您同意并授权的情况下，操作您的资源，完成问题的排查、定位与解决':
    '3) The HuaYoung technical support team will operate your resources through the above account with your consent and authorization to complete the problem investigation, positioning and resolution',
  '4）6小时后，该账号会自动退出您的团队，在此之前，您也可以手动强制踢出此账号':
    '4) After 6 hours, the account will automatically exit your team. Before that, you can also manually force this account',
  请确认您已经阅读并理解了上述内容:
    'Please confirm that you have read and understood the above content',
  '会话保持是指：在一个新的浏览器会话创建时，获取一个动态IP的出口IP值，在此浏览器会话期间内，一直保持这个出口IP值不变':
    'Session persistence means: When a new browser session is created, get the exit IP value of a dynamic IP. During this browser session, keep the exit IP value unchanged',
  '当打开浏览器会话时，会根据出口IP所属城市与IP归属地进行判断，当发觉城市不匹配时会产生告警信息':
    'When opening a browser session, it will be judged according to the city to which the exit IP belongs and the IP location. When the city does not match, an alarm message will be generated',
  归属地保持: 'Location-Hold',
  购买的平台IP: 'Platform IP',
  全部设备: 'All Devices',
  生命周期: 'Life Cycle',
  等待流程自然结束: 'Wait for the process to end naturally',
  等待流程执行: 'Wait for the process to execute',
  分钟后强制结束: 'minutes and force to end',
  '未找到符合条件的{{type}}，切换至{{action}}':
    'No {{type}} found that meets the conditions, switch to {{action}}',
  '名称已存在（请注意检查分身回收站的分身名称）': 'Name already exists',
  '请输入{{label}}': 'Pls enter {{label}}',
  '使用系统代理/VPN': 'Use a system proxy VPN',
  '已选择{{count}}笔交易，开票金额：{{amount}}元':
    '{{count}} transactions have been selected, invoiced amount: {{amount}} yuan',
  已为您过滤所有未发布及平台不符的流程定义:
    'Unpublished and platform mismatch Proc have been filtered',
  显示全部: 'Show All',
  没有符合条件的流程定义: 'No Proc found that meets the conditions',
  未发布: 'Unpublished',
  平台不符: 'Platform mismatch',
  运营公告: 'Announcement',
  客户端网络设置: 'Proxy',
  已为您显示全部流程定义: 'All Proc have been displayed',
  '出于某些安全原因，您的账号已被冻结，无法执行任何操作，请和在线客服联络':
    'For some security reasons, your account has been frozen and cannot perform any operations, please contact online customer service',
  您的账号已经被冻结: 'Your account has been frozen',
  登录设备白名单限制您进入此团队: 'Login device whitelist restricts you from entering this team',
  无法对超管或BOSS开启客户端白名单: 'Cannot open device whitelist for SuperAdmin or BOSS',
  超级管理员与BOSS可在任何客户端设备中登录并进入本团队:
    'Super administrators and BOSS can log in and enter this team on any client device',
  出口IP所属城市与IP归属地保持设置不符:
    'The city to which the exit IP belongs does not match the IP Location-Hold setting',
  出口IP所属城市为: 'The city to which the exit IP belongs is',
  归属地保持设置为: 'Location-Hold is set to',
  '以上信息依赖于花漾IP地址库，未必准确，需要您自行判断':
    'The above information depends on the HuaYoung IP address library, which may not be accurate, and you need to judge by yourself',
  请精确到城市级别或仅选择国家: 'Please be accurate to the city level or only select the country',
  不开启: 'Off',
  国家或地区: 'Country or Region',
  '您绑定的是一个动态IP，是否开启相关策略？':
    'You are bound to a dynamic IP, do you want to enable the relevant policy?',
  请至少选择一种语言: 'Please select at least one language',
  '查看花漾客户端日志文件所在位置，方便排错':
    'Open the location of the HuaYoung client log files for convenient troubleshooting',
  允许用户在该客户端中访问此团队: 'Allow this users to access this team through the device',
  禁止用户在该客户端中访问此团队: 'Prohibit this users to access this team through the device',
  '选中的分身存在联营分身，无法为其解绑IP':
    'The selected browser has a joint venture browser, which cannot be unbound',
  '确定要批量解绑所选分身的IP地址吗？':
    'Are you sure you want to unbind the IP address of the selected browser in batch?',
  'IP服务商提供的特性，访问此URL时会更新出口IP（选填）':
    'IP service provider provides features, the exit IP will be updated when accessing this URL (optional)',
  '如果出口IP既支持IPv4也支持IPv6，则优先使用IPv4连接':
    'If the exit IP supports both IPv4 and IPv6, IPv4 connection is preferred',
  '选中的分身存在联营分身，无法关闭安全策略':
    'The selected browser has a joint venture browser, which cannot be closed',
  需要先关闭分身的联营状态才能够关闭安全策略:
    'You need to close the joint venture status of the avatar before you can close the security policy',
  访问此URL时会更新出口IP: 'The exit IP will be updated when accessing this URL',
  手动刷新: 'Refresh',
  只有动态IP才支持手动刷新: 'Only dynamic IP supports manual refresh',
  '手动刷新是指更新动态IP的出口IP，是由IP服务商提供的私有特性':
    'Manual refresh means updating the exit IP of the dynamic IP, which is a private feature provided by the IP service provider',
  该动态IP不支持手动刷新: 'The dynamic IP does not support manual refresh',
  '手动刷新是由IP服务商提供的特性，您需要在IP属性中设置手动刷新的URL，访问此URL时会更新动态IP的出口IP':
    'Manual refresh is a feature provided by the IP service provider. You need to set the URL for manual refresh in the IP attributes. When accessing this URL, the exit IP of the dynamic IP will be updated',
  '正在为您手动刷新该动态IP的出口IP...':
    'Manually refreshing the exit IP of the dynamic IP for you...',
  更新出口IP: 'Update Exit IP',
  '探测...': 'Detecting...',
  '根据使用的加速通道的不同，将会根据消耗的流量扣除相应数量的花瓣，请确认是否继续':
    'According to the different acceleration channels used, the corresponding number of tokens will be deducted according to the consumed traffic. Please confirm whether to continue',
  手动更换IP: 'Change IP',
  查看更多IP服务商: 'View more IP service providers',
  已显示全部的: 'Show all',
  仅显示未绑定分身的的IP: 'Only show IPs that are not bound to the avatar',
  已为您过滤所有已绑定分身的IP地址:
    'All IP addresses bound to the avatar have been filtered for you',
  浏览器分身策略: 'Browser avatar policy',
  侧边栏控制台: 'Sidebar Console',
  默认展开: 'Show',
  默认收起: 'Hide',
  控制台内容格式化: 'Console Format',
  可设置控制台输出内容的格式: 'The format of the console output content can be set',
  窗口布局顺序: 'Layout Order',
  正序排列: 'Ascending',
  倒序排列: 'Descending',
  可拖拽移动窗口顺序: 'Drag and drop to move the window order',
  发送邮件: 'Send Email',
  收取邮件: 'Receive Email',
  邮箱账号: 'Email Account',
  接收协议: 'Protocol',
  '密码/授权码': 'Password',
  邮件服务器: 'Email Server',
  邮件发送时间: 'Email Send Time',
  最近24小时: 'Last 24 Hours',
  最近48小时: 'Last 48 Hours',
  最近7天: 'Last 7 Days',
  '发件人包含的关键字，留空则不限': 'Keywords contained in the sender, leave blank if not limited',
  邮件标题: 'Email Title',
  '邮件标题包含的关键字，留空则不限':
    'Keywords contained in the email title, leave blank if not limited',
  接收规则: 'Receive Rule',
  接收符合规则的第一封邮件: 'Receive the first email',
  按时间由新到旧排序: 'Sort by time from new to old',
  接收符合规则的全部邮件: 'Receive all emails',
  提取规则: 'Extract Rule',
  提取邮件标题: 'Extract Email Title',
  提取邮件正文: 'Extract Email Content',
  正则表达式: 'Regular Expression',
  留空则提取全部内容: 'Leave blank to extract all content',
  关于正则表达式: 'About Regular Expression',
  高级: 'Advanced',
  提取6位数字: 'Extract 6 digits',
  提取双引号包含的内容: 'Extract the content enclosed in double quotes',
  提取单引号包含的内容: 'Extract the content enclosed in single quotes',
  '提取紧随code:后面的4位非空字符': 'Extract the 4 non-empty characters immediately after code:',
  获取当前焦点元素: 'Fetch focused element',
  将焦点元素提取至变量: 'Save focused element to',
  无法获取网址信息: 'Unable to get URL information',
  请检查您输入的网址是否正确: 'Please check if the URL you entered is correct',
  '导入时间：': 'Import time',
  '为联营分身解绑IP，将导致联营分身无法访问':
    'Unbinding IP for joint venture avatar will cause the joint venture avatar unable to access',
  无法对联营分身进行本地代理的相关操作:
    'Unable to perform related operations on local proxy for joint venture avatar',
  '选中的分身中包含有联营分身，无法对联营分身进行本地代理的相关操作':
    'The selected browser contains a joint venture browser, and related operations on local proxy for joint venture browser cannot be performed',
  '已经联营的分身被取消联营，未联营的分身自动忽略':
    'The joint venture avatar has been canceled, and the non-joint venture avatar is automatically ignored',
  '无法对联营分身进行绑定/解绑IP的相关操作':
    'Unable to perform related operations on binding/unbinding IP for joint venture avatar',
  '选中的分身中包含有他人联营给您的分身，无法对联营分身进行绑定/解绑IP的相关操作':
    'The selected browser contains a joint venture browser given to you by others, and related operations on binding/unbinding IP for joint venture browser cannot be performed',
  '选中的分身中包含有他人联营给您的分身，您无法对该联营分身再次进行联合运营':
    'The selected browser contains a joint venture browser given to you by others, and you cannot perform joint operations on the joint venture browser again',
  无法对他人的联营分身进行联合运营:
    "Unable to perform joint operations on other people's joint venture browser",
  无法对联营分身进行转让: 'Unable to transfer joint venture browser',
  '选中的分身中包含有联营分身，无法对联营分身进行转让':
    'The selected browser contains a joint venture browser, and the joint venture browser cannot be transferred',
  默认开启: 'Default on',
  默认关闭: 'Default off',
  新创建的分身是否默认开启安全策略:
    'Whether the newly created avatar defaults to enable the security policy',
  关闭所有分身的安全策略: 'Close the security policy of all avatars',
  '确定要暂停此任务吗？': 'Are you sure you want to pause this task?',
  '暂停操作将在3～5秒后生效': 'The pause operation will take effect after 3 to 5 seconds',
  '确定要恢复此任务吗？': 'Are you sure you want to resume this task?',
  '恢复操作将在3～5秒后生效': 'The resume operation will take effect after 3 to 5 seconds',
  恢复: 'Resume',
  所选分身中有他人联营给您的分身:
    'The selected browser contains a joint venture browser given to you by others',
  请取消勾选这类分身后再进行操作: 'Please uncheck this type of avatar before operation',
  选中的分身均未开启联营: 'The selected browser has not enabled joint venture',
  '分身开启联营后，才能够修改分身的联营安全策略':
    'After the avatar enables joint venture, you can modify the joint venture security policy of the avatar',
  流量计划网站规则: 'Traffic Plan Website Rules',
  内容不能为空: 'Content cannot be empty',
  '当勾选此选项时，如果某个分身包含此流程定义，则此分身包含的流程定义的版本自动升级，但这并不会影响正在运行中的流程任务':
    'When this option is checked, if an avatar contains this process definition, the version of the process definition contained in this avatar will be automatically upgraded, but this will not affect the running process task',
  导入计划: 'Import Plan',
  只有创建者方可执行: 'Only the creator can execute',
  有权限的人都可执行: 'Anyone with permission can execute',
  '允许他人以我的身份、在我的设备上执行此流程计划':
    'Allow others to execute this process plan as myself on my device',
  '允许他人以自己身份、在自己的设备上执行此流程计划':
    'Allow others to execute this process plan as themselves on their device',
  以创建者的身份执行此流程计划: 'Execute this process plan as the creator',
  以我自己的身份执行此流程计划: 'Execute this process plan as myself',
  未访问过任何分身: 'Not visited any avatar',
  访问人: 'Visitor',
  最近访问: 'Recently Visited',
  '连续{{days}}天与您相遇，感觉真好': 'Meet you for {{days}} days in a row, feels good',
  '初次见面，幸甚至哉': 'Nice to meet you for the first time',
  '{{days}}天没有见到您了，别来无恙': 'Haven’t seen you for {{days}} days, hope you are well',
  很高兴见到你: 'Nice meeting you',
  '花瓣充足，还可维持{{days}}天消耗':
    'Sufficient tokens, can still maintain consumption for {{days}} days',
  '{{days}}天后将有{{count}}个IP释放': '{{count}} IPs will be released in {{days}} days',
  待续费IP: 'IP to be renewed',
  空闲IP: 'Idle IP',
  失效IP: 'Invalid IP',
  查看全部: 'View All',
  登录地点: 'Login Location',
  通过花漾RPA自动化流程能够大幅提升您的运营效率:
    'Automating processes through HuaYoung RPA can significantly improve your operational efficiency',
  未创建流程定义: 'No process definition created',
  即将执行的流程计划: 'The process plan to be executed',
  预期执行时间: 'Expected execution time',
  未执行流程任务: 'Unexecuted process task',
  RPA流程任务: 'RPA Process Task',
  无活跃的浏览器分身会话: 'No active browser avatar session',
  活跃会话: 'Active Session',
  云盘存储空间: 'Cloud Storage Space',
  '团队成员（暨用户）数量': 'Team Members (Users) Quantity',
  浏览器分身数量: 'Browser Avatar Quantity',
  RPA按量计费: 'RPA Pay-As-You-Go',
  流量加速费用: 'Traffic Acceleration Fee',
  '凡使用花漾接入点进行网络加速，按实际产生流量进行扣取':
    'For network acceleration using HuaYoung access points, the actual traffic generated is deducted',
  重置: 'Reset',
  报价计算器: 'Quote Calculator',
  是否开启审计录像: 'Whether to enable audit video',
  审计录像保留时长: 'Audit video retention period',
  是否会使用RPA流程: 'Whether to use RPA process',
  每账号预估每天执行的RPA次数: 'Estimated number of RPA executions per account per day',
  合计约: 'Total about',
  绑定微信: 'Bind WeChat',
  '请用微信扫一扫完成绑定，绑定成功后将赠送您 {{count}} 花瓣':
    'Please use WeChat to scan and complete the binding. After successful binding, you will receive {{count}} tokens as a gift',
  '绑定手机，您将免费获得 {{count}} 花瓣':
    'Bind your phone, you will get {{count}} tokens for free',
  关于花瓣: 'About Tokens',
  '花瓣是花漾提供的一种类似于点数的内部结算单位，1元=100花瓣（购买数量较多时享有优惠）。':
    'Tokens are an internal settlement unit similar to points provided by HuaYoung, 1 yuan = 100 tokens (enjoy discounts when purchasing in large quantities).',
  '传统的“基于套餐”的报价模式，其缺点是：让用户为没有用到的配额而买单。为最大程度让利于用户，花漾提供基于花瓣的报价模式，先用再买，用多少买多少。':
    'The traditional "package" pricing model has the disadvantage that it forces users to pay for unused quotas. To maximize the benefits for users, HuaYoung provides a pricing model based on tokens. First use, then buy, use as much as you want.',
  '举例：前2个浏览器分身免费，第3个分身每天扣除10花瓣。如果将分身删除，则不再扣除且实时生效。这种报价模式对用户最为有利——先用再买，用多少买多少，一旦不用，则不必再支付任何费用，诚意满满。':
    'For example: The first two browser avatars are free, the third avatar deducts 10 tokens per day. If the avatar is deleted, it will no longer be deducted and will be effective immediately. This pricing model is most advantageous for users -- first use, then buy, use as much as you want -- once not used, no need to pay any fee -- sincerity',
  '这是您首次使用花漾灵动客户端，请绑定微信，将免费赠送您{{count}}花瓣':
    'This is your first time using the HuaYoung client, please bind WeChat, and you will receive {{count}} tokens for free',
  '这是您首次使用花漾灵动客户端，请绑定您的手机号，将免费赠送您{{count}}花瓣':
    'This is your first time using the HuaYoung client, please bind your phone number, and you will receive {{count}} tokens for free',
  '我们为您生成了一个专属邀请码，通过该邀请码每发展一名用户，您将获得{{count}}花瓣的激励':
    'We have generated an exclusive invitation code for you. For each user you develop through this invitation code, you will receive {{count}} tokens as an incentive',
  专属邀请码: 'Exclusive Invitation Code',
  专属邀请链接: 'Exclusive Invitation Link',
  已发展的用户: 'Developed Users',
  用户在注册时请务必填上您的专属邀请码:
    'Please be sure to fill in your exclusive invitation code when registering',
  欢迎使用花漾灵动: 'Welcome to HuaYoung',
  进入分身管理: 'Enter Avatar Management',
  RPA自动化: 'RPA Automation',
  '“RPA（暨“机器人流程自动化）是花漾最重要的组成部分，通过花漾RPA能将大量枯燥的工作化繁为简，使您以一当百，轻松拥有强大的自动化能力':
    '"RPA (i.e. "Robotic Process Automation") is the most important part of HuaYoung. Through HuaYoung RPA, a large amount of tedious work can be simplified, allowing you to do a hundredfold, and easily have powerful automation capabilities',
  进入花漾RPA: 'Enter HuaYoung RPA',
  卡密: 'Card Key',
  使用说明: 'Instructions',
  奖励花瓣: 'Reward Tokens',
  已领取: 'Received',
  未发放: 'Not issued',
  '通过您的专属邀请码或专属邀请链接每发展一名注册用户（该用户必须通过手机注册），您将获得{{count}}花瓣的激励':
    'For each registered user who develops through your unique invitation code or exclusive invitation link (the user must register mobile phone), you will receive {{count}} petal incentives',
  已获得的花瓣: 'Received Tokens',
  '当分身{{threshold}}时，每增加一个分身扣除{{price}}':
    'When the avatar is {{threshold}}, deduct {{price}} for each additional avatar',
  '实行阶梯报价，分身越多单价越小；团队前{{traffic}}个分身免费':
    'Implement ladder pricing, the more avatars, the lower the unit price; the first {{traffic}} avatars of the team are free',
  '{{count}}花瓣/天': '{{count}} tokens/day',
  '按照执行流程任务的累积时长计费，每浏览器分身每分钟扣除{{price}}花瓣，不足1分钟按1分钟扣除，如：{{count}}个浏览器分身同时执行流程任务，每个浏览器分身分别执行时长1.8分钟，则累计扣除 {{price}}花瓣 x {{count}}分身 x {{min}}分钟 = {{total}}花瓣':
    'Charged according to the cumulative duration of executing process tasks, deduct {{price}} tokens per minute for each browser avatar, and deduct 1 minute if less than 1 minute, such as: {{count}} browser avatars execute process tasks at the same time, each browser avatar executes for 1.8 minutes, then the total deduction is {{price}} tokens x {{count}} avatars x {{min}} minutes = {{total}} tokens',
  '分身/分钟': 'Avatar/Minute',
  '团队前{{amount}}个成员（含超管）免费，每增加一个团队成员每日扣除{{price}}花瓣':
    'The first {{amount}} members of the team (including the super administrator) are free, and each additional team member is deducted {{price}} tokens per day',
  '团队免费赠送{{amount}}GB空间，每增加1GB每日扣除{{price}}花瓣（不足1GB按1GB收取）':
    'The team gives away {{amount}}GB of space for free, and each additional 1GB is deducted {{price}} tokens per day (less than 1GB is charged as 1GB)',
  '共{{count}}个分身': 'Total {{count}} avatars',
  我的邀请码: 'My invitation code',
  最近30天奖励花瓣: 'Total Reward Tokens for last 30 days',
  当前团队的花瓣余额必须大于所转让分身一天的花瓣消耗量才允许接收分身:
    'The petal balance of the current team must be greater than the petal consumption of the avatar for one day to allow the avatar to be received',
  当前团队的花瓣余额必须大于0才能够转让分身:
    'The petal balance of the current team must be greater than 0 to transfer the avatar',
  暂无系统通知: 'No system notification',
  无即将执行的流程计划: 'No process plan to be executed',
  最近登录位置: 'Last login location',
  可设置团队成员允许在哪些客户端设备中进入本团队:
    'Set which client devices team members are allowed to enter this team',
  不限制登录客户端: 'No restriction on login client',
  '当指定时间到达后如果流程仍未结束，会强制结束当前正在执行流程的浏览器分身（未执行流程的浏览器分身会被忽略），最后执行清理子流程':
    'If the specified time arrives and the process has not ended, the browser avatar currently executing the process will be forced to end (the browser avatar that has not executed the process will be ignored), and finally execute the cleaning sub-process',
  同步输入: 'Sync Input',
  文本悬浮窗口: 'Float Window',
  导入IP池IP: 'Import IP Pool IP',
  TK套餐: 'TK Solution',
  '花瓣余额不够，将影响访问分身': 'Insufficient petal balance will affect access to the avatar',
  '花瓣只可维持{{days}}天消耗': 'Tokens can only maintain consumption for {{days}} days',
  未开启自动续费的IP: 'IP not set to auto-renew',
  执行人身份: 'Executor',
  没有待续费的平台IP: 'No IP to be renewed',
  通过为浏览器分身绑定IP地址做到分身的IP隔离:
    'By binding the IP address to the avatar, the IP of the avatar is isolated',
  离线: 'Offline',
  团队成员管理: 'Users',
  查看登录日志: 'Visit Login Logs',
  该网站已存在: 'The website already exists',
  Linux暂不支持花漾浏览器的快捷方式: 'Linux does not support HuaYoung shortcuts at the moment',
  '您的专属邀请码，通过该邀请码发展用户（该用户必须通过手机注册）后您将获得花瓣激励':
    'Your exclusive invitation code, through the invitation code to develop users (the user must register by mobile phone) after you will receive tokens incentives',
  '您的专属邀请链接（内含邀请码），通过该邀请链接发展用户（该用户必须通过手机注册）后您将获得花瓣激励':
    'Your exclusive invitation link (containing invitation code), through the invitation link to develop users (the user must register by mobile phone) after you will receive tokens incentives',
  '恭喜您获得 {{count}} 花瓣的礼品卡': 'Congratulations on receiving {{count}} tokens gift card',
  '请到个人资料中查看礼品卡相关信息，并可随时激活':
    'Please check the gift card information in your personal profile and activate it at any time.',
  该计划不支持当前选择的执行方式:
    'The plan does not support the current selection of execution methods',
  分身侧边栏: 'Browser side panel',
  发布到浏览器分身: 'Publish to browser avatar',
  '将流程定义内嵌到浏览器分身中（分身所属平台要和流程定义中规定的平台相符），浏览器分身打开后，可直接在浏览器窗口中执行此流程':
    'Embed the process definition into the browser avatar (the platform to which the avatar belongs must match the platform specified in the process definition). After the browser avatar is opened, the process can be executed directly in the browser window',
  已将流程定义发布到指定的浏览器分身:
    'The process has been published to the specified browser avatar',
  '打开此浏览器分身后，可在浏览器窗口中直接执行此流程':
    'After opening this browser avatar, the process can be executed directly in the browser window',
  发布流程定义失败: 'Failed to publish the process',
  '欢迎使用 TikTok 达人邀约与私域管理系统':
    'Welcome to use TikTok talent invitation and private domain management system',
  保存至桌面: 'Save to desktop',
  备忘: 'Remark',
  海外IP网络加速: 'Overseas IP Network Acceleration',
  关于海外IP的网络加速: 'About network acceleration of overseas IP',
  '针对海外IP，通过切换不同的加速通道可以提升访问速度':
    'For overseas IP, switching different acceleration channels can improve access speed',
  '您需要在IP详情页面，开启网络加速，并选择不同的加速通道':
    'You need to open network acceleration on the IP details page and select different acceleration channels',
  '加速通道有免费与收费的区别，请注意区分':
    'There is a difference between free and paid acceleration channels, please pay attention to the distinction',
  '您可以进入“TK店铺达人邀约系统”续费，也可以暂停TK套餐，只使用花漾浏览器继续管理您的店铺':
    'You can renew your subscription by entering the "TK Invitation System", or you can pause the TK package and continue to manage your store using Blossom Browser only',
  '进入{{module}}': 'Enter {{module}}',
  '确定要将当前的出口IP设置为 WebRTC IP吗？':
    'Are you sure you want to set the current remote IP as WebRTC IP?',
  '当前出口 IP 为': 'Current remote IP is',
  '此操作会将当前出口 IP 设置为 WebRTC IP':
    'This operation will set the current exit IP as WebRTC IP',
  '该动态IP支持手动刷新，当访问以下URL时将会更换出口IP':
    'This dynamic IP supports manual refresh, and the exit IP will be changed when accessing the following URL',
  '该动态IP支持会话保持，当变化参数时，可以更换出口IP':
    'This dynamic IP supports session hold, and the exit IP can be changed when the parameter changes',
  该动态IP不支持更换出口IP: 'This dynamic IP does not support changing the remote IP',
  '该动态IP既不支持会话保持，也不支持手动刷新，无法更换其出口IP':
    'This dynamic IP neither supports session hold nor manual refresh, and cannot change its remote IP',
  '打开浏览器失败，请和在线客服联络':
    'Failed to open the browser, please contact online customer service',
  IP隔离设置: 'IP Isolation',
  账号销毁: 'Account Destruction',
  右侧标签页: 'Right Tabs',
  编辑权限: 'Edit Policy',
  允许他人编辑此计划: 'Allow others to edit this plan',
  不允许他人编辑此计划: 'Not allow others to edit this plan',
  '是否要批量{{label}}选定的流程计划？': 'Are you sure to {{label}} selected plan ?',
  '您当前选定了 {{count}} 个流程计划': 'You have selected {{count}} plans',
  '成功执行选定的 {{count}} 个流程计划': 'Successfully executed {{count}} selected plans',
  外圈图片节点: 'Outer Image Node',
  内圈图片节点: 'Inner Image Node',
  旋转图片: 'Rotate Image',
  '请输入接收人的微信OpenId，多个接受人用逗号“,”分隔':
    'Enter the WeChat OpenId, separated by commas ","',
  该输入变量没有高级设置: 'The variable has no advanced settings',
  二维码: 'QR Code',
  微信消息接收者: 'WeChat Message Receiver',
  输入OpenId: 'WeChat OpenId',
  查看二维码: 'View QR Code',
  '（请扫码以接收流程消息，2小时有效）': '(Please scan the code, valid for 2 hours)',
  当前团队不符合充值要求: 'The current team does not meet the recharge requirements',
  当前团队需要完成实名认证才能够进行充值:
    'The current team needs to complete the real name authentication in order to recharge',
  '（已有{{count}}人扫码）': '(Scanned by {{count}} people)',
  必须要有一人扫码以接收流程消息:
    'At least one person must scan the code to receive process messages',
  重置二维码: 'Reset',
  '（清空已有的消息接收者）': '(Clear the existing receiver)',
  微信消息接收者的: "WeChat message receiver's",
  码: 'Code',
  标识码: 'Mark Code',
  标识码底色: 'Mark Code Background',
  标识码设置: 'Mark Code Settings',
  '标识码可以包含英文字母、数字、下划线':
    'The mark code can contain letters, numbers, and underscores',
  长度小于等于3: 'Length less than or equal to 3',
  '在花漾浏览器的任务栏图标上绘制标识符，帮助您更好的区分浏览器分身':
    'Draw an identifier on the taskbar icon of the HuaYoung browser to help you better distinguish the browser avatar',
  无法批量执行他人创建的流程计划: 'Unable to execute process plans created by others in batches',
  '他人创建的流程计划，只能单个执行':
    'Process plans created by others can only be executed individually',
  您已经加入该团队: 'You have joined the team',
  请先注销登录态: 'Please log out first',
  '您已经登录，如需加入该团队，请先注销登录再重新打开此页面':
    'You are already logged in. To join the team, please log out first and then reopen this page',
  设置昵称: 'Set Nickname',
  请设置您在该团队的中的昵称: 'Please set your nickname in the team',
  团队邀请者: 'Team Inviter',
  客户端存储记忆: 'Client Storage Memory',
  '当通过花漾浏览器插件执行此流程时，输入变量的值会被存储至客户端记忆下来，方便再次执行免输变量':
    'When executing this process through the HuaYoung browser plugin, the value of the input variable will be stored in the client for easy execution without re-entering the variable',
  '以流程为维度，存储在客户端': 'Stored on the client side with the process as the dimension',
  '以分身为维度，存储在客户端': 'Stored on the client side with the avatar as the dimension',
  不存储: 'Do not store',
  请至少选择一个平台的指纹模板: 'Please select at least one platform fingerprint template',
  '一个团队可免费下载的指纹模板总数为{{quota}}枚':
    'A team can download a total of {{quota}} fingerprint templates for free',
  安装: 'Install',
  第三方插件: 'Others',
  已安装的插件: 'Installed',
  插件镜像: 'Plugin Image',
  '找不到心仪的插件？': 'Can’t find the plugin you want?',
  去Google应用商店检索: 'Search in Google store',
  上传插件: 'Upload Plugin',
  '提供方：{{provider}}': 'Provider: {{provider}}',
  立即安装: 'Install',
  版本更新: 'Update',
  概述: 'Overview',
  其他信息: 'Other Info',
  产品更新日期: 'Product Update Date',
  开发者网站: 'Developer Website',
  查询最新版本: 'Query Latest Version',
  '每一个浏览器分身在打开时都会加载此处的插件，如个别分身需要自定义安装某些插件，请在分身属性中设置':
    'Every browser avatar will load the plugins here when it is opened. If certain avatars need to install certain plugins, please set them in the avatar properties',
  尚未安装全局插件: 'The global plugin has not been installed',
  '您可以去插件市场挑选安装，也可以通过第三方插件安装':
    'You can go to the plugin marketplace and choose to install it, or you can install it through a third-party plugin',
  '未找到数据，请修改搜索词': 'No data found, modify the search term',
  '如果您自有插件无法提交至Google市场，可上传至此，方便对分身进行批量安装插件':
    'If your own plug-in cannot be submitted to Google Market, you can upload it here to facilitate the batch installation of plug-ins on your clones',
  '确定要删除选中的插件吗？': 'Are you sure you want to remove the selected plugins?',
  '插件一旦被删除，将影响所有的已安装的该插件的浏览器分身':
    'Once the plug-in is removed, it will affect all browser clones of the plug-in that have been installed',
  '正在刷新插件信息，这可能需要几分钟...':
    'Refreshing plugin information, this may take a few minutes...',
  当前插件已经是最新版本: 'The current plugin is already the latest version',
  '选中的插件将复制至“已安装的插件”中，所有的浏览器分身在打开时都会加载这些插件':
    'The selected plug-ins will be copied to the "Installed Plug-ins" and will be loaded by all browser avatars when opened',
  '确定要安装选中的插件吗？': 'Are you sure you want to install the selected plugins?',
  全部卸载: 'Uninstall All',
  '确定要卸载所有的插件吗？': 'Are you sure you want to uninstall all the plugins?',
  '插件一旦被卸载，将影响所有的浏览器分身':
    'Once the plugin is uninstalled, it will affect all browser clones',
  '{{action}}成功': '{{action}} Success',
  卸载: 'Uninstall',
  当前显示字段: 'Show Fields',
  待显示字段: 'Hidden Fields',
  团队成员分身字段同步: 'Global Sync',
  '一旦开启此选项，意味着当前团队所有成员都会采用相同的分身字段设置（只有超管与BOSS才能够开启/关闭此选项）':
    'Once this option is enabled, it means that all members of the current team will use the same avatar field settings (only the super admin and boss can enable/disable this option)',
  '确定要开启同步分身字段的选项吗？':
    'Are you sure you want to enable the option to synchronize avatar fields?',
  '一旦开启意味着当前是团队其它成员的分身字段都会以当前的字段设置为准，请确认是否继续':
    'Once enabled, it means that the avatar fields of other members of the current team will be set according to the current fields, please confirm whether to continue',
  '调整{{type}}视图的显示顺序': 'Adjust the display order of {{type}} view',
  当前显示视图: 'Show views',
  待显示视图: 'Hidden views',
  团队成员视图同步: 'Global Sync',
  '一旦开启此选项，意味着当前团队所有成员都会采用相同的视图设置（只有超管与BOSS才能够开启/关闭此选项）':
    'Once this option is enabled, it means that all members of the current team will use the same view settings (only the super admin and boss can enable/disable this option)',
  '确定要开启同步视图的选项吗？':
    'Are you sure you want to enable the option to synchronize views?',
  '一旦开启意味着当前是团队其它成员的分身视图都会以当前的视图设置为准，请确认是否继续':
    'Once enabled, it means that the current clone view of other team members will be subject to the current view settings, please confirm whether to continue',
  TK达人邀约系统: 'TK Invitation System',
  切换团队: 'Switch Team',
  '确定要暂停TK套餐吗？': 'Are you sure you want to pause your TK package?',
  '一旦暂停TK套餐，意味着：': 'Once the TK package is suspended, it means:',
  您将无法登录TK达人邀约系统: 'You will not be able to log in to the TK Invitation System',
  暂停套餐: 'Pause Package',
  TK套餐已过期: 'The TK package has expired',
  出厂默认值: 'Default value',
  修订为: 'Revised as',
  对某个角色的权限修订会影响所有承担此角色的用户:
    'Permission revisions to a role affect all users in that role',
  继承自角色的权限: 'Role Permissions',
  对用户的权限修订仅会影响该用户自身: 'Permission revisions to a user only affect the user self',
  '清空该笔强制授权记录，默认继承自角色权限':
    'Clear the forced authorization record, default inherit from role permissions',
  '花漾插件市场中的插件全部来源自 Google 插件市场，如未找到您心仪的插件，可随时将其从 Google 市场中镜像到此处':
    'All plugins in the Blossom Plugin Market are sourced from the Google Plugin Market, if you cannot find your favorite plugin, you can always mirror it from the Google Market to here at any time',
  立即镜像: 'Upload Now',
  角色权限: 'Permissions',
  TK店铺自动邀约系统: 'TK Shop System',
  KOL私域管理: 'KOL System',
  强制开启: 'Forced On',
  强制关闭: 'Forced Off',
  强制授权: 'Mandatory',
  '按{{period}}': 'By {{period}}',
  '临期的IP是指将在72小时之内过期的IP，如果您已经开启了IP的自动续费且账户余额充足，请忽略；否则请留意，IP一旦释放无法找回':
    'The IP that expires within 72 hours is the IP that will expire within 72 hours. If you have enabled IP auto-renewal and have sufficient account balance, please ignore; otherwise, please pay attention, the IP will be released and cannot be recovered',
  '开启“可查看并管理团队云盘”的用户默认可以查看团队云盘的所有资源':
    'The user with the "Can View and Manage Team Drive" permission can view all resources in the team cloud drive by default',
  '团队云盘“{{name}}”授权': 'Team Drive "{{name}}" Authorization',
  '如需要注销账号，请点击{{action}}': 'If you need to cancel your account, click {{action}}',
  '系统内置四个角色：超级管理员、BOSS、经理、员工，每个角色默认拥有的权限如下所示；既可以对角色进行权限定义，也允许对承担此角色的用户进行权限调整':
    'The system has four built-in roles: Super Administrator, BOSS, Manager, and Employee, and the default permissions for each role are shown below; you can define the permissions for the role and adjust the permissions for the user who assumes the role.',
  '暂停TK套餐后，您可以继续使用花漾浏览器正常运营您的TK店铺，当有需要时，可以再次续费恢复TK套餐，您的达人数据最多保留3个月':
    'After suspending the TK package, you can continue to use the Blossom Browser to operate your TK store normally, and when necessary, you can renew and resume the TK package again, and your influencer data will be retained for up to 3 months',
  '该成员的角色为“员工”，员工角色是团队内权限最小的功能集合；如果您希望将该成员的权限提升，可在{{action}}中修订该成员的角色身份；如果您希望在员工角色的基础上，只调整当前用户的权限，请修改强制授权':
    "The member's role is \"Employee\", and the employee role is the least privileged set of functions in the team; if you want to elevate the member's privileges, you can modify the member's role identity in {{action}}; if you want to adjust only the current user's privileges based on the employee role, modify the mandatory authorization.",
  '该成员的角色为“经理”，经理角色可管理自己所在及下级组织单元的相关业务；如果您希望调整该成员的权限，可在{{action}}中修订该成员的角色身份；如果您希望在经理角色的基础上，只调整当前用户的权限，请修改强制授权':
    "The member's role is \"Manager\", and the manager role can manage the related business of his/her own and subordinate organizational units; if you want to adjust the member's privileges, you can modify the member's role identity in {{action}}; if you want to adjust only the current user's privileges on the basis of the manager's role, please modify the mandatory authorization.",
  加载资源文件失败: 'Load resource file failed',
  '加载资源文件 {{path}} 失败': 'Load resource file {{path}} failed',
  手机管理: 'Mobile Management',
  清理缓存: 'Clear Cache',
  清理缓存成功: 'Clear cache successfully',
  不支持对联营团队进行实时监管: 'Real-time supervision of affiliate teams is not supported',
  '实时监管只限于对团队内部使用，无法跨团队监管':
    'Real-time monitoring is limited to intra-team use and cannot be monitored across teams',
  不支持对联营团队强制关闭浏览器: 'Forced browser closure for affiliate teams is not supported',
  '强制结束浏览器只限于对团队内部使用，无法跨团队使用':
    'Force End Browser is limited to use within a team and cannot be used across teams.',
  修改平台: 'Change Platform',
  平台类型: 'Platform',
  '针对一些敏感站点，可以控制是否显示其链接与标题':
    'For some sensitive sites, you can control whether to display their links and titles.',
  私密网站: 'Privite Sites',
  标题栏显示文本: 'Title Bar Text',
  地址栏显示文本: 'Address Bar Text',
  '一个浏览器分身能够加载的插件包含在“插件”页签“已安装的插件”，以及在此处添加的插件，注：在此处添加的插件只对本浏览器分身有效':
    'A browser sub-identity can load plugins included in the "Plugins" tab "Installed Plugins" and the plugins added here, note: plugins added here are only valid for this browser sub-identity',
  奖励原因: 'Reason for award',
  奖励时间: 'Bonus time',
  普通团队: 'Normal',
  '普通团队是基于“花瓣”的按量计费模式（花瓣是一种类似于点数的内部结算单位，1元=100花瓣）':
    'Normal teams are based on the "Petal" billing model (Petal is a kind of internal settlement unit similar to points, 1 yuan = 100 tokens)',
  TikTok店铺达人自动邀约与私域管理系统: 'TikTok Private Domain Management System ',
  '通过花漾为您提供的RPA流程，完成TikTok店铺达人自动邀约与私域管理，需购买相应套餐':
    'Complete the automatic invitation and private domain management of TikTok store professionals through the RPA process provided by Flowers, and you need to purchase the corresponding package.',
  '由“{{name}}”创建并分享给您': 'This view was created by "{{name}}" and shared with you',
  添加手机: 'Add Mobile',
  '收藏后的团队会置顶，帮助您快速定位团队':
    'Favorite teams will be top-ranked to help you quickly locate teams',
  输入前先点击元素: 'Click element before input',
  运行时版本与操作系统不匹配: 'Runtime version mismatch',
  '版本不匹配，请重新下载适用于Win10的运行时版本':
    'Version mismatch, pls re-download the runtime version for Win10',
  '浏览器指纹代表着一台电脑或手机的软硬件信息，一个分身必须要绑定一个浏览器指纹才能够正常使用':
    'Browser fingerprint represents the soft and hardware information of a computer or mobile phone, a sub-identity must be bound to a browser fingerprint in order to use it',
  '版本不匹配，请重新下载适用于Win7的运行时版本':
    'Version mismatch, pls re-download the runtime version for Win7',
  '输入店铺的登录信息，花漾为您完成密码代填':
    'Input the login information of the store, HuaYoung will fill in the password for you',
  '输入店铺的登录信息，花漾会为您进行密码代填（登录信息会被高强度加密存储）':
    'Input the login information of the store, HuaYoung will fill in the password for you (the login information will be encrypted and stored with high strength)',
  '针对一些敏感网站（如电商店铺），如果多个分身使用同一个IP访问有可能造成关联风险':
    'For some sensitive websites (such as e-commerce stores), if multiple sub-identities use the same IP to access, it may cause association risk',
  '在花漾中，“浏览器分身”代表的是一个完全隔离的浏览器运行环境，它拥有自己的软硬件特征（暨浏览器指纹）与网络通路（暨IP隔离）；您的每个店铺账号或者社媒账号，都代表着一个浏览器分身':
    'In HuaYoung, "Browser Avatar" represents a completely isolated browser running environment, it has its own software and hardware characteristics (i.e. browser fingerprint) and network channels (i.e. IP isolation); each of your store accounts or social media accounts represents a browser sub-identity',
  '温馨提示，流程执行期间：': 'Friendly reminder, during the process execution:',
  '请不要随意触控手机屏幕，或关闭手机电源':
    'Please do not touch the phone screen at will, or turn off the phone power',
  请不要断开手机与花漾客户端的连线:
    'Please do not disconnect the connection between the phone and client',
  花漾客户端所在电脑不能关机或处于休眠状态:
    'The computer where the HuaYoung client is located cannot be turned off or in sleep mode',
  '由于花漾以“拟人化”方式执行流程，流程执行期间：':
    'Friendly reminder, as HuaYoung RPA executes the process in a "humanized" way, during the process execution:',
  请不要将花漾浏览器最小化或缩放窗口大小:
    'Please do not minimize or resize the HuaYoung browser window',
  '如果花漾浏览器处于置顶状态，可使用其它程序窗口遮挡住，不会影响您正常工作':
    'If the HuaYoung browser is in the top state, in order not to affect your normal work, you can let other application windows cover the HuaYoung browser',
  '注：本商品为 IPv6，并不是所有网站都支持 IPv6（尽管绝大部分主流网站都支持），花漾客户端所在电脑也必须同时支持 IPv6（或使用支持IPv6的花漾接入点）':
    'Note: This product is IPv6, not all websites support IPv6 (although most mainstream websites do), and the computer where the HuaYoung client is located must also support IPv6 (or use a HauYoung access point that supports IPv6)',
  '是否停止群控？': 'Stop group control?',
  '当前选中的商品中包含有 IPv6': 'The current selected product contains IPv6',
  '请注意，并不是所有网站都支持IPv6尽管绝大部分主流网站都支持（花漾客户端所在电脑也必须同时支持IPv6或使用支持IPv6的花漾接入点），请确认是否要继续购买':
    'Please note that not all websites support IPv6, although most mainstream websites support it (the computer where the HuaYoung client is located must also support IPv6 or use a HauYoung access point that supports IPv6), please confirm whether to continue to purchase',
  '禁用 WebRTC': 'Disable WebRTC',
  '动态IP会经常改变出口IP，建议禁用 WebRTC':
    'Dynamic IP will often change the exit IP, it is recommended to disable WebRTC',
  不变: 'Keep',
  修改浏览器指纹: 'Change browser fingerprint',
  AI对话生成: 'AI Generation',
  TK发送邮件: 'TK Send Mail',
  浏览器指纹代表浏览器所在电脑的软硬件信息:
    'Browser fingerprint represents the soft and hardware information of the computer where the browser is located',
  打开花漾浏览器时的默认首页设置: 'Default homepage settings when opening HuaYoung browser',
  'Cookie 数据过多': 'Cookie data is too much',
  '选中的 Cookie 数据过多，建议您直接下载数据文件':
    'The selected cookie data is too much, it is recommended that you download the data file directly',
  下载Cookie文件: 'Download Cookie',
  继续文本展示: 'Text Display',
  '导入的Cookie数据文件过大，不在这里显示具体内容，点击确定后将直接导入到分身属性':
    'The imported cookie data file is too large, the specific content is not displayed here, click OK to import directly into the avatar properties',
  未开启海外IP网络加速: 'Overseas IP network acceleration is not enabled',
  '如何开启？': 'How to enable?',
  '{{P_NAME}}升级完毕': '{{P_NAME}} upgrade completed',
  '{{P_NAME}}正在升级': '{{P_NAME}} is upgrading',
  重新启动以完成升级工作: 'Restart to complete',
  群控快捷键: 'Group control shortcut',
  '群控启动/暂停的快捷键': 'Shortcut key for group control start/stop',
  '打开分身“{{shopName}}"出现错误，请复制下述错误信息，并反馈给在线客服：':
    'An error occurred when opening the avatar "{{shopName}}", please copy the following error information and feedback to online customer service:',
  '请复制下述错误信息，并反馈给在线客服：':
    'Please copy the following error information and feedback to online customer service:',
  缺省设置: 'Default Settings',
  浏览器指纹缺省设置: 'Browser fingerprint default settings',
  无痕分身缺省设置: 'Stateless avatar default settings',
  '修改{{targetLabel}}分身的所属平台': 'Change the platform of the {{targetLabel}} avatar',
  浏览器分身缺省设置: 'Avatar default settings',
    KOL私域管理系统已过期: 'KOL system has expired',
  '请您和在线客服联系，进行续费操作': 'Please contact the online customer service  to renew your account.',
  KOL私域管理系统: 'KOL system',
  您的团队已经被禁用: 'Your team has been blocked',
  '您的团队已被禁用，无法执行任何操作，请和在线客服联络': 'Your team has been blocked and cannot perform any operations. Please contact online customer service.',
  '将清除旧有的授权关系，请确认是否要继续': 'The old authorization relationship will be cleared. Please confirm whether you want to continue.',
  输入用户昵称进行检索: 'Search by nickname',
  找回密码: 'Reset your password',
  '在使用花漾之前，您需要创建/加入一个团队：': 'Before using HuaYang, you need to create/join a team',
  '创建一个团队来管理您的资源：': 'Create a team to manage your resources',
  '请输入团队名称！': 'Please enter a team name!',
  分身: 'Avatar',
  '如：我的*IP': 'For example: My *IP',
  Add: 'Add',
  重新生成指纹: 'Regenerate fingerprint',
  浏览器指纹硬件参数: 'Browser fingerprint hardware parameters',
  请选择内存大小: 'Please select the memory size',
  请指定时区: 'Please specify time zone',
  指定时区: 'Please specify time zone',
  浏览器指纹分辨率: 'Browser fingerprint resolution',
  '只会影响选中的 Android 浏览器指纹': 'Only affects selected Android browser fingerprints',
  '只会影响全部的 Android 浏览器指纹': 'Affects all Android browser fingerprints',
  'WebRTC 设置': 'WebRTC Setup',
  请输入关键字: 'Please enter keywords',
  选择团队: 'Select team',
  手机账号: 'Mobile phone account',
  团队: 'Team',
  我: 'I',
  '非常抱歉，系统遇到了中断性错误，您可以进行强力修复：': 'We are sorry, but the system has encountered a disruptive error. You can perform a force repair',
  帮我关闭所有的花漾浏览器进程: 'Help me close all the HuaYang browser processes',
  帮我重新解压缩花漾浏览器内核: 'Help me re-decompress the HuaYang browser kernel',
  帮我清空此分身的所有缓存数据: 'Help me clear all cached data for this avatar',
  强力修复: 'Force repair',
  花漾技术支持: 'HuaYang Technical Support',
  已经发起申请: 'Application has been initiated',
  '全部 UA 版本': 'All UA Versions',
  登录TIkTok店铺达人自动邀约与私域管理系统完成套餐的购买: 'Log in to https://tk.szdamai.com to purchase',
  '系统会打开浏览器并登录TikTok达人自动邀约与私域管理系统，请您根据TikTok店铺数量购买相应的套餐；或者您可以跟在线客服联络，申请免费试用':
    'This will open https://tk.szdamai.com. Please purchase the corresponding package according to the number of TikTok stores,or you can contact the online customer service to apply for a free trial',
  '请打开{{action}}进行修订': 'Please open {{action}} for revision',
  '恭喜，您已成功加入团队“{{name}}”': 'Congratulations, you have successfully joined the team "{{name}}"',
  '花漾灵动基于"阿米巴"的多团队经营管理模式，您可以创建/加入多个团队，并在不同团队之间自如切换':
    'HuaYang is based on the multi-team management model of "Amoeba". You can create/join multiple teams and switch between different teams freely.',
  '不同团队之间的资源是隔离的，但可以通过分身转让、分身联营、快捷方式等手段实现不同团队之间的资源共享与协作':
    'The resources between different teams are isolated, but resource sharing and collaboration between different teams can be achieved through means such as avatar transfer, avatar joint operation, shortcuts, etc.',
  依据名称或ID检索: 'Search by name or ID',
  没有符合条件的团队: 'No matching teams found',
  您本人创建的视图: 'Views you created',
  '由＂{{name}}＂创建并分享给您': 'Created and shared with you by "{{name}}"',
  流程计划: 'RPA Plan',
  流程任务: 'RPA Task',
  内置到浏览器的流程: 'Built-in RPA flow',
  云盘: 'Cloud Disk',
  您有权限访问的所有浏览器分身: 'All browser avatars you have access to',
  '您有权限访问的所有平台类别为”电商平台“的浏览器分身，如Amazon/eBay/TikTok小店等': 'All browser avatars of the platform category "e-commerce platform" that you have access to, such as Amazon/eBay/TikTok store, etc.',
  '您有权限访问的所有平台类别为”社交媒体“的浏览器分身，如抖音/知乎/TikTok等': 'All browser avatars of platforms that you have access to and are classified as "social media", such as Douyin/Zhihu/TikTok, etc.',
  没有绑定IP地址的浏览器分身: 'Browser avatars without IP addresses',
  没有明确授权的浏览器分身: 'Browser avatars without explicit authorization',
  以您名下收藏的浏览器分身: 'Your favorite browser avatars',
  可对打开的浏览器分身窗口进行排列布局与群控操作: 'The opened browser avatar windows can be arranged and group controlled',
  "删除的浏览器分身会在回收站中保留7天": "Deleted browser avatars will be kept in the Recycle Bin for 7 days",
 "IP地址": "IP address",
 "打开手机查看详情": "Open your phone to view details",
 "设备ID": "Device ID",
 "立即审批": "Immediate approval",
 "当前团队的花瓣余额必须大于所转让手机一天的花瓣消耗量才允许接收手机": "The current team's token balance must be greater than the daily token consumption of this mobile phone,otherwise you can not receive it.",
 "手机名称": "Phone Name",
 "手机数量": "Phone Number",
 "{{count}}个": "{{count}}",
 "任务详情": "Task Details",
 "批量下载审计录像": "Batch download of audit videos",
 "Quantity": "Quantity",
 "-不限": "-Unlimited",
 "-指定城市": "-Specified city",
 "购买动态、": "Purchase dynamic,",
 "的": "of",
 "您购买的商品中包含有“CPU<=4核”的流程任务卡": "The product you purchased contains a RPA task card with \"CPU <= 4 cores\"",
 "流程任务卡能够绑定的主机类型必须与CPU核数相匹配，否则将绑定失败，请确认待绑定主机的CPU核数<=4": "The RPA task card must match the number of CPU cores, otherwise the binding will fail. Please confirm that the number of CPU cores of the host to be bound is <= 4",
 "变更成功": "Change successful",
 "{{count}}张流程任务卡": "{{count}} RPA task cards",
 "对数据列表进行排序": "Sort a list of data",
 "长度为8~40个字符，需至少包含以下四类字符中的三类：大写字母、小写字母、数字、特殊符号": "The length is 8 to 40 characters and must contain at least three of the following four types of characters: uppercase letters, lowercase letters, numbers, special symbols",
 "韩语-한국어": "Korean - 한국어",
 "泰语-ไทย": "Thai - ไทย",
 "越南语-TiếngViệt": "Vietnamese - Tiếng Việt",
 "内核版本": "Kernel version",
 "安装位置": "Installation location",
 "该浏览器正处于打开的状态": "The browser is currently open",
 "请关闭相应的浏览器后再进行删除": "Please close the corresponding browser before deleting",
 "确定要删除选中的浏览器吗？": "Are you sure you want to delete the selected browsers?",
 "花漾客户端同时支持不同内核版本的浏览器": "HuaYang client supports browsers with different kernel versions",
 "文件所在位置": "File location",
 "正在{{action}}": "{{action}}",
 "账号注销": "Account logout",
 "在流程定义编辑器中运行预览RPA流程时，流程引擎生成的文件的默认存储位置": "The default storage location for files generated by the RPA engine when you run and preview an RPA process in the process definition editor",
 "花漾门户的缓存信息，当发觉花漾客户端无法正常工作时，可以考虑进行清理": "The cache of the HuaYang portal. If you find that the HuaYang client does not work properly, you can consider cleaning it.",
 "存储位置": "Storage Location",
 "录像文件": "Video files",
 "RPA运行预览文件": "RPA running preview file",
 "磁盘空间总容量": "Total disk space",
 "其他文件": "Other files",
 "剩余可用空间": "Remaining free space",
 "清空缓存": "Clear the cache",
 "更改存储位置": "Changing the storage location",
 "打开存储位置": "Open storage location",
 "在线": "Online",
 "当前设备标识": "Current device ID",
 "当前花漾客户端的设备标识，全局唯一": "The device ID of the current HuaYang client, globally unique",
 "设备是否随操作系统而自动启动花漾客户端，请注意，针对 Windows 操作系统，需要关闭账户控制（UAC）": "Whether the device automatically starts the HuaYang client with the operating system. Please note that for Windows operating systems, you need to turn off the User Account Control (UAC)",
 "需关闭 Windows UAC": "Windows UAC needs to be turned off",
 "花漾客户端在访问花漾门户服务器时的网络设置": "Network settings of HuaYang client when accessing HuaYang portal",
 "群控主窗口边框": "Group control main window border",
 "群控时颜色": "Group control Color",
 "暂停时颜色": "Pause Color",
 "新设备登录": "New device login",
 "手机联营": "Mobile phone joint operating",
 "自动化": "Automation",
 "插件": "Plugins",
 "Kakao消息同步": "Kakao message synchronization",
 "手机联营确认": "Mobile phone alliance confirmation",
 "KOL消息通知": "KOL message notification",
 "企业级": "Enterprise",
 "IP质量测试": "IP Quality Test",
 "WebRTC 是分身所绑定的浏览器指纹的一个属性，考虑到动态IP经常变化出口IP，建议关闭 WebRTC": "WebRTC is a property of the browser fingerprint bound to the avatar. Considering that the dynamic IP often changes the egress IP, it is recommended to turn off WebRTC",
 "经纬度是分身所绑定的浏览器指纹的一个属性，考虑到动态IP经常变化出口IP，建议关闭经纬度": "Longitude and latitude are attributes of the browser fingerprint bound to the avatar. Considering that the dynamic IP often changes the egress IP, it is recommended to turn off longitude and latitude.",
 "请下载花漾客户端程序，花漾客户端内置花漾浏览器，只有通过花漾客户端才能够打开浏览器": "Please download the HuaYang client program. The HuaYang client has a built-in HuaYang browser. The browser can only be opened through the HuaYang client.",
 "花漾客户端有新版本，建议立即升级": "There is a new version of HuaYang client, it is recommended to upgrade immediately",
 "花漾客户端新版本已经准备就绪，强烈建议您立即升级，整个过程大概需要持续1分钟左右的时间": "The new version of HuaYang client is ready. It is strongly recommended that you upgrade immediately. The whole process will take about 1 minute.",
 "书签顺序设置": "Bookmark order settings",
 "请通过拖拽改变书签的顺序": "Please change the order of bookmarks by dragging",
 "导入书签": "Import Bookmarks",
 "未找到书签数据": "Bookmark data not found",
 "可将Chrome、Edge等浏览器的书签导出成一个 html 文件，然后将其导入至花漾": "You can export bookmarks from Chrome, Edge and other browsers into an html file, and then import it into HuaYang",
 "请选择书签文件": "Please select a bookmark file",
 "导入后的存储位置": "Storage location after import",
 "书签栏": "Bookmarks Bar",
 "全局共享书签": "Global shared bookmarks",
 "个人私有书签": "Personal bookmarks",
 "该分身绑定的指纹内核版本过高": "The kernel version bound to this avatar's fingerprint is too high",
 "Win7最高只支持109内核，打开过高内核版本的浏览器分身可能会存在一定风险，确信要继续打开吗？": "Win7 only supports up to 109 kernels. Opening it with a higher kernel version may be risky. Are you sure you want to continue opening it?",
 "Chrome内核已经不再维护Win7版本，强烈建议您升级至Win10/11": "The Chrome kernel no longer maintains the Win7 version. It is strongly recommended that you upgrade to Win10/11",
 "建议您升级花漾客户端最新版本": "It is recommended that you upgrade to the latest version of the HuaYang client",
 "此分身绑定的指纹包含有较高版本的UA，当前花漾客户端版本较低，如果强行打开此分身可能会带来风险，强烈建议您升级花漾客户端最新版本": "The fingerprint bound to this avatar contains a higher version of UA. The current HuaYang client version is lower. If you forcefully open this avatar, it may bring risks. It is strongly recommended that you upgrade to the latest version of HuaYang client",
 "查看版本信息": "View version information",
 "继续打开": "Continue Open",
 "下载对应内核的花漾浏览器": "Download the corresponding kernel of HuaYang browser",
 "当前分身绑定的浏览器指纹包含的 User Agent 版本与花漾客户端内置的浏览器版本不匹配，需要下载相匹配的浏览器才能够打开此分身": "The User Agent version contained in the browser fingerprint bound to the current avatar does not match the browser version built into the HuaYang client. You need to download a matching browser to open this avatar.",
 "修订浏览器指纹的 User Agent 至指定版本": "Revise the browser fingerprint User Agent to the specified version",
 "通常而言，升级浏览器指纹的 User Agent 至指定版本是一种更安全的做法，但如果当前团队有用户依旧使用 Win 7 版本，则不建议升级指纹，因为 Win 7 版本支持的 Chrome 内核最高至 109 版本": "Generally speaking, it is safer to upgrade the browser fingerprint User Agent to a specified version. However, if the current team still has users using the Win 7 version, it is not recommended to upgrade the fingerprint, because the Win 7 version supports Chrome kernels up to version 109.",
 "已下载的浏览器版本": "Downloaded browser version",
 "当前分身开启了独占式访问，正在被其它用户访问": "The current avatar has exclusive access enabled and is being accessed by other users",
 "开启了独占式访问，正在被其它用户访问": "Exclusive access is enabled and is being accessed by other users",
 "未设置指纹": "Fingerprint not set",
 "以下{{count}}个分身无法打开会话": "The following {{count}} avatars cannot open a session",
 "原因": "reason",
 "{{count}}个分身绑定的主IP的配额流量已用完，本次访问将消耗花瓣余额": "The quota traffic of the main IP bound to {{count}} avatars has been consumed. This visit will consume the balance of Token",
 "下列分身绑定的主IP的配额流量已用完，如果继续访问，将产生花瓣消耗，请确保花瓣余额大于{{amount}}": "The quota traffic of the main IP bound to the following avatar has been consumed. If you continue to access, Tokens will be consumed. Please ensure that the Tokens balance is greater than {{amount}}",
 "分身绑定的指纹内核版本过高": "The kernel version bound to the avatar's fingerprint is too high",
 "分身绑定的指纹包含有较高版本的UA，当前花漾客户端版本较低，如果强行打开此分身可能会带来风险，强烈建议您升级花漾客户端最新版本": "The avatar's fingerprint contains a higher version of UA. The current version of the HuaYang client is lower. If you forcefully open this avatar, it may bring risks. It is strongly recommended that you upgrade to the latest version of the HuaYang client",
 "以下分身的浏览器指纹需要升级，或者下载对应内核的花漾浏览器": "The following browser fingerprints need to be upgraded, or the corresponding kernel of HuaYang browser needs to be downloaded",
 "分身的缓存数据过大，将导致打开浏览器极为缓慢": "If the cached data of the avatar is too large, it will cause the browser to open very slowly.",
 "以下{{count}}个分身打开会话失败": "The following {{count}} avatars failed to open a session",
 "IP加速通道": "IP Acceleration Channel",
 "检测到 WebRTC 的 IP 和实际的出口IP不符，这通常是因为动态IP更换出口IP导致的，可手动更新 WebRTC IP": "The detected WebRTC IP does not match the actual egress IP. This is usually caused by the dynamic IP changing the egress IP. You can manually update the WebRTC IP.",
 "是否上传当前浏览器的缓存数据至云端？": "Do you want to upload the current browser's cache data to the cloud?",
 "上传的数据仅限于在分身属性“云端同步策略”中开启同步的数据，上传后其它用户打开此分身时将使用最新的缓存数据": "The uploaded data is limited to the data that is enabled in the avatar's properties \"Cloud Synchronization Strategy\". After uploading, other users will use the latest cached data when they open this avatar.",
 "正在为您上传缓存数据": "Uploading cached data for you",
 "成功上传缓存数据至云端": "Successfully uploaded cache data to the cloud",
 "您的缓存数据已经成功上传至云端，其它用户打开此分身时将使用最新的缓存数据": "The browser cache data has been successfully uploaded to the cloud. When other users open this avatar, they will use the latest cache data.",
 "上传缓存数据": "Upload cache data",
 "如果重新测试发觉也无法连通，请点击“错误日志”以获得进一步的信息": "If you still cannot connect after retesting, please click \"Error Log\" to get further information.",
 "此分身绑定的 IP 无法连接": "The IP bound to this avatar cannot be connected",
 "以下是错误异常，请复制异常信息和您的IP服务商联络": "The following is an error exception. Please copy the exception information and contact your IP service provider.",
 "非常抱歉，此分身绑定的 IP 出现了一些问题导致无法正常连接": "We are very sorry, there are some problems with the IP bound to this avatar, which makes it cannot be connected.",
 "以下是错误异常，请复制异常信息和在线客服联络": "The following are errors and exceptions. Please copy the exception information and contact online customer service.",
 "会话ID": "Session ID",
 "本地代理类型": "Local Proxy Type",
 "指定的代理IP“{{sourceIp}}”与检测机构所检测到的IP“{{remoteIp}}”不符": "The specified proxy IP \"{{sourceIp}}\" does not match the IP \"{{remoteIp}}\" detected.",
 "费用": "Cost",
 "电子发票": "Electronic Invoice",
 "请将电子发票发送至": "Please send electronic invoices to",
 "电子发票（普通发票）": "Electronic invoice (ordinary invoice)",
 "RPA智能AI": "RPA Intelligent AI",
 "RPA验证码识别": "RPA verification code recognition",
 "分身访问日志": "Access log of avatar",
 "加速通道流量": "Accelerate channel traffic flow",
 "花瓣数量>=": "Number of Tokens>=",
 "关于账户余额": "About account balance",
 "账户余额主要用来对从花漾中购买的平台IP进行自动续费": "The account balance is mainly used to automatically renew the platform IP purchased from HuaYang.",
 "当您购买了平台IP且开启了自动续费时，请确保账户余额充足，在IP释放前24小时，系统会自动使用账户余额用于平台IP的续费": "When you purchase a platform IP and enable automatic renewal, please ensure that your account balance is sufficient. 24 hours before the IP is released, the system will automatically use the account balance to renew the platform IP.",
 "昨日消耗": "Yesterday's consumption",
 "未购买平台IP": "No platform IP purchased",
 "有{{count}}个未开启自动续费的IP即将过期": "{{count}} IPs without automatic renewal are about to expire",
 "即将到期的IP": "IPs about to expire",
 "账户余额不足": "Insufficient account balance",
 "IP一旦释放无法找回": "Once an IP is released, it cannot be retrieved",
 "平台IP运行良好，请放心使用": "The platform IP runs well, please feel free to use it",
 "Personal": "Personal",
 "Market": "Market",
 "Shared": "Shared",
 "File": "File",
 "Draft": "Draft",
 "执行人": "Executor",
 "共{{total}}个活跃会话": "Total {{total}} active sessions",
 "确定要结束所有的浏览器会话吗？": "Are you sure you want to end all browser sessions?",
 "此操作会关闭当前团队内所有的活跃会话": "This operation will close all active sessions in the current team",
 "全部结束": "All Over",
 "花漾 RPA（暨“机器人流程自动化”）是花漾灵动重要的组成部分，通过花漾 RPA 能够将大量枯燥的工作化繁为简，使您以一当百，大幅提升您的工作效率": "HuaYang RPA (also known as \"Robotic Process Automation\") is an important part of HuaYang Lingdong. Through HuaYang RPA, a lot of boring work can be simplified, allowing you to do a hundred things with one person, greatly improving your work efficiency",
 "TK店铺的创建，以及达人邀约、批量私信等自动化流程的发起均通过TK达人管理系统完成，现在，请您进入TK达人管理系统完成TK店铺的创建": "The creation of TK stores, as well as the initiation of automated processes such as talent invitations and batch private messages are all completed through the TK talent management system. Now, please enter the TK talent management system to complete the creation of the TK store.",
 "进入TK达人管理系统，创建TK店铺": "Enter the TK Talent Management System and create a TK store",
 "安装成功": "Installation Successful",
 "确定要卸载选中的插件吗？": "Are you sure you want to uninstall the selected plugin?",
 "卸载成功": "Uninstall successfully",
 "已安装": "Installed",
 "插件扩展的云端同步策略": "Cloud synchronization strategy for plugin extensions",
 "当开启插件扩展的云端同步后，在所有的分身、所有的用户之间维持相同的插件状态，但有些插件的信息通过 IndexedDB 维持，需要同步开启 IndexedDB 的云端同步": "When cloud synchronization of plugin extensions is enabled, the same plugin status is maintained between all avatars and all users. However, some plugin information is maintained through IndexedDB, so cloud synchronization of IndexedDB needs to be enabled simultaneously.",
 "已安装的分身": "Installed avatars",
 "{{count}}个分身": "{{count}} avatars",
 "同步策略": "Synchronization strategy",
 "提交插件": "Submit plugin",
 "花漾的插件体系与Chrome浏览器完全兼容，您只需要输入Google市场中插件的地址，即可将其提交至花漾插件市场；需要提醒您的是，您提交的插件必须遵循相关法律、法规的规定，云上悦动保留审核并追责的权利": "HuaYang's plug-in system is fully compatible with the Chrome browser. You only need to enter the address of the plug-in in the Google Market to submit it to the HuaYang plug-in market. Please note that the plug-in you submit must comply with relevant laws and regulations. Yunshang Yuedong reserves the right to review and pursue responsibility.",
 "刷新失败": "Refresh failed",
 "未配置开发者网站": "Developer website not configured",
 "文件预览": "File Preview",
 "浏览器指纹采集器": "Browser Fingerprint Collector",
 "针对海外IP可选择是否开启加速通道，以及在加速通道之间的切换策略": "For overseas IP addresses, you can choose whether to enable the acceleration channel, as well as the switching strategy between acceleration channels.",
 "代理内容/格式不正确": "Incorrect proxy content/format",
 "支持以文本方式快速导入，常用格式：": "Supports quick import in text format, common formats:",
 "跳过检测": "Skip detection",
 "当前代理信息无法联通": "The current proxy information cannot be connected",
 "是否继续导入": "Continue importing",
 "继续导入": "Continue importing",
 "修改临时IP": "Modify temporary IP",
 "绑定临时IP": "Bind temporary IP",
 "支持导入Socks5、Http/Https、SSH等代理协议的IP地址；如果代理服务器开启了IP白名单，请根据连接方式将本机IP或者花漾接入点IP纳入到IP白名单": "Supports importing IP addresses of proxy protocols such as Socks5, Http/Https, SSH, etc. If the proxy server has enabled the IP whitelist, please add the local IP or the HuaYang access point IP to the IP whitelist according to the connection method.",
 "静态IP是指出口IP”几乎“固定不变，通常适用于对账号的长期稳定运营": "Static IP refers to an export IP that is \"almost\" fixed and usually suitable for long-term stable operation of an account.",
 "动态IP是指出口IP经常发生变化，通常适用于新账号注册或对网站的数据抓取等场景": "Dynamic IP refers to the frequent changes in the export IP, which is usually applicable to scenarios such as new account registration or data crawling of websites.",
 "登陆账号不能为空": "Login account cannot be empty",
 "解析代理地址的IP协议": "Resolve the IP protocol of the proxy address",
 "处于转让状态的IP不能删除": "IP in the transfer state cannot be deleted",
 "处于准备状态的IP不能删除": "IP in preparation state cannot be deleted",
 "当前IP转让中，无法继续操作": "The current IP is being transferred and the operation cannot be continued",
 "批量设置IP的加速通道": "Batch set IP acceleration channel",
 "批量设置平台IP的加速通道": "Batch set platform IP acceleration channels",
 "批量设置自有IP的加速通道": "Batch set customer IP acceleration channels",
 "当前选中的既有自有IP，也有平台IP，需要提醒您的是，针对自有IP和平台IP设置的加速通道是不同的，您需要分别设置": "Currently selected are both customer IP and platform IP. Please note that the acceleration channels set for customer IP and platform IP are different. You need to set them separately.",
 "已发送手动刷新的请求": "A manual refresh request has been sent",
 "手动刷新的请求发送失败": "Manual refresh request failed",
 "名称后缀": "Name suffix",
 "名称表达式": "Name Expression",
 "忽略IP检测": "Ignore IP detection",
 "为导入的IP批量命名": "Batch name the imported IPs",
 "导入成功{{successCount}}个，失败{{errorCount}}个": "{{successCount}} items were imported successfully, and {{errorCount}} items failed",
 "您还没有IP资源": "You have no IP yet",
 "设置全部IP的加速通道": "Set up acceleration channels for all IPs",
 "根据IP数量的不同，这可能会是一个非常耗时的操作，请确认是否继续": "Depending on the number of IP addresses, this may be a time-consuming operation. Please confirm whether to continue.",
 "网络加速设置": "Network acceleration settings",
 "您还有未结束的全量测速任务": "You still have unfinished full speed test tasks",
 "是否要对所有的IP进行质量测试？": "Do you need to perform quality testing on all IPs?",
 "{{count}}个IP的质量测试已完成": "Quality testing of {{count}} IPs completed",
 "请直接在IP列表或详情中查看每个IP的测速结果": "Please check the speed test results of each IP directly in the IP list or details",
 "正在对{{count}}个IP进行质量测试，已完成{{percent}}%": "Quality testing is being performed on {{count}} IPs, {{percent}}% completed",
 "正在对{{count}}个IP进行质量测试，请稍候...": "Quality testing is being performed on {{count}} IPs. Please wait...",
 "隐藏至后台运行会形成一笔任务，可在任务中查看执行细节": "Hiding in the background will create a task, and you can view the execution details in the task",
 "全量操作": "Full operation",
 "正在获取商品价格": "Getting product prices",
 "暂不支持变更续费周期": "Changing the renewal period is not supported yet",
 "当前IP续费周期为月，暂不支持变更续费周期": "The current IP renewal period is monthly, and changing the renewal period is not supported yet.",
 "花漾为您提供的免费加速通道，由若干加速线路组成": "HuaYang provides you with a free acceleration channel, which consists of several acceleration lines.",
 "花漾为您提供的VIP收费加速通道，会根据实际消耗的流量扣除一定数量的花瓣": "HuaYang provides you with a VIP paid acceleration channel, which will deduct a certain number of Tokens based on the actual traffic consumption.",
 "当前团队自有 IP 不支持网络加速设置": "The current team's customer IP does not support network acceleration settings",
 "允许当前IP使用以下连接方式：": "Allow the current IP to use the following connection methods:",
 "IP连接方式的切换策略：": "IP connection mode switching strategy:",
 "默认由客户端直接连接IP，只有当直连不通时才会在当前允许的连接方式内，按照收费标准由低到高依次尝试": "By default, the client connects directly to the IP. Only when the direct connection fails will it try the currently allowed connection methods in order of charging standards from low to high.",
 "自有IP加速通道": "Own IP acceleration channel",
 "平台IP加速通道": "Platform IP acceleration channel",
 "正在获取最新的加速通道信息...": "Getting the latest acceleration channel information...",
 "如需使用海外IP加速通道，请和在线客服联系": "If you need to use overseas IP acceleration channel, please contact online customer service",
 "只有选中的加速通道才会访问你的IP（适用于IP白名单）：": "Only the selected acceleration channel will access your IP (applicable to IP whitelist):",
 "平台IP无需设置白名单": "No need to set up a whitelist for platform IP",
 "可将上述IP加入到IP服务商的白名单": "The above IP can be added to the whitelist of IP service providers",
 "正在寻找可用接入点...": "Searching for available access points...",
 "IP流量包含自身流量以及通过加速通道产生的加速流量，可在IP流量日志中查看每一笔流量的细节": "IP traffic includes its own traffic and the accelerated traffic generated by the acceleration channel. The details of each traffic can be viewed in the IP traffic log.",
 "商品规格为“高性价比”的平台IP在购买周期内可免费使用的流量称之为流量配额，超过配额时将按照实际超额流量扣除花瓣": "The free traffic that can be used by platform IPs with product specifications of \"high cost performance\" during the purchase period is called traffic quota. When the quota is exceeded, the Tokens will be deducted according to the actual excess traffic.",
 "商品规格为“企业级”的平台IP在购买周期内不限制流量，但当超过一定流量额度时，可能会产生网络限速": "Platform IPs with \"enterprise-level\" product specifications have no traffic restrictions during the purchase period, but when a certain traffic quota is exceeded, network speed restrictions may occur",
 "纯净度": "Purity",
 "IPGO设置": "IPGO Settings",
 "主机名称": "Host Name",
 "导入规则": "Import rules",
 "全部导入": "Import All",
 "只导入IPv4": "Import only IPv4",
 "只导入IPv6": "Import only IPv6",
 "手动指定": "Manually specify",
 "网卡列表": "Network card list",
 "网卡名称": "Network card name",
 "MAC地址": "MAC Address",
 "内网地址": "Intranet address",
 "{{action}}指令已发出": "{{action}} command has been issued",
 "汇报": "report",
 "确定要重启选中的IPGO吗？": "Are you sure you want to restart the selected IPGO?",
 "此操作可能会导致30秒左右的服务中断": "This operation may cause a service interruption of about 30 seconds.",
 "重启": "Restart",
 "确定要卸载选中的IPGO吗？": "Are you sure you want to uninstall the selected IPGO?",
 "此操作会自动删除通过此IPGO导入的IP地址": "This operation will automatically delete the IP address imported through this IPGO",
 "导入的IP": "Imported IP",
 "{{count}}个IP": "{{count}} IPs",
 "主机规格": "Host Specifications",
 "安装时间": "Installation time",
 "最近心跳时间": "Recent heartbeat time",
 "已停止": "Stopped",
 "已卸载": "Uninstalled",
 "强制汇报": "Mandatory reporting",
 "IPGO列表字段自定义": "IPGO list field customization",
 "根据IP搜索": "Search by IP",
 "设置列表字段": "Setting List Fields",
 "可在此处管理所有由当前团队自行安装的IPGO以及由其导入的IP地址": "All IPGO installed by the current team and the IP addresses imported by it can be managed here",
 "IPGO是由花漾灵动自主研发的主机代理软件，在主机/VPS中安装IPGO，可将其IP地址导入至花漾": "IPGO is a host proxy software independently developed by HuaYang Lingdong. Install IPGO on the host/VPS to import its IP address into HuaYang.",
 "导入VPS/云主机的IP地址": "Import the IP address of the VPS/Cloud Server",
 "请更换检索条件": "Please change the search conditions",
 "请选择要操作的IPGO": "Please select the IPGO you want to operate",
 "批量重启": "Batch Restart",
 "批量汇报": "Batch reporting",
 "批量卸载": "Batch Uninstall",
 "当前团队内所有IP地址": "All IP addresses in the current team",
 "当前团队内所有的静态IP": "All static IPs in the current team",
 "当前团队内所有的动态IP": "All dynamic IPs in the current team",
 "未绑定任何一个浏览器分身的IP地址": "Not bound to any browser avatar's IP address",
 "在花漾内购买的公有云主机的IP地址": "The IP address of the public cloud server purchased in HuaYang",
 "联营IP": "Affiliate IP",
 "和联营的浏览器分身一起带过来的IP地址，您可能需要为这些IP地址进行续费操作": "You may need to renew the IP addresses brought by the browser avatars of the affiliate.",
 "72小时内即将过期并被销毁的公有云主机（IP一旦释放无法找回）": "Public cloud hosts that will expire and be destroyed within 72 hours (IP cannot be retrieved once released)",
 "无法连通或无法提供服务的IP地址": "IP addresses that are unreachable or unavailable for service",
 "当IP服务商允许用户以API的形式批量提取IP地址时，我们通常把它包装成一个IP池，方便您的使用": "When IP service providers allow users to extract IP addresses in batches in the form of APIs, we usually package it into an IP pool for your convenience.",
 "该IP正在转让给其它团队，无法执行操作": "The IP is being transferred to another team and the operation cannot be performed.",
 "编辑IP": "Edit IP",
 "为平台IP续费": "Renew the platform IP",
 "联营IP是指随同联营分身带过来的IP地址，只能对其进行续费，无法执行其它操作": "Affiliate IP refers to the IP address brought along with the affiliate avatar. You can only renew it, and cannot perform other operations",
 "关于{{about}}，希望以下信息可以帮到您": "Regarding {{about}}, I hope the following information can help you",
 "您购买的商品中包含有“高性价比”规格的IP，这些IP有流量配额（通常为1GB每周或5GB每月），使用过程中如果超过流量配额，会根据超额的流量扣除花瓣，而“企业级”规格的IP则不限流量，请根据您的使用情况自行斟酌是否继续购买": "The product you purchased includes \"cost-effective\" IPs. These IPs have traffic quotas (usually 1GB per week or 5GB per month). If you exceed the traffic quota during use, Tokens will be deducted based on the excess traffic. \"Enterprise\" IPs have unlimited traffic. Please consider whether to continue purchasing based on your usage.",
 "请勾选并同意购买云主机的注意事项": "Please check and agree to the precautions for purchasing cloud hosting",
 "Product Name": "Product Name",
 "Duration": "Duration",
 "有流量配额限制，在购买周期内超过流量配额将按照实际产生的流量扣除一定数量的花瓣，价格非常优惠，适用于入门级用户，如需不限流量请购买企业版": "There is a traffic quota limit. If the traffic quota is exceeded during the purchase period, a certain number of Tokens will be deducted according to the actual traffic generated. The price is very favorable and suitable for entry-level users. If you need unlimited traffic, please purchase the Enterprise Edition",
 "不限流量，但在超过流量配额后会导致运营商带宽限速": "Unlimited traffic, but exceeding the traffic quota will cause the operator's bandwidth to limit the speed",
 "同一商品单次购买数量不要大于10": "The single purchase quantity of the same product should not exceed 10",
 "为确保所购商品的库存数量，建议您同一商品的单次购买数量不要大于10，如果您有数量方面的需求，可分成多次购买": "To ensure the inventory of the purchased goods, it is recommended that you do not purchase more than 10 of the same product at a time. If you have quantity requirements, you can purchase them in multiple batches.",
 "分身管理日志": "Avatar Mgmt Log",
 "团队管理日志": "Team Mgmt Log",
 "成员登录日志": "Member Login Log",
 "】为\"": "】for\"",
 "'至'": "'to'",
 "'之间": "'between",
 "区域": "area",
 "流量消耗": "Data usage",
 "加速流量": "Accelerate traffic",
 "请选择时间范围": "Please select a time range",
 "拟清理的审计录像时间范围": "Time range of audit videos to be cleared",
 "下载完成": "Download Complete",
 "打开目录": "Open Directory",
 "录像": "Video",
 "只能在花漾客户端中下载审计录像": "Audit videos can only be downloaded in the HuaYang client",
 "下载审计录像需要对录像文件进行重新渲染，只能在花漾客户端中下载": "Downloading audit videos requires re-rendering of the video files, which can only be downloaded in the HuaYang client.",
 "至少选择一个可下载录像": "Select at least one downloadable video",
 "出现重复的 IP 地址访问同一平台的不同浏览器分身": "Duplicate IP addresses access different browser avatars of the same platform",
 "当前浏览器分身": "Current browser avatar",
 "当前出口 IP 地址": "Current egress IP address",
 "使用过此IP的其它分身": "Other avatars that have used this IP",
 "最近的访问时间": "Last visit time",
 "当前浏览器窗口将在10秒钟后自动关闭，请注意保存相关工作": "The current browser window will automatically close in 10 seconds, please remember to save your work",
 "关闭浏览器窗口": "Close the browser window",
 "立即激活": "Activate now",
 "已激活": "Activated",
 "花漾灵动推广激励计划": "HuaYangy Smart Promotion Incentive Program",
 "登录": "Log in",
 "确定要删除所选手机账号吗？": "Are you sure you want to delete the selected mobile account?",
 "该操作不可恢复": "This operation cannot be recovered",
 "添加账号": "Add Account",
 "账号名称": "Account Name",
 "打开手机": "Open your phone",
 "确定要删除该手机账号吗？": "Are you sure you want to delete this mobile account?",
 "手机账号属性": "Mobile phone account attributes",
 "所属手机": "Mobile phone",
 "请选择所属手机": "Please select your mobile phone",
 "修改手机": "Modify mobile phone",
 "可选，最多支持{{max}}个字符": "Optional, supports up to {{max}} characters",
 "创建手机分组": "Create a mobile phone group",
 "分组名称已存在": "Group name already exists",
 "手机列表": "Mobile phone list",
 "手机ID": "Mobile ID",
 "手机联营是指将手机分享给其它团队共同管理，您可以随时切断联营关系": "Mobile phone pooling means sharing your mobile phone with other teams for joint management. You can cut off the pooling relationship at any time.",
 "手机已处于联合运营的状态": "The mobile phone is already in joint operation",
 "1、您指定的联系人将收到联营消息，当他确认后，就可以访问此手机": "1. The contact you specify will receive a joint operation message. Once he confirms, he can access this phone.",
 "2、此手机的归属权依然是您，您可以随时中断与其它团队的联营行为": "2. The ownership of this phone still belongs to you, and you can terminate the joint operation with other teams at any time.",
 "手机流畅度设置": "Phone fluency settings",
 "当您发觉手机控制出现卡顿时，可通过对小屏帧率、大屏分辨率进行调整以提升流畅度": "When you find that the phone control is stuck, you can adjust the small screen frame rate and large screen resolution to improve the fluency",
 "小屏帧率": "Small screen frame rate",
 "极低": "Very low",
 "较低": "Lower",
 "常规": "conventional",
 "流畅": "Smooth",
 "大屏分辨率": "Large screen resolution",
 "高清": "HD",
 "标清": "Standard Definition",
 "设备代码": "Device Code",
 "选择手机": "Select Phone",
 "您没有“RPA流程定义的执行权与流程任务的查看权”权限": "You do not have the \"Execute RPA process definition and view process tasks\" permission",
 "继续添加": "Keep adding",
 "请输入手机名称": "Please enter your phone name",
 "花漾既支持本地物理手机，也支持遵循 ADB 协议的云端手机": "HuaYang supports both local physical mobile phones and cloud mobile phones that follow the ADB protocol",
 "连接地址": "Connection Address",
 "请输入连接地址": "Please enter the connection address",
 "需要配对信息": "Pairing information required",
 "配对代码": "Pairing code",
 "请输入配对代码": "Please enter pairing code",
 "配对端口": "Pairing port",
 "手机数量已经达到团队最大配额": "The number of mobile phones has reached the maximum quota of the team",
 "操作手机": "Operate the phone",
 "，无法{{label}}，您需要先购买花瓣并使其余额大于": ", cannot {{label}}, you need to buy Tokens first and make the balance greater than",
 "才能够{{label}}": "To be able to {{label}}",
 "尚未添加手机": "No mobile phone added yet",
 "请将 Android 手机开启调试模式，然后通过 USB 线缆将手机连接此电脑": "Please turn on debugging mode on your Android phone and connect it to this computer via a USB cable.",
 "不支持在网页中添加手机": "Adding mobile phones to web pages is not supported",
 "只有通过花漾客户端才能够连接手机": "Only through the HuaYang client can you connect to your mobile phone",
 "手机视图": "Mobile view",
 "收起": "Close",
 "确定要删除该花漾客户端连接的所有手机吗？": "Are you sure you want to delete all mobile phones connected to this HuaYang client?",
 "该操作会删除此花漾客户端连接的所有手机，请确认是否继续": "This operation will delete all mobile phones connected to this HuaYang client. Please confirm whether to continue.",
 "联营给我的": "Associated with me",
 "虚拟分组": "Virtual Grouping",
 "确定要删除“{{group}}”分组吗？": "Are you sure you want to delete group \"{{group}}\"?",
 "删除分组后无法恢复": "Once a group is deleted, it cannot be restored",
 "新分组": "New Group",
 "展开": "Expand",
 "已弹出": "Popped up",
 "设备离线": "Device offline",
 "操控者": "Controller",
 "主控中": "In main control",
 "该手机只会被从当前分组中移除，并不会被真正删除": "The phone will only be removed from the current group and will not be actually deleted.",
 "手机删除后将无法连接与操控，除非将其重新添加": "Once the phone is deleted, it will not be able to connect and control unless it is added again",
 "重新连接": "Reconnect",
 "流畅度设置": "Fluency settings",
 "小屏尺寸": "Small screen size",
 "大屏尺寸": "Large screen size",
 "全选手机": "Select all mobile phones",
 "批量授权": "Bulk licensing",
 "批量移除": "Bulk removal",
 "添加手机至此分组": "Add a phone to this group",
 "将手机添加至虚拟分组，方便您更好的管理": "Add mobile phones to virtual groups for better management",
 "是否要停止此手机的联营状态？": "Do you want to stop the affiliate status of this phone?",
 "一旦停止此手机的联营状态，所有的合作方团队都将无法再次访问本手机": "Once the joint operation status of this phone is stopped, all partner teams will no longer be able to access this phone",
 "新增联营": "New joint operating",
 "以下用户或位于以下组织单元的用户，均可以访问本手机": "The following users or users in the following organizational units can access this mobile phone",
 "已授权对象": "Authorized Object",
 "手机属性": "Phone attributes",
 "授权信息": "Authorization Information",
 "在本手机中登录的各个平台的账号": "Accounts of various platforms logged in on this phone",
 "可将手机以联营的方式分享给其它团队，由您和其它团队共同管理此手机": "You can share your mobile phone with other teams in a joint operating, and you and other teams can manage this mobile phone together.",
 "联营客队": "United visiting team",
 "联营时间": "joint operating time",
 "确定要终止与此团队的手机联营吗？": "Are you sure you want to terminate mobile affiliation with this team?",
 "一旦终止联营，则该团队无法再次访问您的手机": "Once the partnership is terminated, the team will no longer be able to access your phone.",
 "文本输入失败？": "Text input failed?",
 "文件大小不应该超过512MB": "The file size should not exceed 512MB",
 "联营的手机不允许重新连接": "The associated mobile phone does not allow reconnection",
 "如果手机出现黑屏、无法等情况，请和主队相关人员联系，由他们进行重新连接或者重新启动花漾客户端": "If your phone has a black screen or cannot be used, please contact the host team and ask them to reconnect or restart the HuaYang client.",
 "截屏": "screenshot",
 "息屏模式": "Screen off mode",
 "旋转屏幕": "Rotate the screen",
 "获取手机剪贴板内容": "Get the content of the mobile phone clipboard",
 "发送至手机剪贴板": "Send to phone clipboard",
 "关于花漾 RPA 驱动": "About HuaYang RPA Driver",
 "正在为您安装花漾 RPA 驱动": "Installing the HuaYang RPA driver for you",
 "如果希望执行 RPA 自动化流程，必须在手机中安装花漾 RPA 驱动": "If you want to execute RPA automation process, you must install the HuaYang RPA driver on your mobile phone",
 "安装花漾 RPA 驱动之前需要关闭手机的一些安全限制，请根据您的手机型号查询相关文档": "Before installing the HuaYang RPA driver, you need to disable some security restrictions on your phone. Please check the relevant documents according to your phone model.",
 "安装RPA驱动": "Install the RPA driver",
 "当前禁止执行RPA流程": "Execution of RPA processes is currently prohibited",
 "当前手机的状态为“禁止执行” RPA 流程": "The current status of the mobile phone is \"prohibited execution\" RPA process",
 "当允许执行 RPA 流程后，后续的 RPA 流程将会正常执行": "Once the RPA process is allowed to execute, the subsequent RPA processes will execute normally.",
 "允许执行 RPA": "Allows execution of RPA",
 "允许执行": "Allow execution",
 "当前允许执行RPA流程": "Currently allowed to execute RPA processes",
 "当前手机的状态为“允许执行” RPA 流程": "The current mobile phone status is \"Allow execution\" RPA process",
 "如果您正在操作手机，为了避免RPA的干扰，您可以禁止RPA的执行": "If you are operating a mobile phone, you can disable the execution of RPA to avoid interference from RPA.",
 "禁止执行": "Prohibition of execution",
 "预览流程": "Preview process",
 "一旦退还手机，您当前团队所有成员都将无法使用当前手机，请确认是否继续": "Once the phone is returned, all members of your current team will not be able to use the current phone. Please confirm whether to continue",
 "弹出": "pop up",
 "退还": "return",
 "显示控制台": "Display Console",
 "正在停止“{{flowName}}”RPA流程": "Stopping the \"{{flowName}}\" RPA process",
 "RPA流程“{{flowName}}”正在执行": "The RPA process \"{{flowName}}\" is being executed",
 "批量修改所选手机授权": "Modify the selected mobile phone authorizations in batches",
 "Authorization": "Authorization",
 "除超管与BOSS外，其它用户必须授权后才能够访问此手机": "Except for the super administrator and BOSS, other users must be authorized before they can access this phone",
 "您解除了自己本人与该手机的关系，这意味着一旦确定就无法再查看此手机，确定要继续吗": "You have terminated your relationship with this phone, which means you will no longer be able to view this phone once confirmed. Are you sure you want to continue?",
 "编辑手机分组": "Edit Phone Group",
 "确定要重新连接花漾客户端与手机吗？": "Are you sure you want to reconnect the HuaYang client and your phone?",
 "该操作会重新启动所选花漾客户端的ADB服务，会影响该花漾客户端正在连接的所有手机，造成10秒左右的中断，常用于手机黑屏无法操作等异常场景": "This operation will restart the ADB service of the selected HuaYang client, which will affect all mobile phones that the HuaYang client is connecting to, causing an interruption of about 10 seconds. It is often used in abnormal scenarios such as black screen and inoperable mobile phones.",
 "正在为您重新连接花漾客户端与手机": "Reconnecting the HuaYang client to your phone",
 "此操作大概需要10秒左右的时间，请稍候...": "This operation may take about 10 seconds, please wait...",
 "手机已删除": "Phone deleted",
 "手机已离线": "Phone is offline",
 "浏览器窗口布局": "Browser Window Layout",
 "在本机执行流程时才允许设置浏览器窗口布局": "Setting the browser window layout is only allowed when the process is executed locally",
 "请在流程运行设备中选择本机，然后再设置浏览器窗口布局": "Please select the local machine in the process running device, and then set the browser window layout",
 "自动记录窗口的大小和位置": "Automatically record window size and position",
 "任务窗口": "Task Window",
 "默认弹出流程任务窗口": "The default pop-up process task window",
 "请至少选择一个手机": "Please select at least one phone",
 "不知道如何填写？阅读{{link}}": "Don't know how to fill it out? Read {{link}}",
 "已经是最新版本，无需更新": "Already the latest version, no need to update",
 "该流程的当前版本为{{version_1}}，最新版本为{{version_2}}": "The current version of this process is {{version_1}}, and the latest version is {{version_2}}",
 "确定要更新至最新版本吗？": "Are you sure you want to update to the latest version?",
 "该流程的当前版本为{{version_1}}，最新版本为{{version_2}}，会同步更新所有包含此流程定义的流程计划": "The current version of this process is {{version_1}}, and the latest version is {{version_2}}. All process plans containing this process definition will be updated synchronously.",
 "流程更新时自动更新": "Automatically update when the process is updated",
 "全部客户端": "All clients",
 "该手机没有与客户端连接，无法访问": "The phone is not connected to the client and cannot access",
 "执行方式": "Execution",
 "只关闭流程打开的浏览器": "Close only the browser that the process opened",
 "请至少选择一部手机": "Please select at least one phone",
 "消息设置": "Message settings",
 "流程开始": "Process Start",
 "实时查看": "Live View",
 "隐式执行": "Implicit execution",
 "消息通知": "Message Notification",
 "弹出流程任务窗口": "Pop-up process task window",
 "不弹出流程任务窗口": "Do not pop up the process task window",
 "执行体列表": "Executable List",
 "预览时只能选择正在与当前电脑连接的手机": "When previewing, you can only select the mobile phone that is currently connected to the computer",
 "系统版本": "System version",
 "Label": "Label",
 "请输入OpenId": "Please enter OpenId",
 "请输入第三方系统的名称": "Please enter the name of the third-party system",
 "请输入第三方系统的名称（不超过100个字符）": "Please enter the name of the third-party system (no more than 100 characters)",
 "客户端发起": "Client initiated",
 "浏览器发起": "Browser initiated",
 "第三方系统发起": "Initiated by a third-party system",
 "流程发起方式": "Process initiation method",
 "请选择流程发起方式": "Please select the process initiation method",
 "强制提醒": "Mandatory reminder",
 "指定拟由哪些浏览器分身来执行此流程": "Specify which browser avatars will execute this process",
 "（创建流程任务/计划时由用户手动添加分身）": "(Users manually add avatars when creating a process task/plan)",
 "（在初始子流程中由程序控制动态添加分身）": "(In the initial sub-process, the avatars are dynamically added by the program)",
 "流程执行前自动打开浏览器分身": "Automatically open the browser avatar before the process is executed",
 "流程执行前不自动打开浏览器分身": "Do not automatically open the browser avatar before the process is executed",
 "浏览器分身关闭时自动结束流程": "Automatically end the process when the browser avatar is closed",
 "浏览器分身关闭时不自动结束流程": "The process does not end automatically when the browser avatar is closed",
 "分身最小化策略": "Strategy to minimize avatars",
 "在执行RPA流程时，如果发觉浏览器窗口被最小化时的响应策略": "When executing an RPA process, if you find that the browser window is minimized, what is the response strategy?",
 "流程开始执行时，是否自动展开浏览器侧边栏": "When the process starts executing, whether to automatically expand the browser sidebar",
 "读取图片": "Reading pictures",
 "插入图片": "Insert a picture",
 "打开应用": "Open the app",
 "发送按键": "Send Key",
 "执行appium脚本": "Execute appium script",
 "手机截屏": "Mobile screenshot",
 "手机操作": "Mobile phone operation",
 "日志": "log",
 "调用ChatGPT引擎生成匹配内容，输出结果保存至变量；可使用用户的Token，如果使用花漾的Token则根据输入+输出内容总字数进行收费，每千字符2花瓣": "Call ChatGPT engine to generate matching content, and save the output results to variables; you can use the user's token. If you use HuaYang's token, you will be charged based on the total number of words in the input + output content, 2 Tokens per thousand characters",
 "使用花漾灵动的Token": "Use the HuaYang Lingdong Token",
 "使用用户自己的Token": "Use your own Token",
 "调用ChatGPT生成输出内容，输出结果保存至变量；可使用用户的Token，如果使用花漾的Token则根据输入+输出内容总字数进行收费，每千字符2花瓣": "Call ChatGPT to generate output content, and save the output result to a variable; you can use the user's token. If you use HuaYang's token, you will be charged based on the total number of words in the input + output content, 2 Tokens per thousand characters",
 "镜像区域": "Mirror Area",
 "完整页面": "Full Page",
 "指定页面节点": "Specifying a page node",
 "请确认需要在手机中操作的区域完整位于指定页面节点的可见范围": "Please make sure that the area you need to operate on your phone is completely within the visible range of the specified page node",
 "不超过100个字符": "No more than 100 characters",
 "该节点没有特定属性": "This node has no specific attributes",
 "该节点通常在“事件子流程”中使用": "This node is usually used in the \"Event Sub-Process\"",
 "可在控制台中输出您期望的内容，日志级别分别是：Debug（详尽）、Info（提示）、Error（简要）和控制台输出；等价于console.log、console.info、console.error、console.print": "You can output the content you expect in the console. The log levels are: Debug (detailed), Info (prompt), Error (brief), and console output; equivalent to console.log, console.info, console.error, console.print",
 "为": "for",
 "图片路径": "Image Path",
 "调整图片宽度": "Adjust image width",
 "调整图片高度": "Adjust image height",
 "图片读取成功后，将图片文件的本地路径保存到该变量": "After the image is read successfully, the local path of the image file is saved to this variable",
 "请输入Appium脚本，您可以操作所有的系统变量与用户自定义变量": "Please enter the Appium script, you can operate all system variables and user-defined variables",
 "请输入Appium脚本": "Please enter the Appium script",
 "包名": "Package Name",
 "活动名": "Activity Name",
 "后退": "Back",
 "任务视图": "Task View",
 "音量+": "Volume+",
 "音量-": "volume-",
 "按键": "button",
 "使用客户端网络": "Using Client Network",
 "使用浏览器分身网络": "Using a browser avatar",
 "确定要移除所有的流程吗？": "Are you sure you want to remove all processes?",
 "流程一旦被移除，将影响所有的浏览器分身": "Once the process is removed, it will affect all browser avatars",
 "已为您过滤所有未发布的流程定义": "All unpublished process definitions have been filtered for you",
 "该流程不支持通过浏览器发起": "This process cannot be initiated through a browser.",
 "选中的流程定义已内置到浏览器分身中": "The selected process definition has been built into the browser avatar",
 "凡是与流程定义适用平台相符的浏览器分身，可在打开浏览器后执行此流程": "Any browser avatar that matches the platform applicable to the process definition can execute this process after opening the browser",
 "发布失败": "Publishing failed",
 "尚未添加分身流程": "No avatar process has been added yet",
 "每一个浏览器分身在打开时都会自动加载发布到此处且与分身所属平台相符的流程定义": "Each browser avatar will automatically load the process definition published here and matching the platform to which the avatar belongs when it is opened.",
 "添加新的流程定义": "Adding a new process definition",
 "全部移除": "Remove All",
 "每一个浏览器分身在打开时都会自动加载发布到此处且与分身所属平台相符的流程定义，如个别分身需自行指定某些流程定义，请在分身属性中设置": "Each browser avatar will automatically load the process definition published here and consistent with the avatar's platform when it is opened. If individual avatars need to specify certain process definitions, please set them in the avatar properties.",
 "同步升级在当前团队内包含此流程定义的浏览器分身": "Synchronously upgrade the browser avatars that contain this process definition in the current team",
 "流程预览": "Process Preview",
 "您将获得和“{{flow}}”相关的微信消息通知，如需取消请点击查看详情": "You will receive a WeChat message notification related to \"{{flow}}\". If you need to cancel, please click to view details",
 "起始": "Starting",
 "执行体包含": "The executable contains",
 "发布": "release",
 "说明": "illustrate",
 "确定要移除选中的流程定义吗？": "Are you sure you want to remove the selected process definition?",
 "流程定义移除后，将无法再从浏览器中执行此流程": "Once the process definition is removed, it will no longer be possible to execute the process from the browser.",
 "请打开花漾浏览器后，在浏览器中执行此流程": "Please open the HuaYang browser and execute this process in the browser",
 "请先打开花漾浏览器，然后通过右上角的机器人插件执行此流程": "Please open the HuaYang browser first, and then execute this process through the robot plug-in in the upper right corner",
 "该流程为TK套餐专属流程": "This process is exclusive to the TK package",
 "您的TK套餐已过期，如果需要执行该流程，请续费后使用": "Your TK package has expired. If you need to perform this process, please renew it.",
 "建议您在TK达人管理系统中执行此流程": "It is recommended that you perform this process in the TK Talent Management System",
 "当前团队已经购买了TK解决方案的套餐，建议您到TK达人管理系统中执行此流程定义": "The current team has purchased the TK solution package. It is recommended that you execute this process definition in the TK Talent Management System.",
 "通过浏览器发起": "Initiate via browser",
 "通过第三方系统“{{thirdPartyName}}”发起": "Initiated by the third-party system \"{{thirdPartyName}}\"",
 "该流程不允许通过客户端发起执行": "This process is not allowed to be initiated by the client",
 "该流程允许的执行方式为": "The execution modes allowed by this process are:",
 "浏览器流程": "Browser Process",
 "手机流程": "Mobile phone process",
 "当流程定义较多时，可以通过分组将流程定义进行分类": "When there are many process definitions, you can classify them by grouping",
 "创建分组": "Create Group",
 "不再显示": "Don't show again",
 "Proc Plan": "Proc Plan",
 "访问RPA流程市场": "Visit the RPA Process Marketplace",
 "是否要批量执行选定的流程计划？": "Do you want to execute the selected process plans in batches?",
 "批量执行": "Batch Execution",
 "批量启用": "Batch activation",
 "批量禁用": "Batch disable",
 "当流程计划较多时，可以切换为列表展示形式": "When there are many process plans, you can switch to list display",
 "只保留最近90天的历史任务": "Only keep the historical tasks of the last 90 days",
 "RPA 流程只保留最近 90 天的历史任务": "The RPA process only retains historical tasks within the last 90 days",
 "为提升系统响应效率，超过 90 天的历史任务将被自动清理，请您理解": "To improve system response efficiency, historical tasks older than 90 days will be automatically cleared. Please understand.",
 "设备ID='": "Device ID = '",
 "流程定义='": "Process definition = '",
 "手机ID='": "Mobile ID = '",
 "执行体": "Executable",
 "个手机": "Cell Phone",
 "一张券同时只能绑定在一台客户端；当解除绑定关系后，可以重新绑定到其它的客户端设备": "A voucher can only be bound to one client at a time; after unbinding, it can be re-bound to other client devices",
 "激活流程任务卡": "Activate process task card",
 "流程执行结束": "Process execution ends",
 "10秒钟后，将关闭本窗口（可在流程任务中继续查看详情信息）": "After 10 seconds, this window will be closed (you can continue to view detailed information in the process task)",
 "正在加载": "Loading",
 "加载更多": "Load More",
 "流程暂停运行": "The process is suspended",
 "正在等待控制台输出...": "Waiting for console output...",
 "弹出手机": "Pop-up phone",
 "分身详情": "Details of the avatar",
 "确定要中断该分身的执行吗？": "Are you sure you want to interrupt the execution of this avatar?",
 "流程一旦中断无法恢复": "Once the process is interrupted, it cannot be restored",
 "确定要取消该分身的执行吗？": "Are you sure you want to cancel the execution of this avatar?",
 "流程一旦取消无法恢复": "Once the process is cancelled, it cannot be restored",
 "取消执行": "Cancel Execution",
 "未找到匹配结果": "No matching results found",
 "重置搜索关键词": "Reset search keywords",
 "浏览器自动化": "Browser Automation",
 "手机App自动化": "Mobile App Automation",
 "正在": "Currently",
 "请输入数值": "Please enter a value",
 "请输入拟导入的达人ID": "Please enter the ID of the expert to be imported",
 "目标数量": "Target quantity",
 "抓取达人数量": "Number of experts captured",
 "单次计划最多添加100个商品，建议不超过50个": "A maximum of 100 products can be added in a single plan, but no more than 50 are recommended",
 "打开缓存文件夹": "Open the cache folder",
 "将已有的 Cookie 导入至此分身": "Import existing cookies to this avatar",
 "为您生成一个链接，一键采集其它浏览器的指纹与Cookie数据": "Generate a link for you to collect fingerprints and cookie data of other browsers with one click",
 "将当前浏览器分身的Cookie、指纹、网站密码等数据迁移至另一个目标分身": "Migrate the cookies, fingerprints, website passwords and other data of the current browser avatar to another target avatar",
 "迁移并替换目标分身的网站密码": "Migrate and replace the target avatar's website passwords",
 "无痕分身相当于“浏览器的隐身模式”，本身不存储Cookie、LocalStorage等数据，且会动态变化浏览器指纹，每次打开都是一个全新的花漾浏览器": "The invisible browser is equivalent to the \"browser's incognito mode\". It does not store cookies, LocalStorage and other data, and will dynamically change the browser fingerprint. Every time you open it, it will be a brand new browser.",
 "请输入浏览器分身命名前缀": "Please enter the browser avatar naming prefix",
 "浏览器分身命名前缀最多20个字符": "The browser avatar naming prefix is limited to 20 characters",
 "为浏览器分身创建不同的浏览器指纹": "Create different browser fingerprints for browser avatars",
 "输入登录信息，花漾会为您进行密码代填（登录信息会被高强度加密存储）": "Enter your login information and HuaYang will fill in your password for you (the login information will be stored with high-strength encryption)",
 "登录信息": "Login Information",
 "跟随浏览器分身缺省设置": "Follow the default settings of the browser avatar",
 "浏览器分身缺省设置约定了新建一个浏览器分身的默认值，可通过{{action}}进行调整": "The default settings for browser avatars define the default values for creating a new browser avatar, which can be adjusted using {{action}}",
 "电商平台首页": "E-commerce platform homepage",
 "针对不同电商平台，有不同的首页": "There are different homepages for different e-commerce platforms",
 "浏览器检测页会对当前分身绑定的IP以及使用的加速通道进行各种检测，默认情况下建议使用此选项": "The browser detection page will perform various tests on the IP bound to the current avatar and the acceleration channel used. By default, it is recommended to use this option",
 "默认打开上次浏览器关闭前打开的网页": "By default, open the webpage that was opened before the browser was closed last time",
 "冲突策略": "Conflict Strategy",
 "会更新表格内所有分身的冲突策略": "Will update the conflict strategy of all avatars in the table",
 "批量设置分身的平台": "Platform for batch setting of avatars",
 "为导入的分身批量打标签": "Batch labeling for imported avatars",
 "绑定已导入的 IP": "Bind imported IP",
 "向我推荐适合的 IP 地址": "Recommend me a suitable IP address",
 "绑定临时 IP": "Bind temporary IP",
 "绑定已创建的 IP 池": "Bind the created IP pool",
 "使用 IP 直连": "Use IP direct connection",
 "未检测到系统代理或代理未开启": "The system proxy is not detected or is not enabled.",
 "如果代理正常工作，则使用代理访问网络；如果代理未开启，则使用本机IP直连": "If the proxy is working properly, use the proxy to access the network; if the proxy is not turned on, use the local IP direct connection",
 "如果希望购买花漾为您提供的 IP 地址（注：花漾提供的 IP 仅适用于店铺运营）": "If you want to purchase the IP address provided by HuaYang (Note: the IP provided by HuaYang is only for store operations)",
 "如果您在花漾中购买了IP地址，或者已经将IP地址导入了花漾": "If you have purchased an IP address in HuaYang, or have imported an IP address into HuaYang",
 "如果您已经创建了 IP 池 （IP池适用于通过 API 动态提取IP地址的情况）": "If you have created an IP pool (IP pool is suitable for dynamically extracting IP addresses through API)",
 "如果您已经拥有IP地址，但尚未导入花漾只是希望临时使用": "If you already have an IP address but have not yet imported it into HuaYang and just want to use it temporarily",
 "如果您的电脑已经开启了代理软件或者 VPN": "If your computer has already started proxy software or VPN",
 "不使用任何代理，始终使用本机 IP 直连": "Do not use any proxy, always use the local IP direct connection",
 "解除IP隔离设置": "Unlock IP isolation settings",
 "会影响所选分身的旧有IP隔离设置": "The old IP isolation settings will affect the selected avatar",
 "IP隔离批量设置": "IP isolation batch setting",
 "已有IP地址": "Already have an IP address",
 "没有IP地址/使用系统代理": "No IP address/Use system proxy",
 "未设置IP隔离，无法打开会话": "IP isolation is not set, unable to open session",
 "为分身绑定不同的IP地址是防止账号关联的重要手段，但这并非必需项，请您根据账号的性质进行判断": "Binding different IP addresses to avatars is an important way to prevent account association, but it is not necessary. Please make your own judgment based on the nature of your account.",
 "联营分身无法修改IP隔离设置": "The affiliated avatar cannot modify the IP isolation settings",
 "确定要批量解绑所选分身的IP隔离设置吗？": "Are you sure you want to unbind the IP isolation settings of the selected avatars in batches?",
 "确定要解绑当前分身的IP隔离设置吗？": "Are you sure you want to unbind the IP isolation settings of the current avatar?",
 "分身必须设置IP隔离后才允许访问": "The avatar must be set up with IP isolation before access is allowed",
 "设置成功": "Set up for success",
 "无法在网页端中设置": "Cannot be set on the web page",
 "只显示空闲IP": "Only show free IPs",
 "选择IP地址": "Select IP Address",
 "请选择平台类型": "Please select the platform type",
 "请输入分身描述以供备忘（凡能够看到此分身的用户均可查看备注内容），最长512字符": "Please enter a description for your avatar (anyone who can see this avatar can view the note), up to 512 characters",
 "无痕分身不保存Cookie数据": "No Cookies are saved",
 "缓存数据": "Cached data",
 "内置RPA流程": "Built-in RPA processes",
 "GBS租户信息": "GBS Tenant Information",
 "请注意：此处并未展示所有分身的真实属性，只有当编辑保存后，才会按照您的输入修订所有的分身属性": "Please note: The true properties of all avatars are not displayed here. Only after editing and saving, will the properties of all avatars be revised according to your input.",
 "理论上，无痕分身不应保存任何缓存数据，但可根据使用场景适当调整，可在": "In theory, the traceless avatar should not save any cache data, but it can be adjusted appropriately according to the usage scenario.",
 "进行统一设置": "Make unified settings",
 "名为“个人私有书签”中的内容会在同一个用户之间进行同步": "The content in the \"Personal Bookmarks\" folder will be synchronized between users.",
 "“其它收藏夹”是指不在“团队收藏夹”、“个人私有书签”中的书签，会在所有能够访问到该分身的人员之间共享": "\"Other Favorites\" refers to bookmarks that are not in \"Team Favorites\" or \"Personal Private Bookmarks\" and will be shared among all people who can access the avatar.",
 "转让分身的 LocalStorage 与 IndexedDB": "Transfer the avatar's LocalStorage and IndexedDB",
 "（其它缓存数据）": "(Other cached data)",
 "同步分身的首页设置与快捷方式": "Home page settings and shortcuts for syncing avatars",
 "（仅限于浏览器检测页中的快捷方式）": "(only for shortcuts in the browser detection page)",
 "同步分身的插件扩展": "Sync avatar plugin extension",
 "（仅限于通过花漾插件市场中安装的插件）": "(Limited to plugins installed through the HuaYang Plugin Market)",
 "（请确认有没有绑定其它分身）": "(Please confirm whether there are other avatars bound)",
 "将绑定的IP地址一并联营给客队": "The bound IP address will be assigned to the visiting team",
 "如果绑定的IP地址是平台IP，可将IP地址联营给客队，客队可以对此IP地址进行续费，但无法执行其它操作": "If the bound IP address is a platform IP, the IP address can be allocated to the guest team. The guest team can renew the IP address, but cannot perform other operations.",
 "绑定的IP地址一并联营": "The bound IP address is also linked",
 "（仅限于平台IP，适用于需要客队对IP续费的场景）": "(Limited to platform IP, applicable to scenarios where the visiting team needs to renew the IP)",
 "2、如果IP被转让，当对方确认后IP将从当前团队中移除": "2. If the IP is transferred, the IP will be removed from the current team after the other party confirms it.",
 "3、当对方确认后分身也将从当前团队中移除": "3. When the other party confirms, the avatar will also be removed from the current team",
 "您指定的联系人将收到一笔消息，一旦他确认后，该分身将从您的团队中移除": "The contact you specified will receive a message, and once he confirms, the avatar will be removed from your team.",
 "24小时内，如果您指定的联系人没有确认分身交接，本次分身转让将被置为失败，分身将重新置为正常状态": "If the contact person you designate does not confirm the avatar handover within 24 hours, the avatar transfer will be considered failed and the avatar will be reset to normal status.",
 "您指定的联系人将收到分身联营消息，当他确认后，就可以和您一起联合运营此分身": "The contact you specify will receive a message about the avatar joint operation. Once he confirms, he can jointly operate this avatar with you.",
 "您指定的联合运营团队在访问此分身时，会使用此分身的指纹与IP地址，并受您指定的分身联营安全策略的约束，拥有足够的安全性": "When the joint operation team you designate accesses this avatar, they will use the fingerprint and IP address of this avatar and will be subject to the avatar joint operation security policy you designate, which is sufficiently secure.",
 "此分身的归属权依然是您，您可以随时中断与其它团队的联营行为": "The ownership of this avatar is still yours, and you can terminate the joint operation with other teams at any time",
 "快照策略": "Snapshot strategy",
 "修改全部浏览器分身": "Modify all browser avatars",
 "修改全部浏览器指纹": "Modify all browser fingerprints",
 "修改全部无痕分身": "Modify all traceless avatars",
 "全部分身生成快照": "Generate snapshots of all avatars",
 "分身恢复": "Avatar Recovery",
 "该分身采用的是系统代理": "The avatar uses the system proxy",
 "如果系统代理已经开启，则会使用系统代理；否则使用本机IP直连": "If the system proxy is already enabled, it will be used; otherwise, the local IP will be used for direct connection",
 "该分身采用的是本机IP直连": "The avatar uses a direct connection to the local IP",
 "这意味着无论本机是否开启了代理，始终使用本机 IP 直连": "This means that no matter whether the proxy is turned on or not, the local IP address is always used for direct connection.",
 "尚未设置IP隔离": "IP isolation has not been set up yet",
 "请点击右上角齿轮图标进行设置": "Please click the gear icon in the upper right corner to set",
 "快照管理": "Snapshot Management",
 "强制关团浏览器窗口将中断当前用户的操作，请确认是否继续": "Forcibly closing the browser window will interrupt the current user's operation. Please confirm whether to continue",
 "关于浏览器分身缺省设置": "About the default settings of browser avatars",
 "所选": "Selected",
 "全量修改所有分身的属性": "Modify the properties of all avatars",
 "批量修改分身属性": "Modify the attributes of avatars in batches",
 "全量修改分身属性会清除所有分身的旧有属性设置": "Modifying all avatar attributes will clear all old attribute settings of all avatars.",
 "创建新的快照": "Create a new snapshot",
 "可将分身的缓存数据进行快照备份，在有需要时可随时恢复，需要注意的是，云端快照会占据网盘空间并有可能形成额外的花瓣支出": "You can backup the cached data of the avatar as a snapshot and restore it at any time when needed. It should be noted that cloud snapshots will occupy network disk space and may incur additional Token expenses.",
 "云端快照": "Cloud snapshots",
 "需要先关闭所有正在打开的浏览器会话": "You need to close all open browser sessions first",
 "需要先关闭当前正在打开的所有浏览器会话后才能够从快照中恢复": "You need to close all currently open browser sessions before you can restore from a snapshot.",
 "确定要恢复此快照吗？": "Are you sure you want to revert to this snapshot?",
 "当前浏览器分身相关的信息将被快照中的内容所覆盖，请确认是否继续": "The information related to the current browser avatar will be overwritten by the content in the snapshot. Please confirm whether to continue",
 "确定要删除此快照吗？": "Are you sure you want to delete this snapshot?",
 "如缓存数据未开启云端同步则忽略": "Ignore if the cached data is not synchronized to the cloud",
 "批量生成快照": "Generate snapshots in batches",
 "快照设置": "Snapshot Settings",
 "需要开启云端同步才能够进行快照": "You need to enable cloud synchronization to take snapshots",
 "任何浏览器分身的缓存数据，必须要开启云端同步策略才能够进行快照": "The cache data of any browser avatar must have the cloud synchronization strategy enabled to be snapshotted",
 "未开启云端同步": "Cloud sync is not enabled",
 "快照名称": "Snapshot name",
 "快照内容": "Snapshot content",
 "请至少选择一种快照内容": "Please select at least one snapshot content",
 "插件扩展的缓存数据": "Plugin extension cache data",
 "快照类型": "Snapshot Type",
 "最多256个字符": "Up to 256 characters",
 "所选分身中有联营分身": "The selected avatar has a joint avatar",
 "分享链接备忘": "Share Link Notes",
 "使用权限": "Use Permissions",
 "允许打开此分身浏览器": "Allow this browser to open",
 "允许执行发布到此分身的RPA流程": "Allows execution of RPA processes published to this avatar",
 "有效时长": "Validity period",
 "对浏览器指纹缺省设置的修订，只会影响新创建的浏览器指纹": "Modifications to the default settings for browser fingerprinting will only affect newly created browser fingerprints.",
 "默认 UserAgent 版本": "Default UserAgent Version",
 "当前客户端能够支持的最高版本": "The highest version that the current client can support",
 "最新版本的花漾客户端能够支持的最高版本": "The latest version of the HuaYang client can support the highest version",
 "默认语言": "Default language",
 "是否启用 WebRTC": "Whether to enable WebRTC",
 "是否启用经纬度": "Whether to enable longitude and latitude",
 "已为选中的分身安装了指定插件": "The specified plugin has been installed for the selected avatar",
 "请分别到选中的分身详情中查看已安装的插件，此处并未展示每个分身已经安装的插件列表": "Please check the installed plugins in the details of the selected avatars. The list of installed plugins for each avatar is not displayed here.",
 "关于批量安装插件": "About bulk installation of plugins",
 "共选择了{{count}}个分身，当安装新的插件时，会分别安装到选定的分身中，但在此处并未展示每个分身已经安装的插件列表": "A total of {{count}} avatars have been selected. When installing new plugins, they will be installed in the selected avatars separately. However, the list of plugins installed in each avatar is not displayed here.",
 "可在“{{extension_tab}}”主页签中为当前团队所有的分身统一安装插件": "You can install plugins for all avatars of the current team in the \"{{extension_tab}}\" homepage tab",
 "一个浏览器分身能够加载的插件包含在“{{extension_tab}}”页签“{{installed_tab}}”，以及在此处添加的插件，注：在此处添加的插件只对本浏览器分身有效": "The plugins that a browser avatar can load are included in the \"{{extension_tab}}\" tab \"{{installed_tab}}\", as well as the plugins added here. Note: The plugins added here are only valid for this browser avatar.",
 "请注意：此处并未展示所选分身的浏览器指纹真实信息，只有点击确定后，才会按照您的输入值修订所选分身的指纹信息": "Please note: The actual browser fingerprint information of the selected avatar is not displayed here. Only after clicking OK will the fingerprint information of the selected avatar be revised according to your input value.",
 "全量修改所有分身的浏览器指纹": "Modify the browser fingerprint of all avatars",
 "批量修改所选分身的浏览器指纹": "Modify the browser fingerprints of selected avatars in batches",
 "Windows 7 及更早版本不支持 110 以上内核，请勿修改": "Windows 7 and earlier versions do not support kernels above 110, please do not modify",
 "重新生成浏览器指纹": "Regenerate browser fingerprint",
 "设置 WebRTC": "Setting up WebRTC",
 "修改经纬度与时区": "Modify latitude, longitude and time zone",
 "修改硬件参数": "Modify hardware parameters",
 "修改Android浏览器指纹的分辨率": "Modify the resolution of Android browser fingerprint",
 "GBS租户ID": "GBS Tenant ID",
 "GBS账号": "GBS Account",
 "GBS密码": "GBS Password",
 "稍后设置": "Set up later",
 "请输入您的港宝租户信息：": "Please enter your Hong Kongbao tenant information:",
 "系统代理的出口IP探测": "System proxy egress IP detection",
 "请指定归属地": "Please specify the place of origin",
 "请指定IP语言": "Please specify IP language",
 "每次打开浏览器时自动进行一轮探测": "Automatically perform a round of detection every time you open the browser",
 "（温馨提示：此选项依赖于花漾IP地理库的精准性）": "(Tips: This option depends on the accuracy of the HuaYang IP geographic database)",
 "确定要恢复出厂设置吗？": "Are you sure you want to perform a factory reset?",
 "前期所做的修订都将被重置，请确认是否继续": "All previous revisions will be reset. Please confirm whether to continue",
 "恢复出厂设置": "Restore factory settings",
 "分身同名书签": "Bookmark with the same name",
 "在书签栏第一个位置放上与分身同名的书签，用来区分不同的分身": "Put a bookmark with the same name as the avatar in the first position of the bookmark bar to distinguish different avatars",
 "书签顺序": "Bookmark Order",
 "已为选中的分身发布了指定的RPA流程定义": "The specified RPA process definition has been published for the selected avatar",
 "请分别到选中的分身详情中查看已发布的流程定义，此处并未展示每个分身已经发布的流程定义列表": "Please view the published process definitions in the details of the selected avatars. The list of published process definitions for each avatar is not displayed here.",
 "关于批量RPA发布": "About Batch RPA Release",
 "共选择了{{count}}个分身，当发布新的RPA流程时时，会分别发布到选定的分身，但在此处并未展示每个分身已经发布的流程定义": "A total of {{count}} avatars have been selected. When a new RPA process is released, it will be released to the selected avatars separately. However, the process definition that has been released for each avatar is not displayed here.",
 "一个浏览器分身能够加载的RPA流程定义包含在“{{tab_1}}”页签“{{tab_2}}”，以及在此处添加的流程定义，注：在此处添加的流程定义只对本浏览器分身有效": "The RPA process definitions that can be loaded by a browser avatar are included in the \"{{tab_1}}\" tab \"{{tab_2}}\" and the process definitions added here. Note: The process definitions added here are only valid for this browser avatar.",
 "可在“{{tab}}”主页签中为当前团队所有的分身统一发布RPA流程定义": "You can publish RPA process definitions for all avatars of the current team in the \"{{tab}}\" homepage tab",
 "确定要修改所选分身的屏蔽站点列表？": "Are you sure you want to modify the blocked sites list of the selected avatar?",
 "必须绑定IP或IP池": "Must bind IP or IP pool",
 "可以限制在打开浏览器分身时，必须绑定IP或者IP池，这是为了避免由于绑定的IP被释放的情况下，误操作打开浏览器分身导致带来安全风险": "You can restrict the opening of the browser avatar to a bound IP or IP pool to avoid security risks caused by opening the browser avatar by mistake when the bound IP is released.",
 "关闭选中分身的安全策略，请注意：已开启联营的分身将被忽略，如需要关闭所有分身的安全策略，请点击{{action}}": "Disable the security policy of the selected avatar. Please note that the avatars with alliance enabled will be ignored. If you need to disable the security policy of all avatars, please click {{action}}",
 "默认属性之安全策略": "Default security policy",
 "知晓": "Know",
 "不知晓": "Unknown",
 "实时监管": "Real-time supervision",
 "用户感知": "User perception",
 "当实时监管某个浏览器会话时，是否允许浏览器会话操作者感知到有其它人正在监管": "When monitoring a browser session in real time, whether to allow the browser session operator to be aware that someone else is monitoring it",
 "当强制录像时，是否允许浏览器会话当前操作者感知到正在录像": "When forced recording, whether to allow the current operator of the browser session to be aware that recording is in progress",
 "为分身开启安全策略将产生额外的花瓣消耗，您可以：": "Enabling security policies for avatars will incur additional Token consumption. You can:",
 "确定要关闭所有分身的安全策略吗？": "Are you sure you want to disable security policies for all avatars?",
 "请注意：已开启联营的分身将自动忽略（分身如果要联营必须开启安全策略）": "Please note: The avatars that have enabled the alliance will be automatically ignored (the avatars must enable the security policy if they want to be affiliated)",
 "一键关闭所有分身的安全策略": "One-click security policy to disable all avatars",
 "一个浏览器分身只能加入一个快照策略": "A browser avatar can only join one snapshot policy",
 "确定删除此快照策略？": "Are you sure you want to delete this snapshot policy?",
 "保留数量": "Reserve quantity",
 "创建快照策略": "Creating a snapshot policy",
 "确定要将该分成从快照策略中移除？": "Are you sure you want to remove this partition from the snapshot policy?",
 "移除后，该分身将不再被快照策略管理": "After removal, the avatar will no longer be managed by the snapshot policy.",
 "请输入策略名称": "Please enter a policy name",
 "打开浏览器之前动态变化指纹信息": "Dynamically change fingerprint information before opening the browser",
 "打开浏览器维持指纹信息不变": "Open the browser to keep the fingerprint information unchanged",
 "关闭浏览器删除所有缓存数据": "Close your browser to delete all cached data",
 "关闭浏览器时保存以下数据": "The following data is saved when you close your browser",
 "浏览器配置数据的辅助性文件": "Auxiliary files for browser configuration data",
 "无痕分身其它属性的默认值和": "The default values of other attributes of the traceless avatar and",
 "保持一致": "Be consistent",
 "对无痕分身缺省设置的修订，只会影响新创建的无痕分身": "Modifications to the default settings of the avatar only affect newly created avatars.",
 "请注意：此处并未展示所有无痕分身的真实属性，只有当编辑保存后，才会按照您的输入修订所有无痕分身的属性": "Please note: The true properties of all traceless avatars are not displayed here. Only when the edit is saved, the properties of all traceless avatars will be revised according to your input",
 "全量修改所有无痕分身": "Modify all traceless avatars",
 "导入网站规则": "Import Site Rules",
 "解析失败，请检测格式是否正确": "Parsing failed, please check whether the format is correct",
 "清空已有规则": "Clear existing rules",
 "导出网站规则": "Export Site Rules",
 "导出": "Export",
 "标识码为空则不绘制，可在{{route}}中全局关闭分身标识码": "If the ID code is empty, it will not be drawn. You can globally disable the avatar code in {{route}}",
 "团队设置": "Team Setup",
 "一次最多打开30个分身": "A maximum of 30 avatars can be opened at a time",
 "窗口布局设置": "Window layout settings",
 "本次群控中输入的所有文本的记录，以英文逗号分隔，可下载成CSV文件后用Excel打开": "All texts entered in this group control are recorded, separated by commas, and can be downloaded as CSV files and opened with Excel",
 "以换行符（Shift + Enter）作为分割": "Use line breaks (Shift + Enter) as separators",
 "显示文本输入悬浮窗口": "Display text input floating window",
 "无效的快捷方式": "Invalid shortcut",
 "快捷方式不存在或已过期，请和您的好友联络以获取进一步信息": "The shortcut does not exist or has expired. Please contact your friend for further information.",
 "打开花漾浏览器": "Open the HuaYang Browser",
 "有新版本可以更新，请下载最新版本后，重新安装即可（原有数据将完整保留）": "There is a new version available for update. Please download the latest version and reinstall it (the original data will be fully retained)",
 "可一次创建多个团队成员账户，用逗号分隔": "You can create multiple team member accounts at once, separated by commas.",
 "成员管理": "Membership Management",
 "安全设置": "Security Settings",
 "告警并关闭浏览器窗口": "Alert and close the browser window",
 "仅告警": "Warning only",
 "修改IP监管": "Modify IP supervision",
 "添加IP监管": "Add IP supervision",
 "系统会自动记录指定监管平台所有分身使用过的出口IP，一旦该平台不同浏览器分身在指定时间内使用同一个出口IP时，会产生告警消息": "The system will automatically record the export IP used by all avatars of the specified supervision platform. Once different browser avatars of the platform use the same export IP within a specified time, an alarm message will be generated.",
 "监管平台": "Regulatory Platform",
 "请选择监管平台": "Please select a regulatory platform",
 "监管时长": "Supervision time",
 "请设置监管时长": "Please set the supervision time",
 "IP访问记录的保持时长，最长6个月": "The maximum retention period of IP access records is 6 months",
 "告警动作": "Alarm Action",
 "确定要删除 {{name}} 的IP监管吗？": "Are you sure you want to delete the IP supervision of {{name}}?",
 "系统会同步删除与此平台相关的 IP 访问记录，请确认是否继续": "The system will simultaneously delete the IP access records related to this platform. Please confirm whether to continue",
 "关于 IP 监管": "About IP Supervision",
 "您可以决定是否对某些平台的浏览器分身进行 IP 监管，开启 IP 监管后，系统会自动记录该平台所有分身使用过的出口 IP，方便您日后查询": "You can decide whether to monitor the IP addresses of browser avatars on certain platforms. After IP monitoring is turned on, the system will automatically record the egress IP addresses used by all avatars of the platform, which will facilitate your future inquiries.",
 "同时，一旦有该平台不同浏览器分身使用同一个出口 IP 时，系统会自动产生告警消息": "At the same time, once different browser avatars of the platform use the same export IP, the system will automatically generate an alarm message",
 "{{count}}个月": "{{count}} months",
 "增加新的监管平台": "Adding new regulatory platforms",
 "（已删除）": "(Deleted)",
 "允许登录的平台": "Platforms that allow login",
 "允许登录的时间": "Time allowed to log in",
 "策略组详情": "Policy Group Details",
 "可登录设备": "Login device",
 "当团队成员使用一台已经登录的花漾客户端设备访问团队资源时的运维策略": "Operation and maintenance strategy when a team member uses a logged-in HuaYang client device to access team resources",
 "是否限制团队成员使用的客户端设备：": "Do you want to restrict the client devices used by team members?",
 "只允许指定的客户端设备": "Allow only specified client devices",
 "可登录时间": "Login time",
 "团队成员在访问团队资源时的时间限制": "Time limits for team members to access team resources",
 "是否限制团队成员在何时访问团队资源：": "Whether to restrict team members from accessing team resources at any time:",
 "只允许指定时间段": "Only allow specified time periods",
 "重复时间段": "Repeating time period",
 "请选择时间段": "Please select time period",
 "请指定日期范围": "Please specify a date range",
 "修改策略": "Modify the policy",
 "新增策略": "New strategies",
 "策略名称": "Policy Name",
 "请为运维策略起一个易记忆的名称": "Please give the operation and maintenance strategy a memorable name",
 "可以输入一段描述，方便您区分每个策略的适用场景": "You can enter a description to help you distinguish the applicable scenarios of each strategy",
 "确定删除这个策略吗?": "Are you sure you want to delete this policy?",
 "修改名称及备注": "Modify name and remarks",
 "备注：{{text}}": "Note: {{text}}",
 "只允许指定的时间段登录": "Only allow login during specified time periods",
 "创建新的运维策略": "Creating a new operation and maintenance strategy",
 "运维策略用来限制用户在访问当前团队资源时的可登录设备及可登录时间": "Operation and maintenance policies are used to limit the devices and time users can log in to when accessing the current team resources.",
 "修改后可操作": "Modified and operable",
 "舍弃修改": "Discard changes",
 "未指定最小版本号": "No minimum version number specified",
 "版本号格式不正确": "The version number format is incorrect",
 "允许 {{platform}} 版本的客户端访问分身": "Allow clients of {{platform}} version to access the avatar",
 "只允许高于{{version}}的客户端访问": "Only clients with version higher than {{version}} are allowed to access",
 "您可以设置只允许指定版本的花漾客户端才能够访问本团队内的所有浏览器分身，该设置对团队内所有人（无论超管还是BOSS）都生效，也包括以联营方式访问本团队分身的人员": "You can set the setting to allow only the specified version of the HuaYang client to access all browser avatars in the team. This setting is effective for everyone in the team (whether super administrator or boss), including those who access the team avatars in a joint operating manner.",
 "可以针对每一位团队成员（除超管与BOSS）设置其运维策略": "You can set operation and maintenance strategies for each team member (except the super administrator and boss)",
 "运维策略": "Operation and maintenance strategy",
 "超管与BOSS不受任何运维策略的约束": "Super managers and BOSS are not subject to any operation and maintenance strategy",
 "设备ID：{{id}}": "Device ID: {{id}}",
 "您选择拒绝接收手机，请在下方输入您的拒绝理由：": "You have chosen to decline to receive mobile phone calls. Please enter your reason for decline below:",
 "成员审核": "Member review",
 "是否允许该用户使用新的设备访问当前团队资源": "Whether to allow the user to access the current team resources using a new device",
 "登录地址": "Login address",
 "很多同学希望能够通过程序/脚本控制花漾客户端，如：通过程序/脚本创建或打开浏览器分身等，从而方便与其它系统的整合。 基于此，花漾提供了 Open API 的特性，其实现原理是基于 Access Key 认证体系的 Http 请求/响应机制，理论上允许用户使用任意语言如 Java、Python、NodeJS、C# 等与花漾客户端进行交互。": "Many students hope to be able to control the HuaYang client through programs/scripts, such as creating or opening a browser avatar through programs/scripts, so as to facilitate integration with other systems. Based on this, HuaYang provides the Open API feature, which is implemented based on the Http request/response mechanism of the Access Key authentication system. In theory, it allows users to use any language such as Java, Python, NodeJS, C#, etc. to interact with the HuaYang client.",
 "但需要提醒您的是，通过 Open API 能够完成的事情，绝大部分都可以通过花漾 RPA 完成，相比 Open API，花漾 RPA 使用起来更简单易用。": "But we need to remind you that most of the things that can be done through Open API can also be done through HuaYang RPA. Compared with Open API, HuaYang RPA is simpler and easier to use.",
 "花漾 Open API 使用帮助": "HuaYang Open API User Guide",
 "花漾 Open API 参考指引": "HuaYang Open API Reference Guide",
 "示例：通过 Java 调用花漾 Open API": "Example: Calling the HuaYang Open API via Java",
 "示例：通过 Python 调用花漾 Open API": "Example: Calling the HuaYang Open API via Python",
 "示例：通过 NodeJS 调用花漾 Open API": "Example: Calling HuaYang Open API via NodeJS",
 "示例：通过 Selenium 控制花漾浏览器 （Python）": "Example: Controlling the HuaYang Browser via Selenium (Python)",
 "示例：通过 Selenium 控制花漾浏览器 （Java）": "Example: Controlling the HuaYang Browser via Selenium (Java)",
 "示例：通过 Selenium 控制花漾浏览器 （NodeJS）": "Example: Controlling the HuaYang Browser (NodeJS) via Selenium",
 "用户登录管理": "User login Admin",
 "运维策略管理": "Ops Admin",
 "客户端版本管理": "Client version Admin",
 "IP监管": "IP Supervision",
 "分身标识码": "Avatar Code",
 "是否启用浏览器分身标识码": "Whether to enable browser avatar code?",
 "系统会打开浏览器访问支付宝网站，请您完成支付宝登录；本次登录仅限于实名认证，不会对您的支付宝账号产生任何其它影响": "The system will open a browser to access the Alipay website. Please complete the Alipay login. This login is limited to real-name authentication and will not have any other impact on your Alipay account.",
 "花漾灵动已经和港宝GBS系统打通，韩国主流电商平台的销售订单与国内主流电商的采购订单均可自动同步至港宝GBS系统": "HuaYang Lingdong has been connected with the Hongbao GBS system. Sales orders from mainstream Korean e-commerce platforms and purchase orders from mainstream domestic e-commerce platforms can be automatically synchronized to the Hongbao GBS system.",
 "韩国电商全平台物流订单同步系统": "Korean e-commerce full platform logistics order synchronization system",
 "团队类型": "Team Type",
 "您的TikTok店铺达人自动邀约套餐已过期，如需要继续使用，请续费以恢复套餐": "Your TikTok store influencer automatic invitation package has expired. If you need to continue using it, please renew to restore the package",
 "到TikTok达人自动邀约系统进行续费": "Go to TikTok's automatic invitation system to renew",
 "手机数量配额": "Mobile phone quota",
 "本团队由KOL私域管理系统创建，有分身数量最大配额、手机数量最大配额与团队成员最大配额的限制，更进一步的信息请到KOL私域管理系统中查询": "This team is created by the KOL Private Domain Management System. There are restrictions on the maximum number of avatars, the maximum number of mobile phones, and the maximum number of team members. For further information, please go to the KOL Private Domain Management System",
 "不支持修改": "Modification is not supported",
 "确定要删除部门“{{name}}吗？": "Are you sure you want to delete department \"{{name}}\"?",
 "切换至{{type}}": "Switch to {{type}}",
 "请使用Kakao Talk扫一扫完成注册/登录": "Please use Kakao Talk to scan and complete registration/login",
 "的用户": "Users",
 "定位机制": "Positioning mechanism",
 "恢复群控": "Restore group control",
 "快捷键设置": "Shortcut key settings",
 "暂停群控": "Pause group control",
 "输入文本": "Enter text",
 "花瓣告警策略": "Token Alert Strategy",
 "您可以设置当因为云盘空间产生的每日花瓣消耗大于某个数值时，产生关键事项检查的告警消息": "You can set an alarm message to generate a critical matter check when the daily Token consumption caused by the cloud disk space exceeds a certain value",
 "每日消耗花瓣的告警值": "Daily Token consumption warning value",
 "分身相关数据": "Data about avatars",
 "和浏览器分身相关的数据存储，包括云端的缓存数据，以及快照备份产生的相关数据": "Data storage related to browser avatars, including cached data in the cloud and related data generated by snapshot backups",
 "免费存储配额：{{amount}}GB": "Free storage quota: {{amount}} GB",
 "存储空间单价：{{price}}花瓣/GB/天": "Storage space unit price: {{price}} Tokens/GB/day",
 "当因为云盘空间产生的每日花瓣消耗大于此数值时，产生关键事项检查的告警消息": "When the daily Token consumption caused by the cloud disk space exceeds this value, an alarm message for key matters check is generated",
 "每日消耗的花瓣告警值": "Daily Token consumption alarm value",
 "{{amount}} 花瓣": "{{amount}} Tokens",
 "共{{count_0}}个文件夹，{{count_1}}个文件，已选中{{count_2}}个文件（夹）": "There are {{count_0}} folders, {{count_1}} files, {{count_2}} files (folders) have been selected",
 "\"文件内无文件可下载": "\"No files in the file can be downloaded",
 "{{amount}}周": "{{amount}} weeks",
 "{{amount}}月": "{{amount}} months",
 "{{amount}}年": "{{amount}} years",
 "请输入正确的中国手机号": "Please enter a valid Chinese mobile phone number",
 "该团队拒绝您的访问": "The team has denied your access",
 "只允许指定的时间日期访问": "Only allow access during specified time and dates",
 "新的花漾客户端设备需要通过审核": "New HuaYang client devices need to pass the review",
 "只允许指定的花漾客户端设备访问": "Only allow designated HuaYang client devices to access",
 "拒绝原因": "Reason for rejection",
 "请点击“刷新”以查看审核结果": "Please click \"Refresh\" to view the review results",
 "请和团队管理员联系以获取进一步信息": "Please contact your team administrator for further information",
 "您可以进入“TK店铺达人邀约系统”续费，也可以暂时只使用花漾浏览器继续管理您的店铺": "You can enter the \"TK Store System\" to renew, or you can temporarily use the HuaYang Browser to continue managing your store.",
 "暂不续费": "Not renewing yet"
};
