import type { TreeDataNode } from 'antd';
import { Typography } from 'antd';
import IconFontIcon from '@/components/Common/IconFontIcon';
import TeamAvatar from '@/components/Common/TeamAvatar';
import { Avatar } from '@/components/Common/UserAvatarAndName';
import { history } from 'umi';
import constants from '@/constants';
import React from 'react';
import { getUrlOfCurrentTeam } from './utils';

export interface DepartmentTreeNodeData extends TreeDataNode {
  id: number;
  key: string;
  isMember?: boolean;
  memberCount?: number;
  parentId: string;
  children?: DepartmentTreeNodeData[];
  userVo?: API.UserVo;
}

interface TreeSelectDataNode {
  value: string;
  title: React.ReactNode;
  children?: TreeSelectDataNode[];
}

// 将后台返回的部门树上的成员列表转换成tree需要的数据
const treeTransformMembers = (members: API.UserVo[], parentId: string) => {
  return members.map((node) => {
    const icon = <Avatar size={24} src={node.avatar || constants.defaultAvatar} />;
    const treeNode: DepartmentTreeNodeData = {
      id: node.id ?? 0,
      title: node.nickname,
      isMember: true,
      parentId,
      icon,
      key: `user-${parentId}-${node.id}`,
      userVo: node,
    };
    return treeNode;
  });
};

// 在后台返回的部门树中搜索成员
export const searchMember = (data: API.DepartmentTreeNode[], searchKeyword: string) => {
  let nodes: DepartmentTreeNodeData[] = [];
  data.forEach((o) => {
    const node = { ...o };
    if (node.childList) {
      const childMembers = searchMember(node.childList, searchKeyword);
      if (childMembers.length > 0) {
        nodes = nodes.concat(childMembers);
      }
    }
    if (node.members) {
      const members = node.members.filter(
        (o1) =>
          (o1.nickname?.indexOf(searchKeyword) ?? -1) > -1 ||
          (o1.phone?.indexOf(searchKeyword) ?? -1) > -1,
      );
      if (members.length > 0) {
        nodes = nodes.concat(treeTransformMembers(members, node.id?.toString() ?? ''));
      }
    }
  });

  return nodes;
};

// 将后台返回的部门树转换成tree需要的树
export const treeTransform = (
  data: API.DepartmentTreeNode[],
  teamInfo: API.TeamDto,
  showMember?: boolean,
) => {
  return data.map((node) => {
    let icon = <IconFontIcon iconName="shuzhuangxingshi_24" />;
    if (node.parentId === 0) {
      icon = <TeamAvatar team={teamInfo} size={24} className="team-name" />;
    }
    const treeNode: DepartmentTreeNodeData = {
      id: node.id ?? 0,
      title: node.name,
      memberCount: node.memberCount || 0,
      parentId: node.parentId?.toString() ?? '',
      icon,
      isLeaf: false,
      key: node.id?.toString() ?? '',
    };
    if (node.childList) {
      treeNode.children = treeTransform(node.childList, teamInfo, showMember);
    }
    if (node.members && showMember) {
      const children = treeTransformMembers(node.members, node.id?.toString() ?? '');
      if (!treeNode.children) {
        treeNode.children = children;
      } else {
        treeNode.children = treeNode.children.concat(children);
      }
    }
    return treeNode;
  });
};

// 将后台返回的部门树转换成TreeSelect需要的树
export const treeSelectTransform = (data: API.DepartmentTreeNode[], teamInfo: API.TeamDto) => {
  return data.map((node) => {
    let icon = <IconFontIcon iconName="shuzhuangxingshi_24" />;
    if (node.parentId === 0) {
      icon = <TeamAvatar team={teamInfo} size={24} className="team-name" />;
    }
    const treeNode: TreeSelectDataNode = {
      title: (
        <div className="tree-select-node">
          <div
            style={{ flex: 1, gap: 4, overflow: 'hidden', display: 'flex', alignItems: 'center' }}
          >
            {icon}
            <Typography.Text
              style={{ flex: 1, overflow: 'hidden' }}
              ellipsis={{ tooltip: node.name }}
            >
              {node.name}
            </Typography.Text>
          </div>
          <IconFontIcon iconName="Check-Circle_24" className="tree-select-node-checked" />
        </div>
      ),
      value: node.id?.toString() ?? '',
    };
    if (node.childList) {
      treeNode.children = treeSelectTransform(node.childList, teamInfo);
    }
    return treeNode;
  });
};

// 在整个部门树上找到所有节点的ID
export const getAllNodeIds = (data: API.DepartmentTreeNode[]) => {
  let nodeIds: string[] = [];
  data.forEach((o) => {
    nodeIds.push(o.id?.toString() ?? '');
    if (o.childList) {
      nodeIds = nodeIds.concat(getAllNodeIds(o.childList));
    }
  });

  return nodeIds;
};

// 在整个部门树上找到指定节点的所有父节点
export const getAllParentIds = (
  treeResult: API.DepartmentTreeNode[],
  currentDepartmentId: string | null,
  path: string[] = [],
) => {
  let newPath: string[] = [...path];
  let find = false;
  // 遍历每个根节点
  treeResult.some((o) => {
    // 如果当前节点就是指定部门，则返回当前路径
    if (o.id?.toString() === currentDepartmentId) {
      find = true;
      return true;
    }
    // 不匹配当前节点，继续查找子节点
    if (o.childList) {
      const childPath = getAllParentIds(
        o.childList,
        currentDepartmentId,
        path.concat([o.id?.toString() ?? '']),
      );
      if (childPath) {
        find = true;
        newPath = childPath;
        return true;
      }
    }
    return false;
  });
  if (find) {
    return newPath;
  }

  return false;
};

// 在整个部门树上找到指定节点
export const getNodeById = (
  treeNodes: DepartmentTreeNodeData[],
  currentDepartmentId: string | null,
) => {
  if (!currentDepartmentId) {
    return treeNodes[0];
  }
  let node: DepartmentTreeNodeData | null = null;
  let find = false;
  // 遍历每个根节点
  treeNodes.some((o) => {
    // 如果当前节点就是指定部门，则返回当前节点
    if (o.key === currentDepartmentId) {
      find = true;
      node = o;
      return true;
    }
    // 不匹配当前节点，继续查找子节点
    if (o.children) {
      const childFindResult = getNodeById(o.children, currentDepartmentId);
      if (childFindResult) {
        find = true;
        node = childFindResult;
        return true;
      }
    }
    return false;
  });
  if (find) {
    return node;
  }

  return false;
};

// 统计当前部门及所有子部门的成员数
const countDepartmentMember = (
  treeNode: DepartmentTreeNodeData, // 部门节点
) => {
  let count = treeNode.memberCount ?? 0;
  if (treeNode.children) {
    treeNode.children.forEach((o: DepartmentTreeNodeData) => {
      count += countDepartmentMember(o);
    });
  }
  return count;
};

// 统计指定部门的所有子部门成员数，不包含指定部门直接下属
export const countSubDepartmentMember = (
  treeNodes: DepartmentTreeNodeData[], // 整个部门树
  currentDepartmentId: string | null, // 指定部门的ID
) => {
  const node = getNodeById(treeNodes, currentDepartmentId);
  let count = 0;
  if (node && node.children) {
    node.children.forEach((o: DepartmentTreeNodeData) => {
      count += countDepartmentMember(o);
    });
  }
  return count;
};

// 统计指定部门的直接成员数
export const countDirectMember = (
  treeNodes: DepartmentTreeNodeData[],
  currentDepartmentId: string | null,
) => {
  // treeNodes中根节点的memberCount就是整个团队的人数
  if (!currentDepartmentId) {
    return treeNodes[0].memberCount ?? 0 - countSubDepartmentMember(treeNodes, currentDepartmentId);
  }
  const node = getNodeById(treeNodes, currentDepartmentId);
  return node ? node.memberCount : 0;
};

//  查找当前部门及所有子部门的成员
const getDepartmentMembers = (
  treeNode: DepartmentTreeNodeData, // 部门节点
) => {
  let members: DepartmentTreeNodeData[] = [treeNode];
  if (treeNode.children) {
    treeNode.children.forEach((o: DepartmentTreeNodeData) => {
      members = members.concat(getDepartmentMembers(o));
    });
  }
  return members;
};

// 查找指定部门下的所有成员列表，包含指定部门直接下属
export const getAllMembers = (
  treeNodes: DepartmentTreeNodeData[], // 整个部门树
  currentDepartmentId: string | null, // 指定部门的ID
) => {
  const node = getNodeById(treeNodes, currentDepartmentId);
  if (node) {
    return getDepartmentMembers(node);
  }
  return [];
};

// 检查节点是不是用户节点
export const isMemberNode = (nodeId: React.Key) => {
  return nodeId.toString().indexOf('user-') === 0;
};

// 用户节点Id转换成数字id（number类型）
export const getUserIdFromNodeId = (nodeId: React.Key) => {
  const userId = nodeId.toString().replace(/user-\d+-/, '');
  return parseInt(userId, 10);
};

// 将已选节点列表换化成用户id列表
export const getUserIdsFromCheckedKeys = (checkedKeys: React.Key[]) => {
  return Array.from(
    new Set(checkedKeys.filter((o) => o.toString().indexOf('user') > -1).map(getUserIdFromNodeId)),
  );
};

// 将已选节点列表换化成部门id列表
export const getDepartmentIdsFromCheckedKeys = (checkedKeys: React.Key[]) => {
  return Array.from(new Set(checkedKeys.filter((o) => o.toString().indexOf('user') < 0)));
};

export const getUserManageMode = (teamId: number) => {
  return localStorage.getItem(`team_${teamId}_usermanage_mode`);
};

export const setUserManageMode = (teamId: number, mode: string) => {
  return localStorage.setItem(`team_${teamId}_usermanage_mode`, mode);
};

export const gotoDepartment = (teamId: number, targetDepartmentId: string) => {
  setUserManageMode(teamId, 'department');
  history.push(getUrlOfCurrentTeam(`/team/:teamId/teamManage/users/${targetDepartmentId}`, teamId));
};

const findNodeInItem = (item: API.DepartmentTreeNode, id: number) => {
  const path: API.DepartmentTreeNode[] = [];
  const interval = (node: API.DepartmentTreeNode) => {
    if (node?.childList?.length) {
      let result = false;
      for (let j = 0; j < node.childList.length; j++) {
        const child = node.childList[j];
        if (child.id === id) {
          result = true;
          path.push(_.pick(node, ['id', 'name', 'parentId']));
          path.push(_.pick(child, ['id', 'name', 'parentId']));
          break;
        } else {
          result = interval(child);
          if (result) {
            path.unshift(_.pick(node, ['id', 'name', 'parentId']));
          }
        }
      }
      return result;
    }
    return false;
  };
  interval(item);
  if (path.length) {
    return path;
  }
  return false;
};
export const getPaths = (list: API.DepartmentTreeNode[], id: number) => {
  let path: API.DepartmentTreeNode[] = [];
  for (let i = 0; i < list.length; i++) {
    const item = list[i];
    if (item.id === id) {
      path = [_.pick(item, ['id', 'name', 'parentId'])];
      break;
    } else {
      const result = findNodeInItem(item, id);
      path = result || [];
    }
  }
  return path;
};
