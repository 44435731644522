import I18N from '@/i18n';
import { Statistic, Typography } from 'antd';
import colors from '@/style/color.less';
import { useMemo } from 'react';

const Credit = (props: {
  value: any;
  size?: number | string | 'inherit';
  suffix?: any;
  type?: 'success' | 'warning' | 'danger';
}) => {
  const { value, size = 30, suffix = I18N.t('个'), type = 'warning' } = props;
  const precision = useMemo(() => {
    if (String(value).split('.')[1]?.length > 2) {
      return 2;
    }

    return undefined;
  }, [value]);
  const color = useMemo(() => {
    if (type === 'danger') {
      return colors.errorColor;
    } else if (type === 'success') {
      return colors.successColor;
    }
    return colors.warningColor;
  }, [type]);
  return (
    <Statistic
      precision={precision}
      value={value}
      suffix={suffix ? <Typography.Text style={{ fontSize: 12 }}>{suffix}</Typography.Text> : null}
      valueStyle={{
        color,
        fontSize: size,
      }}
    />
  );
};
export default Credit;
