import { Col, Row, Tree, Typography } from 'antd';
import { treeTransform } from '@/utils/departmentUtils';
import useCurrentTeam from '@/hooks/useCurrentTeam';
import { useRequest } from '@@/plugin-request/request';
import { departmentTreeGet as tree } from '@/services/api-Account/DepartmentController';
import type { Key } from 'react';
import IconFontIcon from '@/components/Common/IconFontIcon';
import styles from './index.less';

interface Props {
  selectedKeys: string[];
  onSelect: (keys: string[], rows: any[]) => void;
  onInit: (rows: any[]) => void;
}

function findRows(data: any[], selectedKeys: Key[]) {
  const rows: any[] = [];
  const fn = (list: any[]) => {
    list.forEach((node) => {
      if (selectedKeys.includes(String(node.id))) {
        rows.push(node);
      }
      if (node.children) {
        fn(node.children);
      }
    });
  };
  fn(data);
  return rows;
}
/**
 * 部门树
 * @param props
 * @constructor
 */
const DepartmentTree = (props: Props) => {
  const { onSelect, selectedKeys, onInit } = props;
  const { team } = useCurrentTeam();
  const { data: treeData } = useRequest(() => tree({}), {
    formatResult: (rs) => {
      const treeNode = treeTransform(rs.data!, team);
      onInit(findRows(treeNode, selectedKeys));
      return treeNode;
    },
  });
  if (!treeData) {
    return null;
  }
  return (
    <Tree
      defaultExpandAll
      className={styles.departmentTree}
      showLine
      selectable={false}
      checkStrictly
      checkable
      blockNode
      checkedKeys={selectedKeys}
      onCheck={(keys, info) => {
        const { checkedNodes } = info;
        onSelect(keys.checked, checkedNodes);
      }}
      treeData={treeData}
      switcherIcon={
        <div>
          <IconFontIcon iconName="angle-down_24" />
          <IconFontIcon iconName="angle-right_24" />
        </div>
      }
      titleRender={(node: any) => {
        return (
          <Row
            className={styles.departmentNode}
            gutter={[8, 8]}
            align="middle"
            wrap={false}
            style={{ overflow: 'hidden', width: '100%' }}
          >
            <Col>{node.icon}</Col>
            <Col flex={1} style={{ overflow: 'hidden' }}>
              <Typography.Text ellipsis>{node.title}</Typography.Text>
            </Col>
          </Row>
        );
      }}
    />
  );
};
export default DepartmentTree;
