import I18N from '@/i18n';
import type { ListObjectResult } from 'ali-oss';
import OSS from 'ali-oss';
import { diskPreSignUrlGet, diskTeamListGet } from '@/services/api-DiskAPI/DiskController';
import { getTeamIdFromUrl, SkipErrorNotifyOption } from '@/utils/utils';
import { message } from 'antd';
import CryptoJS from 'crypto-js';
import _ from 'lodash';
import DMConfirm from '@/components/Common/DMConfirm';
import { isElectron, sendAsync } from '@/utils/ElectronUtils';
import { getFileExt } from '@/pages/Yunpan/components/FileIcon';
import { lookup } from 'mrmime';
import { history } from '@@/core/history';
import { useCallback } from 'react';

export type FileRecord = {
  name: string;
  directory?: boolean;
  type?: string;
  size?: number;
  path: string;
  lastModified?: string;
  originPath: string;
};

export function getPaths(name: string) {
  return name.split('/');
}
export function getReadableName(name: string) {
  const ps = name.split('/');
  let last = ps.pop();
  if (last === '') {
    last = ps.pop();
  }
  return last as string;
}

export function isDirectory(path: string) {
  return path.endsWith('/');
}

export function getReadableDirectory(name: string) {
  const paths = getPaths(name);
  const { length } = paths;
  let startIndex = 2;
  const endIndex = isDirectory(name) ? length - 2 : length - 1;
  let prefix = I18N.t('团队云盘');
  if (name.startsWith('/rpa')) {
    prefix = I18N.t('RPA任务');
    startIndex += 1;
  } else {
    const type = paths[0];
    if (type === 'user_disk') {
      startIndex += 1;
      prefix = I18N.t('我的云盘');
    } else if (type === 'team_disk') {
      prefix = I18N.t('团队云盘');
    } else if (type === 'record') {
      prefix = I18N.t('审计录像');
    } else if (type === 'shop-data') {
      prefix = I18N.t('分身数据');
    }
  }
  let path = `/${paths.slice(startIndex, endIndex).join('/')}`;
  path = (prefix + path).replace('//', '/');
  if (path.endsWith('/')) {
    path = path.slice(0, path.length - 1);
  }
  return path;
}
export function getDirectory(name: string) {
  const paths = getPaths(name);
  const { length } = paths;
  const startIndex = 0;
  const endIndex = isDirectory(name) ? length - 2 : length - 1;
  const path = `${paths.slice(startIndex, endIndex).join('/')}/`;
  return path.replace('//', '/');
}

export function getRootPath(name: string) {
  const diskType = getDiskType(name);
  const paths = getPaths(name);
  return `${paths.slice(0, diskType === 'TeamDisk' ? 2 : 3).join('/')}/`;
}
export function getPathWithoutRoot(name: string) {
  const root = getRootPath(name);
  return name.replace(root, '');
}
export async function getAuthPaths(p: string) {
  const type = getDiskType(p || '');
  if (type === 'TeamDisk') {
    return diskTeamListGet(
      {
        path: p,
      },
      SkipErrorNotifyOption,
    ).then((_res) => {
      return (
        _res.data?.files?.map((item) => {
          return item.path!;
        }) || []
      );
    });
  }
  return undefined;
}
export async function generateTableMeta(res: ListObjectResult, authedPaths?: string[]) {
  const { objects, prefixes } = res;
  const list: FileRecord[] = [];
  const startWithPath = (p: string) => {
    return authedPaths?.some((_p) => _p.startsWith(p));
  };
  prefixes?.forEach((path) => {
    if (startWithPath(path) || !authedPaths) {
      list.push({
        name: getReadableName(path),
        directory: true,
        path: getPathWithoutRoot(path),
        originPath: path,
      });
    }
  });
  objects?.forEach((meta) => {
    const { lastModified, name, size, type } = meta;
    if (!isDirectory(name)) {
      if (startWithPath(name) || !authedPaths) {
        list.push({
          name: getReadableName(name),
          size,
          type,
          lastModified,
          directory: false,
          path: getPathWithoutRoot(name),
          originPath: name,
        });
      }
    }
  });

  return list;
}

export function generatePath(prefix: string, path: string, directory?: boolean) {
  return `${prefix ? `${prefix}/` : ''}${path}${directory ? '/' : ''}`.replace('//', '/');
}

export function getRelativePath(base: string, path: string) {
  return path.startsWith(base) ? path.replace(base, '') : path;
}

export function createOssClient(
  stsVo: API.DiskStsVo,
  getSignature: () => Promise<API.DiskStsVo>,
): OSS & { baseDir: string } {
  const { stsToken, baseDir, downloadEndpoint } = stsVo;
  const oss = new OSS({
    region: stsToken.region,
    endpoint: downloadEndpoint,
    accessKeyId: stsToken.accessKeyId,
    accessKeySecret: stsToken.accessKeySecret,
    bucket: stsToken.bucketName,
    cname: true,
    stsToken: stsToken.securityToken,
    secure: true,
    refreshSTSToken: async () => {
      const { stsToken: newToken } = await getSignature();
      return {
        accessKeyId: newToken.accessKeyId,
        accessKeySecret: newToken.accessKeySecret,
        stsToken: newToken.securityToken,
      };
    },
  });
  oss.baseDir = baseDir;
  return oss;
}

export function getDiskType(name: string) {
  if (name.startsWith('team_disk/')) {
    return 'TeamDisk';
  }
  if (name.startsWith('user_disk/')) {
    return 'UserDisk';
  }
  if (name.startsWith('attachment://')) {
    return 'RpaAttachment';
  }
  if (name.startsWith('/rpa/')) {
    return 'RpaFile';
  }
  if (name.startsWith('record/')) {
    return 'RecordSlice';
  }
  return undefined;
}

export function getDirectoryArrayForCreate(files: FileList | File[]) {
  const list: string[] = [];
  if (files.length) {
    for (let i = 0; i < files.length; i++) {
      const deepFile = files[i];
      const path = deepFile.webkitRelativePath;
      const pathSnippets = getPaths(path).filter((value: string) => value);
      pathSnippets.pop();
      pathSnippets.forEach((value, index) => {
        const url = `${pathSnippets.slice(0, index + 1).join('/')}/`;
        if (!_.includes(list, url)) {
          list.push(url);
        }
      });
    }
  }
  return _.uniq(list);
}

// 加密
export function encrypt(txt: string) {
  const src = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encodeURIComponent(txt)));
  return src.toString();
}

// 解密
export function decrypt(txt: string) {
  return decodeURIComponent(CryptoJS.enc.Base64.parse(txt).toString(CryptoJS.enc.Utf8));
}

export async function download(name: string) {
  return await diskPreSignUrlGet({ name, diskType: getDiskType(name) }, SkipErrorNotifyOption).then(
    (res) => res.data!,
  );
}

async function downloadOssDirectory(path: string, ossClient: OSS, savePath: string) {
  const res = await ossClient?.list(
    {
      prefix: path,
      'max-keys': 1000,
    },
    { timeout: 10 * 1000 },
  );
  const paths = getPaths(path);
  const prefix = `${paths.slice(0, paths.length - 2).join('/')}/`;
  // directory 文件夹内所有的文件，平铺展开
  const files = _.filter(res?.objects || [], ({ name }) => !isDirectory(name));
  if (files.length) {
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      const { name } = f;
      // eslint-disable-next-line no-await-in-loop
      const directoryName = name ? getRelativePath(prefix, getDirectory(name)) : undefined;
      appDownload({ name, savePath, fileName: getReadableName(name), directoryName });
    }
  } else {
    throw new Error(`"${getReadableName(path)}${I18N.t('"文件内无文件可下载')}`);
  }
}

/**
 * 客户端
 * 下载文件或文件夹
 * @param path
 * @param client
 * @param savePath
 */
function appDownloadFileOrDirectory(path: string, client: OSS, savePath: string) {
  if (isDirectory(path)) {
    downloadOssDirectory(path, client, savePath);
  } else {
    appDownload({ name: path, savePath, fileName: getReadableName(path) });
  }
}

export async function downloadFiles(
  paths: string[],
  getClient: (path: string) => OSS & { baseDir: string },
) {
  const hasDirectory = _.some(paths, (item) => isDirectory(item));
  if (!isElectron() && hasDirectory) {
    const title = I18N.t('您要下载的文件包含文件夹');
    DMConfirm({
      type: 'info',
      title,
      content: I18N.t(
        '请下载花漾客户端并通过花漾客户端完成文件的上传和下载，这样会带来更好的传输速率与操作体验',
      ),
    });
  } else if (isElectron()) {
    // 客户端
    const savePath = await selectSavePath();
    try {
      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        const client = getClient(path);
        // eslint-disable-next-line no-await-in-loop
        appDownloadFileOrDirectory(path, client, savePath);
      }
    } catch (e) {
      message.error(e.toString());
    }
  } else {
    // 浏览器内
    try {
      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        download(path).then((url) => {
          downloadByIframe(url);
        });
      }
    } catch (e) {
      message.error(e.toString());
    }
  }
}
type DownloadOption = {
  name?: string;
  url?: string;
  fileName?: string;
  savePath?: string;
  directoryName?: string;
};
async function appDownload(downOption: DownloadOption) {
  const { name, url, fileName, savePath, directoryName } = downOption;
  let _url = url;
  if (name) {
    _url = await download(name);
  }
  if (_url) {
    try {
      sendAsync('disk-file-download', {
        url: _url,
        fileName,
        savePath,
        relativePath: directoryName,
      });
    } catch (e) {
      console.log(e);
    }
  }
}

/**
 * 选择保存路径
 */
function selectSavePath() {
  return sendAsync('disk-file-path-select');
}

export async function singleFileDownload(opt: { name?: string; url?: string; fileName?: string }) {
  const { name, url, fileName } = opt;
  if (isElectron()) {
    const savePath = await selectSavePath();
    if (!savePath) return;
    appDownload({ name, url, fileName, savePath });
  } else {
    downloadByIframe(url ?? (await download(name!)));
  }
}

export function downloadByIframe(src: string) {
  const iframe = document.createElement('iframe');
  iframe.style.display = 'none';
  iframe.src = src;
  document.body.appendChild(iframe);
  setTimeout(() => {
    iframe.remove();
  }, 1000);
}

/**
 * iframe 下载可能会被浏览器拦截
 * @param src
 */
export function downloadByLink(src: string) {
  const alink = document.createElement('a');
  alink.href = src;
  alink.style.overflow = 'hidden';
  alink.style.width = '0px';
  alink.style.height = '0px';
  alink.style.visibility = 'hidden';
  alink.style.fontSize = '0px';
  alink.download = 'download';
  document.body.appendChild(alink);
  alink.click();
  setTimeout(() => {
    alink.remove();
  }, 2000);
}
export function getSessionIdByPath(path: string) {
  const arr = path.split('.');
  const target = _.find(arr, (item) => item.includes('-'));
  if (target) {
    return target.split('-')[1];
  }
  return undefined;
}
export function getRpaTaskIdByPath(path: string) {
  const arr = path.split('/');
  return arr[3];
}
export function canPreview(path: string) {
  const ext = String(getFileExt(path)).toLowerCase();
  return (
    ![
      'doc',
      'docx',
      'ppt',
      'pptx',
      'xls',
      'xlsx',
      'bmpr',
      'exe',
      'iso',
      'rar',
      'zip',
      '7z',
      'war',
      'jar',
      'exe',
      'dll',
      'bin',
      'so',
      'dmg',
      'msi',
      'sys',
      'com',
      'obj',
      'res',
    ].includes(ext) && ext
  );
}
export function oversizeWhenPreview(size: number) {
  return size - 20 * 1024 * 1024 > 0;
}

export const getMimetype = (path: string) => {
  const preset = 'text/plain';
  const result = lookup(path);
  if (path.endsWith('.ts') || path.endsWith('.tsx')) {
    return preset;
  }
  if (result === 'text/html' && !path.includes('.pdf')) {
    return preset;
  }
  if (
    result &&
    [
      'application/xml',
      'application/javascript',
      'application/ecmascript',
      'application/json',
      'application/xml',
    ].includes(result)
  ) {
    return preset;
  }
  return result || preset;
};

export function previewFile(opt: {
  path: string;
  size?: number;
  name?: string;
  url?: string;
  oss?: OSS;
}) {
  const { path, size, name, url, oss } = opt;
  let _url = url;
  if (canPreview(path)) {
    const isVideo = lookup(path)?.includes('video');
    const mimetype = getMimetype(path);
    if (oss) {
      _url = oss.signatureUrl(path, {
        response: {
          'content-disposition': 'inline',
          'content-type': `${mimetype};charset=utf-8`,
        },
      });
    }
    if (size && oversizeWhenPreview(size) && !isVideo) {
      DMConfirm({
        width: 600,
        title: I18N.t('文件体积过大，不支持预览'),
        content: I18N.t('文件体积大于20M无法预览，请您下载后再自行查看'),
        type: 'info',
      });
    } else if (isElectron()) {
      sendAsync('disk-file-preview', {
        path: encodeURIComponent(path),
        name,
        url: encodeURIComponent(url ?? ''),
        host: `${window.location.protocol}//${
          window.location.host
        }/team/${getTeamIdFromUrl()}/preview`,
      });
    } else {
      window.open(_url);
    }
  } else {
    DMConfirm({
      type: 'info',
      width: 600,
      title: I18N.t('不支持预览的文件类型'),
      content: I18N.t('该文件类型不支持预览，请您下载后再自行查看'),
    });
  }
}

export function redirectYunpan(p: string) {
  history.push(`/team/${getTeamIdFromUrl()}/teamManage/yunpan/${p}`.replaceAll('//', '/'));
}
