import I18N from '@/i18n';
import { FC, useState, useEffect } from 'react';
import _ from 'lodash';

import styles from './messageDetail.less';
import {
  paymentFindOrderResourcesGet,
  paymentOrdersByOrderIdDetailGet,
} from '@/services/api-PaymentAPI/PaymentController';
import PayConstans from '@/constants/PayConstans';
import { PayStatusEnum } from '@/pages/Cost/constants';

type Props = {
  message: API.UserMessageVo;
  serialNumber?: string;
  resourceCount?: number;
  goto: (path: string) => void;
};

/**
 * 订单详情表格
 * @param props
 * @constructor
 */
const OrderDetailTable: FC<Props> = (props) => {
  const { message, serialNumber, resourceCount, goto } = props;
  const [resourceDetail, setResourceDetail] = useState<any>(null);
  const [count, setCount] = useState<number | string | undefined>(resourceCount);

  const { resourceId, resourceType, parameters } = message;

  let _parameters: { serialNumber?: string };
  try {
    _parameters = JSON.parse(parameters!);
  } catch (e) {
    _parameters = {};
  }
  if (!_parameters.serialNumber) {
    _parameters.serialNumber = serialNumber;
  }
  useEffect(() => {
    (async () => {
      try {
        if (resourceType === 'Orders') {
          const { data: orderDetail } = await paymentOrdersByOrderIdDetailGet({
            orderId: resourceId!,
          });
          setResourceDetail(orderDetail ?? null);
          if (_.isUndefined(resourceCount)) {
            const { data: orderResourceListRs } = await paymentFindOrderResourcesGet({
              orderIds: resourceId!,
            });
            if (orderResourceListRs) {
              const orderResourceList = orderResourceListRs
                ? orderResourceListRs[resourceId || 0] ?? []
                : [];
              if (!orderResourceList.some((resource: any) => resource.goodsType !== 'Credit')) {
                // 购买花瓣
                setCount(
                  `${_.sumBy(orderResourceList, (resource: any) => resource.count)}${I18N.t(
                    '花瓣',
                  )}`,
                );
              } else {
                setCount(orderResourceList.length);
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [resourceId, resourceType, resourceCount]);

  if (!resourceDetail) return null;
  return (
    <div className={styles.table}>
      <div className={styles.tableRow}>
        <div>{I18N.t('订单编号')}</div>
        <div>
          <a
            onClick={() =>
              goto(
                `/costManage/order?serialNumber=${
                  _parameters.serialNumber ?? resourceDetail.serialNumber
                }`,
              )
            }
          >
            {_parameters.serialNumber ?? resourceDetail.serialNumber}
          </a>
        </div>
      </div>
      <div className={styles.tableRow}>
        <div>{I18N.t('订单类型')}</div>
        <div>{PayConstans.orderType[resourceDetail.orderType] ?? '--'}</div>
      </div>
      {count > 0 && (
        <div className={styles.tableRow}>
          <div>{I18N.t('商品明细')}</div>
          <div>{count}</div>
        </div>
      )}

      <div className={styles.tableRow}>
        <div>{I18N.t('应付金额')}</div>
        <div>{resourceDetail.totalPrice ? `￥${resourceDetail.totalPrice.toFixed(2)}` : '--'}</div>
      </div>
      <div className={styles.tableRow}>
        <div>{I18N.t('实付金额')}</div>
        <div>
          {resourceDetail.payablePrice ? `￥${resourceDetail.payablePrice.toFixed(2)}` : '--'}
        </div>
      </div>
      <div className={styles.tableRow}>
        <div>{I18N.t('支付状态')}</div>
        <div className={resourceDetail.payStatus}>
          {PayStatusEnum[resourceDetail.payStatus]?.text ?? '--'}
        </div>
      </div>
    </div>
  );
};

export default OrderDetailTable;
