import { useState, useCallback } from 'react';
import EventEmitter from 'events';

const emitter = new EventEmitter();

export default () => {
  const [visible, setVisible] = useState(false);
  const [unreadMsgCountList, setUnreadMsgCountList] = useState<API.UserUnreadMsgCountVo[]>([]);

  const show = useCallback(() => {
    setVisible(true);
  }, []);

  const hide = useCallback(() => {
    setVisible(false);
  }, []);

  return {
    visible,
    show,
    hide,
    unreadMsgCountList,
    setUnreadMsgCountList,
    emitter,
  };
};
