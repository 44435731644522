import I18N from '@/i18n';
import DMModal from '@/components/Common/Modal/DMModal';
import { Button, Col, message, Row, Space, Typography } from 'antd';
import ImageCropper from '@/components/Common/ImageCropper';
import IconFontIcon from '@/components/Common/IconFontIcon';
import type { ReactNode } from 'react';
import { useRef, useState } from 'react';
import constants from '@/constants';

/**
 * 上传头像
 * @param props
 * @constructor
 */
const AvatarModal = (props: {
  onSubmit: (data: string) => Promise<void>;
  avatar?: string;
  title: ReactNode;
}) => {
  const [visible, changeVisible] = useState(true);
  const { onSubmit, avatar, title } = props;
  const avatarSelector = useRef<HTMLInputElement>();
  const [avatarUrl, setAvatarUrl] = useState(avatar);
  const avatarTmp = useRef<string>();
  const handleImageChange = (e: any) => {
    const { files } = e.target;
    if (files && files.length) {
      const file = files[0];
      // 校验文件大小
      if (file.size > 10 * 1000 * 1000) {
        message.error(I18N.t('文件大小超过限制'));
        return;
      }
      setAvatarUrl(URL.createObjectURL(file));
    }
  };

  const onCropFinish = (dataUrl: string) => {
    avatarTmp.current = dataUrl;
  };
  return (
    <DMModal
      title={title}
      destroyOnClose
      visible={visible}
      onCancel={() => {
        changeVisible(false);
      }}
      onOk={async () => {
        if (!avatarTmp.current || avatarTmp.current === constants.defaultAvatar) {
          changeVisible(false);
        } else {
          await onSubmit(avatarTmp.current);
          changeVisible(false);
        }
      }}
    >
      <Row>
        <Col span={12}>
          <ImageCropper
            url={avatarUrl}
            onCropFinish={onCropFinish}
            placeholder={
              <Button
                icon={<IconFontIcon iconName="shangchuan_24" />}
                onClick={() => avatarSelector.current?.click()}
              >
                {I18N.t('请选择图片')}
              </Button>
            }
          />
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Button
              icon={<IconFontIcon iconName="shangchuan_24" />}
              onClick={() => avatarSelector.current?.click()}
            >
              {I18N.t('选择图片')}
            </Button>
            <input
              ref={avatarSelector}
              style={{ display: 'none' }}
              type="file"
              accept="image/jpeg,image/png"
              onChange={handleImageChange}
            />

            <Typography.Paragraph type="secondary">
              {I18N.t('支持JPG，PNG格式，文件大小不得超过10MB')}
            </Typography.Paragraph>
          </Space>
        </Col>
      </Row>
    </DMModal>
  );
};
export default AvatarModal;
