// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 校验验证码 GET /api/captcha/check */
export async function captchaCheckGet(
  params: {
    // query
    /** captcha */
    captcha: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/captcha/check', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取captcha图片 GET /api/captcha/get */
export async function captchaGetGet(
  params: {
    // query
    /** captcha token */
    token: string;
    /** 图片宽度 */
    width?: number;
    /** 图片高度 */
    height?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.langNameVoid>('/api/captcha/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取captcha图片（base64格式） GET /api/captcha/getBase64 */
export async function captchaGetBase64Get(
  params: {
    // query
    /** captcha token */
    token: string;
    /** 图片宽度 */
    width?: number;
    /** 图片高度 */
    height?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultCaptchaBase64Vo>('/api/captcha/getBase64', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取一个captcha token GET /api/captcha/token */
export async function captchaTokenGet(options?: { [key: string]: any }) {
  return request<API.WebResultCaptchaTokenVo>('/api/captcha/token', {
    method: 'GET',
    ...(options || {}),
  });
}
