import { useRequest } from '@@/plugin-request/request';
import { departmentTreeGet } from '@/services/api-Account/DepartmentController';
import { Spin, Tooltip, Typography } from 'antd';
import type { CSSProperties, FC } from 'react';
import { useEffect, useMemo } from 'react';
import { getPaths } from '@/utils/departmentUtils';
import IconFontIcon from '@/components/Common/IconFontIcon';
import colors from '@/style/color.less';
import styles from './index.less';
import classNames from 'classnames';
import { SkipErrorNotifyOption } from '@/utils/utils';
import _ from 'lodash';

const renderItem = (paths: any[]) => {
  const strings: string[] = [];
  paths.forEach((d: any) => {
    const { parentId, name } = d;
    if (parentId === 0) {
      strings.unshift(name);
    } else {
      strings.push(name);
    }
  });
  return strings.join(' / ');
};
export function useDepartmentPaths(
  list: API.DepartmentDto[],
  meta?: API.DepartmentTreeNode[],
  requestOptions?: any,
) {
  return useRequest(
    async () => {
      if (!meta) {
        return departmentTreeGet({}, SkipErrorNotifyOption);
      }
      return {
        data: meta,
      };
    },
    {
      ...requestOptions,
      formatResult(res) {
        return _.sortBy(
          list.map((item) => {
            const paths = getPaths(res.data!, item.id!);
            const name = renderItem(paths);
            return {
              deep: paths.length,
              name,
              id: item.id,
            };
          })!,
          'deep',
        );
      },
      defaultLoading: true,
      refreshDeps: [list, meta],
    },
  );
}

interface DepartmentProps {
  data: API.DepartmentDto;
  showName?: boolean;
  style?: CSSProperties;
  showTooltip?: boolean;
}
export const Department: FC<DepartmentProps> = (props) => {
  const { data, showName = true, showTooltip, ...others } = props;
  const text = useMemo(() => {
    return data.name || data.title;
  }, [data.name, data.title]);
  return (
    <Tooltip title={showTooltip ? text : false}>
      <span className={classNames(styles.departmentNode, 'department-node')} {...others}>
        <IconFontIcon
          size={24}
          style={{ color: colors.primaryColor }}
          iconName="shuzhuangxingshi_24"
        />
        {showName && <Typography.Text ellipsis>{text}</Typography.Text>}
      </span>
    </Tooltip>
  );
};

const DepartmentDescriptions = (props: { list: API.DepartmentTreeNode[] }) => {
  const { list = [] } = props;
  const { data, loading } = useDepartmentPaths(list);
  const nodes = useMemo(() => {
    if (data) {
      return data.map((vo) => {
        const { id, name } = vo;
        return <Department key={id} data={{ name }} showName />;
      });
    }
    return null;
  }, [data]);
  if (loading) {
    return <Spin />;
  }

  return <>{nodes}</>;
};
export default DepartmentDescriptions;
