import I18N from '@/i18n';
import { useCallback, useState } from 'react';
import _ from 'lodash';
import DMConfirm from '@/components/Common/DMConfirm';
import { getTeamIdFromUrl } from '@/utils/utils';
import { shopByShopIdBriefGet, shopListPropsPost } from '@/services/api-ShopAPI/ShopController';
import { history } from '@@/core/history';
import { openSessionWatchWindow } from '@/utils/pageUtils';
import { useRequest } from '@@/plugin-request/request';
import { useBeforeEnabledSecurityPolicy } from '@/hooks/interactions';
import EventEmitter from 'events';

/**
 * 获取分身主通道
 * @param account
 */
export function getPrimaryChannel(account: API.ShopDetailVo): API.ShopChannelVo | undefined {
  if (!account) {
    return undefined;
  }
  const channel = account.channels?.find?.((ip) => ip.primary);
  if (!channel) {
    return undefined;
  }

  return channel;
}
/**
 * 主通道类型
 * @param account
 */
export function getPrimaryChannelType(account: API.ShopDetailVo) {
  const primaryChannel = getPrimaryChannel(account);
  if (!primaryChannel) {
    return 'none';
  }
  return _.has(primaryChannel, 'ipPool') && primaryChannel?.ipPool ? 'ipPool' : 'ip';
}
/**
 * 获取分身IP池通道
 * @param account
 */
export function getPoolChannel(account: API.ShopDetailVo): API.ShopChannelVo | undefined {
  if (!account) {
    return undefined;
  }
  const channel = _.find(account.channels, (ip) => !!ip.ipPool);
  if (!channel) {
    return undefined;
  }
  return channel;
}

/**
 * 获取分身主ip
 * @param account
 * @param key string
 */
export function getPrimaryIp(account: API.ShopDetailVo, key?: string) {
  const target = getPrimaryChannel(account)?.ip;
  if (key !== undefined) {
    return target?.[key];
  }
  return target;
}

/**
 * 访问会话未绑定IP提示
 * 未绑定IP且未开启本地代理
 * @param account
 */
export function shouldShowIpEmptyAlert(account: API.ShopDetailVo) {
  return !getPrimaryChannel(account) && !account?.lanProxy?.enabled;
}
/**
 * 获取分身副通道（副ip）
 * @param account
 */
export function getSecondChannel(account: API.ShopDetailVo) {
  if (!account) {
    return undefined;
  }
  return _.find(account.channels, (ip) => !ip.primary);
}
/**
 * 获取默认通讯通道
 * @param account
 */
export function getDefaultChannelType(account: API.ShopDetailVo) {
  if (!account) {
    return undefined;
  }
  const { routers } = account;
  if (routers?.length) {
    const router = _.find(routers, (_router) => _router.ruleType === 'Default');
    return router?.channelType;
  }
  return undefined;
}
export function sharedAlert() {
  return DMConfirm({
    title: I18N.t('无法查看进一步信息'),
    type: 'info',
    content: I18N.t('该分身由他人分享给当前团队联合运营，您无法查看进一步信息'),
  });
}
export function transferringAlert() {
  return DMConfirm({
    title: I18N.t('无法查看进一步信息'),
    type: 'info',
    content: I18N.t('该分身处于转让中，您无法查看进一步信息'),
  });
}

export function showSharedShopIpEmptyAlert() {
  DMConfirm({
    width: 600,
    title: I18N.t('分身未绑定IP地址，无法访问'),
    content: I18N.t(
      '该分身是一个联营分身，必须要由主队绑定IP地址后才允许客队访问，否则会对运营带来风险',
    ),
  });
}

export function useLatestShopsCode() {
  const [loading, setLoading] = useState(false);
  const run = useCallback(
    async (shopIds: number[]) => {
      setLoading(true);
      const code: (
        | 'TRANSFERRING'
        | 'SHARING'
        | 'SECURITY_POLICY'
        | 'SHARED'
        | 'IP_POOL'
        | 'EXCLUSIVE'
        | 'NO_IP'
        | undefined
      )[] = [];
      if (loading) {
        return [];
      }
      try {
        const res = await shopListPropsPost(
          {
            props: 'ip',
          },
          {
            ids: shopIds,
          },
        );
        setLoading(false);
        for (let i = 0; i < (res.data?.length || 0); i++) {
          const item_1 = res.data?.[i]!;
          if (item_1.operateStatus === 'transferring') {
            code.push('TRANSFERRING');
          } else if (item_1.operateStatus === 'sharing') {
            code.push('SHARING');
          } else if (item_1.operateStatus === 'shared') {
            code.push('SHARED');
          }
          if (!item_1.securityPolicyEnabled) {
            code.push('SECURITY_POLICY');
          }

          if (!!getPoolChannel(item_1)) {
            code.push('IP_POOL');
          }
          if (item_1.exclusive) {
            code.push('EXCLUSIVE');
          }
          if (!getPrimaryIp(item_1)) {
            code.push('NO_IP');
          }
        }

        return code;
      } catch (e) {
        setLoading(false);
        return [];
      }
    },
    [loading],
  );
  return {
    run,
    loading,
  };
}
export const RecordPolicy = {
  Chosen: I18N.t('员工选择'),
  Disabled: I18N.t('不允许录像'),
  Forced: I18N.t('强制录像'),
};
export const HEADER_RIGHT_SLOT_ID = Symbol('ACCOUNTS-HEADER-RIGHT-CONTENT');

export const onMonitorNotAllowed = () => {
  DMConfirm({
    title: I18N.t('该分身的安全策略不允许事中监管'),
    content: I18N.t('该浏览器分身的安全策略中，已经关闭了实时监管，需要将其开启才允许进入监管页面'),
    type: 'info',
  });
};
export const onSecurityDisabled = (onOk: () => void) => {
  DMConfirm({
    title: I18N.t('未开启安全策略，无法监管'),
    content: I18N.t('需要开启此分身的安全策略后，才允许进入监管页面'),
    iconType: 'info',
    okText: I18N.t('立即开启'),
    onOk() {
      onOk();
    },
  });
};

export function useOpenWatcher(options: { refer: 'dashboard' | 'detail'; onUpdate?: () => void }) {
  const { refer, onUpdate } = options;
  const enableSecurity = useBeforeEnabledSecurityPolicy();
  return useRequest(
    async (session: API.ActiveShopSessionVo, mode: 'video' | 'screenshot' = 'video') => {
      try {
        const { teamId, shopId, id: sessionId } = session;
        const shopBrief = await shopByShopIdBriefGet({
          shopId,
        }).then((res) => {
          return res.data!;
        });
        if (shopBrief?.securityPolicyEnabled) {
          if (shopBrief?.allowMonitor) {
            if (mode === 'video') {
              openSessionWatchWindow({
                teamId,
                shopId,
                sessionId,
              });
            }
            return true;
          }
          onMonitorNotAllowed();
        } else {
          onSecurityDisabled(() => {
            if (refer === 'dashboard') {
              history.push(`/team/${getTeamIdFromUrl()}/shopManage/all/${shopId}?enablePolicy`);
            } else {
              enableSecurity([shopId!]).then(onUpdate);
            }
          });
        }
        return false;
      } catch (e) {
        return false;
      }
    },
    {
      manual: true,
    },
  );
}
export const ShopFetchEventEmitter = new EventEmitter();
export const FETCH_SHOP = Symbol('FETCH_SHOP');
