import I18N from '@/i18n';
import type { FC } from 'react';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import classNames from 'classnames';
import { useMemo, useRef } from 'react';
import type { RefSelectProps } from 'antd/es/select';
import styles from './index.less';

export interface SelectorProps extends SelectProps<number | string | any[]> {
  dataSourceRequest?: () => Promise<any>;
  showPlaceholderOption?: boolean;
  placeholderKey?: any;
  horizontal?:
    | true
    | {
        threshold?: number;
        maxWidth?: number;
      };
}

/**
 * 异步数据暂不支持
 */
/**
 * 魔改选择器
 * @param props
 * @constructor
 */
const Selector: FC<SelectorProps> = (props) => {
  const {
    showArrow = true,
    showSearch = false,
    maxTagCount = 'responsive',
    placeholder = I18N.t('请选择'),
    allowClear = true,
    options = [],
    showPlaceholderOption = false,
    onSelect,
    placeholderKey,
    onChange,
    className,
    value,
    mode,
    dropdownClassName,
    horizontal,
    menuItemSelectedIcon,
    dropdownStyle,
    dropdownMatchSelectWidth,
    ...others
  } = props;
  const ref = useRef<RefSelectProps>();
  const optionsSize = useMemo(() => {
    return _.sumBy(options, (item) => {
      if (item.options?.length) {
        return item.options.length;
      }
      return 1;
    });
  }, [options]);
  const horizontalOptions = useMemo(() => {
    if (!horizontal) return undefined;
    const _option = {
      threshold: 5,
      maxWidth: 420,
    };
    if (horizontal === true) {
      if (_option.threshold <= optionsSize) {
        return _option;
      }
      return undefined;
    }
    const threshold = horizontal.threshold || _option.threshold;
    if (threshold > optionsSize) return undefined;
    return {
      ..._option,
      ...horizontal,
    };
  }, [horizontal, optionsSize]);
  // 原始需求，希望在只有一个选中Item时，表现得像是单选模式
  const maxTagCountHack = useMemo(() => {
    if (mode === 'multiple' && value?.length <= 1) {
      return undefined;
    }
    return maxTagCount;
  }, [maxTagCount, mode, value]);
  return (
    <Select
      virtual={false}
      className={classNames(
        styles.dmSelector,
        'dm-selector',
        { [styles.nowrap]: !maxTagCountHack },
        { nowrap: !maxTagCountHack },
        className,
      )}
      ref={ref}
      mode={mode}
      value={value}
      showArrow={showArrow}
      showSearch={showSearch}
      maxTagCount={maxTagCountHack}
      onChange={onChange}
      allowClear={allowClear}
      placeholder={placeholder}
      dropdownClassName={classNames({
        [styles.horizontalOverlay]: !!horizontalOptions,
        'dm-selector-overlay': true,
        'horizontal-overlay': !!horizontalOptions,
      })}
      dropdownMatchSelectWidth={
        !!horizontalOptions && !dropdownMatchSelectWidth ? false : dropdownMatchSelectWidth
      }
      dropdownStyle={
        !!horizontalOptions && !dropdownMatchSelectWidth
          ? {
              maxWidth: horizontalOptions.maxWidth,
              ...(dropdownStyle || {}),
            }
          : dropdownStyle
      }
      menuItemSelectedIcon={!!horizontalOptions ? false : menuItemSelectedIcon}
      {...others}
    >
      {showPlaceholderOption && (
        <Select.Option
          key="placeholder"
          value={placeholderKey}
          style={{ padding: 0 }}
          className={classNames({ 'ant-select-item-option-selected': !value })}
        >
          <div
            style={{ padding: '5px 12px', width: '100%', height: '100%' }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onChange) {
                onChange(placeholderKey, placeholderKey);
              }
              ref.current?.blur();
            }}
          >
            {placeholder}
          </div>
        </Select.Option>
      )}

      {options.map((option, index) => {
        const { value, label, options: _options, ...rest } = option;
        if (_options?.length) {
          return (
            <Select.OptGroup key={index} label={label}>
              {_options.map((_option: { [x: string]: any; value: any; label: any }) => {
                const { value, label, ...rest } = _option;
                return (
                  <Select.Option key={value} value={value} {...rest}>
                    {label}
                  </Select.Option>
                );
              })}
            </Select.OptGroup>
          );
        }
        return (
          <Select.Option key={value} value={value} {...rest}>
            {label}
          </Select.Option>
        );
      })}
    </Select>
  );
};
export default Selector;
