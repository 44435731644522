import I18N from '@/i18n';
import _ from 'lodash';
import type { NodesStruct, NodeVo } from '@/pages/Rpa/FlowDetail/layout/EditorPanel';
import random from '@/utils/fingerprint/random';

export const JS_MARKER = '#js>';

export const ELM_MARKER = 'element://';

export function removeJsExpSuffix(v: any) {
  if (_.isPlainObject(v)) {
    const _v = { ...v };
    _.forEach(v, (ov: any, ok: string) => {
      if (_.isString(ov)) {
        // eslint-disable-next-line no-param-reassign
        _v[ok] = ov.replace(/^#js>/, '');
      }
    });
    return _v;
  }
  if (_.isString(v)) {
    return v.replace(/^#js>/, '');
  }
  return v;
}

export function FilePathTooltipCon(props: {
  includeHttp?: boolean;
  includeAttachment?: boolean;
  prefixText?: string;
}) {
  const { includeAttachment = true, includeHttp = false, prefixText } = props;
  return (
    <>
      {!!prefixText && <div>{prefixText}</div>}
      <div>{I18N.t('支持本地文件及以下协议类型：')}</div>
      <div>{I18N.t('team_disk://团队云盘文件路径')}</div>
      <div>{I18N.t('user_disk://个人云盘文件路径')}</div>
      {includeAttachment && <div>{I18N.t('attachment://资源文件路径')}</div>}
      {includeHttp && <div>{I18N.t('http(s)://远程文件路径')}</div>}
      <div>
        {I18N.t(
          '如果路径不含以上前缀且不是绝对路径，则认为是RPA工作目录文件路径，既当前操作系统下的“文档”',
        )}
      </div>
    </>
  );
}

export function findNodes(nodes: Record<string, NodeVo>, values: { key: string; value: any }[]) {
  let targetNodes: {
    nodeId: string;
    prevNodeId?: string;
    nextNodeId?: string;
    parentNodeId?: string;
    controlNodeName?: string;
  }[] = [];
  _.forEach(nodes, (node, nodeId) => {
    if (!values.some(({ key, value }) => _.get(node, key) !== value)) {
      targetNodes.push({
        nodeId,
      });
    }
    return true;
  });
  if (targetNodes.length === 0) {
    return targetNodes;
  }
  targetNodes = targetNodes.map((targetNode) => {
    let prevNodeId: string | undefined;
    const nextNodeId = nodes[targetNode.nodeId].next;
    let parentNodeId: string | undefined;
    let controlNodeName: string | undefined;
    _.forEach(nodes, (node, nodeId) => {
      if (node.next === targetNode.nodeId) {
        prevNodeId = nodeId;
        return false;
      }
      if (node.props?.ifNode) {
        controlNodeName = 'ifNode';
      } else if (node.props?.elseNode) {
        controlNodeName = 'elseNode';
      } else if (node.props?.afterNode) {
        controlNodeName = 'afterNode';
      } else if (node.props?.refreshNode) {
        controlNodeName = 'refreshNode';
      } else if (node.props?.tryNode) {
        controlNodeName = 'tryNode';
      } else if (node.props?.catchNode) {
        controlNodeName = 'catchNode';
      }
      if (controlNodeName && node.props?.[controlNodeName] === targetNode.nodeId) {
        parentNodeId = nodeId;
        return false;
      }
      return true;
    });
    return {
      nodeId: targetNode.nodeId,
      prevNodeId,
      nextNodeId,
      parentNodeId,
      controlNodeName,
    };
  });

  return targetNodes;
}

const replaceNodesIdMapCache: Record<string, Record<string, string>> = {};

/**
 * 替换节点ID
 * @param nodes
 * @param cacheKey
 */
export function replaceNodesId(nodes: Record<string, NodeVo>, cacheKey?: string) {
  const _nodes = {};
  let nodeIdMap: Record<string, string> = {};
  if (cacheKey) {
    if (!replaceNodesIdMapCache[cacheKey]) {
      const _nodeIdMap: Record<string, string> = {};
      _.forEach(nodes, (nodeVo, nodeId) => {
        _nodeIdMap[nodeId] = random.nextString(10);
      });
      replaceNodesIdMapCache[cacheKey] = _nodeIdMap;
    }
    nodeIdMap = replaceNodesIdMapCache[cacheKey];
  } else {
    _.forEach(nodes, (nodeVo, nodeId) => {
      nodeIdMap[nodeId] = random.nextString(10);
    });
  }
  // header 节点
  nodeIdMap.header = 'header';
  _.forEach(nodes, (nodeVo, nodeId) => {
    const vo = _.cloneDeep(nodeVo);
    if (vo.next && nodeIdMap[vo.next]) {
      vo.next = nodeIdMap[vo.next];
    }
    if (vo.props?.ifNode && nodeIdMap[vo.props?.ifNode]) {
      vo.props.ifNode = nodeIdMap[vo.props?.ifNode];
      if (vo.props?.elseNode && nodeIdMap[vo.props?.elseNode]) {
        vo.props.elseNode = nodeIdMap[vo.props?.elseNode];
      }
    } else if (vo.props?.afterNode && nodeIdMap[vo.props?.afterNode]) {
      vo.props.afterNode = nodeIdMap[vo.props?.afterNode];
    } else if (vo.props?.refreshNode && nodeIdMap[vo.props?.refreshNode]) {
      vo.props.refreshNode = nodeIdMap[vo.props?.refreshNode];
    } else if (vo.props?.tryNode && nodeIdMap[vo.props?.tryNode]) {
      vo.props.tryNode = nodeIdMap[vo.props?.tryNode];
      if (vo.props?.catchNode && nodeIdMap[vo.props?.catchNode]) {
        vo.props.catchNode = nodeIdMap[vo.props?.catchNode];
      }
    } else if (vo.props?.target && nodeIdMap[vo.props.target]) {
      vo.props.target = nodeIdMap[vo.props.target];
    }
    _nodes[nodeIdMap[nodeId]] = vo;
  });
  return _nodes;
}

export function findNodeAndChildren(
  nodes: Record<string, NodeVo>,
  nodeId: string,
  res: Record<string, NodeVo>,
  findNext = true,
) {
  const nodeVo = nodes[nodeId];
  if (nodes[nodeId]) {
    res[nodeId] = nodes[nodeId];
  }
  if (nodeVo.props?.ifNode && nodes[nodeVo.props?.ifNode]) {
    findNodeAndChildren(nodes, nodeVo.props.ifNode, res);
    if (nodeVo.props?.elseNode && nodes[nodeVo.props?.elseNode]) {
      findNodeAndChildren(nodes, nodeVo.props.elseNode, res);
    }
  } else if (nodeVo.props?.afterNode && nodes[nodeVo.props?.afterNode]) {
    findNodeAndChildren(nodes, nodeVo.props.afterNode, res);
  } else if (nodeVo.props?.refreshNode && nodes[nodeVo.props?.refreshNode]) {
    findNodeAndChildren(nodes, nodeVo.props.refreshNode, res);
  } else if (nodeVo.props?.tryNode && nodes[nodeVo.props?.tryNode]) {
    findNodeAndChildren(nodes, nodeVo.props.tryNode, res);
    if (nodeVo.props?.catchNode && nodes[nodeVo.props?.catchNode]) {
      findNodeAndChildren(nodes, nodeVo.props.catchNode, res);
    }
  }
  if (findNext && nodeVo.next && nodes[nodeVo.next]) {
    findNodeAndChildren(nodes, nodeVo.next, res);
  }
}

export function buildNodesStruct(props: {
  nodesState: Record<string, NodeVo>;
  flowCollapseNodeIds?: Record<string, string[] | undefined>;
  subFlowId?: string;
}) {
  const { nodesState, flowCollapseNodeIds = [], subFlowId } = props;
  const nodeRelation: any = {
    header: { type: 'rpa.Header', prev: null, next: null, parent: null },
  };
  const nodeList: NodesStruct['list'] = [];
  const iterator = (
    nodeId: string,
    nodeIdPath: string[],
    prev: string | null,
    parent: string | null,
    parentBranchName: string | null,
    level: number,
    isCollapsed: boolean,
  ) => {
    const node = nodesState?.[nodeId];
    if (!node) return;
    nodeRelation[nodeId] = {
      type: node.type,
      prev,
      next: null,
      parent,
      parentBranchName,
      nodeIdPath,
    };
    nodeList.push({ nId: nodeId, nodeIdPath, level, isCollapsed });
    let hasBranch = false;
    _.forEach(
      ['ifNode', 'elseNode', 'afterNode', 'refreshNode', 'tryNode', 'catchNode'],
      (childrenName) => {
        if (
          !_.isUndefined(node.props?.[childrenName]) ||
          (childrenName === 'catchNode' && !_.isUndefined(node.props?.tryNode))
        ) {
          if (!hasBranch) hasBranch = true;
          if (['elseNode', 'catchNode'].includes(childrenName)) {
            // 补充虚拟节点
            nodeList.push({
              nId: nodeId,
              nodeIdPath,
              isVirtual: true,
              branchName: childrenName,
              level,
              isCollapsed,
            });
          } else {
            nodeList[nodeList.length - 1].branchName = childrenName;
          }
          // 如果没折叠
          const collapseKey = `${nodeId}-${childrenName}`;
          const _isCollapsed =
            isCollapsed || !!flowCollapseNodeIds[subFlowId ?? 'index']?.includes(collapseKey);
          // 子节点
          iterator(
            node.props?.[childrenName],
            [...nodeIdPath, nodeId],
            null,
            nodeId,
            childrenName,
            level + 1,
            _isCollapsed,
          );
        }
      },
    );
    if (hasBranch) {
      // insert droppable placeholder
      if (!nodeList[nodeList.length - 1].placeholders) {
        nodeList[nodeList.length - 1].placeholders = [];
      }
      // branch 节点作为最后一个节点的情况，不追加 placeholder
      if (nodeList[nodeList.length - 1].nId !== nodeId) {
        nodeList[nodeList.length - 1].placeholders!.unshift(nodeId);
      }
    }
    if (node.next && nodesState[node.next]) {
      // 下一个节点
      nodeRelation[nodeId].next = node.next;
      iterator(node.next, nodeIdPath, nodeId, parent, null, level, isCollapsed);
    }
  };
  iterator('header', [], null, null, null, 0, false);
  // console.log('nodeTree', nodeRelation);
  // console.log('nodeList', nodeList);
  return {
    tree: nodeRelation,
    list: nodeList,
  };
}
