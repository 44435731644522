import I18N from '@/i18n';
import styles from './../index.less';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Descriptions, Input, Space, Tooltip, Typography } from 'antd';
import IconFontIcon from '@/components/Common/IconFontIcon';
import MiddleSpin from '@/components/Common/MiddleSpin';
import bytes from 'bytes';
import { dateFormat } from '@/utils/utils';
import TaskType from '@/constants/TaskType';
import { taskByTaskIdResultGet } from '@/services/api-TaskAPI/TaskFrontController';
import ProTable from '@ant-design/pro-table';
import { scrollProTableOptionFn } from '@/mixins/table';
import { getReadableDirectory } from '@/pages/Yunpan/utils';
import { useRequest } from '@@/plugin-request/request';
import _ from 'lodash';
import Placeholder from '@/components/Common/Placeholder';

export const MissionStatusMap = {
  Fail: I18N.t('失败'),
  Running: I18N.t('进行中'),
  Success: I18N.t('成功'),
  Timeout: I18N.t('超时'),
  Pending: I18N.t('等待'),
};

export const MissionStatusCell = (props: { data: API.TaskDto }) => {
  const { data } = props;
  const { remarks, status } = data;
  let infoType: any = status;
  if (infoType === 'Fail') {
    infoType = 'danger';
  } else if (infoType === 'Timeout') {
    infoType = 'danger';
  } else if (infoType === 'Pending') {
    infoType = 'secondary';
  } else if (infoType === 'Success') {
    infoType = 'success';
  }
  return (
    <Tooltip title={remarks}>
      <Typography.Text type={infoType}>{MissionStatusMap[status!]}</Typography.Text>
    </Tooltip>
  );
};

export type DetailInstance = {
  show: (data: API.TaskDto) => void;
  hide: () => void;
};
const DetailNode = {
  UploadDiskFile: (props: { detail: API.TaskDto }) => {
    const { detail } = props;
    const { name } = detail;
    const content = JSON.parse(detail?.detail as string);

    const subType = content.subType;
    const targetPath = () => {
      if (content.targetFile) {
        return content.targetFile;
      }
      if (content.target) {
        return getReadableDirectory(content.target + name);
      }
      if (content.path) {
        return getReadableDirectory(content.path + name);
      }
      return name;
    };
    return (
      <Descriptions
        size={'small'}
        className="descriptions-wrapper"
        column={1}
        bordered
        labelStyle={{ width: 130 }}
      >
        {!subType && (
          <Descriptions.Item label={I18N.t('源文件')}>
            {content.sourceFile || name}
          </Descriptions.Item>
        )}
        {!subType && (
          <Descriptions.Item label={I18N.t('文件大小')}>
            {bytes.format(content.size, { decimalPlaces: 2 })}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={I18N.t('目标地址')}>{targetPath()}</Descriptions.Item>
        <Descriptions.Item label={I18N.t('开始时间')}>
          {dateFormat(new Date(detail.createTime!))}
        </Descriptions.Item>
        <Descriptions.Item label={I18N.t('结束时间')}>
          {detail.finishTime ? dateFormat(new Date(detail.finishTime)) : '--'}
        </Descriptions.Item>
        <Descriptions.Item label={I18N.t('任务进度')}>{`${detail.progress}%`}</Descriptions.Item>
        <Descriptions.Item label={I18N.t('任务状态')}>
          <MissionStatusCell data={detail} />
        </Descriptions.Item>
      </Descriptions>
    );
  },
  ProbeIps: (props: { detail: API.TaskDto }) => {
    const { detail } = props;
    const content = JSON.parse(detail?.detail as string);
    const count = content.ids ? (content.ids || [])?.length : content?.count || 0;
    return (
      <Descriptions
        size={'small'}
        className="descriptions-wrapper"
        column={1}
        bordered
        labelStyle={{ width: 130 }}
      >
        <Descriptions.Item label={I18N.t('IP数量')}>
          {I18N.t('{{count}}个', {
            count: count.toLocaleString(),
          })}
        </Descriptions.Item>
        <Descriptions.Item label={I18N.t('开始时间')}>
          {dateFormat(new Date(detail.createTime!))}
        </Descriptions.Item>
        <Descriptions.Item label={I18N.t('结束时间')}>
          {detail.finishTime ? dateFormat(new Date(detail.finishTime)) : '--'}
        </Descriptions.Item>
        <Descriptions.Item label={I18N.t('任务进度')}>{`${detail.progress}%`}</Descriptions.Item>
        <Descriptions.Item label={I18N.t('任务状态')}>
          <MissionStatusCell data={detail} />
        </Descriptions.Item>
      </Descriptions>
    );
  },
  UserRefreshIp: (props: { detail: API.TaskDto }) => {
    const { detail } = props;
    const { id } = detail;
    const { data } = useRequest(
      async () => {
        const res = await taskByTaskIdResultGet({ taskId: id! });
        const { result } = res.data || { result: [] };
        try {
          return { data: JSON.stringify(result, null, 2) };
        } catch (e) {
          return { data: String(result) };
        }
      },
      {
        refreshDeps: [id],
        defaultLoading: true,
      },
    );
    return <Input.TextArea value={data} rows={20} />;
  },
  Common: (props: { detail: API.TaskDto }) => {
    const { detail } = props;
    const { id, taskType } = detail;
    const { data, loading } = useRequest(
      async () => {
        const res = await taskByTaskIdResultGet({ taskId: id! });
        const { result } = res.data || { result: [] };
        return {
          data: _.map(result, (item, index) => {
            const { error, success, ...others } = item;
            return {
              id: index,
              error: item.error,
              success: item.success,
              ...others,
            };
          }),
        };
      },
      {
        refreshDeps: [id],
        defaultLoading: true,
      },
    );

    return (
      <div className={'table-wrapper'}>
        <ProTable
          rowKey="id"
          dataSource={data}
          loading={loading}
          columns={[
            {
              dataIndex: 'name',
              title: I18N.t('名称'),
              width: 150,
              ellipsis: true,
              renderText(_text, record) {
                if (['ImportIp', 'ImportIps', 'ImportIppIps'].includes(taskType!)) {
                  return (
                    record?.ip?.name ||
                    record?.ip?.ip ||
                    record?.ip?.outboundIp ||
                    record?.proxy ||
                    _text
                  );
                }
                return _text;
              },
            },
            {
              dataIndex: 'result',
              title: I18N.t('导入结果'),
              width: '100px',
              render(_text, record) {
                const { success } = record;
                if (success) {
                  return <Typography.Text type={'success'}>{I18N.t('成功')}</Typography.Text>;
                }
                return <Typography.Text type={'danger'}>{I18N.t('失败')}</Typography.Text>;
              },
            },
            {
              dataIndex: 'error',
              title: I18N.t('描述'),
              ellipsis: true,
              renderText(_text, record) {
                if (['ImportIp', 'ImportIps', 'ImportIppIps'].includes(taskType!)) {
                  return record?.proxy || _text;
                }
                return _text;
              },
            },
          ]}
          {...scrollProTableOptionFn({
            size: 'small',
            pagination: false,
          })}
        />
      </div>
    );
  },
};

/**
 * 任务详情
 * @param props
 * @constructor
 */
const MissionDetail = forwardRef((props, ref) => {
  const [visible, changeVisible] = useState(false);
  const [detail, setDetail] = useState<API.TaskDto>();
  useImperativeHandle(ref, () => {
    return {
      show(data: API.TaskDto) {
        setDetail(data);
        changeVisible(true);
      },
      hide() {
        setDetail(undefined);
        changeVisible(false);
      },
    };
  });
  const title = useMemo(() => {
    if (!detail) {
      return I18N.t('任务详情');
    }
    if (JSON.parse(detail?.detail || '{}').subType === 'DownloadRecord') {
      return I18N.t('批量下载审计录像');
    }
    return TaskType[detail?.taskType!] || detail?.taskType;
  }, [detail]);
  const main = useMemo(() => {
    if (!detail) {
      return (
        <div className="main">
          <MiddleSpin />
        </div>
      );
    }
    const Node = DetailNode[detail.taskType!] || DetailNode.Common;
    return (
      <div className={'main'}>
        <div className="title">{title}</div>
        <div className="sub-title">{dateFormat(new Date(detail.createTime!))}</div>
        {Node ? <Node detail={detail} /> : ''}
      </div>
    );
  }, [detail, title]);

  return (
    <div className={classNames(styles.missionDetail, { [styles.active]: visible })}>
      <div className="nav">
        <a
          onClick={() => {
            changeVisible(false);
            setTimeout(() => {
              setDetail(undefined);
            }, 300);
          }}
        >
          <Space size={4}>
            <IconFontIcon iconName="angle-double-left_24" size={12} />
            {I18N.t('返回列表')}
          </Space>
        </a>
      </div>
      {main}
    </div>
  );
});
export default MissionDetail;
