// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取JS SDK 签名 GET /api/wx/jsSignature */
export async function wxJsSignatureGet(
  params: {
    // query
    /** url */
    url: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultWxJsapiSignature>('/api/wx/jsSignature', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** getOAuthUrl GET /api/wx/oauth2/getUrl */
export async function wxOauth2GetUrlGet(
  params: {
    // query
    /** targetUrl */
    targetUrl: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultstring>('/api/wx/oauth2/getUrl', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取登录二维码 GET /api/wx/bindQrCode */
export async function wxBindQrCodeGet(options?: { [key: string]: any }) {
  return request<API.WebResultWxQrCodeVo>('/api/wx/bindQrCode', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取登录二维码扫描结果 GET /api/wx/bindQrCodeResult */
export async function wxBindQrCodeResultGet(
  params: {
    // query
    /** qrcodeId */
    qrcodeId: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultWxBindQrCodeResult>('/api/wx/bindQrCodeResult', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 确认注册新用户 POST /api/wx/confirmRegister */
export async function wxConfirmRegisterPost(
  params: {
    // query
    /** nickname */
    nickname: string;
    /** qrcodeId */
    qrcodeId: string;
    /** inviteCode */
    inviteCode?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultWxLoginQrCodeResult>('/api/wx/confirmRegister', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取登录二维码 GET /api/wx/loginQrCode */
export async function wxLoginQrCodeGet(
  params: {
    // query
    /** rememberMe */
    rememberMe?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultWxQrCodeVo>('/api/wx/loginQrCode', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取登录二维码扫描结果 GET /api/wx/loginQrCodeResult */
export async function wxLoginQrCodeResultGet(
  params: {
    // query
    /** qrcodeId */
    qrcodeId: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultWxLoginQrCodeResult>('/api/wx/loginQrCodeResult', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查看微信消息 GET /api/wx/message/${param0} */
export async function wxMessageByMessageIdGet(
  params: {
    // path
    /** messageId */
    messageId: string;
  },
  options?: { [key: string]: any },
) {
  const { messageId: param0, ...queryParams } = params;
  return request<API.WebResultRpaMessageVo>(`/api/wx/message/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 查询当前会话信息 GET /api/wx/session/${param0} */
export async function wxSessionBySessionIdGet(
  params: {
    // path
    /** sessionId */
    sessionId: string;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResultSessionInfoVo>(`/api/wx/session/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 踢出会话 DELETE /api/wx/session/${param0} */
export async function wxSessionBySessionIdDelete(
  params: {
    // path
    /** sessionId */
    sessionId: string;
  },
  options?: { [key: string]: any },
) {
  const { sessionId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/wx/session/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 解除微信绑定 PUT /api/wx/unbind */
export async function wxUnbindPut(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/wx/unbind', {
    method: 'PUT',
    ...(options || {}),
  });
}
