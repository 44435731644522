import { useEffect, useState } from 'react';
import EventEmitter from 'events';

const emitter = new EventEmitter();
emitter.setMaxListeners(9999);
const UPDATE_EVENT = Symbol('update');

export type Role = API.RoleVo;
let currentRole: Role;

export function setCurrentRole(data: Role) {
  currentRole = data;
  emitter.emit(UPDATE_EVENT, data);
}

/**
 * 比较两个权限，one,two
 * 前者大则返回1，相等则返回0，后者大则返回-1
 * @param roleOne
 * @param roleTwo
 */
export function compareRole(roleOne: API.RoleVo, roleTwo: API.RoleVo) {
  if (roleOne.code === roleTwo.code) {
    return 0;
  }
  switch (roleOne.code) {
    case 'boss': {
      return roleTwo.code === 'superadmin' ? -1 : 1;
    }
    case 'manager': {
      return ['superadmin', 'boss'].includes(roleTwo.code) ? -1 : 1;
    }
    case 'superadmin': {
      return 1;
    }
    case 'staff': {
      return 1;
    }
    default:
      return -1;
  }
}
/**
 * 当前用户在当前团队的角色
 * 切换时机，团队切换时
 */
export default function useCurrentRole() {
  const [role, setRole] = useState<Role>(currentRole);
  const [isSpecialRole, changeSpecial] = useState(false);
  useEffect(() => {
    if (role?.code) {
      changeSpecial(['boss', 'superadmin'].includes(role.code));
    }
  }, [role]);

  useEffect(() => {
    emitter.addListener(UPDATE_EVENT, setRole);
    return () => {
      emitter.removeListener(UPDATE_EVENT, setRole);
    };
  }, []);

  return {
    role,
    setRole: setCurrentRole,
    isSpecialRole,
  };
}
