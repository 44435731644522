import I18N from '@/i18n';
import { ModalForm } from '@ant-design/pro-form';
import { Alert, Button, Input, Select, Space, Tooltip } from 'antd';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import { getDefaultModalProps, getModalFormSubmitter } from '@/components/Common/Modal/DMModal';
import { metaIpLocationPost } from '@/services/api-MetaAPI/MetaIpController';
import { useRequest } from '@umijs/hooks';
import { CountryIcon } from '@/pages/IpManage/widgets/IpLocation';
import { useRef } from 'react';
import { metaCountriesGet } from '@/services/api-MetaAPI/MetaGeoController';
import IconFontIcon from '@/components/Common/IconFontIcon';

// 地域维护
const RegionalMaintenance = (props: { onUpdate: (data: API.IpLocationDto) => void }) => {
  const { onUpdate } = props;
  const form = useRef();
  const { data, loading } = useRequest(() => metaCountriesGet({ show: true }), {
    formatResult(res) {
      const { data } = res;
      return data?.map((value) => {
        return {
          label: (
            <Space size={'small'} align="center">
              <CountryIcon country={value.nameEn} />
              {value.name}
            </Space>
          ),

          value: value.code,
          name: value.name,
        };
      });
    },
  });
  return (
    <ModalForm
      formRef={form}
      modalProps={{ ...getDefaultModalProps() }}
      width={520}
      layout={'horizontal'}
      title={I18N.t('地域维护')}
      submitter={getModalFormSubmitter()}
      onFinish={async (values) => {
        const { countryCode, province, city } = values;
        const params = {
          countryCode,
        };
        if (_.trim(province)) {
          params.province = province;
        }
        if (_.trim(province)) {
          params.province = province;
        }
        if (_.trim(city)) {
          params.city = city;
        }
        return metaIpLocationPost(params).then((res) => {
          onUpdate(res?.data);
          return true;
        });
      }}
      trigger={
        <Button type={'link'} size="small">
          <Space align={'center'}>
            <Tooltip
              title={I18N.t(
                '如果您有新的国家/省（州）/城市并没有出现在我们的知识库中，您可以自行添加这些地域至我们的知识库',
              )}
            >
              <IconFontIcon iconName={'bangzhu_24'} size={'inherit'} />
            </Tooltip>
            {I18N.t('地域维护')}
          </Space>
        </Button>
      }
    >
      <Alert
        style={{ marginBottom: 15 }}
        showIcon
        message={I18N.t(
          '如果您有新的国家/省（州）/城市并没有出现在我们的知识库中，您可以自行添加这些地域至我们的知识库',
        )}
        type={'info'}
      />

      <DMFormItemContext.Provider value={{ disableLabelMuted: true, labelWidth: 80 }}>
        <DMFormItem
          label={I18N.t('国家')}
          rules={[{ required: true, message: I18N.t('请选择国家') }]}
          name="countryCode"
        >
          <Select
            optionFilterProp="name"
            loading={loading}
            showSearch
            placeholder={I18N.t('请选择国家')}
            options={data}
          />
        </DMFormItem>
        <DMFormItem
          label={I18N.t('省（州）')}
          name="province"
          rules={[{ required: true, message: I18N.t('请输入省（州）的名称'), whitespace: true }]}
        >
          <Input placeholder={I18N.t('请输入省（州）的名称')} />
        </DMFormItem>
        <DMFormItem label={I18N.t('城市')} name="city" rules={[{ whitespace: true }]}>
          <Input placeholder={I18N.t('请输入城市的名称')} />
        </DMFormItem>
      </DMFormItemContext.Provider>
    </ModalForm>
  );
};

export default RegionalMaintenance;
