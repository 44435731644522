import ColoursIcon from '@/components/Common/ColoursIcon';
import type { CSSProperties, FC } from 'react';
import { Icon } from '@/components/Common/Selector/CategorySelector';

interface Props {
  platformName: string;
  className?: string;
  size?: number;
  style?: CSSProperties;
}

const PlatformCateIcon: FC<Props> = (props) => {
  const { platformName, className, size = 16, ...others } = props;
  if (platformName === 'All') {
    return <ColoursIcon className="buxianpingtai_24" size={size} />;
  }

  if (platformName === 'IM') {
    //  要求不一样
    return <ColoursIcon className={'shejiaopingtai_24'} size={size} {...others} />;
  }
  if (platformName === 'Mobile') {
    return <ColoursIcon className="yidongwangluoIP_24" size={size} {...others} />;
  }
  return <Icon type={platformName} size={size} {...others} />;
};
export default PlatformCateIcon;
