const str_seed = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const low_char_and_num = '0123456789abcdefghijklmnopqrstuvwxyz';

export const random = {
  /**
   * 获取 [min, max] 区间的随机数
   * @param min include
   * @param max include
   */
  nextInt: function (
    min: number = 0,
    max: number = Number.MAX_SAFE_INTEGER,
    negative: boolean = false,
  ) {
    let result = 0;
    if (max <= min) {
      result = min;
    } else {
      result = min + Math.floor(Math.random() * (max - min + 1));
    }
    return (negative ? -1 : 1) * result;
  },

  /**
   * 随机浮点数
   * @param min
   * @param max
   * @param bitLen 默认小数位长度 4
   */
  nextFloat: function (min: number, max: number, bitLen = 4) {
    const base = Math.pow(10, bitLen);
    return this.nextInt(min * base, max * base) / base;
  },

  /**
   * 获取随机boolean值
   */
  nextBoolean: function () {
    return !!this.nextInt(0, 1);
  },

  randomMember: function (arr: Array<any>) {
    if (arr.length > 0) {
      return arr[this.nextInt(0, arr.length - 1)];
    }
  },

  /**
   * 生成一个随机字符串，长度 len
   * @param len
   */
  nextString: function (len: number = 16, seed: string = str_seed) {
    if (len <= 0 || !seed) {
      return '';
    }
    let result = '';
    while (len-- > 0) {
      result += seed.charAt(this.nextInt(0, seed.length - 1));
    }
    return result;
  },
};
