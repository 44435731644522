// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 检查(团队-用户)是否已经完成了某个活动 GET /api/activity/isActivityFinished */
export async function activityIsActivityFinishedGet(
  params: {
    // query
    /** activityCode */
    activityCode: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultboolean>('/api/activity/isActivityFinished', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
