export default {
  登录: 'Log in',
  购买云主机: 'Buy IP',
  扫码结果页: 'Scan Result',
  注册: 'Register',
  找回密码: 'Reset Password',
  邀请注册: 'Invite Registration',
  团队邀请: 'Team Invitation',
  选择团队: 'Choose Team',
  团队: 'Team',
  文件预览: 'File Preview',
  分身: 'Browser',
  分身详情: 'Browser Details',
  指纹库: 'Fingerprint',
  指纹模板详情: 'FP Template Details',
  IP地址: 'IPs',
  IP详情: 'IP Details',
  流程编辑: 'Proc Editor',
  流程管理: 'Proc Mgnt',
  自动化: 'Automate',
  流程计划: 'Plan',
  插件: 'Plugins',
  流程任务: 'Task',
  任务详情: 'Task Detail',
  实时查看: 'View In Real Time',
  云盘: 'Storage',
  费用: 'Orders',
  操作日志: 'Logs',
  分身访问日志: 'Access Logs',
  分身管理日志: 'Mgmt Logs',
  IP日志: 'IP logs',
  团队管理日志: 'Team Mgmt logs',
  成员登录日志: 'Login logs',
  成员管理: 'Member Mgmt',
  团队设置: 'Team Settings',
  成员审核: 'Member Audit',
  其它资源: 'Other Resources',
  录像: 'Video',
  实时监管: 'Real-time Supervision',
  轨迹: 'Trace',
  浏览器指纹采集器: 'Browser FP Collector',
  微信登录: 'WeChat Login',
  花漾RPA消息: 'HuaYoung RPA News',
  输入验证码: 'Enter Verification Code',
  元素捕获器: 'Element Capturer',
  花漾: 'HuaYoung',
  花漾灵动: 'HuaYoung',
  花漾客户端: 'HuaYoung App',
  授权结果: 'Oauth result',
  首页: 'Dashboard',
  我获得的奖励: 'My Rewards',
  角色权限: 'Permissions',
  手机: 'Mobile',
};
