// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 通过联营审批 PUT /api/shop/share/audit/${param0}/pass */
export async function shopShareAuditByAuditIdPassPut(
  params: {
    // path
    /** auditId */
    auditId: number;
  },
  options?: { [key: string]: any },
) {
  const { auditId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/share/audit/${param0}/pass`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 拒绝联营审批 PUT /api/shop/share/audit/${param0}/reject */
export async function shopShareAuditByAuditIdRejectPut(
  params: {
    // query
    /** reason */
    reason?: string;
    // path
    /** auditId */
    auditId: number;
  },
  options?: { [key: string]: any },
) {
  const { auditId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/share/audit/${param0}/reject`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 批量添加联营团队 POST /api/shop/share/batch */
export async function shopShareBatchPost(
  body: API.AddBatchShopShareRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultAuditDto>('/api/shop/share/batch', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 停止联营 POST /api/shop/share/delete */
export async function shopShareDeletePost(
  body: API.CommonIdsRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/share/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量修改联营分身的IP在客队中的可见性 PUT /api/shop/share/ipVisible */
export async function shopShareIpVisiblePut(
  body: API.UpdateSharedShopIpVisibleRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/share/ipVisible', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 关闭特定联营团队 PUT /api/shop/share/${param0}/ipVisible */
export async function shopShareByShareIdIpVisiblePut(
  params: {
    // query
    /** visible */
    visible: boolean;
    // path
    /** shareId */
    shareId: number;
  },
  options?: { [key: string]: any },
) {
  const { shareId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/share/${param0}/ipVisible`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 查询联营团队 GET /api/shop/share/${param0} */
export async function shopShareByShopIdGet(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultListShopShareVo>(`/api/shop/share/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 删除联营团队 DELETE /api/shop/share/${param0}/${param1} */
export async function shopShareByShopIdByShareIdDelete(
  params: {
    // path
    /** shopId */
    shopId: number;
    /** shareId */
    shareId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, shareId: param1, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/share/${param0}/${param1}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 退还分身 shopId是子分身ID DELETE /api/shop/shared/${param0} */
export async function shopSharedByShopIdDelete(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/shared/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}
