import I18N from '@/i18n';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, DatePicker, message, Progress, Space, Tooltip, Typography } from 'antd';
import type { ActionType } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import { scrollProTableOptionFn } from '@/mixins/table';
import type { DetailInstance } from '@/components/MissionCenter/components/MissionDetail';
import MissionDetail from '@/components/MissionCenter/components/MissionDetail';
import IconFontIcon from '@/components/Common/IconFontIcon';
import styles from './index.less';
import {
  taskByTaskIdAbortPut,
  taskByTaskIdDelete,
  taskFinishedDelete,
  taskPageGet,
} from '@/services/api-TaskAPI/TaskFrontController';
import useCurrentTeam from '@/hooks/useCurrentTeam';
import { dateFormat } from '@/utils/utils';
import Placeholder from '../Common/Placeholder';
import FileName from '@/pages/Yunpan/components/FileName';
import { getReadableName } from '@/pages/Yunpan/utils';
import TaskType from '@/constants/TaskType';
import DMConfirm from '@/components/Common/DMConfirm';
import ColoursIcon from '@/components/Common/ColoursIcon';

interface Props {
  visible?: boolean;
}
const MissionCenter = (props: Props) => {
  const { visible } = props;
  const { team } = useCurrentTeam();
  const [type, changeType] = useState<undefined | string>();
  const detailRef = useRef<DetailInstance>();
  const [dates, setDates] = useState<string[]>([]);
  const actionRef = useRef<ActionType>();
  const fetch = useCallback((reset?: boolean) => {
    if (reset) {
      actionRef.current?.reloadAndRest?.();
    } else {
      actionRef.current?.reload();
    }
  }, []);
  useEffect(() => {
    if (!visible) {
      detailRef?.current?.hide();
      fetch();
    } else {
      fetch();
    }
  }, [visible, fetch]);

  return (
    <div className={styles.missionCenter}>
      <div className="search-form">
        <Space>
          <Button
            style={{ width: 100 }}
            type={!type ? 'primary' : 'default'}
            onClick={() => {
              changeType(undefined);
            }}
          >
            {I18N.t('全部类型')}
          </Button>
          <Button
            style={{ width: 100 }}
            type={type === 'ImportIp' ? 'primary' : 'default'}
            onClick={() => {
              changeType('ImportIp');
            }}
          >
            {I18N.t('IP管理')}
          </Button>
          <Button
            style={{ width: 100 }}
            type={type === 'ImportBrowser' ? 'primary' : 'default'}
            onClick={() => {
              changeType('ImportBrowser');
            }}
          >
            {I18N.t('分身管理')}
          </Button>
          <Button
            style={{ width: 100 }}
            type={type === 'UploadDiskFile' ? 'primary' : 'default'}
            onClick={() => {
              changeType('UploadDiskFile');
            }}
          >
            {I18N.t('文件传输')}
          </Button>
        </Space>
        <DatePicker.RangePicker
          placeholder={[I18N.t('开始时间'), I18N.t('结束时间')]}
          style={{ width: 260 }}
          onChange={(_dates, strings) => {
            setDates(strings);
          }}
        />
      </div>
      <div className="table-wrap">
        <ProTable
          actionRef={actionRef}
          params={{
            type,
          }}
          request={async (params) => {
            if (team?.id) {
              const { current, pageSize } = params;
              let taskType = params.type;
              if (type === 'ImportIp') {
                taskType = 'ImportIp,ImportIps,ImportIppIps,ProbeIps';
              } else if (type === 'ImportBrowser') {
                taskType = 'ImportAccounts,ImportShop';
              }
              return await taskPageGet({
                taskType,
                pageSize,
                pageNum: current,
                createTimeFrom: dates[0],
                createTimeTo: dates[1],
              }).then((res) => {
                return {
                  data: res.data?.list,
                  total: res.data?.total,
                };
              });
            }
            return {
              data: [],
              total: 0,
            };
          }}
          columns={[
            {
              title: I18N.t('任务名称'),
              dataIndex: 'name',
              className: 'name-cell',
              render(_text, record) {
                const { taskType, detail, name } = record;
                if (taskType === 'UploadDiskFile') {
                  return (
                    <FileName
                      name={getReadableName(name!)}
                      hack={
                        JSON.parse(detail || '{}').subType === 'DownloadRecord'
                          ? 'record'
                          : undefined
                      }
                    />
                  );
                }
                let iconName = 'renwu_24';
                if (['ImportIp', 'ImportIps', 'ImportIppIps'].includes(taskType!)) {
                  iconName = 'IP_24';
                } else if (['ImportAccounts', 'ImportShop'].includes(taskType!)) {
                  iconName = 'zhanghao_24';
                } else if (taskType === 'ProbeIps') {
                  iconName = 'shandian_24';
                }
                return (
                  <Space size={5}>
                    <ColoursIcon className={iconName} size={18} />
                    <Typography.Text ellipsis={{ tooltip: name }} style={{ flex: 1 }}>
                      {name}
                    </Typography.Text>
                  </Space>
                );
              },
            },
            {
              title: I18N.t('任务类型'),
              dataIndex: 'taskType',
              width: 100,
              render(_text, record) {
                const { taskType } = record;
                return TaskType[taskType!] || taskType;
              },
            },

            {
              title: I18N.t('开始时间'),
              width: '150px',
              dataIndex: 'createTime',
              render(_, record) {
                const { createTime } = record;
                return dateFormat(new Date(createTime!), 'MM-dd hh:mm:ss');
              },
            },
            {
              title: I18N.t('结束时间'),
              width: '150px',
              dataIndex: 'finishTime',
              render(_text, record) {
                const { finishTime } = record;
                return finishTime ? (
                  dateFormat(new Date(finishTime), 'MM-dd hh:mm:ss')
                ) : (
                  <Placeholder />
                );
              },
            },
            {
              title: I18N.t('任务进度'),
              dataIndex: 'progress',
              width: '150px',
              render(_text, record) {
                const { progress, status, remarks } = record;
                if (status === 'Fail' || status === 'Timeout') {
                  return (
                    <Tooltip title={remarks}>
                      <Progress status={'exception'} percent={progress} size={'small'} showInfo />
                    </Tooltip>
                  );
                }
                return (
                  <Progress
                    status={progress === 100 ? 'success' : 'active'}
                    percent={progress}
                    size={'small'}
                    showInfo
                  />
                );
              },
            },
            {
              title: I18N.t('操作'),
              key: 'opt',
              width: '100px',
              render(_text, record) {
                const { id, taskType, status } = record;
                return (
                  <Space>
                    <Typography.Link
                      onClick={() => {
                        detailRef.current?.show(record);
                      }}
                    >
                      <IconFontIcon iconName={'xiangqing_24'} />
                    </Typography.Link>
                    {(taskType === 'ImportIp' || taskType === 'ImportShop') &&
                      (status === 'Pending' || status === 'Running') && (
                        <Typography.Link
                          onClick={() => {
                            DMConfirm({
                              title: I18N.t('确定要中止该任务嘛？'),
                              onOk: async () => {
                                await taskByTaskIdAbortPut({
                                  taskId: id!,
                                });
                                message.success(I18N.t('任务已中止'));
                                fetch();
                              },
                            });
                          }}
                        >
                          <IconFontIcon iconName={'tingzhi2_24'} />
                        </Typography.Link>
                      )}

                    <Typography.Link
                      onClick={async () => {
                        await taskByTaskIdDelete({
                          taskId: id!,
                        });
                        message.success(I18N.t('任务已删除'));
                        fetch();
                      }}
                    >
                      <IconFontIcon iconName={'Trash_24'} />
                    </Typography.Link>
                  </Space>
                );
              },
            },
          ]}
          {...scrollProTableOptionFn({
            rowKey: 'id',
            dateFormatter: 'string',
            pageId: 'missionCenter',
            footer: () => {
              return (
                <Button
                  type="primary"
                  ghost
                  onClick={async () => {
                    await taskFinishedDelete();
                    fetch(true);
                  }}
                >
                  {I18N.t('清空已完成的任务')}
                </Button>
              );
            },
          })}
        />
      </div>
      <MissionDetail ref={detailRef} />
    </div>
  );
};
export default MissionCenter;
