import React, { useEffect } from 'react';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import { Col, Form, FormInstance, InputNumber, Radio, Row, Select, Space } from 'antd';
import I18N from '@/i18n';
import ColoursIcon from '@/components/Common/ColoursIcon';
import { sendAsync } from '@/utils/ElectronUtils';
import { Display } from '@/models/windowSync';
import DmCollapse from '@/components/Common/DmCollapse';

type Props = {
  form: FormInstance;
  hidden?: boolean;
  orderVisible?: boolean;
  onValuesChange?: () => void;
};

export const WindowSizeCon: React.FC<{ display: Display; style?: React.CSSProperties }> = (
  props,
) => {
  const { display, style } = props;

  return (
    <DMFormItem name="sizeMethod" initialValue="auto" style={style}>
      <Radio.Group>
        <Space direction="vertical" size={16}>
          <Radio value="auto" style={{ lineHeight: '30px' }}>
            {I18N.t('自动调整窗口大小')}
            <span style={{ color: '#999' }}>{I18N.t('（确保摆放全部窗口）')}</span>
          </Radio>
          <Radio value="custom" style={{ lineHeight: '30px' }}>
            {I18N.t('指定窗口大小：')}

            <Space>
              <span>{I18N.t('宽')}</span>
              <DMFormItem noStyle shouldUpdate>
                {(f) => {
                  const disabled = f.getFieldValue('sizeMethod') !== 'custom';
                  return (
                    <DMFormItem
                      name={'width'}
                      initialValue={800}
                      noStyle
                      rules={[
                        {
                          required: !disabled,
                          message: I18N.t('请输入窗口大小'),
                        },
                      ]}
                    >
                      <InputNumber
                        disabled={disabled}
                        min={500}
                        step={100}
                        max={display?.workAreaSize?.width}
                      />
                    </DMFormItem>
                  );
                }}
              </DMFormItem>
              <DMFormItem noStyle shouldUpdate>
                {(f) => {
                  const disabled = f.getFieldValue('sizeMethod') !== 'custom';
                  return (
                    <DMFormItem
                      name={'height'}
                      initialValue={600}
                      noStyle
                      rules={[
                        {
                          required: !disabled,
                          message: I18N.t('请输入窗口大小'),
                        },
                      ]}
                    >
                      <InputNumber
                        disabled={disabled}
                        min={375}
                        step={100}
                        max={display?.workAreaSize?.height}
                      />
                    </DMFormItem>
                  );
                }}
              </DMFormItem>
              <span>{I18N.t('高')}</span>
            </Space>
          </Radio>
        </Space>
      </Radio.Group>
    </DMFormItem>
  );
};

/**
 * 对角线布局
 * @param props
 * @constructor
 */
const DiagonalLayoutForm: React.FC<Props> = (props) => {
  const { form, hidden = false, orderVisible = false, onValuesChange } = props;
  const [displays, setDisplays] = React.useState<Display[]>([]);

  useEffect(() => {
    sendAsync('get-screen-info').then((data) => {
      setDisplays(data.allDisplays);
      if (!form.getFieldValue('displayId')) {
        form.setFieldsValue({ displayId: data.allDisplays[0].id });
      }
    });
  }, [form]);

  return (
    <Form form={form} requiredMark={false} hidden={hidden} onValuesChange={onValuesChange}>
      <DMFormItemContext.Provider value={{}}>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return (
              <>
                <DMFormItem label={I18N.t('显示器')} initialValue={1} name={'displayId'}>
                  <Select
                    options={displays.map((d) => ({
                      label: (
                        <Space size={4}>
                          <ColoursIcon className="kehuduan_24" />
                          {d.label}
                          <span style={{ color: '#999' }}>
                            （{d.size.width}x{d.size.height}）
                          </span>
                        </Space>
                      ),

                      value: d.id,
                    }))}
                  />
                </DMFormItem>
                {orderVisible && (
                  <DMFormItem label={I18N.t('窗口布局顺序')} initialValue="asc" name={'order'}>
                    <Radio.Group>
                      <Radio value="asc">{I18N.t('正序排列')}</Radio>
                      <Radio value="desc">{I18N.t('倒序排列')}</Radio>
                    </Radio.Group>
                  </DMFormItem>
                )}
                <WindowSizeCon
                  display={
                    displays.find((d) => d.id === form.getFieldValue('displayId')) ?? displays[0]
                  }
                  style={{ marginBottom: 0 }}
                />
                <DmCollapse header={I18N.t('高级选项')}>
                  <DMFormItem
                    label={I18N.t('起始位置')}
                    shouldUpdate
                    style={{ marginBottom: 0, alignItems: 'baseline' }}
                  >
                    <Row>
                      <Col flex={'3em'}>X</Col>
                      <Col flex={1}>
                        <DMFormItem
                          name={'left'}
                          initialValue={0}
                          rules={[{ required: true, message: I18N.t('请输入起始位置') }]}
                        >
                          <InputNumber max={1000} />
                        </DMFormItem>
                      </Col>
                      <Col flex={'3em'}>Y</Col>
                      <Col flex={1}>
                        <DMFormItem
                          name={'top'}
                          initialValue={0}
                          rules={[{ required: true, message: I18N.t('请输入起始位置') }]}
                        >
                          <InputNumber max={1000} />
                        </DMFormItem>
                      </Col>
                    </Row>
                  </DMFormItem>
                  <DMFormItem
                    label={I18N.t('窗口偏移')}
                    shouldUpdate
                    style={{ marginBottom: 0, alignItems: 'baseline' }}
                  >
                    <Row>
                      <Col flex={'3em'}>{I18N.t('横向')}</Col>
                      <Col flex={1}>
                        <DMFormItem
                          name={'hs'}
                          initialValue={100}
                          rules={[{ required: true, message: I18N.t('请输入窗口偏移') }]}
                        >
                          <InputNumber min={0} max={200} step={10} />
                        </DMFormItem>
                      </Col>
                      <Col flex={'3em'}>{I18N.t('纵向')}</Col>
                      <Col flex={1}>
                        <DMFormItem
                          name={'vs'}
                          initialValue={100}
                          rules={[{ required: true, message: I18N.t('请输入窗口偏移') }]}
                        >
                          <InputNumber min={0} max={200} step={10} />
                        </DMFormItem>
                      </Col>
                    </Row>
                  </DMFormItem>
                </DmCollapse>
              </>
            );
          }}
        </Form.Item>
      </DMFormItemContext.Provider>
    </Form>
  );
};

export default DiagonalLayoutForm;
