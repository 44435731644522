import I18N from '@/i18n';
import React, { useEffect, useState } from 'react';
import { Button, Space, Table, Typography } from 'antd';

import buttonStyles from '@/style/button.less';
import styles from '@/components/MessageCenter/components/messageDetail.less';
import { userCenterEmitter } from '@/components/UserCenter';
import {
  newUserMissionOrder,
  newUserMissionText,
  showBuyIpConfirmModal,
  showCreateShopConfirmModal,
} from '@/components/LeftMenu/NewUserActivityModal';
import { activityIsActivityFinishedGet } from '@/services/api-ActivityAPI/ActivityController';
import DMConfirm from '@/components/Common/DMConfirm';

type Props = {
  message: API.UserMessageVo;
  goto: (path: string) => void;
  close: () => void;
};

/**
 * 运营推广
 * @param props
 * @constructor
 */
const ActivityDetail: React.FC<Props> = (props) => {
  const { message, goto, close } = props;
  const { content } = message;
  let parameters: any = {};
  try {
    parameters = JSON.parse(message.parameters ?? '{}');
  } catch (e) {
    console.error('JSON parse failed', message.parameters);
  }
  const { activityCode, actions = [] } = parameters;
  const [actionVo = {}] = actions;
  if (activityCode === '2022.guide_create_fingerprint') {
    return (
      <>
        <p>
          {I18N.t(
            '请采集指纹模板，并通过指纹模板生成指纹实例，指纹实例创建成功后，您将获得1000花瓣的激励',
          )}
        </p>
        <p>{I18N.t('1、指纹实例是判断浏览器唯一性的特征标识')}</p>
        <p>
          {I18N.t(
            '2、花漾在业界首创“采集物理世界的浏览器特征信息以形成指纹模板，再对指纹模板加以微调与噪音从而裂变成若干指纹实例”的创新性做法，确保了每一个指纹实例都是真实且唯一的',
          )}
        </p>
        <p>
          {I18N.t(
            '3、有了指纹实例后，您便可以创建分身并将指纹实例绑定到分身上，每个分身则相当于一个完全隔离的浏览器分身环境，而指纹实例则赋予此浏览器分身环境唯一的特征信息',
          )}
        </p>
        <p>{I18N.t('4、花瓣是花漾提供的一种内部结算单位（或称代币），1元=100花瓣')}</p>
        <a onClick={() => goto(`/teamManage/fpManage/tpl/?showFetchTplModal=true`)}>
          {I18N.t('立即采集指纹模板')}
        </a>
      </>
    );
  }
  if (activityCode === '2022.createTeam.voucher') {
    const { amount, minOrderPrice, expireDays } = actionVo.params ?? {};
    return (
      <>
        <p>
          {I18N.t(
            '您有一张新的代金券已经到账，使用此代金券后只需再支付2元，即可购买一个海外IP地址（使用期：一周）',
          )}

          <a onClick={() => goto(`/costManage/voucher`)}>{I18N.t('立即查看')}</a>
        </p>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            <div>{I18N.t('面值')}</div>
            <div>
              {amount}
              {I18N.t('元')}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div>{I18N.t('最小订单价格')}</div>
            <div>{minOrderPrice ?? '--'}</div>
          </div>
          <div className={styles.tableRow}>
            <div>{I18N.t('有效天数')}</div>
            <div>
              {expireDays}
              {I18N.t('天')}
            </div>
          </div>
          <div className={styles.tableRow}>
            <div>{I18N.t('适用产品')}</div>
            <div>{I18N.t('购买IP')}</div>
          </div>
        </div>
      </>
    );
  }
  if (activityCode === '2022.createFingerprint.credit') {
    const { amount } = actionVo.params ?? {};
    return (
      <>
        <p>
          {I18N.t('恭喜您成功创建了指纹实例，已获得')}
          {amount}
          {I18N.t('花瓣激励，需要提醒您的是：')}
        </p>
        <p>{I18N.t('1、有了指纹实例后，您便可以创建分身并将指纹实例绑定在分身上')}</p>
        <p>
          {I18N.t(
            '2、每个分身相当于一个完全隔离的浏览器分身环境，而指纹实例则赋予此浏览器分身环境唯一的特征信息',
          )}
        </p>
        <p>
          {I18N.t('3、我们强烈推荐您一个指纹实例只能绑定在一个分身上，尽量避免重复使用指纹实例')}
        </p>
        <Space size="large">
          <a onClick={() => goto('/costManage/overview')}>{I18N.t('查看费用中心')}</a>
          <a onClick={() => goto('/shopManage/all?import=true')}>{I18N.t('立即创建分身')}</a>
        </Space>
      </>
    );
  }
  if (activityCode === '2022.createShop.credit') {
    const { amount } = actionVo.params ?? {};
    return (
      <>
        <p>
          {I18N.t('恭喜您成功创建了分身，已获得')}
          {amount}
          {I18N.t('花瓣激励，需要提醒您的是：')}
        </p>
        <p>{I18N.t('1、分身创建成功后，您需要为每个分身绑定一个指纹实例和一个IP地址')}</p>
        <p>
          {I18N.t(
            '2、您可通过“访问分身”以打开花漾灵动——这是一个和其它分身完全隔离的浏览器分身环境',
          )}
        </p>
        <a onClick={() => goto('/costManage/overview')}>{I18N.t('查看费用中心')}</a>
      </>
    );
  }
  if (activityCode === '2022.openSession.credit') {
    const { amount } = actionVo.params ?? {};
    return (
      <>
        <p>
          {I18N.t('恭喜您成功访问了分身，已获得')}
          {amount}
          {I18N.t('花瓣激励，需要提醒您的是：')}
        </p>
        <p>{I18N.t('1、您可以设置分身的访问策略，以约束团队其它成员访问分身时的规则与限制')}</p>
        <p>{I18N.t('2、您也可以通过分身联营或转让，与其它团队共同合作运营您的分身资源')}</p>
        <a onClick={() => goto('/costManage/overview')}>{I18N.t('查看费用中心')}</a>
      </>
    );
  }
  if (activityCode === '2022.guide.buyCredit') {
    return (
      <>
        <p>{I18N.t('现在购买花瓣，只要订单金额大于1元，即可获得1000花瓣的激励：')}</p>
        <p>{I18N.t('1、花瓣是花漾提供的一种内部结算单位（或称代币），1元=100花瓣')}</p>
        <p>
          {I18N.t(
            '2、针对SaaS应用的常见报价模式是基于套餐的报价，如专业版可以管理多少分身、企业版可以管理多少分身等等，这种方式最大的缺点是让用户为没有使用的配额提前买单；为最大程度让利于用户，花漾提供基于花瓣这种类似于点数的报价模式，让用户先用再买，用多少买多少',
          )}
        </p>
        <p>
          {I18N.t(
            '3、指纹实例配额、团队成员数量、存储空间大小、RPA执行次数等等，都会影响到花瓣的消耗，请确保团队内拥有足够数量的花瓣余额',
          )}
        </p>
        <a onClick={() => goto('/costManage/overview')}>{I18N.t('立即购买')}</a>
      </>
    );
  }
  if (activityCode === '2022.buyCredit.credit') {
    const { amount } = actionVo.params ?? {};
    return (
      <>
        <p>
          {I18N.t('恭喜您成功购买了花瓣，已获得')}
          {amount}
          {I18N.t('花瓣激励，需要提醒您的是：')}
        </p>
        <p>
          {I18N.t(
            '1、指纹实例配额、团队成员数量、存储空间大小、RPA执行次数等等，都会影响到花瓣的消耗',
          )}
        </p>
        <p>
          {I18N.t(
            '2、每天零点以后，系统会根据团队前一天的配额使用情况进行花瓣的扣除，请确保团队内拥有足够数量的花瓣余额，否则将影响部分功能特性的正常使用',
          )}
        </p>
        <a onClick={() => goto('/costManage/overview')}>{I18N.t('查看费用中心')}</a>
      </>
    );
  }
  if (activityCode === '2022.guide.bindPhone') {
    return (
      <>
        <p>{I18N.t('您尚未绑定手机，存在安全风险，现在绑定手机，即可获得1000花瓣的激励')}</p>
        <a
          onClick={() => {
            close();
            userCenterEmitter.emit('open');
            setTimeout(() => {
              document.getElementById('bind-phone-btn')?.click();
            }, 300);
          }}
        >
          {I18N.t('立即绑定')}
        </a>
      </>
    );
  }
  if (activityCode === '2022.bindPhone.credit') {
    const { amount } = actionVo.params ?? {};
    return (
      <>
        <p>
          {I18N.t('恭喜您成功绑定了手机，已获得')}
          {amount}
          {I18N.t('花瓣激励')}
        </p>
        <a onClick={() => goto('/costManage/overview')}>{I18N.t('查看费用中心')}</a>
      </>
    );
  }
  if (activityCode === '2022.guide.invoite') {
    return (
      <>
        <p>{I18N.t('您可邀请您的小伙伴们加入团队，即可获得30元代金券和3000花瓣的激励')}</p>
        <a onClick={() => goto('/teamManage/setting')}>{I18N.t('立即邀请')}</a>
      </>
    );
  }
  if (activityCode === '2022.invoite.present') {
    const voucherAction = actions.find((item: any) => item.actionCode === 'giving_vouchers');
    const creditAction = actions.find((item: any) => item.actionCode === 'giving_credit');
    const presentList = [];
    if (voucherAction) {
      presentList.push(`${voucherAction.params?.amount}${I18N.t('元代金券')}`);
    }
    if (creditAction) {
      presentList.push(`${creditAction.params?.amount}${I18N.t('花瓣')}`);
    }
    const presentText = presentList.join(presentList.length > 1 ? I18N.t('和') : '');
    return (
      <>
        <p>
          {I18N.t('恭喜您成功邀请小伙伴加入了团队，已获得')}
          {presentText}
          {I18N.t('激励，需要提醒您的是：')}
        </p>
        <p>{I18N.t('1、您可以创建组织单元，并将不同的团队成员置于不同的组织单元')}</p>
        <p>{I18N.t('2、团队成员可承担不同的角色')}</p>
        <p>{I18N.t('3、可通过授权将分身授权给团队成员或者组织单元')}</p>
        <a onClick={() => goto('/costManage/overview')}>{I18N.t('查看费用中心')}</a>
      </>
    );
  }
  if (activityCode === '2022.guide.bindWeixin') {
    return (
      <>
        <p>{I18N.t('您可以关注我们的微信公众号以获得1000花瓣激励，关注微信公众号后，您可以：')}</p>
        <p>{I18N.t('1、可通过微信公众号获得所有的消息推送')}</p>
        <p>{I18N.t('2、可通过微信扫码登录花漾')}</p>
        <a
          onClick={() => {
            close();
            userCenterEmitter.emit('open');
            setTimeout(() => {
              document.getElementById('bind-weixin-btn')?.click();
            }, 300);
          }}
        >
          {I18N.t('立即绑定')}
        </a>
      </>
    );
  }
  if (activityCode === '2022.bindWeixin.credit') {
    const { amount } = actionVo.params ?? {};
    return (
      <>
        <p>
          {I18N.t('恭喜您成功绑定了微信，已获得')}
          {amount}
          {I18N.t('花瓣激励')}
        </p>
        <a onClick={() => goto('/costManage/overview')}>{I18N.t('查看费用中心')}</a>
      </>
    );
  }
  if (activityCode === '2022.guide.recharge') {
    return (
      <>
        <p>{I18N.t('现在对账户余额充值，只要订单金额大于1元，即可获得10元现金的激励：')}</p>
        <p>{I18N.t('1、账户余额可以用来购买IP地址、续费IP地址、购买花瓣等')}</p>
        <p>{I18N.t('2、如果您购买的平台IP设置为自动续费，请确保拥有足够的账户余额')}</p>
        <a onClick={() => goto('/costManage/overview')}>{I18N.t('立即购买')}</a>
      </>
    );
  }
  if (activityCode === '2022.recharge.balance') {
    const { amount } = actionVo.params ?? {};
    return (
      <>
        <p>
          {I18N.t('恭喜您成功完成了充值，已获得')}
          {amount}
          {I18N.t('元现金激励，需要提醒您的是：')}
        </p>
        <p>{I18N.t('1、账户余额可以用来购买IP地址、续费IP地址、购买花瓣等')}</p>
        <p>{I18N.t('2、如果您购买的平台IP设置为自动续费，请确保拥有足够的账户余额')}</p>
        <a onClick={() => goto('/costManage/overview')}>{I18N.t('查看费用中心')}</a>
      </>
    );
  }
  if (activityCode === '2022.05.ac2.step0') {
    return <Ac2Step0Con close={close} />;
  }
  return (
    <>
      <p>{content ?? '--'}</p>
    </>
  );
};

function Ac2Step0Con(props: { close: () => void }) {
  const { close } = props;
  const [finishedMission, setFinishedMission] = useState<string[]>([]);

  useEffect(() => {
    const promiseArr: Promise<API.WebResultboolean>[] = [];
    newUserMissionOrder.forEach((code) => {
      promiseArr.push(activityIsActivityFinishedGet({ activityCode: code }));
    });
    Promise.all(promiseArr).then((resList) => {
      const finishedCodes: string[] = [];
      resList.forEach((res, idx) => {
        if (res.data) {
          finishedCodes.push(newUserMissionOrder[idx]);
        }
      });
      setFinishedMission(finishedCodes);
    });
  }, []);

  const dataSource = newUserMissionOrder.map((code) => ({ code, text: newUserMissionText[code] }));
  return (
    <>
      <p>
        <Table
          size="small"
          pagination={false}
          bordered
          columns={[
            {
              title: I18N.t('活动内容'),
              dataIndex: 'text',
              render: (dom, record, idx) => (
                <span>
                  {idx + 1}、{dom}
                </span>
              ),
            },
            {
              title: I18N.t('状态'),
              key: 'status',
              width: 140,
              render: (dom, record) =>
                finishedMission.includes(record.code) ? (
                  <Typography.Text type="success">{I18N.t('已完成')}</Typography.Text>
                ) : (
                  I18N.t('未完成')
                ),
            },
          ]}
          dataSource={dataSource}
        />
      </p>
      <p>
        {I18N.t(
          '新手有礼活动第一阶段，完成本次全部任务后，会开启后续阶段，总共可获得180元激励礼包',
        )}
      </p>
      <Button
        className={
          finishedMission.length === newUserMissionOrder.length ? buttonStyles.disabled : ''
        }
        type="primary"
        onClick={() => {
          if (finishedMission.length === newUserMissionOrder.length) {
            DMConfirm({
              type: 'info',
              title: I18N.t('活动已结束'),
              content: I18N.t(
                '活动已结束，无法继续参与，您可以关注我们的微信公众号，以持续参与我们的活动',
              ),
            });
          } else {
            const currentStep = newUserMissionOrder.findIndex(
              (item) => !finishedMission.includes(item),
            );
            if (currentStep === 1) {
              showBuyIpConfirmModal();
            } else if (currentStep === 2) {
              showCreateShopConfirmModal();
            }
            close();
          }
        }}
      >
        {finishedMission.length === newUserMissionOrder.length
          ? I18N.t('活动已结束')
          : I18N.t('继续参与')}
      </Button>
    </>
  );
}

export default ActivityDetail;
