import I18N from '@/i18n';

/* eslint-disable no-bitwise */
export default {
  orderType: {
    BuyIp: I18N.t('购买IP'),
    BuyTraffic: I18N.t('购买流量'),
    CashOut: 'CashOut',
    OrderCancel: 'OrderCancel',
    Recharge: I18N.t('账户充值'),
    RechargeCredit: I18N.t('购买花瓣'),
    Refund: I18N.t('退款'),
    RenewIp: I18N.t('IP续费'),
    MakeupPriceDifference: I18N.t('补差价'),
    BuyRpaVoucher: I18N.t('购买流程任务卡'),
    RenewRpaVoucher: I18N.t('流程任务卡续费'),
    BuyTkPack: I18N.t('购买TK套餐'),
    RenewTkPack: I18N.t('TK套餐续费'),
  },
  goodsType: {
    IP: I18N.t('IP+共享式主机'),
    ExclusiveIp: I18N.t('IP+独享式主机'),
    SharingIp: I18N.t('共享IP'),
    IpGo: 'IPGO', // IPGO服务，IPGO宿主机非平台所有，而是用户所有
    IpProxy: 'IpProxy', // IP代理服务，代理IP的商品类型
    UserExclusiveIp: I18N.t('用户自有云账号IP'), // 用户自有云账号独享IP
    Credit: I18N.t('花瓣'), // 花瓣的商品
    TransitTraffic: I18N.t('流量加速'), // 接入点流量商品
    TeamMemberQuota: I18N.t('团队成员'), // 团队成员配额商品
    FingerprintQuota: I18N.t('指纹实例'), // 指纹实例配额商品
    StorageQuota: I18N.t('存储空间'), // 存储空间配额商品
    RpaExecuteQuota: I18N.t('流程任务'), // RPA次数配额商品
    Voucher: I18N.t('代金券'), // 代金券，目前支持伙伴购买代金券
  },
  networkType: {
    cloudIdc: I18N.t('公有云'),
    proxyIdc: I18N.t('数据中心'),
    residential: I18N.t('家庭住宅'),
    mobile: I18N.t('移动网络'),
    unknown: I18N.t('未知'),
    // unknownIdc: '未知IDC',
  },
  // 各售卖周期的价格字段
  periodUnitPriceField: {
    周: 'weekPrice',
    月: 'price',
  },
  providers: {
    aliyun: I18N.t('阿里云'),
    aws: I18N.t('亚马逊'),
    qcloud: I18N.t('腾讯云'),
    googlecloud: I18N.t('Google云'),
    huayang: I18N.t('花漾'),
    luminati: 'Bright Data',
    ipidea: 'ipidea',
    netnut: 'netnut',
    oxylabs: 'oxylabs',
    aws_cn: I18N.t('亚马逊中国'),
    azure: I18N.t('微软Azure'),
    baidu: I18N.t('百度云'),
    huaweicloud: I18N.t('华为云'),
    jdcloud: I18N.t('京东云'),
    KooKeey: 'KooKeey',
    PrivateProxy: 'PrivateProxy',
    IPRoyal: 'IPRoyal',
    SOAX: 'SOAX',
    other: I18N.t('其他'),
    ProxyLine: 'ProxyLine',
    jdeip: I18N.t('住宅IP'),
    ucloud: 'UCloud',
  },
  voucherType: {
    General: I18N.t('通用'),
    IpOnly: I18N.t('IP购买'),
    TrafficOnly: I18N.t('流量购买'),
  },
  bank: {
    accountName: I18N.t('深圳市云上悦动科技有限公司'),
    account: '337010100102252166',
    bankName: I18N.t('兴业银行股份有限公司深圳分行'),
  },
};

export const VoucherType = {
  General: 0xffff, // 通用代金券，能用在所有地方
  BuyIp: 1 << 0, // 只能用于购买新ip
  RenewIp: 1 << 1, // 只能用于ip续费
  Traffic: 1 << 15, // 只能用于购买流量
  Rpa: 1 << 2, // 只能用于购买流量
};

export const VoucherTypeText = {
  General: I18N.t('通用'), // 通用代金券，能用在所有地方
  BuyIp: I18N.t('IP购买'), // 只能用于购买新ip
  RenewIp: I18N.t('IP续费'), // 只能用于ip续费
  Traffic: I18N.t('流量'), // 只能用于购买流量
  Rpa: I18N.t('流程任务卡'),
};

export const VoucherTypeIndex = {
  General: `1_${VoucherType.General}`,
  BuyIp: `1_${VoucherType.BuyIp}`,
  RenewIp: `1_${VoucherType.RenewIp}`,
  Traffic: `1_${VoucherType.Traffic}`,
  Rpa: `1_${VoucherType.Rpa}`,
};

export const AvailableVoucherType = {
  BuyIp: VoucherType.BuyIp,
  BuyTraffic: VoucherType.Traffic,
  RenewIp: VoucherType.RenewIp,
  Rpa: VoucherType.Rpa,
};
export const PerformanceEnum = {
  CostEffective: I18N.t('高性价比'),
  LargeTraffic: I18N.t('大流量'),
  HighlyConcurrent: I18N.t('高并发'),
  RemoteLogin: I18N.t('可远程'),
  UnlimitedTraffic: I18N.t('企业级'),
};
export const CurrencyEnum = {
  RMB: I18N.t('元'),
  USD: I18N.t('美元'),
  CREDIT: I18N.t('花瓣'),
};
