import ProTable from '@ant-design/pro-table';
import { scrollProTableOptionFn } from '@/mixins/table';
import { useVT } from 'virtualizedtableforantd4';
import type { ReactNode } from 'react';
import type { GetComponentProps, GetRowKey } from 'rc-table/lib/interface';

type Props<T> = {
  height: number;
  dataSource: T[];
  renderItem: (data: T, key?: string) => ReactNode;
  rowKey: string | GetRowKey<T>;
  onRow?: GetComponentProps<T>;
};

function VirtualList<T>(props: Props<T>) {
  const { height, dataSource, renderItem, rowKey, onRow } = props;
  const [vt, setVt] = useVT(() => ({ scroll: { y: height }, initTop: 0 }), []);

  return (
    <ProTable
      bordered={false}
      showHeader={false}
      {...scrollProTableOptionFn({ pagination: false, scroll: { y: height }, size: 'small' })}
      dataSource={dataSource}
      components={vt}
      rowKey={rowKey}
      columns={[
        {
          dataIndex: 'title',
          render(text, record) {
            const key = _.isFunction(rowKey) ? rowKey(record) : record[rowKey];
            return renderItem(record, key);
          },
          onCell: (record) => {
            return _.merge(
              {
                style: {
                  border: 'none',
                },
              },
              onRow?.(record) || {},
            );
          },
        },
      ]}
    />
  );
}
export default VirtualList;
