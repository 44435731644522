export const TEAM_NOT_MEMBER = 1002; // 不在这个团队内
export const TEAM_MEMBER_BLOCKED = 1007; // 被禁用
export const RESOURCE_NOT_AUTHORIZED = 403; // 资源未授权
export const RESOURCE_NOT_FOUND = 404; // 资源不存在
export const TEAM_MEMBER_NOT_BOSS_OR_SA = 1008; // 不是BOSS和超管
export const TEAM_QUOTA_EXCEED = 1009; // 不是BOSS和超管
export const ACCOUNT_AUTH_NO_SELF = 122; // 授权时，把自己的整的没权限了
export const ALREADY_LOGIN = 406; // 已经登录.
export const RPA_BANNED_ON_DEVICE = 209;
export const USER_BLOCKED = 1012; // 不是BOSS和超管
export const TEAM_MEMBER_INVALID_DEVICE = 1013; // 不在设备白名单内
export const TEAM_INSUFFICIENT_CREDIT = 1014; // 花瓣余额不足
export const TEAM_BLOCKED = 1011; // 团队被禁用

export const MEMBER_ERROR_CODE_COLLECTION = [
  TEAM_NOT_MEMBER,
  TEAM_MEMBER_BLOCKED,
  USER_BLOCKED,
  TEAM_MEMBER_INVALID_DEVICE,
];
export const STRATEGY_CODE_COLLECTION = [1016, 1017, 1018, 1019];
