import I18N from '@/i18n';
import type { FC, MutableRefObject } from 'react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ModalForm } from '@ant-design/pro-form';
import type { CaptchaInstance } from './Captcha';
import Captcha from './Captcha';
import { t } from '@/utils/utils';
import type { ButtonProps } from 'antd';
import { Button, Col, Form, Input, message, Row, Typography } from 'antd';
import { accountVerifyCodeGet as verifyCode } from '@/services/api-Account/RegisterController';
import styles from './tips.less';
import classNames from 'classnames';
import type { FormInstance } from 'antd/es/form';
import type { NamePath } from 'antd/lib/form/interface';
import DMModal, { getDefaultModalProps } from '@/components/Common/Modal/DMModal';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import { useModalCaller } from '@/mixins/modal';
import IconFontIcon from '@/components/Common/IconFontIcon';

interface Props {
  type: 'bindEmail';
  form: FormInstance;
  identify?: NamePath;
  beforeOpen?: () => Promise<any>;
  buttonProps?: ButtonProps;
  size?: 'large' | 'small' | 'middle';
}

/**
 * 验证码button
 * @param props
 * @constructor
 */
const EmailCaptchaButton: (props: Props) => JSX.Element = (props) => {
  const {
    type,
    form,
    identify = 'account',
    beforeOpen = () => Promise.resolve(),
    buttonProps = {},
  } = props;
  const { onClick, disabled, ...otherProps } = buttonProps;
  const [error, updateError] = useState('');
  const [countDown, setCountDown] = useState(60);
  const [timing, toggleTiming] = useState(false);
  const [visible, toggleModal] = useState(false);
  const [innerForm] = Form.useForm();
  const captchaRef: MutableRefObject<CaptchaInstance> = useRef();
  const timer = useRef();
  useEffect(() => {
    if (timing) {
      timer.current = setInterval(() => {
        setCountDown((preSecond) => {
          if (preSecond <= 1) {
            toggleTiming(false);
            clearInterval(timer.current); // 重置秒数
            return 60;
          }

          return preSecond - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer.current);
    };
  }, [timing]);

  return (
    <ModalForm
      onChange={() => {
        updateError('');
      }}
      form={innerForm}
      visible={visible}
      layout="horizontal"
      trigger={
        <Button
          style={{ height: 'auto' }}
          disabled={timing}
          {...otherProps}
          onClick={() => {
            form.validateFields([identify]).then(() => {
              beforeOpen().then(() => {
                toggleModal(true);
              });
            });
          }}
        >
          {timing ? `${countDown}${I18N.t('秒后重新获取')}` : I18N.t('获取验证码')}
        </Button>
      }
      title={I18N.t('获取验证码')}
      width={360}
      submitter={{
        render(_, dom) {
          return (
            <Row wrap={false} style={{ overflow: 'hidden' }}>
              <Col style={{ flex: 1, overflow: 'hidden' }}>
                {error && (
                  <div className={classNames(styles.statusInfo)}>
                    <IconFontIcon iconName="Warning-Circle_24" style={{ fontSize: 'inherit' }} />
                    <Typography.Text type={'danger'} ellipsis={{ tooltip: error }} className="text">
                      {error}
                    </Typography.Text>
                  </div>
                )}
              </Col>
              <Col>{dom.reverse()}</Col>
            </Row>
          );
        },
      }}
      modalProps={{
        ...getDefaultModalProps({
          onCancel() {
            toggleModal(false);
            updateError('');
          },
          destroyOnClose: true,
        }),
      }}
      onFinish={async ({ captcha }) => {
        updateError('');
        const account = form.getFieldValue(identify);
        return verifyCode(
          {
            account,
            action: type,
            captcha,
          },
          {
            errorHandler: (err) => {
              throw err;
            },
          },
        )
          .then((res) => {
            const { success } = res;
            if (success) {
              toggleTiming(true);
              message.success(`${I18N.t('邮箱验证码已成功发送至')}${account}`);
              toggleModal(false);
            }
          })
          .catch((err) => {
            const fn = () => {
              captchaRef.current.update();
              updateError(err.toString().replace('BizError:', ''));
            };
            fn();
          });
      }}
    >
      <Form.Item
        name="captcha"
        rules={[{ required: true, message: I18N.t('请输入图形验证码') }]}
        label={I18N.t('图形验证码')}
        labelCol={{ span: 7 }}
        validateTrigger="onBlur"
      >
        <Input
          autoFocus
          autoComplete="off"
          placeholder={I18N.t('请输入图形验证码')}
          onPressEnter={innerForm.submit}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 7 }}>
        <Captcha ref={captchaRef} />
      </Form.Item>
    </ModalForm>
  );
};

interface CaptchaModalProps {
  onSubmit: (code: string) => Promise<any>;
  title?: JSX.Element;
}
const EmailCaptchaModal: FC<CaptchaModalProps> = (props) => {
  const { onSubmit, title = I18N.t('图形验证码') } = props;
  const [visible, changeVisible] = useState(true);
  const [error, setError] = useState('');
  const captchaRef = useRef();
  const [form] = Form.useForm();
  const footer = useMemo(() => {
    return (
      <Row wrap={false} style={{ overflow: 'hidden' }}>
        <Col style={{ flex: 1, overflow: 'hidden' }}>
          {error && (
            <div className={classNames(styles.statusInfo)}>
              <IconFontIcon iconName={'Warning-Circle_24'} style={{ fontSize: 'inherit' }} />
              <span className="text">{error}</span>
            </div>
          )}
        </Col>
        <Col>
          <Button type="primary" onClick={form.submit}>
            {I18N.t('确定')}
          </Button>
          <Button
            onClick={() => {
              changeVisible(false);
            }}
          >
            {I18N.t('取消')}
          </Button>
        </Col>
      </Row>
    );
  }, [error, form?.submit]);
  return (
    <DMModal
      title={title}
      footer={footer}
      visible={visible}
      onCancel={() => {
        changeVisible(false);
      }}
    >
      <DMFormItemContext.Provider
        value={{
          disableLabelMuted: true,
          labelWidth: 110,
        }}
      >
        <Form
          form={form}
          onFinish={async (values) => {
            try {
              await onSubmit(values.captcha);
              changeVisible(false);
            } catch (e) {
              setError(e.message);
              captchaRef.current.update();
            }
          }}
        >
          <DMFormItem
            name="captcha"
            rules={[{ required: true, message: I18N.t('请输入图形验证码') }]}
            label={I18N.t('图形验证码')}
            validateTrigger="onBlur"
          >
            <Input
              autoFocus
              autoComplete="off"
              placeholder={I18N.t('请输入图形验证码')}
              onPressEnter={form.submit}
            />
          </DMFormItem>
          <div style={{ marginLeft: 110 }}>
            <Captcha ref={captchaRef} />
          </div>
        </Form>
      </DMFormItemContext.Provider>
    </DMModal>
  );
};
export function useCaptchaModal(options?: CaptchaModalProps) {
  const modalCaller = useModalCaller(true);
  return useCallback(
    (args?: Props) => {
      modalCaller({
        component: <EmailCaptchaModal {...options} {...args} />,
      });
    },
    [modalCaller, options],
  );
}
/**
 * 验证码及输入验证码Input
 * @param props
 * @constructor
 */
export const EmailCaptchaGroup: React.FC<Props & { codeIdentify?: string; label?: string }> = (
  props,
) => {
  const { codeIdentify = 'verifyCode', form, label } = props;
  return (
    <DMFormItem
      label={label}
      validateTrigger="onBlur"
      name={codeIdentify}
      rules={[
        {
          required: true,
          whitespace: true,
          message: I18N.t('请输入邮箱验证码'),
        },
      ]}
    >
      <div
        style={{
          display: 'flex',
          overflow: 'hidden',
          flexWrap: 'nowrap',
          gap: 8,
          alignItems: 'stretch',
          alignContent: 'stretch',
        }}
      >
        <Input
          type="search"
          autoComplete={'new-password'}
          style={{ flex: 1, overflow: 'hidden' }}
          placeholder={I18N.t('请输入邮箱验证码')}
          onPressEnter={() => {
            form.submit();
          }}
        />

        <EmailCaptchaButton {...props} />
      </div>
    </DMFormItem>
  );
};
export default EmailCaptchaButton;
