import { useCallback, useState } from 'react';

export default () => {
  // 当前IP详情的缓存
  const [phoneWatchers, setPhoneWatchers] = useState<
    Record<
      number,
      { id: number; nickname: string; role: string; teamId: number; teamName: string }[]
    >
  >({});

  const updatePhoneWatchers = useCallback(
    (phoneId: number, watchers: { id: number; nickname: string }[]) => {
      setPhoneWatchers((oldPhoneWatchers) => {
        const newPhoneWatchers = { ...oldPhoneWatchers };
        newPhoneWatchers[phoneId] = watchers;
        return newPhoneWatchers;
      });
    },
    [],
  );

  return { phoneWatchers, updatePhoneWatchers };
};
