// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 更新当前用户头像 POST /api/account/avatar */
export async function accountAvatarPost(
  body: Record<string, any>,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/avatar', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查看用户头像 GET /api/account/avatar/${param0} */
export async function accountAvatarByFileGet(
  params: {
    // path
    /** file */
    file: string;
  },
  options?: { [key: string]: any },
) {
  const { file: param0, ...queryParams } = params;
  return request<Record<string, any>>(`/api/account/avatar/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 查询当前用户信息 GET /api/account/curUser */
export async function accountCurUserGet(options?: { [key: string]: any }) {
  return request<API.WebResultUserDetailVo>('/api/account/curUser', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取当前用户在当前团队中的角色 GET /api/account/currentRole */
export async function accountCurrentRoleGet(options?: { [key: string]: any }) {
  return request<API.WebResultRoleVo>('/api/account/currentRole', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查询当前用户信息 GET /api/account/currentUser */
export async function accountCurrentUserGet(options?: { [key: string]: any }) {
  return request<API.WebResultUserDetailVo>('/api/account/currentUser', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 更换邮箱 PUT /api/account/email */
export async function accountEmailPut(
  params: {
    // query
    /** 邮箱 */
    email: string;
    /** 验证码 */
    verifyCode: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/email', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修改用户昵称和签名 PUT /api/account/nicknameAndSignature */
export async function accountNicknameAndSignaturePut(
  params: {
    // query
    /** nickname */
    nickname: string;
    /** signature */
    signature: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/nicknameAndSignature', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 微信号绑定成功后触发活动事件 PUT /api/account/notifyWeixinBind/${param0} */
export async function accountNotifyWeixinBindByTeamIdPut(
  params: {
    // path
    /** teamId */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/account/notifyWeixinBind/${param0}`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 更换手机号 PUT /api/account/phone */
export async function accountPhonePut(
  params: {
    // query
    /** areaCode */
    areaCode?: string;
    /** 手机号 */
    phone: string;
    /** 验证码 */
    verifyCode: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/phone', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 设置登录密码和昵称 POST /api/account/setPwdAndNickname */
export async function accountSetPwdAndNicknamePost(
  params: {
    // query
    /** 昵称 */
    nickname: string;
    /** updateToken */
    updateToken: string;
    /** password */
    password: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/setPwdAndNickname', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 设置登录密码和昵称 POST /api/account/setPwdWhenEmpty */
export async function accountSetPwdWhenEmptyPost(
  params: {
    // query
    /** password */
    password: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/setPwdWhenEmpty', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 解绑邮箱 PUT /api/account/unbindEmail */
export async function accountUnbindEmailPut(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/account/unbindEmail', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 解绑手机号 PUT /api/account/unbindPhone */
export async function accountUnbindPhonePut(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/account/unbindPhone', {
    method: 'PUT',
    ...(options || {}),
  });
}

/** 修改登录密码 POST /api/account/updatePwd */
export async function accountUpdatePwdPost(
  params: {
    // query
    /** 原始登录密码 */
    originPassword?: string;
    /** 新的登录密码 */
    curPassword: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/updatePwd', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 通过短信验证码修改密码 POST /api/account/updatePwdByVerifyCode */
export async function accountUpdatePwdByVerifyCodePost(
  params: {
    // query
    /** areaCode */
    areaCode?: string;
    /** 账号 */
    account: string;
    /** 密码 */
    password: string;
    /** 验证码 */
    verifyCode: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/updatePwdByVerifyCode', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据用户id查看用户头像 GET /api/account/${param0}/avatar */
export async function accountByUserIdAvatarGet(
  params: {
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<Record<string, any>>(`/api/account/${param0}/avatar`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 发送邀请成员加入团队的链接 POST /api/link/invite-join-team */
export async function linkInviteJoinTeamPost(
  params: {
    // query
    departmentId?: number;
    emailList?: string[];
    roleCode?: 'boss' | 'manager' | 'staff' | 'superadmin';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/link/invite-join-team', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 邀请成员加入团队的链接响应 GET /api/link/invite-join-team/${param0} */
export async function linkInviteJoinTeamByTokenGet(
  params: {
    // path
    /** token */
    token: string;
  },
  options?: { [key: string]: any },
) {
  const { token: param0, ...queryParams } = params;
  return request<API.WebResultInviteJoinTeamCheckResult>(`/api/link/invite-join-team/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 邀请成员加入团队的链接响应 POST /api/link/invite-join-team/${param0}/confirm */
export async function linkInviteJoinTeamByTokenConfirmPost(
  params: {
    // query
    /** password */
    password?: string;
    // path
    /** token */
    token: string;
  },
  options?: { [key: string]: any },
) {
  const { token: param0, ...queryParams } = params;
  return request<API.WebResultConfirmInviteJoinTeamResult>(
    `/api/link/invite-join-team/${param0}/confirm`,
    {
      method: 'POST',
      params: {
        ...queryParams,
      },
      ...(options || {}),
    },
  );
}
