// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取客户端IP GET /api/meta/ip */
export async function metaIpGet(options?: { [key: string]: any }) {
  return request<string>('/api/meta/ip', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取IP检查器 GET /api/meta/ip/checkers */
export async function metaIpCheckersGet(options?: { [key: string]: any }) {
  return request<API.WebResultRemoteIpCheckerConfig>('/api/meta/ip/checkers', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 添加ip归属地 POST /api/meta/ip/location */
export async function metaIpLocationPost(
  params: {
    // query
    /** countryCode */
    countryCode: string;
    /** province */
    province: string;
    /** city */
    city?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultIpLocationDto>('/api/meta/ip/location', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询国家级别地理位置 GET /api/meta/ip/location/countries */
export async function metaIpLocationCountriesGet(options?: { [key: string]: any }) {
  return request<API.WebResultListIpLocationDto>('/api/meta/ip/location/countries', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查询特定国家级别地理位置 GET /api/meta/ip/location/country */
export async function metaIpLocationCountryGet(
  params: {
    // query
    /** countryCode */
    countryCode: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultIpLocationDto>('/api/meta/ip/location/country', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询位置信息 GET /api/meta/ip/location/${param0} */
export async function metaIpLocationByLocationIdGet(
  params: {
    // path
    /** locationId */
    locationId: number;
  },
  options?: { [key: string]: any },
) {
  const { locationId: param0, ...queryParams } = params;
  return request<API.WebResultIpLocationDto>(`/api/meta/ip/location/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 查询位置信息层次 GET /api/meta/ip/location/${param0}/hierarchy */
export async function metaIpLocationByLocationIdHierarchyGet(
  params: {
    // path
    /** locationId */
    locationId: number;
  },
  options?: { [key: string]: any },
) {
  const { locationId: param0, ...queryParams } = params;
  return request<API.WebResultListIpLocationDto>(`/api/meta/ip/location/${param0}/hierarchy`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 返回ip归属地列表（树结构） GET /api/meta/ip/locationTree */
export async function metaIpLocationTreeGet(
  params: {
    // query
    /** countryCode */
    countryCode: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListProvinceDetailVo>('/api/meta/ip/locationTree', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取客户端IP（或指定IP）的位置信息 GET /api/meta/ip/myLocation */
export async function metaIpMyLocationGet(
  params: {
    // query
    /** ip */
    ip?: string;
    /** force */
    force?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultIpLocationVo>('/api/meta/ip/myLocation', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 搜索位置 匹配城市、省、国家 GET /api/meta/ip/search */
export async function metaIpSearchGet(
  params: {
    // query
    /** query */
    query: string;
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultIpLocationDto>('/api/meta/ip/search', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 搜索城市 可以不设置countryCode和provinceCode，只搜素city（匹配中英文） GET /api/meta/ip/searchCity */
export async function metaIpSearchCityGet(
  params: {
    // query
    /** countryCode */
    countryCode?: string;
    /** provinceCode */
    provinceCode?: string;
    /** query */
    query: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListIpLocationDto>('/api/meta/ip/searchCity', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据countryCode搜索省 GET /api/meta/ip/searchProvince */
export async function metaIpSearchProvinceGet(
  params: {
    // query
    /** countryCode */
    countryCode: string;
    /** query */
    query: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListProvinceVo>('/api/meta/ip/searchProvince', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取客户端IP（或指定IP）的位置信息 GET /api/meta/ip138 */
export async function metaIp138Get(
  params: {
    // query
    /** ip */
    ip?: string;
    /** force */
    force?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultIpDataVo>('/api/meta/ip138', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
