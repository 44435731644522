import type { RowProps } from 'antd';
import ColoursIcon from '@/components/Common/ColoursIcon';
import type { FC, ReactNode } from 'react';
import {
  metaIpLocationByLocationIdHierarchyGet,
  metaIpMyLocationGet,
} from '@/services/api-MetaAPI/MetaIpController';
import { isIconValid } from '@/utils/iconUtils';
import I18N from '@/i18n/I18N';
import { useEffect, useState } from 'react';
import Placeholder from '@/components/Common/Placeholder';
import _ from 'lodash';
import IconFontIcon from '@/components/Common/IconFontIcon';

/**
 * 国家图标
 * @param props
 * @constructor
 */
export const CountryIcon = (props: {
  [x: string]: any;
  country: any;
  rollbackIcon?: ReactNode;
  size?: number;
}) => {
  const stl = {
    fontSize: 'inherit',
  };
  const {
    country,
    style,
    rollbackIcon = (
      <ColoursIcon size={16} title={country} style={stl} className="IPguishushiqu_24" />
    ),
    ...others
  } = props;

  let countryClass = country?.replaceAll(' ', '_');
  if (countryClass === 'HongKong') {
    countryClass = 'Hong_Kong';
  }
  if (!isIconValid(countryClass)) {
    return rollbackIcon as JSX.Element;
  }
  return <ColoursIcon size={16} title={country} className={countryClass} style={stl} {...others} />;
};

interface Props extends RowProps {
  location: API.IpLocationDto;
  onlyCountry?: boolean;
  showIcon?: boolean;
  rollbackIcon?: ReactNode;
  iconSize?: number;
}
const IpLocation: FC<Props> = (props) => {
  const {
    location,
    rollbackIcon,
    onlyCountry = true,
    showIcon = true,
    iconSize = 16,
    ...others
  } = props;
  if (!location) {
    return null;
  }

  const { countryEn = '', country = '--', province, city, provinceEn, cityEn, level } = location;
  const _country = (I18N.isCn() ? country : countryEn) || country;
  const detail = [];
  if (level === 'City') {
    if (province || provinceEn) {
      if (I18N.isCn() && province) {
        detail.push(province);
      } else if (provinceEn) {
        detail.push(provinceEn);
      }
    }
    if (city || cityEn) {
      if (I18N.isCn() && city) {
        detail.push(city);
      } else if (cityEn) {
        detail.push(city);
      }
    }
  } else if (level === 'Province') {
    if (province || provinceEn) {
      if (I18N.isCn() && province) {
        detail.push(province);
      } else if (provinceEn) {
        detail.push(provinceEn);
      }
    }
  }

  if (detail.length) {
    detail.unshift('');
  }
  const detailText = detail.join('，');
  return (
    <div
      style={{
        overflow: 'hidden',
        height: '100%',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap',
        display: 'flex',
      }}
      title={_country + detailText}
      {...others}
    >
      <div style={{ display: 'flex', gap: 4 }}>
        {showIcon && (
          <span style={{ minWidth: iconSize }}>
            <CountryIcon size={iconSize} country={countryEn} rollbackIcon={rollbackIcon} />
          </span>
        )}
        <span
          style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {_country}
        </span>
      </div>
      {!onlyCountry && (
        <span
          style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {detailText}
        </span>
      )}
    </div>
  );
};
export default IpLocation;

const LocationCache: Record<string, Promise<API.IpLocationVo>> = {};
export function useIpLocation(ip: string) {
  const [data, setData] = useState<API.IpLocationVo>();
  useEffect(() => {
    if (_.isString(ip)) {
      if (!LocationCache[ip]) {
        LocationCache[ip] = metaIpMyLocationGet({ ip }).then((res) => {
          return res.data!;
        });
      }
      LocationCache[ip].then((res) => {
        setData(res);
      });
    }
  }, [ip]);
  return data;
}

const LocationIdCache: Record<number, Promise<API.IpLocationVo[]>> = {};
export function useIpLocationById(locationId: number) {
  const [data, setData] = useState<API.IpLocationVo[]>();
  useEffect(() => {
    if (_.isNumber(locationId)) {
      if (!LocationIdCache[locationId]) {
        LocationIdCache[locationId] = metaIpLocationByLocationIdHierarchyGet({
          locationId,
        }).then((res) => {
          return res.data?.filter((item) => ['Country', 'Province', 'City'].includes(item.level!))!;
        });
      }
      LocationIdCache[locationId].then((res) => {
        setData(res);
      });
    }
  }, [locationId]);
  return data;
}

export const IpLocationById = (props: {
  locationId: number;
  onlyCountry?: boolean;
  rollbackIcon?: ReactNode;
}) => {
  const { locationId, onlyCountry, rollbackIcon } = props;
  const data = useIpLocationById(locationId);
  if (!data) {
    return <IconFontIcon size={16} iconName={'loading_24'} spin />;
  }
  if (data.length) {
    return (
      <IpLocation location={_.first(data)!} onlyCountry={onlyCountry} rollbackIcon={rollbackIcon} />
    );
  }
  return <Placeholder />;
};
