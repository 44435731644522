import { useState } from 'react';
import { setCurrentTeam as setTeam, setCurrentAuths } from '@/hooks/useCurrentTeam';

export default () => {
  const [currentTeam, setCurrentTeam] = useState<API.TeamStatVo>();
  const [currentTeamAuthFunctions, setCurrentTeamAuthFunctions] = useState<API.FunctionVo[]>([]);

  const hasAuthedFunction = (code: string | string[], userId: number | undefined): boolean => {
    if (typeof code === 'string') {
      return (
        currentTeam?.creatorId === userId || !!currentTeamAuthFunctions.find((o) => o.id === code)
      );
    }

    return (
      currentTeam?.creatorId === userId ||
      !!currentTeamAuthFunctions.find((o) => o.id && code.includes(o.id))
    );
  };

  return {
    currentTeam,
    setCurrentTeam: (team: API.TeamStatVo) => {
      setTeam(team);
      setCurrentTeam(team);
    },
    currentTeamAuthFunctions,
    setCurrentTeamAuthFunctions: (list: API.FunctionVo[]) => {
      setCurrentAuths(list);
      setCurrentTeamAuthFunctions(list);
    },
    hasAuthedFunction,
  };
};
