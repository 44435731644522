// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 激活一张礼品卡 POST /api/payment/gift-card/activeAGiftCard */
export async function paymentGiftCardActiveAGiftCardPost(
  params: {
    // query
    /** receivingTeamId */
    receivingTeamId?: number;
    /** cardNumber */
    cardNumber: string;
    /** cardPassword */
    cardPassword: string;
    /** 是否立即激活，如果 false 说明只想检查一下密码对不对，并取回相应信息 */
    activeNow?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultGiftCardPackItemVo>('/api/payment/gift-card/activeAGiftCard', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
