import { useEffect, useState } from 'react';
import EventEmitter from 'events';
import { accountCurrentUserGet } from '@/services/api-Account/AccountController';
import { SkipErrorNotifyOption } from '@/utils/utils';
import { refreshJwt } from '@/utils/jwt';

const emitter = new EventEmitter();
emitter.setMaxListeners(9999);
const UPDATE_EVENT = Symbol('update');

export type User = API.UserDetailVo;
let currentUser: User;

export function setCurrentUser(data: User) {
  currentUser = data;
  emitter.emit(UPDATE_EVENT, data);
}
export function getCurrentUser() {
  return currentUser;
}
export function clearCurrentUser() {
  setCurrentUser(null!);
}
export async function fetchCurrentUser() {
  try {
    const res = await accountCurrentUserGet({ ...SkipErrorNotifyOption, ignoreTeamId: true });
    setCurrentUser(res.data!);
    refreshJwt();
    return res.data!;
  } catch (e) {
    console.log(e);
    refreshJwt();
  }
  return undefined;
}
export default function useCurrentUser() {
  const [user, setUser] = useState<User>(currentUser);

  useEffect(() => {
    emitter.addListener(UPDATE_EVENT, setUser);
    return () => {
      emitter.removeListener(UPDATE_EVENT, setUser);
    };
  }, []);

  return {
    user,
  };
}
