import {RpcTransport} from './type';
import {low_char_and_num, random} from './utils';
import {Buffer} from 'buffer';
import toBuffer from 'blob-to-buffer';
import {getApiUrl, isPublicPage} from '@/utils/utils';

const max_frame_length = 10 * 1024 - 4;
const protocol_version_1 = 1;
const first_mark = 0x01; // 0000 0001
const last_mark = 0x02; // 0000 0010

const client_init = {
  action: 'protocol',
  type: 'clientInit',
  version: protocol_version_1
};

let timer: NodeJS.Timeout;
export default class WsTransport extends RpcTransport {
  ws!: WebSocket;

  status: 'connecting' | 'connected' | 'disconnected' = 'disconnected';

  constructor() {
    super();
  }

  start() {
    this.status = 'connecting';
    this.reconnect();
  }

  stop() {
    this.status = 'disconnected';
    this.ws?.close();
  }

  isConnected(): boolean {
    return this.status === 'connected';
  }

  send(request: any): string {
    if(!request.action) {
      return '';
    }
    let requestId = request.requestId || random.nextString(16, low_char_and_num);
    if(this.status == 'connected') {
      let action = request.action;
      let type = request.type;
      delete request.action;
      delete request.requestId;
      delete request.type;
      let proto = JSON.stringify({
        action, requestId, type
      });
      let pb = Buffer.from(proto, 'utf8');
      let pbl = Buffer.alloc(2);
      pbl.writeUInt16BE(pb.length, 0);
      let buff = Buffer.concat([pbl, pb, Buffer.from(JSON.stringify(request), 'utf8')]);
      let exactly = true;
      let count = Math.floor(buff.length / max_frame_length);
      if (max_frame_length * count < buff.length) {
        count++;
        exactly = false;
      }
      for (let i = 0; i < count; i++) {
        let mark = (i == 0 ? first_mark : 0) | (i == count - 1 ? last_mark : 0);
        let sliceSize = max_frame_length;
        if (i == count - 1 && !exactly) {
          sliceSize = buff.length % max_frame_length;
        }
        let slice = buff.slice(0, sliceSize);
        let head = Buffer.alloc(2);
        head.writeInt8(mark, 0);
        head.writeInt8(protocol_version_1, 1);
        let data = Buffer.concat([head, slice]);
        this.ws.send(data);
        buff = buff.slice(sliceSize);
      }
    } else {
      console.error('[ws-rpc] 未连接到服务器，无法发送消息');
    }
    return requestId;
  }

  private cache: Buffer = Buffer.alloc(0);
  private handleBuffer(buff: Buffer) {
    //buff[0-1] 作为预留扩展位
    let total = (buff[2] << 8) | (buff[3] << 0);
    let current = (buff[4] << 8) | (buff[5] << 0);
    if (current == 1) {
      //first slice
      this.cache = Buffer.alloc(0);
    }
    this.cache = Buffer.concat([this.cache, buff.slice(6)]);
    if (current == total) {
      //last slice
      try {
        let json = this.cache.toString('utf8');
        let response = JSON.parse(json);
        if(response.action === 'protocol') {
          switch (response.name) {
            case 'websocket/connected':
              this.status = 'connected';
              this.emit('connected');
              this.send(client_init);
              return;
            case 'websocket/unauthorized':
              console.error('[ws-rpc] 服务端返回状态未登录.');
              timer = setTimeout(() => {
                this._reconnect();
              }, 3000);
              return;
          }
        }
        try {
          this.responseHandler.onResponse(response);
        } catch (e) {
          //framework dont care ignore
        }
      } catch (err: any) {
        console.error('Failed to parse response:', err);
      }
    }
  }
  reconnect() {
    clearTimeout(timer);
    timer = setTimeout(() => {
      this._reconnect();
    }, 3000);
  }

  private _reconnect() {
    if (this.status == 'disconnected') {
      return;
    }
    if(isPublicPage()) {
      console.log('[ws-rpc] 当前页跳过websocket重连.');
      timer = setTimeout(() => {
        this._reconnect();
      }, 3000);
      return;
    }
    if (this.ws) {
      if (this.ws.readyState == WebSocket.OPEN) {
        return;
      }
      this.ws.close();
    }

    const websocketUrl = this.getWebsocketUrl();
    this.status = 'connecting';
    this.ws = new WebSocket(websocketUrl);
    this.ws.onopen = () => {
      console.info('[ws-rpc] websocket 通道连接成功');
    };

    this.ws.onmessage = async (_ws) => {
      toBuffer(_ws.data, (error, buffer) => {
        if (error) {
          throw error;
        }
        this.handleBuffer(buffer);
      });
    };

    this.ws.onclose = () => {
      this.status = 'connecting';
      console.error('[ws-rpc] WebSocket closed, reconnecting...');
      this.reconnect();
    };
    this.ws.onerror = (_ws) => {
      console.error('[ws-rpc] WebSocket connection got an error.', _ws);
      //?
    };
  }

  getWebsocketUrl() {
    const newUrl = getApiUrl('/api/hy_ws');
    return newUrl.replace('http', 'ws').replace('https', 'wss');
  }
}
