// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 取消一个聊天回复 PUT /api/ai/conversation/cancelChat */
export async function aiConversationCancelChatPut(
  params: {
    // query
    /** chatJobId */
    chatJobId: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ai/conversation/cancelChat', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 清空对话的历史记录 DELETE /api/ai/conversation/conversation/${param0} */
export async function aiConversationConversationByConversationIdDelete(
  params: {
    // path
    /** conversationId */
    conversationId: number;
  },
  options?: { [key: string]: any },
) {
  const { conversationId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ai/conversation/conversation/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取对话信息 GET /api/ai/conversation/findConversation */
export async function aiConversationFindConversationGet(
  params: {
    // query
    /** conversationType */
    conversationType: 'helper' | 'rpa_editor' | 'visitor_chat';
    /** 有些对话是绑定到特定的资源上的，如rpa编辑器助理绑定了流程id */
    resourceId?: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultAiConversationDto>('/api/ai/conversation/findConversation', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除消息 DELETE /api/ai/conversation/message/${param0} */
export async function aiConversationMessageByMessageIdDelete(
  params: {
    // path
    /** messageId */
    messageId: number;
  },
  options?: { [key: string]: any },
) {
  const { messageId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/ai/conversation/message/${param0}`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 访客取消一个聊天回复 PUT /api/ai/conversation/visitor/cancelChat */
export async function aiConversationVisitorCancelChatPut(
  params: {
    // query
    /** chatJobId */
    chatJobId: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/ai/conversation/visitor/cancelChat', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 网站访客获取对话信息。调用之前请确保cookie: toc-tg-visitor 已经存在，否则会抛异常 GET /api/ai/conversation/visitor/findConversation */
export async function aiConversationVisitorFindConversationGet(options?: { [key: string]: any }) {
  return request<API.WebResultAiConversationDto>('/api/ai/conversation/visitor/findConversation', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 网站访客发送消息，生成回复。调用之前请确保cookie: toc-tg-visitor 已经存在，否则会抛异常 GET /api/ai/conversation/visitor/${param0}/chat/stream */
export async function aiConversationVisitorByConversationIdChatStreamGet(
  params: {
    // query
    /** 消息内容 */
    content: string;
    // path
    /** conversationId */
    conversationId: number;
  },
  options?: { [key: string]: any },
) {
  const { conversationId: param0, ...queryParams } = params;
  return request<API.SseEmitter>(`/api/ai/conversation/visitor/${param0}/chat/stream`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取某个网站访客对话的消息列表。调用之前请确保cookie: toc-tg-visitor 已经存在，否则会抛异常 GET /api/ai/conversation/visitor/${param0}/messages */
export async function aiConversationVisitorByConversationIdMessagesGet(
  params: {
    // query
    /** 拉取该消息之前的@limit条消息，为空表示拉最新的 */
    lastMsgId?: number;
    /** 拉取多少条消息 */
    limit?: number;
    // path
    /** conversationId */
    conversationId: number;
  },
  options?: { [key: string]: any },
) {
  const { conversationId: param0, ...queryParams } = params;
  return request<API.WebResultListAiConversationMsgDto>(
    `/api/ai/conversation/visitor/${param0}/messages`,
    {
      method: 'GET',
      params: {
        ...queryParams,
      },
      ...(options || {}),
    },
  );
}

/** streamChat GET /api/ai/conversation/${param0}/chat/stream */
export async function aiConversationByConversationIdChatStreamGet(
  params: {
    // query
    /** 消息内容 */
    content: string;
    // path
    /** conversationId */
    conversationId: number;
  },
  options?: { [key: string]: any },
) {
  const { conversationId: param0, ...queryParams } = params;
  return request<API.SseEmitter>(`/api/ai/conversation/${param0}/chat/stream`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取某个对话的消息列表 GET /api/ai/conversation/${param0}/messages */
export async function aiConversationByConversationIdMessagesGet(
  params: {
    // query
    /** 拉取该消息之前的@limit条消息，为空表示拉最新的 */
    lastMsgId?: number;
    /** 拉取多少条消息 */
    limit?: number;
    // path
    /** conversationId */
    conversationId: number;
  },
  options?: { [key: string]: any },
) {
  const { conversationId: param0, ...queryParams } = params;
  return request<API.WebResultListAiConversationMsgDto>(`/api/ai/conversation/${param0}/messages`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** streamRpaChat GET /api/ai/conversation/${param0}/rpaChat/stream */
export async function aiConversationByConversationIdRpaChatStreamGet(
  params: {
    // query
    /** 消息内容 */
    content: string;
    // path
    /** conversationId */
    conversationId: number;
  },
  options?: { [key: string]: any },
) {
  const { conversationId: param0, ...queryParams } = params;
  return request<API.SseEmitter>(`/api/ai/conversation/${param0}/rpaChat/stream`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}
