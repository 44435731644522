import I18N from '@/i18n';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Form, message, Space, Tooltip, Typography } from 'antd';
import {
  addEventListener,
  emitEvent,
  getAppVersion,
  sendAsync,
  sendSync,
} from '@/utils/ElectronUtils';
import { getPathSep } from '@/utils/utils';
import DMConfirm, { DMLoading } from '@/components/Common/DMConfirm';
import { formatBytes } from '@/utils/format/number';
import Placeholder from '@/components/Common/Placeholder';
import type { SysPresSettings } from '@/i18n/I18N';
import { getSysPresSettings, setSysPresSettings } from '@/i18n/I18N';
import ProTable from '@ant-design/pro-table';
import { scrollProTableOptionFn } from '@/mixins/table';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { ProFormText } from '@ant-design/pro-form';
import { useRequest } from '@@/plugin-request/request';
import { Pie } from '@ant-design/charts';
import _ from 'lodash';
import pMinDelay from 'p-min-delay';
import LabelRow, { LabelRowContext } from '@/components/Common/LabelRow';

type SizeStat = { size?: number; path?: string; free?: number; total?: number; disk?: string };
/**
 * 配置文件路径设置
 */
function DataDirForm() {
  const [form] = Form.useForm();
  const sysPresSettingsState = getSysPresSettings();
  const cacheStat = useRef<Record<string, SizeStat>>({});
  const appVersion = getAppVersion();
  const [dataSource, setDataSource] = useState<
    {
      type: string;
      name: string;
      description: string;
      stat: SizeStat;
      path?: string;
    }[]
  >([]);
  const loadSize = useCallback(
    async (path: string) => {
      if (parseFloat(appVersion) >= 10.6) {
        try {
          const stat = await sendAsync('disk-space-of-path', {
            path,
          });
          cacheStat.current[path] = stat;
          setDataSource((prevState) => {
            const newState = [...prevState];
            newState.forEach((item, index) => {
              if (item.path === path) {
                newState[index] = {
                  ...item,
                  stat,
                };
              }
            });
            return newState;
          });
        } catch (e) {
          message.error(e.message);
        }
      }
    },
    [appVersion],
  );
  const initDataSource = useCallback(() => {
    setDataSource([
      {
        type: I18N.t('浏览器配置文件'),
        name: 'dataDir',
        path: sysPresSettingsState.dataDir,
        description: I18N.t(
          '存储浏览器分身环境的缓存信息，随着分身的增多，它往往会占据较大的硬盘空间，建议将其从系统盘中移出',
        ),
        stat: {} as SizeStat,
      },
      {
        type: I18N.t('录像文件下载位置'),
        name: 'videoDir',
        path: sysPresSettingsState.videoDir,
        description: I18N.t('查看分身访问日志并希望下载录像文件时，录像文件的默认存放位置'),
        stat: {} as SizeStat,
      },
      {
        type: I18N.t('RPA运行预览存储位置'),
        name: 'rpaDir',
        path: sysPresSettingsState.rpaDir,
        description: I18N.t(
          '在流程定义编辑器中运行预览RPA流程时，流程引擎生成的文件的默认存储位置',
        ),
        stat: {} as SizeStat,
      },
      {
        type: I18N.t('花漾客户端缓存'),
        name: 'appCache',
        description: I18N.t('花漾门户的缓存信息，当发觉花漾客户端无法正常工作时，可以考虑进行清理'),
        stat: {} as SizeStat,
      },
    ]);
  }, [sysPresSettingsState]);
  const loadAppCacheSize = useCallback(() => {
    return sendAsync('get-app-cache-size')
      .then((size) => {
        setDataSource((prevState) => {
          const newState = [...prevState];
          const index = prevState.findIndex((item) => item.name === 'appCache');
          newState[index] = {
            ...prevState[index],
            stat: { size },
          };
          return newState;
        });
      })
      .catch((e) => {
        message.error(e.message);
      });
  }, []);

  useEffect(() => {
    initDataSource();
    loadAppCacheSize();
  }, [initDataSource, loadAppCacheSize]);
  const { run: onDataChange } = useRequest(
    async (values: SysPresSettings) => {
      const _arr: any[] = [];
      Object.entries(values).forEach(([key, value]) => {
        if (['dataDir', 'rpaDir', 'videoDir'].includes(key)) {
          _arr.push(loadSize(value as string));
        }
      });
      await Promise.all(_arr);
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    onDataChange(sysPresSettingsState);
  }, [sysPresSettingsState, onDataChange, loadAppCacheSize]);

  const { run: clearAppCache, loading: isClearingCache } = useRequest(
    async () => {
      await sendAsync('clear-app-cache')
        .catch((e) => {
          message.error(e.message);
        })
        .finally(() => {
          loadAppCacheSize();
        });
    },
    {
      manual: true,
    },
  );

  useEffect(() => {
    const removeListener = addEventListener('path-selected', (evt, { key, path, separator }) => {
      let subDir = '';
      if (key === 'dataDir') {
        subDir = 'HuaYoungBrowser';
      } else if (key === 'rpaDir') {
        subDir = 'HuaYoungRPA';
      } else if (key === 'videoDir') {
        subDir = 'HuaYoung';
      }
      let _path = path;
      if (subDir) {
        if (!_path.endsWith(separator)) {
          _path += separator;
        }
        _path += subDir;
      }
      let loadingModal: any;
      if (['dataDir', 'rpaDir'].includes(key) && form.getFieldValue(key) !== _path) {
        loadingModal = DMLoading({
          title: I18N.t('正在为您进行文件的迁移'),
          content: I18N.t(
            '配置文件的路径发生了变化，正在为您进行相关文件的迁移，这可能需要一点时间，请稍等...',
          ),

          width: 550,
        });
      }
      form.setFieldsValue({ [key]: _path });
      pMinDelay(setSysPresSettings({ ...sysPresSettingsState, ...form.getFieldsValue() }), 2000)
        .then(() => {
          loadingModal?.destroy();
        })
        .catch((err) => {
          message.error(err.message);
          loadingModal?.destroy();
        });
      setDataSource((prevState) => {
        const newState = [...prevState];
        const index = prevState.findIndex((item) => item.name === key);
        newState[index] = {
          ...prevState[index],
          path: _path,
          stat: {},
        };
        return newState;
      });
      loadSize(_path);
    });
    return () => {
      removeListener();
    };
  }, []);

  const checkSession = useCallback(() => {
    return new Promise((resolve, reject) => {
      const sessionIds = sendSync('get-opening-session-ids', {});
      if (sessionIds.length > 0) {
        let checked = true;
        DMConfirm({
          title: I18N.t('需要关闭所有浏览器实例才能够修订此位置'),
          content: (
            <>
              <div>
                {I18N.t(
                  '浏览器配置文件存储了浏览器分身的缓存数据，为避免出现数据紊乱，需要关闭所有花漾浏览器才允许修订此配置文件所在位置',
                )}
              </div>
              <div style={{ marginTop: 8 }}>
                <Checkbox
                  defaultChecked={checked}
                  onChange={(e) => {
                    checked = e.target.value;
                  }}
                >
                  {I18N.t('帮我关闭所有花漾浏览器')}
                </Checkbox>
              </div>
            </>
          ),

          onOk: () => {
            if (checked) {
              return sendAsync('close-all-browsers')
                .then(() => {
                  resolve(true);
                })
                .catch((e) => reject(e));
            }
            resolve(true);
            return true;
          },
          onCancel: () => {
            reject();
          },
        });
      } else {
        resolve(true);
      }
    });
  }, []);

  const { run: changePath } = useRequest(
    async (name: string) => {
      if (name !== 'videoDir') {
        await checkSession();
      }
      const pathArr = (form.getFieldValue(name) ?? '').split(getPathSep());
      emitEvent('show-path-select-dialog', {
        key: name,
        defaultPath: pathArr.slice(0, pathArr.length - 1).join(getPathSep()),
      });
    },
    {
      manual: true,
    },
  );
  return (
    <Form
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        flex: 1,
        overflow: 'hidden',
        gap: 16,
        paddingTop: 16,
      }}
      form={form}
      initialValues={{ ...sysPresSettingsState! }}
    >
      <Alert
        showIcon
        message={I18N.t('花漾配置文件的存储位置，如果空间不足，可以考虑移动其存储位置')}
      />
      <div style={{ flex: 1, overflow: 'hidden' }}>
        <ProFormText hidden name={'dataDir'} />
        <ProFormText hidden name={'videoDir'} />
        <ProFormText hidden name={'rpaDir'} />
        <ProTable
          {...scrollProTableOptionFn()}
          dataSource={dataSource}
          rowKey={'name'}
          columns={[
            {
              dataIndex: 'type',
              title: I18N.t('类型'),
              width: 170,
              render(text, record) {
                const { type, description } = record;
                return (
                  <Tooltip placement={'leftBottom'} title={description}>
                    <Typography.Text ellipsis={{ tooltip: type }}>{type}</Typography.Text>
                  </Tooltip>
                );
              },
            },
            {
              dataIndex: 'location',
              title: I18N.t('存储位置'),
              render(text, record) {
                const { name, path } = record;
                if (name === 'appCache') {
                  return <Placeholder />;
                }
                return (
                  <Typography.Text
                    ellipsis={{
                      tooltip: path,
                    }}
                  >
                    {path}
                  </Typography.Text>
                );
              },
            },
            {
              dataIndex: 'size',
              title: I18N.t('大小'),
              width: 120,
              shouldCellUpdate(record, prevRecord) {
                return !_.isEqual(record, prevRecord);
              },
              render(text, record) {
                const { name, stat } = record;
                if (parseFloat(appVersion) < 10.6 && name !== 'appCache') {
                  return <Placeholder />;
                }
                if (stat?.size === undefined) {
                  return <IconFontIcon iconName={'loading_24'} spin />;
                }
                if (name === 'appCache') {
                  return (
                    <div style={{ display: 'flex', alignItems: 'center', height: 24 }}>
                      {formatBytes(stat.size)}
                    </div>
                  );
                }
                const danger = stat.free < 5 * 1024 * 1024 * 1024 || stat.free / stat.total < 0.05;
                const data = [
                  {
                    type: name,
                    color: danger ? '#b90100' : '#5a9bd5',
                    value: stat.size,
                  },
                  {
                    type: 'other',
                    color: '#7f7f7f',
                    value: stat.total - stat.free - stat.size,
                  },
                  {
                    type: 'remain',
                    color: '#d9d9d9',
                    value: stat.free,
                  },
                ];
                let label = I18N.t('存储空间');
                if (name === 'dataDir') {
                  label = I18N.t('浏览器配置文件');
                } else if (name === 'videoDir') {
                  label = I18N.t('录像文件');
                } else if (name === 'rpaDir') {
                  label = I18N.t('RPA运行预览文件');
                }
                return (
                  <Tooltip
                    placement={'leftBottom'}
                    overlayInnerStyle={{ paddingRight: 32 }}
                    title={
                      <LabelRowContext.Provider value={{ labelMuted: false, labelWidth: 110 }}>
                        <LabelRow label={I18N.t('磁盘空间总容量')}>
                          <span>{formatBytes(stat.total)}</span>
                        </LabelRow>
                        <LabelRow label={label}>
                          <span>{formatBytes(stat.size)}</span>
                        </LabelRow>
                        <LabelRow label={I18N.t('其他文件')}>
                          <span>{formatBytes(stat.total - stat.free - stat.size)}</span>
                        </LabelRow>
                        <LabelRow label={I18N.t('剩余可用空间')}>
                          <span>{formatBytes(stat.free)}</span>
                        </LabelRow>
                      </LabelRowContext.Provider>
                    }
                  >
                    <div
                      style={{
                        height: 24,
                        alignItems: 'center',
                        display: 'flex',
                        flexWrap: 'nowrap',
                        gap: 8,
                      }}
                    >
                      <Pie
                        {...{
                          style: {
                            width: 24,
                            height: 24,
                          },
                          data,
                          width: 23,
                          height: 23,
                          appendPadding: 0,
                          padding: 0,
                          autoFit: true,
                          angleField: 'value',
                          colorField: 'type',
                          radius: 1,
                          innerRadius: 0.6,
                          label: false,
                          legend: false,
                          color(datum) {
                            return data.find((item) => item.type === datum.type)?.color;
                          },
                          tooltip: false,
                          statistic: false,
                        }}
                      />
                      <span>{formatBytes(stat.size)}</span>
                    </div>
                  </Tooltip>
                );
              },
            },
            {
              valueType: 'option',
              title: I18N.t('操作'),
              width: 80,
              render(text, record) {
                const { name } = record;
                if (name === 'appCache') {
                  return (
                    <Tooltip title={I18N.t('清空缓存')}>
                      <Button
                        style={{ width: 24, height: 24, padding: 0 }}
                        icon={<IconFontIcon iconName={'qingchu_24'} />}
                        type="link"
                        loading={isClearingCache}
                        onClick={clearAppCache}
                      />
                    </Tooltip>
                  );
                }
                return (
                  <Space size={2}>
                    <Tooltip title={I18N.t('更改存储位置')}>
                      <Button
                        style={{
                          width: 24,
                          height: 24,
                          padding: 0,
                        }}
                        type="link"
                        icon={<IconFontIcon iconName={'edit_24'} />}
                        onClick={() => {
                          changePath(name);
                        }}
                      />
                    </Tooltip>
                    <Tooltip title={I18N.t('打开存储位置')}>
                      <Button
                        style={{ width: 24, height: 24, padding: 0 }}
                        icon={<IconFontIcon iconName={'xinjianyeqian_24'} />}
                        type="link"
                        onClick={() => {
                          emitEvent('open-path', { path: sysPresSettingsState[name] });
                        }}
                      />
                    </Tooltip>
                  </Space>
                );
              },
            },
          ]}
        />
      </div>
    </Form>
  );
}

export default DataDirForm;
