import I18N from '@/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import type { PaginationProps } from 'antd';
import { Space, Popover, Button, message } from 'antd';
import IconFontIcon from '@/components/Common/IconFontIcon';
import styles from './index.less';
import { storePageSize } from '@/utils/utils';
import { InputNumber } from 'antd';
import classNames from 'classnames';

export type Props = PaginationProps & {
  pageId?: any;
  maxPageSize?: number;
};
const MAX_PAGE_SIZE = 200;
const MIN_PAGE_SIZE = 5;

/**
 * 分页器
 * @param props
 * @constructor
 */
const Index: React.FC<Props> = (props) => {
  const { pageId, ...pagination } = props;
  const {
    onChange,
    pageSize = MIN_PAGE_SIZE,
    maxPageSize = MAX_PAGE_SIZE,
    total = 0,
    current = 1,
  } = pagination;
  const [size, changePageSize] = useState(pageSize);
  const [pageSizeVisible, toggleSizeChanger] = useState(false);
  const [currentPage, changeCurrentPage] = useState(current);
  const [currentPageVisible, toggleCurrentChanger] = useState(false);
  const totalPage = Math.ceil(total / size);
  const [inputSize, setInputSize] = useState(Math.min(pageSize));
  const [inputCurrent, setInputCurrent] = useState(current);
  useEffect(() => {
    changePageSize(pageSize);
    setInputSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    changeCurrentPage(current);
    setInputCurrent(current);
  }, [current]);

  const goToPage = useCallback(
    (page) => {
      changeCurrentPage(page);
      const targetPage = Math.min(page, totalPage);
      if (onChange) {
        onChange(targetPage, size);
      }
    },
    [onChange, size, totalPage],
  );

  const nextPage = useCallback(() => {
    const targetPage = currentPage + 1;
    changeCurrentPage(targetPage);
    goToPage(targetPage);
  }, [currentPage, goToPage]);
  const prevPage = useCallback(() => {
    const targetPage = currentPage - 1;
    changeCurrentPage(targetPage);
    goToPage(targetPage);
  }, [currentPage, goToPage]);

  const emitPageSizeChange = useCallback(() => {
    const value = inputSize;
    if (value < MIN_PAGE_SIZE || maxPageSize < value || !value) {
      message.warn(
        `${I18N.t('每页显示条数应该在')}${MIN_PAGE_SIZE} - ${maxPageSize}${I18N.t('之间')}`,
      );
    } else {
      changePageSize(value);
      storePageSize(pageId, value);
      if (onChange) {
        onChange(1, value);
      }
      toggleSizeChanger(false);
    }
  }, [inputSize, onChange, pageId]);

  const emitCurrentPageChange = useCallback(() => {
    const value = inputCurrent;
    if (!value) {
      message.warn(`${I18N.t('请输入您要跳转的页码')}`);
    } else {
      goToPage(value);
      toggleCurrentChanger(false);
    }
  }, [goToPage, inputCurrent]);
  return (
    <Space wrap={false} style={{ whiteSpace: 'nowrap' }} className={classNames('pagination')}>
      <span>
        {I18N.t('共')}
        {total}
        {I18N.t('条记录，')}
      </span>
      <Popover
        destroyTooltipOnHide
        content={
          <Space>
            <span>{I18N.t('每页显示')}</span>
            <InputNumber
              autoFocus
              min={1}
              size={'small'}
              value={inputSize}
              className={classNames(styles.setPageSize, 'set-page-size')}
              onChange={(val) => {
                setInputSize(val);
              }}
              onPressEnter={() => {
                emitPageSizeChange();
              }}
            />

            <span>{I18N.t('条')}</span>
            <Button
              size="small"
              type={'primary'}
              disabled={!inputSize}
              onClick={() => {
                emitPageSizeChange();
              }}
            >
              {I18N.t('确定')}
            </Button>
          </Space>
        }
        trigger="click"
        visible={pageSizeVisible}
        onVisibleChange={(e) => toggleSizeChanger(e)}
      >
        <a>
          {size}
          {I18N.t('条/页')}
        </a>
      </Popover>
      <span className={classNames(styles.pageList, 'page-list')}>
        <IconFontIcon
          iconName="angle-double-left_24"
          disabled={currentPage <= 1}
          onClick={() => goToPage(1)}
        />

        <IconFontIcon
          iconName="angle-left_24"
          disabled={currentPage <= 1}
          onClick={() => prevPage()}
        />

        <span className={classNames(styles.currentPage, 'current-page')}>
          {currentPage} / {totalPage}
          {I18N.t('页')}
        </span>
        <IconFontIcon
          iconName="angle-right_24"
          disabled={currentPage >= totalPage}
          onClick={() => nextPage()}
        />

        <IconFontIcon
          iconName="angle-double-right_24"
          disabled={currentPage === totalPage}
          onClick={() => goToPage(totalPage)}
        />
      </span>
      <Popover
        content={
          <Space>
            <span>{I18N.t('跳转到')}</span>
            <InputNumber
              autoFocus
              size="small"
              min={1}
              max={totalPage}
              value={inputCurrent}
              className={classNames(styles.gotoPage, 'goto-page')}
              onChange={(val) => {
                setInputCurrent(val);
              }}
              onPressEnter={() => {
                emitCurrentPageChange();
              }}
            />

            <span>{I18N.t('页')}</span>
            <Button
              size="small"
              type="primary"
              disabled={!inputCurrent}
              onClick={() => {
                emitCurrentPageChange();
              }}
            >
              {I18N.t('确定')}
            </Button>
          </Space>
        }
        trigger="click"
        visible={currentPageVisible}
        onVisibleChange={(e) => toggleCurrentChanger(e)}
      >
        <a>{I18N.t('跳转')}</a>
      </Popover>
    </Space>
  );
};

export default Index;
