import I18N from '@/i18n';
import type { HTMLProps } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import IconFontIcon from '@/components/Common/IconFontIcon';
import _ from 'lodash';
import { Col, Input, message, Row, Space, Tag, Typography } from 'antd';
import styles from '@/style/tag.less';
import classNames from 'classnames';
import {
  tagByTagIdResourceDelete as resource,
  tagByTagIdDelete as tagUseDelete,
  tagByTagIdPut as tagUsePut,
} from '@/services/api-ShopAPI/TagController';
import { t } from '@/utils/utils';
import DMConfirm, { DMLoading } from '@/components/Common/DMConfirm';
import pMinDelay from 'p-min-delay';

function getTagColor(tag: API.TagVo) {
  const { color } = tag;
  if (color) {
    return `#${color.toString(16)}`;
  }
  return 'inherit';
}

type Props = HTMLProps<HTMLDivElement> & {
  tag: API.TagVo;
  resourceId?: number;
  onUpdate?: () => void;
  editable?: boolean;
  closable?: boolean;
  className?: string;
  checkable?: boolean;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  onClose?: (tag: API.TagVo, e: Event) => void;
  showCount?: boolean;
  updateDb?: boolean;
};

export const TagRow = (props: { tag: API.TagVo; className?: string }) => {
  const { tag, className } = props;
  return (
    <Row wrap={false} gutter={8} align="middle" className={className} style={{ fontSize: 14 }}>
      <Col style={{ minWidth: '1em' }}>
        <IconFontIcon
          iconName="biaoqian_241"
          size={'inherit'}
          style={{ color: getTagColor(tag) }}
        />
      </Col>
      <Col style={{ flex: 1, overflow: 'hidden' }}>
        <Typography.Text ellipsis title={tag.tag} className={styles.tagText}>
          {tag.tag}
        </Typography.Text>
      </Col>
    </Row>
  );
};

/**
 * 标签Item
 * @param props
 * @constructor
 */
const TagItem: React.FC<Props> = (props) => {
  const {
    tag,
    resourceId,
    className,
    closable,
    onUpdate,
    editable,
    checkable,
    onChange,
    checked,
    updateDb = true,
    onClose: onDelete,
    showCount,
    ...tagProps
  } = props;
  const [editing, toggleEdit] = useState(false);
  const { resourceType, id, tag: text, resourceCount } = tag;
  const [inputValue, setValue] = useState(text);
  const [selected, toggle] = useState(checked || false);
  const onSave = useCallback(() => {
    const value = _.trim(inputValue);
    if (value.length < 2) {
      message.error(I18N.t('标签至少两个字符'));
    }
    tagUsePut({
      tag: value,
      tagId: id!,
      resourceType,
    }).then(() => {
      toggleEdit(false);
      onUpdate?.();
    });
  }, [id, inputValue, onUpdate, resourceType]);
  const onClose = useCallback(
    (e) => {
      if (onDelete && !updateDb) {
        onDelete(tag, e);
      } else if (resourceId) {
        resource({
          resourceType,
          resourceId,
          tagId: id!,
        }).then(() => {
          onUpdate?.();
        });
      } else {
        DMConfirm({
          title: I18N.t('确定要删除此标签吗？'),
          content: I18N.t('在此处删除标签意味着会对所有打过此标签的对象移除此标签，请确认是否继续'),
          onOk() {
            const loading = DMLoading({
              title: I18N.t('正在为您删除此标签，请稍候...'),
            });
            pMinDelay(
              tagUseDelete({
                tagId: id!,
                resourceType,
              }),
              1000,
            )
              .then(() => {
                loading.destroy();
                onUpdate?.();
              })
              .catch((e) => {
                loading.destroy();
                console.log(e);
              });
          },
        });
      }
    },
    [id, onDelete, onUpdate, resourceId, resourceType, tag, updateDb],
  );
  const content = useMemo(() => {
    if (editing) {
      return (
        <Row gutter={4} wrap={false} align="middle">
          <Col style={{ flex: 1, overflow: 'hidden' }}>
            <Input
              value={inputValue}
              size="small"
              autoFocus={!updateDb}
              bordered={false}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              required
              onPressEnter={onSave}
            />
          </Col>
          <Col>
            <IconFontIcon
              iconName="check_24"
              className={styles.saveBtn}
              onClick={() => {
                onSave();
              }}
            />
          </Col>
          <Col>
            <IconFontIcon
              iconName="guanbi_24"
              className={styles.cancelBtn}
              onClick={() => {
                toggleEdit(false);
                setValue(tag.tag);
              }}
            />
          </Col>
        </Row>
      );
    }
    return (
      <Row align="middle" style={{ flex: 1, overflow: 'hidden' }}>
        <Col style={{ flex: 1, overflow: 'hidden' }}>
          <TagRow tag={tag} />
        </Col>
        {(editable || closable || showCount) && (
          <Col className="extra">
            {showCount && !editing && <span className="count">{resourceCount}</span>}
            <Space className="actions" size={4}>
              {editable && (
                <IconFontIcon
                  iconName="edit_24"
                  className={styles.editBtn}
                  onClick={() => {
                    toggleEdit(true);
                  }}
                />
              )}

              {closable && !editing && (
                <IconFontIcon
                  iconName={resourceId ? 'Close-Circle_24' : 'Trash_24'}
                  className={styles.deleteBtn}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClose(e);
                    e.preventDefault();
                    return false;
                  }}
                />
              )}
            </Space>
          </Col>
        )}
      </Row>
    );
  }, [
    closable,
    editable,
    editing,
    inputValue,
    onClose,
    onSave,
    resourceCount,
    showCount,
    tag,
    updateDb,
  ]);
  const options = {
    className: classNames(className, styles.tag, 'tag', {
      [styles.cursor]: editable || closable || checkable,
      editable,
      selected,
      checkable,
    }),
    ...tagProps,
  };
  if (checkable) {
    return (
      <div className={classNames(styles.tagWrap, 'tag-wrap')}>
        <Tag.CheckableTag
          onChange={(val) => {
            if (onChange) {
              onChange(val);
            }
            toggle(val);
          }}
          checked={selected}
          {...options}
        >
          {content}
        </Tag.CheckableTag>
      </div>
    );
  }
  return (
    <div className={classNames(styles.tagWrap, 'tag-wrap')}>
      <Tag {...options}>{content}</Tag>
    </div>
  );
};
export default TagItem;
