import React, { useCallback } from 'react';
import { message } from 'antd';
import _ from 'lodash';
import I18N from '@/i18n/I18N';
import {
  getPortalUrl,
  getTeamIdFromUrl,
  SkipErrorNotifyOption,
  stringEllipsis,
} from '@/utils/utils';
import { GhostModalCaller } from '@/mixins/modal';
import AuthModal from '@/components/Common/AuthModal';
import InfoTip from '@/components/Tips/InfoTip';
import HelpLink from '@/components/HelpLink';
import {
  shopMobileGetSimpleInfoGet,
  shopMobileGrantMobileToUsersPost,
  shopMobileGroupAddMobilesPut,
  shopMobileGroupRemoveMobilesPost,
  shopMobileGroupUpdatePost,
  shopMobileRestartAdbServerPut,
} from '@/services/api-ShopAPI/TeamMobileGroupController';
import { ACCOUNT_AUTH_NO_SELF } from '@/constants/ErrorCode';
import DMConfirm, { DMLoading } from '@/components/Common/DMConfirm';
import CountdownBtn from '@/components/Common/CountdownBtn';
import { sendAsync } from '@/utils/ElectronUtils';

export const useUtils = () => {
  const changePhoneAuth = useCallback(
    (selectedPhoneIds, phoneVo?: API.TeamMobileVo, onFinish?: () => void) => {
      const title =
        selectedPhoneIds.length > 1
          ? I18N.t('批量修改所选手机授权')
          : I18N.t(
              `手机“{{name}}”授权`,
              {
                name: stringEllipsis(phoneVo?.name ?? '', 16),
              },
              'Authorization',
            );
      GhostModalCaller(
        <AuthModal
          tooltip={
            <InfoTip
              message={
                <div>
                  {I18N.t('除超管与BOSS外，其它用户必须授权后才能够访问此手机')}
                  <HelpLink style={{ marginLeft: 12 }} href={'/team/permission'} />
                </div>
              }
            />
          }
          showClean={selectedPhoneIds.length > 1}
          selected={{
            users:
              selectedPhoneIds?.length > 1
                ? []
                : phoneVo?.grantUserVoList?.map((item) => item.id!) ?? [],
            departments:
              selectedPhoneIds?.length > 1
                ? []
                : phoneVo?.grantDepartmentList?.map((item) => item.id!) || [],
          }}
          title={title}
          onSubmit={(keyInfo) => {
            const { users, departments, cleanFirst } = keyInfo;
            return new Promise<void>((resolve, reject) => {
              shopMobileGrantMobileToUsersPost(
                {
                  confirm: false,
                  cleanFirst,
                  teamId: getTeamIdFromUrl(),
                  userIdList: users,
                  departmentIdList: departments,
                  mobileIds: selectedPhoneIds,
                },
                SkipErrorNotifyOption,
              )
                .then(() => {
                  resolve();
                  onFinish?.();
                })
                .catch((e) => {
                  const { code } = e?.data || {};
                  if (code === ACCOUNT_AUTH_NO_SELF && selectedPhoneIds.length === 1) {
                    DMConfirm({
                      title: I18N.t('授权关系中未包含您自身'),
                      content: I18N.t(
                        '您解除了自己本人与该手机的关系，这意味着一旦确定就无法再查看此手机，确定要继续吗',
                      ),
                      onOk() {
                        shopMobileGrantMobileToUsersPost({
                          cleanFirst,
                          teamId: getTeamIdFromUrl(),
                          userIdList: users,
                          departmentIdList: departments,
                          mobileIds: selectedPhoneIds,
                          confirm: true,
                        }).then(() => {
                          resolve();
                          onFinish?.();
                        });
                      },
                      onCancel() {
                        reject(e);
                      },
                    });
                  } else {
                    message.error(e.message);
                    reject(e);
                  }
                });
            });
          }}
        />,
        'MobileAuthModal',
      );
    },
    [],
  );

  const buildPhoneGroupSettingsModalProps = useCallback(
    (props: {
      mobileGroupVo: API.MobileGroupVo;
      originMobileIds: number[];
      reloadPhoneList?: () => void;
      reloadMenu?: () => void;
    }) => {
      const { mobileGroupVo, originMobileIds, reloadPhoneList, reloadMenu } = props;
      return {
        title: I18N.t('编辑手机分组'),
        initName: mobileGroupVo.name,
        initMobileIds: originMobileIds,
        onSubmit: (values: any) => {
          shopMobileGroupUpdatePost({
            id: mobileGroupVo.id!,
            ...values,
          }).then(async () => {
            if (!_.isEqual(values.mobileIds, originMobileIds)) {
              await shopMobileGroupRemoveMobilesPost({
                mobileIds: originMobileIds,
                groupId: mobileGroupVo.id!,
              });
              await shopMobileGroupAddMobilesPut({
                mobileIds: values.mobileIds,
                groupId: mobileGroupVo.id!,
              });
              reloadPhoneList?.();
            }
            reloadMenu?.();
          });
        },
      };
    },
    [],
  );

  const showRestartAdbServerConfirm = useCallback((deviceId: string) => {
    DMConfirm({
      title: I18N.t('确定要重新连接花漾客户端与手机吗？'),
      content: I18N.t(
        '该操作会重新启动所选花漾客户端的ADB服务，会影响该花漾客户端正在连接的所有手机，造成10秒左右的中断，常用于手机黑屏无法操作等异常场景',
      ),
      onOk: () => {
        return shopMobileRestartAdbServerPut({
          deviceId,
        }).then(() => {
          const modalRef = DMLoading({
            title: I18N.t('正在为您重新连接花漾客户端与手机'),
            content: I18N.t('此操作大概需要10秒左右的时间，请稍候...'),
            footerBtns: [
              {
                content: (
                  <CountdownBtn
                    type="primary"
                    availableAllTime
                    autoClick
                    seconds={10}
                    onClick={() => {
                      modalRef.destroy();
                    }}
                  >
                    {I18N.t('关闭')}
                  </CountdownBtn>
                ),
              },
            ],
          });
        });
      },
    });
  }, []);

  const getScreenInfo = useCallback((mobileCode: string): { w: number; h: number } => {
    const videoSettingsStr = localStorage.getItem(`screenInfo:${mobileCode}`);
    if (videoSettingsStr) {
      return JSON.parse(videoSettingsStr);
    }
    return {
      w: 640,
      h: 1280,
    };
  }, []);

  const popupPhone = useCallback(
    (mobileId?: number) => {
      if (!mobileId) {
        return;
      }
      shopMobileGetSimpleInfoGet({ id: mobileId! }).then((res) => {
        if (!res.data) {
          message.warn(I18N.t('手机已删除'));
          return;
        }
        if (res.data?.status === 'OFFLINE') {
          message.warn(I18N.t('手机已离线'));
          return;
        }
        const screenInfo = getScreenInfo(res.data?.code ?? '');
        const sizeScaleRatio = 640 / Math.max(screenInfo.h, screenInfo.w);
        sendAsync('popup-mobile', {
          phoneId: mobileId,
          url: getPortalUrl(`/team/${res.data.teamId}/phonePopup/${res.data.id}`),
          width: Math.floor(screenInfo.w * sizeScaleRatio) + 44 + 10 + 12 * 2,
          height:
            Math.floor(screenInfo.h * sizeScaleRatio) +
            30 +
            10 +
            window.navigator.windowControlsOverlay.getTitlebarAreaRect().height +
            86,
        });
      });
    },
    [getScreenInfo],
  );

  return {
    changePhoneAuth,
    buildPhoneGroupSettingsModalProps,
    showRestartAdbServerConfirm,
    getScreenInfo,
    popupPhone,
  };
};
