// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 激活一张代金券 POST /api/payment/activeAVoucher */
export async function paymentActiveAVoucherPost(
  body: API.ActiveVoucherRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultVoucherVo>('/api/payment/activeAVoucher', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取团队余额 GET /api/payment/balance */
export async function paymentBalanceGet(options?: { [key: string]: any }) {
  return request<API.WebResultbigdecimal>('/api/payment/balance', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查询团队收支明细列表 GET /api/payment/balanceInOutList */
export async function paymentBalanceInOutListGet(
  params: {
    // query
    /** pageNum */
    pageNum: number;
    /** pageSize */
    pageSize: number;
    /** orderType */
    orderType?:
      | 'BuyGiftCard'
      | 'BuyIp'
      | 'BuyPluginPack'
      | 'BuyRpaVoucher'
      | 'BuyTkPack'
      | 'BuyTraffic'
      | 'CashOut'
      | 'MakeupPriceDifference'
      | 'Merge'
      | 'OrderCancel'
      | 'PartnerBuyVoucher'
      | 'PartnerDraw'
      | 'PartnerRecharge'
      | 'PartnerRechargeCredit'
      | 'PartnerRenewRpaVoucher'
      | 'Present'
      | 'Recharge'
      | 'RechargeCredit'
      | 'Refund'
      | 'RenewIp'
      | 'RenewPluginPack'
      | 'RenewRpaVoucher'
      | 'RenewTkPack';
    /** payType */
    payType?: 'AliPay' | 'BalancePay' | 'BankPay' | 'WechatPay';
    /** from */
    from?: string;
    /** to */
    to?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultBalanceInOutDetailVo>('/api/payment/balanceInOutList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 计算购买花瓣价格 GET /api/payment/calcBuyCreditPrice */
export async function paymentCalcBuyCreditPriceGet(
  params: {
    // query
    /** 购买多少个 */
    count: number;
    /** distributionCode */
    distributionCode?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultCalcPriceResponse>('/api/payment/calcBuyCreditPrice', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 计算购买ip价格 GET /api/payment/calcBuyIpPrice */
export async function paymentCalcBuyIpPriceGet(
  params: {
    // query
    /** ip方案对应的商品id */
    goodsIds: number;
    /** 每个goods购买多少个，必须与goodsIds顺序一一对应 */
    counts: number;
    /** 购买多少个周期 */
    duration: number;
    /** 续费周期单位 */
    periodUnit:
      | 'Buyout'
      | 'Byte'
      | 'GB'
      | 'GB天'
      | '个'
      | '个天'
      | '分钟'
      | '周'
      | '天'
      | '年'
      | '张'
      | '无'
      | '月'
      | '次';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultCalcPriceResponse>('/api/payment/calcBuyIpPrice', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 取消一个未支付的订单 PUT /api/payment/cancel/order/${param0} */
export async function paymentCancelOrderByOrderIdPut(
  params: {
    // query
    /** reason */
    reason?: string;
    // path
    /** orderId */
    orderId: number;
  },
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/payment/cancel/order/${param0}`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 批量取消未支付的订单 PUT /api/payment/cancelOrders */
export async function paymentCancelOrdersPut(
  params: {
    // query
    /** reason */
    reason?: string;
    // path
    /** orderIds */
    orderIds: string;
  },
  options?: { [key: string]: any },
) {
  const { orderIds: param0, ...queryParams } = params;
  return request<API.WebResult>('/api/payment/cancelOrders', {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取公有云IP通用折扣信息 GET /api/payment/cloudIpDiscounts */
export async function paymentCloudIpDiscountsGet(options?: { [key: string]: any }) {
  return request<API.WebResultListDiscountsDto>('/api/payment/cloudIpDiscounts', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取团队账户某段时间支出情况 GET /api/payment/costSummary */
export async function paymentCostSummaryGet(
  params: {
    // query
    /** 开始日期 */
    from?: string;
    /** 结束日期 */
    to?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultCostSummaryVo>('/api/payment/costSummary', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取团队的各种代金券数量 GET /api/payment/countVouchers */
export async function paymentCountVouchersGet(options?: { [key: string]: any }) {
  return request<API.WebResultMapstringint>('/api/payment/countVouchers', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 创建购买花瓣订单 POST /api/payment/createBuyCreditOrder */
export async function paymentCreateBuyCreditOrderPost(
  body: API.CreateBuyCreditOrderRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultCreateOrderResponse>('/api/payment/createBuyCreditOrder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 创建充值订单 POST /api/payment/createRechargeOrder */
export async function paymentCreateRechargeOrderPost(
  body: API.CreateRechargeOrderRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultCreateOrderResponse>('/api/payment/createRechargeOrder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取团队花瓣 GET /api/payment/credit */
export async function paymentCreditGet(options?: { [key: string]: any }) {
  return request<API.WebResultbigdecimal>('/api/payment/credit', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 下载花瓣收支明细 GET /api/payment/creditExcel */
export async function paymentCreditExcelGet(
  params: {
    // query
    /** creditTypes */
    creditTypes?: string;
    /** from */
    from?: string;
    /** to */
    to?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.Resource>('/api/payment/creditExcel', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 下载花瓣收支明细 GET /api/payment/creditExcel/${param0} */
export async function paymentCreditExcelByTokenGet(
  params: {
    // path
    /** token */
    token: string;
  },
  options?: { [key: string]: any },
) {
  const { token: param0, ...queryParams } = params;
  return request<API.Resource>(`/api/payment/creditExcel/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 下载花瓣收支明细 GET /api/payment/creditExcelToken */
export async function paymentCreditExcelTokenGet(
  params: {
    // query
    /** creditTypes */
    creditTypes?: string;
    /** from */
    from?: string;
    /** to */
    to?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultstring>('/api/payment/creditExcelToken', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询团队花瓣明细列表 GET /api/payment/creditInOutList */
export async function paymentCreditInOutListGet(
  params: {
    // query
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    /** creditType */
    creditType?:
      | 'BuyCredit'
      | 'ConsumeCredit'
      | 'FingerprintQuota'
      | 'GiftCard'
      | 'InitPresent'
      | 'IpOverQuotaTraffic'
      | 'OrderRefund'
      | 'Present'
      | 'RpaCaptcha'
      | 'RpaExecuteQuota'
      | 'RpaMarketFlow'
      | 'RpaOpenAi'
      | 'RpaSendEmail'
      | 'RpaSendSms'
      | 'RpaSendWeChat'
      | 'ShopQuota'
      | 'ShopSecurityPolicy'
      | 'StorageQuota'
      | 'TeamMemberQuota'
      | 'TeamMobileQuota'
      | 'TransferIn'
      | 'TransferOut'
      | 'TransitTraffic';
    /** minCredit */
    minCredit?: number;
    /** serialNumber */
    serialNumber?: string;
    /** from */
    from?: string;
    /** to */
    to?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultCreditInOutDetailVo>('/api/payment/creditInOutList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取一些商品可用折扣情况 GET /api/payment/discounts */
export async function paymentDiscountsGet(
  params: {
    // query
    /** goodsIds */
    goodsIds: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultMaplongListDiscountsDto>('/api/payment/discounts', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询订单商品明细 GET /api/payment/findOrderResources */
export async function paymentFindOrderResourcesGet(
  params: {
    // query
    /** orderIds */
    orderIds: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultMaplongListOrderResourceVo>('/api/payment/findOrderResources', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取花瓣这一商品信息，主要是因为后边售卖都需要goodsId，通过该方法来获取花瓣goodsId GET /api/payment/getCreditGoods */
export async function paymentGetCreditGoodsGet(options?: { [key: string]: any }) {
  return request<API.WebResultGoodsDto>('/api/payment/getCreditGoods', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取rpa包时券商品信息，主要是因为后边售卖都需要goodsId，通过该方法来获取goodsId GET /api/payment/getRpaVoucherGoodsInfo */
export async function paymentGetRpaVoucherGoodsInfoGet(options?: { [key: string]: any }) {
  return request<API.WebResultGoodsDto>('/api/payment/getRpaVoucherGoodsInfo', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 锁定并支付一个订单（查看未支付订单详情界面点立即支付） POST /api/payment/lockAndPayOrder */
export async function paymentLockAndPayOrderPost(
  body: API.LockAndPayOrderRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultCreateOrderResponse>('/api/payment/lockAndPayOrder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 合并支付订单。已经锁定的订单不允许参与合并支付。合并支付点支付后会将所有的子订单锁定 POST /api/payment/mergePayOrders */
export async function paymentMergePayOrdersPost(
  body: API.MergePayOrdersRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultCreateOrderResponse>('/api/payment/mergePayOrders', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 客户银行转账后确认提交 POST /api/payment/order/${param0}/markBankTransferDone */
export async function paymentOrderByOrderIdMarkBankTransferDonePost(
  params: {
    // path
    /** orderId */
    orderId: number;
  },
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  return request<API.WebResultCreateOrderResponse>(
    `/api/payment/order/${param0}/markBankTransferDone`,
    {
      method: 'POST',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 用在订单列表界面点击未支付订单的【支付】按钮，重新获取支付方式 GET /api/payment/order/${param0}/paidInfoForPay */
export async function paymentOrderByOrderIdPaidInfoForPayGet(
  params: {
    // path
    /** orderId */
    orderId: number;
  },
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  return request<API.WebResultCreateOrderResponse>(`/api/payment/order/${param0}/paidInfoForPay`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 查询团队订单列表 GET /api/payment/orders */
export async function paymentOrdersGet(
  params: {
    // query
    /** pageNum */
    pageNum: number;
    /** pageSize */
    pageSize: number;
    /** id */
    id?: number;
    /** parentOrderId */
    parentOrderId?: number;
    /** serialNumber */
    serialNumber?: string;
    /** orderType */
    orderType?:
      | 'BuyGiftCard'
      | 'BuyIp'
      | 'BuyPluginPack'
      | 'BuyRpaVoucher'
      | 'BuyTkPack'
      | 'BuyTraffic'
      | 'CashOut'
      | 'MakeupPriceDifference'
      | 'Merge'
      | 'OrderCancel'
      | 'PartnerBuyVoucher'
      | 'PartnerDraw'
      | 'PartnerRecharge'
      | 'PartnerRechargeCredit'
      | 'PartnerRenewRpaVoucher'
      | 'Present'
      | 'Recharge'
      | 'RechargeCredit'
      | 'Refund'
      | 'RenewIp'
      | 'RenewPluginPack'
      | 'RenewRpaVoucher'
      | 'RenewTkPack';
    /** payStatus */
    payStatus?:
      | 'CANCELED'
      | 'Created'
      | 'Locked'
      | 'PAID'
      | 'PartialRefund'
      | 'REFUNDED'
      | 'WAIT_CONFIRM';
    /** from */
    from?: string;
    /** to */
    to?: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultOrderDetailVo>('/api/payment/orders', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询团队某个订单详情 GET /api/payment/orders/${param0}/detail */
export async function paymentOrdersByOrderIdDetailGet(
  params: {
    // path
    /** orderId */
    orderId: number;
  },
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  return request<API.WebResultOrderDetailVo>(`/api/payment/orders/${param0}/detail`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 重新计算订单的提成并返回订单数据（不存盘） GET /api/payment/orders/${param0}/recalculateDraw */
export async function paymentOrdersByOrderIdRecalculateDrawGet(
  params: {
    // path
    /** orderId */
    orderId: number;
  },
  options?: { [key: string]: any },
) {
  const { orderId: param0, ...queryParams } = params;
  return request<API.WebResultOrdersDto>(`/api/payment/orders/${param0}/recalculateDraw`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取团队账户总览 GET /api/payment/summary */
export async function paymentSummaryGet(options?: { [key: string]: any }) {
  return request<API.WebResultPaymentSummaryVo>('/api/payment/summary', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 更新一个订单支付信息（查看未支付订单详情界面继续点稍候支付） POST /api/payment/updateOrderPayment */
export async function paymentUpdateOrderPaymentPost(
  body: API.UpdateOrderPaymentRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultCreateOrderResponse>('/api/payment/updateOrderPayment', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 根据cardNumber获取代金券详情 GET /api/payment/voucher/card_number/${param0} */
export async function paymentVoucherCard_numberByCardNumberGet(
  params: {
    // path
    /** cardNumber */
    cardNumber: string;
  },
  options?: { [key: string]: any },
) {
  const { cardNumber: param0, ...queryParams } = params;
  return request<API.WebResultVoucherVo>(`/api/payment/voucher/card_number/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取团队的代金券 GET /api/payment/vouchers */
export async function paymentVouchersGet(
  params: {
    // query
    /** pageNum */
    pageNum: number;
    /** pageSize */
    pageSize: number;
    /** voucherStatus */
    voucherStatus: 'Available' | 'ExpireSoon' | 'Invalid' | 'Used';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultVoucherVo>('/api/payment/vouchers', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除代金券 DELETE /api/payment/vouchers */
export async function paymentVouchersDelete(
  params: {
    // query
    /** voucherIds */
    voucherIds: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultint>('/api/payment/vouchers', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
