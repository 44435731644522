import I18N from '@/i18n';
import React, { ReactNode } from 'react';
import { useRequest } from 'umi';

import { metaDwlFindDwlAuditByAuditIdGet } from '@/services/api-ShopAPI/DwlController';
import { CustomServiceRow } from '@/components/MessageCenter/components/MessageDetail';

type Props = {
  message: API.UserMessageVo;
};

/**
 * 域名加白消息
 * @param props
 * @constructor
 */
const DomainAuditDetail: React.FC<Props> = (props) => {
  const { message } = props;
  const { messageType, content, resourceId } = message;
  const { data } = useRequest(() => metaDwlFindDwlAuditByAuditIdGet({ auditId: resourceId! }));

  const regRes = content?.match(/^(.*“)(.+)(”.*)$/);
  let contentRow: ReactNode = content ?? '--';
  if (regRes) {
    const [_content, prefix, domain, suffix] = regRes;
    contentRow = (
      <span title={_content}>
        {prefix}
        <a>{domain}</a>
        {suffix}
      </span>
    );
  }
  if (messageType === 'Domain_White_Pass') {
    return (
      <>
        <p>{contentRow}</p>
        <p>
          {I18N.t('申请原因：')}
          {data?.reason ?? '--'}
        </p>
        <p>{I18N.t('审核结果：同意')}</p>
        <p>{I18N.t('网址白名单已生效，请重新打开花漾灵动即可访问申请的网站地址')}</p>
        <p>
          {I18N.t(
            '请注意：花漾提供的所有服务均遵循国家相关法律法规的规定，请确保不要用于任何违背国家相关法律法规的用途',
          )}
        </p>
      </>
    );
  }
  return (
    <>
      <p>{contentRow}</p>
      <p>
        {I18N.t('申请原因：')}
        {data?.reason ?? '--'}
      </p>
      <p>{I18N.t('审核结果：拒绝')}</p>
      <p>
        {I18N.t('拒绝原因：')}
        {data?.auditRemark ?? '--'}
      </p>
      <CustomServiceRow />
    </>
  );
};

export default DomainAuditDetail;
