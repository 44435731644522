import I18N from '@/i18n';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useModalCaller } from '@/mixins/modal';
import DMModal from '@/components/Common/Modal/DMModal';
import { Alert, Form, TimePicker } from 'antd';
import DMFormItem from '@/components/Common/DMFormItem';
import { buildAppVersion } from '@/utils/utils';
import moment from 'moment';
import { getAppVersion, sendAsync } from '@/utils/ElectronUtils';
import { metaAppVersionConfigsGet } from '@/services/api-MetaAPI/MetaController';
import { getVersionConfig } from '@/components/CustomService';
import { getSysPresSettings } from '@/i18n/I18N';

type Props = {
  appPlatformCode?: string;
  onClose: () => void;
  onFinish: () => void;
};

/**
 * 延迟自动安装
 * @param props
 * @constructor
 */
const DelayAutoInstallModal: React.FC<Props> = (props) => {
  const { appPlatformCode, onClose, onFinish } = props;
  const [remoteVersion, setRemoteVersion] = useState('');
  const [form] = Form.useForm();
  const localVersion = getAppVersion();

  useEffect(() => {
    metaAppVersionConfigsGet().then((versionConfigsData = {}) => {
      const config = getVersionConfig(versionConfigsData.data?.versionConfigs, appPlatformCode);
      setRemoteVersion(buildAppVersion(config?.appVersion, config?.earlyTryVersion));
    });
  }, [appPlatformCode]);

  const sysPresSettings = useMemo(() => getSysPresSettings(), []);
  const [startTimeStr, endTimeStr] = (sysPresSettings.autoInstallTimeRange ?? '02:00-05:59').split(
    '-',
  );

  return (
    <DMModal visible title={I18N.t('推迟自动升级')} onOk={form.submit} onCancel={onClose}>
      <Alert
        showIcon
        message={I18N.t(
          '花漾客户端有最新版本且已经为您下载完毕，强烈建议您立即升级，如果现在不合适升级，您也可以指定下次升级时间',
        )}
        style={{ marginBottom: 16 }}
      />

      <Form
        form={form}
        requiredMark={false}
        initialValues={{ range: [moment(startTimeStr, 'HH:mm'), moment(endTimeStr, 'HH:mm')] }}
        onFinish={(fields) => {
          const nextTime = moment()
            .add(fields.range[0].hours() <= moment().hours() ? 1 : 0, 'days')
            .set({ hours: fields.range[0].hours(), minutes: fields.range[0].minutes() })
            .startOf('seconds');
          const seconds = nextTime.diff(moment(), 'seconds');
          sendAsync('delay-auto-install', { seconds });
          onFinish();
          onClose();
        }}
      >
        <DMFormItem label={I18N.t('当前版本')}>{localVersion || '--'}</DMFormItem>
        <DMFormItem label={I18N.t('最新版本')}>{remoteVersion || '--'}</DMFormItem>
        <DMFormItem
          label={I18N.t('下次升级时间')}
          name="range"
          rules={[
            { required: true },
            {
              validator: (rule, value) => {
                const minutes = value[1].diff(value[0], 'minutes');
                if (minutes < 30) {
                  return Promise.reject(new Error(I18N.t('时间段跨度不能小于30分钟')));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <TimePicker.RangePicker showSecond={false} format="HH:mm" style={{ width: '100%' }} />
        </DMFormItem>
      </Form>
    </DMModal>
  );
};

export default DelayAutoInstallModal;

export function useDelayAutoInstallModal() {
  const modalCaller = useModalCaller(true);
  return useCallback(
    (props: Omit<Props, 'onClose'>) => {
      modalCaller({
        component: ({ close }) => <DelayAutoInstallModal {...props} onClose={close} />,
      });
    },
    [modalCaller],
  );
}
