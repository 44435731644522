import { message } from 'antd';
import uaParser from 'ua-parser-js';

declare global {
  interface Window {
    isElectronEnv: boolean;
    showCustomWindowTitleBar?: boolean;
    hideWindowTitleBarHelpBtn?: boolean;
    hideWindowTitleBarPinBtn?: boolean;
    ipcRenderer: {
      send: (channel: string, data: any) => void;
      sendSync: (channel: string, data: any) => any;
      invoke: (channel: string, data: any) => Promise<any>;
      on: (channel: string, func: (evt: IpcRendererEvent, data: any) => void) => () => void;
      removeListener: (channel: string, func: (evt: IpcRendererEvent, data: any) => void) => void;
      removeAllListeners: (channel: string) => void;
    };
  }
}

type IpcRendererEvent = {
  sender: { sendTo: (senderId: number) => void };
  senderId: number;
};
type EvtCallback = {
  (evt: IpcRendererEvent, data: any): void;
};
export type InstalledKernelInfo = { version: number; buildNumber: number; path: string };

const { ipcRenderer } = window;

const channel = 'asynchronous-message';
const listener: Record<string, { fun: EvtCallback; once: boolean }[]> = {};

// 检测是否是Electron环境
export function isElectron() {
  return window.isElectronEnv;
}

let appUUID = '';
let appBuildNumber = '1';
let appVersion = '';
let hostName = '';
let cpus: number;
let memory: number;
let appProxy: Record<string, any> = { type: 'direct' };
let installedKernelInfo: InstalledKernelInfo[] = [];

export function getAppUUID() {
  return appUUID;
}
export function getAppBuildNumber() {
  return appBuildNumber;
}
export function getAppVersion() {
  return appVersion;
}
export function getHostName() {
  return hostName;
}
export function getCpus() {
  return cpus;
}
export function getMemory() {
  return memory;
}
export function getAppProxy() {
  return appProxy;
}
export function setAppProxy(proxyConfig: Record<string, any>) {
  appProxy = proxyConfig;
}

export function getInstalledBrowserVersions() {
  return installedKernelInfo.map((info) => info.version);
}

export function getInstalledKernelInfo() {
  return installedKernelInfo;
}

export function getLatestInstalledBrowserVersion() {
  const v = getInstalledBrowserVersions()[0];
  if (!v) {
    const ua = uaParser(navigator.userAgent);
    if (ua.os.name?.includes('Windows') && Number(ua.os.version) < 10) {
      return 109;
    }
    return isElectron() ? 120 : 128;
  }
  return v;
}

/**
 * 发送异步消息
 * @param event
 * @param data
 */
export function emitEvent(event: string, data: any) {
  if (isElectron()) {
    ipcRenderer?.send(channel, {
      event,
      data,
    });
  } else {
    const error = new Error('请在花漾客户端中访问');
    console.error(error.message);
  }
}

/**
 * 添加异步消息监听器
 * @param eventName 事件名
 * @param callback
 * @param once
 */
export function addEventListener(eventName: string, callback: EvtCallback, once = false) {
  const arr = listener[eventName] || [];
  listener[eventName] = arr;
  arr.push({ fun: callback, once });
  if (!once) {
    return () => {
      if (!arr) return;
      const idx = arr.findIndex(({ fun }) => fun === callback);
      if (idx !== -1) {
        arr.splice(idx, 1);
      }
    };
  }
  return () => {};
}

/**
 * 发送异步消息，并返回主进程的结果
 * @param channelName
 * @param data
 * @param showError
 */
export function sendAsync(channelName: string, data = {}, showError = false) {
  if (isElectron()) {
    console.log('sendAsync', channelName);
    return ipcRenderer.invoke(channelName, data).catch((err) => {
      if (showError && err.message.includes('No handler registered'))
        message.error('客户端版本不支持该功能，请升级客户端');
      throw err;
    });
  }
  const error = new Error('请在花漾客户端中访问');
  return Promise.reject(error);
}

/**
 * 发送同步消息
 * @param channelName
 * @param data
 */
export function sendSync(channelName: string, data = {}) {
  console.log('sendSync', channelName);
  const res = ipcRenderer.sendSync(channelName, data);
  console.log('res', res);
  return res;
}

export function ipcRenderOn(channelName: string, channelListener: (evt: any, args: any) => void) {
  if (isElectron()) {
    ipcRenderer.on(channelName, channelListener);
    return () => {
      ipcRenderer.removeListener(channelName, channelListener);
    };
  }
  return () => {};
}

export function ipcRenderOff(channelName: string, channelListener: (evt: any, args: any) => void) {
  if (isElectron()) {
    ipcRenderer.removeListener(channelName, channelListener);
  }
}

export function removeAllListeners(channelName: string) {
  if (isElectron()) {
    ipcRenderer.removeAllListeners(channelName);
  }
}

if (isElectron()) {
  ipcRenderer.on(channel, (evt: any, args: any) => {
    if (!args) {
      return;
    }
    const { event, data } = args;
    const callbacks = listener[event] ?? [];
    if (callbacks.length > 0) {
      for (let i = callbacks.length - 1; i >= 0; i -= 1) {
        const callback = callbacks[i];
        callback.fun(evt, data);
        if (callback.once) {
          callbacks.splice(i, 1);
        }
      }
      if (callbacks.length === 0) {
        // 如果没有监听者了，取消监听
        delete listener[event];
      }
    }
  });
  ipcRenderer.on('reload-page', () => {
    window.location.reload();
  });
  // set app uuid
  sendAsync('get-app-uuid').then((uuid: string) => {
    appUUID = uuid;
  });
  sendAsync('get-app-build-number').then((number) => {
    appBuildNumber = number;
  });
  sendAsync('get-release-app-version').then((appReleaseVersion) => {
    appVersion = appReleaseVersion;
  });
  sendAsync('get-host-name').then((name: string) => {
    hostName = name;
  });
  sendAsync('get-cpus').then((cpu: number) => {
    cpus = cpu;
  });
  sendAsync('get-memory').then((_memory: number) => {
    memory = _memory;
  });
  sendAsync('get-app-proxy').then((_appProxy: any) => {
    appProxy = _appProxy;
  });
  addEventListener('installed-browser-versions-change', (evt, data) => {
    installedKernelInfo = data;
  });
  sendAsync('get-installed-browser-versions').then((versionsInfo: []) => {
    installedKernelInfo = versionsInfo;
  });
}

export default {
  isElectron,
  addEventListener,
  emitEvent,
};
