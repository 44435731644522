import classNames from 'classnames';
import type { CSSProperties, ReactNode } from 'react';

import styles from './index.less';
import React from 'react';

type ContextValue = {
  labelWidth?: number | string;
  labelMuted?: boolean;
};
export const LabelRowContext = React.createContext<ContextValue>({});

type Props = {
  /**
   * label
   * 可能没有左侧,起一个padding占位用
   */
  label: ReactNode;
  /**
   * 是否显示为浅色文本
   */
  labelMuted?: boolean;
  /**
   * value 也可以通过子节点的方式给 value 赋值
   */
  value?: ReactNode;
  /**
   * className
   */
  className?: string;
  /**
   * flex-direction
   */
  direction?: 'row' | 'column';
  /**
   * 水平排列时，label 的宽度
   */
  labelWidth?: string | number;
  /**
   * 底外边距
   */
  marginBottom?: number;
  /**
   * row-value style object
   */
  valueWrapStyle?: CSSProperties;
  children?: ReactNode;
  /**
   * 样式
   */
  style?: CSSProperties;
  /**
   * 冒号
   */
  colon?: boolean;
};

/**
 * 左侧文字,中间冒号,右边内容的一个小组件
 * 不再把冒号放进国际化里面了,不再区分中英文(当前使用的为英文冒号+间距)
 * 可以单独设置冒号的左右间距及颜色
 */
export default function LabelRow(props: Props) {
  const {
    label,
    labelMuted = true,
    colon = true,
    value,
    className,
    direction = 'row',
    children,
    labelWidth = 75,
    marginBottom = undefined,
    style,
    valueWrapStyle,
  } = props;
  const content = value || children;
  const pureTextContent = typeof content === 'string' || typeof content === 'number';
  const pureTextLabel = typeof label === 'string' || typeof label === 'number';
  const classNameTmp = classNames(styles.wrap, 'dm-label-row', className, {
    pureRow: pureTextContent && pureTextLabel,
  });
  return (
    <LabelRowContext.Consumer>
      {(value) => {
        const width = value.labelWidth || labelWidth;
        const muted = _.has(value, 'labelMuted') ? value.labelMuted : labelMuted;
        return (
          <div
            className={classNameTmp}
            style={{ flexDirection: direction, marginBottom, ...style }}
          >
            <div
              className={classNames('row-label', {
                placeholder: !label,
                pureText: pureTextLabel,
                muted,
                colon,
              })}
              style={{ width, minWidth: width }}
              title={pureTextLabel ? (label as string) : ''}
            >
              {label}
            </div>
            <div
              className={classNames('row-value', { pureText: pureTextContent })}
              title={pureTextContent ? (content as string) : ''}
              style={valueWrapStyle}
            >
              {content}
            </div>
          </div>
        );
      }}
    </LabelRowContext.Consumer>
  );
}
