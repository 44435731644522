import React, { useMemo } from 'react';
import { Link, useModel, useRouteMatch } from 'umi';
import classNames from 'classnames';
import routes from 'config/routes';
import { getUrlOfCurrentTeam } from '@/utils/utils';
import styles from './index.less';
import IconFontIcon from '@/components/Common/IconFontIcon';
import I18N from '@/i18n/I18N';
import useCurrentRole from '@/hooks/useCurrentRole';

interface Props {
  matchMenuKeys: string[];
}

const Menus: React.FC<Props> = (props) => {
  const { initialState, loading } = useModel('@@initialState');
  const routeMatch = useRouteMatch('/team/:teamId/:menu');
  const { currentTeam } = useModel('currentTeam');
  const { isSpecialRole } = useCurrentRole();

  const currentMenu = useMemo(() => {
    return routeMatch?.params?.menu ?? '';
  }, [routeMatch?.params?.menu]);

  const menus = useMemo(() => {
    const _menus: React.ReactNode[] = [];
    routes.forEach((o) => {
      if (o.key === 'team') {
        if (o.routes) {
          o.routes.forEach((o1) => {
            if (!o1.hideInMenu) {
              if ((o1.needSpecialRole && isSpecialRole) || !o1.needSpecialRole) {
                const active = o1.key === currentMenu;
                const path = getUrlOfCurrentTeam(o1.path, initialState?.teamId)
                  .replace(/:[^/]*/g, '')
                  .replace(/\/\//, '/');
                // 左侧菜单权限控制不控制菜单的显示隐藏，无权限时，显示无权限页面即可
                _menus.push(
                  <Link
                    key={o1.path}
                    className={classNames('menu-item', active ? 'active' : '')}
                    to={path}
                  >
                    <IconFontIcon iconName={o1.className} />
                    <span className="menu-item-name">{I18N.t(o1.name)}</span>
                  </Link>,
                );
              }
            }
          });
        }
        return false;
      }
      return true;
    });
    return _menus;
  }, [currentMenu, initialState?.teamId, isSpecialRole]);

  if (loading || !initialState || !currentTeam) {
    return null;
  }

  return <div className={styles.menus}>{menus}</div>;
};

export default Menus;
