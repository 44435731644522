// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 查询当前团队所有店铺的地区列表 GET /api/shop/areas */
export async function shopAreasGet(options?: { [key: string]: any }) {
  return request<API.WebResultListAreaVo>('/api/shop/areas', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 通过转移分身审批 PUT /api/shop/audit/${param0}/pass */
export async function shopAuditByAuditIdPassPut(
  params: {
    // path
    /** auditId */
    auditId: number;
  },
  options?: { [key: string]: any },
) {
  const { auditId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/audit/${param0}/pass`, {
    method: 'PUT',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 拒绝转移分身审批 PUT /api/shop/audit/${param0}/reject */
export async function shopAuditByAuditIdRejectPut(
  params: {
    // query
    /** reason */
    reason?: string;
    // path
    /** auditId */
    auditId: number;
  },
  options?: { [key: string]: any },
) {
  const { auditId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/audit/${param0}/reject`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 批量导入分身 POST /api/shop/batch */
export async function shopBatchPost(
  body: API.CreateShopsRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultListShopDto>('/api/shop/batch', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量解绑分身指纹实例或指纹模版 PUT /api/shop/bindFingerprintOrTemplate */
export async function shopBindFingerprintOrTemplatePut(
  body: API.BindFingerprintOrTemplateRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/bindFingerprintOrTemplate', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 绑定IP池 PUT /api/shop/bindIpp */
export async function shopBindIppPut(
  body: API.IppBindShopParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/bindIpp', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 按视图类型（或视图ID）查询 GET /api/shop/byCategory/${param0} */
export async function shopByCategoryByCategoryGet(
  params: {
    // query
    /** 视图ID(category=custom时指定) */
    viewId?: number;
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    /** 查询字符串（名称或IP） */
    query?: string;
    /** 平台类型(Amazon|eBay|...)，用逗号连接 */
    platformTypes?: string;
    /** 地区Area(Global|United_States|Canada|...，用逗号连接 */
    areas?: string;
    /** 按标签id过滤 */
    tagIds?: string;
    /** 标签的逻辑条件，支持OR|AND */
    tagLc?: 'AND' | 'NOT' | 'OR';
    /** props */
    props?: string;
    /** 排序字段 */
    sortFiled?: string;
    /** 顺序 */
    sortOrder?: 'asc' | 'desc';
    // path
    /** 视图分类 */
    category:
      | 'Shop'
      | 'SocialMedia'
      | 'all'
      | 'collected'
      | 'custom'
      | 'recycleBin'
      | 'unbound'
      | 'unfingerprinted'
      | 'ungranted'
      | 'windowSync';
  },
  options?: { [key: string]: any },
) {
  const { category: param0, ...queryParams } = params;
  return request<API.WebResultPageResultShopDetailVo>(`/api/shop/byCategory/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 按部门查询授权的店铺 GET /api/shop/byDepartment/${param0} */
export async function shopByDepartmentByDepartmentIdGet(
  params: {
    // path
    /** departmentId */
    departmentId: number;
  },
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.WebResultListShopDetailVo>(`/api/shop/byDepartment/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 按各种查询条件过滤分身列表 GET /api/shop/byTeam/${param0} */
export async function shopByTeamByTeamIdGet(
  params: {
    // query
    /** 查询字符串（名称或IP） */
    query?: string;
    /** 平台名称(Amazon|eBay) */
    platform?: string;
    /** IP */
    ip?: string;
    /** 标签的逻辑条件，支持OR|AND */
    tagLc?: 'AND' | 'NOT' | 'OR';
    /** 授权成员ID */
    grantedUserId?: number;
    /** 全部分身 */
    all?: boolean;
    /** 过滤未绑定IP的 */
    ipUnbound?: boolean;
    /** 过滤未授权的 */
    unGranted?: boolean;
    /** 过滤未设置指纹的 */
    unFingerprinted?: boolean;
    /** 过滤我收藏的 */
    collected?: boolean;
    /** 过滤访问中的 */
    opened?: boolean;
    /** 按标签id过滤 */
    tagIds?: string;
    /** lightly */
    lightly?: boolean;
    /** props */
    props?: string;
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    // path
    /** 团队ID */
    teamId: number;
  },
  options?: { [key: string]: any },
) {
  const { teamId: param0, ...queryParams } = params;
  return request<API.WebResultPageResultShopTagVo>(`/api/shop/byTeam/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 查询某个用户被授权的分身列表 GET /api/shop/byUser/${param0} */
export async function shopByUserByUserIdGet(
  params: {
    // query
    /** teamId */
    teamId: number;
    /** pageNum */
    pageNum: number;
    /** pageSize */
    pageSize: number;
    // path
    /** userId */
    userId: number;
  },
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.WebResultPageResultShopGrantVo>(`/api/shop/byUser/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 判断名称是否存在 GET /api/shop/checkNameExists */
export async function shopCheckNameExistsGet(
  params: {
    // query
    /** name */
    name: string;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultboolean>('/api/shop/checkNameExists', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 清空回收站 DELETE /api/shop/cleanDeletings */
export async function shopCleanDeletingsDelete(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/shop/cleanDeletings', {
    method: 'DELETE',
    ...(options || {}),
  });
}

/** 将某个分身的Cookie等数据迁移/合并至当前团队的另外一个分身
 POST /api/shop/copyShopProps */
export async function shopCopyShopPropsPost(
  body: API.CopyShopPropsRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/copyShopProps', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询回收站的分身数量 GET /api/shop/countDeletings */
export async function shopCountDeletingsGet(options?: { [key: string]: any }) {
  return request<API.WebResultint>('/api/shop/countDeletings', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 统计某个团队分身个数 GET /api/shop/countTeamShops */
export async function shopCountTeamShopsGet(options?: { [key: string]: any }) {
  return request<API.WebResultint>('/api/shop/countTeamShops', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 删除分身 POST /api/shop/delete */
export async function shopDeletePost(
  params: {
    // query
    /** 是否加入回收站 */
    recycled?: boolean;
    /** deleteFingerprint */
    deleteFingerprint?: boolean;
  },
  body: API.CommonIdsRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 下载分身信息 GET /api/shop/download/${param0} */
export async function shopDownloadByTokenGet(
  params: {
    // path
    /** token */
    token: string;
  },
  options?: { [key: string]: any },
) {
  const { token: param0, ...queryParams } = params;
  return request<API.Resource>(`/api/shop/download/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 下载分身的token POST /api/shop/exportToken */
export async function shopExportTokenPost(
  body: API.ExportShopRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultstring>('/api/shop/exportToken', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 全局字段配置 GET /api/shop/fieldConfig */
export async function shopFieldConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultTeamShopFieldConfig>('/api/shop/fieldConfig', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 设置全局字段配置 PUT /api/shop/fieldConfig */
export async function shopFieldConfigPut(
  body: API.TeamShopFieldConfig,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/fieldConfig', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 授权给用户 POST /api/shop/grantToUsers */
export async function shopGrantToUsersPost(
  body: API.ShopGrant2UsersParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/grantToUsers', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询已经授权分身的所有用户 GET /api/shop/grantedUsers */
export async function shopGrantedUsersGet(options?: { [key: string]: any }) {
  return request<API.WebResultListMemberVo>('/api/shop/grantedUsers', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 导入流量计划 POST /api/shop/importTrafficSpec */
export async function shopImportTrafficSpecPost(
  body: API.ImportTrafficSpecRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/importTrafficSpec', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量导入分身（最终版） 如果导入分身失败，导入成功的IP也会回滚 POST /api/shop/importV2 */
export async function shopImportV2Post(
  params: {
    // query
    /** clientPlatform */
    clientPlatform?: string;
  },
  body: Record<string, any>[],
  options?: { [key: string]: any },
) {
  return request<API.WebResultTaskDto>('/api/shop/importV2', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量修改是否允许内网访问 PUT /api/shop/intranetEnabled */
export async function shopIntranetEnabledPut(
  body: API.ShopIntranetEnableParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/intranetEnabled', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量（全量）设置本地代理 PUT /api/shop/lanProxy */
export async function shopLanProxyPut(
  body: API.BatchUpdateShopLanProxyVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResultListShopLanProxyDto>('/api/shop/lanProxy', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询回收站的分身 GET /api/shop/listDeletings */
export async function shopListDeletingsGet(
  params: {
    // query
    /** 平台类型(Amazon|eBay|...)，用逗号连接 */
    platformTypes?: string;
    /** 地区Area(Global|United_States|Canada|...，用逗号连接 */
    areas?: string;
    /** pageNum */
    pageNum?: number;
    /** pageSize */
    pageSize?: number;
    /** 名称 */
    name?: string;
    /** 排序字段 */
    sortFiled?: string;
    /** 顺序 */
    sortOrder?: 'asc' | 'desc';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultPageResultShopDetailVo>('/api/shop/listDeletings', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询分身详情 POST /api/shop/listProps */
export async function shopListPropsPost(
  params: {
    // query
    /** props */
    props?: string;
  },
  body: API.CommonIdsRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultListShopDetailVo>('/api/shop/listProps', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 解析adspower分身文件xlsx POST /api/shop/parseExcel/adspower */
export async function shopParseExcelAdspowerPost(
  body: {
    /** file */
    file?: string;
  },
  files?: File[],
  options?: { [key: string]: any },
) {
  const formData = new FormData();
  if (files) {
    formData.append('file', files[0] || '');
  }
  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      formData.append(ele, typeof item === 'object' ? JSON.stringify(item) : item);
    }
  });

  return request<API.WebResultListExcelAccountWithIpVo>('/api/shop/parseExcel/adspower', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    ...(options || {}),
  });
}

/** 解析Huayoung分身文件xlsx POST /api/shop/parseExcel/huayoung */
export async function shopParseExcelHuayoungPost(
  body: {
    /** file */
    file?: string;
  },
  files?: File[],
  options?: { [key: string]: any },
) {
  const formData = new FormData();
  if (files) {
    formData.append('file', files[0] || '');
  }
  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      formData.append(ele, typeof item === 'object' ? JSON.stringify(item) : item);
    }
  });

  return request<API.WebResultListExcelAccountWithIpVo>('/api/shop/parseExcel/huayoung', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    ...(options || {}),
  });
}

/** 批量更新平台 PUT /api/shop/platform */
export async function shopPlatformPut(
  body: API.UpdateShopPlatformRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/platform', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量转让分身准备 GET /api/shop/prepareBatchTransferShop */
export async function shopPrepareBatchTransferShopGet(options?: { [key: string]: any }) {
  return request<API.WebResultBatchTransferShopPrepareResult>(
    '/api/shop/prepareBatchTransferShop',
    {
      method: 'GET',
      ...(options || {}),
    },
  );
}

/** 批量给分身重新生成指纹实例/指纹模板。会静默跳过联营过来的分身 PUT /api/shop/reGenFingerprint */
export async function shopReGenFingerprintPut(
  body: API.BatchReGenFingerRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/reGenFingerprint', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 恢复分身 PUT /api/shop/recovery */
export async function shopRecoveryPut(
  body: API.CommonIdsRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRecoveryShopResult>('/api/shop/recovery', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询恢复店铺需要支付的花瓣 POST /api/shop/recoveryResult */
export async function shopRecoveryResultPost(
  body: API.CommonIdsRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResultRecoveryShopResult>('/api/shop/recoveryResult', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 从某个分身移除一些插件 DELETE /api/shop/removeAllTeamExtensions */
export async function shopRemoveAllTeamExtensionsDelete(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/shop/removeAllTeamExtensions', {
    method: 'DELETE',
    ...(options || {}),
  });
}

/** 从某个分身移除一些插件 DELETE /api/shop/removeTeamExtensions */
export async function shopRemoveTeamExtensionsDelete(
  params: {
    // query
    /** extensionIds */
    extensionIds: number;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/removeTeamExtensions', {
    method: 'DELETE',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修复版本过低的指纹。会静默跳过联营过来的分身 POST /api/shop/repairShopFingerprint */
export async function shopRepairShopFingerprintPost(
  body: API.RepairShopFingerprintRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/repairShopFingerprint', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取团队的插件列表 GET /api/shop/teamExtensions */
export async function shopTeamExtensionsGet(options?: { [key: string]: any }) {
  return request<API.WebResultListExtensionsVo>('/api/shop/teamExtensions', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 批量修改是否开启流量节省计划 PUT /api/shop/trafficSaving */
export async function shopTrafficSavingPut(
  body: API.ShopTrafficSavingParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/trafficSaving', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量转让分身 POST /api/shop/transferBatch */
export async function shopTransferBatchPost(
  body: API.BatchTransferShopRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/transferBatch', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量转让分身 POST /api/shop/transferBatchV2 */
export async function shopTransferBatchV2Post(
  body: API.BatchTransferShopRequestV2,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/transferBatchV2', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量解绑分身IP、IP池、临时IP PUT /api/shop/unbindAll */
export async function shopUnbindAllPut(
  body: API.UnbindShopAllRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/unbindAll', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量解绑分身指纹实例或指纹模版 PUT /api/shop/unbindFingerprint */
export async function shopUnbindFingerprintPut(
  body: API.ShopBatchRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/unbindFingerprint', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量解绑分身IP PUT /api/shop/unbindIp */
export async function shopUnbindIpPut(
  body: API.UnBindShopListParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/unbindIp', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 解绑IP池 PUT /api/shop/unbindIpp */
export async function shopUnbindIppPut(
  body: API.IppBindShopParamVo,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/unbindIpp', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量给分身重新生成指纹实例/指纹模板。会静默跳过联营过来的分身 POST /api/shop/updateShopFingerprint */
export async function shopUpdateShopFingerprintPost(
  body: API.BatchUpdateShopFingerprintRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/updateShopFingerprint', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 分身视图分类 GET /api/shop/viewCategories */
export async function shopViewCategoriesGet(options?: { [key: string]: any }) {
  return request<API.WebResultListShopViewCategoryVo>('/api/shop/viewCategories', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 视图配置 GET /api/shop/viewConfig */
export async function shopViewConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultTeamViewConfig>('/api/shop/viewConfig', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 设置视图 PUT /api/shop/viewConfig */
export async function shopViewConfigPut(
  body: API.TeamViewConfig,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/shop/viewConfig', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户分身视图列表 GET /api/shop/views */
export async function shopViewsGet(options?: { [key: string]: any }) {
  return request<API.WebResultListGlobalViewVo>('/api/shop/views', {
    method: 'GET',
    ...(options || {}),
  });
}

/** getShopPlatform GET /api/shop/${param0}/platform */
export async function shopByIdPlatformGet(
  params: {
    // path
    /** id */
    id: number;
  },
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.WebResultstring>(`/api/shop/${param0}/platform`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 查询分身详情 GET /api/shop/${param0} */
export async function shopByShopIdGet(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultShopDetailVo>(`/api/shop/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 查询分身概要信息 GET /api/shop/${param0}/brief */
export async function shopByShopIdBriefGet(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultShopBriefVo>(`/api/shop/${param0}/brief`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 取消转让分身 POST /api/shop/${param0}/cancelTransfer */
export async function shopByShopIdCancelTransferPost(
  params: {
    // path
    /** 分身ID */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/${param0}/cancelTransfer`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** getShopChannels GET /api/shop/${param0}/channels */
export async function shopByShopIdChannelsGet(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultListShopChannelVo>(`/api/shop/${param0}/channels`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 更新动态IP策略 PUT /api/shop/${param0}/dynamicStrategy */
export async function shopByShopIdDynamicStrategyPut(
  params: {
    // query
    /** ipId */
    ipId?: number;
    /** ippId */
    ippId?: number;
    /** dynamicStrategy */
    dynamicStrategy: 'Off' | 'Remain' | 'SwitchOnSession';
    /** locationId */
    locationId?: number;
    /** locationLevel */
    locationLevel?: 'City' | 'Continent' | 'Country' | 'District' | 'None' | 'Province' | 'Unknown';
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/${param0}/dynamicStrategy`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 导出流量计划 GET /api/shop/${param0}/exportTrafficSpec */
export async function shopByShopIdExportTrafficSpecGet(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultRouterSpecVo>(`/api/shop/${param0}/exportTrafficSpec`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取分身的插件策略 GET /api/shop/${param0}/extensionPolicy */
export async function shopByShopIdExtensionPolicyGet(
  params: {
    // query
    /** operateSharing */
    operateSharing?: boolean;
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultExtensionPolicyVo>(`/api/shop/${param0}/extensionPolicy`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取分身的插件列表 GET /api/shop/${param0}/extensions */
export async function shopByShopIdExtensionsGet(
  params: {
    // query
    /** operateSharing */
    operateSharing?: boolean;
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultListExtensionsVo>(`/api/shop/${param0}/extensions`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取分身绑定过的ip历史记录 GET /api/shop/${param0}/findShopBindIpsHistory */
export async function shopByShopIdFindShopBindIpsHistoryGet(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultListShopIpBindHistoryVo>(
    `/api/shop/${param0}/findShopBindIpsHistory`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 查询授权给分身的用户列表 GET /api/shop/${param0}/grantedUsers */
export async function shopByShopIdGrantedUsersGet(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultListMemberVo>(`/api/shop/${param0}/grantedUsers`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 设置本地代理 PUT /api/shop/${param0}/lanProxy */
export async function shopByShopIdLanProxyPut(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  body: API.UpdateShopLanProxyVo,
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultShopLanProxyDto>(`/api/shop/${param0}/lanProxy`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 获取店铺的运维记录 GET /api/shop/${param0}/opsList */
export async function shopByShopIdOpsListGet(
  params: {
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultListShopOpsDto>(`/api/shop/${param0}/opsList`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 转让分身准备 GET /api/shop/${param0}/prepareTransfer */
export async function shopByShopIdPrepareTransferGet(
  params: {
    // query
    /** 是否转移IP */
    transferIp: boolean;
    // path
    /** 分身ID */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResultTransferShopPrepareResult>(`/api/shop/${param0}/prepareTransfer`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 从某个分身移除一些插件 DELETE /api/shop/${param0}/removeExtensions */
export async function shopByShopIdRemoveExtensionsDelete(
  params: {
    // query
    /** extensionIds */
    extensionIds: number;
    /** operateSharing */
    operateSharing?: boolean;
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/${param0}/removeExtensions`, {
    method: 'DELETE',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 转让分身 POST /api/shop/${param0}/transfer */
export async function shopByShopIdTransferPost(
  params: {
    // query
    /** 受让团队 */
    targetTeamId: number;
    /** 受让联系人 */
    targetUserId: number;
    /** 是否转移IP */
    transferIp: boolean;
    /** 是否转让指纹 */
    transferFingerprint: boolean;
    // path
    /** 分身ID */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/${param0}/transfer`, {
    method: 'POST',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 更新分身识别码 PUT /api/shop/${param0}/updateShopMarkCode */
export async function shopByShopIdUpdateShopMarkCodePut(
  params: {
    // query
    /** markCode */
    markCode: string;
    /** markCodeBg */
    markCodeBg: number;
    // path
    /** shopId */
    shopId: number;
  },
  options?: { [key: string]: any },
) {
  const { shopId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/shop/${param0}/updateShopMarkCode`, {
    method: 'PUT',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}
