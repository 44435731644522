//@i18n-ignore
export default {
  ShopCategoryKeys: {
    鞋服箱包: 'xiefuxiangbao',
    母婴玩具: 'muyingwanju',
    护肤美妆: 'hufumeizhuang',
    电脑平板: 'diannaopingban',
    手机数码: 'shoujishuma',
    家电电器: 'jiadiandianqi',
    汽车配件: 'qichepeijian',
    工业用品: 'gongyeyongpin',
    艺术珠宝: 'yishuzhubao',
    手表眼镜: 'shoubiaoyanjing',
    户外运动: 'huwaiyundong',
    花园聚会: 'huayuanjuhui',
    生活家居: 'shenghuojiaju',
    家具建材: 'jiajujiancai',
    宠物用品: 'chongwuyongpin',
    食品生鲜: 'shipinshengxian',
    鲜花绿植: 'xianhualvzhi',
    医药保健: 'yiyaobaojian',
    计生情趣: 'jishengqingqu',
    图书文具: 'tushuwenju',
    音乐影视: 'yinyueyingshi',
    软件程序: 'ruanjianchengxu',
    电商其他: 'qita',
  },
  ShopCategories: [
    '鞋服箱包',
    '母婴玩具',
    '护肤美妆',
    '电脑平板',
    '手机数码',
    '家电电器',
    '汽车配件',
    '工业用品',
    '艺术珠宝',
    '手表眼镜',
    '户外运动',
    '花园聚会',
    '生活家居',
    '家具建材',
    '宠物用品',
    '食品生鲜',
    '鲜花绿植',
    '医药保健',
    '计生情趣',
    '图书文具',
    '音乐影视',
    '软件程序',
    '电商其他',
  ],
};
