import _ from 'lodash';

import * as AjaxEventController from '@/services/api-OtherAPI/AjaxEventController';
import random from './fingerprint/random';
import {SkipErrorNotifyOption} from '@/utils/utils';
import {portalRpcClient} from "@/websocket/portalRpcClient";

interface Listener {
  hd: string;
  once?: boolean;
  name: string;
  resId?: string | number;
  resIds?: string[] | number[];
  fun: (data: any, resId: string) => void;
}

/**
 * 事件去重白名单
 */
const skipUniqEvents = ['session-monitor-signaling-watcher', 'msg-center-new-message'];

/**
 * Ajax 事件轮询
 */
export class AjaxEventClient {
  listeners: Listener[];
  queryTimer: any = 0;

  pageId: string = random.nextString(6);
  lastQueryTime = 0;

  queryInterval = 2000;

  _handleAjaxPush: any;
  _onWsReady: any;

  constructor() {
    this.listeners = [];
    this._query();
    this._handleAjaxPush = this.handleAjaxPush.bind(this);
    this._onWsReady = this.onWsReady.bind(this);
    portalRpcClient.addListener('on-ajax-push', this._handleAjaxPush);
    portalRpcClient.transport.addListener('connected', this._onWsReady);
  }

  /**
   * 监听resId相关的一个事件
   * @param name
   * @param resId
   * @param fun
   * @param once 是否只监听一次，默认false
   * @return listenerId 一个字符串，取消监听要用到
   */
  async on(name: string, resId: any, fun: (evt: any) => void, once: boolean = false) {
    const hd = random.nextString(12);
    if (_.findIndex(this.listeners, (lsn) => lsn.name === name && lsn.resId === resId) === -1) {
      // 同一个事件同一个资源不重复发请求
      AjaxEventController.ajaxEventListenerPut({
        resId,
        eventName: name,
        pageId: this.pageId,
      }, { ws: true });
    }
    const listener: Listener = { hd, once, fun, resId, name };
    this.listeners.push(listener);
    return hd;
  }

  async tryReListenerEvents() {
    if (this.listeners.length > 0) {
      console.warn('事件监听已经丢失，尝试重新监听');
      for (let i = 0; i < this.listeners.length; i++) {
        let listener = this.listeners[i];
        AjaxEventController.ajaxEventListenerPut({
          resId: String(listener.resId),
          eventName: listener.name,
          pageId: this.pageId,
        }, { ws: true });
      }
    }
  }

  async batchOn(
    name: string,
    resIds: string[] | number[],
    fun: (data: any, resId: string) => void,
  ) {
    const resIdsStr = resIds.join(',');
    AjaxEventController.ajaxEventBatchListenerPut({
      resIds: resIdsStr,
      eventName: name,
      pageId: this.pageId,
    }, { ws: true });
    const hd = random.nextString(12);
    const listener: Listener = { hd, fun, resIds, name };
    this.listeners.push(listener);
    return hd;
  }

  /**
   * 一次性监听resId相关的一个事件
   * @param name
   * @param resId
   * @param fun
   * @see #on
   * @return 一个字符串，取消监听要用到
   */
  async once(name: string, resId: any, fun: (evt: any) => void) {
    return await this.on(name, resId, fun, true);
  }

  /**
   * 取消监听一个事件
   * @param hd on 或 once 返回的handler id
   */
  async un(hd: string) {
    for (let i = 0; i < this.listeners.length; i++) {
      const listener = this.listeners[i];
      if (listener.hd === hd) {
        this.listeners.splice(i, 1);
        if (
          _.findIndex(this.listeners, (lsn) => {
            let isCurrentRes = false;
            if (listener.resIds && lsn.resIds) {
              isCurrentRes = listener.resIds.join(',') === lsn.resIds.join(',');
            } else {
              isCurrentRes = lsn.resId === listener.resId;
            }
            return lsn.name === listener.name && isCurrentRes;
          }) === -1
        ) {
          if (listener.resIds) {
            this._batchUnRequest(listener.name, listener.resIds.join(','));
          } else {
            this._unRequest(listener.name, listener.resId);
          }
        }
        break;
      }
    }
  }

  async close() {
    clearInterval(this.queryTimer);
    _.forEach(this.listeners, (listener) => {
      this._unRequest(listener.name, listener.resId);
    });
    this.listeners = [];
  }

  async _query() {
    this.queryTimer = setTimeout(async () => {
      if (this.listeners.length > 0) {
        try {
          let needReListener = false;
          const now = Date.now();
          if (now - this.lastQueryTime < 500) return;
          this.lastQueryTime = now;
          const res = await AjaxEventController.ajaxEventQueryGet(
            { pageId: this.pageId },
            { ...SkipErrorNotifyOption, ws: true },
          ).catch((e) => {
            if (e.message === 'PageIdNotExist') {
              needReListener = true;
            } else {
              console.error(e);
            }
          });
          if (needReListener) {
            this.tryReListenerEvents();
          } else if (res?.data) {
            const { data } = res;
            if(data && data.length > 0) {
              this.queryInterval = 2000;
              await this._handleEvents(data);
            }
          }
        } catch (e) {
          console.error(e);
        } finally {
          this._query();
        }
      } else {
        this._query();
      }
    }, this.queryInterval);
  }

  async _handleEvents(data: any) {
    const dataTmp = _.uniqWith(data, (a: any, b) => {
      if (skipUniqEvents.includes(a.name!)) {
        return false;
      }
      return a.name === b.name && a.resId === b.resId;
    });
    _.forEach(dataTmp, (evt) => {
      for (let i = 0; i < this.listeners.length; i++) {
        const listener = this.listeners[i];
        const isCurrentResId = listener.resIds
          ? _.some(listener.resIds, (id) => String(id) === String(evt.resId))
          : String(listener.resId) === String(evt.resId);
        if (listener.name === evt.name && isCurrentResId) {
          // const obj: any = { resId: evt.resId };
          // if (_.isPlainObject(evt.data)) {
          //   Object.assign(obj, { ...evt.data });
          // } else {
          //   obj.data = evt.data;
          // }
          this._invoke(listener, evt.data, evt.resId!);
          if (listener.once) {
            this.listeners.splice(i, 1);
            i--;
          }
        }
      }
    });
  }

  handleAjaxPush(events: any[]) {
    if(events && events.length > 0) {
      this.queryInterval = 30000;
      this._handleEvents(events);
    }
  }

  async onWsReady() {
    setTimeout(async ()=>{
      await AjaxEventController.ajaxEventWs_readyPut(
        { pageId: this.pageId },
        { skipErrorHandler: true, ws: true },
      );
    }, 1000);
  }

  async _unRequest(name: string, resId?: string | number) {
    if (typeof resId === 'undefined') return;
    await AjaxEventController.ajaxEventUnListenerPut(
      { eventName: name, resId: String(resId), pageId: this.pageId },
      { skipErrorHandler: true, ws: true },
    );
  }

  async _batchUnRequest(name: string, resIds?: string) {
    if (typeof resIds === 'undefined') return;
    await AjaxEventController.ajaxEventBatchUnListenerPut(
      { eventName: name, resIds, pageId: this.pageId },
      { skipErrorHandler: true, ws: true },
    );
  }

  async _invoke(listener: Listener, data: any, resId: string) {
    listener.fun.call(null, data, resId);
  }
}

const ajaxEventClient = new AjaxEventClient();
export default ajaxEventClient;
