import { useEffect, useState } from 'react';
import EventEmitter from 'events';
import { useMount } from '@umijs/hooks';
import { accountDeviceCurrentGet } from '@/services/api-Account/LoginDeviceController';
import { SkipErrorNotifyOption } from '@/utils/utils';

const emitter = new EventEmitter();
emitter.setMaxListeners(9999);
const UPDATE_EVENT = Symbol('update');

let currentDevice: API.LoginDeviceDto;
let job: any;

export function setCurrentDevice(data: API.LoginDeviceDto) {
  currentDevice = data;
  emitter.emit(UPDATE_EVENT, data);
}

/**
 * 当前用户在当前团队的角色
 * 切换时机，团队切换时
 */
export default function useCurrentDevice() {
  const [device, setDevice] = useState<API.LoginDeviceDto>(currentDevice);
  useMount(() => {
    if (!job) {
      job = accountDeviceCurrentGet(SkipErrorNotifyOption)
        .then((res) => {
          setCurrentDevice(res.data!);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  });

  useEffect(() => {
    emitter.addListener(UPDATE_EVENT, setDevice);
    return () => {
      emitter.removeListener(UPDATE_EVENT, setDevice);
    };
  }, []);

  return device;
}
