import React from 'react';
import type { SwitchProps } from 'antd';
import { Switch } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';

const StyledSwitch = styled(Switch)`
  height: 20px !important;
  font-size: 13px;
  line-height: 20px !important;
  border-radius: 2px !important;
  &.emergency {
    &.ant-switch-checked {
      background-color: #52c41a;
    }
    background-color: #ff4d4f;
  }
  .ant-switch-handle {
    top: 2px !important;
    bottom: 2px !important;
    width: 18px !important;
    height: auto !important;
    &::before {
      border-radius: 2px !important;
    }
  }
  .ant-switch-inner {
    font-size: inherit;
  }
`;

const DmSwitch: React.FC<SwitchProps & { theme?: 'primary' | 'emergency' }> = (props) => {
  const { className, theme = 'primary', ...otherProps } = props;
  return (
    <StyledSwitch
      unCheckedChildren={'OFF'}
      checkedChildren={'ON'}
      className={classNames('dm-switch', className, theme)}
      {...otherProps}
    />
  );
};
export default DmSwitch;
