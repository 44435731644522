import React from 'react';
import type { HTMLProps, CSSProperties } from 'react';
import '@/assets/fonts/iconfont';
import '@/assets/fonts/iconfont.hack';
import classNames from 'classnames';

interface Props {
  className: string;
  // 图标尺寸，优先级比 style 的 width/height 要高
  size?: number | string;
  disabled?: boolean;
  style?: CSSProperties;
}

const ColoursIcon: React.FC<Props & HTMLProps<HTMLDivElement>> = (props) => {
  const { size, disabled = false } = props;
  const style = {
    ...props.style,
    ...(size ? { width: size, height: size } : null),
  };
  return (
    <svg
      className={classNames('dm-colours-icon', { 'dm-colours-icon__disabled': disabled })}
      aria-hidden="true"
      style={style}
    >
      <use xlinkHref={`#icon-${props.className}`} />
    </svg>
  );
};

export default ColoursIcon;
