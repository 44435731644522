import I18N from '@/i18n';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import styles from '../userSetting.less';
import DMFormItem, { DMFormItemContext } from '@/components/Common/DMFormItem';
import { Form, Radio, Space } from 'antd';
import type { FormBaseProps } from '@/components/UserCenter/UserSetting';

type FormData = {
  volume: 'muted' | 'louder';
};
const STORAGE_KEY = 'notify-volume';
export function isNotifyMuted() {
  return localStorage.getItem(STORAGE_KEY) === 'muted';
}
/**
 * 消息订阅
 * @constructor
 */
const MsgSubscribeForm = forwardRef((props: FormBaseProps, ref) => {
  const { editing } = props;
  const [muted, setMuted] = useState(isNotifyMuted());
  const notifyVolume = useMemo(() => {
    if (!editing) {
      return muted ? I18N.t('静音') : I18N.t('播放声音');
    }
    return (
      <Radio.Group
        value={muted}
        onChange={(e) => {
          setMuted(e.target.value);
        }}
      >
        <Space>
          <Radio value={false}>{I18N.t('播放声音')}</Radio>
          <Radio value={true}>{I18N.t('静音')}</Radio>
        </Space>
      </Radio.Group>
    );
  }, [editing, muted]);

  useImperativeHandle(ref, () => {
    return {
      submit() {
        return localStorage.setItem(STORAGE_KEY, muted ? 'muted' : 'louder');
      },
      reset() {
        setMuted(isNotifyMuted());
      },
    };
  });
  return (
    <div className={styles.msgSubscribeWrap}>
      <Form<FormData>>
        <DMFormItemContext.Provider value={{ labelWidth: 100 }}>
          <DMFormItem label={I18N.t('新消息通知')}>{notifyVolume}</DMFormItem>
        </DMFormItemContext.Provider>
      </Form>
    </div>
  );
});

export default MsgSubscribeForm;
