// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取实现阿里云实时语音识别的配置 GET /api/ai/transcription/getAliNlsConfig */
export async function aiTranscriptionGetAliNlsConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultAliNlsDto>('/api/ai/transcription/getAliNlsConfig', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取实现讯飞语音识别的配置 GET /api/ai/transcription/getXFAsrConfig */
export async function aiTranscriptionGetXFAsrConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultXFAsrConfig>('/api/ai/transcription/getXFAsrConfig', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 语音识别(使用的是openai的接口) POST /api/ai/transcription/transcribe */
export async function aiTranscriptionTranscribePost(
  body: {
    /** audio */
    audio?: string;
  },
  files?: File[],
  options?: { [key: string]: any },
) {
  const formData = new FormData();
  if (files) {
    formData.append('audio', files[0] || '');
  }
  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      formData.append(ele, typeof item === 'object' ? JSON.stringify(item) : item);
    }
  });

  return request<API.WebResultstring>('/api/ai/transcription/transcribe', {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
    ...(options || {}),
  });
}

/** 网站访客获取实现讯飞语音识别的配置 GET /api/ai/transcription/visitor/getXFAsrConfig */
export async function aiTranscriptionVisitorGetXFAsrConfigGet(options?: { [key: string]: any }) {
  return request<API.WebResultXFAsrConfig>('/api/ai/transcription/visitor/getXFAsrConfig', {
    method: 'GET',
    ...(options || {}),
  });
}
