// @ts-ignore
/* eslint-disable */
import request from '@/websocket/request';

/** 获取当前登录设备 GET /api/account/device/current */
export async function accountDeviceCurrentGet(options?: { [key: string]: any }) {
  return request<API.WebResultLoginDeviceLocationVo>('/api/account/device/current', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 设备发送心跳 GET /api/account/device/heartbeat */
export async function accountDeviceHeartbeatGet(options?: { [key: string]: any }) {
  return request<API.WebResult>('/api/account/device/heartbeat', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 把对方会话踢下线 PUT /api/account/device/kickoff */
export async function accountDeviceKickoffPut(
  params: {
    // query
    /** sessionId */
    sessionId?: string;
    /** self */
    self?: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/device/kickoff', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取相同deviceId的多个登录会话 仅限app设备 GET /api/account/device/login-sessions */
export async function accountDeviceLoginSessionsGet(options?: { [key: string]: any }) {
  return request<API.WebResultListLoginDeviceSessionVo>('/api/account/device/login-sessions', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 查询客户端关闭后是否维持登录状态 GET /api/account/device/remainLogin */
export async function accountDeviceRemainLoginGet(options?: { [key: string]: any }) {
  return request<API.WebResultboolean>('/api/account/device/remainLogin', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 更新客户端关闭后是否维持登录状态 PUT /api/account/device/remainLogin */
export async function accountDeviceRemainLoginPut(
  params: {
    // query
    /** appRemainLogin */
    appRemainLogin: boolean;
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/device/remainLogin', {
    method: 'PUT',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 查询设备到接入点的连接状态 GET /api/account/device/transitStatus */
export async function accountDeviceTransitStatusGet(options?: { [key: string]: any }) {
  return request<API.WebResultListLoginDeviceTransitDto>('/api/account/device/transitStatus', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 更新到接入点的连接信息 POST /api/account/device/transitStatus */
export async function accountDeviceTransitStatusPost(
  body: API.LoginDeviceTransitRequest,
  options?: { [key: string]: any },
) {
  return request<API.WebResult>('/api/account/device/transitStatus', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取设备详情 GET /api/account/device/${param0} */
export async function accountDeviceByDeviceIdGet(
  params: {
    // path
    /** deviceId */
    deviceId: string;
  },
  options?: { [key: string]: any },
) {
  const { deviceId: param0, ...queryParams } = params;
  return request<API.WebResultLoginDeviceLocationVo>(`/api/account/device/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 在设备上退出登录 DELETE /api/account/device/${param0}/logout */
export async function accountDeviceByDeviceIdLogoutDelete(
  params: {
    // path
    /** deviceId */
    deviceId: number;
  },
  options?: { [key: string]: any },
) {
  const { deviceId: param0, ...queryParams } = params;
  return request<API.WebResult>(`/api/account/device/${param0}/logout`, {
    method: 'DELETE',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取当前用户登录设备 GET /api/account/devices */
export async function accountDevicesGet(
  params: {
    // query
    /** 是否在线，不设置返回全部 */
    online?: boolean;
    /** sortField */
    sortField?: 'hostName' | 'id' | 'lastActiveTime' | 'lastLoginTime';
    /** sortOrder */
    sortOrder?: 'asc' | 'desc';
  },
  options?: { [key: string]: any },
) {
  return request<API.WebResultListUserLoginDeviceVo>('/api/account/devices', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
