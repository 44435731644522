import { FC, HTMLProps } from 'react';
import classNames from 'classnames';

import styles from './index.less';

type Props = {
  x1: string;
  x2?: string;
} & HTMLProps<HTMLImageElement>;

/**
 * 图片
 * @param props
 * @constructor
 */
const RetinaImage: FC<Props> = (props) => {
  const { x1, x2 = '', className, ...restProps } = props;

  let srcSet = `${x1} 1x`;
  if (x2) {
    srcSet += `,${x2} 2x`;
  }
  return (
    // @ts-ignore
    <img
      className={classNames(styles.wrap, className)}
      srcSet={srcSet}
      src={x1}
      alt=""
      {...restProps}
    />
  );
};

export default RetinaImage;
