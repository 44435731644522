import I18N from '@/i18n';
import React, { useCallback, useRef, useState } from 'react';
import { Space, Tooltip, Typography } from 'antd';
import type { ActionType } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import IconFontIcon from '@/components/Common/IconFontIcon';
import { scrollProTableOptionFn } from '@/mixins/table';
import styles from './../userSetting.less';
import {
  accountDeviceByDeviceIdGet,
  accountDeviceByDeviceIdLogoutDelete,
  accountDevicesGet,
} from '@/services/api-Account/LoginDeviceController';
import DMConfirm from '@/components/Common/DMConfirm';
import { dateFormat, SkipErrorNotifyOption } from '@/utils/utils';
import ColoursIcon from '@/components/Common/ColoursIcon';
import Placeholder from '@/components/Common/Placeholder';
import uaParser from 'ua-parser-js';
import bytes from 'bytes';
import SortTitle from '@/components/Sort/SortTitle';
import { useOrder } from '@/components/Sort/SortDropdown';
import pMinDelay from 'p-min-delay';

export type DeviceFormInstance = {
  submit: () => Promise<void>;
  reset: () => void;
};

export function osIconName(osName: string | undefined) {
  if (!osName) {
    return 'kehuduan_24';
  }
  const _osName = osName.toLowerCase();
  if (_osName.includes('windows')) {
    return 'Windows_24';
  }
  if (_osName.includes('mac')) {
    return 'MacOS_24';
  }
  if (_osName.includes('linux')) {
    return 'Linux_24';
  }

  return 'kehuduan_24';
}
export const Device = (props: { data: API.UserLoginDeviceVo; iconSize?: number }) => {
  const { data, iconSize } = props;
  if (!data) {
    return <Placeholder />;
  }
  const hacks = ['Safari', 'Firefox', 'Chrome', 'Edge'];
  const { userAgent = '', hostName, deviceType, osName } = data;
  let iconName = 'Global';
  const { browser, os } = uaParser(userAgent)!;
  let name = browser?.name || os?.name;
  if (deviceType === 'App') {
    iconName = osIconName(os.name);
    name = hostName;
  } else if (osName?.includes('Android')) {
    iconName = 'Android_24';
  } else if (hacks.includes(name)) {
    iconName = name;
    if (['Chrome', 'Edge'].includes(name)) {
      iconName = String(name).toLowerCase();
    }
    iconName = `${iconName}_24`;
  }
  return (
    <div
      style={{
        gap: 5,
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      <ColoursIcon className={iconName} size={iconSize} />
      <div style={{ overflow: 'hidden', flex: 1 }}>
        <Typography.Text style={{ color: 'inherit' }} ellipsis title={name}>
          {name}
        </Typography.Text>
      </div>
    </div>
  );
};

const Devices = () => {
  const { order: _order, changeOrder } = useOrder(
    { key: 'lastLoginTime', ascend: false },
    'devices',
  );
  const tableRef = useRef<ActionType>();
  const [statusMap, setStatusMap] = useState({});
  const refreshStatus = useCallback((deviceId: string) => {
    setStatusMap((prev) => {
      return {
        ...prev,
        [deviceId]: {},
      };
    });
    pMinDelay(
      accountDeviceByDeviceIdGet(
        {
          deviceId,
        },
        SkipErrorNotifyOption,
      ),
      1000,
    )
      .then((res) => {
        setStatusMap((prev) => {
          return {
            ...prev,
            [deviceId]: {
              data: res.data,
            },
          };
        });
      })
      .catch(() => {
        setStatusMap((prev) => {
          return {
            ...prev,
            [deviceId]: undefined,
          };
        });
      });
  }, []);
  return (
    <div className={styles.devices}>
      <div
        style={{
          paddingTop: 24,
          paddingBottom: 8,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ color: '#999' }}>{I18N.t('已登录的客户端列表')}：</span>
        <Typography.Link
          onClick={() => {
            tableRef.current?.reload();
          }}
        >
          {I18N.t('刷新')}
        </Typography.Link>
      </div>
      <div className="table-wrap">
        <ProTable
          params={{
            order: _order,
          }}
          actionRef={tableRef}
          columns={[
            {
              title: (
                <SortTitle
                  order={_order}
                  orderKey={'hostName'}
                  onSort={changeOrder}
                  label={I18N.t('设备名称')}
                />
              ),

              dataIndex: 'hostName',
              render(_, record): JSX.Element {
                return <Device data={record} />;
              },
            },
            {
              title: I18N.t('设备标识'),
              dataIndex: 'deviceId',
              render(_, record: API.UserLoginDeviceVo): JSX.Element {
                const { deviceId } = record;
                if (deviceId) {
                  return (
                    <Typography.Text
                      style={{ fontFamily: 'Consolas, Courier New, monospace' }}
                      ellipsis
                      title={deviceId}
                    >
                      {deviceId}
                    </Typography.Text>
                  );
                }
                return <Placeholder />;
              },
            },
            {
              title: I18N.t('设备配置'),
              width: '100px',
              dataIndex: 'disposition',
              render(_, record: API.UserLoginDeviceVo): React.ReactNode {
                const { cpus, deviceType, mem } = record;
                if (deviceType === 'App') {
                  let str = `${cpus}${I18N.t('核')}`;
                  if (mem) {
                    str += `${bytes(mem, { fixedDecimals: true, decimalPlaces: 0 })}`;
                  }
                  return str;
                }
                return <Placeholder />;
              },
            },
            {
              title: (
                <SortTitle
                  order={_order}
                  orderKey={'lastActiveTime'}
                  onSort={changeOrder}
                  label={I18N.t('最近活跃时间')}
                />
              ),

              dataIndex: 'lastActiveTime',
              width: '130px',
              render(_, record: API.UserLoginDeviceVo) {
                return dateFormat(
                  new Date(record.lastActiveTime! || record.lastLoginTime!),
                  'MM-dd hh:mm:ss',
                );
              },
            },
            {
              dataIndex: 'online',
              title: I18N.t('状态'),
              width: '60px',
              render(_text, record: API.UserLoginDeviceVo) {
                const { deviceId } = record;
                const _status = statusMap?.[deviceId!];
                if (_status && !_status.data) {
                  return <IconFontIcon iconName={'loading_24'} spin />;
                }
                const online = _status?.data?.online ?? record.online;
                if (online) {
                  return (
                    <Typography.Text
                      onClick={() => {
                        refreshStatus(deviceId!);
                      }}
                      style={{
                        cursor: 'pointer',
                      }}
                      type={'success'}
                    >
                      {I18N.t('在线')}
                    </Typography.Text>
                  );
                }
                return (
                  <Typography.Text
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      refreshStatus(deviceId!);
                    }}
                    type={'secondary'}
                  >
                    {I18N.t('离线')}
                  </Typography.Text>
                );
              },
            },
            {
              title: I18N.t('操作'),
              width: '50px',
              dataIndex: 'options',
              render(_, record: API.UserLoginDeviceVo) {
                const { id, currentDevice } = record;
                if (currentDevice) {
                  return <Typography.Text type={'success'}>{I18N.t('本机')}</Typography.Text>;
                }
                return (
                  <Tooltip title={I18N.t('从此设备中注销（暨解除此设备的登录态）')}>
                    <Typography.Link
                      type={'danger'}
                      onClick={() => {
                        DMConfirm({
                          title: I18N.t('确认要退出登录此设备吗？'),
                          content: I18N.t(
                            '一旦退出登录后，下次在该设备上重新登录时需要验证您的身份',
                          ),
                          onOk() {
                            accountDeviceByDeviceIdLogoutDelete({
                              deviceId: id!,
                            }).then(() => {
                              tableRef.current?.reload();
                            });
                          },
                        });
                      }}
                    >
                      <Space>
                        <IconFontIcon iconName="tuichu_24" />
                      </Space>
                    </Typography.Link>
                  </Tooltip>
                );
              },
            },
          ]}
          request={async (params) => {
            const { order } = params;
            const res = await accountDevicesGet({
              sortField: order.key,
              sortOrder: order.ascend ? 'asc' : 'desc',
            });
            setStatusMap(() => {});
            return {
              data: res.data?.filter((item) => {
                return item.deviceType === 'App';
              }),
            };
          }}
          {...scrollProTableOptionFn({
            pagination: false,
            size: 'small',
          })}
        />
      </div>
    </div>
  );
};
export default Devices;
